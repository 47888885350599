<template>
  <svn-modal-skeleton
    modal-title-center
    @append-icon-click="fetchUsers"
    @on-click-outside="fetchUsers"
  >
    <template #activator>
      <div @click="updateBreadcrumbs">
        <slot name="button" />
      </div>
    </template>

    <template #header-text>
      <svn-text
        base
        medium
      >
        {{ $t('Deactivated users', { deactivated: inactiveUsers?.length || 0 }) }}
      </svn-text>
    </template>

    <template #content-body>
      <div
        class="w-full h-full flex flex-col"
      >
        <div class="flex px-4 pt-4 sm:!pt-2 pb-2 items-center self-stretch">
          <svn-text
            sm
            normal
            color="dark-grey"
            class="text-center"
          >
            {{ $t('Deactivated users data still remains on Aleph, but these accounts are no longer accessible by their owner. Their email address can not be used for another account.') }}
          </svn-text>
        </div>

        <div class="flex flex-col items-center self-stretch px-4 py-2 sm:!py-4">
          <v-text-field
            v-model="search"
            variant="outlined"
            density="compact"
            :label="$t('Search')"
            hide-details
            class="w-full"
            color="dark-grey"
            @update:model-value="searchInactiveUsers"
          >
            <template #prepend-inner>
              <Icon
                icon="mdi:magnify"
                height="24"
                width="24"
                class="text-dark-grey"
              />
            </template>
          </v-text-field>
        </div>

        <div
          v-if="loading"
          class="flex w-full h-[225px] mx-auto justify-center items-center"
        >
          <svn-loader loading-size="lg" />
        </div>

        <div
          v-else
          class="w-full h-full flex flex-col"
        >
          <div
            v-if="inactiveUsers?.length"
            class="flex flex-col"
          >
            <v-infinite-scroll
              color="primary"
              class="overflow-hidden"
              :items="inactiveUsers"
              @load="loadMoreData"
            >
              <template
                v-for="(user) in inactiveUsers"
                :key="user?.id"
              >
                <deactivated-user-item :user="user" @restore-user="restoreDeactivatedUser" />
              </template>

              <template #empty />
            </v-infinite-scroll>
          </div>

          <div
            v-else-if="search?.length && !inactiveUsers?.length"
            class="flex flex-col gap-3 w-full h-full mx-auto py-16 justify-center items-center"
          >
            <div>
              <Icon
                icon="noto:thinking-face"
                height="24"
                width="24"
              />
            </div>

            <div class="max-w-[300px] flex">
              <svn-text
                sm
                medium
                class="text-center"
              >
                {{ $t('Hmm, it seems we can’t find any results based on your search.') }}
              </svn-text>
            </div>
          </div>

          <div
            v-else-if="!inactiveUsers?.length && !search?.length"
            class="flex flex-col gap-3 w-full h-full mx-auto py-16 justify-center items-center"
          >
            <div>
              <Icon
                icon="noto:thinking-face"
                height="24"
                width="24"
              />
            </div>

            <div class="max-w-[300px]">
              <svn-text
                sm
                medium
                class="text-center"
              >
                {{ $t('Hmm, it seems you have no inactive users yet.') }}
              </svn-text>
            </div>
          </div>
        </div>
      </div>
    </template>
  </svn-modal-skeleton>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Icon } from "@iconify/vue";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
import { debounce } from "lodash";
import axiosService from "@/tools/axios-service.js";
import DeactivatedUserItem from "@/components/companyApp/DeactivatedUserItem.vue";

onMounted(async() => {
  if (!inactiveUsers?.value) {
    try {
      const { data } = await axiosService.get(`/api/v1/users/inactives`);
      inactiveUsers.value = data?.users
      totalPages.value = data?.meta?.pagination?.total_pages;
    } catch (error) {
      console.log(error)
    }
  }
  loading.value = false;
})

const emit = defineEmits(['fetch-users'])

const search = ref(null);
const loading = ref(true);
const currentPage = ref(1);
const usersChanged = ref(false);
const totalPages = ref(null);

const snackbar = useSnackbar();
const { restoreInactiveUser } = useUserStore();

const { isMobile } = storeToRefs(useMobileStore());
const { inactiveUsers } = storeToRefs(useUserStore());

const searchInactiveUsers = debounce(async() => {
  loading.value = true;
  try {
    const res = await axiosService.get(`/api/v1/users/inactives`, {
      params: {
        title: search?.value,
      }
    })
    inactiveUsers.value = res?.data?.users;
    loading.value = false
  } catch (error) {
    console.log(error)
  }
}, 300)

const restoreDeactivatedUser = debounce(async(userId) => {
  usersChanged.value = true;
  try {
    await restoreInactiveUser(userId)

    inactiveUsers?.value?.splice(inactiveUsers?.value?.findIndex(user => user?.id === userId), 1)


    snackbar.setBgColor('positiveGreen');
    snackbar.setCustomClass(isMobile?.value ? 'mb-[80px]' : 'mb-8')
    snackbar.setMsg('User has been reactivated successfully.');
    snackbar.displaySnackBar();
  } catch (error) {
    console.log(error)
  }
}, 300)

const loadMoreData = async({ done }) => {
  if (currentPage.value < totalPages.value) {
    try {
      const res = await axiosService.get(`/api/v1/users/inactives`, {
        params: {
          "page[number]": currentPage?.value + 1,
          "page[per]": 25,
        }
      })

        if (res.data.users.length) {
          inactiveUsers.value = [...inactiveUsers.value, ...res.data.users]
        }
        done('ok')
        currentPage.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const fetchUsers = () => {
  if (usersChanged.value) {
    emit('fetch-users')
  }
  usersChanged.value = false
}
</script>

<style scoped>
* :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>