<template>
  <div
    class="relative flex flex-col justify-center items-start p-4 bkt-bg-light-grey9-hover cursor-pointer"
    @click="() => router.push( {name: 'roadmaps_targets_show', params: { id: roadmap.id }})"
  >
    <svn-dots-button
      circle
      variant="hoverNoPrimary"
      class="absolute top-0 right-0"
    >
      <template #items>
        <v-list-item
          v-if="type === 'unarchived'"
          value="unarchived"
          @click="openPopUpArchive(roadmap?.id)"
        >
          {{ $t('Archive target') }}
        </v-list-item>

        <v-list-item
          v-if="type !== 'unarchived'"
          value="different_than_unarchived"
          @click="openPopUpUnarchive(roadmap?.id)"
        >
          {{ $t('Unarchive target') }}
        </v-list-item>

        <v-list-item
          value="delete"
          class="text-negativeRed"
          @click="openPopUpDelete(roadmap?.id)"
        >
          {{ $t('Delete') }}
        </v-list-item>
      </template>
    </svn-dots-button>
  
    <p class="text-base text-start text-fakeBlack font-medium text-ellipsis">
      {{ roadmap?.title }}
    </p>
  
    <p class="text-sm text-darkGrey font-normal mb-2">
      {{ $t('Deadline : ', {date: roadmap?.due_date}) }}
    </p>
  
    <my-roadmaps-objective-indicator :objective-indicator="roadmap.objective_indicator" />
  </div>
</template>

<script setup>
import { usePopUpStore } from '@/store/pop-up';
import {useSnackbar} from "@/store/snackbar.js";
import { useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import MyRoadmapsObjectiveIndicator from './MyRoadmapsObjectiveIndicator.vue';

const props = defineProps({
  roadmap: { type: Object, required: true },
  type: { type: String, required: true },
});

const emit = defineEmits(['update'])

const router = useRouter();

const { openPopUp, closePopUp } = usePopUpStore();

const snackbar = useSnackbar();
snackbar.checkStatus();

const openPopUpArchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to archive this target ?',
    subtitle: 'You will find this target in the "Archived targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, archive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'archived'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been archived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while archiving your target !')
      }
    },
  })
}

const openPopUpUnarchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to unarchive this target ?',
    subtitle: 'You will find this target in the "Current targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, unarchive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'opened'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been unarchived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while unarchiving your target !')
      }
    },
  })
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this target ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No",
    textConfirm: "Yes, delete",
    color: 'red',
    customClass: '',
    async confirm() {
      try {
        await axiosService.delete(`/api/v1/roadmaps/targets/${id}`)
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been deleted.')
        await router.push({name: "my_roadmaps"})
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while deleting your target !')
      }
    },
  })
}
</script>