<template>
  <div
    :class="overviewClass()"
    class="h-full mx-auto p-4 sm:flex-column"
  >
    <div
      class="2xl:flex sm:flex-column 2xl:space-x-4 2xl:space-y-0 sm:space-x-0 sm:space-y-4 sm:h-full 2xl:h-[300px]"
      :class="doughnutClass()"
    >
      <completion-all-data
        v-if="completionAllData"
        :completion-all-data="completionAllData"
        :chart-colors="chartColors"
        :chart-completion-all-options="chartCompletionAllOptions"
        :campaign="campaign"
        :loading="loadingCompletionAll"
      />
      <completion-individual
        :completion-individual="completionIndividual"
        :chart-completion-individual="chartCompletionIndividual"
        :chart-completion-individual-options="chartCompletionIndividualOptions"
        :campaign="campaign"
        :loading="laodingCompletionIndividual"
      />
    </div>
    
    <chart-completion-category
      :campaign="campaign"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import {storeToRefs} from "pinia";
import {useCampaignStore} from "@/store/campaign.js";
import {CampaignTypeBackend} from "@/constants/CampaignTypeEnum.js";
import {useI18n} from "vue-i18n";
import { useRoute } from "vue-router";
import CompletionAllData from "../campaign/CompletionAllData.vue";
import CompletionIndividual from "../campaign/CompletionIndividual.vue";
import ChartCompletionCategory from "../campaign/ChartCompletionCategory.vue";

const route = useRoute();
const { t } = useI18n();
const { campaign, completionIndividual, completionAll, laodingCompletionIndividual, loadingCompletionAll } = storeToRefs(useCampaignStore());
const { fetchCompletionIndividual } = useCampaignStore();
const props = defineProps({
  completionCategories: {
    type: Array,
    default: () => []
  },
})

onMounted(async () => {
  if (campaign.value.campaign_type === CampaignTypeBackend.ONE_TO_ONE) {
    await fetchCompletionIndividual(props.campaignId || route.params.id)
  }
})

const chartColors = ref(['#7BC079', '#FFC85C', '#FF5656', '#787878']);
const chartLabels = ref([t('Submitted'), t('In progress'), t('Not started'), t('Not available yet')]);


const completionAllData = computed(() => {
  return [
    completionAll.value?.['submitted'],
    completionAll.value?.['in_progress'],
    completionAll.value?.['not_started'],
  ]
})

const chartCompletionIndividual = computed(() => {
  return {
    labels: [
      t('Interviewee'),
      t('Interviewer'),
      t('Cross'),
    ],
    datasets: [
      {
        label: chartLabels.value[0],
        backgroundColor: chartColors.value[0],
        data: completionIndividual.value ? Object.entries(completionIndividual.value.submitted)
          .sort(([keyA], [keyB]) => chartLabels.value.indexOf(keyA) - chartLabels.value.indexOf(keyB))
          .map(([, value]) => value) : null,
        maxBarThickness: 20,
      },
      {
        label: chartLabels.value[1],
        backgroundColor: chartColors.value[1],
        data: completionIndividual.value ? Object.entries(completionIndividual.value.in_progress)
          .sort(([keyA], [keyB]) => chartLabels.value.indexOf(keyA) - chartLabels.value.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      },
      {
        label: chartLabels.value[2],
        backgroundColor: chartColors.value[2],
        data: completionIndividual.value ? Object.entries(completionIndividual.value.not_started)
          .sort(([keyA], [keyB]) => chartLabels.value.indexOf(keyA) - chartLabels.value.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      },
      {
        label: chartLabels.value[3],
        backgroundColor: chartColors.value[3],
        data: completionIndividual.value ? Object.entries(completionIndividual.value.not_available_yet)
          .sort(([keyA], [keyB]) => chartLabels.value.indexOf(keyA) - chartLabels.value.indexOf(keyB))
          .map(([, value]) => value) : null,
        skipNull: true,
        maxBarThickness: 20,
      }
    ]
  }
});

const chartCompletionAllOptions = {
  cutout: "80%",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      align: "center",
      title: {
        display: false
      },
      padding: {
        x: 0,
        y: 0
      }
    },
  },
}


const chartCompletionIndividualOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      align: "start"
    }
  }
}

const overviewClass = () => {
  switch (campaign.value.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return 'lg:w-2/3 lg:space-y-4 sm:space-y-4'
    case CampaignTypeBackend.FEEDBACK_360:
      return 'lg:flex lg:w-11/12 lg:space-x-4 sm:space-x-0 sm:space-y-4 lg:space-y-0'
    case CampaignTypeBackend.SURVEY:
      return 'lg:flex lg:w-11/12 lg:space-x-4 sm:space-x-0 sm:space-y-4 lg:space-y-0'
    default:
      return ''
  }
}

const doughnutClass = () => {
  switch (campaign.value.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return 'w-full'
    case CampaignTypeBackend.FEEDBACK_360 || CampaignTypeBackend.SURVEY:
      return 'lg:w-1/3 sm:w-full'
    case CampaignTypeBackend.SURVEY:
      return 'lg:w-1/3 sm:w-full'
    default:
      return ''
  }
}

</script>