<template>
  <v-dialog
    v-model="dialogDuplicateModule"
    content-class="!rounded-[12px] bg-white"
    :width="isMobile ? 300 : 400"
  >
    <template #default>
      <div class="w-full flex flex-col p-6 gap-8">
        <!-- Icon and text -->
        <div class="flex flex-col items-center gap-4">
          <!-- icon -->
          <Icon
            icon="mdi:content-copy"
            height="32"
            width="32"
          />

          <!-- Title & description -->
          <div class="flex flex-col items-center self-stretch gap-4">
            <p class="text-fakeBlack text-xl font-medium text-center">
              {{ $t('Duplicate module') }}
            </p>

            <div class="flex flex-col items-start self-stretch gap-2">
              <p class="etxt-fakeBlack text-sm font-normal">
                {{ $t('Chose your title :') }}
              </p>

              <!-- Duplicate title -->
              <v-text-field
                v-model="duplicateTitle"
                variant="outlined"
                class="text-sm text-fakeBlack font-normal w-full input"
                :placeholder="$t('Copy of content')"
                hide-details
                maxlength="70"
                density="compact"
              />
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col items-start gap-3 self-stretch lg:!flex-row">
          <v-btn
            variant="outlined"
            color="fakeBlack"
            class="normal-case text-xs w-full lg:!flex-1"
            :text="$t('Cancel')"
            @click="dialogDuplicateModule = false"
          />

          <v-btn
            variant="flat"
            color="primary"
            class="normal-case text-xs w-full lg:!w-1/2"
            :text="$t('Duplicate')"
            @click="emit('duplicate-module', duplicateTitle)"
          />
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';

const dialogDuplicateModule = ref(false)

const duplicateTitle = ref(null)

const { isMobile } = storeToRefs(useMobileStore())

const emit = defineEmits(['duplicate-module'])

defineExpose({
  dialogDuplicateModule
})
</script>

<style scoped>
:deep(.v-field) {
  border-radius: 8px;
}
</style>
