<template>
  <div
    ref="tagContainer"
    class="bkt-tag-container flex-row-start-centered ml-4"
  >
    <bkt-tag
      v-for="tag in displayTags()"
      :key="tag.id"
      class="bkt-tag"
    >
      {{ tag.title }}
    </bkt-tag>

    <bkt-tag
      v-if="remainingTags() > 0"
      class="bkt-tag"
    >
      +{{ remainingTags() }}
    </bkt-tag>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import BktTag from "../components/BktTag.vue";

  const props = defineProps(
    {
      tags: { type: Array, default: () => [] }
    }
  )

  const maxLen = ref(30)

  const displayTags = () => {
    let len = 0
    return props.tags.filter(tag => {
      len += tag.title.length
      return (len < maxLen.value)
    })
  }
  const remainingTags = () => {
    return props.tags.length - displayTags().length
  }
</script>
