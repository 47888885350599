<template>
  <div class="flex flex-col w-full h-full">
    <!-- Header -->
    <module-show-header-buttons
      type="draft"
      @close-and-go-back="handleClose"
    >
      <template #preview-module>
        <modal-preview-learn-module
          v-if="learnModule"
          :title="title"
          :cover="learnModule?.cover_url?.['500']"
          :themes="learnModule?.themes"
          :duration="learnModule?.duration"
          :editor-data="editorContentData"
          :reactions="learnModule?.user_reactions"
          :has-learn-pieces-quiz="learnModule?.has_learn_pieces_quiz"
          :inputs="inputs"
          :learn-module="learnModule"
        >
          <template #button>
            <svn-icon-button
              icon="ic:outline-remove-red-eye"
              button-size="md"
              variant="noOutlined"
            />
          </template>
        </modal-preview-learn-module>
      </template>

      <template #dots-button>
        <svn-dots-button variant="hoverNoPrimary">
          <template #items>
            <v-list-item value="duplicate_module" @click="openDialogDuplicateModule(learnModule?.id)">
              {{ $t('Duplicate module') }}
            </v-list-item>

            <v-list-item value="delete_module" @click="openDialogDeleteModude(learnModule?.id)" class="text-negativeRed">
              {{ $t('Delete module') }}
            </v-list-item>
          </template>
        </svn-dots-button>
      </template>

      <template v-if="learnModule?.status === 'draft'" #publish-module>
        <publish-module-button
          :cannot-be-published="cannotBePublished"
          @publish-module="publishLearnModule"
        />
      </template>
    </module-show-header-buttons>

    <!-- Main content -->
    <div
      class="flex flex-col pb-[80px] lg:w-[758px] lg:px-[180px] lg:pt-8 mx-auto px-4 gap-6 lg:gap-8 w-full"
    >
      <!-- Module Image section -->
      <bkt-image-cover-position
        class="my-4 lg:!my-[0px] "
        mode="edit"
        :url="learnModule?.cover_url ? learnModule?.cover_url?.['1000'] : null"
        :coordinates="{left: learnModule?.cover_offset_left, top: learnModule?.cover_offset_top,}"
        @update:coordinates="updateModule({id: route?.params?.id, cover_offset_left: $event.left, cover_offset_top: $event.top})"
        @file-upload="uploadImage"
        @error="errorUploading"
      />

      <div class="flex flex-col gap-6">
        <!-- Module title input -->
        <v-text-field
          v-model="title"
          :variant="'plain'"
          persistent-counter
          maxlength="70"
          counter
          hide-details="auto"
          :onfocus="changeVariant"
          :onblur="changeVariant"
          class="text-fakeBlack font-semibold input-title relative text-3xl"
          :placeholder="$t('Module title')"
          density="compact"
          @input="updateLearnModuleTitle"
        >
          <template #counter="{ value }">
            <div
              v-if="variant === 'outlined'"
              class="absolute top-[8px] right-[19px] z-[20] self-start"
            >
              <span class="text-middleGrey text-xl font-medium">
                {{ 70 - value }}
              </span>
            </div>
          </template>
        </v-text-field>

        <!-- Duration -->
        <div class="flex flex-col gap-2">
          <!-- Estimated duration text -->
          <div class="font-medium text-sm lg:text-xl flex items-center gap-1">
            <p>{{ $t('Estimated duration') }}</p>

            <div class="cursor-pointer">
              <Icon
                icon="ic:outline-info"
                class="h-4 w-4 text-darkGrey"
              />
            </div>
          </div>

          <!-- Inputs -->
          <div class="flex gap-2 w-full">
            <!-- Time -->
            <div class="w-[104px] flex-items-center">
              <v-text-field
                v-model="time"
                hide-details
                density="compact"
                variant="outlined"
                :placeholder="learnModule?.duration ? learnModule?.duration?.split(' ')?.[0] : '10'"
                type="number"
                @input="updateLearnModuleDurationTime"
              />
            </div>

            <!-- Time unit -->
            <div class="w-[214px]">
              <v-select
                v-model="timeUnit"
                :items="timeUnits"
                density="compact"
                class="inputDuration"
                hide-details
                variant="outlined"
                @update:model-value="updateLearnModuleDurationTimeUnit"
              />
            </div>
          </div>
        </div>

        <!-- Theme block -->
        <div class="flex flex-col gap-2">
          <!-- Themes text -->
          <div class="font-medium text-sm flex items-center gap-1 lg:text-xl">
            <p>{{ $t('Themes') }}</p>

            <div class="cursor-pointer">
              <Icon
                icon="ic:outline-info"
                class="h-4 w-4 text-darkGrey"
              />
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <!-- Add theme button -->
            <div
              v-if="!newTheme"
              class="flex"
            >
              <div
                class="text-xs text-dark-grey flex gap-2 normal-case px-4 p-y-0 items-center font-medium
                hover:bg-light-grey rounded-lg hover:text-fake-black cursor-pointer"
                @click="newTheme = true"
              >
                <span>{{ $t('Add') }}</span>

                <div class="h-12 flex items-center ">
                  <Icon
                    icon="material-symbols:add"
                    class="h-4 w-4"
                  />
                </div>
              </div>
            </div>

            <!-- Input to add a new theme -->
            <div
              v-else
              class="w-full flex items-center gap-4 sm:!w-[258px]"
            >
              <svn-autocomplete
                v-model="entityThemes"
                :items="learnThemes"
                item-title="name"
                hide-details
                hide-selected
                color="blue-grey-lighten-2"
                :label="$t('Select a theme from the list')"
                multiple
                return-object
                @update:search="searchThemeText = $event"
                @update:model-value="updateLearnModuleThemes"
              >
                <template #main-content>
                  {{ null }}
                </template>

                <template #append-content>
                  {{ null }}
                </template>

                <template #prepend-details>
                  {{ null }}
                </template>

                <template #no-data>
                  <div
                    v-if="searchThemeText.length !== 0"
                    class="flex h-10 cursor-pointer hover:bg-gray-200 items-center"
                    @click="addTheme()"
                  >
                    <svn-text base class="ml-4 mr-2">
                      {{ $t('Create') }}
                    </svn-text>

                    <svn-chip
                      v-if="searchThemeText"
                      class="!bg-transparent-medium-orange !text-sm !text-primary border-none !rounded-full !px-3 !cursor-pointer"
                      :text="searchThemeText"
                    />
                  </div>

                  <div
                    v-else
                    class="flex h-10 cursor-pointer hover:bg-gray-200 items-center"
                  >
                    <span class="ml-4 mr-2">
                      {{ ('Type something to search for tags') }}
                    </span>
                  </div>
                </template>

                <template #chip-content>
                  {{ null }}
                </template>
              </svn-autocomplete>
            </div>

            <!-- Themes list -->
            <div
              v-if="entityThemes?.length"
              class="flex flex-wrap gap-2"
            >
              <div
                v-for="(theme, index) in entityThemes"
                :key="theme"
                class="flex justify-center items-center px-2 py-[6px] gap-2 rounded-[8px] bg-trainingOrangeSoft"
              >
                <p class="flex text-xs font-normal text-fakeBlack items-center">
                  {{ theme.name }}
                </p>

                <div
                  class="cursor-pointer"
                  @click.stop.prevent="deleteTheme(theme)"
                >
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-middleGrey"
                  />
                </div>
              </div>
            </div>

            <!-- No theme in the module -->
            <div
              v-else
              class="w-fit flex justify-center items-center px-2 py-[6px] gap-2 rounded-[8px] bg-trainingOrangeSoft"
            >
              <p class="flex text-xs font-normal text-fakeBlack items-center">
                {{ $t('No theme') }}
              </p>
            </div>
          </div>
        </div>

        <!-- Rich text editor -->
        <svn-tiptap
          v-if="editorContentData?.blocks?.length"
          :create-image-url="`/api/v1/editor_contents/${editorContentId}/upload_image`"
          :html-data="editorContentData?.blocks"
          :extension-selection="AllTipTapPlugins"
          :extension-slash-command="AllTipTapPlugins"
          :extension-left-menu="true"
          @on-save="debounceEditorContentUpdate"
        />

        <!-- Evaluation block -->
        <div class="flex flex-col gap-2">
          <!-- Evaluation block title -->
          <div class="flex items-center gap-1">
            <p class="font-medium text-sm lg:text-xl ">{{ $t('Evaluation') }}</p>
          </div>

          <!-- Evaluation main block -->
          <div
            class="relative flex flex-col"
            :class="(learnModule?.learn_pieces_quiz?.id || learnModule?.learn_pieces_face_to_face_evaluation?.id) ?
              '2xl:gap-0 gap-4 2xl:flex-row 2xl:w-[1094px] 2xl:items-start' : ''"
          >
            <div id="module-evaluation" class="flex flex-col p-6 border border-middle-grey rounded-[12px] gap-4" :class="learnModule.piece_id ? '' : warningClass">
              <div class="flex flex-col items-start gap-4 self-stretch">
                <div class="flex items-center gap-1 self-stretch">
                  <svn-text sm medium>
                    {{ $t('Evaluation type') }}
                  </svn-text>

                  <div>
                    <Icon
                      icon="ic:outline-info"
                      class="h-4 w-4 text-dark-grey"
                    />
                  </div>
                </div>

                <v-divider class="border-opacity-100 w-full text-light-grey" />
              </div>

              <!-- Choose evaluation type -->
              <div class="flex flex-col gap-4 sm:grid sm:grid-cols-2 sm:gap-4">
                <evaluation-type-item
                  v-for="item in evaluationItems"
                  :key="item?.type"
                  :icon="item?.icon"
                  :title="item?.title"
                  :selected="item?.type === learnModule?.submission_type"
                  :description="item?.description"
                  @click=changeEvaluationType(item?.key)
                />
              </div>


              <!-- Evaluation type is Approval -->
              <div
                v-if="learnModule?.has_learn_pieces_approval"
                class="flex flex-col gap-2"
              >
              <span class="text-darkGrey text-sm">
                Learning objective(s)*
              </span>

              <v-textarea
                v-model="learnApprovalInput.text"
                variant="outlined"
                density="compact"
                class="w-full"
                hide-details
                @update:model-value="updateApprovalInputText"
              />
              </div>
              <!-- Evaluation type is Quiz -->
              <div
                v-if="learnModule?.learn_pieces_quiz?.id && inputs?.length"
                class="flex flex-col gap-2"
              >
                <transition-group>
                  <draggable
                    key="draggable"
                    item-key="item"
                    :list="inputs"
                    v-bind="dragOptions"
                    class="flex flex-col gap-2"
                    :delay="50"
                    :delay-on-touch-only="true"
                    handle=".drag"
                    @start="drag = true"
                    @end="drag = false"
                    @change="getListAfterDrag($event)"
                  >
                    <template #item="{ element }">
                      <div
                        :key="element?.type"
                        class="rounded-lg border p-4"
                      >
                        <learn-edit-question-block
                          v-if="element.type === LearnInputType.CHECKBOX"
                          :input="element"
                          :can-remove-input="inputs.filter(input =>
                          input.type === LearnInputType.CHECKBOX).length > 1"
                          @update-input="inputChannel?.update"
                          @remove-option="removeOption($event)"
                          @add-option="addOption($event)"
                          @remove-input="removeInput(element)"
                          @duplicate-option="duplicateOption(element)"
                        />

                        <learn-edit-free-content-block
                          v-if="element.type === LearnInputType.PARAGRAPH && element?.id"
                          :editor-id="String(element?.id)"
                          :input="element"
                          :is-dragged="isDragged"
                          :module-id="learnModule.id"
                          @remove-paragraph="removeInput(element)"
                          @toggle-drag-state="toggleDragState"
                        />
                      </div>
                    </template>
                  </draggable>
                </transition-group>
              </div>

              <!-- Evaluation type is Face to face -->
              <div
                v-if="learnModule?.learn_pieces_face_to_face_evaluation?.id && inputsFaceToFace?.length"
                class="flex flex-col gap-2"
              >
                <transition-group>
                  <draggable
                    key="draggable"
                    item-key="item"
                    :list="inputsFaceToFace"
                    v-bind="dragOptions"
                    class="flex flex-col gap-2"
                    :delay="50"
                    :delay-on-touch-only="true"
                    handle=".drag"
                    @start="drag = true"
                    @end="drag = false"
                    @change="getListAfterDragFaceToFace($event)"
                  >
                    <template #item="{ element }">
                      <div
                        :key="element?.type"
                        class="rounded-lg border p-4"
                      >
                        <learn-edit-open-question-block
                          v-if="element.type === LearnInputType.OPEN"
                          :input="element"
                          :can-remove-question="inputsFaceToFace.filter(input =>
                            input.type === LearnInputType.OPEN)?.length > 1"
                          @update-question="inputChannel?.update"
                          @duplicate-question="duplicateOpenQuestion(element)"
                          @remove-question="removeOpenQuestion(element)"
                        />

                        <learn-edit-free-content-block
                          v-if="element.type === LearnInputType.PARAGRAPH && element?.id"
                          :editor-id="String(element?.id)"
                          :input="element"
                          :is-dragged="isDragged"
                          :module-id="learnModule.id"
                          @remove-paragraph="removeFreeContentFaceToFace(element)"
                          @toggle-drag-state="toggleDragState"
                        />
                      </div>
                    </template>
                  </draggable>
                </transition-group>
              </div>
            </div>

            <!-- If Evaluation type is Quiz or Face to Face -->
            <div v-if="learnModule?.learn_pieces_quiz?.id || learnModule?.learn_pieces_face_to_face_evaluation?.id"
              class="flex flex-col bg-white sticky top-[50%]"
            >
              <div class="2xl:pl-[18px] flex flex-col gap-2 lg:gap-4">
                <learn-add-question-block
                  v-if="learnModule?.learn_pieces_quiz?.id"
                  @click="addQuizQuestionBlock()"
                />

                <learn-add-question-block
                  v-if="learnModule?.learn_pieces_face_to_face_evaluation?.id"
                  @click="addFaceToFaceOpenQuestionBlock()"
                  face-to-face
                />

                <learn-add-free-content-block
                  v-if="learnModule?.learn_pieces_quiz?.id"
                  @click="addQuizFreeContentBlock()"
                />

                <learn-add-free-content-block
                  v-if="learnModule?.learn_pieces_face_to_face_evaluation?.id"
                  @click="addQuizFreeContentBlock(true)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog duplicate module -->
    <dialog-duplicate-module
      ref="duplicateModuleDialog"
      @duplicate-module="duplicateLearnModule"
    />

    <!-- Dialog delete module -->
    <dialog-delete-module
      ref="deleteModuleDialog"
      :trainings="learnModule?.learn_trainings"
      @delete-module="deleteLearnModule"
    />

  </div>

  <!-- Scroll to top button -->
  <svn-floating-action-button
    square
    to-top
    class="!z-[2]"
  />

</template>

<script setup>
import {Icon} from "@iconify/vue";
import {onMounted, watch, ref, computed, onBeforeUnmount} from "vue";
import draggable from "vuedraggable";
import ModalPreviewLearnModule from "@/components/BktPopUp/Modals/learn/ModalPreviewLearnModule.vue";
import LearnAddQuestionBlock from "@/components/learnApp/moduleBlock/createBlock/LearnAddQuestionBlock.vue";
import LearnAddFreeContentBlock from "@/components/learnApp/moduleBlock/createBlock/LearnAddFreeContentBlock.vue";
import LearnEditQuestionBlock from "@/components/learnApp/moduleBlock/editBlock/LearnEditQuestionBlock.vue";
import LearnEditFreeContentBlock from "@/components/learnApp/moduleBlock/editBlock/LearnEditFreeContentBlock.vue";
import { useLearnModuleStore } from "@/store/learn-module";
import PublishModuleButton from "@/components/trainingApp/PublishModuleButton.vue";
import DialogDeleteModule from "@/components/BktPopUp/Dialogs/learn/DialogDeleteModule.vue";
import DialogDuplicateModule from '@/components/BktPopUp/Dialogs/learn/DialogDuplicateModule.vue';
import { useLearnThemesStore } from "@/store/learn-themes";
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { useRouter, useRoute } from "vue-router";
import { debounce } from "lodash";
import { useActionCable } from "@/store/cable.js";
import { useLearnTrainingStore } from "@/store/learn-trainings.js";
import { useToastStore } from "@/store/toast.js";
import { useUserStore } from "@/store/user.js";
import i18n from "@/plugins/i18n.js";
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";
import axiosService from "@/tools/axios-service.js";
import EvaluationTypeItem from "../../../../../components/learnApp/EvaluationTypeItem.vue";
import ModuleShowHeaderButtons from "@/components/trainingApp/ModuleShowHeaderButtons.vue";
import LearnEditOpenQuestionBlock from "../../../../../components/learnApp/moduleBlock/editBlock/LearnEditOpenQuestionBlock.vue";
import { LearnInputType } from '@/constants/types';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';

const { addToast } = useToastStore();
const { id: userId } = storeToRefs(useUserStore())

const {
  updateModule, publishModule, duplicateModule, addThemeToModule,
  removeThemeFromModule,
  fetchModule,
  deleteModule,
  fetchInputs,
  fetchInputsFaceToFace,
  updateInputs,
  updateInputsFaceToFace,
  postInputs,
  postInputsFaceToFace,
  postParagraph,
  postParagraphFaceToFace,
  deleteInputQuestion,
  deleteOpenQuestion,
  getEditorContent,
  updateModuleImage,
  resetStates,
  changeSubmissionPieceType,
  updateApprovalInput
} = useLearnModuleStore()

const {
  learnModule,
  inputs,
  inputsFaceToFace,
  editorContentId,
  editorContentData,
  learnApprovalInput
} = storeToRefs(useLearnModuleStore());
const {cable} = storeToRefs(useActionCable());

const learnThemesStore = useLearnThemesStore()
const { fetchThemes } = learnThemesStore
const { learnThemes } = storeToRefs(learnThemesStore)

const learnTrainingStore = useLearnTrainingStore()
const { learnTraining } = storeToRefs(learnTrainingStore)
const { updateModuleAndPlaylistData, fetchTraining } = learnTrainingStore

const mobileStore = useMobileStore()

const { isMobile } = storeToRefs(mobileStore)

onMounted(async () => {
  await resetStates()

  try {
    await fetchModule(route?.params?.id).then(() => {
      entityThemes.value = learnModule.value?.themes
      title.value = learnModule.value?.title
      if (learnModule.value?.duration) {
        time.value = learnModule.value?.duration?.split(' ')?.[0]
        timeUnit.value = learnModule.value?.duration?.split(' ')?.[1] === 'h' ? 'hours' : 'minutes'
      }
    }).then(() => {
      updateEditorContent()
    })

    if (learnModule?.value?.editor_content_id) {
      await getEditorContent(learnModule.value.editor_content_id);
    }

    if (learnModule?.value?.has_learn_pieces_quiz) {
      await fetchInputs(learnModule.value?.learn_pieces_quiz?.id);
    }

    if (learnModule?.value?.has_learn_pieces_face_to_face_evaluation) {
      await fetchInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id);
    }
    updateInputQuestion();
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }

  try {
    await fetchThemes()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching themes')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
})

const title = ref()
const evaluationItems = ref([
  {
    key: 'Approval',
    type: 'Learn::Pieces::Approval',
    icon: 'noto:check-mark-button',
    title: 'Self-assessment',
    description: 'The learner must confirm they have fully understood the module before proceeding.',
  },
  {
    key: 'Quiz',
    type: 'Learn::Pieces::Quiz',
    icon: 'noto:red-question-mark',
    title: 'Quiz',
    description: 'The learner must pass the quiz with a score of 100% correct answers to successfully complete this section.',
  },
  {
    key: 'FaceToFaceEvaluation',
    type: 'Learn::Pieces::FaceToFaceEvaluation',
    icon: 'noto:busts-in-silhouette',
    title: 'Face-to-face evaluation',
    description: "The learner's answers will be evaluated in real time by an expert, ensuring accurate assessment and personalized feedback.",
  },
]);

const cannotBePublished = computed(() => {
  return (
    !title.value ||
    !time.value
  );
});
const time = ref()
const isDragged = ref(false)
const timeUnit = ref('minutes')
const timeUnits = ref([
  'minutes',
  'hours',
])
const variant = ref('plain')
const newTheme = ref(false)
const entityThemes = ref([])
const searchThemeText = ref('')
const warningClass = ref('')
const drag = ref(false)
const snackbar = useSnackbar()
const router = useRouter()
const route = useRoute()
const inputChannel = ref(null)
const editorContentChannel = ref(null)
const duplicateModuleDialog = ref(false)
const deleteModuleDialog = ref(false)
const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});
const coordinates = ref({
  left: learnModule?.cover_offset_left,
  top: learnModule?.cover_offset_top,
});

const toggleDragState = (even) => {
  isDragged.value = even
}

const updateApprovalInputText = debounce(async(even) => {
  await updateApprovalInput({text: even})
}, 300)

const addTheme = async () => {
  if (searchThemeText.value) {
    try {
      await addThemeToModule(learnModule?.value?.id, searchThemeText.value)
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Theme added to module')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
      snackbar.displaySnackBar()
      entityThemes.value = learnModule.value.themes
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error adding theme to module')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
      snackbar.displaySnackBar()
    } finally {
      searchThemeText.value = ''
    }
  }
}

const deleteTheme = async (theme) => {
  try {
    await removeThemeFromModule(learnModule?.value?.id, theme?.name);
    snackbar.setBgColor('fakeBlack');
    snackbar.setMsg('Theme removed from module');
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]');
    snackbar.displaySnackBar();
    entityThemes.value = learnModule.value.themes;
  } catch (error) {
    snackbar.setBgColor('negativeRed');
    snackbar.setMsg('Error removing theme from module');
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]');
    snackbar.displaySnackBar();
  }
}

const updateLearnModuleThemes = async () => {
  try {
    const theme = findDifferentValues(entityThemes.value, learnModule.value.themes)
    if (theme) {
      if (entityThemes.value.length > learnModule.value.themes.length) {
        await addThemeToModule(learnModule?.value?.id, theme)
        entityThemes.value = learnModule.value.themes
        snackbar.setBgColor('positiveGreen')
        snackbar.setMsg('Theme added to module')
        snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
        snackbar.displaySnackBar()
      }
    }
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}

const findDifferentValues = (array1, array2) => {
  const entityThemeNames = array1.map(theme => theme.name);
  const learnModuleThemeNames = array2.map(theme => theme.name);

  // Find the different values
  const differentValues = entityThemeNames.filter(name => !learnModuleThemeNames.includes(name));

  return differentValues[differentValues.length - 1];
}

const publishLearnModule = async () => {
  if (!learnModule.value.piece_id) {
    warningClass.value = "!border !border-[#FF5656]/100"

    setTimeout(() => {
      document.getElementById('module-evaluation').scrollIntoView({behavior: 'smooth'})
    }, 100)

    snackbarMethod('Evaluation type must be set.')
    return null
  }

  try {
    await publishModule(learnModule?.value?.id)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module published!')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
    if (route.query?.training_id && route.query?.training_id != 'NaN') {
      await fetchTraining(route.query?.training_id)
      const list = learnTraining.value?.learn_contents
      list.push({
        contentable_id: learnModule?.value?.id,
        contentable_type: "Learn::Module"
      })
      await updateModuleAndPlaylistData(list)
      router.push({name: "training_edit", params: {id: learnTraining.value.id}})
    } else {
      router.push({name: "module_show", params: {id: learnModule?.value?.id}})
    }
  } catch (error) {
    snackbarMethod('Error publishing module!')
  }
}

const openDialogDuplicateModule = () => {
  duplicateModuleDialog.value.dialogDuplicateModule = true
}

const openDialogDeleteModude = () => {
  deleteModuleDialog.value.dialogDeleteModule = true
}

const changeVariant = () => {
  if (variant.value === 'plain') {
    variant.value = 'outlined'
  } else {
    variant.value = 'plain'
  }
}

const updateLearnModuleTitle = debounce(async () => {
  try {
    await updateModule({
      id: learnModule?.value?.id,
      title: title.value
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}, 300)

const updateLearnModuleDurationTime = debounce(async () => {
  try {
    await updateModule({
      id: learnModule?.value?.id,
      title: title.value,
      duration: (time.value + ' ' + timeUnit.value)
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}, 300)

const updateLearnModuleDurationTimeUnit = debounce(async () => {
  try {
    await updateModule({
      id: learnModule?.value?.id,
      title: title?.value,
      duration: ((time?.value !== learnModule?.value?.duration?.split(' ')?.[0] ?
          time.value : learnModule?.value?.duration?.split(' ')?.[0]) + ' ' + timeUnit?.value)
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}, 300)

const uploadImage = async (blob) => {
  try {
    await updateModuleImage(route?.params?.id, blob)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module image changed successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error uploading module image')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}

const snackbarMethod = async (text) => {
  snackbar.setBgColor('negativeRed')
  snackbar.setMsg(text)
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
  snackbar.displaySnackBar()
}

const addQuizQuestionBlock = debounce(async() => {
  const data = {
    proposals: [{proposal: "", correct: false}],
    title: null,
    type: LearnInputType.CHECKBOX,
  }
  if (learnModule?.value?.piece_id) {
    try {
      await postInputs(learnModule.value?.piece_id, data);
    } catch (error) {
     console.log(error) ;
    }
  }
}, 200);

const addFaceToFaceOpenQuestionBlock = debounce(async() => {
  const data = {
    title: null,
    description: null,
  }
  if (learnModule?.value?.piece_id) {
    try {
      await postInputsFaceToFace(learnModule?.value?.piece_id, data)
    } catch (error) {
      console.log(error);
    }
  }
}, 200);

const addQuizFreeContentBlock = debounce(async(faceToFace = false) => {
  const data = {
    title: '',
    type: LearnInputType.PARAGRAPH,
  }
  if (learnModule?.value?.learn_pieces_quiz?.id) {
    try {
      await postParagraph(learnModule?.value?.learn_pieces_quiz?.id, data)
    } catch (error) {
      console.log(error);
    }
  }
  if (learnModule?.value?.learn_pieces_face_to_face_evaluation?.id) {
    try {
      await postParagraphFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, data);
    } catch (error) {
      console.log(error);
    }
  }
}, 200)

const duplicateLearnModule = async (title) => {
  try {
    const duplicated = await duplicateModule(learnModule?.value?.id, title)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module duplicated')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    duplicateModuleDialog.value.dialogDuplicateModule = false

    setTimeout(() => {
      router.push({name: 'module_show', params: {id: duplicated.id}})
    }, 200);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error duplicating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deleteLearnModule = async () => {
  try {
    await deleteModule(learnModule?.value?.id)

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Your module has been deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    deleteModuleDialog.value.dialogDeleteModule = false

    setTimeout(() => {
      router.push({name: 'catalog'})
    }, 200);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const getListAfterDrag = async () => {
  const inputs_copy = inputs.value
  inputs.value = []
  for (const index in inputs_copy) {
    await updateInputs(learnModule.value?.learn_pieces_quiz?.id, inputs_copy[index].id, {position: parseInt(index) + 1})
  }
  inputs.value = inputs_copy
  isDragged.value = false
};

const getListAfterDragFaceToFace = async (list) => {
  const inputs_face_to_face_copy = inputsFaceToFace.value
  inputsFaceToFace.value = []
  for (const index in inputs_face_to_face_copy) {
    await updateInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id, inputs_face_to_face_copy[index].id, {position: parseInt(index) + 1})
  }
  inputsFaceToFace.value = inputs_face_to_face_copy
  isDragged.value = false
};

const updateInputQuestion = debounce(async () => {
    const subscribeOptions =
    {
      channel: "Learn::InputChannel",
      piece_id: learnModule?.value?.submission_type === 'Learn::Pieces::Quiz' ?
        learnModule.value?.learn_pieces_quiz?.id : learnModule?.value?.learn_pieces_face_to_face_evaluation?.id
    }

  inputChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === "update") {

        switch (data.entity.type) {
          case LearnInputType.CHECKBOX:
            const index = inputs.value.findIndex(x => x.id === data.entity.id);

            inputs.value[index].title = data.entity.title
            inputs.value[index].proposals = data.entity.proposals
            inputs.value[index].position = data.entity.position
            break;
          case LearnInputType.OPEN:
            const indexFaceToFace = inputsFaceToFace.value.findIndex(x => x.id === data.entity.id);

            inputsFaceToFace.value[indexFaceToFace].title = data.entity.title
            inputsFaceToFace.value[indexFaceToFace].description = data.entity.description
            inputsFaceToFace.value[indexFaceToFace].position = data.entity.position
          break;
        }
      }
    },

    update: async function (event) {
      let data = {};

      switch (event?.type) {
        case LearnInputType.PARAGRAPH:
          data = {}
          break;
        case LearnInputType.CHECKBOX:
          data = {
            input_id: event?.id,
            title: event?.title,
            proposals: event?.proposals,
          }
          break;
        case LearnInputType.OPEN:
          data = {
            input_id: event?.id,
            title: event?.title,
            description: event?.description,
          }
          break;
      }

      inputChannel.value.perform('update', data);
    },
  });
}, 300)

const updateEditorContent = debounce(async () => {
  const subscribeOptions =
    {
      channel: "EditorContentChannel", id: learnModule.value.editor_content_id
    }

  editorContentChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === "update" && data.current_user.id !== userId.value && learnModule.value.editor_content_id === data.editor_content_id) {
        addToast(
            'info',
            i18n.global.t(`This module has just been updated !`),
            i18n.global.t(`The lastest version of this content will be visible if you reload this page.`),
            false,
            {
              name: i18n.global.t(`Reload this page`),
              link: '/learns/module/' + learnModule.value.id + '/edit'
            }
        )
      } else {
        editorContentData.value.blocks = data.entity.blocks
      }
    },

    update: async function (event) {
      const data = {
        blocks: event
      }

      editorContentChannel.value.perform('update', { data });
    },
  });
}, 100)

const debounceEditorContentUpdate = debounce(e => editorContentChannel?.value?.update(e), 300)

const removeOption = debounce(async (data) => {
  data.input.proposals.splice(data.index, 1);
  inputChannel.value?.update(data.input);
}, 200);

const removeInput = debounce(async (input) => {
  try {
    await deleteInputQuestion(learnModule?.value?.learn_pieces_quiz?.id, input.id)
  } catch (error) {
    console.log(error)
  }
}, 200);

const removeFreeContentFaceToFace = debounce(async (input) => {
  try {
    await deleteOpenQuestion(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, input.id)
  } catch (error) {
    console.log(error)
  }
}, 200);

const removeOpenQuestion = debounce(async (input) => {
  try {
    await deleteOpenQuestion(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, input?.id)
  } catch (error) {
    console.log(error)
  }
}, 200)

const duplicateOption = debounce(async (input) => {
  try {
    await postInputs(learnModule?.value?.learn_pieces_quiz?.id, input)
  } catch (error) {
    console.log(error)
  }
}, 200);

const duplicateOpenQuestion = debounce(async (input) => {
  try {
    await postInputsFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, input)
  } catch (error) {
    console.log(error)
  }
}, 200);

const addOption = debounce((input) => {
  input.proposals.push({
    correct: false,
    proposal: ""
  })
  inputChannel.value?.update(input)
}, 200);

const changeEvaluationType = debounce(async(type) => {
  if (`Learn::Pieces::${type}` !== learnModule?.value?.submission_type) {
    try {
      await changeSubmissionPieceType(type);
      const hasCheckboxQuestion = learnModule.value.learn_pieces_quiz?.has_custom_inputs
      const hasOpenQuestion = learnModule.value.learn_pieces_face_to_face_evaluation?.has_custom_inputs

      if (type === 'Quiz') {
        inputsFaceToFace.value = []
        if (hasCheckboxQuestion) {
          await fetchInputs(learnModule?.value?.piece_id)
        } else {
          addQuizQuestionBlock();
        }
      } else if (type === 'FaceToFaceEvaluation') {
        inputs.value = []
        if (hasOpenQuestion) {
          await fetchInputsFaceToFace(learnModule?.value?.piece_id)
        } else {
          addFaceToFaceOpenQuestionBlock();
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  updateInputQuestion();
}, 200);

onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  editorContentChannel?.value?.unsubscribe();
})

const handleClose = () => {
  router.back()
}

const errorUploading = (msg) => {
  snackbar.setBgColor('negativeRed')
  snackbar.setMsg(msg)
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
  snackbar.displaySnackBar()
}
</script>

<style scoped>

.inputDuration :deep(.v-field__input) {
  min-height: 48px !important;
}

.input-title :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0;
}

.input-title :deep(.v-field__input) {
  border: 1px solid transparent;
  padding: 12px 0;
  max-height: 48px;
  font-size: 30px;
}

.input-title:focus-within :deep(.v-field__input) {
  padding: 12px 52px 12px 12px;
  border-radius: 8px;
}

.input-title :deep(.v-input__details) {
  padding-right: 40px;
  padding-top: 0;
  font-size: 30px;
  max-height: 0;
  min-height: 0;
}

* :deep(.v-field) {
  border-radius: 8px;
}

:deep(.v-field__clearable) {
  display: none
}

.file-selector :deep(.v-input__control) {
  display: none;
}

.file-selector :deep(.mdi-pencil-outline) {
  margin-left: 17px;
  margin-top: 3px;
}
</style>
