import axios from "axios";
import i18n from "@/plugins/i18n.js";
import {useToastStore} from "@/store/toast.js";
import useTools from "@/tools/useTools.js";

const { bktSleep } = useTools()

// just for staging testing we will rollback that

export const fetchPDFBuffer = async (endpoint, filename) => {
  const pdfUrl = import.meta.env.VITE_PDF_PROXY_URL
  return axios.post(pdfUrl, {
    localStorageData: {
      bktAccess: JSON.parse(localStorage.getItem('bktAccess'))
    },
    pdfConfig: {
      printBackground: true,
      format: 'A4',
      scale: 0.4,
      margin: {
        top: '48px',
        left: '60px',
        right: '60px',
        bottom: '48px',
      },
    },
    fileProposalName: filename,
    url: `${window.location.origin}${endpoint}`,
  })
}


export const getAndSavePDF = async(pdfUrl, fileName = 'buffer.pdf') => {
  try {
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target= '_blank'
    link.download = fileName; // Set the desired file name
    // Simulate a click on the link to trigger the download
    return link
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
}

export const downloadPdf = async (endpoint, filename) => {
  const { addToast, closeToast } = useToastStore()

  const generateId = addToast(
    'info',
    i18n.global.t('Your pdf is being generated.'),
    i18n.global.t('Wait a few seconds to download it. You will get notified when file generation is complete.'),
    true
  )
  try {
    await bktSleep(1000)
    const res = await fetchPDFBuffer(endpoint, filename)
    const link = await getAndSavePDF(res.data.url, filename);
    closeToast(generateId)
    await bktSleep(400)
    addToast(
      'success',
      i18n.global.t('Your pdf is ready.'),
      i18n.global.t('Click here to download your pdf.'),
      false,
      { name: 'Download pdf', link }
    )
  } catch (e) {
    console.error(e.message)
    closeToast(generateId)
    await bktSleep(400)
    addToast(
      'error',
      i18n.global.t('An error occurred'),
      i18n.global.t('An error occurred while downloading your pdf, please try again later'),
    )
  }
}

export const downloadPdfWithoutToast = async (endpoint, filename) => {
  try {
    const res = await fetchPDFBuffer(endpoint, filename);
    const link = await getAndSavePDF(res.data.url, filename);

    return link;
  } catch (error) {
    console.log(error);
  }
}