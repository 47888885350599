<template>
  <div :id="question.id">
    <div
      id="roadmapBlock"
      class="w-full flex flex-col gap-2 border-[0.5px] border-middle-grey bg-white rounded-[12px] p-10"
    >
      <p class="text-2xl font-semibold">
        {{ question.text }}
      </p>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />
    </div>

    <div
      v-if="targetsSuggestionsCreated?.length"
      class="w-full flex flex-col gap-2 sm:!gap-8 mt-2 sm:!mt-8"
    >
      <roadmap-block-target
        v-for="(draftTarget, idx) in targetsSuggestionsCreated"
        :key="idx"
        :target="draftTarget"
        :question="question"
        :is-draft="true"
        :employee="employee"
        @changed="fetchUpdatedTarget"
      />
    </div>

    <div
      v-if="!locked"
      class="w-full flex flex-col items-center justify-center gap-6 border-[0.5px] border-middle-grey bg-white rounded-[12px] p-10 mt-2 sm:!mt-8"
    >
      <v-icon
        color="primary"
        icon="mdi-bullseye-arrow"
        size="xxx-large"
        theme="roadmap"
      />

      <div class="w-full flex flex-col gap-2 items-center jsutify-center">
        <p class="text-2xl font-medium text-fake-black leading-normal text-center">
          {{ $t('New targets') }}
        </p>

        <p class="text-sm font-normal text-fake-black leading-5 text-center">
          {{ $t('Targets added will be created and added to the Roadmap when the Cross Review will is submitted.') }}
        </p>
      </div>

      <bkt-new-target-button
        :employees="[employee]"
        :overlay-mode="true"
        :create-draft="true"
        :question="question"
        :interview-id="id"
        class="w-fit"
      />
    </div>

    <div
      v-else
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('This interview is already submitted, so you cannot add any new targets for the moment.') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import {storeToRefs} from "pinia";
import RoadmapBlockTarget from "./RoadmapBlockTarget.vue";
import { useInterviewStore } from "@/store/interview.js";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import { ref } from 'vue'

const {
  id,
  locked,
  employee,
  isLastInterviewCompletedAndLocked,
  targetDraftHistories
} = storeToRefs(useInterviewStore())

const { fetchTargetsSuggestionsCreated } = useTargetSuggestionsStore();

const { targetsSuggestionsCreated } = storeToRefs(useTargetSuggestionsStore());

const props = defineProps(['question'])

const fetchUpdatedTarget = (newTargetData) => {
  if (newTargetData) {
    fetchDraftTargets()
  }
}

const fetchDraftTargets = async () => {
  try {
    await fetchTargetsSuggestionsCreated(employee?.value?.id, id?.value)
  } catch (e) {
    console.log("couldn't get draft targets", e)
  }
}
fetchDraftTargets()
</script>
