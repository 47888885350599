import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useTargetSuggestionsStore = defineStore('targets_suggestions', {
  state: () => ({
    ...genericStates,
    targetsSuggestionsUpdated: null,
    targetsSuggestionsCreated: null,
  }),
  actions: {
    ...genericFunctions,
    clearTargetsAndSuggestions() {
      this.targetsSuggestionsCreated = null;
      this.targetsSuggestionsUpdated = null;
    },
    async fetchTargetsSuggestionsUpdated(employeeId, campaignId, interviewId) {
      const res = await axiosService.get(`/api/v1/roadmaps/employees/${employeeId}/snapshot_targets?campaign_id=${campaignId}&interview_id=${interviewId}`)

      this.targetsSuggestionsUpdated = res?.data?.objective_elements;
      return res?.data?.objective_elements
    },
    async fetchTargetsSuggestionsCreated(employeeId, id) {
      const res = await axiosService.get(`/api/v1/roadmaps/employees/${employeeId}/target_drafts?interview_id=${id}`)

      this.targetsSuggestionsCreated = res?.data?.objective_elements;
    },
    async deleteTargetSuggestionCreated(targetDraftId) {
      await axiosService.delete(`/api/v1/roadmaps/target_drafts/${targetDraftId}`)
    }
  }
})
