<template>
  <div class="w-full flex flex-col justify-center items-start self-stretch rounded-[8px] border-[0.5px] border-lightGrey shadow">
    <!-- Training completion - Category -->
    <div class="flex flex-col justify-center items-start self-stretch p-4 gap-2 lg:!p-8 lg:border-b-2 lg:border-b-lightGrey">
      <p class="text-fakeBlack text-xl font-medium">
        {{ $t('Training completion - Module') }}
      </p>

      <p class="text-darkGrey text-sm font-normal">
        {{ $t('Compare completion by module') }}
      </p>
    </div>

    <!-- Completion Chart and legend -->
    <div class="flex items-center self-stretch px-2 py-4 lg:!w-[816px] lg:h-[300px] lg:!mx-auto">
      <!-- Chart -->
      <bkt-bar
        :data="trainingCompletionModuleData"
        :options="trainingCompletionModuleOptions"
      />
    </div>
  </div>
</template>

<script setup>
import BktBar from "@/components/chart/BktBar.vue";
import {ref} from "vue";

const props = defineProps({
  completionByModule: { type: Object, required: true },
})

const trainingCompletionModuleData = ref({
  labels: props.completionByModule.map(module => module.name),
  datasets: [
    {
      label: '',
      borderRadius: 4,
      backgroundColor: '#EF8C64',
      data: props.completionByModule.map(module => module.data),
    },
  ]
})

const trainingCompletionModuleOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      suggestedMax: props.completionByModule.map(module => module.data).sort((a, b) => b > a)[0] + 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    }
  },
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>