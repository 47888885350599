<template>
  <div class="flex items-center gap-4 sm:gap-5">
    <!-- Impersonnate -->
    <svn-icon-button
      v-if="accessLevel === 'super_admin'"
      :icon="impersonateDisplay ? 'mdi-account-eye' : 'mdi-account-eye-outline'"
      variant="hoverNoPrimary"
      color-class="!bg-transparent !text-fake-black hover:!bg-very-light-grey active:!bg-light-grey"
      color="fake-black"
      @click="$emit('display-impersonate')"
    />

    <!-- Apps -->
    <svn-dropdown v-model="appsDropdownOpened" :offset="22">
      <template #activator>
        <svn-icon-button
          icon="material-symbols:apps"
          variant="hoverNoPrimary"
          color-class="!text-fake-black hover:!bg-very-light-grey active:!bg-light-grey"
          :class="'hidden sm:flex' + (appsDropdownOpened ? ' !bg-very-light-grey' : '')"
        />
      </template>

      <template #dropdown>
        <div class="grid grid-cols-2 p-4 gap-2 rounded-[8px] bg-white !shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]">
          <router-link
            v-for="(app, idx) in applicationsFiltered"
            :key="idx"
            :to="{ name: app.linkName, params: app.linkName === 'people_show' ? { id: currentUserId } : null }"
          >
            <div class="flex flex-col hover:bg-very-light-grey gap-1 rounded items-center justify-center text-center relative w-24 h-20">
              <div>
                <Icon
                  :icon="app.icon"
                  height="24"
                  width="24"
                  :class="`text-[${app.iconColor}]`"
                />
              </div>
  
              <svn-text base normal>
                {{ $t(app.appName) }}
              </svn-text>
  
              <div v-if="app.beta" class="flex items-center justify-center p-1 rounded-[3px] border border-fake-black bg-very-light-grey absolute right-0 top-0">
                <svn-text xxs medium>
                  {{ "Beta" }}
                </svn-text>
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </svn-dropdown>

    <!-- Profile Dropdown -->
    <svn-dropdown :offset="22">
      <template #activator>
        <div
          id="profile_dropdown"
          class="items-center h-12 border border-dark-grey rounded p-2 gap-2 cursor-pointer hidden sm:flex"
        >
          <div class="w-full h-8 p-1">
            <img
              :src="logo"
              alt=""
              class="w-full h-full"
              style="max-width: 150px"
            >
          </div>

          <svn-avatar
            :avatar="avatar"
            :firstname="firstname"
            :lastname="lastname"
            size="md"
          />
        </div>

        <div class="sm:hidden">
          <svn-avatar
            :avatar="avatar"
            :firstname="firstname"
            :lastname="lastname"
            size="lg"
          />
        </div>
      </template>

      <template #dropdown>
        <div class="w-full flex flex-col py-4 gap-2 rounded-[8px] !shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]">
          <div class="flex flex-col gap-1 px-4">
            <!-- User Firstname & Lastname -->
            <svn-text base medium>
              <span class="text-xl uppercase">
                {{ lastname }}
              </span>

              {{ firstname }}
            </svn-text>

            <!-- User email -->
            <svn-text sm regular>
              {{ email }}
            </svn-text>
          </div>

          <v-divider
            color="black"
            class="opacity-100 mx-4"
          />

          <!-- Buttons -->
          <div class="flex flex-col">
            <!-- Profile -->
            <v-list-item value="profile" @click="goToProfile">
              <div class="flex justify-start items-center gap-2">
                <div>
                  <Icon
                    icon="mdi-person-outline"
                    width="16"
                    height="16"
                    class="text-fakeBlack"
                  />
                </div>

                <svn-text sm regular>
                  {{ $t("Profile") }}
                </svn-text>
              </div>
            </v-list-item>

            <!-- Company -->
            <v-list-item v-if="isAdminOrAbove()" value="company" @click="goToCompanyShow">
              <div class="flex justify-start items-center gap-2">
                <div>
                  <Icon
                    icon="mingcute:building-2-line"
                    width="16"
                    height="16"
                    class="text-fakeBlack"
                  />
                </div>

                <svn-text sm regular>
                  {{ $t("Company") }}
                </svn-text>
              </div>
            </v-list-item>

            <!-- Recommend Aleph -->
            <dialog-recommend-aleph>
              <template #button>
                <v-list-item value="recommend">
                  <div class="flex justify-start items-center gap-2">
                    <div>
                      <Icon
                        icon="ic:baseline-star-border"
                        width="16"
                        height="16"
                        class="text-fakeBlack"
                      />
                    </div>

                    <svn-text sm regular>
                      {{ $t("Recommend Aleph") }}
                    </svn-text>
                  </div>
                </v-list-item>
              </template>
            </dialog-recommend-aleph>

            <!-- Help & Support -->
            <dialog-help-and-support>
              <template #button>
                <v-list-item value="help_and_support">
                  <div class="flex justify-start items-center gap-2">
                    <div>
                      <Icon
                        icon="mdi:help-circle-outline"
                        width="16"
                        height="16"
                        class="text-fakeBlack"
                      />
                    </div>
    
                    <svn-text sm regular>
                      {{ $t("Help & support") }}
                    </svn-text>
                  </div>
                </v-list-item>
              </template>
            </dialog-help-and-support>

            <!-- Log out -->
            <v-list-item value="logout" @click="axiosService.logout()">
              <div class="flex justify-start items-center gap-2">
                <div>
                  <Icon
                    icon="mdi:logout"
                    width="16"
                    height="16"
                    class="text-fakeBlack"
                  />
                </div>

                <svn-text sm regular>
                  {{ $t("Logout") }}
                </svn-text>
              </div>
            </v-list-item>
          </div>
        </div>
      </template>
    </svn-dropdown>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import { Icon } from "@iconify/vue/dist/iconify.js";
import { useUserStore } from "@/store/user.js";
import { storeToRefs } from "pinia";
import DialogRecommendAleph from "../BktPopUp/Dialogs/DialogRecommendAleph.vue";
import DialogHelpAndSupport from "../BktPopUp/Dialogs/DialogHelpAndSupport.vue";
import { ref } from 'vue';

const router = useRouter();
const { isAdminOrAbove } = useUserStore()

const { id: currentUserId } = storeToRefs(useUserStore());

const props = defineProps({
  id: { type: Number, default: null },
  firstname: { type: String, default: null },
  lastname: { type: String, default: null },
  email: { type: String, default: null },
  avatar: { type: String, default: null },
  accessLevel: { type: String, default: null },
  logo: { type: String, default: null },
  name: { type: String, default: null },
  applicationsFiltered: { type: Array, default: () => [] },
  impersonateDisplay: { type: Boolean, default: false },
});

const appsDropdownOpened = ref(false);

const goToProfile = async () => {
  const routeData = router.resolve({ name: "people_show", params: { id: props.id }, query: { page: 'interviews' } });
  window.open(routeData.href, '_blank')
};

const goToCompanyShow = () => {
  router.push({ name: "company", query: { tab: 'information' } })
}

</script>

<style lang="scss" scoped>
.profile-box {
  height: 3rem !important;
  border: black 1px solid !important;
}

* :deep(.v-list-item--density-default.v-list-item--one-line) {
  min-height: 40px !important;
}
</style>
