<template>
  <svn-floating-action-button
    v-if="isMobile"
    class="fixed bottom-[104px] right-8"
    icon="material-symbols:download"
    :loading="loading"
    mini
    square
    @click="$emit('onClick', $event)"
  >
    <template #loading>
      <svn-loader loading-size="md"/>
    </template>
  </svn-floating-action-button>

  <svn-button
    v-else
    :prepend-icon="'material-symbols:download'"
    :text="loading ? $t(loadingText) : $t(title)"
    variant="plain"
    button-size="md"
    :loading="loading"
    @click="$emit('onClick', $event)"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  loading: {type: Boolean, default: false},
  title: {type: String, required: true},
  loadingText: {type: String, required: true},
});
defineEmits(['onClick'])
</script>