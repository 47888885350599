<template>
  <div class="flex flex-col p-5 lg:p-0 w-full lg:!max-w-[928px] xl:!max-w-[1136px] 2xl:pt-10 mx-auto h-full relative gap-8 self-center">
    <div class="flex flex-col w-full gap-6 lg:gap-0 2xl:gap-6">
      <svn-hello-banner
        app="home"
        class="lg:py-6"
        :firstname="$t('Hello,', { firstname: firstname })"
        :applications="applications"
        :text-chip-first="modules_acquired > 1 ? $t('modules_acquired_count', { count: modules_acquired }) : $t('modules_acquired_no_count', { count: modules_acquired })"
        :text-chip-third="interviews_submitted > 1 ? $t('interviews_submitted_count', { count: interviews_submitted }) : $t('interviews_submitted_no_count', { count: interviews_submitted })"
        :text-chip-second="current_targets > 1 ? $t('current_targets_count', { count: current_targets }) : $t('current_targets_no_count', { count: current_targets })"
      />

      <div class="w-full h-full flex flex-col gap-6 pb-16">
        <div class="flex gap-4 h-[120px] lg:h-[230px] desktop:h-[272px] w-full">
          <div
            :style="{'background-image': `url('${homeBanner?.['1000']}')`, 'background-size': 'cover'} "
            class="w-full h-[128px] lg:h-[230px] desktop:h-[272px] p-10 bg-neutral-100 !rounded-xl bg-center flex items-center justify-center relative overflow-hidden"
          />

        </div>
  
        <div class="flex flex-col lg:flex-row gap-4 justify-center">
          <app-card
            v-for="(app, idx) in applicationsFiltered"
            :key="idx"
            :app="app"
            class="w-full lg:w-1/3"
          />
        </div>

        <information-card
          v-if="links?.length"
          :company-id="id"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import AppCard from "@/components/home/AppCard.vue";
import { computed } from "vue";
import InformationCard from "@/components/home/InformationCard.vue";

const { applications, homeBanner, id, links } = storeToRefs(useCompanyStore());
const { firstname, interviews_submitted, modules_acquired, current_targets } = storeToRefs(useUserStore());

const appsConfigs = [
  {
    name: "interviews",
    displayName: "Interview",
    icon: "mingcute:chat-3-line",
    text: "Annual reviews, onboardings, discovery reports and many more are all processed here.",
    subText: "Go to Interview",
    color: "#3177B7",
    linkName: "my_interviews",
  },
  {
    name: "trainings",
    displayName: "Learn",
    icon: "mingcute:mortarboard-line",
    text: "Professional trainings, skill upgrades, and all kinds of contents in a single app.",
    subText: "Go to learn",
    color: "#EF8C64",
    linkName: "my_learnings",
    beta: true,
  },
  {
    name: "objectives",
    displayName: "Roadmap",
    icon: "mingcute:target-line",
    text: "View, create and update all your targets and team targets. Follow their completion here.",
    subText: "Go to Roadmap",
    color: "#5C95FF",
    linkName: "my_roadmaps",
  },
];

const applicationsFiltered = computed(() => {
  return appsConfigs.filter((app) => applications.value.includes(app.name));
});
</script>