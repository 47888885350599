<template>
  <v-window-item
    value="draft"
  >
    <v-fade-transition>
      <!-- Skeleton loaders -->
      <div
        v-if="loading"
        class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-start self-stretch
          sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <skeleton-loader-module-card
          v-for="item in 8"
          :key="item"
        />
      </div>

      <!-- Finished loading -->
      <div
        v-else
        class="flex flex-col mx-4 sm:!mx-6 lg:!mx-12 2xl:!mx-0"
      >
        <!-- Search & Select -->
        <div class="w-full flex flex-row gap-4 pb-1 pt-6 self-stretch xl:!px-0">
          <!-- Search -->
          <v-text-field
            v-model="searchTermModules"
            variant="outlined"
            density="comfortable"
            class="input"
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            @input="search"
          />

          <!-- Sort & Filter -->
          <div class="flex gap-2 xl:!gap-4">
            <!-- Filter -->
            <v-menu
              :close-on-content-click="false"
              location="bottom"
              :content-class="themes?.length === 0 ? 'w-[320px] xl:!w-[464px] h-[228px]' :
                themes?.length === themes?.length ?
                  'w-[320px] xl:!w-[464px] h-[416px]' : 'w-[320px] xl:!w-[464px] h-fit'"
            >
              <!-- Menu activator -->
              <template #activator="{ props }">
                <div
                  v-bind="props"
                  class="flex justify-center items-center w-[52px] h-[52px] px-4
                        border border-darkGrey rounded cursor-pointer"
                >
                  <div class="flex justify-center items-center self-stretch">
                    <Icon
                      icon="mdi:filter-variant"
                      height="16"
                      width="16"
                    />
                  </div>
                </div>
              </template>

              <!-- List -->
              <v-list class="!py-0 !rounded-[8px] !border !border-fakeBlack">
                <template #default>
                  <div
                    class="flex flex-col"
                  >
                    <!-- Searchbar -->
                    <div
                      class="flex flex-col gap-2"
                    >
                      <v-text-field
                        v-model="filteredThemeSearchModules"
                        prepend-inner-icon="mdi-magnify"
                        variant="outlined"
                        density="comfortable"
                        class="px-4 pt-4"
                        :placeholder="$t('Search')"
                        hide-details
                        @input="searchThemes"
                      />

                      <p class="text-middleGrey text-base font-normal pl-6">
                        {{ $t('Themes') }}
                      </p>
                    </div>

                    <!-- Theme list -->
                    <v-list-item
                      v-for="(item, index) in themes"
                      :key="index"
                      class="!p-0 cursor-pointer"
                      :class="selectedThemesListModules?.find(theme => theme?.id === item?.id) !== undefined ?
                        'bg-veryLightGrey' : ''"
                      @click.stop="toggleThemeSelection(item)"
                    >
                      <template #default>
                        <div class="flex h-10 pl-10 pr-2 gap-2 items-center">
                          <Icon
                            :icon="selectedThemesListModules?.find(theme => theme?.id === item?.id) !== undefined ?
                              'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                            color="darkGrey"
                            width="18"
                            height="18"
                          />

                          <div class="w-full flex items-center">
                            <p class="text-darkGrey text-base font-normal">
                              {{ item?.name }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-list-item>

                    <!-- No theme to display -->
                    <div
                      v-if="themes?.length === 0"
                      class="w-full h-[120px] flex justify-center items-center self-stretch p-2"
                    >
                      <p class="text-middleGrey text-base font-normal">
                        {{ $t('No theme to display') }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-list>
            </v-menu>

            <!-- Settings on all: icon mdi-dots-vertical -->
            <!-- <bkt-learn-list-settings
              :content-values="settingsOnAllItems"
            /> -->
          </div>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesListModules?.length > 0"
          class="flex justify-between items-start self-stretch px-4 pb-6 xl:!px-0 xl:flex-wrap"
        >
          <!-- Themes list -->
          <div class="flex items-start content-start gap-2 flex-1 flex-wrap">
            <div
              v-for="(theme, index) in selectedThemesListModules"
              :key="index"
              class="flex justify-center items-center rounded-[8px]
                    bg-trainingOrangeSoft !border-[0.5px] !border-[#FEF4F0]"
            >
              <div class="flex justify-center items-center px-2 py-[6px] gap-2">
                <p class="text-fakeBlack text-xs font-normal">
                  {{ theme?.name }}
                </p>

                <div
                  class="cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                >
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-darkGrey"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Clear all themes selected -->
          <div
            v-if="selectedThemesListModules?.length > 1"
            class="flex justify-center items-center px-4 py-[10px]"
            @click="clearAllSelectedThemes"
          >
            <p class="text-xs text-trainingOrange font-medium cursor-pointer">
              {{ $t('Clear all') }}
            </p>
          </div>
        </div>

        <!-- Modules card list -->
        <v-infinite-scroll
          :items="learnModulesDraft"
          class="overflow-hidden"
          color="primary"
          @load="loadMoreData"
        >
          <div
            class="w-full grid grid-cols-1 my-4 gap-4 justify-center items-start self-stretch
            sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            <module-card
              v-for="(module) in learnModulesDraft"
              :id="module?.id"
              :key="module?.id"
              :module-creator-id="module?.creator_id"
              :background-image="module?.cover_url?.['500']"
              :is-favorite="module?.connected_user_favorited"
              :updated-at="filters.formatDate(module?.updated_at, 'DD/MM/YYYY')"
              :reactions="module?.user_reactions"
              :duration="module?.duration"
              :title="module?.title"
              :is-draft="true"
              :theme-list="module?.themes"
              @toggle-favorite="toggleModuleFavorite(module?.id, module?.status)"
              @duplicate-module="toggleDuplicateModule(module?.id)"
              @module-show="goToModuleShowEdit(module?.id, module?.title)"
              @delete-module="openDialogDeleteModule(module?.id, module?.learn_trainings)"
            />
          </div>

          <template #empty />
        </v-infinite-scroll>

        <!-- No module found after search -->
        <div
          v-if="!learnModulesDraft?.length && searchTermModules.length > 0"
          class="flex flex-col text-center justify-center items-center gap-4 px-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:face-with-monocle"
            height="32"
            width="32"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{ $t("Hmm, it seems we can’t find any results based on your search.") }}
          </p>
        </div>

        <!-- No module in index -->
        <div
          v-if="learnModulesDraft?.length === 0 && searchTermModules.length === 0"
          class="flex flex-col text-center justify-center items-center gap-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:eyes"
            height="24"
            width="24"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{ $t("There are no modules. Create your first module !") }}
          </p>
        </div>
      </div>
    </v-fade-transition>

    <!-- Dialog duplicate module -->
    <dialog-duplicate-module
      ref="duplicateModuleDialog"
      @duplicate-module="duplicateLearnModule"
    />
  
    <!-- Dialog delete module -->
    <dialog-delete-module
      ref="dialogDeleteModule"
      :trainings="moduleToDeleteTraining"
      @delete-module="deleteLearnModule"
    />
  </v-window-item>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from "pinia";
import { ref, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { useLearnThemesStore } from '@/store/learn-themes';
import { useLearnModuleStore } from "@/store/learn-module.js";
import DialogDuplicateModule from '@/components/BktPopUp/Dialogs/learn/DialogDuplicateModule.vue';
import DialogDeleteModule from "@/components/BktPopUp/Dialogs/learn/DialogDeleteModule.vue";
import filters from '@/tools/filters';
import ModuleCard from "@/components/trainingApp/ModuleCard.vue";
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import SkeletonLoaderModuleCard from '@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue';
import { debounce } from 'lodash';
import router from '@/router';
import i18n from '@/plugins/i18n';

const props = defineProps({
  themes: { type: Array, default: null },
})

onMounted(async() => {
  try {
    await fetchModulesDraft().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnModulesDraft.value = res.data.learn_modules
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
})

const searchTermModules = ref('')

const page = ref(1)

const totalPages = ref(null)

const learnModulesDraft = ref([])

const loading = ref(true)

const filteredThemeSearchModules = ref('')

const selectedThemesListModules = ref([])

const snackbar = useSnackbar()

const { fetchThemes } = useLearnThemesStore()

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const { fetchModulesDraft, toggleFavorite, duplicateModule, deleteModule } = useLearnModuleStore();

const { isMobile } = storeToRefs(useMobileStore())

const idModuleToDelete = ref(null)

const idModuleToDuplicate = ref(null)

const duplicateModuleDialog = ref(false)

const dialogDeleteModule = ref(false)

const moduleToDeleteTraining = ref(null)

const toggleDuplicateModule = (id) => {
  idModuleToDuplicate.value = id
  duplicateModuleDialog.value.dialogDuplicateModule = true
}

const toggleThemeSelection = debounce(async(item) => {
  const found = selectedThemesListModules?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesListModules?.value?.splice(selectedThemesListModules?.value?.findIndex((theme) =>
      theme?.id === item?.id), 1);
  }
  else {
    selectedThemesListModules?.value?.push(item);
  }
  search()
}, 200)

const toggleModuleFavorite = debounce(async(moduleId) => {
  try {
    await toggleFavorite(moduleId, "draft")
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error toggling favorite')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}, 200)

const duplicateLearnModule = async(title) => {
  try {
    const duplicated = await duplicateModule(idModuleToDuplicate.value, title)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module duplicated')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    idModuleToDuplicate.value = null
    duplicateModuleDialog.value.dialogDuplicateModule = false

    setTimeout(() => {
      router.push({ name: 'module_edit', params: { id: duplicated.id } })
    }, 200);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error duplicating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const openDialogDeleteModule = (id, trainings = null) => {
  dialogDeleteModule.value.dialogDeleteModule = true
  idModuleToDelete.value = id
  moduleToDeleteTraining.value = trainings
}

const deleteLearnModule = async() => {
  try {
    await deleteModule(idModuleToDelete?.value)

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('This module has been deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    dialogDeleteModule.value.dialogDeleteModule = false

    await fetchModulesDraft().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnModulesDraft.value = res.data.learn_modules
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
  idModuleToDelete.value = null
}

const goToModuleShowEdit = (id, title) => {
  updateBreadcrumbs(title)
  router.push({ name: 'module_edit', params: { id: id } })
}

const clearAllSelectedThemes = () => {
  selectedThemesListModules.value = [];
}

const searchThemes = async() => {
  try {
    await fetchThemes(filteredThemeSearchModules.value)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching themes')
    snackbar.displaySnackBar()
  }
}

const search = async() => {
  try {
    const res = await fetchModulesDraft(searchTermModules.value,
      selectedThemesListModules?.value?.map(theme => theme?.id))
      learnModulesDraft.value = res.data.learn_modules
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
}

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/catalog')
  update()
}

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      const res = await fetchModulesDraft(searchTermModules.value,
        selectedThemesListModules?.value?.map(theme => theme?.id), page.value + 1)

        if (res.data.learn_modules.length) {
          learnModulesDraft.value = [...learnModulesDraft.value, ...res.data.learn_modules]
        }
        done('ok')
        page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>

<style scoped>
.skeleton :deep(.v-skeleton-loader__bone) {
  margin-left: 0;
  margin-right: 0;
}

.table :deep(.v-table__wrapper td) {
  padding-left: 0;
  padding-right: 0;
}

.table :deep(.v-table__wrapper) {
  overflow: visible !important;
}
</style>