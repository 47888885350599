<template>
  <div
    class="w-full flex flex-col items-center mx-auto
      sm:w-4/5
      lg:w-[52rem]"
  >
    <svn-hello-banner
      app="interview"
      :firstname="firstname"
      :url="myInterviewsBanner?.['500']"
      :subtitle="$t('Here are your interviews as interviewee.')"
    />

    <v-tabs
      v-model="tab"
      class="w-full sticky top-[75px] z-20 bg-background border-b mb-2"
      :grow="isMobile"
    >
      <v-tab class="normal-case flex flex-row">
        {{ $t('All') }}
        <div class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm">
          <span class="text-xs">{{ interviewsMeta?.total_count || 0 }}</span>
        </div>
      </v-tab>
      <v-tab class="normal-case flex flex-row">
        {{ $t('To do') }}
        <div class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm">
          <span class="text-xs">{{ interviewsToDoMeta?.total_count || 0 }}</span>
        </div>
      </v-tab>
      <v-tab class="normal-case flex flex-row">
        {{ $t('Done') }}
        <div class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm">
          <span class="text-xs">{{ interviewsDoneMeta?.total_count || 0 }}</span>
        </div>
      </v-tab>
    </v-tabs>
    <v-window
      v-model="tab"
      class="w-[102%] px-2"
    >
      <v-window-item
        class="w-full"
        :value="0"
      >
        <MyInterviewList
          v-if="interviews"
          :interviews="interviews"
          :total-pages="interviewsMeta?.total_pages"
          :current-page="interviewsMeta?.current_page"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[0]"
          class="mt-16 max-w-md mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
      <v-window-item
        class="w-full"
        :value="1"
      >
        <MyInterviewList
          v-if="interviewsToDo"
          :interviews="interviewsToDo"
          :total-pages="interviewsToDoMeta?.total_pages"
          :current-page="interviewsToDoMeta?.current_page"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[1]"
          class="mt-16 max-w-sm mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
      <v-window-item
        class="w-full"
        :value="2"
      >
        <MyInterviewList
          v-if="interviewsDone"
          :interviews="interviewsDone"
          :total-pages="interviewsDoneMeta?.total_pages"
          :current-page="interviewsDoneMeta?.current_page"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[2]"
          class="mt-16 max-w-sm mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
    </v-window>
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>

<script setup>
  import {onMounted, ref} from "vue";
  import { storeToRefs } from "pinia";
  import { useMyInterviewsStore } from "@/store/my-interviews.js";
  import { useCompanyStore } from "@/store/company.js";
  import { useUserStore } from "@/store/user.js";
  import {useMobileStore} from "@/store/mobile.js";
  import MyInterviewList from "@/components/interviewApp/MyInterview/MyInterviewList.vue";
  import BktFailToLoad from "@/components/BktFailToLoad.vue";

  // Refs
  const { myInterviewsBanner } = storeToRefs(useCompanyStore());
  const { firstname, lastname, id, avatar } = storeToRefs(useUserStore());
  const {
    interviews,
    interviewsMeta,
    interviewsToDo,
    interviewsToDoMeta,
    interviewsDone,
    interviewsDoneMeta
  } = storeToRefs(useMyInterviewsStore())
  const alignTop = ref(false)
  const {isMobile} = storeToRefs(useMobileStore())
  const tab = ref(0)
  const pages = ref([1, 1, 1])
  const error = ref([false, false,false])

  // Functions
  const { fetchMyInterviews, fetchMyInterviewsToDo, fetchMyInterviewsDone } = useMyInterviewsStore();
  const tabs = [ fetchMyInterviews, fetchMyInterviewsToDo, fetchMyInterviewsDone ]

  const load = async (done) => {
    pages.value[tab.value] += 1
    done(await tabs[tab.value](pages.value[tab.value]))
  }

  // Execute

  onMounted(async () => {
    interviews.value = []
    interviewsToDo.value = []
    interviewsDone.value = []
    if (await fetchMyInterviews(1) === "error")
      error.value[0] = true
    if (await fetchMyInterviewsToDo(1) === "error")
      error.value[1] = true
    if (await fetchMyInterviewsDone(1) === "error")
      error.value[2] = true
  })

</script>
