<template>
  <div
    class="flex items-center gap-1 self-stretch cursor-pointer sm:px-2"
    :class="isManagerCreatorOrAbove() && !isDeleted ? '' : 'my-3'"
  >
    <!-- Drag Icon -->
    <div
      v-if="withDragIcon"
      class="cursor-pointer"
    >
      <Icon
        icon="ic:baseline-drag-indicator"
        height="16"
        width="16"
        class="text-middleGrey"
      />
    </div>

    <!-- Module Card -->
    <div
      class="w-full flex p-4 items-start gap-3 rounded-[12px]"
      :class="isAcquired ?
        !isMobile ?
          '!border !border-[#7BC079] h-[120px]' : '!border !border-[#7BC079] h-[154px]'
        : !isMobile ?
          'border border-[#CDCDCD] h-[120px]' : 'border border-[#CDCDCD] h-[154px]'"
      @click="goToModule()"
    >
      <!-- Card image -->
      <div
        v-if="isMobile"
        class="!w-[120px] !h-[122px]"
      >
        <img
          :src="backgroundImage"
          class="rounded"
          draggable="false"
          :class="isAcquired ? 'grayscale' : 'grayscale-0'"
          style="width: 120px; height: 122px; object-fit: cover;"
        >
      </div>

      <div
        v-if="!isMobile"
        class="w-[112px] h-[88px]"
      >
        <img
          :src="backgroundImage"
          class="rounded"
          draggable="false"
          :class="isAcquired ? 'grayscale' : 'grayscale-0'"
          style="width: 112px; height: 88px; object-fit: cover;"
        >
      </div>

      <div class="flex-1 flex flex-col items-start gap-4 sm:gap-4">
        <!-- Module title -->
        <div class="flex flex-col gap-1 w-full  items-start">
          <div class="flex items-start h-10 sm:h-fit gap-2 w-full">
            <p
              class="line-clamp-2 text-sm font-medium sm:!line-clamp-1 grow"
              :class="isAcquired ? 'text-darkGrey' : 'text-fakeBlack'"
            >
              {{ title }}
            </p>
            <!-- Three dots button -->
            <svn-dots-button
              :show="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id) && !isDeleted"
              button-size="sm"
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  v-if="amICreatorOfEntityOrAdmin(module?.creator_id)"
                  value="edit_module"
                  @click="editModule(id)"
                >
                  {{ $t('Edit module') }}
                </v-list-item>

                <v-list-item
                  v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
                  value="remove_from_playlist"
                  class="text-negativeRed"
                  @click="removeModuleFromPlaylist(id)"
                >
                  {{ $t('Remove module from playlist') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>

          <div class="flex justify-between items-center gap-0 self-stretch mb-0 sm:!justify-center sm:!self-auto sm:gap-6">
            <!-- Module duration -->
            <div class="flex items-center gap-1">
              <div class="flex items-center">
                <Icon
                  icon="mdi:clock-outline"
                  height="16"
                  width="16"
                  class="text-darkGrey"
                />
              </div>

              <p class="text-darkGrey text-sm font-medium">
                {{ duration || $t('No duration') }}
              </p>
            </div>

            <!-- Module updatedAt -->
            <div class="flex items-center gap-1">
              <div class="flex items-center">
                <Icon
                  icon="mdi:update"
                  height="16"
                  width="16"
                  class="text-darkGrey"
                />
              </div>

              <p class="text-darkGrey text-sm font-medium">
                {{ updatedAt.split(' ')?.[0].split('-').reverse().join('-').replace('-', '/').replace('-', '/') }}
              </p>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col gap-1 sm:!justify-between sm:flex-row">
          <!-- Themes -->
          <div
            class="flex items-center gap-x-2"
          >
            <!-- There is only 1 theme -->
            <div
              v-for="(theme) in slicedThemesList"
              :key="theme"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
              :class="isAcquired ? 'bg-veryLightGrey' : ''"
            >
              <p
                class="flex text-xs font-normal text-fakeBlack items-center"
                :class="isAcquired ? 'text-darkGrey' : ''"
              >
                {{ theme?.['name'] || 'no name' }}
              </p>
            </div>

            <!-- If there is more than 1 theme -->
            <div
              v-if="(themeList?.length - slicedThemesList?.length) > 0"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
              :class="isAcquired ? 'bg-veryLightGrey' : ''"
            >
              <p
                class="flex text-xs font-normal text-fakeBlack items-center"
                :class="isAcquired ? 'text-darkGrey' : ''"
              >
                +{{ themeList?.length - slicedThemesList?.length }}
              </p>
            </div>
          </div>

          <!-- Module acquired -->
          <div
            v-if="isAcquired"
            class="flex items-center gap-1"
          >
            <Icon
              icon="gg:check-o"
              height="16"
              width="16"
              class="text-positiveGreen"
            />

            <p class="text-positiveGreen text-sm font-medium">
              {{ $t('Acquired on') }} {{ acquisitionDate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';
import i18n from "@/plugins/i18n.js";
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useUserStore } from "@/store/user.js";
import { useLearnPlaylistStore } from '@/store/learn-playlist';

const { isManagerCreatorOrAbove, amICreatorOfEntityOrAdmin } = useUserStore();
const { learnPlaylist } = storeToRefs(useLearnPlaylistStore())

const props = defineProps ({
  id: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  withDragIcon: { type: Boolean, default: false },
  toDiscover: { type: Boolean, default: false },
  isDraft: { type: Boolean, default: false },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  duration: { type: String, default: '' },
  updatedAt: { type: String, default: '' },
  isAcquired: { type: Boolean, default: false },
  acquisitionDate: { type: String, default: '' },
  isDeleted: { type: Boolean, default: false },
  module: { type: Object, default: () => {}},
})

const emit = defineEmits(['edit-module', 'remove-module-from-playlist', 'go-to-module'])

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props?.themeList?.slice(0, 1)
  }
  else {
    return props?.themeList?.slice(0, 2)
  }
})

const { isMobile } = storeToRefs(useMobileStore())

const editModule = () => {
  emit('edit-module')
}

const goToModule = () => {
  emit('go-to-module')
}

const removeModuleFromPlaylist = () => {
  emit('remove-module-from-playlist')
}
</script>
