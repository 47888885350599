<template>
  <div class="flex flex-col justify-center items-center bg-veryLightGrey p-3 w-full sm:w-full">
    <p
      class="self-start w-full text-md mb-3 sm:mb-5"
    >
      {{ $t("Comments") }}
    </p>
    <p
      v-if="comments.concat(newComs).length === 0"
      class="text-sm text-darkGrey mb-3 sm:mb-8"
    >
      {{ $t("No comments yet") }}
    </p>
    <div
      v-else
      ref="conv"
      :key="newComs"
      class="mb-3 sm:mb-5 w-full h-fit overflow-y-auto snap-y snap-end"
      :class="isPdf ? 'pb-3' : 'max-h-[40vh]'"
    >
      <div
        v-for="com in comments.concat(newComs)"
        :key="com"
        class="w-full mb-3 flex flex-col space-y-2
          last:snap-end"
      >
        <div
          class="w-full flex flex-row items-center justify-start space-x-2 sm:mb-1"
        >
          <v-icon
            v-if="com.user?.id === answerUser?.id && isAnonymous && !isPdf"
            size="35"
            color="blue"
            class="hidden sm:block"
          >
            mdi-account
          </v-icon>

          <svn-avatar
            v-else-if="!isPdf"
            :avatar="com.user?.avatar?.[30]"
            :firstname="com.user?.firstname"
            :lastname="com.user?.lastname"
            size="md"
            class="hidden sm:flex"
          />

          <p v-if="com.user?.id === id && !isPdf">
            {{ $t("You") }}
          </p>

          <p
            v-else
            class="text-sm"
          >
            {{
              com.user?.id === answerUser?.id && isAnonymous ?
                `Participant ${currentIdx + 1}` :
                com.user?.fullname
            }}
          </p>

          <p class="text-xs sm:text-sm text-darkGrey">
            {{  moment(com.created_at).format('LLL') }}
          </p>
        </div>

        <v-card
          class="w-11/12 sm:mb-5"
          :class="isPdf ? 'pb-4 px-3' : 'p-3'"
        >
          <p class="">
            {{ com.text }}
          </p>
        </v-card>
      </div>
    </div>

    <v-divider
      v-if="!isPdf"
      thickness="1"
      class="w-full border-bkt-fakeblack-grey mb-2"
      color="black"
    />

    <div
      v-if="!isPdf"
      class="w-full py-3 flex justify-center items-center gap-4"
    >
      <svn-avatar
        :avatar="avatar"
        :firstname="firstname"
        :lastname="lastname"
        size="md"
        class="hidden sm:flex items-center"
      />

      <v-text-field
        v-model="newComment"
        :label="$t('Add a comment')"
        variant="solo"
        hide-details
        @keydown="enterAddComment"
      >
        <template #append>
          <v-btn
            variant="text"
            @click="addComment"

          >
            <v-icon
              color="primary"
              size="32"
            >
              mdi-send
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useUserStore} from "@/store/user.js";
import {storeToRefs} from "pinia";
import axios from "@/tools/axios-service.js";
import moment from "moment";

// Props
  const props = defineProps({
    isAnonymous: {type: Boolean, default: false},
    comments: {type: Array, required: true},
    answerUser: {type: Object, required: true},
    answerInterviewId: {type: Number, required: true},
    currentIdx : {type: Number, default: 1},
    isPdf: {type: Boolean, default: false},
  })

  // Store variables
  const { t } = useI18n()
  const {fetchUser} = useUserStore()

  // Refs
  const newComment = ref("")
  const {id, firstname, lastname, email, avatar } = storeToRefs(useUserStore());
  const conv = ref(null)
  const newComs = ref([])

  // Functions
  onMounted(() => {
    fetchUser()

    if(conv.value) {
      conv.value.scrollTop = conv.value.scrollHeight
    }
  })

  const addComment = async () => {
    if (newComment.value === "")
      return
    try {
      const res = await axios.post(`/api/v2/interview_app/answers/${props.answerInterviewId}/messages`, {
        text: newComment.value
      })
      if (res.status === 200 || res.status === 201) {
        newComs.value.push({...res.data, created_at: new Date().toString().split("GMT")[0]})
      }
      newComment.value = ""
    } catch (e) {
      console.log(e)
    }
  }

  const enterAddComment = (e) => {
    if (e.key === "Enter") {
      addComment()
    }
  }

</script>