<template>
  <div class="w-full flex flex-col relative bg-white">
    <!-- Desktop view -->
    <bkt-index-skeleton body-class="hidden sm:block bkt-bg-white rounded mt-20 mb-32 px-0 pt-0 sm:!pt-5 pb-0">
      <template #title>
        <p class="mb-5 hidden sm:block font-medium sm:!mb-0">
          {{ $t("Target templates") }}
        </p>
      </template>

      <template #create-index>
        <div class="flex-row justify-center hidden sm:!flex sm:!justify-end mt-5 sm:!mt-0">
          <v-btn
            prepend-icon="mdi-plus"
            color="primary"
            :to="{ name: 'roadmaps_templates_new' }"
          >
            {{ $t("New template") }}
          </v-btn>
        </div>
      </template>

      <template #body>
        <target-templates-index-search
          :roadmap-trashes-templates="roadmapTrashesTemplates"
          @fetch="reloadAfterSearch"
          @search-items="toggleSearchDeletedItems"
          @restore="restoreDeletedItems"
          @delete-permanently-template="deletePermanentlyDeletedItems"
        />

        <Table
          v-if="roadmapTemplates && roadmapTemplates.length > 0"
          @fetch-new-page="updateParamsAndFetch"
        />

        <bkt-create-entity-from-index
          v-else-if="
            roadmapTemplates && roadmapTemplates.length === 0 && !params.title
          "
          to="roadmaps_templates_new"
        >
          {{ $t("template") }}
        </bkt-create-entity-from-index>

        <bkt-no-entity-from-index
          v-else-if="roadmapTemplates && roadmapTemplates.length === 0 && params.title"
        />

        <div v-else-if="!roadmapTemplates" class="flex justify-center h-[30vh] items-center w-full">
          <svn-loader loading-size="lg" />
        </div>
      </template>
    </bkt-index-skeleton>

    <!-- Mobile view -->
    <div class="sm:hidden flex flex-col items-center mb-24">
      <p class="text-xl text-center font-semibold text-fakeBlack mt-8 mb-4">
        {{ $t('Targets templates') }}
      </p>

      <v-btn
        prepend-icon="mdi-plus"
        color="primary"
        class="mx-5 mb-8"
        :to="{ name: 'roadmaps_templates_new' }"
      >
        {{ $t("New template") }}
      </v-btn>

      <div class="w-full flex flex-col">
        <bkt-create-entity-from-index
          v-if="roadmapTemplates && roadmapTemplates.length === 0"
          to="roadmaps_templates_new"
        >
          {{ $t("template") }}
        </bkt-create-entity-from-index>

        <div
          v-for="(template, index) in roadmapTemplates"
          :key="index"
          class="px-4 pb-4 cursor-pointer"
          @click="goToTemplateShow(template.id)"
        >
          <my-roadmaps-template-card :template="template" />
        </div>
      </div>

      <!-- Scroll to top button -->
      <svn-floating-action-button
        square
        mini
        to-top
        :double-icon="true"
      />

      <!-- New target button -->
      <svn-floating-action-button
        v-if="!(roadmapTemplates && roadmapTemplates.length === 0)"
        default
        :text="$t('New template')"
        icon="mdi-plus"
        @click="goToNewTemplateCreation"
      />
    </div>
  </div>
</template>

<script setup>
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import MyRoadmapsTemplateCard from "@/components/roadmapApp/MyRoadmapsTemplateCard.vue";
import TargetTemplatesIndexSearch from "@/components/TargetTemplatesIndexSearch.vue";
import Table from "@/components/roadmapApp/template/Table.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import BktNoEntityFromIndex from "@/components/BktNoEntityFromIndex.vue";
import { storeToRefs } from "pinia";
import { useRoadmapTemplateStore } from "@/store/roadmap-template.js";
import { useRouter } from 'vue-router';
import { ref } from "vue";
import { debounce } from "lodash";
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile";

const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore())

const router = useRouter();

const alignTop = ref(false)

const { roadmapTemplates, roadmapTrashesTemplates, params } = storeToRefs(useRoadmapTemplateStore());
const { fetchRoadmapTemplates, fetchRoadmapTrashesTemplates, restoreRoadmapTemplate, deletePermanentlyRoadmapTemplate, updateParams } = useRoadmapTemplateStore();

fetchRoadmapTemplates();
fetchRoadmapTrashesTemplates();

const updateParamsAndFetch = (page) => {
  updateParams({
    title: params.title,
    "page[number]": page,
  });
  fetchRoadmapTemplates();
}

const reloadAfterSearch = (params) => {
  updateParams({
    title: params.title,
    "page[number]": 1,
  });
  fetchRoadmapTemplates();
};

const goToTemplateShow = (id) => {
  router.push({ name: 'roadmaps_templates_show', params: { id } });
}

const goToNewTemplateCreation = () => {
  router.push({ name: 'roadmaps_templates_new' })
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    updateParams({
      title: value
    })
    await fetchRoadmapTrashesTemplates()
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTemplate(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Template restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a template')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePermanentlyRoadmapTemplate(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Template deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>
* :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
