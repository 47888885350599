<template>
  <div>
    <div class="flex justify-start items-center">
      <v-list>
        <v-list-group :value="$t('Filter by tags')">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              width="250"
            >
              <svn-title
                h2
                semi-bold
                class="flex justify-start items-center text-base cursor-pointer"
              >
                {{ $t('Filter by tags') }}
                <span
                  @click="openInfo"
                >
                  <Icon
                    class="ml-3 cursor-pointer"
                    icon="akar-icons:question"
                  />
                </span>
              </svn-title >
            </v-list-item>
          </template>

          <div class="w-full">
            <v-hover>
              <template #default>
                <div class="flex flex-row">
                  <div
                    class="mr-[190px] p-3 text-base font-medium"
                  >
                    {{ $filters.capitalize(showOther.name) }}
                  </div>

                  <div
                    class="w-full flex-wrap items-center bkt-bg-light-grey9-hover px-2 py-1 rounded cursor-pointer"
                    @mouseleave="hideCatSuggestion(0)"
                    @mouseover="showCatSuggestion(0)"
                    @click.stop.self="editTagField(0)"
                  >
                    <bkt-tag
                      v-for="(tag, tag_idx) in showOther.categories"
                      :key="tag.id"
                      class="mx-2 flex flex-row items-center my-3 vertical-align-middle rounded-md"
                      :selected="tag.selected"
                      :dropdown="true"
                      @click.stop="toggleTag(0, tag_idx)"
                    >
                      {{ $filters.truncateN(tag.title) }}
                      <template #dropdown>
                        <div class="p-3 flex flex-col">
                          <v-text-field
                            v-model="tag.title"
                            class="rounded"
                            variant="outlined"
                            color="black"
                            @change="updateTag(tag.id, tag.title)"
                          />

                          <v-btn
                            class="w-full shadow-none text-red-600 hover:shadow-none focus:shadow-none align-left text-left"
                            prepend-icon="mdi-trash-can"
                            @click="dialog = true"
                          >
                            {{ $t("Delete tag") }}
                          </v-btn>

                          <v-dialog
                            v-model="dialog"
                            width="400"
                            class="text-fakeBlack"
                          >
                            <v-card class="!rounded-xl p-6">
                              <div class="flex flex-col text-center justify-center tracking-tight">
                                <icon
                                  icon="fluent-emoji-flat:warning"
                                  width="32"
                                  height="32"
                                  class="text-base font-medium mx-2 justify-self-center w-full mb-4"
                                />

                                <p class="text-center font-medium text-xl mb-2">
                                  {{ $t("Are you sure you want to delete this tag ?") }}
                                </p>

                                <p class="text-sm text-darkGrey normal-case mb-8">
                                  {{ $t("This tag will be removed from every templates and campaigns it is associate") }}
                                </p>
                              </div>

                              <div class="flex w-full justify-center !px-1.5 gap-x-3">
                                <v-btn
                                  class="normal-case font-normal w-1/2 text-xs"
                                  color="fakeBlack"
                                  variant="outlined"
                                  @click="dialog = false"
                                >
                                  {{ $t("No, cancel") }}
                                </v-btn>

                                <v-btn
                                  class="text-white bg-negativeRed normal-case font-normal w-1/2 text-xs"
                                  color="FF5656"
                                  variant="outlined"
                                  @click="deleteTag(0, tag_idx)"
                                >
                                  {{ $t("Yes, delete this tag") }}
                                </v-btn>
                              </div>
                            </v-card>
                          </v-dialog>
                        </div>
                      </template>
                    </bkt-tag>

                    <p
                      v-if="
                        showGroups.length > 0 && showOther.showCatSuggestion &&
                          showOther.showInputCatSuggestion === false
                      "
                      class="text-sm bkt-light-grey6"
                      @click.stop.self="editTagField(0)"
                    >
                      {{ $t("Move an existing tag to this category") }}
                    </p>

                    <div
                      v-if="showGroups.length > 0 && showOther.showInputCatSuggestion"
                      :ref="loopCatSuggestionRef + 0"
                      v-click-outside="resetCatSuggestionV2"
                      class="flex flex-row items-center relative"
                    >
                      <v-text-field
                        ref="otherSearch"
                        class="w-[300px] rounded ml-5 text-sm"
                        variant="outlined"
                        :label="$t('Tag to move to this category')"
                        hide-details
                        @focus="presearch(showOther)"
                        @input="searchTagFromOtherGroups($event, showOther)"
                      />

                      <ul
                        class="absolute left-5 top-14 w-[300px] bkt-bg-white bkt-box-shadow-medium max-h-64 overflow-y-auto z-10"
                      >
                        <li
                          v-if="suggestionTag && suggestionTag.length === 0"
                          class="px-2 bkt-light-grey6 text-sm"
                        >
                          {{ $t("This tag doesn't exist.") }}
                        </li>

                        <li
                          v-else-if="suggestionTag && suggestionTag.length > 0"
                          class="px-2 bkt-light-grey6 text-sm"
                        >
                          {{ $t("Select existing tag") }}
                        </li>

                        <li
                          v-for="tagObj in suggestionTag"
                          :key="tagObj.id"
                          class="px-2 py-2 bkt-bg-light-grey9-hover cursor-pointer"
                          @click.stop="changeTagOfGroup(tagObj, showOther)"
                        >
                          <bkt-tag :selected="false">
                            {{ tagObj.title }}
                          </bkt-tag>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
            </v-hover>

            <div
              v-for="(item, idx) in showGroups"
              :key="item.id"
              :ref="loopCatSuggestionRef + (idx + 1)"
              class="w-full h-[68px] flex items-center relative"
            >
              <v-dialog
                v-model="deleteCat"
                width="500"
                class="text-fakeBlack w-50"
              >
                <v-card
                  class="!rounded-xl p-6"
                >
                  <div class="w-full flex flex-col justify-center items-center mb-2 text-center font-medium text-xl">
                    {{ $t("Are you sure you want to delete this category ?") }}
                  </div>

                  <div class="w-full flex flex-col justify-center items-center text-darkGrey mb-8 text-center normal-case text-sm">
                    <p>
                      {{
                        $t(
                          "Tags from this category won't be delete, they will move to the category 'Other'."
                        )
                      }}
                    </p>

                    <p>{{ $t("This is a permanent action.") }}</p>
                  </div>

                  <div
                    class="w-full flex justify-center sm:flex-row gap-x-3 px-1.5"
                  >
                    <v-btn
                      color="neutral"
                      variant="outlined"
                      class="text-fakeBlack border-darkGrey border-1 normal-case font-normal w-1/2 text-xs"
                      @click="deleteCat = false"
                    >
                      {{ $t("No, cancel") }}
                    </v-btn>

                    <v-btn
                      color="red"
                      variant="outlined"
                      class="text-white bg-negativeRed normal-case font-normal w-1/2 text-xs"
                      @click="removeGroupCat(deleteCatId)"
                    >
                      {{ $t("Yes, delete this category") }}
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <div>
                <v-text-field
                  v-if="item.inputRenameGroupCat"
                  :ref="'inputRenameGroupCat' + (idx + 1)"
                  class="w-[265px] p-2 bkt rounded-5px text-base"
                  type="text"
                  variant="outlined"
                  :label="$t('New category name')"
                  hide-details
                  @blur="item.inputRenameGroupCat = false"
                  @keyup.enter="renameGroupCat($event, item)"
                />

                <v-btn
                  v-else-if="
                    item.inputRenameGroupCat === false && showGroups.length !== 0
                  "
                  v-click-outside-booklet="hideGroupOptions"
                  type="transparent"
                  variant="text"
                  class="flex justify-start"
                  :width="265"
                  @click.stop="showGroupOptions(idx + 1)"
                >
                  <span
                    class="text-truncate"
                    style="max-width: 265px;"
                  >{{ $filters.capitalize(item.name) }}</span>
                </v-btn>
                <transition name="cat-options">
                  <ul
                    v-if="item.groupOptions"
                    :tabindex="idx"
                    class="absolute left-0 bkt-bg-white bkt-box-shadow-medium rounded z-10 top-14"
                  >
                    <li
                      class="flex justify-start items-center items-center p-2 bkt-bg-light-grey9-hover mb-0 cursor-pointer"
                      @click="showRenameGroupCat(idx + 1)"
                    >
                      <Icon
                        class="mr-2"
                        icon="jam:write"
                      />

                      <p>{{ $t("Rename") }}</p>
                    </li>

                    <li
                      v-if="item.name !== 'others'"
                      class="flex justify-start items-center items-center p-2
                        bkt-bg-light-grey9-hover bkt-red mb-0 cursor-pointer"
                      @click="deleteCat = true; deleteCatId = idx + 1"
                    >
                      <Icon
                        class="mr-2"
                        icon="akar-icons:trash-can"
                      />

                      <p>{{ $t("Delete") }}</p>
                    </li>
                  </ul>
                </transition>
              </div>

              <div
                class=" w-full flex-wrap flex-row items-center bkt-bg-light-grey9-hover px-2 py-1 rounded cursor-pointer"
                @mouseleave="hideCatSuggestion(idx + 1)"
                @mouseover="showCatSuggestion(idx + 1)"
                @click.stop.self="editTagField(idx + 1)"
              >
                <bkt-tag
                  v-for="(tag, tag_idx) in item.categories"
                  :key="tag.id"
                  :dropdown="true"
                  :selected="tag.selected"
                  class="mx-2 inline-block my-3 vertical-align-middle rounded"
                  @close="deleteTag(idx + 1, tag.id)"
                  @click.stop="toggleTag(idx + 1, tag_idx)"
                >
                  {{ $filters.truncateN(tag.title) }}
                  <template #dropdown>
                    <div class="p-3 flex flex-col">
                      <v-text-field
                        v-model="tag.title"
                        class="rounded"
                        variant="outlined"
                        color="black"
                        @change="updateTag(tag.id, tag.title)"
                      />

                      <v-btn
                        class="w-full shadow-none text-red-600 hover:shadow-none focus:shadow-none align-left text-left"
                        prepend-icon="mdi-trash-can"
                        @click="dialog = true"
                      >
                        {{ $t("Delete tag") }}
                      </v-btn>

                      <v-dialog
                        v-model="dialog"
                        width="400"
                        class="text-fakeBlack"
                      >
                        <v-card class="!rounded-xl p-6">
                          <div class="flex flex-col text-center justify-center tracking-tight">
                            <icon
                              icon="fluent-emoji-flat:warning"
                              width="32"
                              height="32"
                              class="text-base font-medium mx-2 justify-self-center w-full mb-4"
                            />

                            <p class="text-center font-medium text-xl mb-2">
                              {{ $t("Are you sure you want to delete this tag ?") }}
                            </p>

                            <p class="text-sm text-darkGrey normal-case mb-8">
                              {{ $t("This tag will be removed from every templates and campaigns it is associate") }}
                            </p>
                          </div>

                          <div class="flex w-full justify-center !px-1.5 gap-x-3">
                            <v-btn
                              class="normal-case font-normal w-1/2 text-xs"
                              color="neutral"
                              variant="outlined"
                              @click="dialog = false"
                            >
                              {{ $t("No, cancel") }}
                            </v-btn>

                            <v-btn
                              class="text-white bg-negativeRed normal-case font-normal w-1/2 text-xs"
                              color="red"
                              variant="outlined"
                              @click="deleteTag(0, tag_idx)"
                            >
                              {{ $t("Yes, delete this tag") }}
                            </v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </bkt-tag>

                <p
                  v-if="showGroups.length > 0 && item.showCatSuggestion &&
                    item.showInputCatSuggestion === false"
                  class="text-sm bkt-light-grey6 my-3 ml-3 cursor-pointer inline-block vertical-align-middle"
                  @click.stop.self="editTagField(idx + 1)"
                >
                  {{ $t("Move an existing tag to this category") }}
                </p>

                <div
                  v-if="showGroups.length > 0 && item.showInputCatSuggestion"
                  v-click-outside="resetCatSuggestionV2"
                  class="w-[350px] flex flex-row items-center relative"
                >
                  <v-text-field
                    ref="otherSearch"
                    class="w-full rounded ml-5 text-sm"
                    variant="outlined"
                    :label="$t('Tag to move to this category')"
                    hide-details
                    @focus="presearch(item)"
                    @input="searchTagFromOtherGroups($event, item)"
                  />

                  <ul
                    class="absolute left-5 top-14 w-[330px] bkt-bg-white bkt-box-shadow-medium max-h-64 overflow-y-auto z-10"
                  >
                    <li
                      v-if="suggestionTag && suggestionTag.length === 0"
                      class="px-2 bkt-light-grey6 text-sm mt-2"
                    >
                      {{ $t("This tag doesn't exist.") }}
                    </li>

                    <li
                      v-else-if="suggestionTag && suggestionTag.length > 0"
                      class="px-2 bkt-light-grey6 text-sm my-2"
                    >
                      {{ $t("Select existing tag") }}
                    </li>

                    <li
                      v-for="tagObj in suggestionTag"
                      :key="tagObj.id"
                      class="px-2 mb-2 bkt-bg-light-grey9-hover cursor-pointer"
                      @click.stop="changeTagOfGroup(tagObj, item)"
                    >
                      <bkt-tag :selected="false">
                        {{ tagObj.title }}
                      </bkt-tag>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="flex justify-start items-center my-4 h-16">
              <v-text-field
                v-if="showNewCatInput"
                v-model="createCatInput"
                variant="outlined"
                :label="$t('Enter a category name')"
                class="p-3 text-base w-full"
                hide-details
                @blur="showNewCatInput = false"
                @keyup.enter="createNewGroupCat"
              />
              <v-btn
                v-else
                variant="text"
                type="transparent"
                @click="displayNewCatInput"
              >
                <v-icon class="mr-1">
                  mdi-plus-circle-outline
                </v-icon>
                {{ $t('Create category') }}
              </v-btn>
            </div>
          </div>
        </v-list-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import BktTag from "@/components/BktTag.vue";
import axiosService from "@/tools/axios-service.js";
import { Icon } from "@iconify/vue";
import modal from "@/tools/modal.js";
import {ref} from "vue";
import {useSnackbar} from "@/store/snackbar.js";

export default {
  components: { BktTag, Icon },
  props: ["entityListKey", "pathKeyArgs"],
  data() {
    return {
      dialog: false,
      groupCategories: [],
      suggestionTag: [],
      loopCatSuggestionRef: "loopCatSuggestion",
      showNewCatInput: false,
      selectedTags: [],
      deleteCat: false,
      snackbar: useSnackbar(),
      otherSearch: ref(),
      createCatInput: null,
      deleteCatId: -1
    }
  },
  computed: {
    showGroups() {
      if (!this.groupCategories || this.groupCategories.length === 0) return [];

      if (!("showCatSuggestion" in this.groupCategories[0])) {
        this.groupCategories = this.groupCategories.map((item) => {
          item.categories = item.categories.map((tag) => {
            return { ...tag, selected: false };
          });
          return {
            ...item,
            showCatSuggestion: false,
            showInputCatSuggestion: false,
            groupOptions: false,
            inputRenameGroupCat: false,
          };
        });
      }
      this.sortByName();
      return this.groupCategories.slice(1);
    },
    showOther() {
      if (!this.groupCategories || this.groupCategories.length === 0)
        return { name: "", categories: {} };
      if (this.groupCategories[0].name === "others") {
        return this.groupCategories[0];
      }
      return { name: "", categories: {} };
    },
  },
  created() {
    this.fetchGroupCategories();
    this.snackbar.checkStatus();
  },
  methods: {
    async fetchGroupCategories() {
      const resGroupCat = await axiosService.get(
        "/api/v1/interviews/group_categories.json"
      );

      this.groupCategories = resGroupCat.data["group_categories"];
    },
    openInfo() {
      modal.open({
        componentName: "info-filter-category-tags",
        icon: '',
        title: '',
        subtitle: '',
        close() {},
      });
    },
    editTagField(arrayIdx) {
      this.groupCategories = this.groupCategories.map((item) => {
        return { ...item, showInputCatSuggestion: false };
      });
      this.groupCategories[arrayIdx].showInputCatSuggestion = true;
      this.groupCategories[arrayIdx].showCatSuggestion = false;
      if (arrayIdx === 0) {
        this.$nextTick(() => {
          this.otherSearch?.value?.focus()
        })
      } else {
        this.$nextTick(() => {
          this.otherSearch?.value?.focus()
        })
      }
    },
    resetCatSuggestion(arrayIdx) {
      this.groupCategories[arrayIdx].showInputCatSuggestion = false;
      this.groupCategories[arrayIdx].showCatSuggestion = false;
    },
    resetCatSuggestionV2() {
      this.groupCategories = this.groupCategories.map((item) => {
        return {
          ...item,
          showInputCatSuggestion: false,
          showCatSuggestion: false,
        };
      });
    },
    showCatSuggestion(arrayIdx) {
      if (this.groupCategories[arrayIdx])
        this.groupCategories[arrayIdx].showCatSuggestion = true;
    },
    hideCatSuggestion(arrayIdx) {
      if (this.groupCategories[arrayIdx])
        this.groupCategories[arrayIdx].showCatSuggestion = false;
    },
    displayNewCatInput() {
      this.showNewCatInput = true;
      // this.$nextTick(() => {
      //   // setTimeout(() => {
      //   this.$refs.showNewCatInput.focus();
      //   // }, 350)
      // });
    },
    async createNewGroupCat(e) {
      const groupName = e.target.value;

      try {
        await axiosService.post("/api/v1/interviews/group_categories", {
          name: groupName,
        });

        this.createCatInput = ""
        this.showNewCatInput = false;
        await this.fetchGroupCategories();
      } catch (e) {
        console.log(e)
      }
    },
    async renameGroupCat(e, item) {
      const groupName = e.target.value;

      await axiosService.put(`/api/v1/interviews/group_categories/${item.id}`, {
        name: groupName,
      });
      await this.fetchGroupCategories();
    },
    showGroupOptions(arrayIdx) {
      this.groupCategories[arrayIdx].groupOptions =
        !this.groupCategories[arrayIdx].groupOptions;
    },
    hideGroupOptions() {
      this.groupCategories = this.groupCategories.map((item) => {
        return { ...item, groupOptions: false };
      });
    },
    async removeGroupCat(arrayIdx) {
      const id = this.groupCategories[arrayIdx].id;

      try {
        for (const cat of this.groupCategories[arrayIdx].categories) {
          await this.changeTagOfGroup(cat, this.groupCategories[0]);
        }
        await axiosService.delete(`/api/v1/interviews/group_categories/${id}`, {
          id: id,
        });
      } catch (e) {}
      this.deleteCat = false;
      this.fetchGroupCategories();
    },
    showRenameGroupCat(arrayIdx) {
      this.groupCategories[arrayIdx].inputRenameGroupCat = true;
      this.$nextTick(() => {
        this.$refs[`inputRenameGroupCat${arrayIdx}`][0].focus();
        this.$refs[`inputRenameGroupCat${arrayIdx}`][0].value =
          this.groupCategories[arrayIdx].name;
      });
      this.sortByName();
    },
    presearch(item) {
      this.searchTagFromOtherGroups({ target: { value: "" } }, item);
    },
    async searchTagFromOtherGroups(e, item) {
      const search = e.target.value;
      const except_group_category_id = item.id;

      const resSuggestionTag = await axiosService.get(
        "/api/v1/interviews/tags.json",
        {
          params: {
            search,
            except_group_category_id,
          },
        }
      );
      this.suggestionTag = resSuggestionTag.data["categories"];
    },
    async changeTagOfGroup(tagObj, groupCatObj) {
      await axiosService.put(`/api/v1/interviews/tags/${tagObj.id}.json`, {
        group_category_id: groupCatObj.id,
      });
      await this.fetchGroupCategories();
    },
    toggleTag(group_idx, tag_idx) {
      this.groupCategories[group_idx].categories[tag_idx].selected =
        !this.groupCategories[group_idx].categories[tag_idx].selected;

      const tagsLengthBackUp = this.selectedTags.length;
      this.selectedTags = this.selectedTags.filter((item) => {
        return (
          item.id !== this.groupCategories[group_idx].categories[tag_idx].id
        );
      });
      if (this.selectedTags.length === tagsLengthBackUp)
        this.selectedTags.push(
          this.groupCategories[group_idx].categories[tag_idx]
        );

      this.$emit(
        "fetch",
        this.selectedTags.map((tag) => tag.id)
      );
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    sortByName() {
      this.groupCategories = this.groupCategories.sort((a, b) => {
        if (this.capitalize(a.name) === this.capitalize(b.name)) return 0;
        if (this.capitalize(a.name) === "Others") return -1;
        if (this.capitalize(b.name) === "Others") return 1;
        if (this.capitalize(a.name) < this.capitalize(b.name)) return -1;
        if (this.capitalize(a.name) > this.capitalize(b.name)) return 1;
        return 0;
      });
    },
    async deleteTag(group_idx, tag_idx) {
      try {
        await axiosService.delete(
          `/api/v1/interviews/categories/${this.groupCategories[group_idx].categories[tag_idx].id}`
        );

        this.groupCategories[group_idx].categories.splice(tag_idx, 1);

        this.$emit(
          "fetch",
          this.selectedTags.map(
            () => this.groupCategories[group_idx].categories[tag_idx].id
          )
        );

        this.snackbar
          .setDefaultColor("success")
          .displaySnackBar("Tag deleted successfully");
      } catch (e) {
        this.snackbar
          .setDefaultColor("failed")
          .displaySnackBar("An error occurred during tag deletion.");
      } finally {
        this.dialog = false;
      }
    },
    async updateTag(id, title) {
      try {
        await axiosService.patch(
          `/api/v1/interviews/categories/${id}`,
          { title: title }
        );

        this.snackbar
          .setDefaultColor("success")
          .displaySnackBar("Tag updated successfully");
      } catch (e) {
        this.snackbar
          .setDefaultColor("failed")
          .displaySnackBar("An error occurred during tag update.");
      }
    },
  },
};
</script>
