<template>
  <div class="relative w-full"
       :class="[isMobile ? 'h-[157px]' : 'h-[320px]', mode === 'position' ? 'drag-position' : '']"
  >
    <cropper
        ref="cropperRef"
        class="absolute left-0 top-0 rounded-lg bg-middle-grey w-full"
        :class="[isMobile ? 'h-[157px]' : 'h-[320px]', mode !== 'show' ? 'hover:cursor-move' : '']"
        :src="urlRef"
        :stencil-props="{
        handlers: {},
        movable: false,
        resizable: false,
        previewClass: 'round-every-box'
      }"
        imageClass="round-every-box"
        backgroundClass="round-every-box"
        :stencil-size="stencilSize"
        image-restriction="stencil"
        :resize-image="{ wheel: false }"
        @change="cropperChanged = $event"
    />
    <div v-if="mode === 'show'" class="w-full h-full absolute top-0 left-0">
      <!--      the purpose of this empty div is to disable movement-->
    </div>

    <div v-else-if="mode === 'edit'" class="w-full h-full absolute top-0 left-0 flex flex-row items-start justify-end pt-2 pr-2 space-x-2">
      <!-- Edit Image button -->
      <svn-icon-button
        circle
        variant="outlined"
        id="closeThefuckingOverlayCover"
        color-class="border border-fake-black bg-white"
      >
        <Icon
          icon="mdi:pencil-outline"
          height="24"
          width="24"
          class="text-fake-black"
        />

        <!-- Upload Image dialog component -->
        <dialog-image-upload
          @upload-local-image="$refs.fileInput.click()"
          @upload-embed-image="uploadImage"
          @upload-unsplash-image="uploadImage"
          @file-dropped="uploadImage"
        />

        <input class="hidden" type="file" ref="fileInput" @change="loadLocalImage" accept="image/*">
      </svn-icon-button>

      <!-- Position Image button -->
      <svn-icon-button
        circle
        variant="outlined"
        @click="mode = 'position'"
        color-class="border border-fake-black bg-white"
      >
        <Icon
          icon="mingcute:move-line"
          height="24"
          width="24"
          class="text-fake-black"
        />
      </svn-icon-button>
    </div>
    <div
        v-else-if="mode === 'position'"
        class="w-20 h-14 absolute top-0 right-0 flex flex-row items-start justify-end pt-2 pr-2 space-x-2">
      <svn-icon-button
          circle
          variant="outlined"
          icon="material-symbols:check"
          @click="mode = 'edit'; validateCoor()"
      >
      </svn-icon-button>

      <svn-icon-button
          circle
          variant="outlined"
          icon="system-uicons:cross"
          @click="mode = 'edit'; rollbackCoor()"
      >
      </svn-icon-button>
    </div>
  </div>
</template>
<script setup>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import {storeToRefs} from "pinia";
import {useMobileStore} from "@/store/mobile.js";
import DialogImageUpload from "@/components/BktPopUp/Dialogs/DialogImageUpload.vue";
import {Icon} from "@iconify/vue";
import {onMounted, onUpdated, onBeforeUpdate, ref, watch} from "vue";

onMounted(() => {
  urlRef.value = props?.url
})

const props = defineProps({
  mode: {
    type: String,
    default() {
      return 'show'
    }
  },
  url: {
    type: String,
    default() {
      return null
    }
  },
  coordinates: {
    type: Object,
    default() {
      return {left: 0, top: 0}
    }
  }
})

const emit = defineEmits(['fileUpload', 'update:coordinates'])

watch(props, (newValue, oldValue) => {
  if (newValue?.url) {
    urlRef.value = newValue?.url
  }
})

const {isMobile} = storeToRefs(useMobileStore())
const cropperChanged = ref(null)
const cropperRef = ref(null)
const blob = ref(null)
const urlRef = ref(null)
const forceUpdateCoor = ref(false)

onBeforeUpdate(() => {
  if (!cropperChanged.value && props.coordinates ||
      cropperChanged.value &&
      props.coordinates &&
      cropperChanged.value?.coordinates.left !== props.coordinates.left &&
      cropperChanged.value?.coordinates.top !== props.coordinates.top) {
    cropperRef.value.setCoordinates({
      left: props.coordinates.left,
      top: props.coordinates.top
    })
    if (forceUpdateCoor.value) {
      forceUpdateCoor.value = false
      validateCoor()
    }
  }
})

const validateCoor = () => {
  emit('update:coordinates', {left: cropperChanged.value.coordinates.left, top: cropperChanged.value.coordinates.top})
  cropperRef.value.setCoordinates({
    left: cropperChanged.value.coordinates.left,
    top: cropperChanged.value.coordinates.top
  })
}

const rollbackCoor = () => {
  cropperRef.value.setCoordinates({
    left: props.coordinates.left,
    top: props.coordinates.top
  })
}

const stencilSize = ({boundaries}) => {
  return {
    height: boundaries.height,
    width: boundaries.width,
    // height: 320,
    // width: 726,
  }
}

const uploadImage = async (imageLink) => {
  try {
    const tmpFile = await loadXHR(imageLink)
    emit('fileUpload', tmpFile)
    forceUpdateCoor.value = true
  } catch (error) {
    emit('error', 'Error uploading module image')
  }
  document.getElementById('closeThefuckingOverlayCover').click()
}

const loadXHR = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.")
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          const file = new File([xhr.response], `tmp-${new Date()}`);
          resolve(file)
        } else {
          reject("Loading error:" + xhr.statusText)
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message)
    }
  });
}

const loadLocalImage = (event) => {
  // Reference to the DOM input element
  const {files} = event.target;
  // Ensure that you have a file before attempting to read it
  if (files && files[0]) {
    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    // if (blob.value) {
    //   URL.revokeObjectURL(blob.value)
    // }
    // 2. Create the blob link to the file to optimize performance:
    // const tmpBlob = URL.createObjectURL(files[0]);

    // 3. The steps below are designated to determine a file mime type to use it during the
    // getting of a cropped image from the canvas. You can replace it them by the following string,
    // but the type will be derived from the extension and it can lead to an incorrect result:
    //
    // this.image = {
    //    src: blob;
    //    type: files[0].type
    // }

    // Create a new FileReader to read this image binary data
    const reader = new FileReader();
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // // Note: arrow function used here, so that "this.image" refers to the image of Vue component
      // this.image = {
      //   // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
      //   src: blob,
      //   // Determine the image type to preserve it during the extracting the image from canvas:
      //   type: getMimeType(e.target.result, files[0].type),
      // };
      if (getMimeType(e.target.result, files[0].type)) {
        // blob.value =  tmpBlob
        emit('fileUpload', files[0])
        forceUpdateCoor.value = true
      } else {
        emit('error', 'Error uploading module image')
      }
    };
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsArrayBuffer(files[0]);
  }
}
const getMimeType = (file, fallback = null) => {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}


</script>

<style>
.round-every-box {
  border-radius: 8px !important;
}

.vue-advanced-cropper__image-wrapper,
.vue-advanced-cropper__foreground {
  border-radius: 8px;
}
</style>

<style scoped>
.drag-position::after {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  content: 'Drag image to reposition';
  position: absolute;
  cursor: not-allowed;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition-property: width, opacity;
  transition-duration: 270ms;
  transition-timing-function: ease;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}
</style>