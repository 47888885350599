<template>
  <pop-up-skeleton
    :title="$t(
      campaign?.campaign_type === 'OneToOne'
        ? 'Select another interviewer'
        : 'Select another person in charge'
    )"
    icon="ic-baseline-person"
  >
    <template #body>
      <svn-autocomplete
        v-model="userSelectedId"
        :placeholder="$t('Search')"
        :items="userInterviewers"
        @input="search($event)"
      >
        <template #main-content>
          {{ null }}
        </template>

        <template #append-content>
          {{ null }}
        </template>
      </svn-autocomplete>
    </template>

    <template #buttons>
      <v-btn
        class="w-full mt-8 sm:w-[150px] sm:self-center h-14 normal-case font-medium shadow-none"
        color="primary"
        type="interview"
        @click="setInterviewer"
      >
        {{ $t("Apply") }}
      </v-btn>

      <v-snackbar />
    </template>
  </pop-up-skeleton>
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from "../../tools/axios-service.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "../../store/pop-up.js";
import { useCampaignStore } from "@/store/campaign.js";
import {useSnackbar} from "@/store/snackbar.js";
import i18n from "@/plugins/i18n";


const props = defineProps({
  campaignId: { type: Number, default: null },
  employeeId: { type: Number, default: null },
});

const { campaign } = storeToRefs(useCampaignStore());
const userSelectedId = ref();
const snackbar = useSnackbar();
const userInterviewers = ref([])

const { closePopUp, callback } = usePopUpStore();

onMounted(async () => {
  try {
    await search()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while searching')
    snackbar.displaySnackBar()
  }
})

const search = async (value) => {
  try {
    const res = await axios.get(`api/v1/users/users_search`,
    { params:
      { text: value,
        only_managers: true,
        limit: 30
      }
    })
    userInterviewers.value = res.data.users
  } catch (error) {
    snackbar.setDefaultColor("failed").displaySnackBar(i18n.global.t('It seems that we cannot update the list. Reload the page or contact the support'));
  }
};

const setInterviewer = async () => {
  if (userSelectedId.value) {
    try {
      await axios.patch(
        `/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/update_sets.json`,
        {
          employee_id: props.employeeId,
          interviewer_id: userSelectedId.value,
        }
        );
      } catch (e) {
        snackbar.setDefaultColor("failed").displaySnackBar(i18n.global.t('The interviewer has not been updated. Please try again.'));
    } finally {
      callback();
      closePopUp();
    }
  }
};
</script>
