<template>
  <div
    v-if="(
      !objectiveIndicator?.options.current_value ||
      (!objectiveIndicator?.options.target_value && objectiveIndicator.indicator_type !== 'multi_choice'))"
    class="rounded-[3px] px-2 py-1 max-w-[150px] w-fit"
    :class="getBgWithBorderByObjectiveIndicatorValue(objectiveIndicator?.options.starting_value,
                                                     objectiveIndicator?.options.current_value,
                                                     objectiveIndicator?.options.target_value,
                                                     objectiveIndicator?.indicator_type)"
  >
    <p class="text-sm font-medium normal-case truncate overflow-ellipsis">
      {{ $t('Not yet set') }}
    </p>
  </div>

  <div
    v-else-if="objectiveIndicator.indicator_type === 'percentage'"
    class="rounded-[3px] px-2 py-1 max-w-[150px] w-fit"
    :class="getBgWithBorderByObjectiveIndicatorValue(objectiveIndicator?.options.starting_value,
                                                     objectiveIndicator?.options.current_value,
                                                     objectiveIndicator?.options.target_value,
                                                     objectiveIndicator?.indicator_type)"
  >
    <p class="text-sm font-medium normal-case truncate overflow-ellipsis">
      {{ objectiveIndicator?.options.current_value + '%' + ' / ' + objectiveIndicator?.options.target_value + '%' }}
    </p>
  </div>

  <div
    v-else-if="objectiveIndicator.indicator_type === 'boolean'"
    class="rounded-[3px] px-2 py-1 max-w-[150px] w-fit"
    :class="getBgWithBorderByObjectiveIndicatorValue(objectiveIndicator?.options.starting_value,
                                                     objectiveIndicator?.options.current_value,
                                                     objectiveIndicator?.options.target_value,
                                                     objectiveIndicator?.indicator_type)"
  >
    <p class="text-sm font-medium normal-case truncate overflow-ellipsis">
      {{ objectiveIndicator?.options.current_value }}
    </p>
  </div>

  <div
    v-else-if="objectiveIndicator.indicator_type === 'numeric_value'"
    class="rounded-[3px] px-2 py-1 max-w-[150px] w-fit"
    :class="getBgWithBorderByObjectiveIndicatorValue(objectiveIndicator?.options.starting_value,
                                                     objectiveIndicator?.options.current_value,
                                                     objectiveIndicator?.options.target_value,
                                                     objectiveIndicator?.indicator_type)"
  >
    <p class="text-sm font-medium normal-case truncate overflow-ellipsis">
      {{ objectiveIndicator?.options.current_value + ' / ' + objectiveIndicator?.options.target_value }}
    </p>
  </div>

  <div
    v-else-if="objectiveIndicator.indicator_type === 'multi_choice'"
    class="flex w-full max-w-[150px]"
  >
    <span
      class="rounded-[3px] px-2 py-1 text-sm font-medium normal-case truncate"
      :class="getBgWithBorderByObjectiveIndicatorValue(0,
                                                       objectiveIndicator?.options.current_value,
                                                       objectiveIndicator?.options?.multi_choice_list?.filter(option => option.checkbox === true),
                                                       objectiveIndicator?.indicator_type)"
    >
      {{ objectiveIndicator?.options.current_value }}
    </span>
  </div>
</template>

<script setup>
import useCampaigns from '@/tools/useCampaigns';

const { getBgWithBorderByObjectiveIndicatorValue } = useCampaigns();

const props = defineProps({
  objectiveIndicator: { type: Object, default: () => {} }
})
</script>
