<template>
  <v-overlay
    activator="parent"
    location-strategy="connected"
    absolute
    class="w-fit"
    :scrim="false"
    :location="isMobile ? 'end bottom' : 'bottom center'"
    :origin="isMobile ? 'end top' : 'auto'"
  >
    <!-- <template #activator="{ props }">
      <div v-bind="props">
        <slot name="activator" />
      </div>
    </template> -->

    <v-card class="flex flex-col rounded-[8px] w-full card mt-[6px]">
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        bg-color="white"
        color="black"
        height="48"
        selected-class="font-medium text-black"
        class="!opacity-100"
        :align-tabs="isMobile ? 'center' : 'start'"
        :grow="isMobile ? true : false"
      >
        <!-- Upload tab -->
        <v-tab
          value="upload"
          color="black"
          width="120"
        >
          <div class="flex px-4 flex-row justify-center items-center gap-2">
            <!-- Icon upload -->
            <div>
              <Icon
                icon="material-symbols:upload"
                :class="tab === 'upload' ? 'text-black' : 'text-darkGrey'"
                height="24"
                width="24"
              />
            </div>

            <!-- Text Upload -->
            <p
              class="text-sm font-medium normal-case"
              :class="tab === 'upload' ? 'text-black' : 'text-darkGrey'"
            >
              {{ $t('Upload') }}
            </p>
          </div>
        </v-tab>

        <!-- Embed tab todo: need to be fixed -->
        <v-tab
          value="embed"
          color="black"
          width="120"
        >
          <div class="flex px-4 flex-row justify-center items-center gap-2">
        <!-- Icon Embed Link -->
        <div>
              <Icon
                icon="mdi:link-variant"
                :class="tab === 'embed' ? 'text-black' : 'text-darkGrey'"
                height="24"
                width="24"
              />
            </div>

        <!-- Text Embed Link -->
        <p
              class="text-sm font-medium normal-case"
              :class="tab === 'embed' ? 'text-black' : 'text-darkGrey'"
            >
              {{ $t('Embed link') }}
            </p>
          </div>
        </v-tab>

        <!-- Unsplash tab -->
        <v-tab
          value="unsplash"
          color="black"
          width="120"
        >
          <div class="flex px-4 flex-row justify-center items-center gap-2">
            <!-- Icon Unsplash -->
            <div>
              <Icon
                icon="gg:unsplash"
                :class="tab === 'unsplash' ? 'text-black' : 'text-darkGrey'"
                height="24"
                width="24"
              />
            </div>

            <!-- Text Unsplash -->
            <p
              class="text-sm font-medium normal-case"
              :class="tab === 'unsplash' ? 'text-black' : 'text-darkGrey'"
            >
              {{ $t('Unsplash') }}
            </p>
          </div>
        </v-tab>
      </v-tabs>

      <!-- Windows -->
      <v-window
        v-model="tab"
        class="w-full"
      >
        <!-- Upload window -->
        <v-window-item value="upload">
          <div
            class="flex flex-col justify-center items-center py-8"
            :class="isMobile ? 'w-full max-w-[360px]' : 'w-[570px] h-[320px]'"
          >
            <!-- Mobile button -->
            <v-btn
              v-if="isMobile"
              variant="text"
              class="rounded bg-black px-4 flex justify-center items-center gap-2 cursor-pointer h-10 normal-case"
              @click="emit('upload-local-image')"
            >
              <div>
                <Icon
                  icon="material-symbols:upload"
                  class="text-white"
                  height="24"
                  width="24"
                />
              </div>

              <p class="text-xs font-medium text-white">
                {{ $t('Upload a file') }}
              </p>
            </v-btn>

            <!-- LargeView -->
            <div
              v-else
              class="flex flex-col w-full max-w-[368px] h-[208px] p-4 justify-center items-center
                gap-2 rounded-lg border border-dashed"
              :class="dragActive ? 'bg-veryLightGrey border-fakeBlack' : 'bg-white border-darkGrey'"
              @drop.prevent="onDrop"
              @dragenter.prevent="setActiveDrag"
              @dragover.prevent="setActiveDrag"
              @dragleave.prevent="setInactiveDrag"
            >
              <div v-if="!dragActive">
                <Icon
                  icon="material-symbols:upload"
                  class="text-middleGrey"
                  height="48"
                  width="48"
                />
              </div>

              <p class="text-fakeBlack text-sm text-center font-normal">
                {{ dragActive ? $t('Drop your file here') : $t('Drag & drop or') }}

                <span
                  v-if="!dragActive"
                  class="text-trainingOrange underline cursor-pointer"
                  @click="emit('upload-local-image')"
                >
                  {{ $t('choose file') }}
                </span>

                <span v-if="!dragActive">{{ $t('to upload') }}</span>
              </p>
            </div>
          </div>
        </v-window-item>

        <!-- Embed Link window -->
        <v-window-item
          value="embed"
          class="flex flex-col justify-center items-center pt-6 pb-8 px-4 gap-2"
          :class="isMobile ? 'w-full max-w-[360px]' : 'w-[570px]'"
        >
          <v-text-field
            v-model="embedLink"
            variant="outlined"
            density="compact"
            class="w-full"
            :label="$t('Paste the image link here')"
            :hide-details="true"
          />

          <v-btn
            color="black"
            variant="flat"
            height="40"
            class="normal-case self-end"
            :text="$t('Embed image')"
            :disabled="!embedLink?.length"
            @click="emit('upload-embed-image', embedLink)"
          />
        </v-window-item>

        <!-- Unsplash window -->
        <v-window-item
          value="unsplash"
          class="flex flex-col justify-center items-center pt-6 px-4 gap-6"
          :class="isMobile ? 'w-full max-w-[360px]' : 'w-[586px]'"
        >
          <v-text-field
            v-model="unsplashSearchQuery"
            variant="outlined"
            density="compact"
            class="w-full"
            :label="$t('Search for an image')"
            :hide-details="true"
            @input="unsplashSearch"
          />

          <!-- Infinite scroll after images search -->
          <div
            v-if="unsplashSearchQuery.length"
            class="flex flex-wrap gap-2 overflow-y-auto justify-center items-center h-[336px] pb-6"
          >
            <div
              v-for="image in unsplashSearchImages"
              :key="image?.id"
              class="flex flex-col justify-between items-start cursor-pointer"
            >
              <div
                class="rounded"
                :class="isMobile ? 'w-[148px] h-[80px]' : 'w-[122px] h-[64px]'"
                :style="{'background-image': image?.urls?.regular ?
                  `url('${image?.urls?.regular}')` : '', 'background-size': 'cover'}"
                @click="emit('upload-unsplash-image', image?.urls?.regular)"
              />

              <p
                class="text-darkGrey text-[10px] font-normal truncate text-ellipsis"
                :class="isMobile ? 'max-w-[148px]' : 'max-w-[122px]'"
              >
                {{ $t('By') }}

                <a
                  class="underline cursor-pointer"
                  :href="`https://unsplash.com/@${image?.user?.username}?utm_source=aleph&utm_medium=referral`"
                >{{ (image?.user?.first_name || '') + ' ' + (image?.user?.last_name || '') }}</a>
              </p>
            </div>
          </div>

          <!-- Default images -->
          <div
            v-else
            class="flex flex-wrap gap-2 overflow-y-auto justify-center items-center h-[336px] pb-6"
          >
            <div
              v-for="image in unsplashDefaultImages"
              :key="image?.id"
              class="flex flex-col justify-between items-start cursor-pointer"
            >
              <div
                class="rounded"
                :class="isMobile ? 'w-[148px] h-[80px]' : 'w-[122px] h-[64px]'"
                :style="{'background-image': image?.urls?.regular ?
                  `url('${image?.urls?.regular}')` : '', 'background-size': 'cover'}"
                @click="emit('upload-unsplash-image', image?.urls?.regular)"
              />

              <p
                class="text-darkGrey text-[10px] font-normal truncate text-ellipsis"
                :class="isMobile ? 'max-w-[148px]' : 'max-w-[122px]'"
              >
                {{ $t('By') }}

                <a
                  class="underline cursor-pointer"
                  :href="`https://unsplash.com/@${image?.user?.username}?utm_source=aleph&utm_medium=referral`"
                >{{ (image?.user?.first_name || '') + ' ' + (image?.user?.last_name || '') }}</a>
              </p>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </v-overlay>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';
import { createApi } from 'unsplash-js';
import { useSnackbar } from '@/store/snackbar';
import { debounce } from 'lodash';

const unsplash = createApi({
  accessKey: import.meta.env.VITE_UNSPLASH_ACCESS_KEY,
});

onMounted(() => {
  if (import.meta.env.VITE_UNSPLASH_ACCESS_KEY) {
    unsplash.photos.list({ perPage: 28 }).then((photos) => {
      if (photos?.errors) {
        snackbar.setBgColor('negativeRed')
        snackbar.setMsg('Error fetching Unplash images')
        snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[10px]')
        snackbar.displaySnackBar()
      }
      else {
        unsplashDefaultImages.value = photos?.response?.results
      }
    })
  }
  events.value.forEach((eventName) => {
    document.body.addEventListener(eventName, (e) => {
      e.preventDefault()
    })
  })
})

const emit = defineEmits(['upload-local-image', 'upload-embed-image', 'upload-unsplash-image', 'file-dropped'])

const { isMobile } = storeToRefs(useMobileStore())

const dragActive = ref(false)

const draggedFile = ref(null)

const dragTimeout = ref(null)

const dialogUpload = ref(false)

const tab = ref(null)

const snackbar = useSnackbar()

const embedLink = ref('')

const unsplashSearchQuery = ref('')

const unsplashDefaultImages = ref([])

const unsplashSearchImages = ref([])

const events = ref(['dragenter', 'dragover', 'dragleave', 'drop'])

const unsplashSearch = debounce(() => {
  unsplash.search.getPhotos({ query: unsplashSearchQuery.value, page: 1, perPage: 28 }).then((photos) => {
    if (photos?.errors) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error fetching Unplash images')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[10px]')
      snackbar.displaySnackBar()
    }
    else {
      if (photos?.response?.results?.length) {
        unsplashSearchImages.value = photos?.response?.results
      }
      else {
        unsplashSearchImages.value = []
      }
    }
  })
}, 250)

const setActiveDrag = () => {
  dragActive.value = true
  clearTimeout(dragTimeout.value)
}

const setInactiveDrag = () => {
  dragActive.value = false
  dragTimeout.value = setTimeout(() => {
    dragActive.value = false
  }, 250)
}

const onDrop = (e) => {
  draggedFile.value = e.dataTransfer.files?.[0]

  if (draggedFile.value) {
    if (new Set(["image/png", "image/jpg", "image/jpeg"]).has(draggedFile.value?.type)) {
      draggedFile.value = URL.createObjectURL(draggedFile.value)
      emit('file-dropped', draggedFile.value)
    }
    else {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Please choose another file. Only .jpeg, .jpg, or .png file formats are supported.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
      snackbar.displaySnackBar()
    }
  }
  setInactiveDrag()
  draggedFile.value = null
}

defineExpose({
  dialogUpload
})

onUnmounted(() => {
  events.value.forEach((eventName) => {
    document.body.removeEventListener(eventName, (e) => {
      e.preventDefault()
    })
  })
})
</script>

<style scoped>
.card {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.infinite :deep(.v-infinite-scroll__side) {
  display: none;
}
</style>
