<template>
  <div class="w-full h-full flex flex-col px-5 py-5 lg:!px-0">
    <div class="flex flex-col items-start gap-6 self-stretch">
      <!-- Global Informations -->
      <div class="flex flex-col items-start gap-4 self-stretch">
        <p class="text-darkGrey text-sm font-medium uppercase">
          {{ $t('Company name') }}
        </p>

        <div class="w-full">
          <v-text-field
            v-model="companyName"
            variant="outlined"
            density="compact"
            hide-details
            class="input text-base font-normal sm:w-1/2"
          />
        </div>
      </div>

      <!-- Shared Informations -->
      <div class="flex flex-col items-start gap-4 self-stretch pb-[80px]">
        <div class="w-full flex flex-col gap-2">
          <p class="text-darkGrey text-sm font-medium uppercase">
            {{ $t('Shared Information') }}
          </p>

          <svn-text sm normal color="dark-grey">
            {{ $t('Links you put as SHARED INFORMATION will show up on the homepage and be accessible to all users. Use it to share guidelines or global information to your employees.') }}
          </svn-text>
        </div>

        <!-- Information Blocks -->
        <div v-if="informationBlocks?.length" class="w-full gap-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <shared-information-card
            v-for="(block, index) in informationBlocks"
            :key="index"
            :block="block"
            @update-information-block="updateInformationBlock($event, index)"
            @delete-information-block="deleteInformationBlock(index)"
          />
        </div>

        <!-- Add information block -->
        <svn-dialog-skeleton
          title="Shared information"
          description="Enter the title and the link of the shared information you want to add."
          primary-button-text="Save"
          button-primary-color="primary"
          dialog-custom-class="w-[400px]"
          @click-primary-button="saveInformationBlock(index)"
        >
          <template #activator>
            <v-btn
              prepend-icon="mdi-plus"
              color="black"
              variant="outlined"
              :text="$t('Add information')"
              hide-details
              height="40"
              class="normal-case"
            />
          </template>

          <template #input>
            <div class="w-full flex flex-col gap-4">
              <!-- Title -->
              <v-text-field
                v-model="linkTitle"
                :label="$t('Title')"
                variant="outlined"
                density="compact"
                hide-details
                class="input text-base font-normal"
              />

              <!-- Link -->
              <v-text-field
                v-model="linkValue"
                :label="$t('Link')"
                variant="outlined"
                density="compact"
                hide-details
                class="input text-base font-normal"
              />
            </div>
          </template>
        </svn-dialog-skeleton>
      </div>
    </div>
  </div>

  <!-- Fixed buttons when you update the company name -->
  <div
    v-if="isEditingCompanyName"
    class="fixed flex left-[50%] -translate-x-[50%] bottom-4 justify-center items-center"
  >
    <div class="flex gap-2 bg-white">
      <v-btn
        :text="$t('Cancel')"
        variant="outlined"
        color="primary"
        height="40"
        class="normal-case"
        @click="cancelEditCompanyName"
      />

      <v-btn
        :text="$t('Save')"
        :disabled="!companyName"
        variant="flat"
        color="primary"
        height="40"
        class="normal-case"
        @click="updateName"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import { useCompanyStore } from '@/store/company';
import { useRoute } from 'vue-router';
import SharedInformationCard from './SharedInformationCard.vue';

onMounted(() => {
  companyName.value = name.value
  informationBlocks.value = links.value
});

const props = defineProps({
  companyLinks: { type: Array, default: null }
});

const { isMobile } = storeToRefs(useMobileStore());
const { name, links } = storeToRefs(useCompanyStore());

const { updateCompanyLinks, updateCompanyName } = useCompanyStore();

const companyName = ref(null)
const isEditingCompanyName = ref(false)
const snackbar = useSnackbar()
const route = useRoute()
const informationBlocks = ref([])
const linkTitle = ref(null)
const linkValue = ref(null)
const newBlocks = ref([])

const saveInformationBlock = async() => {
  try {
    if (linkTitle.value && linkValue.value) {
      newBlocks.value = await updateCompanyLinks([
        ...informationBlocks.value,
        {
          title: linkTitle.value,
          link: linkValue.value,
        }
      ])
      snackbarSavedInformationBlock()
      informationBlocks.value = newBlocks.value
      linkTitle.value = null
      linkValue.value = null
    }
  } catch (error) {
    console.log(error)
  }
}

const updateInformationBlock = async(event, index) => {
  try {
    const found = informationBlocks?.value?.[index]
    if (found) {
      found.title = event?.title ? event?.title : found.title
      found.link = event.link ? event.link : found.link
      newBlocks.value = await updateCompanyLinks(informationBlocks.value)
      snackbarSavedInformationBlock()
      informationBlocks.value = newBlocks.value
      linkTitle.value = null
      linkValue.value = null
    }
  } catch (error) {
    console.log(error)
  }
}

const deleteInformationBlock = async(index) => {
  if (index >= 0) {
    informationBlocks.value.splice(index, 1)
    try {
      await updateCompanyLinks(informationBlocks.value)
      snackbar.setBgColor('fakeBlack')
      snackbar.setMsg('Shared information has been deleted.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error while deleting shared information.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
      snackbar.displaySnackBar()
    }
  }
  else {
    linkTitle.value = null
    linkValue.value = null
  }
}

const snackbarSavedInformationBlock = () => {
  snackbar.setBgColor('fakeBlack')
  snackbar.setMsg('Shared information has been saved.')
  snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
  snackbar.displaySnackBar()
}

const cancelEditCompanyName = () => {
  companyName.value = name.value
  isEditingCompanyName.value = false
}

const updateName = () => {
  isEditingCompanyName.value = false
  try {
    updateCompanyName(companyName.value).then((res) => {
      name.value = res
    })
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Company name updated.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating Company name.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const setBlocks = () => {
  informationBlocks.value = informationBlocks.value.map((link) => {
    return { ...link }
  })
}

watch(route, () => {
  setBlocks()
});

watch(companyName, () => {
  if (companyName.value !== name.value) {
    isEditingCompanyName.value = true
  }
});
</script>

<style scoped>
.input :deep(.v-field) {
  border-radius: 8px;
}
</style>