<template>
  <svn-center-content
    background-color-class="bg-deepBlack"
  >
    <template #centered-content>
      <div class="flex flex-col gap-2 sm:!gap-4 justify-center items-center pb-16">
        <div
          class="flex flex-col items-center justify-center gap-6 2xl:!gap-20 px-8 pt-8 2xl:!px-0 2xl:!w-[732px]"
        >
          <bkt-logo-login />
  
          <svn-login-card>
            <template #box-content>
              <div class="flex flex-col items-start gap-6 w-full">
                <div class="flex flex-col items-center 2xl:!items-start justify-center gap-2 w-full">
                  <p class="text-xl 2xl:!text-2xl font-semibold">
                    {{ $t('Welcome on board !') }}
                  </p>
  
                  <p class="text-sm font-medium text-darkGrey">
                    {{ $t('Please enter your password.') }}
                  </p>
                </div>
  
                <v-text-field
                  v-model="email"
                  :label="$t('Email')"
                  class="w-full"
                  type="text"
                  variant="outlined"
                  :error="emailError.error"
                  :error-messages="emailError.errorMessage"
                  :hide-details="emailError.hideDetails"
                >
                  <template #message>
                    <div class="flex items-center gap-2 w-full ms-1 me-8">
                      <Icon
                        icon="material-symbols:info"
                        heigth="16"
                        width="16"
                      />
                      <p>{{ emailError?.errorMessage }}</p>
                    </div>
                  </template>
                </v-text-field>
  
                <div class="flex flex-column items-end gap-2 w-full">
                  <v-text-field
                    v-model="password"
                    :label="$t('Password')"
                    class="w-full"
                    type="password"
                    variant="outlined"
                    :error="passwordError.error"
                    :error-messages="passwordError.errorMessage"
                    :hide-details="passwordError.hideDetails"
                    @keyup.enter="signIn"
                  >
                    <template #message>
                      <div class="flex items-center gap-2 w-full ms-1 me-8">
                        <Icon
                          icon="material-symbols:info"
                          heigth="16"
                          width="16"
                        />
                        <p>{{ $t('Wrong password') }}</p>
                      </div>
                    </template>
                  </v-text-field>
  
                  <p
                    class="w-fit text-xs text-right cursor-pointer underline text-deepBlack"
                    @click="resetPass"
                  >
                    {{ $t('Forgot my password') }}
                  </p>
                </div>
  
                <v-btn
                  :loading="loginLoading"
                  :height="40"
                  class="w-full bkt-white mb-4 normal-case"
                  color="primary"
                  theme="company"
                  @click="signIn"
                >
                  {{ $t('Log in') }}
                </v-btn>
              </div>
            </template>
          </svn-login-card>
        </div>

        <dialog-request-demo>
          <template #button>
            <svn-text sm regular color="white" class="underline cursor-pointer">
              {{ $t('Request a demo') }}
            </svn-text>
          </template>
        </dialog-request-demo>
      </div>
    </template>
  </svn-center-content>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {useRouter, useRoute} from 'vue-router'
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import axiosService from "@/tools/axios-service.js";
import { useSnackbar } from "@/store/snackbar.js";
import BktLogoLogin from '@/components/BktLogoLogin.vue';
import { Icon } from '@iconify/vue';
import DialogRequestDemo from '../../components/BktPopUp/Dialogs/DialogRequestDemo.vue';

const snackbar = useSnackbar();
snackbar.checkStatus();

const router = useRouter()
const route = useRoute()

const {email} = storeToRefs(useUserStore())
const password = ref()
const loginLoading = ref(false)
const emailError = ref({ error: false, errorMessage: '', hideDetails: true })
const passwordError = ref({ error: false, errorMessage: '', hideDetails: true })

onMounted(async() => {

  if (route.query?.creds) {
    var auth = route.query?.creds
    auth = JSON.parse(auth);

    localStorage.setItem('bktAccess', JSON.stringify(auth));


    if (await axiosService.verifyToken(auth)) {
      await router.push({name: 'home'})
    } else {
      snackbar.setDefaultColor('failed')
          .displaySnackBar('Couldn\'t connect sorry...')
    }
  }
});

const signIn = async () => {
  try {
    loginLoading.value = true
    if (!email.value || !password.value) {
      snackbar.setDefaultColor('failed')
        .displaySnackBar('Fill the fields please')
      return
    }

    await axiosService.emailSignIn({
      email: email.value,
      password: password.value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })

    await router.push({name: 'home'})
  } catch (e) {
    snackbar.setCustomClass('mb-[60px]')
    snackbar.setDefaultColor('failed')
      .displaySnackBar('Email or password incorrect. Please try again.')
    emailError.value = { error: true, errorMessage: "", hideDetails: true }
    passwordError.value = { error: true, errorMessage: "", hideDetails: true }
  } finally {
    loginLoading.value = false
  }
}

const resetPass = async () => {
  if (!email.value) {
    snackbar.setDefaultColor('failed')
      .displaySnackBar('Email can\'t be blank')
    return
  }

  try {
    await axiosService.resetPass(email.value)
    window.localStorage.setItem("bktPass", JSON.stringify({
      email: email.value,
      timer: 0,
      mode: 'reset'
    }))
    await router.push({name: 'email_sent_reset'})
  } catch (error) {
    snackbar.setCustomClass('mb-[60px]')
    snackbar.setDefaultColor('failed')
      .displaySnackBar('This email is not recognized. Please try again.')
    emailError.value = { error: true, errorMessage: "Email not found, please try again !", hideDetails: false }
  }
}

</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;

}

:deep(.v-field) {
  border-radius: 8px!important;
}

</style>
