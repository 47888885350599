import { defineStore } from "pinia";
import axiosService from "@/tools/axios-service.js";

export const useLearnMyLearningsStore = defineStore({
  id: "learn-my-learnings",
  state: () => ({
    id: null,
    myLearningsTodo: null,
    myLearningsCompleted: null,
    myLearningsModulesToDiscover: []
  }),
  getters: {},
  actions: {
    async fetchMyLearningsTodo(page = 1) {
      const res = await axiosService.get(`api/v1/learn/my_learnings`, {
        params: {
          status: 'todo',
          "page[number]": page,
        }
      })
      this.myLearningsTodo = res.data.my_learnings
      return res
    },
    async fetchMyLearningsCompleted(page = 1) {
      const res = await axiosService.get(`api/v1/learn/my_learnings`, {
        params: {
          status: 'completed',
          "page[number]": page,
        }
      })
      this.myLearningsCompleted = res.data.my_learnings
      return res
    },
    async fetchModulesToDiscover() {
      const res = await axiosService.get('api/v1/learn/my_learnings/modules_to_discover')
      this.myLearningsModulesToDiscover = res.data
    }
  },
});
