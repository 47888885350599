
<template>
  <div
    v-if="isFeedBack360() || isSurvey()"
    class="grid justify-items-end"
  >
    <div class="flex items-center">
      <svn-tooltip>
        <template #activator-content>
          <Icon
            icon="mdi:information-outline"
            class="text-dark-grey h-4 w-4"
          />
        </template>

        <template #tooltip-content>
          {{ $t('If requirement is enable, participants will have to answer it before submit') }}
        </template>
      </svn-tooltip>

      <div class="mx-2">
        {{ $t('Required') }}
      </div>
      <v-switch
        :model-value="requiredFor"
        inset
        color="primary"
        value="Manager"
        hide-details
        :disabled="isDeleted"
        @update:model-value="format('update:required-for', $event)"
      />
    </div>
  </div>
  <div
    v-else
    class="flex justify-between"
  >
    <div>
      <p>{{ $t('This question is visible for') }}:</p>
      <v-switch
        :model-value="visibleFor"
        color="primary"
        :label="$t('Interviewer')"
        value="Manager"
        hide-details
        :disabled="isDeleted"
        @update:model-value="format('update:visible-for', $event)"
      />
      <v-switch
        :model-value="visibleFor"
        color="primary"
        :label="$t('Interviewee')"
        value="Employee"
        hide-details
        :disabled="isDeleted"
        @update:model-value="format('update:visible-for', $event)"
      />
    </div>

    <div>
      <p>{{ $t('This question is required for') }}:</p>
      <v-switch
        :model-value="requiredFor"
        color="primary"
        :label="$t('Interviewer')"
        value="Manager"
        hide-details
        :disabled="isDeleted"
        @update:model-value="format('update:required-for', $event)"
      />
      <v-switch
        :model-value="requiredFor"
        color="primary"
        :label="$t('Interviewee')"
        value="Employee"
        hide-details
        :disabled="isDeleted"
        @update:model-value="format('update:required-for', $event)"
      />
    </div>
  </div>
</template>

<script setup>

import useTools from "@/tools/useTools.js";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {Icon} from "@iconify/vue";

const props = defineProps({
  isDeleted: { type: Boolean, required: true },
  visibleFor: {type: Array, required: true},
  requiredFor: {type: Array, required: true}
});

// defineProps(['visibleFor', 'requiredFor'])

const emit = defineEmits([
  'update:visible-for',
  'update:visible-for-formated',
  'update:required-for',
  'update:required-for-formated',
])

const tools = useTools()

const { isFeedBack360, isSurvey } = useInterviewFormStore()

const format = (eventName, event) => {
  emit(eventName, event)
  let formated
  if (isFeedBack360() || isSurvey()) {
    if(event.includes('Manager')) {
        formated = 'all'
    } else {
        formated = 'none'
    }
  } else {
    formated = tools.deduceToBackend(event)
  }

  emit(`${eventName}-formated`, formated)
}

// const visibleFor = ref([])
// const requiredFor = ref([])
</script>
