<template>
  <div class="w-full flex flex-col justify-center items-start gap-4 self-stretch rounded-[8px] border-[0.5px] border-lightGrey shadow lg:!justify-start lg:!gap-12">
    <!-- Training completion - All -->
    <div class="flex flex-col justify-center items-start self-stretch p-4 gap-1 lg:!p-8 lg:border-b-2 lg:border-b-lightGrey">
      <p class="text-fakeBlack text-xl font-medium">
        {{ $t('Training completion - All') }}
      </p>

      <p class="text-darkGrey text-sm font-normal">
        {{ $t('Quick view of your training completion') }}
      </p>
    </div>

    <!-- Completion Chart and legend -->
    <div class="flex items-center self-stretch p-4">
      <!-- Chart -->
      <BktDoughnut
        inner-class="w-[358px] h-[252px]"
        chart-class="w-[358px] h-[252px]"
        :datas="trainingCompletionAllData.completionAllData"
        :labels="[$t('Acquired'), $t('In progress'), $t('Not started')]"
        :colors="trainingCompletionAllData.chartColors"
        :plugins="trainingCompletionAllData.chartCompletionAllPlugins"
        :chart-options="trainingCompletionAllData.chartCompletionAllOptions"
      />
    </div>
  </div>
</template>

<script setup>
import BktDoughnut from '@/components/chart/BktDoughnut.vue';
import {onMounted, ref} from "vue";

const props = defineProps({
  trainingCompletionAll: {type: Object, required: true},
})

const trainingCompletionAllData = ref({
  completionAllData: [props.trainingCompletionAll.acquired, props.trainingCompletionAll.in_progress, props.trainingCompletionAll.not_started],
  chartColors: ['#7BC079', '#FFC85C', '#FF5656', '#787878'],
  chartCompletionAllOptions: {
    cutout: "75%",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        align: "start",
        labels: {
          boxWidth: 21,
          boxHeight: 8,
          color: '#4F4F4F',
          font: {
            weight: 400,
          }
        },
        title: {
          display: false,
        },
        padding: {
          x: 0,
          y: 0
        }
      },
      datalabels: {
        color: '36A2EB',
      }
    },
  },
  chartCompletionAllPlugins: [{
    id: 'doughnutLabel',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;

      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x
      const yCoor = chart.getDatasetMeta(0).data[0].y
      ctx.font = '500 48px sans-serif';
      ctx.fillStyle = '#333333'
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText( props.trainingCompletionAll.all , xCoor, yCoor);
    }
  }]
})
</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>