<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <template #activator="{ props }">
      <v-btn
        color="primary"
        prepend-icon="mdi-eye-outline"
        v-bind="props"
        @click="fetchInterviewForm(route.params.id)"
      >
        {{ $t('see template preview') }}
      </v-btn>
    </template>
    <v-card>
      <v-layout
        full-height
        style="position: fixed; width: 100%;"
      >
        <v-app-bar
          color="surface"
          elevation="4"
          prominent
        >
          <v-app-bar-nav-icon
            variant="text"
            @click.stop="drawer = !drawer"
          />

          <v-toolbar-title>
            <div class="flex w-full">
              <p class="text-center grow">
                {{ title }}
              </p>
            </div>
          </v-toolbar-title>

          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          width="400"
          disable-resize-watcher
          class="!h-auto"
        >
          <div class="w-full break-words">
            <div class="flex justify-center items-center gap-3 w-full text-center font-semibold text-gray-300 text-2xl p-5">
              {{ $t( 'Chapters' ) }}
              <svn-tooltip
                location="bottom start"
                origin="top end"
              >
                <template #activator-content>
                  <Icon
                    icon="mdi:information-outline"
                    class="text-gray-300 h-5 w-5"
                  />
                </template>

                <template #tooltip-content>
                  {{ $t('Template used: ') }} {{ title }}
                </template>
              </svn-tooltip>
            </div>

            <div
              v-for="(item, i) in headlines"
              :key="i"
              class="w-full"
              :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
            >
              <div
                v-if="item.type === InterviewQuestionType.CHAPTER"
                class="uppercase font-semibold break-words px-10 pt-6 pb-1.5"
              >
                {{ item.Chapterpos }}. {{ item.title }}
              </div>

              <v-btn
                v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
                :value="item"
                class="flex justify-start active:bg-blue-100 bg-transparent focus:shadow-none w-full shadow-none hover:shadow-none rounded-none break-words"
                @click="scrollToElement(i)"
              >
                <div class="flex w-full px-5">
                  <v-icon
                    :icon="item.icon"
                    size="1em"
                    class="mr-1 bkt-blue inline-block align-middle"
                  />
                  <span class="first-letter:uppercase normal-case truncate">
                    {{ item.title }}
                  </span>
                </div>
              </v-btn>
            </div>
          </div>
        </v-navigation-drawer>

        <v-main
          class="overflow-y-auto flex justify-center items-start bg-neutral-100"
        >
          <div class="w-10/12">
            <interview-template-show-list
              :title="title"
              :description="description"
              :interview-questions="interviewQuestions"
              :video="video"
              :template-type="templateType"
            />
          </div>
        </v-main>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {computed, ref, watch } from "vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import InterviewTemplateShowList from "@/components/interviewApp/template/Show/InterviewTemplateShowList.vue";
import {useMobileStore} from "@/store/mobile.js";
import {Icon} from "@iconify/vue";
import { InterviewQuestionType } from '@/constants/types';

const dialog = ref(false)
const {isLargeScreen} = storeToRefs(useMobileStore())

const route = useRoute()
const { fetchInterviewForm } = useInterviewFormStore()
const { deduceMatIconFromInterviewQuestionType } = useTools()
const { title, description, interviewQuestions, id, video, templateType } = storeToRefs(useInterviewFormStore())

const drawer = ref(isLargeScreen)

const chapters = computed(() => {
  return interviewQuestions?.value?.filter(question => question?.type === InterviewQuestionType.CHAPTER)
})

const headlines = computed(() => {
  return interviewQuestions.value.map((question) => ({
    id: question?.id,
    title: question?.text,
    type: question?.type,
    icon: deduceMatIconFromInterviewQuestionType(question?.type),
    Chapterpos: question?.type === InterviewQuestionType.CHAPTER ? chapters?.value?.indexOf(question) + 1 : null,
  }))
})

const scrollToElement = (index) => {
  let element = document.getElementById(index)
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
</script>

<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}

.v-btn :deep(.v-btn__overlay){
  display: none;
}

.v-btn :deep(.v-btn__content){
  width: 100%;
}
</style>
