<template>
  <div
    class="flex flex-col items-center justify-center self-stretch w-full sm:max-w-[348px] md:max-w-[472px] h-[302px] p-6
    lg:max-w-[378px] xl:max-w-[341px] 2xl:w-full rounded-[12px] border border-darkGrey
    hover:shadow-[0_1px_5px_rgba(0,0,0,0.20)] gap-4"
  >
    <!-- Only 1 module in the playlist -->
    <div
      class="flex flex-col justify-center items-center h-[190px] w-full bg-cover rounded-[12px] relative cursor-pointer"
      :class="playlistBackgrounds?.length === 0 ? 'bg-lightGrey' : ''"
      :style="{'background-image': playlistBackgrounds?.length === 1 ?
        `url('${playlistBackgrounds?.[0]?.['500']}')` : '', 'background-size' : 'cover'}"
    >
      <div
        v-if="playlistBackgrounds?.length === 1"
        class="h-full w-full"
        @click="goToPlaylistShow"
      />
      <!-- 2 modules in the playlist -->
      <div
        v-if="playlistBackgrounds?.length === 2"
        class="w-full h-full flex flex-row rounded-[12px] overflow-hidden"
        @click="goToPlaylistShow"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[0]?.['500']}')`, 'background-size' : 'cover'}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[1]?.['500']}')`, 'background-size' : 'cover'}"
        />
      </div>

      <!-- 3 modules in the playlist -->
      <div
        v-if="playlistBackgrounds?.length === 3"
        class="w-full h-full flex flex-col rounded-[12px] overflow-hidden"
        @click="goToPlaylistShow"
      >
        <div class="flex w-full h-1/2">
          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${playlistBackgrounds?.[0]?.['500']}')`, 'background-size' : 'cover'}"
          />

          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${playlistBackgrounds?.[1]?.['500']}')`, 'background-size' : 'cover'}"
          />
        </div>

        <div
          class="flex flex-col justify-center items-center w-full h-1/2 bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[2]?.['500']}')`, 'background-size' : 'cover'}"
        />
      </div>

      <!-- At least 4 modules in the playlist -->
      <div
        v-if="playlistBackgrounds?.length >= 4"
        class="w-full h-full flex flex-wrap rounded-[12px] overflow-hidden"
        @click="goToPlaylistShow"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[0]?.['500']}')`, 'background-size' : 'cover'}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[1]?.['500']}')`, 'background-size' : 'cover'}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${playlistBackgrounds?.[2]?.['500']}')`, 'background-size' : 'cover'}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover relative bg-[50%] grayscale"
          :style="{'background-image': `url('${playlistBackgrounds?.[3]?.['500']}')`, 'background-size' : 'cover'}"
        >
          <div
            v-if="playlistBackgrounds?.length > 4"
            class="absolute left-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-[0.5]"
          />

          <p
            v-if="playlistBackgrounds?.length > 4"
            class="!text-[#FFFFFF] text-xl font-medium z-[9999]"
          >
            +{{ playlistBackgrounds?.length - 3 }}
          </p>
        </div>
      </div>

      <!-- No module in the playlist -->
      <div
        v-if="playlistBackgrounds?.length === 0"
        class="flex items-center justify-center h-full w-full"
        @click="goToPlaylistShow"
      >
        <Icon
          icon="ic:outline-play-circle"
          height="48"
          width="48"
          class="text-middleGrey"
        />
      </div>

      <!-- Card buttons -->
      <div class="flex items-center gap-x-2 p-1 absolute top-4 right-4">
        <!-- Favorite button -->
        <svn-icon-button
            :icon="isFavorite ? 'mdi:cards-heart' : 'mdi:cards-heart-outline'"
            variant="noOutlined"
            circle
            button-size="lg"
            color-class="!bg-white"
            :class="isFavorite ? 'text-negativeRed' : 'text-fakeBlack'"
            @click="toggleFavorite"
          />

        <!-- Copy link button -->
        <svn-icon-button
            icon="mdi:link-variant"
            variant="noOutlined"
            circle
            button-size="lg"
            color-class="!bg-white"
            class="text-fakeBlack"
            @click="copyPlaylistLink"
          />

        <!-- Options button -->
        <div
          v-if="amICreatorOfEntityOrAdmin(playlistCreatorId)"
          class="flex flex-col justify-center items-center w-10 h-10 p-1
            bg-white hover:bg-[#FFFFFF] rounded-full overflow-hidden"
        >
          <svn-dots-button
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="edit_playlist"
                @click="editPlaylist"
              >
                {{ $t('Edit playlist') }}
              </v-list-item>

              <v-list-item
                value="delete_playlist"
                class="text-negativeRed"
                @click="deletePlaylist"
              >
                {{ $t('Delete playlist') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>
    </div>

    <!-- PLaylist Info -->
    <div
      class="flex flex-col items-start self-stretch gap-1 cursor-pointer"
      @click="goToPlaylistShow"
    >
      <!-- Playlist title -->
      <p class="text-fakeBlack text-sm font-medium line-clamp-1">
        {{ title }}
      </p>

      <!-- Playlist & themes count -->
      <div class="flex justify-between items-center gap-1 self-stretch">
        <p class="text-darkGrey text-sm font-medium">
          {{ moduleCount }} {{ (moduleCount === 0 || moduleCount === 1) ?
            $t('module') : $t('modules') }}
        </p>

        <!-- Only 1 theme -->
        <div
          v-if="themeList && themeList?.length === 1"
          class="flex justify-end items-start content-start gap-2 flex-1 flex-wrap"
        >
          <div
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft w-fit"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              {{ themeList?.[0] }}
            </p>
          </div>
        </div>

        <!-- More than 1 theme -->
        <div
          v-else-if="themeList && themeList?.length > 1"
          class="flex justify-end items-start content-start gap-2 flex-1 flex-wrap w-2/3"
        >
          <div
            v-for="(theme) in slicedThemesList"
            :key="theme"
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft max-w-fit w-2/3"
          >
            <p class="text-xs font-normal px-2 py-[6px] text-fakeBlack text-center truncate">
              {{ theme }}
            </p>
          </div>

          <div
            v-if="(themeList?.length - slicedThemesList?.length) > 0"
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              +{{ themeList?.length - slicedThemesList?.length }}
            </p>
          </div>
        </div>

        <!-- if there are no themes -->
        <div
          v-else
          class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
        >
          <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
            {{ $t('No theme') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user.js";
import { useRouter } from "vue-router";

const { isMobile } = storeToRefs(useMobileStore());

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  id: { type: Number, default: null },
  playlistCreatorId: { type: Number, default: null },
  playlistBackgrounds: { type: Array, default: null },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  moduleCount: { type: Number, default: null },
})

const router = useRouter()

const emit = defineEmits(['toggle-favorite', 'edit-playlist', 'delete-playlist', 'playlist-show'])

const snackbar = useSnackbar()

const copyPlaylistLink = () => {
  const link = router.resolve({  name: 'playlist_show', params: { id: props?.id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.setMsg('Link copied')
    snackbar.displaySnackBar()
  }).catch(() => {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar('Error copying link to clipboard')
  })
}

const slicedThemesList = computed(() => {
  return props?.themeList?.slice(0, 1)
})

const goToPlaylistShow = () => {
  emit('playlist-show')
}

const toggleFavorite = () => {
  emit('toggle-favorite')
}

const editPlaylist = () => {
  emit('edit-playlist')
}

const deletePlaylist = () => {
  emit('delete-playlist')
}
</script>

<style scoped>
.borderCard {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>
