<template>
  <v-card
    class="w-full flex flex-col items-start justify-center p-4 drop-shadow
      md:!p-6 md:rounded-xl"
  >
    <v-card-title
      class="flex flex-col items-center w-full p-0
        md:flex-row md:justify-start"
    >
      <MyInterviewCardHeader
        :campaign-deadline="campaign.deadline"
        :campaign-type="campaign.campaign_type"
        :campaign-name="campaign.title"
      />
    </v-card-title>
    <v-card-text
      class="w-full max-w-[776px] flex flex-col justify-start items-center pt-3 ml-1 space-x-0
        lg:flex-row lg:justify-start lg:items-center lg:space-x-3 md:!pt-6 px-0"
    >
      <MyInterviewCardPeople
        v-if="interviewSet.employee_interview"
        :status="interviewSet.employee_interview.status"
        :person="interviewee"
        type="interviewee"
      />
      <v-divider
        v-if="(interviewSet.employee_interview && interviewSet.manager_interview)"
        thickness="1"
        class="border-opacity-100 w-full divider hidden
          lg:w-px lg:block"
        vertical
      />
      <v-divider
        v-if="(interviewSet.employee_interview && interviewSet.manager_interview)"
        thickness="1"
        class="border-opacity-100 my-3 w-full divider block
          lg:w-px lg:hidden"
      />
      <MyInterviewCardPeople
        v-if="interviewSet.manager_interview"
        :status="interviewSet.manager_interview.status"
        :person="interviewer"
        type="interviewer"
      />
      <v-divider
        v-if="(interviewSet.manager_interview && interviewSet.crossed_interview)"
        thickness="1"
        class="border-opacity-100 w-full divider hidden
          lg:w-px lg:block"
        vertical
      />
      <v-divider
        v-if="(interviewSet.manager_interview && interviewSet.crossed_interview)"
        thickness="1"
        class="border-opacity-100 my-3 w-full divider block
          lg:w-px lg:hidden"
      />
      <MyInterviewCardPeople
        v-if="interviewSet.crossed_interview"
        :status="interviewSet.crossed_interview.status"
        :person="{
          avatar: interviewee?.avatar?.['30'],
          fullname: interviewee?.fullname,
          firstname: interviewee?.firstname,
          lastname: interviewee?.lastname,
        }"
        :other-person="interviewer"
        type="cross"
      />
    </v-card-text>
    <v-card-actions
      class="w-full flex flex-row justify-center
        md:!justify-end p-0"
    >
      <v-btn
        v-if="props.interviewSet?.employee_interview"
        data-testid="actions"
        class="px-4 normal-case w-full text-xs
          md:w-fit md:text-sm"
        :variant="buttonVariant()"
        color="primary"
        :append-icon="interviewSet?.employee_interview &&
          (buttonSentence() === 'Start my interview' || buttonSentence() === 'Continue my interview') ?
            'mdi-arrow-right' : ''"
        @click="clickInterview"
      >
        {{ $t(buttonSentence()) }}
      </v-btn>
      <p
        v-else
        data-testid="no-actions"
        class="text-darkGrey  normal-case w-full text-xs
          md:w-fit md:text-sm"
      >
        {{ $t("You have no action to do on this interview.") }}
      </p>
    </v-card-actions>
  </v-card>
</template>

<script setup>
  import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
  import MyInterviewCardPeople from "@/components/interviewApp/InterviewCardPeople.vue";
  import {useMobileStore} from "@/store/mobile.js";
  import {storeToRefs} from "pinia";
  import router from "@/router/index.js";

  // Props
  const props = defineProps({
    id: {type: Number, required: true},
    campaign: {type: Object, required: true},
    interviewee: {type: Object, required: true},
    interviewer: {type: Object, required: true},
    interviewSet: {type: Object, required: true},
    crossedInterviewId: {type: Number, required: true},
  })

  // Refs
  const { isMobile } = storeToRefs(useMobileStore())

  // Functions
  const buttonSentence = () => {
    if (props.interviewSet?.crossed_interview?.status === "submitted")
      return "View Cross Review answers"
    else if (props.interviewSet?.employee_interview) {
      switch (props.interviewSet?.employee_interview?.status) {
        case "submitted":
          return "View my answers"
        case "in_progress":
          return "Continue my interview"
        case "not_started":
          return "Start my interview"
        default:
          return ""
      }
    }
    return "You have no action to do on this interview.";
  }

  const buttonVariant = () => {
    if (props.interviewSet?.crossed_interview?.status === "submitted")
      return "outlined"
    else if (props.interviewSet?.employee_interview) {
      switch (props.interviewSet?.employee_interview?.status) {
        case "submitted":
          return "outlined"
        case "in_progress":
          return "flat"
        case "not_started":
          return "flat"
        default:
          return "plain"
      }
    }
    return "plain";
  }

  const clickInterview = () => {
    if (props.interviewSet?.crossed_interview?.status === "submitted") {
      router.push({name:"interview_answer", params: { id: props.crossedInterviewId }})
    } else {
      router.push({name:"interview_answer", params: { id: props.id }})
    }
  }
</script>

<style scoped>
  .divider {
    border-top: 1px solid rgb(var(--v-theme-middleGrey));
  }

  @media (min-width: 640px) {
    .divider {
      border-right: 1px solid rgb(var(--v-theme-middleGrey));
    }
  }
</style>
