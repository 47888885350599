<template>
  <bkt-pop-up-modal-skeleton
    ref="modalRef"
    fullscreen
    two-line
  >
    <template #button>
      <div class="w-full flex sm:justify-end">
        <v-btn
          class="normal-case text-xs w-full sm:w-[296px]"
          variant="flat"
          color="primary"
          @click="submissionStatus === 'acquired' ? retryQuiz() : startQuiz()"
        >
          {{ submissionStatus === 'not_started' ?  $t('Start the quiz !') : $t('Retake quiz') }}
        </v-btn>
      </div>
    </template>

    <template #title>
      <div class="w-full font-semibold text-center leading-0 sm:leading-6 text-xl sm:text-2xl">
        {{ learnModule.title }}
      </div>
    </template>
    <template #body-content>
      <div class="flex flex-col gap-8 gap-h-full pb-[152px] overflow-y-auto lg:py-8 relative">
        <!-- <div class="hidden lg:flex">
          <bkt-breadcrumbs>
            {{ learnModule.title }}
          </bkt-breadcrumbs>
        </div> -->

        <div class="flex flex-col lg:w-[758px] mx-auto p-4 lg:!px-[0px] gap-6 lg:gap-8 w-full">
          <div
            id="top_anchor"
            class="flex flex-col gap-6"
          >
            <!-- Module Title -->
            <div class="flex flex-col gap-1 text-center w-full items-center justify-center">
              <div class="font-semibold text-2xl lg:text-3xl text-fake-black">
                {{ $t('Quiz') }}
              </div>

              <div class="font-medium text-xl lg:text-2xl truncate">
                {{ learnModule.title ? learnModule.title : $t('Module title') }}
              </div>
            </div>

            <!-- Quiz Result -->
            <div
              v-if="showQuizResults && !previewOnly"
              class="flex flex-col px-6 py-8 gap-4 items-center self-stretch"
            >
              <!-- Winking face Gif -->
              <iframe
                :src="getResultGif()"
                width="96"
                height="94"
              />

              <!-- Text -->
              <p class="text-fakeBlack font-montserrat text-center text-[24px] font-semibold">
                {{ getResultText() }}
              </p>

              <!-- Progress value in % -->
              <div class="flex justify-start items-end relative">
                <p class="text-trainingOrange font-montserrat text-[50px] font-medium">
                  {{ Math.floor(resultPercentage) }}
                </p>

                <p class="text-trainingOrange font-montserrat text-[24px] font-bold absolute -right-[20px] bottom-3">
                  %
                </p>
              </div>

              <!-- Progress bar -->
              <div class="w-full">
                <v-progress-linear
                  v-model="progress"
                  height="10"
                  color="primary"
                  bg-color="lightGrey"
                  :rounded="true"
                  :rounded-bar="true"
                />
              </div>
            </div>

            <!-- Quiz try again -->
            <div
              v-if="showQuizResults && !previewOnly"
              class="flex flex-col items-start self-stretch gap-2"
            >
              <!-- Try again block -->
              <div class="flex flex-col items-start self-stretch gap-[10px] p-6 rounded-[8px] border border-darkGrey">
                <p
                  class="text-fakeBlack text-xl font-medium"
                >
                  {{ getResultRetryText() }}
                </p>

                <p
                  v-if="progress === 100"
                  class="text-darkGrey text-sm font-normal"
                >
                  {{ $t("Congratulations, you just acquired a new module, keep going like this !") }}
                </p>

                <p
                  v-else
                  class="text-darkGrey text-sm font-normal"
                >
                  {{ $t("You can try this quiz as much as you want. Having all correct answers will make this module") }}
                  <span class="font-bold text-fakeBlack">{{ $t('acquired') }}</span>
                  {{ $t("for you. Let's up your right answers rate to 100% !") }}
                </p>
              </div>

              <!-- Buttons -->
              <div class="w-full flex flex-col items-end self-stretch gap-[7px]">
                <!-- Retry Mobile -->
                <v-btn
                  v-if="resultPercentage !== 100"
                  variant="flat"
                  color="primary"
                  class="normal-case text-xs self-center flex lg:hidden"
                  :text="$t('Retry quiz')"
                  append-icon="mdi-repeat"
                  @click="retryQuiz"
                />

                <div class="flex gap-2">
                  <!-- Back to module -->
                  <v-btn
                    v-if="resultPercentage !== 100"
                    variant="outlined"
                    color="primary"
                    class="normal-case text-xs"
                    :text="$t('Back to the module')"
                    prepend-icon="mdi-arrow-left"
                    @click="closeModal"
                  />

                  <!-- Back to training if you came from one -->
                  <v-btn
                    v-if="route?.query?.trainingId && resultPercentage === 100"
                    variant="outlined"
                    color="primary"
                    class="normal-case text-xs"
                    :text="$t('Back to training')"
                    @click="closeModalAndBackToTraining"
                  />

                  <!-- Retry Large screen -->
                  <v-btn
                    v-if="resultPercentage !== 100"
                    variant="flat"
                    color="primary"
                    class="normal-case text-xs self-center hidden lg:flex"
                    :text="$t('Retry quiz')"
                    append-icon="mdi-repeat"
                    @click="retryQuiz"
                  />
                  <!-- Next module or Back to My Learnings -->
                  <v-btn
                    :variant="resultPercentage === 100 ? 'flat' : 'outlined'"
                    color="primary"
                    class="normal-case text-xs"
                    :text="route?.query?.trainingId && nextModuleId ?
                      $t('Go to next module') : $t('Go back to the catalog')"
                    append-icon="mdi-arrow-right"
                    @click="goToNextModuleOrBackToLearnings"
                  />
                </div>
              </div>
            </div>

            <!-- Questions List -->
            <div class="flex flex-col items-start gap-4 self-stretch">
              <!-- {{ inputs }} -->
              <quiz-question-card
                v-for="(quizQuestion, index) in inputs"
                :key="index"
                :question="quizQuestion"
                :status="quizQuestion?.status"
                :show-results="showQuizResults"
              />

              <v-btn
                v-if="!showQuizResults && inputs.some(input => input.type === LearnInputType.CHECKBOX)"
                variant="flat"
                color="primary"
                :text="$t('Validate answers')"
                :disabled="checkSelectedValues"
                class="normal-case text-xs w-full"
                @click="validateQuiz()"
              />
            </div>
          </div>
        </div>

        <!-- Scroll to top button -->
        <svn-floating-action-button
          square
          to-top
        />

        <dialog-training-completed
          :opened="opened"
        />
      </div>
    </template>
  </bkt-pop-up-modal-skeleton>
</template>

<script setup>
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import DialogTrainingCompleted from "../../Dialogs/learn/DialogTrainingCompleted.vue";
import QuizQuestionCard from "@/components/trainingApp/quiz/QuizQuestionCard.vue";
import { onMounted, ref, computed, watch } from "vue";
import i18n from "@/plugins/i18n.js";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { useRoute, useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useLearnTrainingStore } from "@/store/learn-trainings.js";
import { useSnackbar } from "@/store/snackbar";
import { LearnInputType } from '@/constants/types';

const { firstname } = storeToRefs(useUserStore())

onMounted(async () => {

  if (route?.query?.trainingId) {
    try {
      await fetchTraining(route?.query?.trainingId)
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error fetching training')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
      snackbar.displaySnackBar()
    }
  }
})
const emit = defineEmits(['start-quiz', 'toggle-proposition', 'validate-quiz', 'retry-quiz', 'recall-mounted-method'])

const props = defineProps({
  inputs: { type: Array, required: false },
  learnModule: { type: Object, required: false },
  nextModuleId: { type: Number, required: false },
  learnResponsesCheckboxes: { type: Object, required: false },
  submission: { type: Object, required: false },
  submissionStatus: {type: String, default: 'not_started'},
  piecesQuizId: { type: Number, required: false },
  resultPercentage: { type: Number, required: false },
  showQuizResults: { type: Boolean, required: false },
  previewOnly: { type: Boolean, required: false },
})

const startQuiz = async () => {
  emit('start-quiz')
}

const { fetchTraining } = useLearnTrainingStore()

const { isMobile } = storeToRefs(useMobileStore())

const { learnTraining } = storeToRefs(useLearnTrainingStore())

const opened = ref(false)

const snackbar = useSnackbar()

const route = useRoute()

const router = useRouter()

const alignTop = ref(false)

const modalRef = ref(null)

defineExpose({
  modalRef
})

if (props?.submission) {
  watch(props.submission, (newValue, oldValue) => {
    if (newValue?.status === 'acquired') {
      opened.value = true
    }
    else {
      opened.value = false
    }
  })
}

const progress = computed(() => {
  return props.resultPercentage
})

const retryQuiz = () => {
  emit('retry-quiz')
}

const checkSelectedValues = computed(() => {
  for (const data of props.learnResponsesCheckboxes) {
    if (data.selected_values.length === 0) {
      return true; // If any selected_values is empty, return true
    }
  }
  return false; // If all selected_values have one or more values, return false
})

const getResultGif = () => {
  if (props.resultPercentage === 0) {
    return 'https://lottie.host/?file=432b1780-2ee6-4a4e-9622-17770ceca77f/yiKfozGfu6.json'
  }
  else if (props.resultPercentage > 0  && props.resultPercentage < 100) {
    return 'https://lottie.host/?file=9f50f3e5-a574-4f28-9e11-401c5def640c/mboM5X5ngu.json'
  }
  else {
    return 'https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json'
  }
}

const getResultText = () => {
  if (props.resultPercentage === 0) {
    return i18n.global.t('What happened ?')
  }
  else if (props.resultPercentage > 0  && props.resultPercentage < 100) {
    return i18n.global.t('Keep going ! Check your mistakes and try again 💪')
  }
  else {
    return i18n.global.t('Only right answers ! Congratulations, 👏', { firstname: firstname?.value })
  }
}

const getResultRetryText = () => {
  if (props.resultPercentage === 100) {
    return i18n.global.t('👌 Here is one more module acquired')
  }
  else {
    return i18n.global.t('💪 Would you like to try again ?')
  }
}

const validateQuiz = () => {
  document.getElementById('top_anchor').scrollIntoView({behavior: 'smooth'})
  emit('validate-quiz')
}

const closeModal = () => {
  modalRef.value.dialog = false
}

const closeModalAndBackToTraining = () => {
  modalRef.value.dialog = false
  router.push({ name: 'training_show', params: { id: route?.query?.trainingId } })
}

const goToNextModuleOrBackToLearnings = () => {
  if (route?.query?.trainingId && props.nextModuleId) {
    router.push({ name: 'module_show', params: { id: props.nextModuleId }, query: {trainingId: route?.query.trainingId }}).then(() => {
      emit('recall-mounted-method')
    })
  }
  else {
    router.push({ name: 'my_learnings' })
  }
}

</script>
