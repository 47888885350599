<template>
  <bkt-pop-up-dialog-skeleton
    ref="dialogModuleSuccess"
    :bind="!fullyUnderstood"
    button-secondary-color="primary"
    :display-secondary-button="false"
    primary-button-class="w-full"
    :display-close="false"
    :width="isMobile ? 300 : 416"
    vertical
    full-width-activator
    @click-primary-button="goToNextModule"
  >
    <template #icon>
      <div class="flex items-center justify-center">
        <iframe
          src="https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json"
          width="56"
          height="56"
        />
      </div>
    </template>

    <template #title>
      <div class="flex gap-4 flex-col w-auto sm:!w-[352px]">
        <p class="text-xl	 font-medium text-center">
          {{ $t('One more module acquired !👏') }}
        </p>
        <p class="text-sm	text-darkGrey font-normal">
          {{ $t('Congratulations, name, keep it up !', { name: firstname }) }}
        </p>
      </div>
    </template>

    <template #primary-button-body>
      <div class="flex gap-2 items-center text-xs">
        <p v-if="nextModuleId">
          {{ $t('Go to next module') }}
        </p>

        <p v-else>
          {{ $t('Go back to the catalog') }}
        </p>

        <div v-if="nextModuleId">
          <Icon
            icon="mdi:arrow-forward"
            class="h-4 w-4"
          />
        </div>
      </div>
    </template>

    <template #button>
      <slot name="button">
        <div class="w-full flex sm:justify-end">
          <v-btn
            variant="flat"
            color="primary"
            class="normal-case text-xs w-full sm:!w-[296px]"
            @click="$emit('fullyUnderstood')"
          >
            {{ $t(fullyUnderstood ? 'Update acquisition date' : `Yes, it's all clear to me`) }}
          </v-btn>
        </div>
      </slot>
    </template>
  </bkt-pop-up-dialog-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useMobileStore } from "@/store/mobile";
import BktPopUpDialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue";
import {useUserStore} from "@/store/user.js";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  fullyUnderstood: { type: Boolean, default: false },
  nextModuleId: { type: Number, default: null },
})

const emit = defineEmits(['fullyUnderstood', 'recall-mounted-method', 'close-parent-dialog'])

const { isMobile } = storeToRefs(useMobileStore())

const router = useRouter()

const route = useRoute()

const { firstname } = storeToRefs(useUserStore())

const dialogModuleSuccess = ref(null)

const goToNextModule = () => {
  dialogModuleSuccess.value.dialog = false

  if (props.nextModuleId) {
    if (route?.query.trainingId) {
      router.push({ name: 'module_show', params: { id: props.nextModuleId }, query: {trainingId: route?.query.trainingId }}).then(() => {
        emit('recall-mounted-method')
      })
    } else if (route?.query.playlistId) {
      router.push({ name: 'module_show', params: { id: props.nextModuleId }, query: {playlistId: route?.query.playlistId }}).then(() => {
      emit('recall-mounted-method')
      })
    }
  }

  else {
    router.push({ name: 'my_learnings' }).then(() => {})
  }

  emit('close-parent-dialog')
}

</script>
