
<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <v-btn
        color="primary"
        variant="text"
        prepend-icon="mdi-help-circle-outline"
      >
        {{ $t('Open question') }}
      </v-btn>
    </template>
    <template #body>
      <v-text-field
        v-model="interviewQuestion.text"
        :label="$t('Open question name')"
        color="primary"
        :disabled="isDeleted"
        @update:model-value="updateQuestion(interviewQuestion.id, {text: $event})"
      />

      <div class="h-40 mb-16">
        <quill-editor
          v-model:content="interviewQuestion.description"
          content-type="html"
          :placeholder="$t('Description')"
          :enable="!isDeleted"
          @update:content="updateQuestion(interviewQuestion.id, {description: $event})"
        />
      </div>

      <template-question-visible-required-for
        v-model:visible-for="visibleFor"
        v-model:required-for="requiredFor"
        :is-deleted="isDeleted"
        @update:visible-for-formated="updateQuestion(interviewQuestion.id, {visible_for: $event})"
        @update:required-for-formated="updateQuestion(interviewQuestion.id, {required_for: $event})"
      />
    </template>
  </template-header-question>
</template>

<script setup>
import {ref, watch} from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import TemplateQuestionVisibleRequiredFor
  from "@/components/interviewApp/template/Edit/TemplateQuestionVisibleRequiredFor.vue";
import useTools from "@/tools/useTools.js";
import {QuillEditor} from "@vueup/vue-quill";

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})
const {updateQuestion } = useInterviewFormStore()
const tools = useTools()

const visibleFor = ref(tools.deduceForWhom(props.interviewQuestion.visible_for))
const requiredFor = ref(tools.deduceForWhom(props.interviewQuestion.required_for))

watch(requiredFor, (newVal, oldVal) => {
  for (const newValKey in newVal) {
    if (!visibleFor.value.includes(newVal[newValKey])) {
      visibleFor.value.push(newVal[newValKey])

      updateQuestion(props.interviewQuestion.id, {visible_for: tools.deduceToBackend(visibleFor.value)})
    }
  }
})

watch(visibleFor, (newVal, oldVal) => {
  for (const newValKey in requiredFor.value) {
    if (!newVal.includes(requiredFor.value[newValKey])) {
          const index = requiredFor.value.indexOf(requiredFor.value[newValKey])
          requiredFor.value.splice(index, 1)

          updateQuestion(props.interviewQuestion.id, {required_for: tools.deduceToBackend(requiredFor.value)})
    }
  }
})
</script>
