<template>
  <div class="w-full flex flex-col pb-[80px]">
    <!-- Bandeau rouge si c'est trash -->
    <trash-warnning
      v-if="isDeleted"
      title="This playlist is in trash."
      @restore="restoreDeletedItems(learnPlaylist?.id)"
      @delete-permanently="deletePermanentlyDeletedItems(learnPlaylist?.id)"
    />

    <!-- Title & buttons -->
    <div class="w-full flex flex-row justify-center items-center relative pt-2 px-2 h-fit md:!h-[164px]">
      <!-- Buttons -->
      <div
        v-if="!isDeleted"
        class="flex items-center gap-2 absolute top-2 right-2 sm:!top-[43px] sm:!right-[5%]"
      >
        <!-- Favorite button -->
        <v-hover>
          <template #default="{ props }">
            <svn-tooltip
              v-bind="props"
            >
              <template #activator-content>
                <svn-icon-button
                  :icon="learnPlaylist?.connected_user_favorited ? 'mdi:cards-heart' : 'mdi:cards-heart-outline'"
                  variant="noOutlined"
                  circle
                  :button-size="!isMobile ? 'xl' : 'lg'"
                  color-class="!bg-white "
                  :class="[learnPlaylist?.connected_user_favorited ? 'text-negativeRed ' : 'text-darkGrey', isMdScreen ? 'shadow' : '']"
                  @click="favoritePlaylist"
                />
              </template>

              <template #tooltip-content>
                <span class="text-trainingOrange">
                  {{ $t('Add to favorites') }}
                </span>
              </template>
            </svn-tooltip>
          </template>
        </v-hover>

        <!-- Copy link button  -->
        <v-hover>
          <template #default="{ props }">
            <svn-tooltip
              v-bind="props"
            >
              <template #activator-content>
                <svn-icon-button
                  icon="mdi:link-variant"
                  variant="noOutlined"
                  circle
                  :button-size="!isMobile ? 'xl' : 'lg'"
                  color-class="!bg-white text-darkGrey "
                  :class="isMdScreen ? 'shadow' : ''"
                  @click="copyPlaylistLink"
                />
              </template>

              <template #tooltip-content>
                <span class="text-trainingOrange">
                  {{ $t('Copy link') }}
                </span>
              </template>
            </svn-tooltip>
          </template>
        </v-hover>

        <!-- Edit & delete buttons -->
        <div
          v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
          class="flex flex-col justify-center items-center p-0 md:p-1 bg-veryLightGrey/50 rounded-full"
        >
          <svn-dots-button
            circle
            variant="hoverNoPrimary"
            class="w-[50px] h-[50px] shadow-none sm:shadow-md rounded-full flex items-center justify-center
                cursor-pointer hover:bg-very-light-grey"
          >
            <template #items>
              <v-list-item
                value="edit_playlist"
                @click="editPlaylist(route?.params?.id)"
              >
                {{ $t('Edit playlist') }}
              </v-list-item>

              <v-list-item
                value="delete_playlist"
                class="text-negativeRed"
                @click="openDeletePlaylist(route?.params?.id)"
              >
                {{ $t('Delete playlist') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>

      <!-- Breadcrumbs -->
      <div class="hidden absolute lg:flex lg:px-[180px] -ml-[60%]">
        <!-- <bkt-breadcrumbs>
          {{ learnPlaylist?.title }}
        </bkt-breadcrumbs> -->
      </div>

      <!-- Title -->
      <div class="flex flex-col justify-center w-[300px] pt-[68px] pb-9 sm:!py-[64.5px] sm:!items-center lg:!w-[600px]">
        <p class="text-fakeBlack text-center text-xl font-semibold line-clamp-2">
          {{ learnPlaylist?.title }}
        </p>
      </div>
    </div>

    <div class="w-full flex flex-col justify-center items-center sm:max-w-[758px] sm:mx-auto">
      <!-- Theme, duration & description -->
      <div class="flex flex-col justify-start self-stretch gap-4 px-4">
        <div class="flex flex-col items-center self-stretch pt-4 gap-2">
          <div class="flex justify-between items-start gap-1 self-stretch">
            <!-- Playlist themes list -->
            <div
              v-if="learnPlaylist?.themes?.length"
              class="flex items-center flex-1 gap-2 flex-wrap"
            >
              <div
                v-for="(theme) in learnPlaylist?.themes"
                :key="theme"
                class="flex items-center h-[22px] rounded-[8px] bg-trainingOrangeSoft"
              >
                <p class="text-xs font-normal px-2 py-[6px] text-fakeBlack text-center">
                  {{ theme }}
                </p>
              </div>
            </div>

            <!-- No themes -->
            <div
              v-else
              class="flex items-center h-[22px] rounded-[8px] bg-trainingOrangeSoft"
            >
              <p class="text-xs font-normal px-2 py-[6px] text-fakeBlack text-center">
                {{ $t('No theme') }}
              </p>
            </div>

            <!-- Playlist duration -->
            <div class="flex justify-start items-center gap-2">
              <Icon
                icon="mdi:clock-outline"
                height="16"
                width="16"
                class="text-darkGrey"
              />

              <p class="text-darkGrey text-sm font-medium">
                {{ learnPlaylist?.all_modules_duration || $t('No duration') }}
              </p>
            </div>
          </div>
        </div>
        <!-- PLaylist description -->
        <div class="flex items-center gap-2 self-stretch">
          <p class="text-fakeBlack text-sm font-normal">
            {{ learnPlaylist?.description }}
          </p>
        </div>
      </div>

      <!-- Modules -->
      <div class="flex flex-col items-start px-2 pt-4 gap-4 self-stretch">
        <div class="flex flex-col items-start self-stretch gap-2">
          <!-- Title -->
          <div class="flex px-2 items-start gap-2 self-stretch">
            <p class="text-fakeBlack text-base font-medium">
              {{ $t('Modules') }}
            </p>
          </div>

          <!-- List of modules in the playlist -->
          <div
            v-if="learnPlaylist?.learn_modules?.length"
            class="w-full flex flex-col gap-2"
          >
            <transition-group
              v-if="!isDeleted && amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
            >
              <!-- List des modules si la playlist est published -->
              <draggable
                key="draggable"
                class="w-full flex flex-col self-stretch gap-2"
                :list="learnPlaylist?.learn_modules"
                v-bind="dragOptions"
                item-key="item"
                :delay="50"
                :delay-on-touch-only="true"
                @start="drag = true"
                @end="drag = false"
                @change="getListAfterDrag($event)"
              >
                <template #item="{ element }">
                  <div :key="element?.id">
                    <module-card-in-playlist
                      :id="element?.id"
                      :module="element"
                      :background-image="element?.cover_url?.['500']"
                      :is-favorite="element?.connected_user_favorited"
                      :title="element?.title"
                      :with-drag-icon="true"
                      :theme-list="element?.themes"
                      :duration="element?.duration"
                      :is-deleted="isDeleted"
                      :updated-at="element?.updated_at"
                      :is-acquired="element?.isAcquired"
                      :acquisition-date="element?.acquisitionDate"
                      @edit-module="goToModuleEdit(element?.id)"
                      @go-to-module="goToModule(element?.id)"
                      @remove-module-from-playlist="openDialogRemoveModuleFromPlaylist(element)"
                    />
                  </div>
                </template>
              </draggable>
            </transition-group>

            <div
              v-else
              class="w-full flex flex-col self-stretch gap-2"
            >
              <module-card-in-playlist
                v-for="element in learnPlaylist?.learn_modules"
                :id="element?.id"
                :key="element?.id"
                :module="element"
                :background-image="element?.cover_url?.['500']"
                :is-favorite="element?.connected_user_favorited"
                :title="element?.title"
                :with-drag-icon="false"
                :is-deleted="isDeleted"
                :theme-list="element?.themes"
                :duration="element?.duration"
                :updated-at="element?.updated_at"
                :is-acquired="element?.isAcquired"
                :acquisition-date="element?.acquisitionDate"
                @edit-module="goToModuleEdit(element?.id)"
                @go-to-module="goToModule(element?.id)"
                @remove-module-from-playlist="openDialogRemoveModuleFromPlaylist(element)"
              />
            </div>
          </div>

          <!-- No modules in list -->
          <div
            v-if="!learnPlaylist?.learn_modules?.length && amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
            class="w-full h-full flex justify-center items-center p-6"
          >
            <p
              v-if="!isDeleted"
              class="text-middleGrey text-center text-sm font-normal"
            >
              {{ $t('No modules in the playlist, add some with the button below') }}
            </p>
          </div>

          <!-- Add modules button -->
          <div
            v-if="amICreatorOfEntityOrAdmin(learnPlaylist?.creator_id)"
            class="flex flex-col self-start pl-5 md:!pl-0 items-start gap-2 md:mt-4"
          >
            <dialog-add-modules-to-playlist
              v-if="!isDeleted"
              text-confirm="Validate"
              :themes="learnThemes"
              :modules-list="learnModules"
              @toggle-module-selection="toggleLearnModuleSelection"
            >
              <template #button>
                <v-btn
                  variant="flat"
                  color="trainingOrangeSoft"
                  prepend-icon="mdi-plus"
                  class="normal-case text-xs font-medium text-trainingOrange"
                >
                  {{ $t("Add modules") }}
                </v-btn>
              </template>
            </dialog-add-modules-to-playlist>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog edit Playlist -->
    <dialog-edit-playlist
      ref="editPlaylistDialog"
      :title="learnPlaylist?.title"
      :description="learnPlaylist?.description"
      @update-playlist="updateLearnPlaylist"
    />

    <!-- Dialog delete Playlist -->
    <dialog-delete-playlist
      ref="deletePlaylistDialog"
      @delete-playlist="deleteLearnPlaylist"
    />

    <!-- Dialog delete module in List -->
    <dialog-remove-module-from-list
      ref="removeModuleFromListDialog"
      @remove-from-playlist="removeModule"
    />

    <!-- Scroll to top button -->
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Icon } from '@iconify/vue';
import draggable from 'vuedraggable';
import DialogEditPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogEditPlaylist.vue';
import DialogDeletePlaylist from '@/components/BktPopUp/Dialogs/learn/DialogDeletePlaylist.vue';
import DialogRemoveModuleFromList from '@/components/BktPopUp/Dialogs/learn/DialogRemoveModuleFromList.vue';
import BktBreadcrumbs from '@/components/BktBreadcrumbs.vue';
import DialogAddModulesToPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogAddModulesToPlaylist.vue';
import { useUserStore } from "@/store/user.js";
import { useLearnThemesStore } from '@/store/learn-themes';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useLearnModuleStore } from '@/store/learn-module';
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import ModuleCardInPlaylist from '@/components/trainingApp/ModuleCardInPlaylist.vue';
import { debounce } from 'lodash';
import TrashWarnning from "@/components/TrashWarnning.vue"

const drag = ref(false)

const moduleToRemove = ref(null)

const editPlaylistDialog = ref(false)

const deletePlaylistDialog = ref(false)

const removeModuleFromListDialog = ref(false)

const route = useRoute()

const router = useRouter()

const snackbar = useSnackbar()

const { amICreatorOfEntityOrAdmin } = useUserStore();

const { fetchModules } = useLearnModuleStore()

const { fetchThemes } = useLearnThemesStore()

const { fetchPlaylist, toggleFavorite, toggleModuleSelection, removeModuleFromPlaylist,
  updatePlaylist, deletePlaylist, updateModuleIds, restorePlaylist, deletePlaylistPermanently } = useLearnPlaylistStore()

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const { learnPlaylist } = storeToRefs(useLearnPlaylistStore())

const { learnModules } = storeToRefs(useLearnModuleStore())

const { learnThemes } = storeToRefs(useLearnThemesStore())

const alignTop = ref(false)

const isDeleted = ref(false)

onMounted(async() => {
  try {
    await fetchPlaylist(route?.params?.id)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error fetching playlist')
  }

  try {
    await fetchModules()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error fetching modules')
  }

  try {
    await fetchThemes()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error fetching themes')
  }

  isDeleted.value = learnPlaylist.value.status === "trash"
})

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});


const favoritePlaylist = debounce(async() => {
  try {
    await toggleFavorite(route?.params?.id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar(learnPlaylist?.value?.connected_user_favorited ?
    'Playlist added to favorites' : 'Playlist removed from favorites')
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error toggling favorite')
  }
}, 200)

const copyPlaylistLink = debounce(() => {
  const link = router.resolve({  name: 'playlist_show', params: { id: route.params.id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Link copied to clipboard.')
  }).catch(() => {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error copying link to clipboard')
  });
}, 200)

const editPlaylist = () => {
  editPlaylistDialog.value.dialogEditPlaylist = true
}

const goToModuleEdit = (id) => {
  router.push({ name: "module_edit", params: { id: id } })
}

const goToModule = (id) => {
  router.push({ name: "module_show", params: { id: id }, query: { playlistId: learnPlaylist.value.id }})
}

const updateLearnPlaylist = async(title, description) => {
  try {
    await updatePlaylist(route?.params?.id, title, description)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Successfully changed the playlist title & description')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating the playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }

  editPlaylistDialog.value.dialogEditPlaylist = false
}

const openDeletePlaylist = () => {
  deletePlaylistDialog.value.dialogDeletePlaylist = true
}

const openDialogRemoveModuleFromPlaylist = (module) => {
  moduleToRemove.value = module
  removeModuleFromListDialog.value.dialogRemoveModuleFromList = true
}

const deleteLearnPlaylist = async() => {
  try {
    await deletePlaylist(route?.params?.id)

    deletePlaylistDialog.value.dialogDeletePlaylist = false

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('This playlist has been deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const removeModule = async() => {
  try {
    await removeModuleFromPlaylist(learnPlaylist?.value?.id, moduleToRemove?.value?.id)
    removeModuleFromListDialog.value.dialogRemoveModuleFromList = false

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Module Removed successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error removing module from playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const toggleLearnModuleSelection = async(module) => {
  try {
    await toggleModuleSelection(module)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error toggling module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const getListAfterDrag = async() => {
  if (amICreatorOfEntityOrAdmin(learnPlaylist.value.creator_id)) {
    const modulesIds = learnPlaylist.value?.learn_modules.map(module => module.id)
    await updateModuleIds(learnPlaylist.value?.id, modulesIds)
  }
}

const restoreDeletedItems = async (id) => {
  try {
    await restorePlaylist(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Playlist restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    isDeleted.value = false
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePlaylistPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Playlist deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "catalog" });

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

</script>

<style scoped>
.shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
