<template>
  <div
    class="relative flex flex-col justify-center items-start p-4 gap-2 cursor-pointer"
    @click="goToTrainingShow"
  >
    <svn-dots-button
      circle
      variant="hoverNoPrimary"
      class="absolute top-0 right-0"
    >
      <template #items>
        <v-list-item
          value="edit_training"
          @click="goToTrainingEdit"
        >
          {{ $t('Edit training') }}
        </v-list-item>

        <v-list-item
          value="delete_training"
          class="text-negativeRed"
          @click="openPopUpDelete"
        >
          {{ $t('Delete training') }}
        </v-list-item>
      </template>
    </svn-dots-button>

    <p class="text-sm text-fakeBlack font-medium text-ellipsis">
      {{ training?.title }}
    </p>

    <div class="flex flex-col items-start gap-1">
      <p class="text-xs text-darkGrey font-normal">
        {{ 'Participants : ' }} {{ training?.participant_count || 0 }}
      </p>

      <p class="text-xs text-darkGrey font-normal">
        {{ $t('Last update : ') }} {{ $filters.formatDate(training?.updated_at) || $t('Never') }}
      </p>

      <my-trainings-completion-indicator
        :acquired="training?.participants_completion"
        :total="training?.participant_count || 0"
      />
    </div>
  </div>
</template>

<script setup>
import { usePopUpStore } from '@/store/pop-up';
import {useSnackbar} from "@/store/snackbar.js";
import { useRouter } from "vue-router";
import { useMobileStore } from '@/store/mobile';
import MyTrainingsCompletionIndicator from './MyTrainingsCompletionIndicator.vue';
import { storeToRefs } from 'pinia';

const props = defineProps({
  training: { type: Object, required: true },
});

const emit = defineEmits(['show-training', 'edit-training', 'delete-training'])

const { openPopUp, closePopUp } = usePopUpStore();

const { isMobile } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();

const router = useRouter();

snackbar.checkStatus();

const goToTrainingShow = () => {
  router.push({ name: 'training_show', params: { id: props?.training?.id } })
}

const goToTrainingEdit = () => {
  router.push({ name: 'training_edit', params: { id: props?.training?.id } })
}

const openPopUpDelete = async () => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this training ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No, cancel",
    textConfirm: "Yes, delete this training",
    color: 'red',
    async confirm() {
      closePopUp(
        emit('delete-training')
      )
      snackbar.setBgColor('fakeBlack').setCustomClass(isMobile.value ? 'mb-[85px]' : '').displaySnackBar('Your training has been deleted.')
    },
  })
}
</script>