<template>
  <svn-dialog-skeleton
    ref="dialogRef"
    :title="$t('Request a demo')"
    :description="$t('Would you like to learn about Aleph\ns features and how it can benefit your company? Our team is ready to show you with a demo!')"
    :width="isMobile ? 300 : 400"
    :primary-button-text="'Send'"
    :primary-button-close-on-click="false"
    :primary-button-loading="primaryButtonLoading"
    @click-primary-button="clickPrimaryButton"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #body>
      <div class="w-full flex flex-col gap-4 self-stretch">
        <div class="w-full flex items-center justify-center gap-2">
          <v-text-field
            v-model="firstname"
            hide-details
            density="compact"
            :placeholder="$t('Firstname')"
            variant="outlined"
          />

          <v-text-field
            v-model="lastname"
            hide-details
            density="compact"
            :placeholder="$t('Lastname')"
            variant="outlined"
          />
        </div>

        <v-text-field
          v-model="email"
          density="compact"
          type="email"
          :placeholder="$t('Email*')"
          variant="outlined"
          :error="emailError.error"
          :error-messages="emailError.errorMessage"
          :hide-details="emailError.hideDetails"
        />

        <v-textarea
          v-model="message"
          density="compact"
          :placeholder="$t('Your message*')"
          variant="outlined"
          :error="messageError.error"
          :error-messages="messageError.errorMessage"
          :hide-details="messageError.hideDetails"
        />
      </div>
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import i18n from "@/plugins/i18n.js";

const snackbar = useSnackbar();

const { isMobile } = storeToRefs(useMobileStore());

const email = ref('');
const message = ref('');
const lastname = ref('');
const firstname = ref('');
const dialogRef = ref(null);
const primaryButtonLoading = ref(false);
const emailError = ref({ error: false, errorMessage: '', hideDetails: true })
const messageError = ref({ error: false, errorMessage: '', hideDetails: true })

const clickPrimaryButton = async () => {
  if (!email?.value) {
    emailError.value = { error: true, errorMessage: "", hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: "", hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!emailHasCorrectFormat?.value) {
    emailError.value = { error: true, errorMessage: i18n.global.t("Wrong email format !"), hideDetails: false };
  }
  if (email?.value && message?.value && emailHasCorrectFormat?.value) {
    primaryButtonLoading.value = true;
    try {
      await axiosService.post('api/v1/global_mailer/request_demo', {
        lastname: lastname?.value,
        firstname: firstname?.value,
        email: email?.value,
        message: message?.value
      });
      dialogRef.value.dialog = false;
      primaryButtonLoading.value = false;

      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setBgColor('positiveGreen');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
  
      resetForm();
    } catch (error) {
      primaryButtonLoading.value = false;

      console.log(error);
      snackbar.setMsg('Error sending email.');
      snackbar.setBgColor('negativeRed');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
    }
  }
}

const resetForm = () => {
  firstname.value = '';
  lastname.value = '';
  email.value = '';
  message.value = '';
}

const emailHasCorrectFormat = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email?.value)
});

const displayRequiredFieldsSnackbar = () => {
  snackbar.setMsg('Please fill out all the required(*) fields.');
  snackbar.setBgColor('negativeRed');
  snackbar.setCustomClass('mb-12');
  snackbar.displaySnackBar();
}

watch(email, (newValue, oldValue) => {
  if (newValue !== "" && emailError?.value?.error === true) {
    emailError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

watch(message, (newValue, oldValue) => {
  if (newValue !== "" && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

watch(emailHasCorrectFormat, (newValue, oldValue) => {
  if (newValue === true) {
    emailError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
