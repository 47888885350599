<template>
  <v-data-table
    v-model="selectedUsersForTargetCreation"
    :headers="headers"
    :loading="roadmapEmployees ? false : true"
    :items="roadmapEmployees ? roadmapEmployees : []"
    :items-per-page="-1"
    item-value="id"
    show-select
  >
    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey9-hover"
      >
        <td>
          <v-checkbox
            v-model="selectedUsersForTargetCreation"
            :value="item.value"
            hide-details
          />
        </td>

        <td>
          <div
            class="flex flex-row justify-start items-center py-2"
            @click="() => router.push({ name: 'his_roadmaps', params: { employee_id: item.raw.id } })"
          >
            <svn-avatar
              :avatar="item.raw.avatars?.['30']"
              :firstname="item.raw.firstname"
              :lastname="item.raw.lastname"
              size="lg"
              class="mr-3"
            />

            <div class="flex flex-col justify-center items-start">
              <div class="flex flex-wrap max-w-[200px]">
                <p class="font-medium text-fakeBlack text-sm">
                  {{ item?.raw.fullname }}
                </p>
              </div>

              <div class="flex flex-wrap max-w-[200px]">
                <p class="font-normal text-darkGrey text-xs">
                  {{ item?.raw.job_title }}
                </p>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div @click="() => router.push({ name: 'his_roadmaps', params: { employee_id: item.raw.id } })">
            <p>{{ item.raw.access_level }}</p>
          </div>
        </td>

        <td>
          <div
            class="flex flex-row justify-start items-center py-2"
            @click="() => router.push({ name: 'his_roadmaps', params: { employee_id: item.raw.id } })"
          >
            <svn-avatar
              :avatar="item.raw?.manager?.avatar"
              :firstname="item.raw?.manager?.firstname"
              :lastname="item.raw?.manager?.lastname"
              size="lg"
              class="mr-3"
            />

            <div class="flex flex-wrap max-w-[200px]">
              <p class="font-medium text-fakeBlack text-sm">
                {{ item?.raw?.manager?.fullname }}
              </p>
            </div>
          </div>
        </td>

        <td>
          <div @click="() => router.push({ name: 'his_roadmaps', params: { employee_id: item.raw.id } })">
            <p>{{ item.raw?.objective_elements_count ? item.raw?.objective_elements_count : 0 }}</p>
          </div>
        </td>

        <td class="px-0">
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="view_targets"
                @click="viewEmployeeTargets(item?.raw?.id)"
              >
                {{ $t('View targets') }}
              </v-list-item>

              <v-list-item
                v-if="item.raw?.access_level === 'manager' || item.raw?.access_level === 'manager_creator' ||
                  item.raw?.access_level === 'admin' || item.raw?.access_level === 'super_admin'"
                value="view_team_roadmap"
                @click="viewTeamRoadmap(item?.raw?.id)"
              >
                {{ $t('View Team Roadmap') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && roadmapEmployees && roadmapEmployees.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="!list-none p-5"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>
        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col jsutify-center items-center my-16">
        <Icon
          class="mb-5"
          icon="noto-winking-face"
          height="24"
          width="24"
        />

        <p class="font-medium text-sm mb-5">
          {{ $t('Hmm, it seems you have no targets yet. Create your first target !') }}
        </p>

        <bkt-new-target-button />
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import { useRoadmapEmployeeStore } from "@/store/roadmap-employee.js";
import { useRouter } from "vue-router";
import i18n from '@/plugins/i18n';
import { ref, watch } from "vue";

const props = defineProps({
  roadmapEmployees: { type: Array, required: true },
});

const emit = defineEmits(['update-selected-users']);

const headers = [
  {
    align: "start",
    key: 'name',
    sortable: false,
    title: i18n.global.t('Name'),
  },
  {
    align: "start",
    key: 'access_level',
    sortable: false,
    title: i18n.global.t('Access Level'),
  },
  {
    align: "start",
    key: 'manager',
    sortable: false,
    title: i18n.global.t('Manager'),
  },
  {
    align: "start",
    key: 'targets',
    sortable: false,
    title: i18n.global.t('Targets'),
  },
  {
    align: "start",
    key: ' ',
    sortable: false,
    title: '',
  },
];

const selectedUsersForTargetCreation = ref([]);
const router = useRouter();
const { pagination } = storeToRefs(useRoadmapEmployeeStore())
const { updateParams, fetchRoadmapEmployees, setRoadmapEmployees } = useRoadmapEmployeeStore()

const fetchNewPage = (e) => {
  setRoadmapEmployees(null);
  updateParams({
    "page[number]": e,
  });
  fetchRoadmapEmployees();
};

const viewEmployeeTargets = (employeeId) => {
  router.push({ name: 'his_roadmaps', params: { employee_id: employeeId } })
}

const viewTeamRoadmap = (employeeId) => {
  router.push({ name: 'his_team_roadmaps', params: { employee_id: employeeId } })
}

watch(selectedUsersForTargetCreation, () => {
  emit('update-selected-users', selectedUsersForTargetCreation.value);
})
</script>
