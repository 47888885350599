<template>
  <v-card
    class="w-full px-4 flex flex-col space-y-4
      md:space-y-6 md:!px-6
    "
  >
    <v-card-title
      class="flex flex-col items-center w-full p-0
        md:flex-row md:items-start
      "
    >
      <MyInterviewCardHeader
        :campaign-deadline="deadline"
        :campaign-type="campaignType"
        :campaign-name="title"
      />
    </v-card-title>
    <v-card-text
      class="w-full p-0 flex flex-col items-center justify-center
        md:items-start
      "
    >
      <p
        class="text-sm text-darkGrey font-normal"
      >
        {{ $t('Submitted') }}
      </p>
      <p
        class="text-2xl text-darkGrey font-medium
          md:text-3xl
        "
      >
        <span
          class="text-fakeBlack"
          data-testid="submittedAnswersCount"
        >{{ submittedAnswersCount }}</span>/<span data-testid="totalAnswersCount">{{ totalAnswersCount }}</span>
      </p>
      <v-progress-linear
        v-model="percentage"
        class="my-2 rounded-full"
        :color="submittedAnswers === 0 || submittedAnswers === null ? 'darkGrey' : 'primary'"
        :height="8"
      />
      <p
        class="text-xs"
        data-testid="percentage"
        :class="submittedAnswers === 0 || submittedAnswers === null ? 'text-darkGrey': 'text-primary'"
      >
        {{ percentage }}%
      </p>
    </v-card-text>
    <v-card-actions
      class="w-full p-0 self-end
        md:w-fit"
    >
      <v-btn
        class="normal-case w-full
          md:w-fit md:!px-6"
        append-icon="mdi-poll"
        variant="outlined"
        data-testid="consultAnswer"
        :color="submittedAnswers === 0 ? 'fakeBlack': 'primary'"
        :disabled="submittedAnswers === 0"
        @click="consultAnswers"
      >
        <span data-testid="buttonText">{{ $t('Consult answers') }}</span>
        <template #append>
          <v-icon
            size="10"
            data-testid="buttonIcon"
          >
            mdi-poll
          </v-icon>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
  import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
  import {ref} from "vue";
  import router from "@/router/index.js";

  // Props
  const props = defineProps({
    title: { type: String, required: true},
    campaignType: { type: String, required: true },
    deadline: { type: String, required: true },
    id: { type: Number, required: true },
    totalAnswers: { type: Number, required: true },
    submittedAnswers: { type: Number, required: true },
  })

  // Consts
  const submittedAnswersCount = props.submittedAnswers !== null &&
                                props.submittedAnswers >= 0     &&
                                props.totalAnswers !== null     &&
                                props.totalAnswers >= 0         ?
                                  props.submittedAnswers : "#error"

  const totalAnswersCount = props.totalAnswers !== null && props.totalAnswers >= 0 ?
    props.totalAnswers : "#error"

  // Refs
  const percentage = ref( props.submittedAnswers >= 0 && props.totalAnswers >= 0 ?
    Math.round(100 * props.submittedAnswers / props.totalAnswers) :
    0
  )

  // Functions
  const consultAnswers = () => {
    router.push({ name: 'survey_answer', params: { id: props.id } })
  }
</script>
