<template>
  <div
    v-if="!loading"
    class="mb-6 w-full h-full shadow rounded">
    <div class="min-h-1/4 border-b-2 px-10 py-4">
      <div class="font-medium uppercase">
        {{ $t(completionAllText()) }}
      </div>
      <div class="text-sm">
        {{ $t('Quick view of your campaign completion') }}
      </div>
    </div>
    <div class="h-3/4 w-full flex px-10 py-4">
      <BktDoughnut
        :inner-class="'w-full'"
        :chart-class="'w-full'"
        :datas="completionAllData"
        :labels="[$t('Submitted'), $t('In progress'), $t('Not started')]"
        :colors="chartColors"
        :chart-options="chartCompletionAllOptions"
      />
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-full w-full">
    <svn-loader loading-size="lg" />
  </div>
</template>
<script setup>
import BktDoughnut from "@/components/chart/BktDoughnut.vue";
import {CampaignTypeBackend} from "@/constants/CampaignTypeEnum.js";

const props = defineProps({
  completionAllData: { type: Array, default: () => [] },
  chartColors: { type: String, default: '' },
  chartCompletionAllOptions: { type: Array, default: () => [] },
  campaign: { type: Object, default: () => {} },
  loading: { type: Boolean, default: false },
})

const completionAllText = () => {
  switch (props.campaign.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return "Interview sets completion - All"
    case CampaignTypeBackend.FEEDBACK_360:
      return "Feedback completion - All"
    case CampaignTypeBackend.SURVEY:
      return "Survey completion - All"
    default:
      return ''
  }
}
</script>
