+<template>
  <div class="flex flex-col w-full h-full overflow-y-auto">
    <!-- Header -->
    <div class="relative border-b border-middle-grey flex items-center">
      <!-- Desktop view -->
      <div
        class="hidden lg:flex h-[72px] w-full relative border-b border-middle-grey
          px-4 py-2 justify-center items-center gap-4"
      >
        <div class="flex justify-center items-center w-[80%]">
          <p
            class="font-semibold text-xl overflow-ellipsis text-truncate"
            :class="learnTraining?.title ? 'text-fakeBlack' : 'text-middle-grey'"
          >
            {{ learnTraining?.title ? learnTraining?.title : $t('Training title') }}
          </p>
        </div>

        <div @click="saveTrainingAndQuit">
          <Icon
            :icon="'material-symbols:close'"
            class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
          />
        </div>
      </div>

      <!-- Mobile view -->
      <div
        class="lg:hidden flex flex-col w-full relative items-center justify-center"
      >
        <div class="flex justify-end w-full pr-4 pt-4">
          <div
            class="flex self-end w-10 h-10 p-2"
            @click="saveTrainingAndQuit"
          >
            <Icon
              :icon="'material-symbols:close'"
              class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
            />
          </div>
        </div>

        <div class="max-w-full h-[75px] gap-2 flex justify-center items-center px-4">
          <p class="font-semibold text-xl text-middleGrey truncate">
            {{ learnTraining?.title ? learnTraining?.title : $t('Training title') }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:w-[758px] mx-auto px-4 lg:!px-[0px] gap-6 lg:!gap-[42px] w-full">
      <!-- training Image section -->
      <bkt-image-cover-position
        class="my-4 "
        mode="edit"
        :url="coverBlobURL ?? learnTraining?.cover_url?.['original']"
        :coordinates="{left: coordinates?.left ?? learnTraining?.cover_offset_left, top: coordinates?.top ?? learnTraining?.cover_offset_top}"
        @update:coordinates="coordinates = {left: $event.left, top: $event.top}"
        @file-upload="uploadImage"
        @error="errorUploading"
      />

      <!-- Main section -->
      <div class="flex !flex-col justify-start gap-6 lg:!gap-[42px] !pb-[120px]">
        <!-- Title Text input -->
        <v-text-field
          v-model="learnTraining.title"
          variant="outlined"
          persistent-counter
          maxlength="70"
          counter
          class="text-fakeBlack text-[24px] font-semibold input relative"
          :placeholder="$t('Training title')"
          density="compact"
        >
          <template #counter="{ value }">
            <div class="absolute top-[6px] right-[14px] z-[20] pt-1 self-start">
              <span class="text-middleGrey text-base font-medium">
                {{ 70 - value }}
              </span>
            </div>
          </template>
        </v-text-field>

        <!-- Description section -->
        <div class="flex flex-col items-start gap-2 self-stretch">
          <p class="text-sm text-fakeBlack font-medium">
            {{ $t('Description') }}
          </p>

          <!-- Description textarea -->
          <v-textarea
            v-model="learnTraining.description"
            class="w-full"
            variant="outlined"
            :no-resize="true"
            :placeholder="$t('Create your first piece of content by modifying the title, description and image. You can also add an estimated duration, objectives and one or more themes just below.')"
            hide-details
          />
        </div>

        <!-- Launch Date -->
        <div class="flex flex-col items-start gap-2 flex-1">
          <!-- Start text -->
          <div class="flex items-center gap-1">
            <p class="text-fakeBlack text-sm font-medium">
              {{ $t('Start') }}
            </p>

            <svn-tooltip
              content-class="text-xs font-normal"
              size="small long"
            >
              <template #activator-content>
                <Icon
                  icon="mdi:information-outline"
                  class="text-dark-grey h-4 w-4"
                />
              </template>

              <template #tooltip-content>
                {{ $t('The date when the training is launched.') }}
              </template>
            </svn-tooltip>
          </div>

          <!-- Date & time pickers -->
          <div class="w-full flex items-start gap-2 self-stretch lg:w-1/2">
            <!-- Date picker -->
            <div class="w-[55%]">
              <svn-date-picker
                v-model="learnTraining.launched_at_date"
                label="Start date"
                density="compact"
                variant="outlined"
              />
            </div>

            <!-- Time select -->
            <div class="w-[40%]">
              <v-select
                v-model="learnTraining.launched_at_time"
                :items="timeItems"
                density="compact"
                variant="outlined"
                :label="`Time (${currentTimeZone})`"
                :placeholder="learnTraining?.launched_at_time"
                hide-details
              />
            </div>
          </div>
        </div>

        <v-divider
          :thickness="1"
          class="w-full border-opacity-100 border border-lightGrey"
        />

        <!-- Participants section -->
        <div class="flex flex-col items-start gap-4 self-stretch">
          <p class="text-fakeBlack text-sm font-medium">
            {{ $t('Participants') }}
          </p>

          <!-- Tabs & Windows -->
          <div class="w-full flex rounded-[12px] border border-darkGrey flex-col p-2">
            <!-- Tabs -->
            <v-tabs
              v-model="tab"
              :grow="true"
              color="trainingOrange"
              height="48"
              selected-class="font-semibold"
              align-tabs="center"
            >
              <v-tab
                value="manual"
                variant="plain"
              >
                <template #default>
                  <div class="flex flex-row justify-center items-center">
                    <p class="text-sm font-medium normal-case">
                      {{ $t('Manual selection') }}
                    </p>
                  </div>
                </template>
              </v-tab>

              <v-tab
                value="by_category"
                variant="plain"
              >
                <template #default>
                  <div class="flex flex-row justify-center items-center">
                    <p class="text-sm font-medium normal-case">
                      {{ $t('By category') }}
                    </p>
                  </div>
                </template>
              </v-tab>
            </v-tabs>

            <!-- Windows -->
            <v-window
              v-model="tab"
              class="w-full"
            >
              <v-window-item value="manual">
                <window-selection-manual
                  :participants="learnTraining?.participant_ids"
                  @update-participants="updateParticipants"
                />
              </v-window-item>

              <v-window-item value="by_category">
                <window-selection-by-tags
                  :participant_tags="learnTraining?.participant_filter_tag_ids"
                  @update-participant-tags="updateParticipantTagCategories"
                />
              </v-window-item>
            </v-window>
          </div>
        </div>

        <!-- Add content Buttons -->
        <div class="flex flex-col items-start gap-2 self-stretch">
          <p class="text-fakeBlack text-sm font-medium">
            {{ $t('Content') }}
          </p>

          <!-- Added content list -->
          <div
            v-if="learnTraining?.modules_and_playlist?.length"
            class="w-full flex flex-col self-stretch"
          >
            <transition-group>
              <draggable
                key="draggable"
                class="w-full flex flex-col self-stretch gap-2"
                item-key="item"
                v-bind="dragOptions"
                :delay="200"
                :list="learnTraining?.modules_and_playlist"
                :delay-on-touch-only="true"
                @start="drag = true"
                @end="drag = false"
                @change="getListAfterDrag()"
              >
                <template #item="{ element }">
                  <div
                    :key="element?.id"
                    class="flex justify-center items-center gap-2"
                  >
                    <!-- Drag Icon -->
                    <div
                      class="cursor-pointer"
                    >
                      <Icon
                        icon="ic:baseline-drag-indicator"
                        height="16"
                        width="16"
                        class="text-middleGrey"
                      />
                    </div>

                    <content-card-in-training-show
                      :content="element"
                      @remove-from-training="removeFromTraining(element)"
                      @edit-content="editContent($event, element?.id)"
                    />
                  </div>
                </template>
              </draggable>
            </transition-group>
          </div>

          <!-- No content currently in training -->
          <div
            v-else
            class="w-[75%] h-full sm:h-[300px] flex justify-center items-center mx-auto"
          >
            <p class="text-darkGrey text-center text-sm font-normal">
              {{ $t('No module or playlist in training for the moment, add some with the buttons below') }}
            </p>
          </div>
          <!-- Buttons -->
          <div class="flex items-start gap-4">
            <!-- Add modules button -->
            <modal-add-content-to-training
              content-type="module"
              :training-themes="learnTraining?.module_themes"
              :contents="learnModules"
              :training-id="Number(route?.params?.id)"
              :modules-and-playlist="learnTraining?.modules_and_playlist"
              :selected-modules-to-add="selectedModulesToAdd"
              @toggle-content-in-training="addOrRemoveContentInTraining"
              @toggle-search="toggleSearch"
            />

            <!-- Add playlists button -->
            <modal-add-content-to-training
              content-type="playlist"
              :contents="learnPlaylists"
              :training-id="Number(route?.params?.id)"
              :modules-and-playlist="learnTraining?.modules_and_playlist"
              :selected-playlists-to-add="selectedPlaylistsToAdd"
              @toggle-content-in-training="addOrRemoveContentInTraining"
              @toggle-search="toggleSearch"
            />
          </div>
        </div>

        <!-- Launch training button -->
        <dialog-validation
          :disabled="trainingValidation"
          icon="noto:incoming-envelope"
          title="Send invitation emails ?"
          description="Do you wish to send invitation emails for this training ? They will be sent as soon as the training will be launched."
          button-primary-color="primary"
          secondary-button-text="No, thanks"
          primary-button-text="Yes, send them"
          @click-primary-button="updateLearnTraining(true)"
          @click-secondary-button="updateLearnTraining(false)"
        >
          <template #button>
            <svn-button
              :loading="loading"
              :disable="trainingValidation"
              :text="learnTraining?.status === 'draft' ?
                $t('Launch training') : $t('Update training')"
              class="w-full text-xs font-medium normal-case"
            />
          </template>
        </dialog-validation>
      </div>
    </div>

    <!-- Scroll to top button -->
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import draggable from "vuedraggable";
import { useSnackbar } from "@/store/snackbar";
import { onMounted, onUnmounted, ref, computed } from "vue";
import DialogValidation from "@/components/BktPopUp/Dialogs/learn/DialogValidation.vue";
import ModalAddContentToTraining from "@/components/BktPopUp/Modals/learn/ModalAddContentToTraining.vue";
import ContentCardInTrainingShow from "@/components/trainingApp/ContentCardInTrainingShow..vue";
import WindowSelectionManual from "@/components/trainingApp/myTrainings/WindowSelectionManual.vue";
import WindowSelectionByTags from "@/components/trainingApp/myTrainings/WindowSelectionByTags.vue";
import { useLearnTrainingStore } from "@/store/learn-trainings";
import { useLearnModuleStore } from "@/store/learn-module";
import { useLearnPlaylistStore } from "@/store/learn-playlist";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { debounce } from "lodash";
import moment from "moment";
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});

const fileInput = ref(null)

const tab = ref(null)
const currentTimeZone = ref(Intl.DateTimeFormat().resolvedOptions().timeZone)

const startDate = ref(null)

const startTime = ref(null)

const drag = ref(false)

const route = useRoute()

const router = useRouter()

const snackbar = useSnackbar()

const selectedModulesToAdd = ref([])

const selectedPlaylistsToAdd = ref([])

const participantIds = ref([])
const participantTagIds = ref([])
const modulesAndPlaylists = ref([])
const coverBlob = ref(null)
const coverBlobURL = ref(null)
const loading = ref(false);

const { fetchModules } = useLearnModuleStore()

const { fetchPlaylists } = useLearnPlaylistStore()

const { resetTraining, fetchTraining, updateTraining, updateParticipantsOfTraining, updateParticipantsTagCategoryOfTraining,
  launchTraining, toggleContentInTraining } = useLearnTrainingStore()

const { learnModules } = storeToRefs(useLearnModuleStore())

const { learnPlaylists } = storeToRefs(useLearnPlaylistStore())

const { isMobile } = storeToRefs(useMobileStore())

const { learnTraining } = storeToRefs(useLearnTrainingStore())

const timeItems = ref([
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 AM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 PM"
])

const coordinates = ref(null)

onMounted(async () => {
  resetTraining()

  try {
    await fetchTraining(route?.params?.id)
    if (learnTraining.value.participant_filter_tag_ids.length > 0) {
      tab.value = 'by_category'
      participantTagIds.value = learnTraining.value.participant_filter_tag_ids
    }

    participantIds.value = learnTraining.value.participant_ids
    modulesAndPlaylists.value = learnTraining.value.modules_and_playlist
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }

  try {
    await fetchModules()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }

  try {
    await fetchPlaylists()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching playlists')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }

  selectedModulesToAdd.value = learnTraining.value.modules_and_playlist.filter((content) => {
    return content.content_type === "Learn::Module"
  })

  selectedPlaylistsToAdd.value = learnTraining.value.modules_and_playlist.filter((content) => {
    return content.content_type === "Learn::Playlist"
  })
})

const updateParticipants = async(participantsListIds) => {
  participantIds.value = participantsListIds
}

const updateParticipantTagCategories = async(participantTagsIds) => {
  participantTagIds.value = participantTagsIds
}

const updateLearnTraining = debounce(async(sendInvitation = false) => {
  loading.value = true

  const specificDateTime = moment(learnTraining.value?.launched_at_date + ' ' + learnTraining.value?.launched_at_time?? '', "YYYY-MM-DD hh:mm A");
  try {
    await updateTraining(
      learnTraining?.value?.id,
      coordinates?.value?.left ?? learnTraining.value?.cover_offset_left,
      coordinates?.value?.top ?? learnTraining.value?.cover_offset_top,
      learnTraining?.value?.title,
      learnTraining?.value?.description,
      learnTraining.value?.learn_contents,
      coverBlob.value?? learnTraining?.value?.cover_url,
      specificDateTime._d,
      currentTimeZone.value,
      sendInvitation
    )
    if (tab.value == 'by_category' && participantTagIds.value.length) {
      await updateParticipantsTagCategoryOfTraining(learnTraining?.value?.id, participantTagIds.value, sendInvitation)
    } else {
      await updateParticipantsOfTraining(learnTraining?.value?.id, participantIds.value, sendInvitation)
    }

    learnTraining.value.learn_contents = modulesAndPlaylists.value.map((content) => {
      return {
        contentable_id: content.id,
        contentable_type: content.content_type
      }
    })

    await toggleContentInTraining(learnTraining?.value?.id, learnTraining?.value?.learn_contents)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Training updated successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()

    loading.value = false

    router.push({ name: "trainings" })
  } catch (error) {
    let error_message = ''
    if (error?.response?.data?.message) {
      error_message = error?.response?.data?.message.join(', ')
    }

    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating training' + error)
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    loading.value = false
  }
}, 300)

const saveTrainingAndQuit = () => {
  router.back()
}

const trainingValidation = computed(() => {
  return !(
    learnTraining?.value?.modules_and_playlist?.length &&
    learnTraining.value?.title &&
    (
      learnTraining.value?.participant_ids ||
      learnTraining.value?.participant_filter_tag_ids
    )
  )
})

const toggleSearch = async(value, type) => {
  if (type === 'module') {
    try {
      await fetchModules(value)
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error fetching modules')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
    }
  }
  else if (type === 'playlist') {
    try {
      await fetchPlaylists(value)
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error fetching playlists')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
    }
  }
}

const uploadImage = async (blob) => {
  if (blob?.size > 12500000) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error uploading training image, file size exceeding 10Mb.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  } else {
    coverBlob.value = blob
    coverBlobURL.value = URL.createObjectURL(blob);
  }
}

const addOrRemoveContentInTraining = (item) => {
  if (item?.content_type === 'Learn::Module') {
    const found = selectedModulesToAdd?.value?.find(content => content.id === item.id && content.content_type === item.content_type)

    if (found) {
      learnTraining.value.modules_and_playlist.splice(selectedModulesToAdd.value?.findIndex(content => content.id === item.id && content.content_type === item.content_type), 1)
      selectedModulesToAdd.value?.splice(selectedModulesToAdd.value?.findIndex(content => content.id === item.id && content.content_type === item.content_type), 1)
    }
    else {
      selectedModulesToAdd.value?.push(item)
      learnTraining.value.modules_and_playlist.push(item)
    }
  }
  else {
    const found = selectedPlaylistsToAdd?.value?.find(content => content.id === item.id)

    if (found) {
      learnTraining.value.modules_and_playlis.splice(selectedPlaylistsToAdd.value?.findIndex(content => content.id === item.id && content.content_type === item.content_type), 1)
      selectedPlaylistsToAdd.value?.splice(selectedPlaylistsToAdd.value?.findIndex(content => content.id === item.id && content.content_type === item.content_type), 1)
    }
    else {
      selectedPlaylistsToAdd.value?.push(item)
      learnTraining.value.modules_and_playlist.push(item)
    }
  }

  modulesAndPlaylists.value = learnTraining.value.modules_and_playlist
}

const editContent = (type, contentId) => {
  if (type === 'Learn::Module') {
    router.push({ name: 'module_edit', params: { id: contentId }})
  }
  else if (type === 'Learn::Playlist') {
    router.push({ name: 'playlist_show', params: { id: contentId }})
  }
}

const removeFromTraining = async(content) => {
  const contentId = content.id
  const contentType = content.content_type
  learnTraining.value.modules_and_playlist = learnTraining?.value?.modules_and_playlist.filter((data) => {
    return data.id !== contentId || contentType !== data.content_type
  })

  modulesAndPlaylists.value = learnTraining.value.modules_and_playlist
}

const getListAfterDrag = async() => {
  modulesAndPlaylists.value = learnTraining?.value?.modules_and_playlist
}

const errorUploading = (msg) => {
  snackbar.setBgColor('negativeRed')
  snackbar.setMsg(msg)
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
  snackbar.displaySnackBar()
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}

.input :deep(.v-field__input) {
  padding-right: 40px;
}

.file-selector :deep(.v-input__control) {
  display: none;
}

.file-selector :deep(.mdi-pencil-outline) {
  margin-left: 17px;
  margin-top: 3px;
}

.load :deep(.v-skeleton-loader__image) {
  height: 350px;
}
</style>
