<template>
  <div class="grid gap-0 grid-cols-[150px_minmax(0px,_475px)]">
    <div
      class="flex flex-col justify-start px-2 py-4 items-center border-r border-r-lightGrey bkt-bg-light-grey9-hover cursor-pointer"
      @click="() => router.push( {name: 'people_show', params: { id: roadmap.id }, query: { page: 'interviews' }})"
    >
      <svn-avatar
        :avatar="roadmap.avatars?.['30']"
        :firstname="roadmap.firstname"
        :lastname="roadmap.lastname"
        size="lg"
        class="mb-2"
      />

      <div class="flex flex-col text-center">
        <div class="flex flex-wrap max-w-[100px]">
          <p class="font-medium text-fakeBlack text-xs">
            {{ roadmap.fullname }}
          </p>
        </div>

        <div class="flex flex-wrap max-w-[100px]">
          <p class="font-normal text-darkGrey text-xs">
            {{ roadmap.job_title }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div
        v-for="(element, index) in roadmap.objective_elements"
        :key="index"
        class="flex flex-row justify-between items-start p-4 bkt-bg-light-grey9-hover cursor-pointer"
        :class="index !== roadmap.objective_elements.length - 1 ? 'border-b border-b-lightGrey' : ''"
        @click="() => router.push( {name: 'roadmaps_targets_show', params: { id: element.id }})"
      >
        <div class="flex flex-col justify-center items-start">
          <p class="text-base text-start text-fakeBlack font-medium text-ellipsis">
            {{ element?.title }}
          </p>

          <p class="text-sm text-darkGrey font-normal mb-2">
            {{ $t('Deadline : ', {date: element?.due_date}) }}
          </p>

          <my-roadmaps-objective-indicator :objective-indicator="element.objective_indicator" />
        </div>

        <svn-dots-button
          circle
          variant="hoverNoPrimary"
          class="-mr-4"
        >
          <template #items>
            <v-list-item
              v-if="type === 'unarchived'"
              value="unarchived"
              @click="openPopUpArchive(element?.id)"
            >
              {{ $t('Archive target') }}
            </v-list-item>

            <v-list-item
              v-if="type !== 'unarchived'"
              value="different_than_unarchived"
              @click="openPopUpUnarchive(element?.id)"
            >
              {{ $t('Unarchive target') }}
            </v-list-item>

            <v-list-item
              value="delete"
              class="text-negativeRed"
              @click="openPopUpDelete(element?.id)"
            >
              {{ $t('Delete') }}
            </v-list-item>
          </template>
        </svn-dots-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { usePopUpStore } from '@/store/pop-up';
import {useSnackbar} from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import MyRoadmapsObjectiveIndicator from './MyRoadmapsObjectiveIndicator.vue';

const props = defineProps({
  roadmap: { type: Object, required: true },
  type: { type: String, required: true },
});

const emit = defineEmits(['update'])

const { openPopUp, closePopUp } = usePopUpStore();

const router = useRouter();

const snackbar = useSnackbar();
snackbar.checkStatus();

const openPopUpArchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to archive this target ?',
    subtitle: 'You will find this target in the "Archived targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, archive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'archived'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been archived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while archiving your target !')
      }
    },
  })
}

const openPopUpUnarchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to unarchive this target ?',
    subtitle: 'You will find this target in the "Current targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, unarchive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'opened'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been unarchived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while unarchiving your target !')
      }
    },
  })
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this target ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No",
    textConfirm: "Yes, delete",
    color: 'red',
    customClass: '',
    async confirm() {
      try {
        await axiosService.delete(`/api/v1/roadmaps/targets/${id}`)
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').setCustomClass('mb-[85px]').displaySnackBar('Your target has been deleted.')
        await router.push({name: "my_team_roadmaps"})
      } catch (error) {
        snackbar.setBgColor('negativeRed').setCustomClass('mb-[85px]').displaySnackBar('Error while deleting your target !')
      }
    },
  })
}
</script>