<template>
  <div
    class="flex flex-col text-center justify-center items-center"
    :class="isMobile ? 'bg-veryLightGrey' : 'bg-white'"
  >
    <svn-avatar
      v-if="user"
      class="mt-4 sm:!mt-12 mx-auto"
      :avatar="user?.avatar"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      size="lg"
    />

    <div class="flex justify-center items-center gap-2 mt-4 mb-4 sm:!mb-16">
      <svn-hello-banner
        v-if="user"
        app="roadmap"
        :firstname="user?.firstname"
        :completed-targets="archived?.length || 0"
        :current-targets="unarchived?.length || 0"
        :text-chip-first="archived?.length ? $t('Targets completed') : $t('Target completed')"
        :text-chip-second="archived?.length ? $t('Current targets') : $t('Current target')"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const { isMobile } = storeToRefs(useMobileStore());

defineProps({
  user: { type: Object, default: null },
  archived: { type: Object, default: null },
  unarchived: { type: Object, default: null },
});
</script>