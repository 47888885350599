<template>
  <modal-skeleton
    :title="title"
    title-center
    :buttons-class="isMobile ? 'flex flex-col-reverse !gap-3' : 'flex'"
    :secondary-button-class="isMobile ? 'w-full' : 'w-1/2'"
    :primary-button-class="isMobile ? 'w-full' : '!w-1/2 flex-1'"
    secondary-button-text="Create and send invitation"
    primary-button-text="Create"
    :action-buttons="true"
    :primaryButtonClosesModal="false"
    :secondaryButtonClosesModal="false"
    @click-primary-button="createUser"
    @click-secondary-button="createUser('?invite=true')"
  >
    <template #button>
      <slot name="button" />
    </template>

    <template #body-content>
      <div class="flex flex-col py-8 h-full overflow-y-auto px-6 gap-4">
        <v-text-field
          v-model="user.firstname"
          color="primary"
          class="input"
          density="compact"
          variant="outlined"
          :label="$t('Firstname') + ' *'"
          :error="firstnameError.error"
          :error-messages="firstnameError.errorMessage"
          :hide-details="firstnameError.hidedetails"
          >
          <template #message>
            <div class="flex items-center gap-2 w-full ms-1 me-8">
              <Icon
                icon="material-symbols:info"
                heigth="16"
                width="16"
              />
              <p>{{ $t('This field is required') }}</p>
            </div>
          </template>
        </v-text-field>

        <v-text-field
          v-model="user.lastname"
          color="primary"
          class="input"
          density="compact"
          variant="outlined"
          :label="$t('Lastname') + ' *'"
          :error="lastnameError.error"
          :error-messages="lastnameError.errorMessage"
          :hide-details="lastnameError.hidedetails"
          >
          <template #message>
            <div class="flex items-center gap-2 w-full ms-1 me-8">
              <Icon
                icon="material-symbols:info"
                heigth="16"
                width="16"
              />
              <p>{{ $t('This field is required') }}</p>
            </div>
          </template>
        </v-text-field>

        <v-text-field
          v-model="user.email"
          color="primary"
          class="input"
          density="compact"
          variant="outlined"
          :label="$t('Email') + ' *'"
          :error="emailError.error"
          :error-messages="emailError.errorMessage"
          :hide-details="emailError.hidedetails"
          >
          <template #message>
            <div class="flex items-center gap-2 w-full ms-1 me-8">
              <Icon
                icon="material-symbols:info"
                heigth="16"
                width="16"
              />
              <p> {{ isTaken ? $t('This email is already taken') : isInvalid ? $t('The email is not valid') : $t('This field is required') }} </p>
            </div>
          </template>
        </v-text-field>

        <v-select
          v-model="user.access_level"
          variant="outlined"
          density="compact"
          :items="[
            { title: $t('Employee'), value: 'employee' },
            { title: $t('Manager'), value: 'manager' },
            { title: $t('Manager Creator'), value: 'manager_creator' },
            { title: $t('Admin'), value: 'admin' },
          ]"
          item-title="title"
          item-value="value"
          class="w-full input"
          color="primary"
          hide-details
          :label="$t('Access Level')"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>

        <div>
          <svn-autocomplete
            v-model="user.manager"
            :label="$t('Manager')"
            :items="managers"
            class="text-black !min-w-full input"
            base-color="fakeBlack"
            :loading="managerLoading"
            prepend-inner-icon=""
            @input="search($event)"
          >
            <template #main-content>
              {{ null }}
            </template>

            <template #append-content>
              {{ null }}
            </template>
          </svn-autocomplete>
        </div>

        <label class="text-darkGrey text-start">
          {{ $t("Birth date") }}
        </label>

        <svn-date-picker
          v-model="user.birth_date"
          label="Select a date"
        />

        <label class="text-darkGrey text-start">
          {{ $t("Hire date") }}
        </label>

        <svn-date-picker
          v-model="user.hire_date"
          label="Select a date"
        />

        <v-select
          v-model="user.gender"
          variant="outlined"
          density="compact"
          :items="[
            { title: $t('Gender-female'), value: 'female'},
            { title: $t('Gender-male'), value: 'male'},
            { title: $t('Gender-other'), value: 'other'},
          ]"
          item-title="title"
          item-value="value"
          class="w-full input"
          color="primary"
          :label="$t('Gender')"
          hide-details
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>

        <v-file-input
          ref="avatar"
          accept="image/*"
          color="primary"
          hide-details
          class="input"
          density="compact"
          variant="outlined"
          :label="$t('Upload photo')"
          @change="uploadFile()"
        />

        <div class="flex justify-between items-top">
          <v-text-field
            v-model="user.companyUid"
            color="primary"
            density="compact"
            variant="outlined"
            hide-details
            class="input"
            :label="$t('Employee registration number')"
          />
          <svn-tooltip>
            <template #activator-content>
              <div class="w-full h-full px-3 flex items-center justify-center">
                <Icon
                  icon="mdi:information"
                  class="text-fake-black h-6 w-6"
                />
              </div>
            </template>

            <template #tooltip-content>
              {{ $t('Fill this field if you have registration number for your employee. This field is optional.') }}
            </template>
          </svn-tooltip>
        </div>

        <div>
          <svn-autocomplete
            v-for="(category, index) in tagCategories"
            :key="user.tags[category.id]"
            v-model="user.tags[category.id]"
            :items="category.tags"
            item-title="tagName"
            :label="category.name"
            class="!min-w-full input"
            color="primary"
            base-color="fakeBlack"
            required
            return-object
            prepend-inner-icon=""
            @update:search="tagCategorySearch[index] = $event"
          >
            <template #main-content>
              {{ null }}
            </template>

            <template #append-content>
              {{ null }}
            </template>

            <template #no-data>
              <div
                class="flex cursor-pointer hover:bg-gray-200"
                @click="createTagInTagCategory(index)"
              >
                <span class="mx-4">
                  {{ $t("create +") }}
                </span>
                <p>'{{ tagCategorySearch[index] }}'</p>
              </div>
            </template>
          </svn-autocomplete>
        </div>

        <p class="text-darkGrey text-xs font-normal">
          {{ $t('*Required fields') }}
        </p>
      </div>
    </template>
  </modal-skeleton>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useCompanyStore } from "@/store/company.js";
import axios from "axios";
import {Icon} from "@iconify/vue";
import i18n from "@/plugins/i18n.js";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import caseStyles from "@/tools/caseStyles.js";
import ModalSkeleton from '../skeleton/BktPopUpModalSkeleton.vue';
import { useMobileStore } from "@/store/mobile";

const props = defineProps({
  title: { type: String, default: "" },
})

const { fetchCompanyManagers } = useCompanyStore();

const { tagCategories, managers } = storeToRefs(useCompanyStore());
const { isMobile } = storeToRefs(useMobileStore());

const isTaken = ref(false)
const isInvalid = ref(false)

const snackbar = useSnackbar();
const router = useRouter();
const formLoading = ref(false);
const managerLoading = ref(false);
const avatar = ref(null);
const user = reactive({
  access_level: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birth_date: null,
  hire_date: null,
  gender: null,
  avatar: null,
  companyUid: null,
  tags: [],
  job_title: null,
});
const tagCategorySearch = ref(tagCategories.value.map((_) => ""));

const createTagInTagCategory = async (index) => {
  const search = tagCategorySearch.value[index];
  const tagCategoryId = tagCategories.value[index].id;

  try {
    const res = await axios.post(
      `/api/v1/companies/tag_categories/${tagCategoryId}/tags`,
      { tag_name: search }
      );
      user.tags[tagCategoryId] = caseStyles.convertKeysToCamel(res.data.tag);
    } catch (e) {
      console.log(e);
    }
  };

const firstnameError = ref({error: false, errorMessage: '', hidedetails: true})
const lastnameError = ref({error: false, errorMessage: '', hidedetails: true})
const emailError = ref({error: false, errorMessage: '', hidedetails: true})

const validateFieldRequired = (model, errorRefName) => {
  const errorRef = eval(errorRefName)

  if (model === "" || model === null) {
    errorRef.value = {
      error: true,
      errorMessage: i18n.global.t("This field is required"),
      hidedetails: false,
    };
  } else {
    errorRef.value = { error: false, errorMessage: "", hidedetails: true };
  }
}

const createUser = async (query = "") => {
  formLoading.value = true;

  isTaken.value = false
  isInvalid.value = false

  validateFieldRequired(user.lastname, 'lastnameError')
  validateFieldRequired(user.firstname, 'firstnameError')
  validateFieldRequired(user.email, 'emailError')


  const formData = new FormData();

  if (user.access_level) formData.append("user[access_level]", user.access_level);
  if (user.firstname) formData.append("user[firstname]", user.firstname);
  if (user.lastname) formData.append("user[lastname]", user.lastname);
  if (user.email) formData.append("user[email]", user.email);
  if (user.hire_date) formData.append("user[hire_date]", JSON.stringify(user.hire_date));
  if (user.birth_date) formData.append("user[birth_date]", JSON.stringify(user.birth_date));
  if (user.gender) formData.append("user[gender]", user.gender ? user.gender : "");
  if (user.job_title) formData.append("user[job_title]", user.job_title);
  if (user.manager) formData.append("user[manager_id]", user.manager || user.manager.id);  if (user.tags) formData.append("user[tags]", JSON.stringify(user.tags));
  if (user.avatar) formData.append("user[avatar]", user.avatar);
  if (user.companyUid) formData.append("user[company_uid]", user.companyUid ? user.companyUid : "");

  const headers = { "Content-Type": "multipart/form-data" };
  if (user.email) {
    try {
      const res = await axios.post(`/api/v1/users/${query}`, formData, {
        headers,
      });

      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg(query?.length ? "The user has been successfully created and has received an invitation e-mail." :
        "The user has been successfully created.")
      snackbar.setCustomClass(isMobile?.value ? 'mb-[80px]' : 'mb-10')
      snackbar.displaySnackBar()

      router.push({ name: "people_show", params: { id: res.data.user_id }, query: { page: "interviews" } });

    } catch (e) {
      const message = e.response.data.message

      if (message == "Email is not an email" || message == "Email n'est pas une adresse e-mail") {
        emailError.value = {error: true, errorMessage: 'The email is not valid', hidedetails: false}
        isInvalid.value = true
        snackbar.setDefaultColor("failed").displaySnackBar("The email is not valid");
      } else if (message == "Email has already been taken") {
        emailError.value = {error: true, errorMessage: i18n.global.t('This email is already taken'), hidedetails: false}
        isTaken.value = true
        snackbar.setDefaultColor("failed").setMsg('This email is already taken').displaySnackBar();
        } else {
          snackbar.setDefaultColor("failed").displaySnackBar("Some fields contain errors");
          if (message.includes("Email is not an email") || message.includes("Email n'est pas une adresse e-mail")) {
            emailError.value = {error: true, errorMessage: i18n.global.t('The email is not valid'), hidedetails: false}
            isInvalid.value = true
            console.log("invalide")
          }
          if (message.includes("Email has already been taken")) {
            emailError.value = {error: true, errorMessage: i18n.global.t('This email is already taken'), hidedetails: false}
            isTaken.value = true
            console.log('taken');
          }
      }

    } finally {
      formLoading.value = false;
    }

  } else {
    const absentFields = []
    if (!user.firstname) absentFields.push("the firstname")
    if (!user.lastname) absentFields.push("the lastname")
    if (!user.email) absentFields.push("the email")

    const snackbarInput = makeCommaSeparatedString(absentFields)
    const error = "Please fill " + snackbarInput
    snackbar.setDefaultColor("failed").displaySnackBar(error);
  }
}

const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(', ')
  const listEnd = arr.slice(-1)
  const conjunction = arr.length <= 1
    ? ''
    : useOxfordComma && arr.length > 2
      ? ', and '
      : ' and '

  return [listStart, listEnd].join(conjunction)
}

const getManagers = async () => {
  try {
    await fetchCompanyManagers({ disableCache: true });
  } catch (e) {
    console.log("error", e);
  } finally {
    managerLoading.value = false;
  }
};
getManagers();

const uploadFile = () => {
  user.avatar = avatar.value.files[0];
};
</script>

<style scoped>
.input :deep(.v-field) {
  border-radius: 8px;
}

.v-text-field :deep(.v-input__details) {
  padding-inline-start: 0px;
}
</style>
