<template>
  <template-header-question
    :id="interviewQuestion.id"
    :copy="false"
    :delete="true"
    :move-down="true"
    :move-up="true"
    :is-deleted="isDeleted"
  >
    <template #title>
      <v-btn
        color="primary"
        prepend-icon="mdi-bullseye-arrow"
        variant="text"
      >
        {{ $t(title) }}
      </v-btn>
    </template>
    <template #body>
      <v-text-field
        v-model="interviewQuestion.text"
        color="primary"
        :label="$t('Roadmap block name')"
        :disabled="isDeleted"
        @update:model-value="updateQuestion(interviewQuestion.id, {text: $event})"
      />

      <div class="h-40 mb-16">
        <quill-editor
          v-model:content="interviewQuestion.description"
          content-type="html"
          :placeholder="$t('Description')"
          :enable="!isDeleted"
          @update:content="updateQuestion(interviewQuestion.id, {description: $event})"
        />
      </div>
    </template>
  </template-header-question>
</template>

<script setup>
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import useTools from "@/tools/useTools.js";
import {QuillEditor} from "@vueup/vue-quill";

// const props = defineProps(['interviewQuestion', 'title'])

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true },
  title: { type: String, required: true}
})

const {updateQuestion} = useInterviewFormStore()
const tools = useTools()


</script>
