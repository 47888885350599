<template>
  <!-- Module Card -->
  <div
    class="w-full flex h-[82px] items-center gap-3"
  >    
    <!-- Card image -->
    <div class="w-[112px] h-[64px]">
      <img
        :src="backgroundImage"
        class="rounded"
        draggable="false"
        style="width: 112px; height: 64px; object-fit: cover;"
      >
    </div>

    <div class="w-full flex flex-col items-start gap-2">
      <div class="flex flex-col items-start">
        <!-- Module title -->
        <p class="line-clamp-1 text-sm font-normal text-fakeBlack">
          {{ title }}
        </p>

        <!-- Duration & Updated at -->
        <div class="flex items-start gap-6 self-stretch">
          <!-- Module duration -->
          <div class="flex items-center gap-1">
            <div class="flex items-center">
              <Icon
                icon="mdi:clock-outline"
                height="12"
                width="12"
                class="text-darkGrey"
              />
            </div>
  
            <p class="text-darkGrey text-xs font-medium">
              {{ duration || $t('No duration') }}
            </p>
          </div>
  
          <!-- Module updatedAt -->
          <div class="flex items-center gap-1">
            <div class="flex items-center">
              <Icon
                icon="mdi:update"
                height="12"
                width="12"
                class="text-darkGrey"
              />
            </div>
  
            <p class="text-darkGrey text-xs font-medium">
              {{ updatedAt }}
            </p>
          </div>
        </div>
      </div>

      <!-- Themes -->
      <div
        class="flex items-center gap-x-2"
      >
        <!-- There is only 1 theme -->
        <div
          v-for="(theme) in slicedThemesList"
          :key="theme"
          class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-white border border-darkGrey"
        >
          <p
            class="flex text-[10px] font-normal text-fakeBlack items-center"
          >
            {{ theme }}
          </p>
        </div>

        <!-- If there is more than 1 theme -->
        <div
          v-if="(themeList?.length - slicedThemesList?.length) > 0"
          class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-white border border-darkGrey"
        >
          <p
            class="flex text-[10px] font-normal text-fakeBlack items-center"
          >
            +{{ themeList?.length - slicedThemesList?.length }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const props = defineProps ({
  id: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  withDragIcon: { type: Boolean, default: false },
  toDiscover: { type: Boolean, default: false },
  isDraft: { type: Boolean, default: false },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  duration: { type: String, default: '' },
  updatedAt: { type: String, default: '' },
  isAcquired: { type: Boolean, default: false },
  acquisitionDate: { type: String, default: '' },
})

const { isMobile } = storeToRefs(useMobileStore())

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props?.themeList?.slice(0, 1)
  }
  else {
    return props?.themeList?.slice(0, 2)
  }
})
</script>