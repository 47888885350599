<template>
  <pop-up-skeleton>
    <template #title>
      <p class="text-fakeBlack text-xl font-medium">
        {{ $t('These targets will be updated and/or created') }}
      </p>
    </template>

    <template #subtitle>
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('If you confirm, all target creations and updates will be applied. You can follow up this in Roadmap app.') }}
      </p>
    </template>

    <template #body>
      <div class="flex flex-col mb-8 max-h-[175px] overflow-auto">
        <div
          v-for="(targetUpdated, index) in realTargetsSuggestionsUpdated"
          :key="index"
          class="flex flex-row justify-between items-center p-2 space-x-2 border-b border-b-lightGrey"
        >
          <div>
            <p class="text-fakeBlack text-start text-sm font-normal">
              {{ targetUpdated?.title }}
            </p>
          </div>

          <div class="flex items-center p-2 border border-primary bg-[#3177B7]/[0.1] rounded-[3px] h-[26px]">
            <p class="text-primary text-sm font-medium">
              {{ $t('Updated') }}
            </p>
          </div>
        </div>

        <div
          v-for="(targetCreated, index) in targetsSuggestionsCreated"
          :key="index"
          class="flex flex-row  justify-between items-center p-2 space-x-2 border-b border-b-lightGrey"
        >
          <div>
            <p class="text-fakeBlack text-start text-sm font-normal">
              {{ targetCreated?.title }}
            </p>
          </div>

          <div class="flex items-center p-2 border border-positiveGreen bg-[#7BC079]/[0.1] rounded-[3px] h-[26px]">
            <p class="text-positiveGreen text-sm font-medium">
              {{ $t('Created') }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template #buttons>
      <div
        class="w-full flex flex-col justify-center sm:flex-row gap-x-3 px-1.5"
      >
        <v-btn
          variant="outlined"
          color="black"
          class="text-fakeBlack border-darkGrey border-1 normal-case font-normal w-full sm:w-1/2 text-xs mb-3 sm:!mb-0"
          @click="closePopUp"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn
          variant="outlined"
          class="text-white bg-primary normal-case font-normal w-full sm:w-1/2 text-xs"
          @click="openSubmitAnswersPopUp"
        >
          {{ $t('Confirm') }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from "@/store/pop-up.js";
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/store/user';

onMounted(() => {
  localSuggestions.value = JSON.parse(localStorage.getItem('suggestions'))?.filter(suggestion => suggestion?.interview_id === Number(route?.params?.id))

  if (targetsSuggestionsUpdated.value?.length) {
    targetsSuggestionsUpdated.value.forEach((target) => {
      let currentUserSuggestions = target?.suggestions?.some((sug => sug?.user?.id === id?.value))
      if (currentUserSuggestions) {
        realTargetsSuggestionsUpdated.value = [...realTargetsSuggestionsUpdated.value, target]
      }
    })
  }
  if (localSuggestions?.value?.length) {
    realTargetsSuggestionsUpdated.value = localSuggestions.value
    realTargetsSuggestionsUpdated.value = realTargetsSuggestionsUpdated.value.map(target => {
      return { ...target, title: targetsSuggestionsUpdated?.value?.find(element => element?.objective_indicator?.id === target?.indicator_id)?.title }
    })
  }
})

const localSuggestions = ref([]);
const realTargetsSuggestionsUpdated = ref([])

const route = useRoute()
const { openPopUp, closePopUp } = usePopUpStore();

const { id } = storeToRefs(useUserStore());
const { targetsSuggestionsUpdated, targetsSuggestionsCreated } = storeToRefs(useTargetSuggestionsStore());

const openSubmitAnswersPopUp = async () => {
  closePopUp();
  openPopUp({
    componentName: 'pop-up-submit-interview-answers',
    title: '',
    showClose: false,
  })
};
</script>