<template>
  <pop-up-skeleton>
    <template #body>
      <div>
        <svn-date-picker
          v-model="currentDeadline"
        />
      </div>
    </template>

    <template #buttons>
      <div class="w-full flex justify-center mt-6">
        <v-btn
          color="primary"
          type="interview"
          class="w-full sm:w-[150px] h-14"
          @click="updateDeadline"
        >
          {{ $t("Save") }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { usePopUpStore } from "../../store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import axios from "../../tools/axios-service.js";
import filters from "../../tools/filters.js";
import { storeToRefs } from "pinia";

const { closePopUp } = usePopUpStore();

const { data } = storeToRefs(usePopUpStore());

const props = defineProps({
  campaignId: { type: String, default: null },
  deadline: { type: String, default: null },
});
const currentDeadline = ref(null);

currentDeadline.value = props.deadline ? props.deadline?.split("/").reverse().join("-") : data?.value?.deadline?.split("/").reverse().join("-");

const updateDeadline = async () => {
  const year = filters.formatDate(currentDeadline.value, "YYYY");
  const month = filters.formatDate(currentDeadline.value, "M");
  const day = filters.formatDate(currentDeadline.value, "DD");

  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${props.campaignId ? props.campaignId : data?.value?.deadline}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day,
      },
    });
  } catch (e) {
  } finally {
    closePopUp();
  }
};
</script>