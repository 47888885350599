<template>
  <!--    INFO: GENERIC MODAL-->
  <v-card class="!rounded-xl !shadow">
    <component
      :is="data.componentName"
      v-bind="data"
    />
  </v-card>
</template>

<script>
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import PopUpValidation from "./PopUpValidation.vue";
import PopUpContentValidation from "./PopUpContentValidation.vue";
import PopUpInfoInterviewStatus from "@/components/popUpComponents/PopUpInfoInterviewStatus.vue";
import PopUpCampaignEditDeadline from "@/components/popUpComponents/PopUpCampaignEditDeadline.vue";
import PopUpSetInterview from "@/components/popUpComponents/PopUpSetInterview.vue";
import PopUpSetAnotherInterviewer from "@/components/popUpComponents/PopUpSetAnotherInterviewer.vue";
import PopUpSetAnotherParticipant from "@/components/popUpComponents/PopUpSetAnotherParticipant.vue";
import PopUpActionDone from "@/components/popUpComponents/PopUpActionDone.vue";
import EditEntityTags from "@/components/popUpComponents/EditEntityTags.vue";
import InfoFilterCategoryTags from "@/components/infoFilterCategoryTagsComponents/InfoFilterCategoryTags.vue";
import PopUpShiftCrossInterviewDate from "@/components/popUpComponents/PopUpShiftCrossInterviewDate.vue";
import PopUpMultiChoiceDetails from "@/components/popUpComponents/PopUpMultiChoiceDetails.vue";
import PopUpUsersRoles from "@/components/popUpComponents/PopUpUsersRoles.vue";
import PopUpManagePairs from "@/components/popUpComponents/PopUpManagePairs.vue";
import PopUpDoNotParticipateSurvey from "@/components/popUpComponents/PopUpDoNotParticipateSurvey.vue";
import PopUpHistoryUpdates from "./PopUpHistoryUpdates.vue";
import PopUpEditTargetIndicatorAnswer from "./PopUpEditTargetIndicatorAnswer.vue";
import PopUpLeaveChannelSlack from "@/components/popUpComponents/PopUpLeaveChannelSlack.vue";
import PopUpLaunchCampaign from "./PopUpLaunchCampaign.vue";
import PopUpCreateTarget from "./PopUpCreateTarget.vue";
import PopUpEditTargetField from "./PopUpEditTargetField.vue";
import PopUpTargetFullDescription from "./PopUpTargetFullDescription.vue";
import PopUpSeeTargetFieldUpdate from "./PopUpSeeTargetFieldUpdate.vue";
import PopUpManageCopilots from "./PopUpManageCopilots.vue";
import PopUpCampaignEditStartline from "./PopUpCampaignEditStartline.vue";
import PopUpManageRecurrence from "./PopUpManageRecurrence.vue";
import PopUpSubmitInterviewAnswers from "./PopUpSubmitInterviewAnswers.vue";
import PopUpSubmitAnswersWithRoadmap from "./PopUpSubmitAnswersWithRoadmap.vue";
import popUpAddThisModuleToPlaylist from "./popUpAddThisModuleToPlaylist.vue";
import PopUpCreatePlaylist from "./PopUpCreatePlaylist.vue";
import PopUpAddModulesToPlaylist from "./PopUpAddModulesToPlaylist.vue";

export default {
  components: {
    PopUpInfoInterviewStatus,
    PopUpSkeleton,
    PopUpCampaignEditDeadline,
        PopUpSetAnotherInterviewer,
    PopUpSetAnotherParticipant,
    PopUpSetInterview,
    PopUpValidation,
    PopUpContentValidation,
    PopUpActionDone,
    InfoFilterCategoryTags,
    PopUpShiftCrossInterviewDate,
    PopUpMultiChoiceDetails,
    EditEntityTags,
    PopUpUsersRoles,
    PopUpManagePairs,
    PopUpDoNotParticipateSurvey,
    PopUpHistoryUpdates,
    PopUpEditTargetIndicatorAnswer,
    PopUpLeaveChannelSlack,
    PopUpLaunchCampaign,
    PopUpCreateTarget,
    PopUpEditTargetField,
    PopUpTargetFullDescription,
    PopUpSeeTargetFieldUpdate,
    PopUpManageCopilots,
    PopUpCampaignEditStartline,
    PopUpManageRecurrence,
    PopUpSubmitInterviewAnswers,
    PopUpSubmitAnswersWithRoadmap,
    popUpAddThisModuleToPlaylist,
    PopUpCreatePlaylist,
    PopUpAddModulesToPlaylist,
  },
  setup() {
    const { data } = storeToRefs(usePopUpStore());

    return {
      data,
    };
  },
};
</script>
