<template>
  <p class="text-3xl">
    <Icon
      height="40"
      :icon="campaign_icon(campaignType)"
    />
  </p>
  <div class="w-full ml-0 md:!ml-2">
    <p
      class="font-bold text-base text-center w-full whitespace-normal
        md:!text-start md:max-w-[50%] md:truncate"
    >
      {{ campaignName }}
    </p>
    <v-list-item-subtitle
      class="text-center text-[10px] text-darkGrey
        sm:text-sm md:w-fit font-medium"
    >
      {{ $t('Deadline') }}: {{ $filters.formatDate(campaignDeadline) }}
    </v-list-item-subtitle>
  </div>
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";
import {Icon} from "@iconify/vue";

const { campaign_icon } = useCampaigns();

// Props
const props = defineProps({
  campaignName: { type: String, required: true },
  campaignType: { type: String, required: true },
  campaignDeadline: { type: String, required: true },
})
</script>
