<template>
  <svn-center-content
    background-color-class="bg-deepBlack"
  >
    <template #centered-content>
      <div
        class="flex flex-col items-center justify-center gap-6 2xl:!gap-20 px-8 pt-8 pb-16 2xl:!px-0 2xl:!w-[732px]"
      >
        <bkt-logo-login />

        <svn-login-card>
          <template #box-content>
            <div class="flex flex-column items-start w-full">
              <div class="flex flex-column items-start gap-6 w-full">
                <div class="flex flex-col items-center md:items-start justify-center gap-4 w-full">
                  <p class="text-xl 2xl:!text-2xl font-semibold">
                    {{ $t('Oops, it seems this email is not registered 🧐') }}
                  </p>

                  <p class="text-sm font-medium text-darkGrey">
                    {{ $t('You may have miswritten your email, please try again.') }}
                  </p>
                </div>

                <v-text-field
                  v-model="email"
                  :label="$t('Email')"
                  class="w-full"
                  type="text"
                  variant="outlined"
                  error
                  error-messages="Email not found"
                >
                  <template #message>
                    <div class="flex items-center gap-2 w-full ms-1 me-8">
                      <Icon
                        icon="material-symbols:info"
                        heigth="16"
                        width="16"
                      />
                      <p>{{ $t('Email not found') }}</p>
                    </div>
                  </template>
                </v-text-field>

                <div class="flex flex-column items-start gap-2 w-full">
                  <v-btn
                    class="w-full"
                    height="40"
                    color="fakeBlack"
                    theme="company"
                    @click="backToSignIn"
                  >
                    <v-icon class="mr-2">
                      mdi-arrow-left
                    </v-icon>
                    <p class="normal-case">{{ $t('Try again') }}</p>
                  </v-btn>
                  <!-- <v-btn
                    class="w-full"
                    height="40"
                    color="fakeBlack"
                    href="mailto:support.aleph@byseven.co"
                  >
                    <p class="normal-case">{{ $t('Contact Aleph support') }}</p>
                  </v-btn> -->
                </div>
              </div>
            </div>
          </template>
        </svn-login-card>
      </div>
    </template>
  </svn-center-content>
</template>

<script setup>
import {useRouter} from 'vue-router'
import {useUserStore} from "@/store/user.js";
import {storeToRefs} from "pinia";
import BktLogoLogin from '@/components/BktLogoLogin.vue';
import { Icon } from '@iconify/vue';

const router = useRouter()

const {email} = storeToRefs(useUserStore())

const backToSignIn = () => {
  router.back()
}

const mailTo = () => {

}
</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;

}

:deep(.v-field) {
  border-radius: 8px!important;
}
</style>
