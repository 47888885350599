<template>
  <div class="container-fluid ">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
      <div
        v-for="(box, idx) in getBoxes"
        :key="idx"
      >
        <target-indicator-box
          :options="box"
          :disabled="modelValue?.attribute_disablement?.indicator || isTrashed"
          @click="updateIndicatorType(box)"
        />
      </div>
    </div>

    <div class="row mt-8">
      <div
        v-for="(box, idx) in getSelectedBoxes"
        :key="idx"
        class="flex flex-col"
      >
        <div class="flex flex-row justify-between items-center w-full">
          <div class="flex flex-row items-center">
            <svn-tooltip>
              <template #activator-content>
                <Icon
                  v-bind="props"
                  icon="ic-outline-info"
                  height="20"
                  width="20"
                  class="hidden sm:block mr-2"
                  color="darkGrey"
                />
              </template>

              <template #tooltip-content>
                {{ $t(box.longDesc) }}
              </template>
            </svn-tooltip>

            <p class="text-base text-start font-medium btext-fakeBlack">
              {{ $t(box.title) }}
            </p>
          </div>

          <div
            v-if="withLock && !isTrashed"
            class="cursor-pointer bkt-bg-light-grey9-hover rounded-5px p-2"
            @click="toggleLockStatus()"
          >
            <svn-tooltip>
              <template #activator-content>
                <Icon
                  v-bind="props"
                  :icon="indicatorValueLocked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                  height="24"
                  width="24"
                  class="outline-none"
                  :class="indicatorValueLocked ? 'text-darkGrey' : 'text-middleGrey'"
                />
              </template>

              <template #tooltip-content>
                <span v-if="indicatorValueLocked">
                {{ $t('When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.') }}
                </span>

                <span v-else>
                  {{ $t('When creating or updating a target, this field will be editable.') }}
                </span>
              </template>
            </svn-tooltip>
          </div>
        </div>

        <!--        for multi-->
        <div
          v-if="box.type === 'multi_choice'"
          class="flex flex-col"
        >
          <p class="font-normal text-xs text-primary mt-2 mb-1">
            {{ $t('Target value') }}
          </p>

          <div
            v-for="(multiChoiceValue, idxInput) in modelValue.multi_choice_list"
            :key="idxInput"
            class="flex justify-between items-center mb-2"
          >
            <div
              v-if="create"
              class="flex flex-row justify-center items-center mx-2"
            >
              <v-checkbox
                v-model="multiChoiceValue.checkbox"
                label=""
                color="primary"
                hide-details
                :disabled="modelValue?.attribute_disablement?.indicator_values || isTrashed"
              />
            </div>

            <div class="w-3/4">
              <v-text-field
                :placeholder="$t('New option')"
                :model-value="multiChoiceValue.option"
                hide-details
                v-bind="isMobile ? { variant: 'outlined' } : ''"
                :disabled="modelValue?.attribute_disablement?.indicator_values || isTrashed"
                @update:model-value="updateOption(idxInput, $event)"
              />
            </div>

            <div
              class="cursor-pointer mx-3"
              @click="removeOption(idxInput)"
            >
              <Icon
                icon="mdi-close"
                height="24"
                width="24"
                :color="idxInput === 0 || modelValue?.attribute_disablement?.indicator_values || isTrashed  ? 'darkGrey' : 'fakeBlack'"
              />
            </div>
          </div>

          <v-btn
            class="mt-3 max-w-[200px] text-primary normal-case border border-primary border-opacity-100 rounded-[4px]"
            variant="outlined"
            prepend-icon="mdi-plus"
            color="white"
            height="45"
            :disabled="modelValue?.attribute_disablement?.indicator_values || isTrashed"
            @click="addOption"
          >
            {{ $t('Add an option') }}
          </v-btn>
        </div>

        <!--          for others-->
        <div
          v-else
          class="grid grid-cols-2 gap-x-4"
        >
          <div class="flex flex-row justify-center items-center ">
            <div
              class="w-full flex flex-col sm:flex-row items-center"
            >
              <div class="w-full flex flex-row justify-start sm:justify-center items-center gap-2 mb-2">
                <div>
                  <Icon
                    icon="ic-round-flag"
                    height="24"
                    width="24"
                    :class=" isMobile ? 'hidden' : 'text-primary'"
                  />
                </div>
                <p class="mx-0 text-xs font-normal text-fakeBlack sm:mx-2">
                  {{ $t('Starting value') }}
                </p>
              </div>
              <v-text-field
                class="w-full"
                :type="box.type === 'boolean' ? 'text' : 'number'"
                :model-value="modelValue.starting_value"
                hide-details
                v-bind="isMobile ? { variant: 'outlined' } : ''"
                :disabled="modelValue?.attribute_disablement?.indicator_values || isTrashed"
                @update:model-value="$emit('update:modelValue', {...modelValue, starting_value: $event,})"
              />
            </div>
          </div>

          <div class="flex flex-row justify-center items-center ">
            <div
              class="w-full flex flex-col sm:flex-row items-center"
            >
              <div class="w-full flex flex-row justify-start sm:justify-center items-center gap-2 mb-2">
                <div>
                  <Icon
                    icon="mdi-target-arrow"
                    height="24"
                    width="24"
                    :class=" isMobile ? 'hidden' : 'text-primary'"
                  />
                </div>
                <p class="mx-0 text-xs font-normal text-fakeBlack sm:mx-2">
                  {{ $t('Target value') }}
                </p>
              </div>

              <v-text-field
                class="w-full"
                :type="box.type === 'boolean' ? 'text' : 'number'"
                :model-value="modelValue.target_value"
                hide-details
                :disabled="modelValue?.attribute_disablement?.indicator_values || isTrashed"
                v-bind="isMobile ? { variant: 'outlined' } : ''"
                @update:model-value="$emit('update:modelValue', {...modelValue, target_value: $event,})"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import TargetIndicatorBox from "./TargetIndicatorBox.vue";
import { computed, onUpdated, ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile.js";
import i18n from '@/plugins/i18n';


const emit = defineEmits(["update:modelValue", 'toggle-target-indicator-value-lock-status']);

const props = defineProps(["modelValue", "create", "withLock", 'lockValue', 'isTrashed']);

const {isMobile} = storeToRefs(useMobileStore())
const indicatorValueLocked = ref(false);
const boxes = ref([
  {
    type: "boolean",
    iconify: "ic:outline-toggle-on",
    title: "True/False",
    shortDesc:
      "For non quantifiable objectives, you can choose the value of the two booleans.",
    longDesc:
      "Define the two words you want to measure the objective. False will be the default value, then you could switch to True value.",
    selected: false,
  },
  {
    type: "numeric_value",
    iconify: "ic:baseline-numbers",
    title: "Number",
    shortDesc:
      "For quantifiable objectives, choose a value (persons, money, apples, ...), a start and target value.",
    longDesc:
      "Define a starting and a target value to measure the objective completion.",
    selected: false,
  },
  {
    type: "percentage",
    iconify: "fa6-solid:percent",
    title: "Percent",
    shortDesc: "Useful to measure easily a quantifiable objective.",
    longDesc:
      "Use a percentage based value to measure the objective completion.",
    selected: false,
  },
  {
    type: "multi_choice",
    iconify: "fluent:text-bullet-list-ltr-16-filled",
    title: "Multi-choice",
    shortDesc:
      "For non quantifiable objectives, you can add and edit choices to rate or qualify your objective.",
    longDesc: "Add options to qualify the objective completion. You can choose which option will be considered a target value by ticking 'target value'.",
    selected: false,
  },
]);

const getBoxes = computed(() => {
  return boxes.value.map((box) => {
    return {
      ...box,
      selected: props.modelValue.indicator_type === box.type,
    };
  });
});

const getSelectedBoxes = computed(() =>
  getBoxes.value.filter((box) => box.selected)
);

onUpdated(() => {
  indicatorValueLocked.value = props.lockValue
})

const addOption = () => {
  if (!props.modelValue?.attribute_disablement?.indicator_values) {
    const obj = { ...props.modelValue };
    obj.multi_choice_list.push({ option: '', checkbox: false });
    emit("update:modelValue", { ...obj });
  }
};

const updateOption = (idxInput, newOption) => {
  if (props.modelValue.multi_choice_list.length > idxInput) {
    const obj = { ...props.modelValue };
    obj.multi_choice_list[idxInput]['option'] = newOption;
    emit("update:modelValue", { ...obj });
  }
};

const removeOption = (idx) => {
  if (!props.modelValue?.attribute_disablement?.indicator_values) {
    if (idx !== 0  && !props.isTrashed) {
      const obj = { ...props.modelValue };
      obj.multi_choice_list.splice(idx, 1)
      emit("update:modelValue", { ...obj });
    }
  }
}

const updateIndicatorType = (box) => {
  if (props.isTrashed) return null
  if (!props.modelValue?.attribute_disablement?.indicator) {
    emit('update:modelValue', {
      ...props.modelValue,
      indicator_type: box.type,
    })
  }
}

const toggleLockStatus = () => {
  indicatorValueLocked.value = !indicatorValueLocked.value;
  emit('toggle-target-indicator-value-lock-status', indicatorValueLocked.value)
}
</script>
