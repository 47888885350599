<template>
  <pop-up-skeleton
    v-if="campaign?.campaign_type === 'OneToOne' || campaign?.campaign_type === 'Feedback'"
    :title="$t('Select another participant')"
  >
    <template #subtitle>
      <svn-text sm medium color="dark-grey">
        {{ $t('Search the participant you wish to add to the campaign.') }}
      </svn-text>
    </template>

    <template #body>
      <svn-text base semiBold class="self-start my-2">
        {{ $t('Participant') }} :
      </svn-text>

      <!-- Autocomplete User Participant -->
      <svn-autocomplete
        :label="$t('Search')"
        avatar
        :items="usersNotInCurrentCampaign"
        class="min-w-full"
        :loading="loading"
        @update:model-value="setParticipant"
        @input="customSearchUsers"
        @intersect="onIntersectUsers"
      >
        <template #prepend-details="{ item }">
          <svn-avatar
            size="md"
            :firstname="item.raw.firstname"
            :lastname="item.raw.lastname"
            :avatar="item?.raw?.avatar?.['30']"
          />
        </template>

        <template #main-content>
          {{ null }}
        </template>

        <template #append-content>
          {{ null }}
        </template>
      </svn-autocomplete>

      <svn-text base semiBold class="self-start my-2">
        {{ $t('Person in charge') }} :
      </svn-text>

      <!-- Autocomplete person in charge -->
      <svn-autocomplete
        :label="$t('Search')"
        avatar
        :items="managers"
        class="min-w-full"
        :loading="loading"
        @update:model-value="setPersonInCharge"
        @input="customSearchManagers"
        @intersect="onIntersectManagers"
      >
        <template #prepend-details="{ item }">
          <svn-avatar
            size="md"
            :firstname="item.raw.firstname"
            :lastname="item.raw.lastname"
            :avatar="item?.raw?.avatar?.['30']"
          />
        </template>

        <template #main-content>
          {{ null }}
        </template>

        <template #append-content>
          {{ null }}
        </template>
      </svn-autocomplete>
    </template>

    <template #buttons>
      <svn-button
        class="w-full sm:w-[125px] sm:self-center"
        :disable="!participant || !personInCharge "
        @click="addFeeback"
      >
        <template #button-text>
          <svn-text sm color="white" class="uppercase">
            {{ $t("Confirm") }}
          </svn-text>
        </template>
      </svn-button>
    </template>
  </pop-up-skeleton>

  <pop-up-skeleton v-else>
    <template #subtitle>
      <svn-text sm medium color="dark-grey">
        {{ $t('Search the participants you wish to add to the campaign.') }}
      </svn-text>
    </template>

    <template #body>
      <svn-text base semiBold class="self-start my-2">
        {{ $t('Participants') }} :
      </svn-text>

      <!-- Autocomplete pour les SURVEYs -->
      <svn-autocomplete
        :label="$t('Search')"
        avatar
        :items="usersNotInCurrentCampaign"
        :menu-props="{ closeOnContentClick: false }"
        with-add-and-remove-buttons
        :disabling-function="isPersonInCampaign"
        multiple
        avatar-chip
        class="min-w-full"
        :loading="loading"
        @update:model-value="selectUser"
        @input="participantsSearch"
        @intersect="onIntersectUsers"
      >
        <template #prepend-details="{ item }">
          <svn-avatar
            size="md"
            :firstname="item.raw.firstname"
            :lastname="item.raw.lastname"
            :avatar="item?.raw?.avatar?.['30']"
          />
        </template>

        <template #append-content="{ item }">
          <svn-button
            v-if="isPersonInCampaign(item.value)"
            variant="none"
            prepend-icon="mdi-check"
            prepend-class="text-green"
          >
            <template #button-text>
              <svn-text sm medium color="green" class="uppercase">
                {{ $t('Added') }}
              </svn-text>
            </template>
          </svn-button>

          <svn-button
            v-else
            variant="none"
            prepend-icon="mdi-plus"
            prepend-class="text-primary"
          >
            <template #button-text>
              <svn-text sm medium color="primary" class="uppercase">
                {{ $t('Add') }}
              </svn-text>
            </template>
          </svn-button>
        </template>
      </svn-autocomplete>
    </template>

    <template #buttons>
      <svn-button
        class="w-full sm:w-[125px] sm:self-center"
        :disable="participants.length === 0 ? true : false"
        @click="addParticipantsAndClosePopUp"
      >
        <template #button-text>
          <svn-text sm color="white" class="uppercase">
            {{ $t("Confirm") }}
          </svn-text>
        </template>
      </svn-button>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { usePopUpStore } from "@/store/pop-up.js";
import { onMounted, ref } from "vue";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import { storeToRefs } from "pinia";
import { useCampaignStore } from "@/store/campaign";
import { useSnackbar } from "@/store/snackbar";
import axiosService from "@/tools/axios-service.js";
import i18n from "@/plugins/i18n";

const { closePopUp, callback } = usePopUpStore();

const { campaign } = storeToRefs(useCampaignStore());

const pageUsers = ref(1);
const totalPagesUsers = ref(null)
const pageManagers = ref(1)
const totalPagesManagers = ref(null)
const loading = ref(true);
const snackbar = useSnackbar();
const participant = ref(null);
const participants = ref([]);
const personInCharge = ref(null);

const props = defineProps({
  participantId: { type: Number, default: null },
  feedbackId: { type: Number, default: null },
  campaignId: { type: String, default: null },
  set: { type: Array, required: true },
});

const managers = ref([]);
const usersNotInCurrentCampaign = ref([]);

onMounted(async () => {
  try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
    { params: {
        not_campaign_id: props.campaignId,
        "page[size]": 25
      }
    });
    usersNotInCurrentCampaign.value = data?.users;
    totalPagesUsers.value = data?.meta?.pagination?.total_pages
    if (data?.users?.length) {
      loading.value = false
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
      { params: {
        only_managers: true,
        "page[size]": 25
      }
    });
    managers.value = data?.users;
    totalPagesManagers.value = data?.meta?.pagination?.total_pages
    if (data?.users?.length) {
      loading.value = false
    }
} catch (error) {
  console.log(error);
}
});

const setParticipant = (userId) => {
  participant.value = usersNotInCurrentCampaign.value.find(user => user.id === userId);
}

const setPersonInCharge = (managerId) => {
  personInCharge.value = managers.value.find(user => user.id === managerId);
}

const selectUser = async(userIds) => {
  participants.value = userIds
}

const onIntersectUsers = async() => {
  if (pageUsers.value < totalPagesUsers.value) {
    pageUsers.value++;
    try {
      const { data } = await axiosService.get(`/api/v1/users/users_search`,
      { params: {
          not_campaign_id: props.campaignId,
          "page[size]": 25,
          "page[number]": pageUsers.value
        }
      });
      usersNotInCurrentCampaign.value = usersNotInCurrentCampaign.value.concat(...data?.users);
    } catch (error) {
      console.log(error);
    }
  }
}

const onIntersectManagers = async() => {
  if (pageManagers.value < totalPagesManagers.value) {
    pageManagers.value++
    try {
      const { data } = await axiosService.get(`/api/v1/users/users_search`,
      { params: {
          only_managers: true,
          "page[size]": 25,
          "page[number]": pageManagers.value
        }
      });
      managers.value = managers.value.concat(...data?.users);
    } catch (error) {
      console.log(error);
    }
  }
}

const customSearchUsers = async (search) => {
    try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
    { params: {
        text: search,
        not_campaign_id: props.campaignId,
        "page[size]": 25
      }
    });
    participant.value = null
    usersNotInCurrentCampaign.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};

const customSearchManagers = async (search) => {
    try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
    { params: {
        text: search,
        only_managers: true,
        "page[size]": 25
      }
    });
    personInCharge.value = null
    managers.value = data?.users;
  } catch (error) {
    console.log(error);
  }
};


const participantsSearch = async(search) => {
  try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
    { params: {
        text: search,
        not_campaign_id: props.campaignId,
        "page[size]": 25
      }
    });
    participant.value = null
    usersNotInCurrentCampaign.value = data?.users;
  } catch (error) {
    console.log(error);
  }
}


const isPersonInCampaign = (userId) => {
  if (participants.value.includes(userId))
    return true;
  return false;
};

const addFeeback = async() => {
  if (participant.value !== null && personInCharge.value !== null) {
    try {
      await axiosService.post(`/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/create_sets.json`, {
        'employee_id': participant.value.id,
        'interviewer_id': personInCharge.value.id,
      })

      closePopUp();
      callback();

      snackbar.setMsg(i18n.global.t('Successfully added participant !'));
      snackbar.setBgColor('success');
      snackbar.displaySnackBar();
    } catch (e) {
      snackbar.setMsg(i18n.global.t('Error adding participant !'));
      snackbar.setBgColor('error');
      snackbar.displaySnackBar();
    }
  }
}

const addParticipantsAndClosePopUp = async() => {
    if (participants.value.length > 0) {
    for (const participantId of participants.value) {
      try {
        await axiosService.post(`/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/create_sets.json`, { 'employee_id': participantId });

        snackbar.setMsg(i18n.global.t('Successfully added participants !'));
        snackbar.setBgColor('success');
        snackbar.displaySnackBar();
      }
      catch (error) {
        snackbar.setMsg(i18n.global.t('Error adding participant !'));
        snackbar.setBgColor('error');
        snackbar.displaySnackBar();
      }
    }
    closePopUp();
    callback();
  }
}
</script>
