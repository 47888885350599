<template>
  <svn-modal-skeleton
    ref="modalUserBadges"
    no-click-animation
    :modal-title="$t('Badges')"
    :modal-title-center="!isMobile"
    :modal-custom-class="isMobile ? null : '!w-auto !rounded-2xl overflow-hidden'"
  >
    <template #activator-div>
      {{ null }}
    </template>

    <template #content-body>
      <div class="w-full h-full bg-white flex flex-col p-5 mb-10 sm:!mb-0 gap-6 self-stretch">
        <div
          v-for="(action, index) in actions"
          :key="index"
          class="w-full flex flex-col items-start gap-2 self-stretch"
        >
          <p class="text-fake-black font-medium text-base leading-6">
            {{ $t(action.text) }}
          </p>

          <div class="w-full h-full flex flex-nowrap justify-start items-start gap-6 overflow-x-auto">
            <Badge
              v-for="(badge, idx) in action.badges"
              :key="idx"
              :badge="badge"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-modal-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import BaseCompleted from '@/assets/images/badges/base_completed.svg';
import BaseOngoing from '@/assets/images/badges/base_ongoing.svg';
import OpenBookCompleted from '@/assets/images/badges/open_book_completed.svg';
import OpenBookOngoing from '@/assets/images/badges/open_book_ongoing.svg';
import PuzzleCompleted from '@/assets/images/badges/puzzle_completed.svg';
import PuzzleOngoing from '@/assets/images/badges/puzzle_ongoing.svg';
import ThumbCompleted from '@/assets/images/badges/thumb_completed.svg';
import ThumbOngoing from '@/assets/images/badges/thumb_ongoing.svg';
import HeartCompleted from '@/assets/images/badges/heart_completed.svg';
import HeartOngoing from '@/assets/images/badges/heart_ongoing.svg';
import Badge from "@/components/badge/Badge.vue";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  userBadges: {type: Object, required: true}
});

const modalUserBadges = ref(null);

const defineBadges = (breakpoints, wheelLink, iconLink, totalScore ) => {
  let lastBreakpoint = 0

  const breakpointResult = breakpoints.map((breakpointData) => {
    const previousBreakpoint = breakpointData[0]
    const breakpoint = breakpointData[1]
    const title = breakpointData[2]
    const isOngoing = totalScore <= breakpoint && totalScore > lastBreakpoint
    lastBreakpoint = breakpoint

    if (totalScore >= breakpoint) {
      return {
        progress: breakpoint,
        wheelLink: wheelLink.completed,
        iconLink: iconLink.completed,
        class: 'opacity-100',
        isOngoing: false,
        // percentage: 100 * totalScore / breakpoint,
        title: title
      }
    }
    else if (totalScore < previousBreakpoint) {
      return {
        progress: breakpoint,
        wheelLink: wheelLink.ongoing,
        iconLink: iconLink.ongoing,
        class: 'opacity-50',
        isOngoing: true,
        // percentage: 100 * totalScore / breakpoint,
        title: title
      }
    }
    else if (totalScore < breakpoint) {
      return {
        progress: totalScore + "/" + breakpoint,
        wheelLink: wheelLink.ongoing,
        iconLink: iconLink.ongoing,
        class: (totalScore >= previousBreakpoint && totalScore) ? 'opacity-100' : 'opacity-50',
        isOngoing: true,
        percentage: 100 * totalScore / breakpoint,
        title: title
      }
    }
  })

  return breakpointResult
}

const actions = ref([
  {
    text: "Acquire a module",
    badges: defineBadges(
      [
        [0, 1, "Enlightened Novice"],
        [1, 5, "Knowledge Explorer"],
        [5, 10, "Thirsty for Knowledge"],
        [10, 25, "Curriculum Master"]
      ],
      {
        completed: BaseCompleted,
        ongoing: BaseOngoing
      },
      {
        completed: PuzzleCompleted,
        ongoing: PuzzleOngoing
      },
      props.userBadges?.acquireModuleCount
    )
  },
  {
    text: "React to a module",
    badges: defineBadges(
      [
        [0, 1, "The Approver"],
        [1, 5, "Positive Supporter"],
        [5, 10, "Enlightened Evaluator"],
        [10, 25, "Master of Emotions"]
      ],
      {
        completed: BaseCompleted,
        ongoing: BaseOngoing
      },
      {
        completed: ThumbCompleted,
        ongoing: ThumbOngoing
      },
      props.userBadges?.reactModuleCount
    )
  },
  {
    text: "Complete a training",
    badges: defineBadges(
      [
        [0, 1, "Accomplished Apprentice"],
        [1, 5, "Trained and Competent"],
        [5, 10, "Certified Expert"],
        [10, 25, "Training Champion"]
      ],
      {
        completed: BaseCompleted,
        ongoing: BaseOngoing
      },
      {
        completed: OpenBookCompleted,
        ongoing: OpenBookOngoing
      },
      props.userBadges?.completeTrainingCount
    )
  },
  {
    text: "Favorite a module",
    badges: defineBadges(
      [
        [0, 1, "The Selector"],
        [1, 5, "Content Lover"],
        [5, 10, "Informed Collector"],
        [10, 25, "Resource Guardian"]
      ],
      {
        completed: BaseCompleted,
        ongoing: BaseOngoing
      },
      {
        completed: HeartCompleted,
        ongoing: HeartOngoing
      },
      props.userBadges?.favoriteModuleCount
    )
  },
])

defineExpose({
  modalUserBadges
})
</script>