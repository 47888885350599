<template>
  <v-data-table
    v-model="selectedUsers"
    :headers="headers"
    :items="roadmapTargets ? roadmapTargets : []"
    :loading="roadmapTargets ? false : true"
    :items-per-page="-1"
    show-select
    class="mt-4"
    @update:model-value="updateSelectedUsers($event)"
  >
    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey9-hover h-14"
      >
        <td>
          <v-checkbox
            v-model="selectedUsers"
            hide-details
            :value="item.value"
            @update:model-value="updateSelectedUsers($event)"
          />
        </td>

        <td class="px-0">
          <div
            class="flex flex-row justify-start items-center px-4 max-w-[250px]"
            @click="goToTargetShow(item?.raw?.title, item?.raw?.id)"
          >
            <p class="font-normal text-fakeBlack text-sm text-truncate">
              {{ item?.raw?.title }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <div
            class="flex flex-row justify-start items-center px-4 max-w-[350px]"
            @click="goToTargetShow(item?.raw?.title, item?.raw?.id)"
          >
            <svn-avatar
              :avatar="item.raw?.employee?.avatar?.['50']"
              :firstname="item.raw?.employee?.firstname"
              :lastname="item.raw?.employee?.lastname"
              size="lg"
              class="mr-3"
            />

            <p class="font-medium text-fakeBlack text-sm text-truncate">
              {{ item?.raw?.employee?.firstname }}
              {{ ' ' }}
              {{ item?.raw?.employee?.lastname }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <div
            class="flex flex-row justify-start px-4 max-w-[250px]"
            @click="goToTargetShow(item?.raw?.title, item?.raw?.id)"
          >
            <svn-tooltip
              v-if="item?.raw?.objective_indicator?.indicator_type === 'multi_choice' &&
                item.raw.objective_indicator.options.current_value !== undefined"
              location="top end"
              origin="bottom start"
            >
              <template #activator-content>
                <my-roadmaps-objective-indicator :objective-indicator="item?.raw?.objective_indicator" />
              </template>

              <template #tooltip-content>
                {{ item.raw.objective_indicator.options.current_value }}
              </template>
            </svn-tooltip>

            <my-roadmaps-objective-indicator
              v-else
              :objective-indicator="item?.raw?.objective_indicator"
            />
          </div>
        </td>

        <td class="px-0">
          <div
            class="flex flex-row justify-start px-4"
            @click="goToTargetShow(item?.raw?.title, item?.raw?.id)"
          >
            <p
              class="text-fakeBlack text-sm font-normal"
            >
              {{ item?.raw?.due_date }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="delete"
                class="text-negativeRed"
                @click="openPopUpDelete(item?.raw?.id)"
              >
                {{ $t('Delete target') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && roadmapTargets && roadmapTargets.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="p-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>

        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col jsutify-center items-center my-16">
        <Icon
          class="mb-5"
          icon="noto-winking-face"
          height="24"
          width="24"
        />

        <p class="font-medium text-sm mb-5">
          {{ $t('Hmm, it seems you have no targets yet. Create your first target !') }}
        </p>

        <bkt-new-target-button />
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import { storeToRefs } from "pinia";
import MyRoadmapsObjectiveIndicator from "../MyRoadmapsObjectiveIndicator.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import { Icon } from "@iconify/vue/dist/iconify.js";
import i18n from '@/plugins/i18n'
import { useRouter } from "vue-router";
import { usePopUpStore } from "@/store/pop-up";
import { useSnackbar } from "@/store/snackbar";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";

const props = defineProps({
  roadmapTargets: { type: Object, default: () => {} }
})

const headers = [
  {
    align: "start",
    key: "targets",
    sortable: false,
    title: i18n.global.t('Targets'),
  },
  {
    align: "start",
    key: "people",
    sortable: false,
    title: i18n.global.t('People'),
  },
  {
    align: "start",
    key: "completion",
    sortable: false,
    title: i18n.global.t('Completion'),
    width: 150,
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
  }
];

const emit = defineEmits(['delete']);

const router = useRouter();
const snackbar = useSnackbar();
const { pagination, selectedUsers } = storeToRefs(useRoadmapTargetStore());
const { updateParams, fetchRoadmapTargets, setRoadmapTargets, setSelectedUsers } = useRoadmapTargetStore();

const { openPopUp, closePopUp } = usePopUpStore();
const { addNode, update } = useBreadcrumbsStore()

const fetchNewPage = (e) => {
  setRoadmapTargets(null);
  updateParams({
    "page[number]": e,
  });
  fetchRoadmapTargets();
};

const updateSelectedUsers = (e) => {
  setSelectedUsers(e)
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this target ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No",
    textConfirm: "Yes, delete",
    color: 'red',
    customClass: '',
    async confirm() {
      try {
        emit('delete', id)
        closePopUp()
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been deleted.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while deleting your target !')
      }
    },
  })
}

const goToTargetShow = (title, targetId) => {
  addNode(title, `/roadmaps/targets/${targetId}`)
  update()
  router.push({ name: 'roadmaps_targets_show', params: { id: targetId } })
}

</script>

<style scoped>
* :deep(.v-data-table__th) {
  background-color: #F6F6F6 !important;
  color: #787878 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}
</style>
