<template>
  <div class="w-100">
    <trash-warnning
      v-if="isTrashed"
      title="This campaign is in trash."
      @restore="restoreDeletedItem"
      @delete-permanently="deleteCampaignPermanently"
    />
  </div>
  <bkt-index-skeleton
    v-if="isManagerCreatorOrAbove()"
    body-class="bkt-bg-white box-shadow-none !px-0 relative"
  >
    <template #title-content>
      <div class="w-full flex flex-row items-center justify-between -top-3 pt-5 mb-0 sm:!mb-[138px]">
        <v-breadcrumbs
          class="hidden w-full justify-start items-center sm:flex p-0"
          data-testid="breadcrumb"
        >
          <div class="flex flex-row justify-center items-center">
            <v-breadcrumbs-item class="mx-2">
              <v-btn
                variant="flat"
                class="normal-case font-normal text-darkGrey px-0"
                @click="goToCampaigns"
              >
                {{ $t('Campaigns') }}
              </v-btn>
            </v-breadcrumbs-item>

            <v-breadcrumbs-divider class="p-1">
              <Icon
                class="iconify text-middleGrey font-semibold text-xs"
                icon="akar-icons:chevron-right"
              />
            </v-breadcrumbs-divider>

            <v-breadcrumbs-item class="mx-2">
              <v-btn variant="flat" class="mr-2 normal-case bkt-fakeblack-grey pl-3 text-fakeBlack font-medium text-sm">
                <p class="sm:max-xl:max-w-[75%] truncate overflow-ellipsis">
                  {{ campaign?.title }}
                </p>
              </v-btn>
            </v-breadcrumbs-item>
          </div>
        </v-breadcrumbs>

        <div class="items-center text-sm text-center sm:text-start w-[300px]">
          <p class="bkt-light-grey">
            {{ $t("Deadline") }} : {{ campaign?.deadline }}
          </p>
        </div>
      </div>
    </template>

    <template #body>
      <div class="w-full">
        <div class="w-full flex relative">
          <v-tabs
            v-model="tab"
            :grow="isMobile"
            align-tabs="center"
            theme="interview"
            class="w-full flex items-end border-b-2 ml-0 sm:!ml-20 mt-12 mb-6 sm:!mt-0 sm:!mb-0 overflow-visible"
          >
            <v-tab
              :value="1"
            >
              {{ $t('Overview') }}
            </v-tab>

            <v-tab
              :value="2"
            >
              {{ $t('Participants') }}
              <div class="rounded-full bkt-bg-light-blue ml-1 p-1">
                {{ campaign ? campaign?.employees_count : 0 }}
              </div>
            </v-tab>

            <v-tab
              v-if="campaign?.campaign_type === CampaignTypeBackend.SURVEY"
              :value="3"
            >
              {{ $t('Answers') }}
              <div class="rounded-full bkt-bg-light-blue ml-1 p-1">
                {{ completionAll?.['submitted'] || 0 }}
              </div>
            </v-tab>
          </v-tabs>

          <div
            v-if="isAdminOrAbove()"
            class="border-b-2 h-full hidden sm:flex items-center justify-center self-end"
          >
            <svn-tooltip
              location="start center"
              origin="end center"
            >
              <template #activator-content>
                <svn-icon-button
                  variant="hoverNoPrimary"
                  icon="mdi-account-pilot"
                  button-size="lg"
                  class="!text-middle-grey"
                  @click="openManageCopilotsPopUp"
                />
              </template>

              <template #tooltip-content>
                {{ $t('The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.') }}
              </template>
            </svn-tooltip>

            <!-- campaign settings menu is a custom component because I need to import a component on list item-->
            <v-menu v-if="!isTrashed">
              <template #activator="{ props }">
                <svn-icon-button
                  v-bind="props"
                  variant="hoverNoPrimary"
                  icon="mdi-dots-vertical"
                  button-size="lg"
                  class="!text-middle-grey"
                />
              </template>

              <v-list>
                <bkt-slack-btn
                  v-if="campaign?.campaign_type === 'Survey' && overview === 'overview'"
                  :access-token="campaign?.slack_access_token"
                  :slack-channel-id="campaign?.slack_channel_id"
                  @connected="saveSlackAccessToken"
                  @channel-selected="joinChannel"
                  @leave-channel="slackLeaveChannel"
                />

                <v-list-item
                  @click="openEditDeadline(campaign?.deadline)"
                >
                  <v-list-item-title>
                    {{ $t('Edit deadline') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="campaign?.completion !== 100"
                  @click="deleteCampaign(campaign?.id)"
                >
                  <v-list-item-title class="text-negativeRed">
                    {{ $t('Delete campaign') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-window
          v-if="campaign"
          v-model="tab"
          class="pt-6"
        >
          <v-window-item
            :key="1"
            :value="1"
          >
            <div class="w-full">
              <campaign-overview />
            </div>
          </v-window-item>

          <v-window-item
            :key="2"
            :value="2"
          >
            <div
              v-if="campaign"
              class="w-full"
            >
              <BktSurveyParticipantTable
                v-if="campaign?.campaign_type === CampaignTypeBackend.SURVEY && participants"
                :headers="headers"
                :table-data="participants"
                :is-trashed="isTrashed"
                :anonymous="isAnonymous"
                :pagination="pagination"
                :deadline="campaign?.deadline"
                :campaign-id="campaign?.id"
                :campaign="campaign"
                :overview="overview"
                :campaign-overview="campaignOverview"
                :set="set"
                @fetch-page="surveyFetchPage"
                @fetch-from-campaign-show-search="fetchCurrentCampaign"
              />

              <CampaignParticipants
                v-else
                :set="set"
                :campaign-overview="campaignOverview"
                :overview="overview"
                :is-trashed="isTrashed"
                @fetch-from-campaign-show-search="fetchCurrentCampaign"
              />
            </div>
            <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
              <svn-loader loading-size="lg" />
            </div>
          </v-window-item>

          <v-window-item
            v-if="campaign?.campaign_type === 'Survey'"
            :key="3"
            :value="3"
          >
            <BktSurveyAnswers
              v-if="campaign"
              class="w-full"
              :is-trashed="isTrashed"
              :campaign="campaign"
            />
          </v-window-item>
        </v-window>

        <v-window
          v-else
        >
          <v-window-item
            :key="1"
          >
          <div class="flex justify-center h-[30vh] items-center w-full">
              <svn-loader loading-size="lg" />
            </div>
          </v-window-item>
        </v-window>
      </div>
    </template>
  </bkt-index-skeleton>
</template>

<script setup>
  import { Icon } from "@iconify/vue";
  import {onMounted, onUnmounted, ref, watch} from "vue";
  import BktIndexSkeleton from "../../../components/BktIndexSkeleton.vue";
  import { storeToRefs } from "pinia";
  import { useCampaignStore } from "@/store/campaign.js";
  import { useCampaignTrashStore } from "@/store/campaign-trash.js";
  import CampaignStatusChip from "../../../components/campaign/CampaignStatusChip.vue";
  import { useRoute, useRouter } from "vue-router";
  import CampaignParticipants from "@/components/campaign/CampaignParticipants.vue";
  import CampaignOverview from "@/components/campaign/CampaignOverview.vue";
  import {CampaignTypeBackend} from "@/constants/CampaignTypeEnum.js";
  import BktSurveyAnswers from "@/components/BktSurveyAnswers.vue"
  import BktSurveyParticipantTable from "@/components/surveyApp/BktTableSurveyParticipants.vue";
  import {useI18n} from "vue-i18n";
  import { useUserStore } from "@/store/user";
  import BktSlackBtn from "@/components/button/BktSlackBtn.vue";
  import {useSnackbar} from "@/store/snackbar.js";
  import axiosService from "@/tools/axios-service.js";
  import {usePopUpStore} from "@/store/pop-up.js";
  import axios from "@/tools/axios-service.js";
  import {debounce} from "lodash";
  import i18n from "@/plugins/i18n.js";
  import BktBreadcrumbs from "@/components/BktBreadcrumbs.vue";
  import TrashWarnning from "@/components/TrashWarnning.vue"
  import {useMobileStore} from "@/store/mobile.js";

  // Props
  const props = defineProps({
    campaignId: { type: String, default: null },
    overview: { type: String, default: null },
    currentUserId: { type: String, default: null },
  });

  // Traduction
  const { t } = useI18n()
  const {isMobile} = storeToRefs(useMobileStore())
  const { isAdminOrAbove } = useUserStore()
  const isTrashed = ref(false);
  // Const
  const headers = [
    {title: t("Participant"), align: "start", key: "participant", width: "33%", class: "bg-red"},
    {title: t("Job Title"), align: "start", key: "job_title", width: "33%"},
    {title: t("Completion"), align: "start", key: "completion", width: "33%"},
    {title: t(""), align: "end", key: "action", width: "1%", sortable: false},
  ]

  // Refs
  const tab = ref(0);
  const isAnonymous = ref(true)
  const participants = ref(null)
  const { accessLevel } = storeToRefs(useUserStore());
  const { isManagerCreatorOrAbove } = useUserStore();

  const { campaign, campaignOverview, completionAll, set, pagination, error: errorEntity } = storeToRefs(useCampaignStore());
  const { destroyPermanentlyCampaign, restoreCampaign } = useCampaignTrashStore();
  const { fetchCompletionAll } = useCampaignStore();

  // Functions
  const {
    fetchCampaign,
    updateParams,
    resetStates,
    fetchCampaignInterview,
    fetchCampaignFeedback,
    fetchCampaignSurvey,
    updateCampaign
  } = useCampaignStore();

  const {openPopUp, closePopUp} = usePopUpStore()
  const snackbar = useSnackbar();
  const router = useRouter();
  const route = useRoute();

  const surveyFetchPage = (page) => {
    updateParams({
      "page[number]": page,
    });
    fetchCurrentCampaign();
  }

  const goToCampaigns = () => {
    router.push({ name: "campaigns" })
  }

  const fetchCurrentCampaign = async () => {
    switch (campaign?.value?.campaign_type) {
      case CampaignTypeBackend.ONE_TO_ONE:
        await fetchCampaignInterview(props.campaignId || route.params.id)
        return
      case CampaignTypeBackend.FEEDBACK_360:
        await fetchCampaignFeedback(props.campaignId || route.params.id)
        return
      case CampaignTypeBackend.SURVEY:
        await fetchCampaignSurvey(props.campaignId || route.params.id)
        return
      default:
        return
    }
  }

  // Execute
  watch(set, (_) => {
    if (campaign.value?.campaign_type !== CampaignTypeBackend.SURVEY) return

    participants.value = set.value?.map((int) => {
      return {
        id: int.id,
        fullname: int.employee.fullname,
        firstname: int.employee.firstname,
        lastname: int.employee.lastname,
        avatar: int.employee.avatar,
        employeeId: int.employee.id,
        job_title: int.employee.job_title,
        tags: int.employee?.tags?.map((tag) => {
          return {
            tag_name: tag.name
          }
        }) || [],
        completion: int.status,
      }
    })
  })

  watch(campaign, (newVal, _) => {
    if (newVal.campaign_type !== CampaignTypeBackend.SURVEY)
      return
    isAnonymous.value = newVal?.anonymize_answers
  })


  watch(tab, (newVal, _) => {
    const id = campaign.value?.id

    router.push({
      name: 'campaign_overview',
      params: { id },
      query: { tab: newVal }
    })
  })

  onMounted( async() => {
    tab.value = parseInt(route?.query?.tab) || 1

    updateParams({
      "page[number]": 1,
    });
    await fetchCampaign(props.campaignId || route.params.id);
    await fetchCurrentCampaign()
    await fetchCompletionAll(props.campaignId || route.params.id)
    isTrashed.value = campaign.value.campaign_status === 'trashed'
  })

  onUnmounted(() => {
    resetStates()
  })

  const saveSlackAccessToken = debounce(async (e) => {
    const accessToken = e.detail

    const ret = await updateCampaign(props.campaignId || route.params.id, {
      slack_access_token: accessToken
    })

    if (!ret) {
      snackbar.setStatus("error").displaySnackBar(errorEntity.value?.message);
    }
  }, 200);

  const joinChannel = async (channelId, channelName) => {
    try {
      const res = await axiosService.post(
        `/api/v2/interview_app/campaigns/${campaign.value.id}/slack_join_channel`,
        {
          slack_channel_id: channelId
        }
      )

      if (campaign.value?.slack_channel_id && campaign.value?.slack_channel_id !== channelId) {
        snackbar.setStatus("updated").displaySnackBar(t('Slack notifications have been redirected to the channel', { channelName: channelName }));
      }
      campaign.value = res.data
    } catch (e) {
      snackbar.setStatus("error").displaySnackBar("Couldn't join the channel");
    }
  }

  const slackLeaveChannel = async (_) => {
    openPopUp({
      componentName: "PopUpLeaveChannelSlack",
      confirm: async () => {
        const ret = await updateCampaign(props.campaignId || route.params.id, {
          slack_channel_id: null
        })

        if (!ret) {
          snackbar.setStatus("error").displaySnackBar(errorEntity.value?.message);
        }
        closePopUp()
      },
      close() {},
    });
  }

  const openEditDeadline = (deadline) => {
    const cId = props.campaignId || route.params.id;
    openPopUp({
      componentName: "pop-up-campaign-edit-deadline",
      title: 'Edit deadline',
      campaignId: cId,
      icon: '',
      deadline: deadline,
      customClass: "",
      showClose: true,
      close() {
        fetchCampaign(cId);
      },
    });
  };

  const deleteCampaign= async (id) => {
    openPopUp({
      componentName: "pop-up-validation",
      title: i18n.global.t("Are you sure you want to delete this campaign ?"),
      subtitle: i18n.global.t("This is a permanent action."),
      textClose: "No, cancel",
      textConfirm: "Yes, delete this campaign",
      textLoading: "Deleting ...",
      icon: "mdi-trash-can-outline",
      color: "red",
      customClass: "w-[500px]",
      showClose: false,
      async confirm() {
        try {
          await axios.delete(`/api/v2/interview_app/campaigns/${id}.json`);
        } catch (e) {
          console.log("Fail to delete campaign", e)
        } finally {
          closePopUp();
          await router.push({name: 'campaigns'})
        }
      },
    });
  };

  const openManageCopilotsPopUp = () => {
    openPopUp({
      componentName: "pop-up-manage-copilots",
      title: i18n.global.t('Manage co-pilots'),
      subtitle: '',
      icon: '',
      campaignId: route?.params?.id,
      customClass: "h-[573px] lg:w-[800px] !justify-normal",
    });
  }

  const restoreDeletedItem = async () => {
    try {
      await restoreCampaign(campaign.value.id)
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Campaign restored successfully')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
      isTrashed.value = false
    } catch (e) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error while restoring a campaign')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
    }
  }

  const deleteCampaignPermanently = async () => {
    try {
      await destroyPermanentlyCampaign(campaign.value.id)
      snackbar.setBgColor('fakeBlack')
      snackbar.setMsg('Campaign has been permanently deleted')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
      await router.push({ name: 'campaigns' });
    } catch (e) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error while deleting permanently a campaign')
      snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
      snackbar.displaySnackBar()
    }
  }
</script>

 <style scoped>
  :deep(.v-slide-group__container) {
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow: visible;
    contain: none;
  }

</style>
