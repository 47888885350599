<template>
  <apps-header
    :app-icon="imageIcon"
    :app-title="$t('Learn')"
    :links="getLinks"
  >

    <router-view />
  </apps-header>
</template>

<script setup>
import { computed } from "vue";
import { useTheme } from "vuetify";
import AppsHeader from "../components/header/AppsHeader.vue";
import { useUserStore } from "@/store/user.js";
import imageIcon from "@/assets/images/icons/trainings.svg"

const { isManagerOrAbove, isManagerCreatorOrAbove, isAdminOrAbove } = useUserStore();
const theme = useTheme();
theme.global.name.value = "learn";

const links = [
  { title: "My Learnings", linkName: "my_learnings" },
  { title: "Catalog", linkName: "catalog" },
  { title: "Trainings", linkName: "trainings" },
];

const getLinks = computed(() => {
  return links.filter((link) => {
    if (link.linkName === "trainings" && !isManagerCreatorOrAbove()) {
      return false;
    }
    return true
  });
});

</script>