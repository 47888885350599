<template>
  <v-card
    class="w-full flex flex-col items-start justify-center p-4 drop-shadow
      sm:!p-6 md:rounded-xl"
  >
    <v-card-title
      class="flex flex-col items-center w-full p-0 mb-4
        md:flex-row md:justify-start md:mb-0"
    >
      <MyInterviewCardHeader
        :campaign-deadline="campaign.deadline"
        :campaign-type="campaign.campaign_type"
        :campaign-name="campaign.title"
      />
    </v-card-title>
    <v-card-text
      class="w-full flex flex-col justify-start items-center p-0
        md:flex-row md:justify-start md:items-center md:!pt-6"
    >
      <MyInterviewCardPeople
        :status="status"
        :person="interviewee"
        type="participant"
        class="w-full"
      />
    </v-card-text>
    <v-card-actions
      class="w-full flex flex-col justify-center space-x-0 space-y-4
        md:!justify-end md:flex-row md:space-y-0 md:space-x-4 p-0"
    >
      <v-btn
        v-if="status !== 'submitted'"
        class="px-4 normal-case w-full text-xs
          md:w-fit md:text-sm"
        variant="outlined"
        color="error"
        data-testid="doNotParticipate"
        @click="doNotParticipate"
      >
        {{ $t('Do not participate') }}
      </v-btn>
      <v-btn
        data-testid="answer"
        class="px-4 normal-case w-full text-xs
          md:w-fit md:text-sm"
        :variant="buttonVariant()"
        color="primary"
        :append-icon="status === 'submitted' ? '' : 'mdi-arrow-right'"
        @click="clickInterview"
      >
        {{ $t(buttonSentence()) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
  import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
  import MyInterviewCardPeople from "@/components/interviewApp/InterviewCardPeople.vue";
  import {useMobileStore} from "@/store/mobile.js";
  import {storeToRefs} from "pinia";
  import {usePopUpStore} from "@/store/pop-up.js";
  import PopUpDoNotParticipateSurvey from "@/components/popUpComponents/PopUpDoNotParticipateSurvey.vue";
  import router from "@/router/index.js";

  // Props
  const props = defineProps({
    id: {type: Number, required: true},
    status: {type: String, required: true},
    campaign: {type: Object, required: true},
    interviewee: {type: Object, required: true},
  })

  // Refs
  const { isMobile } = storeToRefs(useMobileStore())

  // Functions
  const {openPopUp} = usePopUpStore()

  const buttonSentence = () => {
    switch (props.status) {
      case "submitted":
        return "View my answers"
      case "in_progress":
        return "Continue my survey"
      case "not_started":
        return "Start survey"
      default:
        return ""
    }
  }

  const buttonVariant = () => {
    switch (props.status) {
      case "submitted":
        return "outlined"
      case "in_progress":
        return "flat"
      case "not_started":
        return "flat"
      default:
        return "plain"
    }
  }

  const doNotParticipate = () => {
    openPopUp({
      componentName: "PopUpDoNotParticipateSurvey",
      id: props.id,
      close() {},
    })
  }

  const clickInterview = () => {
    router.push({name:"interview_answer", params: { id: props.id }})
  }

</script>