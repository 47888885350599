<template>
  <bkt-index-skeleton body-class="bkt-bg-white rounded mt-20 mb-32">
    <template #title>
      <p class="mb-5 font-medium">
        {{ $t("Templates") }}
      </p>
    </template>

    <template #create-index>
      <v-dialog
        v-model="dialog"
        max-width="900"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="primary"
            prepend-icon="mdi-plus"
          >
            {{ $t("Create template") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <div class="flex flex-column py-4">
              <div class="flex flex-row w-full">
                <p class="grow font-medium">
                  {{ $t("What type of template you want to create ?") }}
                </p>
                <v-icon
                  class="flex-none"
                  icon="mdi-close"
                  @click="dialog = false"
                />
              </div>

              <div class="grid grid-cols-2 gap-8 mt-6">
                <bkt-radio-text-icon-button
                  title="One to one"
                  description="Question between an interviewer and interviewee."
                  :condition="campaign_type === CampaignTypeEnum.ONE_TO_ONE"
                  :on-click="() => { campaign_type = CampaignTypeEnum.ONE_TO_ONE }"
                  icon="twemoji:handshake"
                />
                <bkt-radio-text-icon-button
                  title="Feedback 360"
                  description="Collect feedbacks from employees reviewers."
                  :condition="campaign_type === CampaignTypeEnum.FEEDBACK_360"
                  :on-click="() => { campaign_type = CampaignTypeEnum.FEEDBACK_360 }"
                  icon="noto:megaphone"
                  :is-beta="true"
                />
                <bkt-radio-text-icon-button
                  title="Survey"
                  description="Collect data to take the temperature of employees."
                  :condition="campaign_type === CampaignTypeEnum.SURVEY"
                  :on-click="() => { campaign_type = CampaignTypeEnum.SURVEY }"
                  icon="noto:bar-chart"
                  :is-beta="true"
                />
              </div>
              <div class="flex flex-row w-full mt-8 mb-4">
                <span class="grow font-medium">
                  {{ $t('Who will answer this template?') }}
                </span>
              </div>
              <div
                v-if="campaign_type === CampaignTypeEnum.ONE_TO_ONE"
                class="flex w-full flex-col"
              >
                <v-select
                  v-model="oneToOneSelectedItem"
                  :items="oneToOneTemplateItems"
                  item-text="title"
                  item-value="value"
                  :hint="oneToOneTemplateItems.filter(item => item.value === oneToOneSelectedItem)[0]?.desc"
                  persistent-hint
                  class="mb-2"
                >
                  <template
                    #selection="{item}"
                  >
                    <div class="w-full truncate">
                      {{ item.title }}
                    </div>
                  </template>
                </v-select>
                <div class="flex w-full py-4">
                  <v-btn
                    class="bg-sky-600 text-white mx-auto"
                    @click="createInterviewForm(oneToOneSelectedItem)"
                  >
                    {{ $t('Confirm') }}
                  </v-btn>
                </div>
              </div>
              <div
                v-if="campaign_type === CampaignTypeEnum.FEEDBACK_360"
                class="flex w-full flex-col"
              >
                <v-select
                  v-model="feedbackSelectedItem"
                  :items="feedbackTemplateItems"
                  item-text="title"
                  item-value="value"
                  class="w-full pb-2"
                  disabled
                >
                  <template
                    #selection="{item}"
                  >
                    <div class="w-full truncate">
                      {{ item.title }}
                    </div>
                  </template>
                </v-select>
                <div class="flex w-full py-4">
                  <v-btn
                    class="bg-sky-600 text-white mx-auto"
                    @click="createInterviewForm(feedbackSelectedItem)"
                  >
                    {{ $t('Confirm') }}
                  </v-btn>
                </div>
              </div>

              <div
                v-if="campaign_type === CampaignTypeEnum.SURVEY"
                class="flex w-full flex-col"
              >
                <v-select
                  v-model="surveySelectedItem"
                  :items="surveyTemplateItems"
                  item-text="title"
                  item-value="value"
                  class="w-full pb-2"
                  disabled
                >
                  <template
                    #selection="{item}"
                  >
                    <div class="w-full truncate">
                      {{ item.title }}
                    </div>
                  </template>
                </v-select>
                <div class="flex w-full py-4">
                  <v-btn
                    class="bg-sky-600 text-white mx-auto"
                    @click="createInterviewForm(surveySelectedItem)"
                  >
                    {{ $t('Confirm') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <template #body>
      <div
        class="w-full flex gap-2 items-center justify-center xl:!gap-4"
      >
        <!-- Searchbar -->
        <template-search
          @fetch="reloadAfterSearch"
        />

        <!-- Settings on all: icon mdi-dots-vertical -->
        <div
          v-if="isManagerCreatorOrAbove()"
          class="hidden sm:flex sm:h-12 items-center"
        >
          <bkt-learn-list-settings
            :content-values="settingsOnAllItems"
          />
        </div>
      </div>

      <group-category-filter
        @fetch="updateInterviewFormsFromGroupCategoryFilter"
      />

      <interview-template-table
        v-if="interviewForms && interviewForms.length > 0"
      />

      <bkt-no-entity-from-index
        v-else-if="
          (interviewForms && interviewForms.length === 0 && search) ||
            (interviewForms && interviewForms.length === 0 && currentTags)
        "
        :search="search ? search['title'] : $t('look at the tags you selected')"
      />

      <div v-if="!interviewForms" class="flex justify-center h-[30vh] items-center w-full">
        <svn-loader loading-size="lg" />
      </div>

      <bkt-create-entity-from-index
        v-if="
          interviewForms &&
            interviewForms.length === 0 &&
            !search &&
            !currentTags
        "
        @click="dialog = true"
      >
        {{ $t("template") }}
      </bkt-create-entity-from-index>
    </template>
  </bkt-index-skeleton>

  <!-- Modal Deleted Items to CUSTOMIZE -->
  <modal-view-deleted-items
    ref="deletedItems"
    content-type="template"
    :contents="trashInterviewForms"
    @toggle-search="toggleSearchDeletedItems"
    @restore="restoreDeletedItems"
    @delete-permanently="deletePermanentlyDeletedItems"
    @view-item="viewDeletedItem"
  />
</template>

<script setup>
import axiosService from "@/tools/axios-service.js";
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import { useInterviewFormStore } from "@/store/interview-form.js";
import GroupCategoryFilter from "@/components/GroupCategoryFilter.vue";
import InterviewTemplateTable from "@/components/interviewApp/template/table.vue";
import { storeToRefs } from "pinia";
import TemplateSearch from "./TemplateSearch.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import { inject, ref, onMounted } from "vue";
import BktNoEntityFromIndex from "@/components/BktNoEntityFromIndex.vue";
import { useRouter } from "vue-router";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum.js";
import BktRadioTextIconButton from "@/components/button/BktRadioTextIconButton.vue";
import {useI18n} from "vue-i18n";
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from '@/plugins/i18n';
import {useMobileStore} from "@/store/mobile.js";
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user.js";
import {debounce} from "lodash";
import { useBreadcrumbsStore } from '@/store/breadcrumbs';

const breadcrumbsStore = useBreadcrumbsStore();
const { addNode, update } = breadcrumbsStore

const { isManagerCreatorOrAbove } = useUserStore();

const router = useRouter();

const currentTags = ref(null);

const { interviewForms, search, trashInterviewForms } = storeToRefs(useInterviewFormStore());

const { fetchInterviewForms, updateParams, setInterviewForms, fetchTrashInterviewForms, restoreInterviewForm, deleteInterviewFormPermanently } =
 useInterviewFormStore();

const snackbar = useSnackbar()

const mobileStore = useMobileStore()

const {isMobile} = storeToRefs(mobileStore)

const dialog = ref(false);

const { t } = useI18n();

const deletedItems = ref(null)

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted templates'),
    onClick: openDeletedItems
  }
])

onMounted(async() => {
  try {
    await fetchTrashInterviewForms()
    await fetchInterviewForms();
  } catch (error) {
    console.log(error)
  }
});

const restoreDeletedItems = async (id) => {
  try {
    await restoreInterviewForm(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Interview form restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteInterviewFormPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Interview form deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const viewDeletedItem = async (id) => {
  try {
    goToInterviewForm("Interview form edit", id)
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while showing deleted template')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const goToInterviewForm = (title, interviewFormId) => {
  addNode(title, `interviews/templates/${interviewFormId}/edit`)
  update()
  router.push({ name: 'interviews_template_edit', params: { id: interviewFormId } })
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashInterviewForms(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const reloadAfterSearch = (params) => {
  setInterviewForms(null);
  updateParams({
    title: params.title,
    "page[number]": 1,
  });
  fetchInterviewForms();
}

const campaign_type = ref(CampaignTypeEnum.ONE_TO_ONE);

const oneToOneTemplateItems = [
  {
    title: t("Interviewee Only"),
    desc: t("Only the interviewee will answer this template. The interviewer can still view the answers."),
    value: "employee",
  },
  {
    title: t("Interviewer Only"),
    desc: t("Only the interviewer will answer this template. The interviewee can still view the answers."),
    value: "manager",
  },
  {
    title: t("Both (Interviewee + Interviewer)"),
    desc: t("Both can answer this template. Only the interviewer can view the interviewee's answers."),
    value: "both",
  },
  {
    title: t("Cross Review (Both can answer + a conclusion step for final answers)"),
    desc: t("In addition of the interviewee + interviewer template, the Cross Review is a last step where both can agreed on the interview final version."),
    value: "cross",
  }
]

const feedbackTemplateItems = [
  {
    title: t("Reviewers"),
    desc: "",
    value: "pairs",
  }
]

const surveyTemplateItems = [
  {
    title: t("Survey participants"),
    desc: "",
    value: "survey",
  }
]

const oneToOneSelectedItem = ref(oneToOneTemplateItems[0].value);

const feedbackSelectedItem = ref(feedbackTemplateItems[0].value);

const surveySelectedItem = ref(surveyTemplateItems[0].value);


const createInterviewForm = async (templateType) => {
  try {
    const res = await axiosService.post("/api/v2/interview_app/forms/templates/", {
      title: t("New template"),
      answerable_by: templateType,
    });

    await router.push({
      name: "interviews_template_edit",
      params: { id: res.data.template_id },
    });
  } catch (e) {
    console.log("error", e);
  }
};

const updateInterviewFormsFromGroupCategoryFilter = (e) => {
  currentTags.value = e;

  updateParams({ tags: e });
  fetchInterviewForms();
};
</script>
