export default function () {
  return {
    anyNullInArray(arr) {
      return arr.some((item) => item == null || item === undefined || item === '');
    },
    contactNullStr(arr) {
      let str = "";

      if (!arr) return null;

      for (let i = 0; i < arr.length; i++) {
        if (!arr[i][0]) {
          str += arr[i][1];
        }
      }
      return str;
    },
    arraysAreEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
  };
}
