<template>
  <v-layout
    full-height
    style="position: fixed; width: 100%; height: calc(100% - 75px); z-index: 0"
  >
    <v-app-bar
      color="surface"
      elevation="4"
      prominent
    >
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      />

      <v-toolbar-title>
        <div class="flex w-full">
          <p
            v-if="campaign"
            class="text-center grow"
          >
            {{ campaign.title }}
          </p>
        </div>
      </v-toolbar-title>
      <bkt-download-button
        title="download pdf"
        loading-text="Downloading PDF..."
        :loading="downloadLoading"
        @on-click="clickDownload"
      />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      width="400"
      disable-resize-watcher
      class="!h-auto"
    >
      <div class="w-full break-words">
        <div
          class="flex justify-center items-center gap-3 w-full text-center font-semibold text-gray-300 text-2xl p-5"
        >
          {{ $t("Chapters") }}
          <svn-tooltip
            location="bottom start"
            origin="top end"
          >
            <template #activator-content>
              <Icon
                icon="mdi:information-outline"
                class="text-gray-300 h-5 w-5"
                @mouseover="setTemplateTitle"
              />
            </template>

            <template #tooltip-content>
              {{ $t("Template used") }}: {{ template_title ? template_title : $t('No template used') }}
            </template>
          </svn-tooltip>
        </div>

        <div
          v-for="(item, i) in headlines"
          :key="i"
          class="w-full"
          :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
        >
          <div
            v-if="
              interviewKind === 'crossed' ||
                item.visible_for === interviewKind ||
                item.visible_for === 'all' && item.type !== InterviewQuestionType.PARAGRAPH
            "
          >
            <div
              v-if="item.type === InterviewQuestionType.CHAPTER"
              class="uppercase font-semibold break-words px-10 pt-6 pb-1.5"
            >
              {{ item.separatorpos }}. {{ item.title }}
            </div>

            <v-btn
              v-else-if="item.type === 'roadmap'"
              class="flex justify-start bg-transparent w-full shadow-none rounded-none break-words
               active:bg-blue-100
               focus:shadow-none
               hover:shadow-none"
              :value="item"
              @click="scrollQuestions(item.id)"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  class="mr-1 bkt-blue inline-block align-middle"
                  size="1em"
                />
                <span class="first-letter:uppercase normal-case truncate text-start">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>

            <v-btn
              v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
              :value="item"
              class="flex justify-start rounded-none break-words w-full shadow-none bg-transparent
                active:bg-blue-100
                focus:shadow-none
                hover:shadow-none"
              @click="scrollQuestions(item.id)"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  class="mr-1 bkt-blue inline-block align-middle"
                  size="1em"
                />

                <span class="first-letter:uppercase normal-case truncate text-start">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <v-main class="overflow-y-auto">
      <div class="flex justify-center bg-neutral-100">
        <div class="w-4/6 flex justify-center mb-[30vh]">
          <feedback-answer-list />
        </div>
      </div>
    </v-main>
  </v-layout>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import useTools from "@/tools/useTools.js";
import { useFeedbackStore } from "@/store/feedback.js";
import FeedbackAnswerList from "@/components/interviewApp/feedback_answers/FeedbackAnswerList.vue";
import { useSnackbar } from "@/store/snackbar.js";
import BktDownloadButton from "@/components/button/BktDownloadButton.vue";
import { downloadPdf } from "@/tools/pdf-proxy-service.js";
import {useMobileStore} from "@/store/mobile.js";
import {Icon} from "@iconify/vue";
import { InterviewQuestionType } from '@/constants/types';


const snackbar = useSnackbar();
snackbar.checkStatus();
const {isLargeScreen} = storeToRefs(useMobileStore())

const route = useRoute();

const { deduceMatIconFromInterviewQuestionType } = useTools();
const { fetchFeedback, clearFeedback } = useFeedbackStore();
const { title, campaign, questions, participant } = storeToRefs(useFeedbackStore());

const drawer = ref(isLargeScreen);
const dialog = ref(false);
const template_title = ref("");
const downloadLoading = ref(false);

const headlines = computed(() => {
  if (!questions.value) return [];

  let separator_position = 1;
  return questions.value.map((question) => ({
    id: question.id,
    title: question.title,
    type: question.type,
    separatorpos:
      question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP
            ? separator_position++
            : null,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    visible_for: question.visible_for,
  }));
});

const clickDownload = () => {
  downloadLoading.value = true;
  downloadPdf(`/pdf/feedback_answer/${route.params.id}`, `${participant.value?.fullname}_${campaign?.value?.title}_${campaign?.value?.campaign_type}`.toUpperCase())
  downloadLoading.value = false;
};

const setTemplateTitle = () => {
  template_title.value = title
};
clearFeedback();
fetchFeedback(route.params.id);
</script>

<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}

.v-btn :deep(.v-btn__content) {
  width: 100%;
}
</style>
