<template>
  <pop-up-skeleton>
    <template #title>
      <p class="text-fakeBlack text-xl font-medium">
        {{ $t('Answers will be locked') }}
      </p>
    </template>

    <template #subtitle>
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('Submit this interview will lock your answers. Ask to an admin to unlock it if needed.') }}
      </p>
    </template>

    <template #buttons>
      <div
        class="w-full flex flex-col justify-center sm:flex-row gap-x-3 px-1.5"
      >
        <v-btn
          variant="outlined"
          color="black"
          class="text-fakeBlack border-darkGrey border-1 normal-case font-normal w-full sm:w-1/2 text-xs mb-3 sm:!mb-0"
          @click="closePopUp"
        >
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn
          variant="outlined"
          class="text-white bg-primary normal-case font-normal w-full sm:w-1/2 text-xs"
          @click="lockInterview"
        >
          {{ $t('Confirm') }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from "@/store/pop-up.js";
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import axiosService from "@/tools/axios-service.js";
import { useInterviewStore } from "@/store/interview.js";
import { useUserStore } from "@/store/user.js";

const { id: currentUserId } = storeToRefs(useUserStore());
const snackbar = useSnackbar();

const { clearInterview, lock } = useInterviewStore();

const router = useRouter();

const { closePopUp } = usePopUpStore();

const { interviewKind, id } = storeToRefs(useInterviewStore());

const updateSuggestions = async() => {
  var storedObject = localStorage.getItem('suggestions');

  const suggestions = JSON.parse(storedObject)?.filter((suggestion) =>
    suggestion.interview_id == id.value &&
    suggestion.user_id == currentUserId.value
  )

  for (const suggestion of suggestions) {
    try {
      await axiosService.patch(
        `/api/v1/roadmaps/indicators/${suggestion.indicator_id}`,
        {
          current_value: suggestion.current_value,
          comment: suggestion.comment,
          interview_id: suggestion.interview_id
        }
      );
    } catch (error) {
      snackbar.setBgColor("negativeRed")
      snackbar.displaySnackBar("Could not update suggestion ! Please try again.");
    }
  }
}

const updateLocalStorageSuggestions = () => {
  var storedObject = localStorage.getItem('suggestions');
  const parsedObject = storedObject && storedObject !== undefined ? JSON.parse(storedObject) : [];

  const otherSuggestions = parsedObject?.filter((suggestion) => !(
    suggestion.interview_id == id.value &&
    suggestion.user_id == currentUserId.value
    )
  );

  const objectString = JSON.stringify(otherSuggestions);
  localStorage.setItem('suggestions', objectString);
}

const lockInterview = async () => {
  try {
    await updateSuggestions()
  } catch (error) {
    snackbar.setBgColor("negativeRed")
    snackbar.displaySnackBar("Your suggestion could not be created ! Please try again.");
  }

  try {
    await lock();
    updateLocalStorageSuggestions()
    snackbar.setBgColor("success")
    snackbar.displaySnackBar("Your interview has been submitted, your answers are locked.");
  } catch (error) {
    snackbar.setBgColor("negativeRed")
    snackbar.displaySnackBar("Your interview could not be submitted ! Please try again.");
  }

  if (interviewKind.value === "employee" || interviewKind.value === 'feedback')
    await router.push({ name: "my_interviews" });
  else if (interviewKind.value === "manager")
    await router.push({ name: "my_team_interviews" });
  else if (interviewKind.value === "pairs")
    await router.push({ name: "my_interviews" });
  else if (interviewKind.value === "survey")
    await router.push({name: "my_interviews"})
  else if (interviewKind.value === "crossed")
    await router.push({ name: "my_team_interviews" });
  else await router.push({ name: "home" });

  clearInterview();
  closePopUp();
};
</script>
