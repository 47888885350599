<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div>
        <h4 class="text-base font-medium">
          {{ $t("Multiple templates - Category") }}
        </h4>
        <p class="text-dark-grey text-sm">
          {{
            $t(
              "Choose a category to assign different templates to this campaign."
            )
          }}
        </p>
      </div>
      <v-select
        v-model="campaignDraft.multi_templates_tag_category_id"
        :items="tagCategories"
        :label="$t('Select a category')"
        item-title="name"
        variant="outlined"
        base-color="middleGrey"
        color="primary"
        hide-details
        item-value="id"
        class="w-full"
        @update:model-value="lookPatsTags"
      >
        <template
          #selection="{item}"
        >
          <div class="w-full truncate">
            {{ item.title }}
          </div>
        </template>
      </v-select>
    </div>

    <div
      class="flex flex-col gap-4"
    >
      <div class="flex flex-col">
        <h4 class="text-base font-medium">
          {{ $t("Multiple templates - Assign templates") }}
        </h4>
        <p class="text-dark-grey text-sm">
          {{
            $t(
              "Select a template for each tag. You also have to choose a default template for participants not assigned to either of these tags."
            )
          }}
        </p>
      </div>

      <div
        v-if="campaignDraft.multi_templates_tag_category_id"
        class="flex flex-col gap-3"
      >
        <div
          v-for="(tag, idx) in participantTags"
          :key="idx"
          class="flex flex-column sm:!flex-row justify-between items-center"
        >
          <div class="flex items-center">
            <div class="bg-black h-2 w-2 rounded-full mx-3" />
            <p class="semibold">
              {{ tag.tag_name }}
            </p>
          </div>

          <svn-autocomplete
            :model-value="fetchInterviewFormId(tag.id)"
            :label="$t('Select a template')"
            :items="interviewForms"
            item-title="title"
            :loading="loadingInterviewForms"
            class="text-black !max-w-[280px]"
            color="primary"
            prepend-inner-icon=""
            @update:model-value="
              updateMultiTemplatesTagsIds({
                tag_id: tag.id,
                form_id: $event,
              })
            "
          >
            <template #main-content>
              {{ null }}
            </template>

            <template #prepend-details>
              {{ null }}
            </template>

            <template #append-content>
              {{ null }}
            </template>
          </svn-autocomplete>

        </div>
        <div
          class="flex flex-column sm:!flex-row justify-between items-center my-3"
        >
          <div class="flex items-center">
            <div class="bg-black h-2 w-2 rounded-full mx-3" />
            <p class="semibold">
              {{ $t('Default template') }}
            </p>
          </div>
          <templates-single
            class-custom="!max-w-[280px]"
          />
        </div>
      </div>
      <v-alert
        v-else
        :text="$t('Please choose a category first')"
        icon="mdi-alert"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { useCompanyStore } from "@/store/company.js";
import { onMounted, ref } from "vue";
import TemplatesSingle from "@/components/interviewApp/campaignDraft/templates/TemplatesSingle.vue";

const { fetchInterviewForms } = useCompanyStore();
const { fetchParticipantTags } = useCampaignDraftStore();

const { tagCategories, interviewForms } = storeToRefs(useCompanyStore());
const { campaignDraft, participantTags } = storeToRefs(useCampaignDraftStore());

const loadingInterviewForms = ref(true);

onMounted(async () => {
  if (!interviewForms.value.length) {
    let formType = 'InterviewApp::Forms::Templates::OneToOne'

    if (campaignDraft.value.campaign_type == 'Survye') {
      formType = 'InterviewApp::Forms::Templates::Survey'
    } else if (campaignDraft.value.campaign_type == 'Feedback') {
      formType = 'InterviewApp::Forms::Templates::Feedback'
    }
    await fetchInterviewForms(formType);
    loadingInterviewForms.value = false;
  } else {
    loadingInterviewForms.value = false;
  }
});

const updateMultiTemplatesTagsIds = (entry) => {
  const idx = campaignDraft.value.multi_templates_tags_ids.findIndex(
    (item) => item.tag_id == entry.tag_id
  );

  if (idx !== -1) {
    campaignDraft.value.multi_templates_tags_ids[idx] = entry;
  } else {
    campaignDraft.value.multi_templates_tags_ids.push(entry);
  }
};

const fetchInterviewFormId = (tagId) => {
  const match = campaignDraft.value.multi_templates_tags_ids.find(
    (item) => item.tag_id === tagId
  );

  return match ? match.form_id : null;
};

const lookPatsTags = (arg) => {
  campaignDraft.value.multi_templates_tags_ids = [];
  fetchParticipantTags(arg);
};
</script>
