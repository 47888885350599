<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <v-btn
        color="primary"
        variant="text"
        prepend-icon="mdi-star-half-full"
      >
        {{ $t('Rating') }}
      </v-btn>
    </template>
    <template #body>
      <v-text-field
        v-model="interviewQuestion.text"
        :label="$t('Open question name')"
        color="primary"
        :disabled="isDeleted"
        @update:model-value="updateQuestion(interviewQuestion.id, {text: $event})"
      />

      <div class="h-40 mb-16">
        <quill-editor
          v-model:content="interviewQuestion.description"
          content-type="html"
          :placeholder="$t('Description')"
          :enable="!isDeleted"
          @update:content="updateQuestion(interviewQuestion.id, {description: $event})"
        />
      </div>


      <div class="flex justify-between">
        <div class="flex items-center">
          <v-select
            v-model="maxRating"
            class="w-40"
            :label="$t('Max rating')"
            :items="[1,2,3,4,5,6,7,8,9,10]"
            variant="solo"
            :disabled="isDeleted"
            @update:model-value="updateQuestion(interviewQuestion.id, {options: $event })"
          >
            <template
              #selection="{item}"
            >
              <div class="w-full truncate">
                {{ item.title }}
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <v-checkbox
            v-model="interviewQuestion.allow_comments"
            :label="$t('Allow comments')"
            color="primary"
            hide-details
            :disabled="isDeleted"
            @update:model-value="updateQuestion(interviewQuestion.id, {allow_comments: $event})"
          />
        </div>
      </div>

      <template-question-visible-required-for
        v-model:visible-for="visibleFor"
        v-model:required-for="requiredFor"
        :is-deleted="isDeleted"
        @update:visible-for-formated="updateQuestion(interviewQuestion.id, {visible_for: $event})"
        @update:required-for-formated="updateQuestion(interviewQuestion.id, {required_for: $event})"
      />
    </template>
  </template-header-question>
</template>

<script setup>
import {ref, watch} from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import TemplateQuestionVisibleRequiredFor
  from "@/components/interviewApp/template/Edit/TemplateQuestionVisibleRequiredFor.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import useTools from "@/tools/useTools.js";
import {QuillEditor} from "@vueup/vue-quill";

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const {updateQuestion } = useInterviewFormStore()
const tools = useTools()

const maxRating = ref(props.interviewQuestion.options)

const visibleFor = ref(tools.deduceForWhom(props.interviewQuestion.visible_for))
const requiredFor = ref(tools.deduceForWhom(props.interviewQuestion.required_for))


watch(requiredFor, (newVal, oldVal) => {
  for (const newValKey in newVal) {
    if (!visibleFor.value.includes(newVal[newValKey])) {
      visibleFor.value.push(newVal[newValKey])

      updateQuestion(props.interviewQuestion.id, {visible_for: tools.deduceToBackend(visibleFor.value)})
    }
  }
})

watch(visibleFor, (newVal, oldVal) => {
  for (const newValKey in requiredFor.value) {
    if (!newVal.includes(requiredFor.value[newValKey])) {
      const index = requiredFor.value.indexOf(requiredFor.value[newValKey])
      requiredFor.value.splice(index, 1)

      updateQuestion(props.interviewQuestion.id, {required_for: tools.deduceToBackend(requiredFor.value)})
    }
  }
})
</script>
