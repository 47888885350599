<template>
  <v-infinite-scroll
    class="p-1"
    :items="interviews"
    data-testid="container_interviews"
    @load="onLoad"
  >
    <div
      v-for="(interview, index) in interviews"
      :key="index"
      class="!mb-4"
    >
      <MyInterviewsOneToOneCard
        v-if="interview.campaign?.campaign_type === CampaignTypeBackend.ONE_TO_ONE"
        :id="interview.interview_id"
        :campaign="interview.campaign"
        :interview-set="interview.interview_set"
        :interviewee="interview.interviewee"
        :interviewer="interview.interviewer"
        :crossed-interview-id="interview.cross_interview_id"
      />
      <MyInterviewFeedbackCard
        v-else-if="interview.campaign.campaign_type === CampaignTypeBackend.FEEDBACK_360"
        :id="interview.interview_id"
        :feedback="interview"
        :campaign="interview.campaign"
        :can-auto-evaluation="interview?.can_self_evaluate || false"
        :can-manage-reviewers="interview?.can_choose_pairs || false"
        :can-consult-answers="interview?.can_view_pairs_answers || false"
      />
      <MyInterviewsSurveyCard
        v-else-if="interview.campaign.campaign_type === CampaignTypeBackend.SURVEY"
        :id="interview.interview_id"
        :campaign="interview.campaign"
        :interviewee="interview.interviewee"
        :status="interview.status"
      />
    </div>
  </v-infinite-scroll>
</template>

<script setup>
  import MyInterviewsOneToOneCard from "@/components/interviewApp/MyInterview/MyInterviewsOneToOneCard.vue";
  import MyInterviewFeedbackCard from "@/components/interviewApp/MyInterview/MyInterviewFeedbackCard.vue";
  import MyInterviewsSurveyCard from "@/components/interviewApp/MyInterview/MyInterviewSurveyCard.vue";
  import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";

  // Props
  const props = defineProps({
    interviews: { type: Array, required: true },
    totalPages: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  })

  const emits = defineEmits(["pagination"])

  const onLoad = async ({done}) => {
    if (props.interviews.length < 25) {
      done("empty")
    } else
      emits("pagination", done)
  }
</script>

<style scoped>

</style>