<template>
  <svn-dialog-skeleton
    ref="dialogRef"
    dialogHasTextArea
    :center-description="false"
    :title="$t('Change password')"
    :width="isMobile ? 300 : 400"
    :primary-button-text="$t('Save')"
    primary-button-class="!rounded !text-white !bg-fake-black hover:!bg-[#8c8c8c] active:!bg-[#101010]"
    :primary-button-close-on-click="false"
    :primary-button-loading="primaryButtonLoading"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #body>
      <v-form
        ref="form"
        v-model="valid"
        class="w-full flex flex-col gap-6"
        lazy-validation
      >
        <v-text-field
          v-model="newPassword"
          :rules="passwordRules"
          hide-details
          class="w-full"
          color="primary"
          base-color="middleGrey"
          :label="$t('New password')"
          type="password"
          variant="outlined"
          density="compact"
        />

        <v-text-field
          v-model="confirmPassword"
          :rules="confirmRules"
          hide-details
          class="w-full mt-2"
          color="primary"
          base-color="middleGrey"
          :label="$t('Confirm your new password')"
          type="password"
          variant="outlined"
          density="compact"
        />
      </v-form>
    </template>

    <template #primary-button>
      <svn-button
        :text="$t('Save')"
        class="w-[170px] !self-center"
        :loading="passwordLoading"
        @click="changePassword"
      />
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const emit = defineEmits(['change-password']);

const { isMobile } = storeToRefs(useMobileStore());

const form = ref();
const valid = ref(false);
const dialogRef = ref(null);
const newPassword = ref(null);
const passwordLoading = ref(false);
const confirmPassword = ref(null);
const primaryButtonLoading = ref(false);

const passwordRules = [
  (v) => !!v || "You must type a password !",
  (v) =>
    (v && v.length >= 6) ||
    "Your password have to be at least 8 characters long.",
];

const confirmRules = [
  (v) => !!v || "You must confirm your password !",
  (v) => (v && v === newPassword.value) || "Passwords aren't the same !",
];

const changePassword = async () => {
  passwordLoading.value = true;
  try {
    const { valid } = await form.value.validate();

    if (valid) {
      emit('change-password', newPassword.value);
    }
  } catch (e) {
    console.log(e)
  }
  passwordLoading.value = false;
  newPassword.value = null;
  confirmPassword.value = null;
}

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
