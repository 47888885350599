<template>
  <v-text-field
    v-model="searchText"
    color="primary"
    class="w-full text-darkGrey"
    variant="outlined"
    density="compact"
    hide-details
    prepend-inner-icon="mdi-magnify"
    :label="$t('Search')"
    @update:model-value="updateText"
  />
</template>

<script setup>
import { ref } from "vue";
import { debounce } from "lodash";
// import { storeToRefs } from "pinia";
// import { useRoadmapEmployeeStore } from "@/store/roadmap-employee";

// const { params } = storeToRefs(useRoadmapEmployeeStore());

const emit = defineEmits(["fetch"]);

const searchText = ref('');

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", searchText.value);
}, 300)
</script>