<template>
  <div
    v-if="interviewForm"
    class="w-full flex flex-col gap-[120px] flex-1"
  >
    <div class="flex items-start justify-between self-stretch">
      <div class="flex items-start gap-4">
        <svn-title h2 bold class="whitespace-nowrap">
          {{ campaign?.title }}
        </svn-title>

        <svn-title h2 medium class="whitespace-nowrap">
          {{ '-' }}
        </svn-title>

        <svn-title h2 medium class="whitespace-nowrap">
          {{ getInterviewTypeName(interviewKind?.toLowerCase()) }}
        </svn-title>
      </div>

      <div class="flex flex-col items-end gap-1 self-stretch">
        <div class="flex">
          <svn-title h2 medium class="mr-1">
            {{ $t("Deadline") }}:
          </svn-title>

          <svn-title h2 regular>
            {{ $t(campaign?.deadline || "No deadline") }}
          </svn-title>
        </div>

        <svn-title h2 regular color="dark-grey" class="whitespace-nowrap">
          {{ $t('Time Zone: ') }} {{ timezone }} - {{ timezoneInfo }}
          </svn-title>
      </div>
    </div>

    <div class="flex flex-col items-start gap-[180px] self-stretch">
      <div class="w-full flex flex-col justify-center items-start gap-12 self-stretch">
        <p v-if="campaign.campaign_type === 'Feedback'" class="text-[54px] font-semibold text-center leading-[150%] self-center">
          {{
            $t("Feedback of ", {
              interviewee: employee?.fullname,
            })
          }}
        </p>

        <p v-else-if="campaign.campaign_type === 'Survey'" class="text-[54px] font-semibold text-center leading-[150%] self-center">
          {{ $t("Survey of") }} {{ employee?.fullname }}
        </p>

        <p v-else class="text-[54px] font-semibold text-center leading-[150%] self-center">
          {{
            $t("'s Interview with", {
              interviewee: employee?.firstname,
              interviewer: interviewer?.fullname,
            })
          }}
        </p>

        <v-divider class="w-full border border-opacity-100" :thickness="2" />

        <!-- class="text-fake-black text-[32px] font-normal leading-8" -->
        <div class="flex flex-col items-start gap-6 self-stretch">
          <div
            v-for="history in interview?.format_user_histories"
            :key="history"
            class="flex items-center justify-center"
          >
            <p class="text-fake-black text-[32px] font-semibold leading-8 mr-2">
              {{ `${history?.username}` }}
            </p>

            <p class="text-fake-black text-[32px] font-normal leading-8">
              {{ `${history?.text}` }}
            </p>
          </div>
        </div>

        <quill-editor
          v-if="campaign?.campaign_type === 'Survey' || interviewForm?.description"
          v-model:content="interviewForm.description"
          content-type="html"
          :placeholder="' '"
          :read-only="true"
          class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description"
        />
      </div>

      <!-- Interview Content -->
      <div class="w-full flex flex-col items-start self-stretch gap-20">
        <div
          v-for="(question, idx) in questionsComponent"
          :key="idx"
          class="w-full flex flex-col gap-20"
        >
          <component
            :is="question.componentName"
            :loop-idx="idx"
            :question="question?.data"
          />

          <v-divider
            v-if="idx !== questionsComponent?.length - 1 &&
              (question?.data?.type !== InterviewQuestionType.PARAGRAPH &&
                question?.data?.type !== InterviewQuestionType.UPDATE_ROADMAP &&
                  question?.data?.type !== InterviewQuestionType.CREATE_ROADMAP) &&
                    (questionsComponent?.[idx + 1]?.data?.type !== InterviewQuestionType.UPDATE_ROADMAP &&
                      questionsComponent?.[idx + 1]?.data?.type !== InterviewQuestionType.CREATE_ROADMAP)"
            class="w-full border border-opacity-100 text-fake-black" :thickness="1"
          />
        </div>

        <img
          class="self-center bg-cover aspect-square w-[105px] h-[94px]"
          src="@/assets/logo_aleph_dark_grey.svg"
          alt="Logo Aleph"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useInterviewStore } from "@/store/interview.js";
import ChapterAnswerPdf from "@/components/pdf/interviews/oneToOneElementAnswer/ChapterAnswerPdf.vue";
import OpenQuestionAnswerPdf from "@/components/pdf/interviews/oneToOneElementAnswer/OpenQuestionAnswerPdf.vue";
import RatingAnswerPdf from "@/components/pdf/interviews/oneToOneElementAnswer/RatingAnswerPdf.vue";
import McqAnswerPdf from "@/components/pdf/interviews/oneToOneElementAnswer/McqAnswerPdf.vue";
import RoadmapBlockPdf from "@/components/pdf/interviews/oneToOneElementAnswer/RoadmapBlockPdf.vue";
import ParagraphAnswerPdf from "@/components/pdf/interviews/oneToOneElementAnswer/ParagraphAnswerPdf.vue";
import { InterviewQuestionType } from '@/constants/types';

export default {
  components: {
    RoadmapBlockPdf,
    ChapterAnswerPdf,
    OpenQuestionAnswerPdf,
    RatingAnswerPdf,
    McqAnswerPdf,
    ParagraphAnswerPdf
  },
  setup() {
    const { interviewForm, employee, interviewer, campaign, interview, interviewKind } = storeToRefs(
      useInterviewStore()
    );
    const { timezone, timezoneInfo } = storeToRefs(useUserStore());

    const route = useRoute();

    const { fetchInterview, clearInterview } = useInterviewStore();

    const questionsComponent = computed(() => {
      return interviewForm.value.questions.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER) {
          componentName = "open-question-answer-pdf";
        }
        else if (question.type === InterviewQuestionType.PARAGRAPH) {
          componentName = "open-question-answer-pdf";
        }
        else if (question.type === InterviewQuestionType.OPEN) {
          componentName = "open-question-answer-pdf";
        }
        else if (question.type === InterviewQuestionType.RATING) {
          componentName = "open-question-answer-pdf";
        }
        else if (question.type === InterviewQuestionType.MCQ) {
          componentName = "open-question-answer-pdf";
        }
        else if (question.type === InterviewQuestionType.UPDATE_ROADMAP) {
          componentName = "roadmap-block-pdf";
        }
        else if (question.type === InterviewQuestionType.CREATE_ROADMAP) {
          componentName = "roadmap-block-pdf";
        }

        return { data: question, componentName };
      });
    });

    clearInterview();
    fetchInterview(route.params.id);

    return {
      campaign,
      employee,
      interviewer,
      interviewForm,
      questionsComponent,
      interview,
      timezone,
      timezoneInfo,
      interviewKind,
      InterviewQuestionType
    };
  },
  methods: {
    getInterviewTypeName(interviewType) {
      switch (interviewType) {
        case 'employee':
          return 'Interviewee'
        case 'manager':
          return 'Interviewer'
        case 'crossed':
          return 'Cross Review'
        default:
          return ''
      }
    }
  }
};
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}
</style>