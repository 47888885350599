<template>
  <div
    :class="(showFilterResulsts && showResults) ?
      'flex flex-wrap sm:!flex-nowrap' : (!showFilterResulsts && showResults) ?
        'hidden' : (showFilterResulsts && !showResults) ?
          'flex flex-wrap sm:!flex-nowrap' : 'hidden'"
  >
    <div
      class="flex items-center flex-wrap gap-4 flex-1"
    >
      <svn-filter-item-chip
        v-for="item in selectedFilters"
        :key="item?.id"
        :item="item"
        :data="data"
        @update-selected-data-filters="updateSelectedDataFilters(item?.id, $event)"
        @remove-empty-filter="removeEmptyFilter(item)"
        @delete-filter="deleteFilter(item?.id)"
      />

      <svn-filter
        v-if="selectedFilters?.length && headers"
        :table-name="tableName"
        activator-variant="text"
        activator-icon="mdi-plus"
        :activator-height="32"
        activator-text="Add filter"
        :filter-columns="filterList"
        @add-filter="addFilter"
      />
    </div>

    <v-btn
      v-if="selectedFilters?.length"
      variant="text"
      :text="$t('Clear all')"
      color="primary"
      class="normal-case text-xs font-medium"
      @click="clearSelectedFilters"
    />
  </div>
</template>

<script setup>
import SvnFilter from './SvnFilter.vue';
import SvnFilterItemChip from './SvnFilterItemChip.vue';

const props = defineProps({
  data: { type: Array, default: () => [] },
  tableName: { type: String, default: '', required: true },
  headers: { type: Array, default: () => [] },
  showResults: { type: Boolean, default: false },
  filterList: { type: Array, default: () => [] },
  selectedFilters: { type: Array, default: () => [] },
  showFilterResulsts: { type: Boolean, default: true },
})

const emit = defineEmits(['add-filter', 'delete-filter', 'clear-selected-filters', 'remove-empty-filter', 'update-selected-data-filters'])

const addFilter = (item) => {
  emit('add-filter', item)
}

const deleteFilter = (id) => {
  emit('delete-filter', id)
}

const clearSelectedFilters = () => {
  emit('clear-selected-filters')
}

const removeEmptyFilter = (item) => {
  emit('remove-empty-filter', item)
}

const updateSelectedDataFilters = (id, event) => {
  emit('update-selected-data-filters', { id: id, event: event })
}
</script>

<style scoped>

</style>