<template>
  <svn-dialog-skeleton
    icon="ph:tag-bold"
    :title="$t('Edit template tags')"
    dialog-custom-class="!w-[400px]"
  >
    <template #activator>
      <slot name="activator" />
    </template>

    <template #body>
      <div class="w-full">
        <v-autocomplete
          v-model="entityTags"
          :items="companyCategories"
          chips
          closable-chips
          variant="outlined"
          density="compact"
          hide-details
          class="w-full"
          color="blue-grey-lighten-2"
          label="Search here for tags"
          multiple
          @update:search="searchText = $event"
        >
          <template #item="{ props, item }">
            <v-list-item
              v-bind="props"
              :value="item?.id"
              @click="updateTags(item?.raw)"
            />
          </template>

          <template #no-data>
            <div
              v-if="searchText.length !== 0"
              class="flex h-10 cursor-pointer hover:bg-gray-200 items-center"
              @click="createTag(searchText)"
            >
              <span class="ml-4 mr-2">
                Create
                <v-chip dense>
                  {{ searchText }}
                </v-chip>
              </span>
            </div>
            <div
              v-else
              class="flex h-10 cursor-pointer hover:bg-gray-200 items-center"
            >
              <span class="ml-4 mr-2"> Type something to search for tags </span>
            </div>
          </template>

          <template #chip="{ props, item }">
            <v-chip
              v-bind="props"
              :value="item?.raw?.title"
              closable
              @click:close="updateTags(item?.raw)"
            />
          </template>
        </v-autocomplete>
      </div>
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axiosService from "@/tools/axios-service.js";

const props = defineProps({
  entityId: { type: Number, required: true },
  fetchTagsFromEntityPath: { type: String, required: true },
  route: { type: String, required: true },
});

const emit = defineEmits(["refresh"]);

onMounted(() => {
  fetchEntityTags();
  fetchCompanyTags();
})

const searchText = ref("");
const entityTags = ref([]);
const companyCategories = ref([]);

const fetchEntityTags = async () => {
  try {
    const res = await axiosService.get(
      `${props?.fetchTagsFromEntityPath}/${props?.entityId}`,
    );
    entityTags.value = res?.data?.categories
  } catch (e) {
    console.log("error", e);
  }
};

const fetchCompanyTags = async (title = "") => {
  try {
    const res = await axiosService.get("/api/v1/interviews/categories", {
      params: { title: title },
    });

    companyCategories.value = res?.data?.categories;
  } catch (e) {
    console.log("error", e);
  }
};

const createTag = async (text) => {
  if (props?.route === 'interview_forms') {
    try {
    const res = await axiosService.post(
      `/api/v2/interview_app/forms/templates/${props?.entityId}/tags`,
      { tag: { title: text } }
    );
    companyCategories?.value?.push(res?.data?.category)
    searchText.value = '';
  } catch (e) {
    console.log("couldn't create a tag", e);
  }
  } else {
    const res = await axiosService.post(
      `/api/v2/interview_app/${props?.route}/${props?.entityId}/create_tags`,
      { category_title: text }
    );
    companyCategories?.value?.push(res?.data?.category)
    searchText.value = '';
  }
  emit("refresh");
};

const updateTags = async (tag) => {
  try {
    await axiosService.post(
      `/api/v2/interview_app/${props?.route}/${props?.entityId}/toggle_tag`,
      { tag: tag }
    );
    emit('refresh')
  } catch (error) {
    console.log(error)
  }
};
</script>

<style scoped>
* :deep(.v-field) {
  min-height: 48px;
  border-radius: 8px;
}
</style>