<template>
  <svn-dialog-skeleton
    ref="dialogRef"
    dialogHasTextArea
    :center-description="false"
    :title="$t('Help & support')"
    :description="$t('If you have any questions or require assistance, please feel free to reach out to the Aleph team by filling out this form. We will treat and respond to your message very soon!')"
    :width="isMobile ? 300 : 400"
    :primary-button-text="'Send'"
    loader-color="fake-black"
    primary-button-class="!rounded !text-white !bg-fake-black hover:!bg-[#8c8c8c] active:!bg-[#101010]"
    primary-button-loading-class="!bg-fake-black !text-white"
    :primary-button-close-on-click="false"
    :primary-button-loading="primaryButtonLoading"
    @click-primary-button="clickPrimaryButton"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #body>
      <v-textarea
        v-model="message"
        density="compact"
        :placeholder="$t('Your issue*')"
        variant="outlined"
        :error="messageError.error"
        :error-messages="messageError.errorMessage"
        :hide-details="messageError.hideDetails"
      />
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useUserStore } from "../../../store/user";

const snackbar = useSnackbar();

const { isMobile } = storeToRefs(useMobileStore());
const { email: userEmail } = storeToRefs(useUserStore());

const message = ref('');
const dialogRef = ref(null);
const primaryButtonLoading = ref(false);
const messageError = ref({ error: false, errorMessage: '', hideDetails: true });

const clickPrimaryButton = async () => {
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: "", hideDetails: true };
    displayRequiredFieldsSnackbar();
  } else {
    primaryButtonLoading.value = true;
    try {
      await axiosService.post('api/v1/global_mailer/help_request', {
        email: userEmail?.value,
        message: message?.value,
      });
      dialogRef.value.dialog = false;
      primaryButtonLoading.value = false;

      document.getElementById("profile_dropdown").click();

      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setBgColor('fake-black');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
  
      resetForm();
    } catch (error) {
      primaryButtonLoading.value = false;

      console.log(error);
      snackbar.setMsg('Error sending form, please try again.');
      snackbar.setBgColor('negativeRed');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
    }
  }
}

const resetForm = () => {
  message.value = '';
}

const displayRequiredFieldsSnackbar = () => {
  snackbar.setMsg('Please fill out all the required(*) fields.');
  snackbar.setBgColor('negativeRed');
  snackbar.setCustomClass('mb-12');
  snackbar.displaySnackBar();
}

watch(message, (newValue, oldValue) => {
  if (newValue !== "" && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
