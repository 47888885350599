
let timer = null;

export default function () {
  function bktTimer(cb) {
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      cb()
    }, 300)
  }

  async function bktSleep(time) {
    await new Promise(resolve => setTimeout(resolve, time))
  }

  function setHover(className) {
    const elems = document.querySelectorAll(`.${className}`)
    elems.forEach(el => {
      el.classList.add('bkt-bg-light-grey10')
    })
  }

  function removeHover(className) {
    const elems = document.querySelectorAll(`.${className}`)
    elems.forEach(el => {
      el.classList.remove('bkt-bg-light-grey10')
    })
  }

  function getIndicatorIconifyName(type) {
    if (type === 'boolean') {
      return 'ic:outline-toggle-on'
    } else if (type === 'numeric_value') {
      return 'ic:baseline-numbers'
    } else if (type === 'percentage') {
      return 'fa6-solid:percent'
    } else if (type === 'multi_choice') {
      return 'fluent:text-bullet-list-ltr-16-filled'
    }
  }

  function filterMultiChoiceCount(opts) {
    let count = 0
    const regex = new RegExp(/^choice_.*/)
    for (const opt in opts) {
      if (regex.test(opt)) count++
    }
    return count
  }

  function fetchPage(updateParams, fetch, page) {
    updateParams({
      'page[number]': page,
    })
    try {
      fetch()
    } catch {
      console.log("couldn't fetch")
    }
  }

  function getBorderColorByInterviewStatus(status) {
    if (status === 'not_started') return 'border-bkt-red'
    else if (status === 'in_progress') return 'border-bkt-yellow'
    else if (status === 'submitted') return 'border-bkt-green'
    else if (status === 'not_available_yet') return 'border-bkt-light-grey'
  }

  const deduceForWhom = (state) => {
    if (state === 'none') return []
    else if (state === 'employee') return ['Employee']
    else if (state === 'manager') return ['Manager']
    else if (state === 'all') return ['Employee', 'Manager']
  }
  const deduceToBackend = (arr) => {
    if (arr.length === 0) return 'none'
    else if (arr.length === 2) return 'all'
    else if (arr.length === 1) {
      if (arr[0] === 'Employee') return 'employee'
      else if (arr[0] === 'Manager') return 'manager'
    }
  }
  const deduceMatIconFromInterviewQuestionType = (type) => {
    if (type === 'InterviewApp::Questions::Chapter') return 'mdi-format-title'
    else if (type === 'InterviewApp::Questions::Open') return 'mdi-help-circle-outline'
    else if (type === 'InterviewApp::Questions::Rating') return 'mdi-star-half-full'
    else if (type === 'InterviewApp::Questions::Mcq') return 'mdi-checkbox-marked'
    else if (type === 'InterviewApp::Questions::CreateRoadmap' || type === 'InterviewApp::Questions::UpdateRoadmap') return 'mdi-bullseye-arrow'
  }
  const objectFlip = (obj) => {
    const ret = {};
    Object.keys(obj).forEach(key => {
      ret[obj[key]] = key;
    });
    return ret;
  }
  const backendAutoLogin = (path, params = {}) => {
    const backDns = import.meta.env.VITE_BACKEND_DOMAIN
    const paramsBuild = new URLSearchParams({
      ...params,
      auto_login: JSON.parse(window.localStorage.getItem('bktAccess')) ? JSON.parse(window.localStorage.getItem('bktAccess')).client : ''
    });

    return `${backDns}${path}/?${paramsBuild.toString()}`
  }

  const sortTagsWithTagsCategory = (tags, tagCategories) => {
    let sorted = {};

    Object.getOwnPropertyNames(tagCategories).forEach((val) => {
      tags.forEach((tag) => {
        if (tag?.tagCategory?.id === tagCategories[val].id) {
          sorted[tagCategories[val].id] = tag;
        }
      });
    });

    return sorted;
  };
  const getContentTypeByHeader = (header) => {
    let type

    // https://en.wikipedia.org/wiki/List_of_file_signatures
    // https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    switch (header) {
      case "89504e47":
        type = "image/png";
        break;
      case "47494638":
        type = "image/gif";
        break;
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        type = "image/jpeg";
        break;
      default:
        type = "unknown"; // Or you can use the blob.type as fallback
        break;
    }
    return type
  }
  const getContentTypeByBlob = (blob) => {
    return new Promise((resolve, _) => {
      let fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        const contentType = getContentTypeByHeader(header)
        resolve(contentType)
      };
      fileReader.readAsArrayBuffer(blob);
    })
  }
  const generateId = () => {
    // always start with a letter (for DOM friendlyness)
    let id = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
    do {
      // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
      let asciiCode = Math.floor((Math.random() * 42) + 48);
      if (asciiCode < 58 || asciiCode > 64) {
        // exclude all chars between : (58) and @ (64)
        id += String.fromCharCode(asciiCode);
      }
    } while (id.length < 32);

    return id;
  }

  const mapRange = (value, currentLow, currentHigh, targetLow, targetHigh) => {
    return targetLow + (targetHigh - targetLow) * (value - currentLow) / (currentHigh - currentLow);
  }

  return {
    bktTimer,
    bktSleep,
    setHover,
    removeHover,
    getIndicatorIconifyName,
    filterMultiChoiceCount,
    fetchPage,
    getBorderColorByInterviewStatus,
    deduceForWhom,
    deduceToBackend,
    deduceMatIconFromInterviewQuestionType,
    objectFlip,
    backendAutoLogin,
    sortTagsWithTagsCategory,
    getContentTypeByBlob,
    generateId,
    mapRange
  }
}
