<template>
  <div
    class="w-full h-[72px] flex items-center justify-between px-6 border-b border-darkGrey sticky bg-white z-[5]"
    :class="[type === 'show' ? 'justify-normal' : 'justify-end', modal ? 'top-0' : 'top-[72px]']">
    <!-- Go back button -->
    <svn-icon-button v-if="type === 'show'" icon="ic:baseline-arrow-back" button-size="md" variant="noOutlined" @click="emit('close-and-go-back')" />

    <div class="w-fit h-full flex items-center self-end gap-4">
      <!-- Edit button -->
      <svn-icon-button
        v-if="amICreatorOfEntityOrAdmin(moduleCreatorId) && type === 'show'"
        icon="material-symbols:edit-outline"
        button-size="md"
        variant="noOutlined"
        @click="emit('edit-module')"
      />

      <!-- Preview module button -->
      <slot v-if="type === 'draft' || type === 'edit'" name="preview-module" />

      <!-- Module options button -->
      <slot v-if="type === 'draft' || type === 'edit'" name="dots-button" />

      <!-- Publish module button -->
      <slot v-if="type === 'draft'" name="publish-module" />

      <!-- Comments button -->
      <svn-icon-button
        v-if="type === 'show'"
        icon="material-symbols:chat-bubble-outline"
        button-size="md"
        variant="noOutlined"
        @click="toggleComments"
      />
  
      <!-- Favorite button -->
      <div
        v-if="type === 'show'"
        class="rounded flex items-center justify-center h-8 w-8 p-1 hover:!bg-very-light-grey cursor-pointer"
        @click="emit('toggle-favorite')"
      >
        <Icon
          :icon="isFavorited ? 'mdi-cards-heart' : 'mdi:cards-heart-outline'"
          width="24"
          height="24"
          :class="isFavorited ? '!bg-transparent text-negativeRed active:!bg-light-grey' : '!rounded !bg-transparent !text-dark-grey hover:!text-primary active:!bg-light-grey'"
         />
      </div>
  
      <!-- Copy link button -->
      <svn-icon-button
        v-if="type === 'show'"
        icon="mdi:link-variant"
        button-size="md"
        variant="noOutlined"
        @click="emit('copy-link')"
      />
  
      <!-- Close and Go back button -->
      <svn-icon-button
        v-if="type !== 'show'"
        :icon="'material-symbols:close'"
        button-size="md"
        variant="noOutlined"
        @click="emit('close-and-go-back')"
      />
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { useUserStore } from "@/store/user.js";

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  type: { type: String, default: "view" },
  modal: { type: Boolean, default: false },
  moduleCreatorId: { type: Number, default: null },
  isFavorited: { type: Boolean, default: false },
  drawerCommentsLargeScreen: { type: Boolean, default: false }
})

const toggleComments = () => {
  emit('toggle-comments')
}

const emit = defineEmits(['toggle-comments', 'toggle-favorite', 'copy-link', 'edit-module', 'close-and-go-back'])
</script>