<template>
  <v-dialog
    v-model="dialogEditPlaylist"
    content-class="!rounded-[12px] bg-white"
    :width="isMobile ? 300 : 580"
  >
    <template #default>
      <div class="w-full flex flex-col p-6 gap-6">
        <!-- Header -->
        <div
          class="flex flex-col items-center pb-4"
        >
          <!-- icon -->
          <div
            class="self-end cursor-pointer"
            @click="dialogEditPlaylist = false"
          >
            <Icon
              icon="material-symbols:close"
              height="24"
              width="24"
              class="text-darkGrey !hover:text-fakeBlack"
            />
          </div>

          <!-- Title & description -->
          <div class="flex justify-center items-center self-stretch">
            <p class="text-fakeBlack text-xl font-medium text-center">
              {{ $t('Update playlist title & description') }}
            </p>
          </div>
        </div>

        <!-- Inputs -->
        <div class="flex flex-col gap-6">
          <!-- Title input -->
          <v-text-field
            v-model="playlistTitle"
            variant="outlined"
            class="text-sm text-fakeBlack font-normal input"
            persistent-counter
            maxlength="70"
            counter
            density="compact"
          >
            <template #counter="{ counter, max = 70, value }">
              <div class="flex items-start self-stretch px-4 pt-1">
                <span class="text-fakeBlack text-xs font-normal">
                  {{ value }} / {{ max }}
                </span>
              </div>
            </template>

            <template #label>
              <p class="text-sm text-darkGrey font-normal">
                {{ $t('Playlist title') }}
              </p>
            </template>
          </v-text-field>

          <!-- Description input -->
          <v-textarea
            v-model="playlistDescription"
            variant="outlined"
            class="text-sm text-fakeBlack font-normal input"
            persistent-counter
            maxlength="200"
            counter
            density="compact"
          >
            <template #counter="{ counter, max = 200, value }">
              <div class="flex items-start self-stretch px-4 pt-1">
                <span class="text-fakeBlack text-xs font-normal">
                  {{ value }} / {{ max }}
                </span>
              </div>
            </template>

            <template #label>
              <p class="text-sm text-darkGrey font-normal">
                {{ $t('Description') }}
              </p>
            </template>
          </v-textarea>
        </div>

        <!-- Validate button -->
        <div class="w-full">
          <v-btn
            variant="flat"
            color="primary"
            class="normal-case text-xs w-full"
            :text="$t('Validate')"
            :disabled="!playlistTitle?.length"
            @click="emit('update-playlist', playlistTitle, playlistDescription)"
          />
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
})

const dialogEditPlaylist = ref(false)

const playlistTitle = ref('')

const playlistDescription = ref('')

const { isMobile } = storeToRefs(useMobileStore())

const emit = defineEmits(['update-playlist'])

watch(props ,() => {
  playlistTitle.value = props.title
  playlistDescription.value = props.description
})

defineExpose({
  dialogEditPlaylist
})
</script>