<template>
  <v-card class="w-full mt-10 mr-8">
    <v-card-title>
      {{ $t("Template tags") }}
      <v-divider />
    </v-card-title>

    <v-card-text>
      <p class="hint mb-5 text-neutral-400">
        {{
          $t(
            "Add tags to your template in order to search through templates efficiently and create accurate reports"
          )
        }}
      </p>

      <svn-autocomplete
        v-if="displayTags"
        v-model="interviewFormTags"
        :placeholder="$t('Search tags')"
        :items="companyTags"
        item-title="title"
        item-value="id"
        multiple
        checkbox
        chips
        closable-chips
        close-icon="mdi-close-circle"
        color="primary"
        class="min-w-full"
        :loading="false"
        :done="true"
        :disabled="isDeleted"
        @input="searchOrCreation"
        @update:model-value="updateIntFormTags($event)"
      >
        <template #prepend-details>
          {{ null }}
        </template>

        <template #append-content>
          {{ null }}
        </template>

        <template #no-data>
          <div class="flex justify-start items-center">
            <svn-text base class="mx-4">
              {{ $t("create the following tag") }}:
            </svn-text>

            <svn-chip
              v-if="search"
              class="!bg-interview-blue-soft !text-sm !text-primary border-none !rounded-full !px-3 !cursor-pointer"
              :text="search"
              @click="createTag"
            />
          </div>
        </template>
      </svn-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import axiosService from "@/tools/axios-service.js";
import { storeToRefs } from "pinia";
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useSnackbar } from '@/store/snackbar';
import {useRoute} from "vue-router";

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const route = useRoute()

const { updateInterviewFormTags, fetchInterviewForm } = useInterviewFormStore();
const { tags: interviewFormTags, id, interviewForm } = storeToRefs(useInterviewFormStore());
const isLoading = ref(false);

const companyTags = ref([]);
const search = ref(null);
const itemsInit = ref([]);
const displayTags = ref(false)

const snackbar = useSnackbar()

onMounted(async () => {
  try {
    await fetchInterviewForm(route.params.id)
    await fetchCategories("");
    interviewFormTags.value = interviewForm.value.categories.map(element => element.id)
    itemsInit.value = interviewFormTags.value
    displayTags.value = true
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching categories or interview forms')
    snackbar.displaySnackBar()
  }
});

watch(search, async (newValue, oldValue) => {
  await fetchCategories(newValue);
});

const updateIntFormTags = async(value) => {
  await updateInterviewFormTags(value)
}

const fetchCategories = async (newValue) => {
  isLoading.value = true;
  try {
    const res = await axiosService.get("/api/v1/interviews/categories", {
      params: { title: newValue },
    });

    companyTags.value = res.data.categories;
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching categories')
    snackbar.displaySnackBar()
  } finally {
    isLoading.value = false;
  }
};

const searchOrCreation = (searchValue) => {
  search.value = searchValue
}

const createTag = async () => {
  try {
    await axiosService.post(
      `/api/v2/interview_app/forms/templates/${id.value}/tags`,
      { tag: { title: search.value } }
    );
    await fetchInterviewForm(id.value)
    interviewFormTags.value = interviewForm.value.categories.map(element => element.id)
  } catch (e) {
    console.log("couldn't create a tag", e);
  } finally {
    search.value = null;
  }
};
</script>

<style scoped>
.hint {
  margin-top: 5px;
}
</style>
