<template>
  <v-menu
    :close-on-content-click="false"
    :model-value="true"
  >
    <template #activator="{ props }">
      <!-- Filter chip (which activates the menu) -->
      <div
        v-bind="props"
        class="flex justify-center items-center gap-2 px-2 py-[6px] border border-primary rounded-[8px]
          cursor-pointer max-w-full sm:max-w-[300px]"
        :class="selectedFilterAnswerData?.length ? 'border-primary' : 'border-darkGrey'"
      >
        <!-- Filter chip main content -->
        <div class="flex items-center gap-1">
          <!-- Filter Chip Icon -->
          <div v-if="item?.icon">
            <Icon
              :icon="item?.icon"
              height="16"
              width="16"
              :class="selectedFilterAnswerData?.length ? 'text-primary' : 'text-darkGrey'"
            />
          </div>

          <!-- Filter Chip title -->
          <p
            class="text-xs"
            :class="selectedFilterAnswerData?.length ? 'text-primary font-medium' : 'text-darkGrey font-normal'"
          >
            {{ item?.name }}:
          </p>

          <!-- Filter Chip Operator Option & Answer -->
          <div
            v-if="selectedFilterAnswerData?.length"
            class="flex items-center gap-1"
          >
            <!-- Filter Chip Selected Operator Option -->
            <p
              v-if="item?.type === 'number' || item?.type === 'date'"
              class="text-xs text-primary font-normal capitalize"
            >
              {{ getSelectItemText(filterOperatorOption) }}
            </p>

            <!-- Filter Chip Selected Answer(s) (Type multi_select) -->
            <div
              v-if="item?.type === 'multi_select'"
              class="max-w-[115px] flex items-center gap-1"
            >
              <p
                class="text-xs text-primary font-normal truncate"
              >
                {{ isColumnUser ? finalString : getFilterAnswers() }}
              </p>
            </div>

            <!-- Filter Chip Selected Answer(s) (Other types) -->
            <div
              v-else
              class="max-w-[115px]"
            >
              <p class="text-xs text-primary font-normal truncate">
                {{ selectedFilterAnswerData }}
              </p>
            </div>
          </div>
        </div>

        <!-- Down arrow at the end of the filter chip -->
        <div @click="selectedFilterAnswerData?.length ? '' : removeEmptyFilter()">
          <Icon
            :icon="selectedFilterAnswerData?.length ? 'material-symbols:keyboard-arrow-down' : 'material-symbols:close'"
            height="16"
            width="16"
            :class="selectedFilterAnswerData?.length ? 'text-primary' : 'text-darkGrey hover:!text-fake-black'"
          />
        </div>
      </div>
    </template>

    <!-- Filter chip menu (after the chip is pressed) -->
    <v-list class="!py-0">
      <div class="flex flex-col p-4 items-start gap-2 w-full min-w-[227px] max-w-[500px]">
        <div class="w-full gap-10 flex items-center">
          <div class="w-full flex items-center gap-4">
            <!-- Filter chip title -->
            <p class="text-darkGrey text-xs font-normal">
              {{ item?.name }}
            </p>

            <!-- Filter chip selected operator option (Type text && multi_select) -->
            <p
              v-if="item?.type === 'text' || item?.type === 'multi_select'"
              class="text-darkGrey text-xs font-medium"
            >
              {{ item?.type === 'text' ? $t("contains") : $t("is") }}
            </p>

            <!-- Filter chip selected operator option (Other types) -->
            <v-menu
              v-else
              :close-on-content-click="false"
            >
              <template #activator="{ props }">
                <!-- Menu activator to select another filter operator option -->
                <div
                  id="activatorMenu"
                  v-bind="props"
                  class="flex items-center cursor-pointer gap-1"
                >
                  <!-- Filter Chip selected operator option -->
                  <p class="text-darkGrey text-sm font-normal line-clamp-1 capitalize">
                    {{ getSelectItemText(filterOperatorOption) }}
                  </p>

                  <!-- Down arrow after filter operator option -->
                  <div v-if="item?.type !== 'text' && item?.type !== 'multi_select'">
                    <Icon
                      icon="material-symbols:keyboard-arrow-down"
                      height="16"
                      width="16"
                      class="text-darkGrey"
                    />
                  </div>
                </div>
              </template>

              <!-- Filter operator option list -->
              <v-list class="!py-0">
                <div
                  v-for="(element, index) in getSelectItemsAccordingToType(item?.type)"
                  :key="index"
                  class="flex items-center gap-2 flex-1 cursor-pointer py-3 px-2 hover:bg-light-grey min-w-[167px]"
                  @click="changeSelectItemModel(element)"
                >
                  <!-- Icon for the filter operator option if the filter's type is number -->
                  <div v-if="new Set(['equal', 'not_equal', 'greater_than', 'less_than']).has(element)">
                    <Icon
                      :icon="getSelectItemIcon(element)"
                      height="16"
                      width="16"
                      class="text-fakeBlack"
                    />
                  </div>

                  <!-- Filter operator option value -->
                  <p class="text-fakeBlack text-sm font-normal line-clamp-1">
                    {{ getSelectItemText(element) }}
                  </p>
                </div>
              </v-list>
            </v-menu>
          </div>

          <!-- Delete filter Button -->
          <div
            class="cursor-pointer"
            @click="deleteFilter"
          >
            <Icon
              icon="mdi:trash-can-outline"
              height="16"
              width="16"
              class="text-negativeRed"
            />
          </div>
        </div>

        <!-- Filter type="text" OR Filter type="number" -->
        <v-text-field
          v-if="item?.type === 'text' || item?.type === 'number'"
          v-model="selectedFilterAnswerData"
          :autofocus="true"
          :label="`${item?.name}`"
          hide-details
          :type="item?.type"
          variant="outlined"
          density="compact"
          class="w-full text-xs font-normal"
          @update:model-value="updateSelectedFilterAnswerData"
        />

        <!-- Filter type="date" -->
        <date-picker
          v-else-if="item?.type === 'date'"
          v-model="selectedFilterAnswerData"
          :auto-focus="true"
          base-color="primary"
          density="compact"
          class="!w-full"
          @update:model-value="updateSelectedFilterAnswerData"
        />

        <!-- Filter type="multi_select" -->
        <v-autocomplete
          v-else-if="item?.type === 'multi_select'"
          v-model="selectedFilterAnswerData"
          v-model:search="searchText"
          :autofocus="true"
          variant="outlined"
          chips
          multiple
          density="compact"
          class="w-full max-w-[195px]"
          :menu-props="{ maxWidth: 195, maxHeight: 300 }"
          hide-details
          :item-title="column?.answer_options?.type === 'sync' ?
            (item) => { return `${item}`} :
            (item) => { return `${item?.fullname}`}"
          :item-value="column?.answer_options?.type === 'sync' ? 'key' : 'id'"
          :items="formattedFilteredData"
          :placeholder="'Search'"
          @update:search="searchText = $event"
          @input="setSearch"
          @update:model-value="updateSelectedFilterAnswerData"
        >
          <template
            v-if="column?.answer_options?.type === 'async'"
            #item="{ item, index, props }"
          >
            <div class="w-full flex flex-col gap-2">
              <v-list
                v-bind="props"
                class="!py-0"
              >
                <v-list-item
                  :value="item?.raw?.id"
                  class="!py-0 max-w-[195px]"
                >
                  <p class="truncate">
                    {{ item?.raw?.fullname }}
                  </p>
                </v-list-item>
              </v-list>

              <div
                v-if="index === formattedFilteredData?.length - 1 && currentPage < totalPages && !loading"
                class="self-center"
              >
                <v-btn
                  variant="outlined"
                  density="compact"
                  text="Load more"
                  color="primary"
                  :height="42"
                  class="normal-case"
                  @click="loadMoreData"
                />
              </div>

              <div
                v-if="index === formattedFilteredData?.length - 1 && currentPage < totalPages && loading"
                class="self-center"
              >
                <svn-loader loading-size="lg" />
              </div>
            </div>
          </template>

          <template #no-data>
            <div
              v-if="loading"
              class="w-full h-full flex justify-center items-center mx-auto py-4"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                :size="32"
              />
            </div>

            <div
              v-else
              class="w-[195px] h-full flex flex-col justify-center items-center px-4 py-20 mx-auto text-center"
            >
              <p>
                {{ ('No result found, please try again !') }}
              </p>
            </div>
          </template>
        </v-autocomplete>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { Icon } from '@iconify/vue';
import DatePicker from './dates/BktVuetifyDatePicker.vue';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { useFilterStore } from "@/store/filters";
import axiosService from "@/tools/axios-service.js";

const props = defineProps({
  item: { type: Object, default: () => {} },
  data: { type: Object, default: () => {} },
})

onMounted(async() => {
  initializeModels()
})

const emit = defineEmits(['update-selected-data-filters', 'remove-empty-filter', 'delete-filter'])

const { columns } = storeToRefs(useFilterStore());

const column = ref(null);
const currentPage = ref(1);
const loading = ref(true);
const finalString = ref('');
const itemFound = ref(true);
const searchText = ref(null);
const totalPages = ref(null);
const filterOperatorOption = ref(null);
const formattedFilteredData = ref([]);
const selectedFilterAnswerData = ref(null);

const getSelectItemsAccordingToType = (type) => {
  switch (type) {
    case "date":
      return ["before", "after"]
    case "number":
      return ["equal", "not_equal", "greater_than", "less_than"]
    default:
      return ["contains"]
  }
}

const getSelectItemText = (value) => {
  switch (value) {
    case "before":
      return "Before"
    case "after":
      return "After"
    case "equal":
      return "Equals"
    case "not_equal":
      return "Not Equal"
    case "greater_than":
      return "Greater than"
    case "less_than":
      return "Less than"
    default:
      return ""
  }
}

const getSelectItemIcon = (value) => {
  switch (value) {
    case "equal":
      return "mdi:equal"
    case "not_equal":
      return "mdi:not-equal-variant"
    case "greater_than":
      return "mdi:greater-than-or-equal"
    case "less_than":
      return "mdi:less-than-or-equal"
    default:
      return ""
  }
}

const changeSelectItemModel = (value) => {
  let oldValue = filterOperatorOption.value

  filterOperatorOption.value = value
  document.getElementById('activatorMenu').click()

  if (value !== oldValue?.toLowerCase() && selectedFilterAnswerData?.value?.length) {
    emit('update-selected-data-filters', { id: props?.item?.id, name: props?.item?.key, value: selectedFilterAnswerData?.value, operator: value })
  }
}

const initializeModels = async() => {
  column.value = columns?.value?.find(el => el?.name === props?.item?.key)

  if (!formattedFilteredData?.value?.length) {
    if (column?.value?.answer_options?.type === "async") {
      if (column?.value?.answer_options?.endpoint === "/api/v1/users") {
        if (column?.value?.name === "manager_id") {
          try {
            const { data } = await axiosService.get(`/api/v1/users/users_search`,
            { params: {
                only_managers: true,
                "page[per]": 25
              }
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        } else if (column?.value?.name === "objectivable_id") {
          try {
            const { data } = await axiosService.get(`/api/v1/users`,
            { params: {
                "page[per]": 25
              }
            });
            formattedFilteredData.value = data?.users;
            totalPages.value = data?.meta?.pagination?.total_pages;
            loading.value = false;
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else {
      formattedFilteredData.value = column?.value?.answer_options?.items
    }
  }
  if (!filterOperatorOption?.value) {
    filterOperatorOption.value = getSelectItemsAccordingToType(props?.item?.type)?.[0]
  }
}

const removeEmptyFilter = () => {
  emit('remove-empty-filter')
}

const deleteFilter = () => {
  if (selectedFilterAnswerData?.value) {
    emit('delete-filter', selectedFilterAnswerData.value)
  }
  else {
    removeEmptyFilter()
  }
}

const isColumnUser = computed(() => {
  return column?.value?.name === "manager_id" || column?.value?.name === "objectivable_id"
})

const updateSelectedFilterAnswerData = debounce(async(e) => {
  emit('update-selected-data-filters', { id: props?.item?.id, name: props?.item?.key, value: e, operator: filterOperatorOption?.value })

  if (isColumnUser.value) {
    finalString.value = ""
    for (let [index, element] of e?.entries()) {
      const { data } = await axiosService.get(`/api/v1/users/${element}`)
      if (index !== e?.length - 1) {
        finalString.value += `${data?.user?.fullname}, `
      } else {
        finalString.value += `${data?.user?.fullname}`
      }
    }
  }
}, 300);

const getFilterAnswers = () => {
  let data = ''
  selectedFilterAnswerData.value.map((element, i) => {
    if (i !== selectedFilterAnswerData?.value?.length - 1) {
      data += `${element}, `
    } else {
      data += `${element}`
    }
  })
  return data
}

const setSearch = (e) => {
  if (column?.value?.name === "manager_id" || column?.value?.name === "objectivable_id") {
    loading.value = true
    let found = formattedFilteredData.value.find(el => el?.fullname?.toLowerCase().includes(searchText.value))
    setItemFound(found)
    setDataAfterSearch()
  } else {
    let found = formattedFilteredData.value.find(el => el?.toLowerCase().includes(searchText.value))
    setItemFound(found)
  }
}

const loadMoreData = async() => {
  if (currentPage?.value < totalPages?.value) {
    if (column?.value?.name === "manager_id") {
      try {
        loading.value = true;
        const { data } = await axiosService.get(`/api/v1/users/users_search`,
        { params: {
            only_managers: true,
            "page[per]": 25,
            "page[number]": currentPage?.value + 1
          }
        });
        formattedFilteredData.value = [ ...formattedFilteredData?.value, ...data?.users ]
        totalPages.value = data?.meta?.pagination?.total_pages
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    } else if (column?.value?.name === "objectivable_id") {
      try {
        loading.value = true;
        const { data } = await axiosService.get(`/api/v1/users`,
        { params: {
            "page[per]": 25,
            "page[number]": currentPage?.value + 1
          }
        });
        formattedFilteredData.value = [ ...formattedFilteredData?.value, ...data?.users ]
        totalPages.value = data?.meta?.pagination?.total_pages
        loading.value = false;
      } catch (error) {
        console.log(error);
      }
    }
    currentPage.value += 1;
  }
}

const setDataAfterSearch = debounce(async() => {
  try {
    const { data } = await axiosService.get(`/api/v1/users`,
    { params: {
        title: searchText?.value,
        "page[per]": 25,
        "page[number]": 1
      }
    });
    formattedFilteredData.value = data?.users;
    totalPages.value = data?.meta?.pagination?.total_pages;
    if (!data?.users?.length) {
      loading.value = false;
    }
  } catch (error) {
    console.log(error)
  }
}, 300)

const setItemFound = (found) => {
  if (found) {
    itemFound.value = true
  }
  else {
    itemFound.value = false
  }
}

watch(() => props?.data, (newValue, oldValue) => {
  if (newValue) {
    initializeModels()
  }
})
</script>
