import {defineStore} from "pinia";
import {genericFunctions, genericStates} from "@/tools/genericFunctions.js";

export const useReportStore = defineStore('reports', {
  state: () => ({
    reports: null,
    trashedReports: [],
    ...genericStates,
  }),
  actions: {
    ...genericFunctions,
    async fetchReports(search = null, page = 1) {
      const reportStore = useReportStore()
      this.params = {
        search: search,
        "page[number]": page,
      }

      await reportStore.fetchEntity("/api/v2/interview_app/reports", "interview_reports", 'reports');
    },
    async fetchTrashedReports(search = null) {
      const reportStore = useReportStore()
      this.params = {
        search: search
      }

      await reportStore.fetchEntity("/api/v2/interview_app/reports/trashes", "interview_reports", 'trashedReports');
    },
    async deleteReport(id) {
      const reportStore = useReportStore()

      await reportStore.deleteEntity(`/api/v2/interview_app/reports/${id}`)
      const trash = this.reports.find(trash => trash.id === id)
      if (trash) {
        trash.deleted_at = new Date()
        this.trashedReports.push(trash)
      }
      this.reports = this.reports.filter(trash => trash.id !== id)
    },
    async deletePermanentlyReport(id) {
      const reportStore = useReportStore()

      await reportStore.deleteEntity(`/api/v2/interview_app/reports/${id}/destroy_permanently`)
      this.removeReportsFromTrashes(id)
    },
    async restoreReport(id) {
      const reportStore = useReportStore()

      await reportStore.updateEntity(`/api/v2/interview_app/reports/${id}/restore`)

      this.reports.push(this.trashedReports.find(trash => trash.id === id))
      this.removeReportsFromTrashes(id)
    },
    removeReportsFromTrashes (id) {
      this.trashedReports = this.trashedReports.filter(trash => trash.id !== id)
    }
  }
})