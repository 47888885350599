<template>
  <div class="w-full flex flex-col sm:gap-8 pt-5 sm:pt-10 pb-16">
    <!-- Header with company name & company logo -->
    <div class="w-full mx-auto flex h-full justify-center items-center p-6">
      <div class="flex gap-4 sm:gap-12">
        <svn-title h1 semi-bold>
          {{ name }}
        </svn-title>
      </div>
    </div>

    <!-- Different tabs -->
    <div class="flex flex-col">
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        grow
        align-tabs="center"
        class="border-b border-b-darkGrey w-full max-w-[928px] xl:max-w-[1136px] sm:mx-auto sticky top-[72px] !z-[1] !bg-white"
      >
        <!-- Information Tab -->
        <v-tab value="information">
          <div class="flex items-center gap-2">
            <div>
              <Icon
                icon="ic:baseline-person-outline"
                height="24"
                width="24"
                :class="tab === 'information' ? 'text-primary' : 'text-dark-grey'"
              />
            </div>

            <p
              class="text-sm font-medium normal-case"
              :class="tab === 'information' ? 'text-fakeBlack' : 'text-darkGrey'"
            >
              {{ $t('Information') }}
            </p>
          </div>
        </v-tab>

        <!-- Personnalizatiotabn Tab -->
        <v-tab value="customization">
          <div class="flex items-center gap-2">
            <div>
              <Icon
                icon="material-symbols:brush"
                height="24"
                width="24"
                :class="tab === 'customization' ? 'text-primary' : 'text-dark-grey'"
              />
            </div>

            <p
              class="text-sm font-medium normal-case"
              :class="tab === 'customization' ? 'text-fakeBlack' : 'text-darkGrey'"
            >
              {{ $t('Customization') }}
            </p>
          </div>
        </v-tab>
      </v-tabs>

      <!-- Windows -->
      <v-window v-model="tab" class="w-full max-w-[928px] xl:max-w-[1136px] mx-auto">
        <!-- Information window -->
        <v-window-item value="information">
          <window-information :company-links="links" />
        </v-window-item>

        <!-- Personnalization window -->
        <v-window-item value="customization">
          <window-personnalization />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from 'pinia';
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCompanyStore } from '@/store/company';
import WindowInformation from '@/components/companyApp/WindowInformation.vue';
import WindowPersonnalization from '@/components/companyApp/WindowPersonnalization.vue';

onMounted(async () => {
  tab.value = route?.query?.tab
});

const { name, links } = storeToRefs(useCompanyStore());

const route = useRoute();
const router = useRouter();
const tab = ref('information');

watch(tab, (newVal, _) => {
  router.push({
    name: 'company',
    query: { tab: newVal }
  })
})

watch(route, () => {
  if (route.name == 'company') {
    tab.value = route?.query?.tab
  }
})
</script>