<template>
  <div
    class="min-w-[300px] sm:w-full h-[338px] flex flex-col gap-4 skeleton
    rounded-[12px] border border-darkGrey overflow-hidden cursor-progress"
  >
    <div class="overflow-hidden">
      <v-skeleton-loader
        type="image"
        class="h-[160px] image"
      />
    </div>

    <div class="flex flex-col h-[178px] p-4">
      <div class="flex flex-col gap-2">
        <v-skeleton-loader
          type="list-item"
          class="item"
        />

        <v-skeleton-loader
          type="list-item"
          class="item"
        />
      </div>

      <div class="flex flex-col gap-1">
        <v-skeleton-loader
          type="subtitle"
          class="item w-[50px]"
        />

        <div class="flex items-center gap-2">
          <v-skeleton-loader
            type="subtitle"
            class="item w-[90%]"
          />

          <v-skeleton-loader
            type="subtitle"
            class="item w-[10%]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image :deep(.v-skeleton-loader__image) {
  height: 160px;
}

.item :deep(.v-skeleton-loader__list-item) {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
