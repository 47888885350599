<template>
  <div
    v-if="accessLevel === 'super_admin' && impersonateDisplay || impersonateAdminUser"
    class="sm:flex text-base text-white bg-black"
  >
    <div class="flex items-center pa-3">
      <a
        class="text-base font-weight-bold uppercase"
      >
        impersonate
      </a>
    </div>
    <div class="pa-3 flex border-x-2 border-white">
      <div class="flex items-center">
        Select a user :
      </div>

      <svn-autocomplete
        :placeholder="$t('Search')"
        :items="usersList"
        clear-on-select
        class="text-black !min-w-[300px] ps-2"
        @input="searchUserList"
        @update:model-value="updateImpersonateUser"
      >
        <template #main-content="{ item }">
          <svn-text
            sm
            color="dark-grey"
            @click="updateImpersonateUser"
          >
            {{ item?.raw?.email }}
          </svn-text>
        </template>

        <template #append-content>
          {{ null }}
        </template>
      </svn-autocomplete>

      <div class="flex items-center ">
        <v-btn
          class="hover:bg-neutral-600 px-0 mx-0 shadow-none hover:shadow-none focus:shadow-none"
          @click="logoutImpersonateUser()"
        >
          <v-icon
            icon="mdi-logout"
            class="text-white"
          />
        </v-btn>
      </div>
    </div>
    <div class="pa-3 bg-black">
      <div
        v-if="selectedUser"
        class="text-orange"
      >
        You are connected as <span class="uppercase font-weight-bold"> {{ selectedUser?.lastname }} </span> <span class="font-weight-bold"> {{ selectedUser?.firstname }} </span> access_level <span class="font-weight-bold">{{ selectedUser?.access_level_preset }}</span> - company
      </div>
    </div>
    <div
      v-if="impersonateAdminUser"
      class="border-x-2 pa-3 bg-black"
    >
      Admin as <span class="font-bold">{{ impersonateAdminUser.uid }}</span>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import { useImpersonateStore } from "@/store/impersonate.js";
import { ref, onMounted } from "vue";
import { debounce } from "lodash";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";

const { accessLevel } = storeToRefs(useUserStore());

const router = useRouter();
const snackbar = useSnackbar()
const { superAdminSearchUser } = useUserStore()
const { selectedUser, impersonateDisplay, impersonateAdminUser } = storeToRefs(useImpersonateStore())
const { initImpersonate, setSelectedUser, impersonateUser, impersonateUserLogout } = useImpersonateStore();

const usersList = ref([])
const logoutImpersonateUser = () => {
  impersonateUserLogout()
  router.go()
}

onMounted(async () => {
  try {
    usersList.value = await superAdminSearchUser()
  } catch (error) {
    console.log(error)
  }
})

const updateImpersonateUser = async (userId) => {
  try {
    const selectedUser = await usersList.value.find(element => element.id === userId)
    await setSelectedUser(selectedUser)

    await impersonateUser(selectedUser)
    router.go()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while impersonating')
    snackbar.displaySnackBar()
  }
}

const searchUserList = debounce(
  async (value) => {
    usersList.value = await superAdminSearchUser(value)
  }, 200
)

initImpersonate()
</script>
