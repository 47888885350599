<template>
  <div class="flex items-center justify-center px-4 sm:!px-0">
    <campaign-show-search
      :campaign="campaign"
      :overview="overview"
      :campaign-overview="campaignOverview"
      :set="set"
      @fetch="fetchFromCampaignShowSearch($event)"
    />

    <div
      v-if="overview"
    >
      <svn-dots-button
        table
        :show="!isTrashed"
        button-size="lg"
        variant="hoverNoPrimary"
      >
        <template #items>
          <v-list-item
            v-if="campaign?.campaign_type ===
              CampaignTypeBackend.FEEDBACK_360"
            value="add_participant_feedback"
            @click="openAddFeedbackParticipant"
          >
            {{ `+ ${$t('Add participant')}` }}
          </v-list-item>

          <v-list-item
            v-if="campaign?.campaign_type ===
              CampaignTypeBackend.ONE_TO_ONE"
            value="add_participant_one_to_one"
            @click="openAddParticipant"
          >
            {{ `+ ${$t('Add participant')}` }}
          </v-list-item>

          <v-list-item
            value="send_invitation_to_all"
            @click="sendInvitationToAll"
          >
            {{ $t('Send invitation to all') }}
          </v-list-item>

          <v-list-item
            v-if="campaign?.campaign_type ===
              CampaignTypeBackend.ONE_TO_ONE"
            value="send_reminder_to_all"
            @click="sendReminderToAll"
          >
            {{ $t('Send invitation to all') }}
          </v-list-item>
        </template>
      </svn-dots-button>
    </div>
  </div>
  <campaign-show-table
    v-show="(campaign && set && set.length > 0 && !loading)"
    :campaign="campaign"
    :current-user-id="currentUserId || id.toString()"
    :overview="overview"
    :campaign-overview="campaignOverview"
    :set="set"
    :set-params="setParams"
    :is-trashed="isTrashed"
    :pagination="pagination"
  />
  <svn-loader v-if="!campaign || loading || !set" loading-size="lg" />
  <bkt-no-entity-from-index
    v-else-if="campaign && set && set.length === 0"
    :search="search?.['text']"
    :status="search?.['status']"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCampaignStore } from "@/store/campaign.js";
import { usePopUpStore } from "@/store/pop-up";
import CampaignShowTable from "@/views/interviews/campaigns/CampaignShowTable.vue";
import CampaignShowSearch from "@/views/interviews/campaigns/CampaignShowSearch.vue";
import BktNoEntityFromIndex from "@/components/BktNoEntityFromIndex.vue";
import {useRoute} from "vue-router";
import i18n from "@/plugins/i18n";
import { useUserStore } from "@/store/user.js";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import axiosService from "@/tools/axios-service.js";

const props = defineProps({
  campaignId: { type: String, default: null },
  overview: { type: String, default: null },
  set: { type: Array, required: true },
  campaignOverview: { type: Object, required: true },
  currentUserId: { type: String, default: null },
  isTrashed: { type: Boolean, default: true },
});
const {openPopUp, closePopUp} = usePopUpStore()
const { id } = storeToRefs(useUserStore());
const { campaign, search, pagination, loading } = storeToRefs(useCampaignStore());
const { fetchCampaign, setParams, updateParams, fetchCampaignInterview, fetchCampaignFeedback } =
  useCampaignStore();
const route = useRoute();

const emit = defineEmits(['fetchFromCampaignShowSearch'])

const openAddFeedbackParticipant = () => {
  const cId = props.campaignId || route.params.id;
  openPopUp({
    componentName: "pop-up-set-another-participant",
    title: i18n.global.t('Select another participant'),
    set: props.set,
    campaignId: cId,
    icon: 'ic-baseline-person-add',
    customClass: "lg:w-[560px] !justify-normal",
    callback: () => {
      fetchCampaign(cId);
      fetchCurrentCampaign();
    },
    close() {},
  });
};

const openAddParticipant = () => {
  const cId = props.campaignId || route.params.id;
  openPopUp({
    componentName: "pop-up-set-another-participant",
    title: i18n.global.t('Select another participant'),
    set: props.set,
    campaignId: cId,
    icon: 'ic-baseline-person-add',
    customClass: "lg:w-[560px] !justify-normal",
    callback: () => {
      fetchCampaign(cId);
      fetchCurrentCampaign();
    },
    close() {},
  });
};
const sendReminderToAll = async () => {
  try {
    await axiosService.get(
      `/api/v2/interview_app/campaigns/${props.campaignId || route.params.id}/send_notification_email`,
      {
        params: {
          email_type: "remind",
        },
      }
    );
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Reminder is on its way !`),
      icon: 'noto-bell',
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      appName: "Interviews",
      confirm() {
        closePopUp();
      },
    });
  } catch (e) {}
};
const sendInvitationToAll = async () => {
  try {
    await axiosService.get(
      `/api/v2/interview_app/campaigns/${props.campaignId || route.params.id}/send_notification_email`,
      {
        params: {
          email_type: "invite",
        },
      }
    );
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t('Invitation is on its way !'),
      icon: 'noto-incoming-envelope',
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      appName: "Interviews",
      confirm() {
        closePopUp();
      },
    });
  } catch (e) {
    console.log(e)
  }
};
const fetchFromCampaignShowSearch = (event) => {
  setParams(event);
  updateParams({ tags: [] });
  emit('fetchFromCampaignShowSearch')
};

const fetchCurrentCampaign = async () => {
  switch (campaign?.value?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      await fetchCampaignInterview(props.campaignId || route.params.id)
      return
    case CampaignTypeBackend.FEEDBACK_360:
      await fetchCampaignFeedback(props.campaignId || route.params.id)
      return
    default:
      return
  }
}
</script>
