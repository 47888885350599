<template>
  <div
    v-click-outside="hide"
    class="relative"
    @keydown.enter="display = false"
  >
    <div @click="display = !display">
      <slot
        name="target"
      />
    </div>

    <Transition>
      <div
        v-if="display"
        class="absolute bg-white bkt-box-shadow-medium rounded-[8px] bkt-box-shadow-medium w-max"
        style="top: 60px;right: 10px; z-index: 1000"
      >
        <slot name="dropdown" />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

const display = ref(false);

const hide = () => {
  display.value = false;
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  /*transform: scale(0)*/
}

</style>
