<template>
  <div
    v-if="interviewForm"
    class="w-full mt-4 sm:!mt-10 px-4 sm:!px-0"
  >
    <div
      class="flex"
    >
      <span class="flex-1 text-gray-500">
        {{
          $t("Feedback of", {
            participant: `${participant.firstname} ${participant.lastname}`,
          })
        }}
      </span>

      <span class="flex-1 self-end text-right text-gray-500">
        {{ $t("Deadline") }} :
        {{ $t(campaign.deadline ? campaign.deadline : "No deadline") }}
      </span>
    </div>

    <div
      class="my-8 break-words w-full description"
      v-html="interviewForm.description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="640"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          interviewForm.video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <div class="flex flex-col gap-2 sm:!gap-8">
      <component
        :is="question.componentName"
        v-for="(question, idx) in questionsComponent"
        :key="idx"
        :loop-idx="idx"
        :question="question.data"
      />
    </div>
  </div>
</template>

<script>
import { useFeedbackStore } from "@/store/feedback";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import ChapterAnswer from "@/components/interviewApp/feedback_answers/ChapterAnswer.vue";
import OpenQuestionAnswer from "@/components/interviewApp/feedback_answers/OpenQuestionAnswer.vue";
import ParagraphAnswer from "../interview_answers/ParagraphAnswer.vue";
import RatingAnswer from "@/components/interviewApp/feedback_answers/RatingAnswer.vue";
import McqAnswer from "@/components/interviewApp/feedback_answers/McqAnswer.vue";
import { InterviewQuestionType } from '@/constants/types';

export default {
  components: {
    ChapterAnswer,
    ParagraphAnswer,
    OpenQuestionAnswer,
    RatingAnswer,
    McqAnswer,
  },
  setup() {
    const { questions, campaign, participant, interviewForm } = storeToRefs(
        useFeedbackStore()
    );

    const isValidLink = computed(() => {
      return interviewForm.value.video && interviewForm.value.video.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return questions.value.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER)
          componentName = "chapter-answer";
        else if (question.type === InterviewQuestionType.OPEN)
          componentName = "open-question-answer";
        else if (question.type === InterviewQuestionType.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question.type === InterviewQuestionType.RATING)
          componentName = "rating-answer";
        else if (question.type === InterviewQuestionType.MCQ) componentName = "mcq-answer";

        return { data: question, componentName };
      });
    });

    return {
      questions,
      participant,
      campaign,
      questionsComponent,
      interviewForm,
      isValidLink
    };
  },
};
</script>