<template>
  <div class="flex flex-col gap-4 p-0 sm:!p-4">
    <div class="flex items-center !w-full mt-8 gap-4 sm:p-0">
      <v-text-field
        v-model="searchText"
        class="grow w-[90%] input"
        color="primary"
        density="compact"
        variant="outlined"
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
        @update:model-value="updateText"
      />

      <svn-filter
        v-if="headers && tab === 'current'"
        :table-name="tableName"
        :filter-columns="filterList"
        :show-results="showResults"
        :selected-filters="getActiveFiltersCount()"
        @add-filter="addFilter"
        @activator-clicked="displayOrHideFilterResulsts"
        @get-filter-columns="getFilterColumns"
      />

      <bkt-learn-list-settings
        :content-values="settingsOnAllItems"
      />
    </div>

    <svn-filter-results
      v-if="tab === 'current'"
      :data="campaigns"
      :table-name="tableName"
      :headers="headers"
      :show-results="showResults"
      :filter-list="filterList"
      :selected-filters="selectedFilters"
      :show-filter-resulsts="showFilterResulsts"
      @add-filter="addFilter"
      @delete-filter="deleteFilter"
      @clear-selected-filters="clearSelectedFilters"
      @remove-empty-filter="removeEmptyFilter"
      @update-selected-data-filters="updateSelectedDataFilters"
    />
  </div>

  <!-- Modal Deleted Items to CUSTOMIZE -->
  <modal-view-deleted-items
    ref="deletedItems"
    content-type="campaign"
    :contents="trashedCampaigns"
    @toggle-search="emit('search-items', $event)"
    @restore="emit('restore', $event)"
    @view-item="router.push({ name: 'campaign_show', params: { id: $event} })"
    @delete-permanently="emit('delete-permanently', $event)"
  />
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCampaignStore } from "@/store/campaign";
import { useFilterStore } from "@/store/filters";
import { debounce } from "lodash";
import { useRouter } from "vue-router";
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import i18n from "@/plugins/i18n";
import SvnFilter from "@/components/SvnFilter.vue";
import SvnFilterResults from "@/components/SvnFilterResults.vue";

const props = defineProps({
  pagination: { type: Object, default: () => {} },
  trashedCampaigns: { type: Object, required: true, default: () => {} },
  tab: { type: String, default: 'current' },
})

const emit = defineEmits(["fetch", "search-items", "restore", "delete-permanently"]);

const router = useRouter();
const { fetchCampaigns } = useCampaignStore();
const { fetchFilterMetadata } = useFilterStore();

const { columns } = storeToRefs(useFilterStore());
const { params, campaigns } = storeToRefs(useCampaignStore());

const status = ref("");
const tableName = ref("interview_app_campaigns")
const showResults = ref(false);
const filterList = ref([]);
const filterData = ref([]);
const selectedFilters = ref([]);
const deletedItems = ref(null);
const showFilterResulsts = ref(true);
const headers = ref([
  {
    name: "campaign_type",
    key: "campaign_type",
    title: i18n.global.t("Campaign type"),
    icon: "icon-park-outline:down-c",
  },
  {
    name: "title",
    key: "title",
    title: i18n.global.t("Campaign title"),
    icon: "ic:baseline-title",
  },
  {
    name: "startline",
    key: "startline",
    title: i18n.global.t("Period"),
    icon: "akar-icons:calendar",
  },
  {
    name: "employees_count",
    key: "employees_count",
    title: "Participants",
    icon: "mdi-account-outline",
  },
  {
    name: "completion",
    key: "completion",
    title: "Completion",
    icon: "material-symbols:percent",
  },
]);
const searchText = ref(params?.value?.title);

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", {
    status: status.value,
    title: searchText.value,
  });
}, 300)

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted campaigns'),
    onClick: openDeletedItems
  }
])

const getFilterColumns = async() => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(tableName?.value)
      for (let column of columns?.value) {
        let foundCol = headers?.value?.find(el => el?.name === column?.name)
        if (foundCol) {
          let newCol = { ...column, key: foundCol?.key, name: foundCol?.title, icon: foundCol?.icon || "" }
          filterList?.value?.push(newCol)
        }
      }
    } catch (error) {
      console.log('Error')
    }
  }
}

const addFilter = (item) => {
  selectedFilters.value.push({ ...item, active: false })
  showFilterResulsts.value = true
}

const removeEmptyFilter = (item) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === item?.id), 1)
}

const deleteFilter = async(id) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === id), 1)
  filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === id), 1)
  checkShowResulsts()

  if (filterData?.value?.length) {
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  } else {
    params.value = {}
  }

  await fetchCampaignsAfterFilter()
}

const clearSelectedFilters = async() => {
  selectedFilters.value = []
  showFilterResulsts.value = false
  showResults.value = false

  params.value = {}
  if (filterData?.value?.length) {
    filterData.value = []
    await fetchCampaignsAfterFilter()
  }
}

const updateSelectedDataFilters = async(data) => {
  let found = selectedFilters?.value?.find(el => el?.id === data?.id)
  let filter = filterData?.value?.find(element => element?.id === data?.event?.id)

  if (filter) {
    filter.operator = data?.event?.operator
    filter.value = data?.event?.value
  } else {
    filterData?.value?.push({
      id: data?.event?.id,
      name: data?.event?.name,
      operator: data?.event?.operator?.toLowerCase(),
      value: data?.event?.value,
    })
  }

  if (data?.event?.value?.length) {
    found.active = true
    checkShowResulsts()
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  }
  else {
    found.active = false
    checkShowResulsts()
    filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === data?.id), 1)
    if (filterData?.value?.length) {
      params.value = {
        filter_data: JSON.stringify(filterData?.value),
      }
    } else {
      params.value = {}
    }
  }

  await fetchCampaignsAfterFilter()
}

const getActiveFiltersCount = () => {
  return selectedFilters?.value?.filter(el => el.active === true).length
}

const displayOrHideFilterResulsts = () => {
  showFilterResulsts.value = !showFilterResulsts.value
}

const checkShowResulsts = () => {
  if (selectedFilters?.value?.filter(el => el.active === true).length) {
    showResults.value = true
  }
  else {
    showResults.value = false
  }
}

const fetchCampaignsAfterFilter = async() => {
  try {
    await fetchCampaigns()
  } catch (error) {
    console.log(error)
  }
}

watch(selectedFilters.value, (newValue, oldValue) => {
  if (newValue.length > 0) {
    showFilterResulsts.value = true
  }
  else {
    showFilterResulsts.value = false
  }
})
</script>

<style scoped>
.input :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>