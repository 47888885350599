<template>
  <div class="flex items-center self-stretch px-6 py-4 gap-6 border-b border-b-[#EAECF0]">
    <div class="flex flex-col sm:!flex-row flex-1 justify-center sm:!justify-start items-start sm:!items-center gap-3">
      <div class="flex items-center gap-3 self-stretch sm:!flex-1">
        <svn-avatar
          :avatar="user?.avatar"
          :firstname="user?.firstname"
          :lastname="user?.lastname"
          size="lg"
        />

        <div class="flex flex-col items-start">
          <svn-text
            sm
            medium
          >
            {{ user?.fullname }}
          </svn-text>

          <svn-text
            xs
            normal
            color="dark-grey"
          >
            {{ user?.email }}
          </svn-text>
        </div>
      </div>

      <div class="w-[120px] flex items-center gap-2">
        <div>
          <Icon
            icon="mdi:calendar-remove-outline"
            class="text-fake-black"
            width="16"
            height="16"
          />
        </div>

        <svn-text
          sm
          normal
        >
          {{ user?.disabled_at ? moment(user?.disabled_at).format("DD MMM, YYYY") : 'Not set' }}
        </svn-text>
      </div>
    </div>

    <svn-tooltip content-class="bg-dark-grey">
      <template #activator-content>
        <svn-loader v-if="isRestoring" />

        <svn-icon-button
          v-else
          circle
          variant="plain"
          icon="mdi:restore"
          color-class="!bg-transparent !text-dark-grey hover:!bg-very-light-grey active:!bg-light-grey"
          @click="restoreDeactivatedUser(user?.id)"
        />
      </template>

      <template #tooltip-content>
        <svn-text
          sm
          normal
          color="white"
        >
          {{ $t('Reactivate user') }}
        </svn-text>
      </template>
    </svn-tooltip>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import moment from 'moment';
import { Icon } from '@iconify/vue';

const props = defineProps({
  user: { type: Object, default: () => {} },
});

const emit = defineEmits(['restore-user']);

const isRestoring = ref(false);

const restoreDeactivatedUser = (userId) => {
  isRestoring.value = true;
  emit('restore-user', userId);
}
</script>

<style scoped>
</style>