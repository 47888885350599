<template>
  <div
    v-if="
      interviewKind === 'crossed'
        ? true
        : question.visible_for === interviewKind ||
          question.visible_for === 'all'
    "
    :id="question.id"
    :loading="question.isQuestionLoading"
    class="flex flex-col gap-8 border-[0.5px] border-middle-grey bg-white rounded-[12px] p-10"
    :class="interviewTouched && !findInterviewAnswer.answer
      && (question.required_for === interviewKind || question.required_for === 'all') ?
        '!border !border-[#FF5656]/100' : ''"
  >
    <div class="flex flex-col items-start gap-2 self-stretch relative">
      <p
        v-if="interviewTouched && !findInterviewAnswer.answer
          && (question.required_for === interviewKind || question.required_for === 'all')"
        class="mb-4 text-xs font-normal text-negativeRed self-end"
      >
        {{ $t('This question requires an answer.') }}
      </p>

      <svn-title h3 medium class="break-words w-full">
        {{ question.text }}
        {{
          question.required_for === interviewKind ||
            question.required_for === "all"
            ? "*"
            : ""
        }}
      </svn-title>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />
    </div>

    <div v-if="findEmployeeQuestion(question?.id) || findManagerQuestion(question?.id)" class="flex flex-col items-start gap-4 self-stretch">
      <interview-answer
        v-if="(interviewKind === 'manager' || interviewKind === 'crossed') && findEmployeeQuestion(question.id)"
        :user="employee"
        :question-type="question?.type"
        :rating-length="question.options"
        :answer="findEmployeeQuestion(question.id).answer"
        :comments="findEmployeeQuestion(question.id).comments"
      />

      <interview-answer
        v-if="interviewKind === 'manager' && findManagerQuestion(question.id)"
        :user="interviewer"
        :question-type="question?.type"
        :rating-length="question.options"
        :side="false"
        :answer="findManagerQuestion(question.id).answer"
        :comments="findManagerQuestion(question.id).comments"
      />

      <interview-answer
        v-else-if="interviewKind === 'crossed' && findManagerQuestion(question.id)"
        :user="interviewer"
        :question-type="question?.type"
        :side="false"
        :rating-length="question.options"
        :answer="findManagerQuestion(question.id).answer"
        :comments="findManagerQuestion(question.id).comments"
      />

      <interview-answer
        v-else-if="interviewKind === 'crossed' && findEmployeeQuestion(question.id) && findManagerQuestion(question.id)"
        :user="interviewer"
        :question-type="question?.type"
        :side="false"
        :rating-length="question.options"
        :answer="findManagerQuestion(question.id).answer"
        :comments="findManagerQuestion(question.id).comments"
      />
    </div>

    <v-rating
      v-if="!locked"
      :disabled="locked"
      :length="question.options"
      :model-value="findInterviewAnswer ? Number(findInterviewAnswer.answer) : null"
      color="primary"
      @update:model-value="updateValue('answer', $event)"
    />

    <v-textarea
      v-if="question?.allow_comments && !locked"
      :model-value="findInterviewAnswer.comments"
      :readonly="locked"
      hide-details
      variant="outlined"
      :label="$t('Comment')"
      @update:model-value="updateValue('comments', $event)"
    />

    <div
      v-if="findInterviewAnswer?.answer && locked && interviewKind !== 'manager'"
      class="w-full flex flex-col gap-2"
    >
      <p v-if="interviewKind === 'crossed'" class="text-fake-black text-base font-semibold leading-6">
        {{ $t('Cross review') }}
      </p>

      <interview-answer
        v-if="findInterviewAnswer?.answer && locked"
        :user="findInterviewAnswer?.user"
        :is-last-interview-completed-and-locked="isLastInterviewCompletedAndLocked"
        :question="question"
        :question-type="InterviewQuestionType.RATING"
        :rating-length="question.options"
        :answer="findInterviewAnswer.answer"
        :comments="findInterviewAnswer.comments"
      />

      <bkt-survey-comments
        v-if="locked && findInterviewAnswer && findInterviewAnswer?.messages?.length && campaign?.campaign_type === 'Survey'"
        :answer-interview-id="findInterviewAnswer.id"
        :answer-user="findInterviewAnswer.user"
        :comments="findInterviewAnswer.messages"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import InterviewAnswer from "../InterviewAnswer.vue";
import { useInterviewStore } from "@/store/interview.js";
import { computed } from "vue";
import { debounce } from "lodash";
import { InterviewQuestionType } from '@/constants/types';
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";

const {
  interviewKind,
  campaign,
  isLastInterviewCompletedAndLocked,
  interviewAnswers,
  locked,
  employee,
  interviewer,
  employeeAnswers,
  managerAnswers,
  interviewTouched
} = storeToRefs(useInterviewStore());
const { updateInterviewAnswer } = useInterviewStore();

const props = defineProps(["question", "loopIdx"]);

const findInterviewAnswer = computed(() => {
  return (
    interviewAnswers.value.find(
      (answer) => answer.question_id === props.question.id
    ) || {}
  );
});

const updateValue = debounce((key, value) => {
  findInterviewAnswer.value[key] = value;
  updateRemote(key, value);
}, 400)

const updateRemote = (key) =>
  updateInterviewAnswer(props.loopIdx, props.question.id, {
    [key]: findInterviewAnswer.value[key],
});

const findEmployeeQuestion = (questionId) => {
  return employeeAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const findManagerQuestion = (questionId) => {
  return managerAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const setCrossDefaultAnswer = () => {
  let managerAnswers = findManagerQuestion(props.question.id);
  let employeeAnswers = findEmployeeQuestion(props.question.id);

  if (!findInterviewAnswer.value.answer) {
    findInterviewAnswer.value.answer = managerAnswers
      ? managerAnswers.answer
      : employeeAnswers
      ? employeeAnswers.answer
      : null;

    updateRemote("answer", findInterviewAnswer.value.answer);
  }

  if (!findInterviewAnswer.value.comments) {
    findInterviewAnswer.value.comments = managerAnswers
      ? managerAnswers.comments
      : employeeAnswers
      ? employeeAnswers.comments
      : null;

    updateRemote("comments", findInterviewAnswer.value.comments);
  }
};

if (interviewKind.value === "crossed" && !locked.value) {
  setCrossDefaultAnswer();
}
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}
</style>