<template>
  <div class="w-full flex flex-col gap-4 pt-4">
    <div class="w-full px-2">
      <v-combobox
        id="combobox"
        variant="outlined"
        :items="tagSearch === '' ? tagCategories : searchedTagsNames"
        :placeholder="$t('Category...')"
        item-value="tagName"
        density="compact"
        :item-title="(item) => { return item.tagName; }"
        prepend-inner-icon="mdi-magnify"
        color="primary"
        @input="updateTagSearch"
      >
        <template #item="{ item }">
          <!-- Mobile view -->
          <div v-if="isMobile">
            <div
              class="bg-white w-[300px] max-h-[300px] overflow-y-auto"
              @click="openDrawer(item?.raw)"
            >
              <v-list-item
                class="cursor-pointer bkt-bg-light-grey10-hover"
              >
                <v-list-item-title class="text-darkGrey">
                  {{ item?.raw?.name }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </div>

          <!-- Desktop view -->
          <div v-else>
            <v-menu
              v-if="tagSearch === ''"
              location="end"
              content-class="max-h-[280px] rounded-xl"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  class="cursor-pointer bkt-bg-light-grey10-hover"
                  open
                >
                  <v-list-item-title class="text-darkGrey">
                    {{ item?.raw?.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <div class="bg-white border border-darkGrey w-[300px] max-h-[300px] ml-4 overflow-y-auto">
                <v-list-item
                  v-for="(element, index) in item?.raw?.tags"
                  :key="index"
                  class="h-[50px] py-0 sm:hover:cursor-pointer hover:bg-[#F6F6F6]"
                >
                  <div
                    class="flex flex-row justify-start items-center"
                    @click.stop="toggleTagSelection(element)"
                  >
                    <Icon
                      :icon="selectedTagsList?.find(tag => tag.id === element.id) !== undefined ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                      color="darkGrey"
                      width="18"
                      height="18"
                    />

                    <p class="font-normal text-darkGrey text-base ml-2">
                      {{ element?.tagName }}
                    </p>
                  </div>
                </v-list-item>
              </div>
            </v-menu>

            <v-menu
              v-else
              location="end"
              class="max-h-[280px]"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  class="cursor-pointer bkt-bg-light-grey10-hover"
                >
                  <v-list-item-title
                    class="flex flex-row justify-start items-center text-darkGrey"
                    @click.stop="toggleTagSelection(item?.raw)"
                  >
                    <Icon
                      :icon="selectedTagsList?.find(tag => tag.id === item?.raw?.id) !== undefined ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                      color="darkGrey"
                      width="18"
                      height="18"
                    />

                    <p class="font-normal text-darkGrey text-base ml-2">
                      {{ item?.raw?.tagName }}
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-menu>
          </div>
        </template>

        <template #append-item>
          <v-list-item
            class="cursor-pointer bkt-bg-light-grey10-hover border-t border-t-darkGrey"
            @click="toggleAllTagsSelection"
          >
            <p class="text-darkGrey font-normal text-base">
              {{ $t('All categories') }}
            </p>
          </v-list-item>
        </template>
      </v-combobox>
    </div>

    <div class="flex flex-col items-start px-2 gap-2">
      <div class="flex flex-col items-start self-stretch">
        <p class="text-fakeBlack text-xs font-medium">
          {{ $t("Selected participants : ") }} {{ usersSelected }}
        </p>

        <p class="text-darkGrey text-xs font-normal">
          {{ $t("All employees in the categories below") }}
        </p>
      </div>

      <div class="w-full mx-auto">
        <div
          v-if="selectedTagsList?.length === 0"
          class="flex flex-col justify-center items-center py-20"
        >
          <Icon
            icon="noto-index-pointing-up"
            width="32"
            height="32"
          />

          <p class="mt-5 font-medium text-sm text-fakeBlack text-center max-w-[287px] lg:max-w-[351px]">
            {{ $t('Hmm, it seems you have not selected a category yet. Search for a category in the search bar above.') }}
          </p>
        </div>

        <div
          v-else
          class="flex flex-col gap-2 items-start"
        >
          <div
            v-if="selectedTagsList?.length !== allTagsList.length"
            class="w-full flex flex-wrap gap-2"
          >
            <div
              v-for="(tag, index) in selectedTagsList"
              :key="index"
              variant="outlined"
              class="flex flex-row justify-center items-center h-full bg-veryLightGrey rounded-[8px] border border-darkGrey px-2 py-[6px] cursor-pointer"
            >
              <p class="mr-2 text-xs text-darkGrey">
                {{ tag.tagName }}
              </p>

              <div
                @click="toggleTagSelection(tag)"
              >
                <Icon
                  icon="mdi-close"
                  width="16"
                  height="16"
                  color="darkGrey"
                />
              </div>
            </div>
          </div>

          <div
            v-else
            class="flex flex-row justify-center items-center self-center bg-veryLightGrey rounded-[8px] border border-darkGrey px-2 py-[6px] cursor-pointer"
          >
            <p class="mr-2 text-xs text-darkGrey">
              {{ $t('All categories') }}
            </p>

            <div
              @click="toggleAllTagsSelection"
            >
              <Icon
                icon="mdi-close"
                width="16"
                height="16"
                color="darkGrey"
              />
            </div>
          </div>

          <v-btn
            v-if="selectedTagsList?.length >= 1"
            class="normal-case"
            variant="text"
            color="primary"
            :text="$t('Clear all')"
            @click="clearAllSelectedTags"
          />
        </div>
      </div>
    </div>
  </div>

  <v-navigation-drawer
    v-if="isMobile"
    v-model="drawer"
    :width="drawerWidth"
    location="bottom"
    class="h-screen"
  >
    <modal-window-selection-by-tags-mobile
      :tag-category="selectedTag"
      :selected-tags-list="selectedTagsList"
      @toggle-tag-selection="toggleTagSelection"
      @close-drawer="closeDrawer"
    />
  </v-navigation-drawer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import {useCompanyStore } from "@/store/company.js";
import { ref, onMounted } from "vue";
import { useMobileStore } from "@/store/mobile";
import axiosService from "@/tools/axios-service.js";
import { useLearnTrainingStore } from '@/store/learn-trainings';
import ModalWindowSelectionByTagsMobile from "@/components/BktPopUp/Modals/learn/ModalWindowSelectionByTagsMobile.vue";

const { tagCategories } = storeToRefs(useCompanyStore());
const { isMobile } = storeToRefs(useMobileStore())
const { fetchCompany } = useCompanyStore();
const { learnTraining } = storeToRefs(useLearnTrainingStore())

const allTagsList = ref([]);
const searchedTagsNames = ref([]);
const selectedTagsList = ref([]);
const tagSearch = ref('');
const tagCategoriesNames = ref(null);
const usersSelected = ref(null)
const drawerWidth = ref(null);
const drawer = ref (false);
const selectedTag = ref(null);
const selectMenu = ref(false);

const emit = defineEmits(['update-participant-tags'])

const props = defineProps({
  participants: { type: Array, default: null },
})

onMounted(async() => {
  fetchCompany();

  drawerWidth.value = window.innerWidth;

  tagCategoriesNames.value = tagCategories.value.map((tagCategory) => tagCategory.name );

  tagCategories.value.forEach((tagCategory) => allTagsList.value.push(...tagCategory.tags));
  tagCategories.value.forEach((tagCategory) => {
    tagCategory.tags.forEach(tag => {
      if ( learnTraining.value.participant_filter_tag_ids.includes(tag.id)) {
        selectedTagsList.value.push(tag)
      }
    })
  } )

  updateTagsRequest();
})

const updateTagsRequest = async() => {
  try {
    const { data } = await axiosService.get('/api/v1/users/filter_by_tag_categories', {
      params: {
        tag_ids: selectedTagsList.value.map((tag) => { return tag?.id }),
      }
    })
    usersSelected.value = data.users;
  } catch (error) {
    console.log(error);
  }
}

const toggleTagSelection = (element) => {
  const found = selectedTagsList.value?.find(tag => tag.id === element.id);

  if (found) {
    selectedTagsList.value?.splice(selectedTagsList.value?.findIndex((tag) => tag.id === element.id), 1);
  }
  else {
    selectedTagsList.value?.push(element);
  }

  updateParticipantFilterTagIds(selectedTagsList.value)
  updateTagsRequest();
}

const toggleAllTagsSelection = () => {
  if (selectedTagsList.value?.length !== allTagsList.value.length) {
    selectedTagsList.value = allTagsList.value.slice();
  }
  else {
    selectedTagsList.value = [];
  }

  updateParticipantFilterTagIds(selectedTagsList.value)
  updateTagsRequest();
}

const clearAllSelectedTags = () => {
  selectedTagsList.value = [];
  updateParticipantFilterTagIds(selectedTagsList.value)
  updateTagsRequest();
}

const updateTagSearch = (e) => {
  tagSearch.value = e.target.value;

  allTagsList.value.forEach((tag) => {
    if ((tag.tagName).startsWith(tagSearch.value)) {
      searchedTagsNames.value = allTagsList.value.filter((element) => (element.tagName).startsWith(tagSearch.value))
    }
  })
}

const openDrawer = (tagCategory) => {
  document.getElementById('combobox').blur()

  selectedTag.value = tagCategory

  drawer.value = !drawer.value
}

const closeDrawer = () => {
  drawer.value = false

  document.getElementById('combobox').focus()

  selectMenu.value = !selectMenu.value
}

const updateParticipantFilterTagIds = (tags) => {
  emit("update-participant-tags", tags.map(tag => tag.id))
}
</script>