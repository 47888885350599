<template>
  <pop-up-skeleton>
    <template #body>
      <div>
        <svn-date-picker
          v-model="currentStartline"
        />
      </div>
    </template>

    <template #buttons>
      <div class="w-full flex justify-center mt-8">
        <v-dialog v-model="launchDialog">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              color="primary"
              type="interview"
              class="w-full sm:w-[150px] h-14"
              @click="checkStartline"
            >
              {{ $t("Save") }}
            </v-btn>
          </template>

          <template #default>
            <v-card
              width="400"
              location="center"
              class="!rounded-[12px]"
            >
              <div class="flex flex-col p-6 justify-center items-center">
                <div class="flex flex-col space-y-4 mb-2">
                  <div class="self-center">
                    <Icon
                      icon="noto:warning"
                      size="32"
                    />
                  </div>

                  <div>
                    <p class="text-fakeBlack text-xl font-medium !mt-0">
                      {{ $t('Launch your campaign now ?') }}
                    </p>
                  </div>
                </div>

                <p class="text-darkGrey text-sm font-normal">
                  {{ $t('The date selected is earlier than the current date. Your campaign will therefore be launched now.') }}
                </p>

                <div
                  class="w-full flex flex-col justify-center sm:flex-row gap-x-3 px-1.5 mt-8"
                >
                  <v-btn
                    variant="outlined"
                    color="black"
                    class="text-fakeBlack border-darkGrey border-1 normal-case font-normal w-full sm:w-1/2 text-xs mb-3 sm:!mb-0"
                    @click="launchDialog = false"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>

                  <v-btn
                    variant="outlined"
                    class="text-white bg-primary normal-case font-normal w-full sm:w-1/2 text-xs"
                    color="blue"
                    @click="updateStartline"
                  >
                    {{ $t('Launch now') }}
                  </v-btn>
                </div>
              </div>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { usePopUpStore } from "../../store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import axios from "../../tools/axios-service.js";
import filters from "../../tools/filters.js";
import { useSnackbar } from "@/store/snackbar";
import { storeToRefs } from "pinia";
import moment from "moment";

const { closePopUp } = usePopUpStore();

const snackbar = useSnackbar();

const launchDialog = ref(false);

const { data } = storeToRefs(usePopUpStore());

const props = defineProps({
  campaignId: { type: String, default: null },
  startline: { type: String, default: null },
});
const currentStartline = ref(null);

const date = moment();

let today = date.format("YYYY-MM-DD");

currentStartline.value = props.startline ? props.startline?.split("/").reverse().join("-") : data?.value?.startline?.split("/").reverse().join("-");

const checkStartline = () => {
  if(currentStartline.value <= today) {
    launchDialog.value = true;
  }
  else {
    launchDialog.value = false;
    updateStartline();
  }
};

const updateStartline = async () => {
  const year = filters.formatDate(currentStartline.value, "YYYY");
  const month = filters.formatDate(currentStartline.value, "M");
  const day = filters.formatDate(currentStartline.value, "DD");

  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${props.campaignId ? props.campaignId : data?.value?.startline}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day,
      },
    });

    snackbar.setBgColor('fakeBlack');
    snackbar.setMsg(currentStartline.value === today ? 'The campaign start date been edited and your campaign has been launched.' : 'The campaign start date been edited.');
    snackbar.displaySnackBar();
  }
  catch (e) {
    snackbar.setBgColor('negativeRed');
    snackbar.setMsg('Error updating starting date !');
    snackbar.displaySnackBar();
  }
  finally {
    closePopUp();
  }
}
</script>