<template>
  <div class="w-full flex flex-col justify-center items-start self-stretch rounded-[8px] border-[0.5px] border-lightGrey shadow">
    <!-- Training participants -->
    <div class="w-full flex flex-col justify-center items-start p-4 gap-4 md:!flex-row md:!items-center">
      <p class="text-fakeBlack text-xl font-medium md:!flex-1">
        {{ $t('Participants list') }}
      </p>

      <!-- Search Bar -->
      <div class="w-full md:!w-[25%]">
        <my-trainings-search
          @fetch="reloadAfterSearch"
        />
      </div>
    </div>

    <!-- Partcipants table -->
    <v-data-table
      :headers="headers"
      :loading="trainingParticipantsList ? false : true"
      :items="trainingParticipantsList"
      :items-per-page="-1"
      class="-mt-[56px] md:!mt-0"
    >
      <template #item="{ item }">
        <tr>
          <td class="w-[88px] border-r border-r-darkGrey px-0 md:!w-[35%] md:border-r-0">
            <div class="w-full flex flex-col justify-center items-center gap-2 self-stretch md:!flex-row md:!justify-start md:!gap-3 md:!items-start md:!px-4 md:!py-2">
              <svn-avatar
                :avatar="item?.raw?.user?.avatar?.['30']"
                :firstname="item?.raw?.user?.firstname"
                :lastname="item?.raw?.user?.lastname"
                size="lg"
              />

              <div class="flex flex-col text-center px-1 items-center md:!self-center">
                <p class="text-xs font-medium text-fakeBlack line-clamp-2 md:!line-clamp-1">
                  {{ item.raw?.user?.fullname }}
                </p>
              </div>
            </div>
          </td>

          <td class="px-0 md:!px-6 md:!py-2 md:!w-[20%]">
            <div class="flex flex-col items-start flex-1">
              <div v-if="isMdScreen">
                <my-trainings-completion-indicator
                  :acquired="item?.raw?.module_completion"
                  :total="item?.raw?.module_count"
                />
              </div>

              <div
                v-else
                class="flex flex-col py-2 items-center gap-2 self-stretch"
              >
                <div class="flex flex-col items-center text-center">
                  <p class="text-darkGrey text-xs font-normal">
                    {{ $t('Last module acquired on :') }}
                  </p>

                  <p class="text-fakeBlack text-xs font-medium">
                    {{ item?.raw?.last_module_acquired_on ? moment(item?.raw?.last_module_acquired_on?.updated_at).format('DD MMM YYYY h:mm a') : '-' }}
                  </p>
                </div>

                <v-divider
                  :thickness="2"
                  class="w-full border-opacity-100 border border-lightGrey"
                />

                <div class="flex flex-col items-center text-center">
                  <p class="text-darkGrey text-xs font-normal">
                    {{ $t('Training acquired on :') }}
                  </p>

                  <p class="text-fakeBlack text-xs font-medium">
                    {{ item?.raw?.training_acquired_on ? moment(item?.raw?.training_acquired_on?.updated_at).format('DD MMM YYYY h:mm a') : '-' }}
                  </p>
                </div>

                <v-divider
                  :thickness="2"
                  class="w-full border-opacity-100 border border-lightGrey"
                />

                <div class="flex justify-center items-center gap-2 self-stretch">
                  <p class="text-darkGrey text-xs font-normal">
                    {{ $t('Modules acquired :') }}
                  </p>

                  <my-trainings-completion-indicator
                    :acquired="item?.raw?.module_completion"
                    :total="item?.raw?.module_count"
                  />
                </div>
              </div>
            </div>
          </td>

          <td
            v-if="isMdScreen"
            class="px-0 md:!w-[25%] px-4"
          >
            <div class="flex flex-col items-start flex-1">
              <p class="text-fakeBlack text-sm font-normal">
                {{ item?.raw?.last_module_acquired_on ? moment(item?.raw?.last_module_acquired_on?.updated_at).format('DD MMM YYYY h:mm a') : '-' }}
              </p>
            </div>
          </td>

          <td
            v-if="isMdScreen"
            class="px-0 md:!w-[20%] px-4"
          >
            <div class="flex flex-col items-start flex-1">
              <p class="text-fakeBlack text-sm font-normal">
                {{ item?.raw?.training_acquired_on ? moment(item?.raw?.training_acquired_on?.updated_at).format('DD MMM YYYY h:mm a') : '-' }}
              </p>
            </div>
          </td>
        </tr>
      </template>

      <template #loading>
        <div class="flex flex-col justify-center items-center">
          <svn-loader loading-size="xl" class="mt-8 mb-8" />
        </div>
      </template>

      <template #bottom>
        <v-pagination
          v-if="pagination && trainingParticipantsList && trainingParticipantsList.length > 0"
          :model-value="pagination.current_page"
          :length="pagination.total_pages"
          :total-visible="6"
          theme="primary"
          variant="text"
          class="p-5 !list-none"
          @update:model-value="fetchNewPage"
        >
          <template #prev>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
            >
              <Icon
                icon="mdi-arrow-left"
                width="20"
                height="20"
                class="mr-2"
              />

              <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
            </div>
          </template>

          <template #next>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(pagination?.current_page < pagination?.total_pages ?
                fetchNewPage(pagination?.current_page + 1) : '')"
            >
              <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>

              <Icon
                icon="mdi-arrow-right"
                width="20"
                height="20"
                class="ml-2"
              />
            </div>
          </template>
        </v-pagination>
      </template>

      <template #no-data>
        <div v-if="search.length" class="flex flex-col justify-center items-center h-[320px] gap-2">
          <Icon
            icon="noto-face-with-monocle"
            height="24"
            width="24"
          />

          <p class="font-medium text-sm mb-5">
            {{ $t("Hmm, it seems we can't find any results based on your search.") }}
          </p>
        </div>

        <div v-else class="flex flex-col justify-center items-center h-[320px] gap-2">
          <Icon
            icon="noto-winking-face"
            height="24"
            width="24"
          />

          <p v-if="!isDeleted" class="text-fakeBlack font-medium text-sm">
            {{ $t('Hmm, it seems you have no participants yet. Invite people by editing the training.') }}
          </p>

          <p v-else class="text-fakeBlack font-medium text-sm">
            {{ $t('Hmm, it seems you have no participants.') }}
          </p>

          <!-- Edit training Button -->
          <v-btn
            v-if="!isDeleted"
            variant="flat"
            color="trainingOrange"
            :text="$t('Edit training')"
            @click="goToTrainingEdit"
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import i18n from "@/plugins/i18n";
import MyTrainingsCompletionIndicator from "@/components/trainingApp/MyTrainingsCompletionIndicator.vue";
import MyTrainingsSearch from "@/components/trainingApp/myTrainings/MyTrainingsSearch.vue";
import { computed, ref } from "vue";
import moment from "moment/moment.js";
import { useRouter } from "vue-router";

const props = defineProps({
  trainingParticipantsList: { type: Array, required: true },
  isDeleted: { type: Array, default: () => [] },
})

const { isMdScreen } = storeToRefs(useMobileStore())
const emit = defineEmits(['get-training-participants'])
const search = ref('')
const headers = computed(() => {
  if (isMdScreen.value) {
    return [
      {
      align: "start",
      key: "participants",
      sortable: false,
      title: i18n.global.t('Participants'),
      },
      {
        align: "start",
        key: "modules_acquired",
        sortable: false,
        title: i18n.global.t('Modules acquired'),
      },
      {
        align: "start",
        key: "last_module_acquired_on",
        sortable: false,
        title: i18n.global.t('Last module acquired on'),
      },
      {
        align: "start",
        key: "training_acquired_on",
        sortable: false,
        title: i18n.global.t('Training acquired on'),
      },
    ]
  }
  else {
    return [
      {
        align: "start",
        key: "titles",
        sortable: true,
        title: '',
      },
      {
        align: "start",
        key: "modules",
        sortable: false,
        title: '',
      },
    ]
  }
})

const pagination = ref(null);

const router = useRouter()

const fetchNewPage = (e) => {

};

const reloadAfterSearch = async (text) => {
  search.value = text
  emit('get-training-participants', text)
}

const goToTrainingEdit = () => {
  router.push({ name: 'training_edit' })
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

.table :deep(thead) {
  display: none;
}
</style>
