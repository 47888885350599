<template>
  <div class="w-full">
    <div class="flex flex-col sm:flex-row gap-2">
      <bkt-search
        id="campaigns_search"
        v-model="searchText"
        class="w-full"
        @input="updateText"
        @clear="reset"
      />
      <v-select
        v-model="status"
        :items="selectList"
        item-title="display"
        item-value="value"
        class="sm:w-1/6"
        density="comfortable"
        variant="outlined"
        hide-details
        :label="$t('Completion')"
        @update:model-value="update"
      >
        <template
          #selection="{item}"
        >
          <div class="w-full truncate">
            {{ item.title }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import BktSearch from "../../../components/BktSearch.vue";
import { storeToRefs } from "pinia";
import { useCompanyStore } from "@/store/company.js";
import {useI18n} from "vue-i18n";

const emit = defineEmits(["fetch"]);
const props = defineProps({
  campaign: { type: Object, default: null },
  overview: { type: String, default: null },
  campaignOverview: { type: Object, required: true },
  set: { type: Array, required: true },
});
const { tagCategories } = storeToRefs(useCompanyStore());
const { t } = useI18n()

const employeeTags = ref([])
const filteredTagsCategory = ref([])
const status = ref("");
const searchText = ref();
const selectList = reactive([
  { display: t("All"), value: "" },
  { display: t("Not started"), value: "not_started" },
  { display: t("In progress"), value: "in_progress" },
  { display: t("Submitted"), value: "submitted" },
]);

const update = () => {
  emit("fetch", {
    from: props.overview,
    status: status.value,
    text: searchText.value,
  });
};
const reset = () => {
  searchText.value = null;
  document.getElementById("campaigns_search").querySelector("input").value = "";
  status.value = "";
  emit("fetch", {
    from: props.overview,
    text: searchText.value,
    status: status.value,
  });
};

let timer;

const updateText = (e) => {
  if (timer) clearTimeout(timer);

  timer = setTimeout(() => {
    searchText.value = e;
    emit("fetch", {
      from: props.overview,
      status: status.value,
      text: searchText.value,
    });
  }, 300);
};

onMounted(() => {
  switch(props.campaign?.campaign_type) {
    case "one_to_one":
      employeeTags.value = props.set?.map((interview) => {
        return interview.employee_interview?.employee?.tags;
      }).flat(1) || []
      break;
    case "feedback_360":
      employeeTags.value = props.set?.map((feedback) => {
        return feedback.participant?.tags;
      }).flat(1) || []
      break;
    case "survey":
      employeeTags.value = props.set?.map((survey) => {
        return survey.employee?.tags;
      }).flat(1) || []
      break;
  }

  if (tagCategories.value.length) {
    filteredTagsCategory.value = tagCategories.value.map(category => {
      return {
        id: category.id,
        name: category.name,
        tags: category?.tags?.filter(tag => employeeTags.value.some(t => t?.id === tag?.id))
      };
    });
  }
})
</script>

<style scoped>
* :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
