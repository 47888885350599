<template>
  <svn-center-content
    background-color-class="bg-deepBlack"
  >
    <template #centered-content>
      <div
        class="flex flex-col items-center justify-center gap-6 2xl:!gap-20 px-8 pt-8 pb-16 2xl:!px-0 2xl:!w-[732px]"
      >
        <bkt-logo-login />

        <svn-login-card>
          <template #box-content>
            <div class="flex flex-col items-start w-full">
              <div class="flex flex-col items-start gap-6 w-full">
                <div class="flex flex-col items-center md:items-start justify-center gap-2 w-full">
                  <p class="text-xl 2xl:!text-2xl font-semibold text-fakeBlack">
                    {{ $t('Create password') }}
                  </p>
                  <p class="text-darkGrey text-sm font-medium">
                    {{ $t('Please type and confirm your new password.') }}
                  </p>
                </div>
                <v-text-field
                  v-model="password"
                  :label="$t('Password')"
                  type="password"
                  variant="outlined"
                  class="w-full"
                  :error="passwordError.error"
                  :error-messages="passwordError.errorMessage"
                  :hide-details="passwordError.hide_details"
                  @keyup.enter="reset"
                  autocomplete="new-password"
                >
                  <template #message>
                    <div class="flex items-center gap-2 w-full ms-1 me-8">
                      <Icon
                        icon="material-symbols:info"
                        heigth="16"
                        width="16"
                      />
                      <p>{{ $t("Passwords fields aren't the same") }}</p>
                    </div>
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="passwordConfirmation"
                  label="Confirm password"
                  type="password"
                  variant="outlined"
                  class="w-full"
                  :error="passwordError.error"
                  :error-messages="passwordError.errorMessage"
                  :hide-details="passwordError.hide_details"
                  @keyup.enter="reset"
                  autocomplete="new-password"
                >
                  <template #message>
                    <div class="flex items-center gap-2 w-full ms-1 me-8">
                      <Icon
                        icon="material-symbols:info"
                        heigth="16"
                        width="16"
                      />
                      <p>{{ $t("Passwords fields aren't the same") }}</p>
                    </div>
                  </template>
                </v-text-field>

                <v-btn
                  class="w-full bkt-white mb-4"
                  theme="company"
                  color="primary"
                  height="40"
                  @click="reset"
                >
                  <p class="normal-case">{{ $t('Reset password') }}</p>
                </v-btn>
              </div>
            </div>
          </template>
        </svn-login-card>
      </div>
    </template>
  </svn-center-content>
</template>

<script setup>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useUserStore} from "@/store/user.js";
import axiosService from "@/tools/axios-service.js";
import {useSnackbar} from "@/store/snackbar.js";
import BktLogoLogin from '@/components/BktLogoLogin.vue';
import { Icon } from '@iconify/vue';

const snackbar = useSnackbar();
snackbar.checkStatus();

const {setStates} = useUserStore()
const router = useRouter()
const route = useRoute()
const password = ref()
const passwordConfirmation = ref()
const passwordError = ref({error: false, errorMessage: '', hide_details: true})

const reset = async () => {
  try {
    const token = route.query.reset_password_token

    if (!password.value || !passwordConfirmation.value) {
      snackbar.setDefaultColor('failed')
        .displaySnackBar('Fill the fields please')
      return
    } else if (!token) {
      snackbar.setDefaultColor('failed')
        .displaySnackBar('You have no token come back later')
      return
    } else if (password.value !== passwordConfirmation.value) {
      snackbar.setDefaultColor('failed')
        .displaySnackBar('Hey, password and password confirmation must be equal')
      passwordError.value = {error: true, errorMessage: "Wrong password", hide_details: false}
      return
    }

    try {
      await axiosService.resetPassConfirmation(token, password.value)

      router.push({name: 'check'})
      snackbar.setDefaultColor('success')
        .displaySnackBar("Your password has been reset")

    } catch (error) {
      snackbar.setDefaultColor('failed')
      .displaySnackBar(error)
    }

  } catch (e) {
    snackbar.setDefaultColor('failed')
      .displaySnackBar(e?.response?.data ? e.response?.data?.message : e.message)
  }
}
</script>

<style scoped>
.v-text-field :deep(.v-input__details) {
  padding-inline: 0px;

}

:deep(.v-field) {
  border-radius: 8px!important;
}
</style>
