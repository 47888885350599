<template>
  <div  class="flex flex-col w-full items-center gap-4">
    <div class="w-full flex justify-between items-center h-10">
      <div class="flex gap-4 items-center w-full">
      <span class="text-medium text-base">
        {{ String.fromCharCode(65 + (index % 26)) }}
      </span>

      <v-text-field
        v-model="questionOptions.proposal"
        :placeholder="index === 0 ? 'Option 1' : ''"
        :focused="true"
        hide-details
        :variant="variant"
        :onfocus="changeVariantToUnderlined"
        :onblur="changeVariantToPlain"
        density="compact"
        class="-mt-3 text-fakeBlack text-sm font-normal"
        @update:model-value="updateInput()"
      />
    </div>

    <v-spacer />

    <div class="flex items-center gap-3">
      <v-checkbox
        v-model="questionOptions.correct"
        color="primary"
        hide-details
        @update:model-value="updateInput"
      />

      <div
        class="h-6 w-6 text-darkGrey hover:text-fakeBlack cursor-pointer"
        @click="removeOption"
      >
        <Icon
          v-if="index"
          icon="material-symbols:close"
          class="h-6 w-6"
        />
      </div>
    </div>
    </div>
    <div v-if="checkInput">
      <p
        class="text-xs ml-2"
        style="color: #B3261E !important;"
      >
        {{ $t('unauthorized duplicate option') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps({
  index: { type: Number, required: true },
  checkInput: { type: Boolean, default: false },
  questionOptions: { type: Object, required: true },
})

const emit = defineEmits(['remove-option', 'update-input'])

const optionText = ref('')

const variant = ref('plain')

const removeOption = () => {
  emit('remove-option', props.questionOptions.proposal)
}

const changeVariantToUnderlined = () => {
  variant.value = 'underlined'
}

const changeVariantToPlain = () => {
  variant.value = 'plain'
}

const updateInput = () => {
  emit('update-input')
}
</script>