<template>
  <div
    v-if="question.type == LearnInputType.OPEN"
    :id="`question_${question?.id}`"
    class="flex p-6 md:p-10 flex-col items-start gap-4 md:gap-8 self-stretch border border-middle-grey rounded-[12px]"
  >
    <div class="w-full flex flex-col items-start gap-2 self-stretch">
      <svn-title h3 medium>
        {{ question?.title }}*
      </svn-title>

      <svn-text sm regular color="dark-grey">
        {{ question?.description }}
      </svn-text>
    </div>

    <v-textarea
      v-model="text"
      class="w-full"
      variant="outlined"
      :error="question?.error"
      :label="$t('Answer')"
      hide-details
      @update:model-value="updateAnswer"
    />
  </div>

  <div
    v-if="question?.type == LearnInputType.PARAGRAPH
      && question?.editor_content?.blocks?.length && question?.editor_content?.id"
    class="flex flex-col items-start self-stretch "
  >
    <!-- Rich text editor -->
    <svn-tiptap
      v-if="question?.editor_content?.blocks?.length"
      :create-image-url="`/api/v1/editor_contents/${question?.editor_content.id}fake-id/upload_image`"
      :html-data="question?.editor_content?.blocks"
      :extension-selection="AllTipTapPlugins"
      :extension-slash-command="AllTipTapPlugins"
      :is-editable="false"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import { useLearnModuleStore } from "@/store/learn-module";
import { LearnInputType } from '@/constants/types';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';

const { submission } = storeToRefs(useLearnModuleStore());

const props = defineProps({
  question: { type: Object, default: () => {} },
});

onMounted(() => {
  text.value = submission?.value?.learn_responses_answers?.find(input => input?.input_id == props?.question?.id)?.text
})

const emit = defineEmits(['update-question-answer'])

const text = ref(null);

const response = computed(() => {
  return submission?.value?.learn_responses_answers?.find(input => input?.input_id == props?.question?.id)
});

const updateAnswer = () => {
  let data = {
    text: text?.value,
    response_id: response?.value?.id,
  }
  emit('update-question-answer', data)
}

</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}
</style>