<template>
  <div class="w-full flex p-4 flex-col items-start gap-2 self-stretch border border-middle-grey rounded-[8px]">
    <div class="flex flex-col items-center gap-2 self-stretch">
      <div class="relative flex justify-center items-center gap-2 self-stretch">
        <svn-icon-button
          icon="ic:baseline-drag-indicator"
          variant="noOutlined"
          class="rotate-90 drag"
          button-size="lg"
        />

        <div class="flex justify-center items-start gap-2 absolute right-0">
          <svn-icon-button
            icon="mdi:content-copy"
            variant="noOutlined"
            button-size="lg"
            @click="duplicateQuestion"
          />

          <svn-icon-button
            icon="mdi:trash-can-outline"
            :variant="canRemoveQuestion ? 'noOutlined' : 'plain'"
            button-size="lg"
            :disable="!canRemoveQuestion"
            @click="removeQuestion"
          />
        </div>
      </div>

      <div class="flex flex-col items-center gap-4 self-stretch">
        <v-text-field
          v-model="input.title"
          autofocus
          color="primary"
          class="w-full"
          variant="outlined"
          density="compact"
          :label="$t('Question title')"
          hide-details
          @update:model-value="updateQuestion"
        />

        <v-textarea
          v-model="input.description"
          color="primary"
          class="w-full"
          variant="outlined"
          :label="'Description'"
          hide-details
          @update:model-value="updateQuestion"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  input: { type: Object, default: () => {} },
  canRemoveQuestion: { type: Boolean, default: true },
})

const emit = defineEmits(['update-question', 'duplicate-question', 'remove-question'])

const duplicateQuestion = () => {
  emit('duplicate-question');
}

const removeQuestion = () => {
  if (props.canRemoveQuestion) {
    emit('remove-question');
  }
}

const updateQuestion = () => {
  emit('update-question', props?.input);
}
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}
</style>