<template>
  <div class="w-full flex flex-col gap-4 p-4">
    <div class="flex flex-col sm:flex-row items-center justify-center !w-full gap-4 sm:p-0">
      <v-text-field
        v-model="searchText"
        variant="outlined"
        class="grow w-full text-dark-grey"
        color="primary"
        prepend-inner-icon="mdi-magnify"
        clearable
        hide-details="auto"
        :label="$t('Search')"
        @update:model-value="updateText"
      />

      <div class="w-full flex items-center justify-center gap-3">
        <v-select
          v-model="indicatorType"
          variant="outlined"
          density="comfortable"
          :items="indicatorTypeValues"
          item-title="text"
          item-value="value"
          class="w-full text-darkGrey"
          name="indicatorType"
          hide-details
          :placeholder="$t('Indicator')"
          @update:model-value="update"
        >
          <template #selection="{item}">
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>

        <svn-filter
          v-if="headers"
          :table-name="tableName"
          :filter-columns="filterList"
          :show-results="showResults"
          :selected-filters="getActiveFiltersCount()"
          @add-filter="addFilter"
          @activator-clicked="displayOrHideFilterResulsts"
          @get-filter-columns="getFilterColumns"
        />

        <div class="hidden sm:block">
          <svn-dots-button
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="archive_selected"
                :disabled="!selectedUsers?.length"
                @click="openPopUpArchive"
              >
                {{ $t('Archive selected targets', { selected: selectedUsers?.length || 0 }) }}
              </v-list-item>

              <v-list-item
                value="delete_selected"
                class="text-negativeRed"
                :disabled="!selectedUsers?.length"
                @click="openPopUpDelete"
              >
                {{ $t('Delete selected targets', { selected: selectedUsers?.length || 0 }) }}
              </v-list-item>

              <v-list-item
                value="view_deleted"
                @click="openDeletedItems"
              >
                {{ $t('View deleted targets') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>
    </div>

    <svn-filter-results
      :data="roadmapTargets"
      :table-name="tableName"
      :headers="headers"
      :show-results="showResults"
      :filter-list="filterList"
      :selected-filters="selectedFilters"
      :show-filter-resulsts="showFilterResulsts"
      @add-filter="addFilter"
      @delete-filter="deleteFilter"
      @clear-selected-filters="clearSelectedFilters"
      @remove-empty-filter="removeEmptyFilter"
      @update-selected-data-filters="updateSelectedDataFilters"
    />
  </div>

  <modal-view-deleted-items
    ref="deletedItems"
    content-type="target"
    :has-user="true"
    :contents="trashesTargets"
    @toggle-search="emit('search-items', $event)"
    @restore="emit('restore', $event)"
    @delete-permanently="emit('delete-permanently', $event)"
    @view-item="router.push({ name: 'roadmaps_targets_show', params: { id: $event}, query: { from: 'roadmaps_targets' } })"
  />
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoadmapTargetStore } from "@/store/roadmap-target";
import { debounce } from "lodash";
import { useFilterStore } from "@/store/filters";
import i18n from "@/plugins/i18n.js";
import { useSnackbar } from "@/store/snackbar";
import { usePopUpStore } from "@/store/pop-up";
import axios from "axios";
import { onMounted } from "vue";
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import { useRouter } from "vue-router";
import SvnFilter from "@/components/SvnFilter.vue";
import SvnFilterResults from "@/components/SvnFilterResults.vue";

onMounted(() => {
  indicatorType.value = params?.value?.indicator_type
  indicatorStatus.value = params?.value?.indicator_status
  from.value = params?.value?.from
  to.value = params?.value?.to
})

const props = defineProps({
  trashesTargets: { type: Object, required: true }
})

const emit = defineEmits(["fetch", "update-table", "search-items", "restore", "delete-permanently"]);

const router = useRouter();
const { openPopUp, closePopUp } = usePopUpStore();
const { fetchRoadmapTargets } = useRoadmapTargetStore();
const { fetchFilterMetadata } = useFilterStore();

const { columns } = storeToRefs(useFilterStore());
const { params, selectedUsers } = storeToRefs(useRoadmapTargetStore());
const { roadmapTargets } = storeToRefs(useRoadmapTargetStore());

const searchText = ref(params.value.title);
const indicatorType = ref(null);
const indicatorStatus = ref(null);
const snackbar = useSnackbar();
const from = ref("");
const to = ref("");
const tableName = ref("objective_elements")
const deletedItems = ref(null)
const showResults = ref(false);
const filterList = ref([]);
const filterData = ref([]);
const selectedFilters = ref([]);
const showFilterResulsts = ref(true);
const headers = ref([
  {
    name: "title",
    key: "title",
    title: i18n.global.t("Targets"),
    icon: "",
    sortable: false,
  },
  {
    name: "objectivable_id",
    key: "objectivable_id",
    title: i18n.global.t("Users"),
    icon: "",
    sortable: false,
  },
  {
    name: "completion",
    key: "completion",
    title: i18n.global.t("Completion"),
    icon: "",
    sortable: false,
  },
  {
    name: "due_date",
    key: "due_date",
    title: i18n.global.t("Deadline"),
    icon: "",
    sortable: false,
  },
]);

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", {
      title: searchText.value,
      indicator_type: indicatorType.value,
      indicator_status: indicatorStatus.value,
      from: from.value,
      to: to.value,
  });
}, 300)

const update = () => {
  emit("fetch", {
    title: searchText.value,
    indicator_type: indicatorType.value,
    indicator_status: indicatorStatus.value,
    from: from.value,
    to: to.value,
  });
};

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const indicatorTypeValues = [
  {
    text: i18n.global.t('All'),
    value: ''
  },
  {
    text: i18n.global.t('True/False'),
    value: 'boolean'
  },
  {
    text: i18n.global.t('Numeric Value'),
    value: 'numeric_value'
  },
  {
    text: i18n.global.t('Percentage'),
    value: 'percentage'
  },
  {
    text: i18n.global.t('Multi choice'),
    value: 'multi_choice'
  },
];

const indicatorStatusValues = [
  {
    text: i18n.global.t('All'),
    value: ''
  },
  {
    text: i18n.global.t('Completed'),
    value: 'completed'
  },
  {
    text: i18n.global.t('In progress'),
    value: 'in_progress'
  },
  {
    text: i18n.global.t('Not Completed'),
    value: 'uncompleted'
  }
];

const openPopUpArchive = () => {
  openPopUp({
    componentName: "pop-up-validation",
    title: "Are you sure you want to archive this/these target(s) ?",
    subtitle: "You will find this/these target(s) in the 'Archived targets' tab.",
    icon: "ic-outline-archive",
    textClose: "No, cancel",
    textConfirm: "Yes, archive",
    color: "blue",
    customClass: "",
    async confirm() {
      for (const el of selectedUsers.value) {
        try {
          await axios.put(`/api/v1/roadmaps/targets/${el}`, { status: "archived" });
          snackbar.setBgColor("fakeBlack").displaySnackBar("Your target(s) has/have been archieved successfully.");
        } catch (error) {
          snackbar.setBgColor("negativeRed").displaySnackBar("Error while archiving your targets.");
        }
      }
      emit('update-table')
      closePopUp();
    },
  });
}

const openPopUpDelete = () => {
  openPopUp({
    componentName: "pop-up-validation",
    title: selectedUsers?.value?.length === 1 ? "Are you sure you want to delete this target ?" :
      `Are you sure you want to delete these ${selectedUsers?.value?.length} targets ?`,
    subtitle: 'This is a permanent action.',
    icon: "mdi-trash-can-outline",
    textClose: "No",
    textConfirm: selectedUsers?.value?.length === 1 ? "Yes, delete this target" :
      "Yes, delete these targets",
    color: "red",
    customClass: "",
    async confirm() {
      for (const el of selectedUsers.value) {
        try {
          await axios.delete(`/api/v1/roadmaps/targets/${el}`);
          snackbar.setBgColor("fakeBlack").displaySnackBar("Your target(s) has/have been deleted.");
        } catch (error) {
          snackbar.setBgColor("negativeRed").displaySnackBar("Error while deleting your target(s) !");
        }
      }
      emit('update-table')
      closePopUp();
    },
  });
}

const getFilterColumns = async() => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(tableName?.value)
      for (let column of columns?.value) {
        let foundCol = headers?.value?.find(el => el?.name === column?.name)
        if (foundCol) {
          let newCol = { ...column, key: foundCol?.key, name: foundCol?.title, icon: foundCol?.icon || "" }
          filterList?.value?.push(newCol)
        }
      }
    } catch (error) {
      console.log('Error')
    }
  }
}

const addFilter = (item) => {
  selectedFilters.value.push({ ...item, active: false })
  showFilterResulsts.value = true
}

const removeEmptyFilter = (item) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === item?.id), 1)
}

const deleteFilter = async(id) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === id), 1)
  filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === id), 1)
  checkShowResulsts()

  if (filterData?.value?.length) {
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  } else {
    params.value = {}
  }

  await fetchRoadmapTargetsAfterFilter()
}

const clearSelectedFilters = async() => {
  selectedFilters.value = []
  filterData.value = []
  showFilterResulsts.value = false
  showResults.value = false

  params.value = {}
  await fetchRoadmapTargetsAfterFilter()
}

const updateSelectedDataFilters = async(data) => {
  let found = selectedFilters?.value?.find(el => el?.id === data?.id)
  let filter = filterData?.value?.find(element => element?.id === data?.event?.id)

  if (filter) {
    filter.operator = data?.event?.operator
    filter.value = data?.event?.value
  } else {
    filterData?.value?.push({
      id: data?.event?.id,
      name: data?.event?.name,
      operator: data?.event?.operator?.toLowerCase(),
      value: data?.event?.value,
    })
  }

  if (data?.event?.value?.length) {
    found.active = true
    checkShowResulsts()
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  }
  else {
    found.active = false
    checkShowResulsts()
    filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === data?.id), 1)
    if (filterData?.value?.length) {
      params.value = {
        filter_data: JSON.stringify(filterData?.value),
      }
    } else {
      params.value = {}
    }
  }

  await fetchRoadmapTargetsAfterFilter()
}

const getActiveFiltersCount = () => {
  return selectedFilters?.value?.filter(el => el.active === true).length
}

const displayOrHideFilterResulsts = () => {
  showFilterResulsts.value = !showFilterResulsts.value
}

const checkShowResulsts = () => {
  if (selectedFilters?.value?.filter(el => el.active === true).length) {
    showResults.value = true
  }
  else {
    showResults.value = false
  }
}

const fetchRoadmapTargetsAfterFilter = async() => {
  try {
    await fetchRoadmapTargets()
  } catch (error) {
    console.log(error)
  }
}

watch(selectedFilters.value, (newValue, oldValue) => {
  if (newValue.length > 0) {
    showFilterResulsts.value = true
  }
  else {
    showFilterResulsts.value = false
  }
})
</script>

<style scoped>
* :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>