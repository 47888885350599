<template>
  <div class="flex flex-col gap-[15px]">
    <div class="flex flex-col gap-2">
      <div class="relative flex justify-center items-center gap-2 self-stretch">
        <svn-icon-button
          icon="ic:baseline-drag-indicator"
          variant="noOutlined"
          class="rotate-90 drag"
          button-size="lg"
        />

        <div class="flex justify-center items-start gap-2 absolute right-0">
          <svn-icon-button
            icon="mdi:content-copy"
            variant="noOutlined"
            button-size="lg"
            @click="duplicateInput()"
          />

          <svn-icon-button
            icon="mdi:trash-can-outline"
            :variant="canRemoveInput ? 'noOutlined' : 'plain'"
            button-size="lg"
            :disable="!canRemoveInput"
            @click="removeInput()"
          />
        </div>
      </div>

      <div>
        <v-text-field
          v-model="input.title"
          dirty=""
          autofocus
          hide-details
          color="primary"
          variant="underlined"
          class="drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
          @update:model-value="updateInputTitle()"
        />
      </div>
    </div>

    <div class="text-xs flex text-middle-grey w-full">
      <p>
        {{ $t('Answer options') }}
      </p>

      <v-spacer />

      <p>
        {{ $t('Right answer(s)') }}
      </p>
    </div>

    <div>
      <learn-edit-question-option-block
        v-for="(questionOption, index) in input.proposals"
        :key="index"
        :index="index"
        :question-options="questionOption"
        @remove-option="removeOption(index)"
        @update-input="updateInput($event, questionOption.proposal)"
        :check-input="beforeSaveValidation(input, questionOption.proposal)"
      />
    </div>

    <!-- Add answer button -->
    <div>
      <v-btn
        color="trainingOrangeSoft"
        variant="flat"
        class="normal-case flex gap-2 text-trainingOrange"
        @click="addQuestion()"
      >
        <Icon
          icon="material-symbols:add"
          class="h-4 w-4"
        />
        <p>{{ $t('Add answer option') }}</p>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import LearnEditQuestionOptionBlock from "./LearnEditQuestionOptionBlock.vue";
const emit = defineEmits(['update-input', 'add-option', 'remove-option', 'remove-input', 'duplicate-option'])

const props = defineProps({
  input: { type: Object, default: () => {} },
  update: {type: Function, default: () => {} },
  canRemoveInput: { type: Boolean, default: true }
})

const removeInput = () => {
  if (props.canRemoveInput) {
    emit('remove-input')
  }
}

const addQuestion = () => {
  emit('add-option',  props.input)
}

const duplicateInput = () => {
  emit('duplicate-option')
}

const removeOption= (index) => {
  emit('remove-option', { input: props.input, index: index})
}

const updateInput = (event, value) => {

  if (!beforeSaveValidation(props.input, value)) {
    emit('update-input', props.input)
  }
}

const updateInputTitle = () => {
  emit('update-input', props.input)
}


const beforeSaveValidation = (input, value) => {
  return input.proposals.filter(proposal => proposal.proposal == value).length > 1
}
</script>
