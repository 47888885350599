<template>
  <trash-warnning
    v-if="isDeleted"
    title="This module is in trash."
    @restore="restoreDeletedItems(learnModule.id)"
    @delete-permanently="deletePermanentlyDeletedItems(learnModule.id)"
  />

  <!-- Header -->
  <module-show-header-buttons
    v-if="!isDeleted"
    type="show"
    :is-favorited="learnModule?.connected_user_favorited"
    :module-creator-id="learnModule?.creator_id"
    :drawer-comments-large-screen="drawerCommentsLargeScreen"
    @toggle-comments="toggleComments"
    @toggle-favorite="toggleModuleFavorite"
    @copy-link="copyLink"
    @edit-module="goToModuleEdit"
    @close-and-go-back="handleClose"
  />

  <div class="w-full relative">
    <v-layout
      full-height
      style="position: relative; width: 100%; height: calc(100% - 131); z-index: 0;"
    >
      <v-main class="overflow-y-auto relative">
        <!-- Main content -->
        <div class="flex flex-col md:w-[758px] mx-auto px-4 gap-6 md:gap-8 md:px-[180px] pb-[130px]">
          <!-- Breadcrumbs -->
          <div class="hidden lg:flex lg:px-[180px] -ml-[40%]">
            <!-- <bkt-breadcrumbs>
              {{ learnModule?.title }}
            </bkt-breadcrumbs> -->
          </div>

          <!-- Image -->
          <div class="py-4 lg:!py-[0px]">
            <div
              class="rounded-lg bg-middle-grey h-[147px] sm:h-[320px] p-2 relative bg-contain bg-center"
              :style="{'background-image': learnModule?.cover_url ? `url('${learnModule?.cover_url?.['500']}')` : '',
                       'background-size': 'cover'}"
            />
          </div>

          <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-1">
              <div class="font-semibold text-2xl lg:text-3xl truncate">
                {{ learnModule?.title ? learnModule?.title : $t('Module title') }}
              </div>

              <!-- Module is Acquired for the connected user -->
              <div
                v-if="learnModule?.submission?.status === 'acquired'"
                class="flex gap-1 items-center"
              >
                <Icon
                  icon="gg:check-o"
                  class="h-4 w-4 !text-[#7BC079]"
                />

                <p class="text-sm font-medium !text-[#7BC079]">
                  {{ $t('Acquired on') }} {{ filters.formatDate( learnModuleSubmissionUpdatedAt ? learnModuleSubmissionUpdatedAt : learnModule?.submission_updated_at, 'DD/MM/YYYY') }}
                </p>
              </div>

              <!-- Module theme list -->
              <div
                v-if="learnModule?.themes?.length"
                class="w-full flex gap-2 flex-wrap"
              >
                <div
                  v-for="(theme, index) in learnModule?.themes"
                  :key="index"
                  class="rounded-lg bg-training-orange-soft text-xs px-2 py-[6px] leading-5"
                >
                  {{ theme?.name }}
                </div>
              </div>

              <!-- No theme -->
              <div
                v-else
                class="w-fit rounded-lg bg-training-orange-soft text-xs px-2 py-[6px] leading-5"
              >
                {{ $t('No theme') }}
              </div>

              <!-- Module duration -->
              <div class="flex gap-1 items-center">
                <Icon
                  icon="mdi:access-time"
                  class="h-4 w-4 text-dark-grey"
                />

                <p class="font-medium text-sm text-dark-grey ">
                  {{ learnModule?.duration || $t('No duration') }}
                </p>
              </div>
            </div>

            <!-- Rich text editor -->
            <svn-tiptap
              v-if="editorContentData?.blocks?.length && editorContentId"
              :create-image-url="`/api/v1/editor_contents/${editorContentId}/upload_image`"
              :html-data="editorContentData?.blocks"
              :extension-selection="AllTipTapPlugins"
              :extension-slash-command="AllTipTapPlugins"
              :is-editable="false"
            />

            <!-- Reactions -->
            <div
              v-if="!isDeleted"
              class="w-full flex flex-col gap-6"
            >
              <div class="flex flex-col gap-4 p-6 border rounded-lg">
                <!-- Titles -->
                <div class="flex flex-col gap-2.5">
                  <p class="font-medium text-xl">
                    {{ $t('What did you think of the module ?') }}
                  </p>

                  <p class="text-dark-grey text-sm">
                    {{ $t('Your feedback helps us improve this module.') }}
                  </p>
                </div>

                <!-- Reactions List -->
                <div class="grid grid-cols-2 lg:grid-cols-4 gap-2.5">
                  <reaction-card
                    v-for="(reaction, index) in learnModule?.user_reactions"
                    :key="index"
                    :icon="reaction?.icon"
                    :name="reaction?.name"
                    :count="reaction?.count"
                    :type="reaction?.type"
                    :selected="reaction?.selected"
                    :bind="reaction?.bind"
                    @toggle-reaction="toggleModuleReaction(reaction?.class_type)"
                    @toggle-reaction-pop-up="toggleModuleReactionQuestionOrRemark($event, reaction?.class_type)"
                    @toggle-remove-reaction-pop-up="toggleModuleReaction(reaction?.class_type)"
                  />
                </div>
              </div>

              <!-- No evaluation is present -->
              <div
                v-if="learnModule.has_learn_pieces_approval"
                class="flex flex-col gap-8"
              >
                <div
                  v-if="!learnModule.has_learn_pieces_quiz"
                  class="flex flex-col gap-8 p-6 border rounded-lg"
                >
                  <!-- Titles -->
                  <p class="font-medium text-xl">
                    {{ $t("🙌 You've reached the end! Did you learn all the following learning objectives?") }}
                  </p>

                  <div
                    v-html="learnApprovalInput?.text ||  $t('Not set')"
                    class="font-medium text-base text-darkGrey leading-6 whitespace-pre-wrap"
                  />

                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <dialog-module-success-learning
                      :fully-understood="learnModule?.submission?.status === 'acquired'"
                      :next-module-id="nextModuleId"
                      @recall-mounted-method="mountedMethods()"
                      @fully-understood="toggleFullyUnderstood"
                    />
                  </div>
                </div>
              </div>

              <!-- Evaluation Quiz is present -->
              <div
                v-else-if="learnModule.has_learn_pieces_quiz"
                class="flex flex-col gap-8 p-6 border rounded-lg"
              >
                <!-- Titles -->
                <div class="flex flex-col gap-2.5">
                  <p class="font-medium text-xl">
                    {{ $t("🙌 You’ve reached the end ! Ready for the quiz ?") }}
                  </p>

                  <p class="text-dark-grey text-sm">
                    {{ $t('To complete this module, evaluate yourself with a quiz.') }}
                  </p>
                </div>

                <!-- Start the Quiz block -->
                <div class="flex justify-center sm:justify-end items-center w-full">
                  <v-spacer class="hidden sm:flex" />

                  <modal-quiz-learn-module
                    ref="modalQuiz"
                    :inputs="inputs"
                    :learn-module="learnModule"
                    :learn-responses-checkboxes="learnResponsesCheckboxes"
                    :submission="submission"
                    :submission-status="learnModule?.submission?.status"
                    :result-percentage="resultPercentage"
                    :show-quiz-results="showQuizResults"
                    :next-module-id="nextModuleId"
                    @start-quiz="startQuiz()"
                    @recall-mounted-method="mountedMethods()"
                    @retry-quiz="retryQuiz()"
                    @validate-quiz="validateQuiz()"
                  />
                </div>
              </div>

              <!-- Evaluation Face to Face is present -->
              <div
                v-else-if="learnModule.has_learn_pieces_face_to_face_evaluation"
                class="flex flex-col items-start gap-8 p-6 border border-middle-grey rounded-[8px]"
              >
                <!-- Titles -->
                <div class="flex flex-col items-start gap-[10px] self-stretch">
                  <svn-title v-if="learnModule?.submission?.status === 'in_progress'" h2 medium>
                    {{ $t("🙌 You’ve reached the end! Validate your evaluation to complete this module.") }}
                  </svn-title>

                  <svn-title v-else-if="learnModule?.submission?.status === 'acquired'" h2 medium>
                    {{ $t('✅ Evaluation done, you have acquired this module!') }}
                  </svn-title>

                  <svn-title v-else h2 medium>
                    {{ $t("🙌 You’ve reached the end! Please notify your expert before starting the evaluation.") }}
                  </svn-title>

                  <svn-text v-if="learnModule?.submission?.status !== 'acquired'" sm regular color="dark-grey">
                    {{ $t('To successfully complete this module, your answers must be reviewed in real time by your expert.') }}
                  </svn-text>

                  <svn-text v-else sm regular color="dark-grey">
                    {{ $t('This module has been acquired, but you can still restart the evaluation.') }}
                  </svn-text>
                </div>

                <!-- Start/Continue/restart evaluation button -->
                <div class="flex justify-center sm:justify-end items-center w-full">
                  <v-spacer class="hidden sm:flex" />

                  <modal-start-face-to-face-evaluation
                    ref="faceToFaceEvaluationModal"
                    @start-evaluation="startFaceToFaceEvaluation"
                    :next-module-id="nextModuleId"
                  />
                </div>
              </div>

              <!-- Change module -->
              <div
                class="w-full flex items-center"
                :class="prevModuleId ? nextModuleId ? 'justify-between' : 'justify-start' : 'justify-end' "
              >
                <!-- Go to previous module -->
                <div
                  v-if="prevModuleId && (route?.query?.trainingId || route?.query?.playlistId)"
                  class="flex"
                >
                  <v-spacer class="hidden lg:flex" />

                  <svn-button
                    prepend-icon="mdi:arrow-left"
                    variant="plain"
                    @click="goToPreviousModule"
                  >
                    <template #button-text>
                      {{ $t('Go to previous module') }}
                    </template>
                  </svn-button>
                </div>

                <!-- Go to next module -->
                <div
                  v-if="nextModuleId && (route?.query?.trainingId || route?.query?.playlistId)"
                  class="flex"
                >
                  <v-spacer class="hidden lg:flex" />

                  <!-- Dialog de vérification d'acquisition -->
                  <dialog-module-check-understanding
                    v-if="learnModule.submission?.status !== 'acquired'"
                    :next-module-id="nextModuleId"
                    @recall-mounted-method="mountedMethods()"
                    @open-quiz="openQuiz()"
                    @clear-to-me="toggleFullyUnderstood()"
                    @start-evaluation-face-to-face="startFaceToFaceEvaluation"
                  />

                  <svn-button
                    v-else
                    append-icon="mdi:arrow-forward"
                    variant="plain"
                    @click="goToNextModule"
                  >
                    <template #button-text>
                      {{ $t('Go to next module') }}
                    </template>
                  </svn-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-main>

      <!-- Comments drawer Large Screen -->
      <comments-drawer
        v-model="drawerCommentsLargeScreen"
        :learn-module-reactions="learnModuleReactions"
        location="end"
        :width="526"
        class="border-l-middleGrey hidden lg:block"
        @delete-comment="deleteComment"
        @add-comment-reply="addCommentReply"
      />
    </v-layout>
  </div>

  <!-- Comments drawer Large Mobile -->
  <comments-drawer
    v-model="drawerCommentsMobile"
    :learn-module-reactions="learnModuleReactions"
    :location="isMobile ? 'bottom' : 'end'"
    :width="isMobile ? drawerWidthMobile : 526"
    class="block lg:hidden h-screen"
    :class="isMobile ? 'h-full' : 'border-l-middleGrey'"
    :mobile="true"
    @delete-comment="deleteComment"
    @add-comment-reply="addCommentReply"
    @close-drawer="drawerCommentsMobile = false"
  />

  <!-- Dialog delete comemnt -->
  <dialog-delete-comment
    ref="dialogDeleteComment"
    :has-reaction-type="commentToDelete?.reaction_type === ''"
    @delete-comment="deleteModuleComment"
  />

  <!-- Scroll to top button -->
  <svn-floating-action-button
    square
    to-top
  />
</template>

<script setup>
import ReactionCard from "@/components/trainingApp/quiz/ReactionCard.vue";
import { Icon } from "@iconify/vue";
import CommentsDrawer from "@/components/trainingApp/CommentsDrawer.vue";
import ModuleShowHeaderButtons from "@/components/trainingApp/ModuleShowHeaderButtons.vue";
import { onMounted, onUnmounted, ref, onBeforeUnmount } from "vue";
import DialogDeleteComment from "@/components/BktPopUp/Dialogs/learn/DialogDeleteComment.vue";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import i18n from "@/plugins/i18n.js";
import DialogModuleSuccessLearning from "@/components/BktPopUp/Dialogs/learn/DialogModuleSuccessLearning.vue";
import DialogModuleCheckUnderstanding from "@/components/BktPopUp/Dialogs/learn/DialogModuleCheckUnderstanding.vue";
import ModalQuizLearnModule from "@/components/BktPopUp/Modals/learn/ModalQuizLearnModule.vue";
import { useLearnModuleStore } from "@/store/learn-module";
import { useRoute, useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar";
import { storeToRefs } from "pinia";
import {useToastStore} from "@/store/toast.js";
import { useMobileStore } from "@/store/mobile";
import { useActionCable } from "@/store/cable.js";
import {useUserStore} from "@/store/user.js";
import { debounce } from "lodash";
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useLearnTrainingStore } from "@/store/learn-trainings.js";
import filters from "@/tools/filters";
import TrashWarnning from "@/components/TrashWarnning.vue"
import ModalStartFaceToFaceEvaluation from "@/components/BktPopUp/Modals/learn/ModalStartFaceToFaceEvaluation.vue";
import { LearnInputType } from '@/constants/types';
import {Image, Heading, AllTipTapPlugins}  from 'svn-ui-library/extensions'

onMounted(async () => {
  await mountedMethods()
})

const resultPercentage = ref(0);
const { addToast } = useToastStore();
const { cable } = storeToRefs(useActionCable());
const { id: userId } = storeToRefs(useUserStore())

const completedCount = () => {
  return learnResponsesCheckboxes.value.reduce((count, item) => {
    if (item.status === 'correct') {
      return count + 1;
    }
    return count;
  }, 0);
}

onUnmounted(() => {
  // window.removeEventListener("scroll", alignFunc)
  reset()
})

const { fetchModule, addReaction, toggleReaction, deleteReaction, getSubmissionFaceToFace,
  fetchModuleReactions, toggleFavorite, approvalSubmission, updateApprovalSubmissionDate, fetchInputs,
  getSubmission, fetchInputsFaceToFace, createSubmission, createSubmissionFaceToFace, resetStates, validateSubmission, getEditorContent, restoreModule, deleteModulePermanently  } = useLearnModuleStore()

const {
  learnModule,
  learnModuleReactions,
  inputs,
  inputsFaceToFace,
  submissionId,
  piecesQuizId,
  learnResponsesCheckboxes,
  submission,
  editorContentData,
  editorContentId,
  learnModuleSubmissionUpdatedAt,
  learnApprovalInput
 } = storeToRefs(useLearnModuleStore())

 const { fetchTraining } = useLearnTrainingStore()

const { learnTraining } = storeToRefs(useLearnTrainingStore())

const { fetchPlaylist } = useLearnPlaylistStore()

const { learnPlaylist } = storeToRefs(useLearnPlaylistStore())

const { reset } = useBreadcrumbsStore();

const { isMobile, isLgScreen } = storeToRefs(useMobileStore())

const nextModuleId = ref(null);
const prevModuleId = ref(null);
const showQuizResults = ref(false)
const drawerWidthMobile = ref(null);
const drawerCommentsMobile = ref(false)
const drawerCommentsLargeScreen = ref(false)
const alignTop = ref(false)
const editorContentChannel = ref(null)
const inputChannel = ref(null)
const dialogDeleteComment = ref(false)
const modalQuiz = ref(false)
const commentToDelete = ref(null)
const isDeleted = ref(false)
const faceToFaceEvaluationModal = ref(null);

const route = useRoute()
const router = useRouter()
const snackbar = useSnackbar()

const startQuiz = debounce(async() => {
  try {
    if (submissionId.value) {
      await getSubmission(piecesQuizId.value);
    } else {
      await createSubmission(piecesQuizId.value)
    }

    if (completedCount() === 0 || learnResponsesCheckboxes.value.length === 0) {
      resultPercentage.value = 0
    } else {
      resultPercentage.value = (completedCount() / learnResponsesCheckboxes.value.length) * 100;
    }

    showQuizResults.value = submission?.value?.status === 'retry'
  } catch (error) {
    console.log(error);
  }
}, 200);

const startFaceToFaceEvaluation = debounce(async() => {
  faceToFaceEvaluationModal.value.modalFaceToFace.dialog = true;

  try {
    if (submission?.value?.id) {
      await getSubmissionFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id);
    } else {
      await createSubmissionFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id)
    }
  } catch (error) {
    console.log(error)
  }
}, 200);

const retryQuiz = debounce(async() => {
  showQuizResults.value = false

  await createSubmission(piecesQuizId.value)

  learnResponsesCheckboxes.value?.forEach((question) => {
    question.status = null
    question.selected_values = []
  })
}, 200)

const openQuiz = () => {
  modalQuiz.value.modalRef.dialog = true
  startQuiz()
}

const validateQuiz = debounce(async() => {
  await validateSubmission(piecesQuizId.value)
  if (completedCount() === 0 || learnResponsesCheckboxes.value.length === 0) {
    resultPercentage.value = 0
  } else {
    resultPercentage.value = (completedCount() / learnResponsesCheckboxes.value.length) * 100;
  }

  showQuizResults.value = true
}, 200);

const addCommentReply = debounce(async(text) => {
  try {
    await addReaction(learnModule?.value?.id, '', text)

  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error adding comment')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}, 200)


const deleteComment = (comment) => {
  commentToDelete.value = comment
  dialogDeleteComment.value.dialogDeleteComment = true
}

const deleteModuleComment = debounce(async() => {
  try {
    await deleteReaction(learnModule?.value?.id, commentToDelete.value?.id)
    dialogDeleteComment.value.dialogDeleteComment = false

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Comment deleted.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    dialogDeleteComment.value.dialogDeleteComment = false

    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting comment.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
  commentToDelete.value = null
}, 200)

const toggleFullyUnderstood = async() => {
  if (learnModule?.value?.submission && Object.keys(learnModule?.value?.submission).length) {
    try {
      await updateApprovalSubmissionDate()
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Acquisition date has been updated')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10' )
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error updating acquisition date')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10' )
      snackbar.displaySnackBar()
    }
  } else {
    await approvalSubmission()
  }
}

const toggleModuleReaction = async(type) => {
  try {
    await toggleReaction(learnModule?.value?.id, type, '')
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error toggling reaction.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const toggleModuleReactionQuestionOrRemark = async(text, type) => {
  if (text) {
    try {
      await toggleReaction(learnModule?.value?.id, type, text)
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg(`Your ${type === 'Reaction::Question' ? 'question' : 'remark'} is now visible in the comment section.`)
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
      snackbar.setAction('See comments', openComments)
      snackbar.setCloseButton(false)
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error toggling reaction.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
      snackbar.displaySnackBar()
    }
  }
}

const toggleComments = () => {
  if (isLgScreen.value) {
    drawerCommentsLargeScreen.value = !drawerCommentsLargeScreen.value
  } else {
    drawerCommentsMobile.value = !drawerCommentsMobile.value
  }
}

const openComments = () => {
  if (isLgScreen.value) {
    drawerCommentsLargeScreen.value = true
  } else {
    drawerCommentsMobile.value = true
  }
}

const toggleModuleFavorite = debounce(async() => {
  try {
    await toggleFavorite(route?.params?.id, learnModule?.value?.status)
    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar(learnModule?.value?.connected_user_favorited ? 'Module added to favorites' : 'Module removed from favorites')
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error toggling favorite')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}, 250)

const copyLink = debounce(() => {
  navigator.clipboard.writeText(window.location.href)

  snackbar.setBgColor('positiveGreen')
  snackbar.setMsg('Link copied to clipboard.')
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
  snackbar.displaySnackBar()
}, 200)

const goToModuleEdit = () => {
  router.push({ name: "module_edit", params: { id: route?.params?.id } })
}

const resetBreadcrumbs = () => {
  reset()
}

const handleClose = () => {
  router.back()
}

const goToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}

window.onscroll = () => {
  scrollFunction()
}

const scrollFunction = () => {
  if (document.documentElement.scrollTop >= 300) {
    alignTop.value = true
  }
  else {
    alignTop.value = false
  }
}

const restoreDeletedItems = async (id) => {
  try {
    await restoreModule(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module restored successfully, module has been moved to draft')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    isDeleted.value = false
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteModulePermanently(id, false)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Module deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "catalog" });

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const mountedMethods = (async() => {
  await resetStates()
  // window.addEventListener("scroll", alignFunc);

  drawerWidthMobile.value = window?.innerWidth;

  try {
    await fetchModule(route?.params?.id)
    updateEditorContent()

    if (learnModule?.value?.has_learn_pieces_quiz) {
      await fetchInputs(learnModule.value?.learn_pieces_quiz?.id)
      await updateInputQuestion()
    }

    if (learnModule?.value?.has_learn_pieces_face_to_face_evaluation) {
      await fetchInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id);
      await updateInputQuestion()
    }

    if (learnModule?.value?.editor_content_id) {
    await getEditorContent(learnModule?.value?.editor_content_id)
    }

    // On vient sur le module depuis un training
    if (route?.query?.trainingId) {
      await fetchTraining(route?.query?.trainingId)

      if (learnTraining.value.modules_and_playlist.length) {
        const moduleIds =  learnTraining.value.modules_and_playlist.map((content) => {
        if (content.content_type === 'Learn::Playlist') {
          return content.learn_modules.map((playlist_content) => {
            return playlist_content.id
          })
        } else {
           return content.id
         }
        }).flat(1)

        const moduleIdsClean = moduleIds.filter(Boolean)
        const currentIndex = moduleIdsClean.indexOf(parseInt(route?.params.id))
        nextModuleId.value  = moduleIdsClean[ currentIndex + 1]
        prevModuleId.value = moduleIdsClean[ currentIndex -1]
      }
    }

    // On vient sur le module depuis une playlist
    if (route?.query?.playlistId) {
      await fetchPlaylist(route?.query?.playlistId)

      if (learnPlaylist.value.learn_modules.length) {
        const moduleIds =  learnPlaylist.value.learn_modules.map((mod) => mod?.id)

        const currentIndex = moduleIds.indexOf(parseInt(route?.params.id))
        nextModuleId.value  = moduleIds[ currentIndex + 1]
        prevModuleId.value = moduleIds[ currentIndex -1]
      }
    }

    isDeleted.value = learnModule.value.status === "trash"

  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }

  try {
    await fetchModuleReactions(route?.params?.id)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching module reactions')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
})

const updateInputQuestion = debounce(async() => {
  const subscribeOptions =
  {
      channel: "Learn::InputChannel",
      piece_id: learnModule?.value?.submission_type === 'Learn::Pieces::Quiz' ?
        learnModule.value?.learn_pieces_quiz?.id : learnModule?.value?.learn_pieces_face_to_face_evaluation?.id
    }

  inputChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      if (data.status === "update") {
        const index = inputs.value.findIndex(x => x.id === data.entity.id)
        const indexFaceToFace = inputsFaceToFace.value.findIndex(x => x.id === data.entity.id)

        if (data.entity.type === LearnInputType.CHECKBOX && index >= 0) {
          inputs.value[index].title = data.entity.title
          inputs.value[index].proposals = data.entity.proposals
          inputs.value[index].position = data.entity.position

        } else if (data.entity.type === LearnInputType.OPEN && indexFaceToFace >= 0) {
          inputsFaceToFace.value[indexFaceToFace].title = data.entity.title
          inputsFaceToFace.value[indexFaceToFace].description = data.entity.description
          inputsFaceToFace.value[indexFaceToFace].position = data.entity.position
        } else {
          addToast(
            'info',
            i18n.global.t(`This module has just been updated !`),
            i18n.global.t(`The lastest version of this content will be visible if you reload this page.`),
            false,
            {
              name: i18n.global.t(`Reload this page`),
              link: '/learns/module/' + learnModule.value.id + '/show'
            }
          )
        }
      }
    },

    update: async function() {
    },
  });
}, 200)

const updateEditorContent = debounce(async() => {
  const subscribeOptions =
    {
      channel: "EditorContentChannel", id: learnModule.value.editor_content_id
    }

    editorContentChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      if (data.status === "update") {
        addToast(
          'info',
          i18n.global.t(`This module has just been updated !`),
          i18n.global.t(`The lastest version of this content will be visible if you reload this page.`),
          false,
          {
            name: i18n.global.t(`Reload this page`),
            link: '/learns/module/' + learnModule.value.id + '/show'
          }
        )
      } else {
        if (data?.entity?.blocks?.length) {
          editorContentData.value.blocks = data.entity.blocks
        } else {
          editorContentData.value.blocks = emptyBlocks.value
        }
      }
    },

    update: async function() {
    },
  });
}, 100)

const goToNextModule = () => {
  if (nextModuleId.value) {
    if (route?.query.trainingId) {
      router.push({ name: 'module_show', params: { id: nextModuleId.value }, query: {trainingId: route?.query.trainingId }}).then(async() => {
        await mountedMethods()
      })
    } else if (route?.query.playlistId) {
      router.push({ name: 'module_show', params: { id: nextModuleId.value }, query: {playlistId: route?.query.playlistId }}).then(async() => {
        await mountedMethods()
      })

    }
  }
}

const goToPreviousModule = () => {
  if (prevModuleId.value) {
    if (route?.query.trainingId) {
      router.push({ name: 'module_show', params: { id: prevModuleId.value }, query: {trainingId: route?.query.trainingId }}).then(async() => {
        await mountedMethods()
      })
    } else if (route?.query.playlistId) {
      router.push({ name: 'module_show', params: { id: prevModuleId.value }, query: {playlistId: route?.query.playlistId }}).then(async() => {
        await mountedMethods()
      })

    }
  }
}

onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  editorContentChannel?.value?.unsubscribe();
})
</script>

<style scoped>
.inputSection {
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
