<template>
  <dialog-skeleton
    ref="dialogRef"
    :display-close="false"
    :vertical="isMobile ? true : false"
    button-primary-color="primary"
    :width="isMobile ? 300 : 600"
    :bind="bind"
    :display-secondary-button="true"
    :primary-button-disabled="!text.length"
    :primary-button-text="getDialogActionButtonText"
    secondary-button-text="Cancel"
    @click-primary-button="clickPrimaryButton"
  >
    <template #button>
      <slot
        name="button"
      />
    </template>

    <template #body>
      <div class="flex flex-col gap-4 items-center">
        <!-- Dialog GIF -->
        <div
          v-if="reactionType === 'question' || reactionType === 'remark'"
          class="w-[64px] h-[64px] flex justify-center items-center self-center"
        >
          <iframe
            :src="getDialogGif"
            width="64"
            height="64"
          />
        </div>

        <!-- Dialog Text -->
        <p class="text-fakelack text-center text-xl font-medium">
          {{ getDialogText }}
        </p>

        <!-- Text area -->
        <v-textarea
          v-model="text"
          variant="outlined"
          :no-resize="true"
          hide-details
          class="w-full"
          :placeholder="$t(getDialogInputPlaceholderText)"
        />
      </div>
    </template>

    <template #secondary-button>
      <v-btn
        variant="outlined"
        color="fakeBlack"
        class="border border-middle-grey text-fake-black normal-case text-xs"
        :class="isMobile ? 'w-full' : 'w-1/2'"
        :text="$t('Cancel')"
        @click="dialogLeaveReactionComment = true"
      />
    </template>
  </dialog-skeleton>

  <!-- Dialog -->
  <v-dialog
    v-model="dialogLeaveReactionComment"
    content-class="!rounded-[12px] bg-white"
    :width="isMobile ? 300 : 400"
  >
    <template #default>
      <div class="w-full flex flex-col p-6 gap-8">
        <!-- Icon and text -->
        <div class="flex flex-col items-center gap-4">
          <!-- icon -->
          <Icon
            icon="noto:warning"
            height="32"
            width="32"
          />

          <!-- Title & description -->
          <div class="flex flex-col items-center self-stretch gap-4">
            <p class="text-fakeBlack text-xl font-medium text-center">
              {{ $t('Do you really want to leave ?') }}
            </p>

            <p class="text-darkGrey text-sm font-normal text-center">
              {{ $t('If you quit now, your reaction will not be taken into account and your draft will be deleted.') }}
            </p>
          </div>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col items-start gap-3 self-stretch lg:!flex-row-reverse">
          <v-btn
            variant="flat"
            color="primary"
            class="normal-case text-xs w-full lg:!w-1/2"
            :text="$t('Leave')"
            @click="closeAllDialogs"
          />

          <v-btn
            variant="outlined"
            color="fakeBlack"
            class="normal-case text-xs w-full lg:!flex-1"
            :text="$t('Cancel')"
            @click="dialogLeaveReactionComment = false"
          />
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useMobileStore } from "@/store/mobile";
import DialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue"
import i18n from "@/plugins/i18n";

const props = defineProps({
  reactionType: { type: String, default: '' },
  selected: { type: Boolean, default: false },
  bind: { type: Boolean, default: false },
})

const emit = defineEmits(['toggle-reaction'])

const { isMobile } = storeToRefs(useMobileStore())

const text = ref('')

const dialogLeaveReactionComment = ref(false)

const dialogRef = ref(null)

const clickPrimaryButton = () => {
  dialogRef.value.dialog = false

  emit('toggle-reaction', text.value)
}

const closeAllDialogs = () => {
  dialogLeaveReactionComment.value = false
  dialogRef.value.dialog = false
}

const getDialogActionButtonText = computed(() => {
  if (props?.reactionType === 'question') {
    return 'I have a question'
  }
  else if (props?.reactionType === 'remark') {
    return 'I have a remark'
  }
  else {
    return ''
  }
})

const getDialogText = computed(() => {
  if (props?.reactionType === 'question') {
    return i18n.global.t('Ask your question below')
  }
  else if (props?.reactionType === 'remark') {
    return i18n.global.t('Make a remark below')
  }
  else {
    return ''
  }
})

const getDialogInputPlaceholderText = computed(() => {
  if (props?.reactionType === 'question') {
    return 'I have a question about...'
  }
  else if (props?.reactionType === 'remark') {
    return 'I have a remark about...'
  }
  else {
    return ''
  }
})

const getDialogGif = computed(() => {
  if (props?.reactionType === 'question') {
    return 'https://lottie.host/?file=e4cd4916-3bb6-418f-acec-0d4fa627bdcf/SXP8YkJQSb.json'
  }
  else if (props?.reactionType === 'remark') {
    return 'https://lottie.host/?file=ef25fb66-24e2-445b-a9cb-9ed22534d04e/fDPUISSyuB.json'
  }
  else {
    return ''
  }
})
</script>
