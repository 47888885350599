<template>
  <div class="!rounded-xl shadow-none border w-full flex flex-col pb-1.5">
    <div class="w-full flex-none flex border-b">
      <div class="w-full p-4 flex gap-2 items-center">
        <div>
          <Icon
            icon="ic:outline-info"
            height="16"
            width="16"
          />
        </div>

        <svn-text base normal>
          {{ $t('Shared information') }}
        </svn-text>
      </div>
    </div>

    <div class="text-sm font-medium  overflow-y-auto h-full max-h-[222px]">
      <!-- ALEPH -->
      <div class="overflow-hidden">
        <div
          v-if="links?.length"
          class="flex flex-col"
        >
          <a
            v-for="link in links"
            :key="link"
            class="flex p-4 items-center !w-full underline-none hover:bg-very-light-grey"
            :href="link?.link"
            target="_blank"
          >
            <svn-text sm normal>
              {{ link?.title }}
            </svn-text>

            <v-spacer />

            <div>
              <Icon
                icon="ic:twotone-open-in-new"
                class="text-fake-black text-xs"
                height="16"
                width="16"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useCompanyStore } from "@/store/company";

const props = defineProps({
  companyId: {
    type: Number,
    required: true
  }
})

const { links } = storeToRefs(useCompanyStore())
</script>