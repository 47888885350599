<template>
  <div class="w-full flex flex-col items-center px-4 py-2 gap-4 self-stretch lg:max-w-[1296px] lg:mx-auto">
    <!-- If the training hasn't been launched yet -->
    <div
      v-if="type === 'draft'"
      class="w-full flex flex-col justify-center items-center p-8 self-stretch h-[166px] rounded-[8px] border-[0.5px] border-lightGrey shadow"
    >
      <p class="text-darkGrey text-center text-sm font-medium">
        {{ $t('No data available yet as training has not yet begun.') }}
      </p>
    </div>

    <div
      v-else
      class="flex flex-col gap-4 w-full"
    >
      <analytics-trained :training-completion-all="trainingCompletionAll" />

      <div class="w-full flex flex-col gap-4 lg:flex-row">
        <analytics-training-completion-all
          class="lg:w-[33%]"
          :training-completion-all="trainingCompletionAll"
        />

        <analytics-training-completion-category
          class="lg:w-[67%]"
          :training-id="trainingId"
          :training-participants-list="trainingParticipantsList"
        />
      </div>

      <analytics-training-completion-module
        :completion-by-module="completionByModule"
      />


      <analytics-participants-list
        :training-participants-list="trainingParticipantsList"
        :is-deleted="isDeleted"
        @get-training-participants="getTrainingParticipants"
      />
    </div>
  </div>
</template>

<script setup>
import AnalyticsTrained from './AnalyticsTrained.vue';
import AnalyticsTrainingCompletionAll from './AnalyticsTrainingCompletionAll.vue';
import AnalyticsTrainingCompletionModule from './AnalyticsTrainingCompletionModule.vue';
import AnalyticsTrainingCompletionCategory from './AnalyticsTrainingCompletionCategory.vue';
import AnalyticsParticipantsList from './AnalyticsParticipantsList.vue';

const props = defineProps({
  status: { type: String, required: true },
  trainingId: {type: String, required: true},
  trainingCompletionAll: {type: Object, required: true},
  completionByModule: { type: Object, required: true },
  trainingParticipantsList: { type: Array, required: true },
  isDeleted: { type: Array, default: () => { return false } },
})
const emit = defineEmits(['get-training-participants'])

const getTrainingParticipants = (value) => {
  emit('get-training-participants', value)
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>