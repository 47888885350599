<template>
  <modal-skeleton
    ref="addToPlaylist"
    :bind="true"
    :display-secondary-button="false"
    button-primary-color="trainingOrange"
    :primary-button-text="textConfirm"
    :fullscreen="isMobile ? true : false"
    :width="580"
  >
    <!-- Header -->
    <template #header="{ close }">
      <div class="mt-[56px] mb-[24px] flex justify-center items-center relative">
        <p class="text-xl font-medium text-fakeBlack">
          {{ (selectedModules?.length === 0 || selectedModules?.length === 1) ?
            $t('Select module to add ()', { selectedModules: selectedModules?.length }) :
            $t('Select modules to add ()', { selectedModules: selectedModules?.length }) }} 
        </p>

        <div
          class="absolute right-6 bottom-6 cursor-pointer"
          @click="close"
        >
          <Icon
            icon="material-symbols:close"
            height="24"
            width="24"
            class="text-dark-grey hover:text-fake-black"
          />
        </div>
      </div>
    </template>

    <!-- Main body -->
    <template #body>
      <div class="flex flex-col h-full p-4 sm:p-6 gap-4 overflow-y-auto">
        <!-- Inputs -->
        <div class="flex flex-start gap-2 self-stretch pb-4">
          <!-- Search bar -->
          <v-text-field
            v-model="searchText"
            hide-details
            variant="outlined"
            :label="$t('Search')"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            @input="search"
          />

          <!-- Filter -->
          <v-menu
            :close-on-content-click="false"
            location="bottom"
            :content-class="'w-[300px] h-[300px]'"
          >
            <!-- Menu activator -->
            <template #activator="{ props }">
              <div
                v-bind="props"
                class="flex justify-center items-center w-[48px] h-[48px] px-4 border border-darkGrey rounded cursor-pointer"
              >
                <div class="flex justify-center items-center self-stretch">
                  <Icon
                    icon="mdi:filter-variant"
                    height="16"
                    width="16"
                  />
                </div>
              </div>
            </template>

            <!-- List of themes -->
            <v-list
              class="!py-0 !rounded-[8px] !border !border-fakeBlack"
            >
              <template #default>
                <div
                  class="flex flex-col"
                >
                  <!-- Theme list -->
                  <v-list-item
                    v-for="(item, index) in themes"
                    :key="index"
                    class="!p-0 cursor-pointer"
                    :class="selectedThemesList.find(theme => theme?.id === item?.id) !== undefined ?
                      'bg-veryLightGrey' : ''"
                    @click.stop="toggleThemeSelection(item)"
                  >
                    <template #default>
                      <div class="flex h-10 px-4 gap-2 items-center">
                        <Icon
                          :icon="selectedThemesList.find(theme => theme?.id === item?.id) !== undefined ?
                            'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                          color="darkGrey"
                          width="18"
                          height="18" 
                        />
  
                        <div class="w-full flex items-center">
                          <p class="text-darkGrey text-base font-normal">
                            {{ item?.name }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-list-item>

                  <!-- No result -->
                  <div
                    v-if="!themes?.length"
                    class="w-full h-[300px] flex justify-center items-center self-stretch p-2"
                  >
                    <p class="text-middleGrey text-base font-normal">
                      {{ $t('No theme to display') }}
                    </p>
                  </div>
                </div>
              </template>
            </v-list>
          </v-menu>

          <!-- Create new Module button -->
          <v-btn
            color="primary"
            class="text-darkGrey border border-darkGrey"
            variant="plain"
            size="48"
            @click="createNewModule"
          >
            <Icon
              icon="mdi-add"
              height="16"
              width="16"
              class="text-darkGrey"
            />
          </v-btn>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesList.length > 0"
          class="flex justify-between items-start self-stretch"
        >
          <div class="flex items-start content-start gap-2 flex-1 flex-wrap">
            <!-- Theme chips -->
            <div
              v-for="(theme, index) in selectedThemesList"
              :key="index"
              class="flex px-2 py-[6px] justify-center items-center rounded-[8px] bg-veryLightGrey !border !border-middleGrey"
            >
              <div class="flex justify-center items-center gap-2">
                <p class="text-darkGrey text-xs font-normal">
                  {{ theme?.name }}
                </p>
  
                <!-- Close icon -->
                <div
                  class="cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                >
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-darkGrey"
                  />
                </div>
              </div>
            </div>
          </div>
  
          <!-- Clear all selected themes -->
          <div
            v-if="selectedThemesList.length > 1"
            class="flex justify-center items-center px-4 py-[10px]"
            @click="clearAllSelectedThemes"
          >
            <p class="text-xs text-trainingOrange font-medium cursor-pointer">
              {{ $t('Clear all') }}
            </p>
          </div>
        </div>
  
        <!-- Modules list -->
        <div
          v-if="modulesList?.length"
          class="w-full max-h-full pb-6 overflow-y-auto"
        >
          <div class="flex flex-col gap-1">
            <div
              v-for="(module, index) in modulesList"
              :key="index"
              class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer h-[82px]"
              :class="selectedModules?.find(el => el === module?.id) ?
                'bg-trainingOrangeSoft rounded-[8px]' : 'bg-white'"
              @click="toggleAddToPlaylist(module)"
            >
              <!-- Checkbox -->
              <div>
                <Icon
                  :icon="selectedModules?.find(el => el === module?.id) ?
                    'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                  height="18"
                  width="18"
                  :color="selectedModules?.find(el => el === module?.id) ?
                    '#EF8C64' : 'black'"
                />
              </div>
  
              <module-card-list-item
                :id="module?.id"
                :key="index"
                :background-image="module?.cover_url?.['500']"
                :is-favorite="module?.connected_user_favorite"
                :title="module?.title"
                :theme-list="module?.themes"
                :duration="module?.duration"
                :updated-at="moment(module?.updated_at).format('DD/MM/YYYY')"
                :is-acquired="module?.isAcquired"
                :acquisition-date="module?.acquisitionDate"
              />
            </div>
          </div>
        </div>

        <!-- No modules in list -->
        <div
          v-else
          class="h-full sm:!h-[300px] flex justify-center items-center"
        >
          <p class="text-middleGrey text-sm font-normal">
            {{ $t('No modules to add for the moment') }}
          </p>
        </div>

        <!-- Validate button -->
        <div class="w-full">
          <v-btn
            variant="flat"
            class="w-full normal-case"
            color="primary"
            @click="addModulesToPlaylist(selectedModules)"
          >
            <p class="text-white text-xs font-medium">
              {{ $t('Validate') }}
            </p>
          </v-btn>
        </div>
      </div>
    </template>

    <template #button>
      <slot name="button" />
    </template>
  </modal-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import { useLearnModuleStore } from "@/store/learn-module";
import { useLearnPlaylistStore } from "@/store/learn-playlist";
import ModuleCardListItem from "@/components/trainingApp/ModuleCardListItem.vue";
import ModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue"
import { storeToRefs } from "pinia";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

const props = defineProps({
  textConfirm: { type: String, default: '' },
  themes: { type: Array, default: null },
  modulesList: { type: Array, required: true },
})

const { isMobile } = storeToRefs(useMobileStore())

const { selectedModules } = storeToRefs(useLearnPlaylistStore())

const { updatePlaylistInfos } = useLearnPlaylistStore()

const { fetchModules, createModule, updateModuleImage } = useLearnModuleStore()

const addToPlaylist = ref(null)

const searchText = ref('')

const selectedThemesList = ref([])

const router = useRouter()

const route = useRoute()

const snackbar = useSnackbar()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const emit = defineEmits(['toggle-module-selection'])

const toggleThemeSelection = async(item) => {
  const found = selectedThemesList?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesList.value?.splice(selectedThemesList.value?.findIndex((theme) => theme?.id === item?.id), 1);
  }
  else {
    selectedThemesList.value?.push(item);
  }

  await search()
}

const toggleAddToPlaylist = (module) => {
  emit('toggle-module-selection', module)
}

const addModulesToPlaylist = async(list) => {
  try {
    await updatePlaylistInfos(route?.params?.id, list)

    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg((selectedModules?.value?.length === 0 || selectedModules?.value?.length === 1) ?
      `Module successfully added to playlist.` :
      `Modules successfully added to playlist.`)
    snackbar.displaySnackBar()

    addToPlaylist.value.dialog = false
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg('Error adding module(s) to playlist')
    snackbar.displaySnackBar()
  }
}

const search = async() => {
  try {
    await fetchModules(searchText.value, selectedThemesList?.value?.map(theme => theme?.id))
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
}

const clearAllSelectedThemes = () => {
  selectedThemesList.value = [];
}

const createNewModule = async() => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    const module = await createModule("Module title", "30 minutes")

    loadXHR(randomImage).then(async(blob) => {
      updateModuleImage(module?.data?.id, blob)
    })

    router.push({ name: "module_edit", params: { id: module?.data?.id } })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}
</script>