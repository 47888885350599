import {defineStore} from "pinia";
import {genericFunctions, genericStates} from "@/tools/genericFunctions.js";

export const useMyTeamRoadmapArchivedStore = defineStore('myteamroadmaparchived', {
  state: () => ({
    users: null,
    ...genericStates
  }),
  actions: {
    ...genericFunctions,
    async fetchMyTeamRoadmapArchived(id, page = 1, title = '') {
      this.params = {
        'page[number]': page,
        title
      }
      await this.fetchEntity(
        `/api/v1/roadmaps/employees/${id}/team_archived_targets`,
        'users'
      );
    }
  }
})
