<template>
  <template-header-question
    :id="interviewQuestion.id"
    :is-deleted="isDeleted"
  >
    <template #title>
      <v-btn
        color="primary"
        variant="text"
        prepend-icon="mdi-checkbox-marked"
      >
        {{ $t('Multi choice') }}
      </v-btn>
    </template>
    <template #body>
      <v-text-field
        v-model="interviewQuestion.text"
        :label="$t('Multi choice name')"
        color="primary"
        :disabled="isDeleted"
        @update:model-value="updateQuestion(interviewQuestion.id, {text: $event})"
      />

      <div class="h-40 mb-16">
        <quill-editor
          v-model:content="interviewQuestion.description"
          content-type="html"
          :placeholder="$t('Description')"
          :enable="!isDeleted"
          @update:content="updateQuestion(interviewQuestion.id, {description: $event})"
        />
      </div>

      <div class="flex flex-col justify-start items-start">
        <p class="mb-1rem">
          {{ $t('Answer options') }}
        </p>
        <v-text-field
          v-for="(opt, idx) in options"
          :key="idx"
          v-model="opt.value"
          class="w-full"
          :label="$t('Option')"
          append-icon="mdi-close-thick"
          :disabled="isDeleted"
          @click:append="removeOption(idx, interviewQuestion.id, opt.position)"
          @blur="beforeUpdateQuestionOptions(interviewQuestion.id, opt.position, { value: $event.target.value }, idx)"
        />
      </div>
      <div class="flex justify-between">
        <div class="flex items-center">
          <v-btn
            color="primary"
            variant="flat"
            :disabled="isDeleted"
            @click="createOption"
          >
            {{ $t('Add an option') }}
          </v-btn>
        </div>
        <div>
          <v-checkbox
            v-model="interviewQuestion.allow_comments"
            :label="$t('Allow comments')"
            color="primary"
            hide-details
            :disabled="isDeleted"
            @update:model-value="updateQuestion(interviewQuestion.id, {allow_comments: $event})"
          />
        </div>
      </div>

      <template-question-visible-required-for
        v-model:visible-for="visibleFor"
        v-model:required-for="requiredFor"
        :is-deleted="isDeleted"
        @update:visible-for-formated="updateQuestion(interviewQuestion.id, {visible_for: $event})"
        @update:required-for-formated="updateQuestion(interviewQuestion.id, {required_for: $event})"
      />
    </template>
  </template-header-question>
</template>

<script setup>
import {ref, watch} from "vue";
import TemplateHeaderQuestion from "@/components/interviewApp/template/Edit/TemplateHeaderQuestion.vue";
import TemplateQuestionVisibleRequiredFor
from "@/components/interviewApp/template/Edit/TemplateQuestionVisibleRequiredFor.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import useTools from "@/tools/useTools.js";
import { useSnackbar } from "@/store/snackbar.js";
import {QuillEditor} from "@vueup/vue-quill";

const props = defineProps({
  interviewQuestion: { type: Object, required: true },
  isDeleted: { type: Boolean, required: true }
})

const {updateQuestion, updateQuestionOptions, deleteQuestionOptions, createQuestionOptions } = useInterviewFormStore()
const tools = useTools()
const { setBgColor, setMsg, displaySnackBar } = useSnackbar()

const visibleFor = ref(tools.deduceForWhom(props.interviewQuestion.visible_for))
const requiredFor = ref(tools.deduceForWhom(props.interviewQuestion.required_for))

const removeOption = (idx, id, position) => {
  options.value.splice(idx, 1)
  deleteQuestionOptions(id, position)
}

const beforeUpdateQuestionOptions = (questionId, optionPos, params, idx) => {
  if (options.value.filter(option => option.value === params.value && optionPos !== option.position).length !== 0) {
    removeOption(idx, questionId, optionPos)
    setBgColor('negativeRed')
    setMsg('There can\'t be two identical options!')
    displaySnackBar()
  } else {
    updateQuestionOptions(questionId, optionPos, params)
  }
}

const createOption = () => {
  if (options.value && options.value.length > 0) {
    var maxPos = getMaxPosition(options.value);
    options.value.push({ value: '', position: maxPos + 1 });
    createQuestionOptions(props.interviewQuestion.id, maxPos + 1);
  } else {
    options.value.push({ value: '', position: 1 });
    createQuestionOptions(props.interviewQuestion.id, 1);
  }
};

const getMaxPosition = (options) => {
  return Math.max(...options.map(option => option.position));
};
const options = ref(props.interviewQuestion.options)


watch(requiredFor, (newVal, oldVal) => {
  for (const newValKey in newVal) {
    if (!visibleFor.value.includes(newVal[newValKey])) {
      visibleFor.value.push(newVal[newValKey])

      updateQuestion(props.interviewQuestion.id, {visible_for: tools.deduceToBackend(visibleFor.value)})
    }
  }
})

watch(visibleFor, (newVal, oldVal) => {
  for (const newValKey in requiredFor.value) {
    if (!newVal.includes(requiredFor.value[newValKey])) {
      const index = requiredFor.value.indexOf(requiredFor.value[newValKey])
      requiredFor.value.splice(index, 1)

      updateQuestion(props.interviewQuestion.id, {required_for: tools.deduceToBackend(requiredFor.value)})
    }
  }
})
</script>
