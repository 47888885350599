<template>
  <div
    v-if="answers.length"
    class="w-full flex flex-col gap-2 items-center justify-center"
  >
    <v-card
      v-for="(item, idx) in answers"
      :key="item"
      class="p-5 w-[99%] flex flex-col gap-2"
    >
      <div class="flex flex-row gap-3 relative">
        <div v-if="isAnonymous" class="w-2/12 flex justify-center mt-1 sm:w-1/12">
          <v-icon
            size="35"
            color="blue"
          >
            mdi-account
          </v-icon>
        </div>

        <div v-else class="hidden sm:flex">
          <svn-avatar
            :avatar="item.user?.avatar?.[30]"
            :firstname="item.user?.fullname.split(' ')[0]"
            :lastname="item.user?.fullname.split(' ')[1]"
            size="md"
          />
        </div>

        <div class="flex flex-col space-y-3 md:w-full">
          <p class="text-sm">
            {{ isAnonymous ? `Participant ${idx + 1}` : item.user?.fullname }}
          </p>
          <div
            v-if="answer?.type !== InterviewQuestionType.MCQ && answer?.type !== InterviewQuestionType.RATING"
            class="w-10/12 bg-veryLightGrey p-2 rounded-sm"
          >
            {{ item.answer }}
          </div>
          <v-chip
            v-else-if="answer?.type === InterviewQuestionType.RATING"
            class="w-fit bg-veryLightGrey"
            color="veryLightGrey"
          >
            <v-rating
              v-model="item.answer"
              color="primary"
              density="compact"
              size="small"
              empty-icon=""
              readonly
              :length="item.answer"
            />
          </v-chip>
          <v-chip
            v-else-if="answer?.type === InterviewQuestionType.MCQ"
            class="w-fit bg-veryLightGrey"
            color="fakeBlack"
          >
            {{ item.answer }}
          </v-chip>
          <p>
            {{ item.comments }}
          </p>
        </div>
        
        <div class="absolute -top-1 right-5">
          <v-icon
            class="hover:cursor-pointer"
            @click="pin(idx, answer.id, interviewFormId, item.id, !item.pinned)"
          >
            {{ item.pinned ? "mdi-pin" : "mdi-pin-outline" }}
          </v-icon>
          <v-icon
            class="hover:cursor-pointer"
            @click="open(idx)"
          >
            {{ item.open ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </div>
      </div>

      <v-expand-transition>
        <div
          v-show="item.open"
          class="ml-11 flex flex-col gap-3"
        >
          <div class="w-full flex flex-row flex-wrap justify-start items-center space-x-2">
            <v-chip
              v-for="user_tag in item.user.tags"
              :key="user_tag"
              label
              class="text-lg py-2 overflow-visible my-1"
            >
              {{ user_tag.tag_name }}
            </v-chip>
          </div>

          <bkt-survey-comments
            v-if="item.open"
            :answer-user="item.user"
            :comments="item.messages"
            :answer-interview-id="item.id"
            :is-trashed="isTrashed"
            :current-idx="idx"
            :is-anonymous="isAnonymous"
          />
        </div>
      </v-expand-transition>
    </v-card>
  </div>
  <div
    v-else
    class="mx-auto w-full text-center p-10 bkt-dark-grey"
  >
    {{ $t('No answers yet') }}
  </div>
</template>

<script setup>
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";
import { InterviewQuestionType } from '@/constants/types';

const props = defineProps({
  isAnonymous: {type: Boolean, default: false},
  answer: {type: Object, default() {return {}}},
  answers: {type: Array, required: true},
  interviewFormId: {type: Number, required: true},
  isTrashed: { type: Boolean, default: true },
})

const emit = defineEmits(["pin", "open", "add_comment"])

const pin = (idx, question_id, interview_form_id, interview_answer_id, pin_answer) => {
  emit("pin", idx, question_id, interview_form_id, interview_answer_id, pin_answer)
}

const open = (idx) => {
  emit("open", idx)
}

</script>
