<template>
  <div class="w-full flex flex-col relative bg-white py-14">
    <!-- Desktop view -->
    <bkt-index-skeleton body-class="bkt-bg-white rounded mb-32 px-0 pt-0 sm:!pt-5 pb-0 hidden sm:block">
      <template #title>
        <p class="mb-5 hidden sm:block text-[24px] font-medium sm:!mb-0 px-5">
          {{ $t("Trainings") }}
        </p>
      </template>

      <template #create-index>
        <div
          v-if="isManagerCreatorOrAbove()"
          class="flex flex-row justify-center hidden sm:!block sm:!justify-end mt-5 sm:!mt-0 px-5 h-fit"
        >
          <v-btn
            color="primary"
            flat
            prepend-icon="mdi-plus"
            theme="learn"
            class="flex px-[16px] py-[23px] normal-case"
            @click="goToCreateTraining"
          >
            {{ $t("Create training") }}
          </v-btn>
        </div>
      </template>

      <template #body>
        <div class="w-full relative">
          <!-- Tabs -->
          <v-tabs
            v-model="tab"
            color="primary"
            align-tabs="center"
          >
            <!-- published trainings tab -->
            <v-tab
              value="published"
              color="primary"
            >
              <div class="flex flex-row justify-center items-center">
                <p class="!normal-case">
                  {{ $t('Launched') }}
                </p>
                <span
                  class="px-2 rounded-[5px] text-sm ml-2 bg-trainingOrangeSoft"
                >
                  {{ learnTrainings?.length || 0 }}
                </span>
              </div>
            </v-tab>

            <!-- Upcoming trainings tab -->
            <v-tab
              v-if="isManagerCreatorOrAbove()"
              value="scheduled"
              color="primary"
            >
              <div class="flex flex-row justify-center items-center">
                <p class="!normal-case">
                  {{ $t('Upcoming') }}
                </p>
                <span
                  class="px-2 rounded-[5px] text-sm ml-2 bg-trainingOrangeSoft"
                >
                  {{ learnUpcomingTrainings?.length || 0 }}
                </span>
              </div>
            </v-tab>
          </v-tabs>


          <!-- Windows -->
          <v-window
            v-model="tab"
          >
            <v-window-item value="published">
              <learn-table
                :trainings="learnTrainings"
                :trainings-loading="learnTrainingsLoading"
                type="published"
                @delete-training="destroyTraining($event)"
              />
            </v-window-item>

            <v-window-item value="scheduled">
              <learn-table
                :trainings="learnUpcomingTrainings"
                type="scheduled"
                @delete-training="destroyTraining($event)"
              />
            </v-window-item>
          </v-window>

          <!-- Search and Settings -->
          <div
            class="flex gap-4 xl:flex top-0 right-5 absolute items-center"
          >
            <div
              class="hidden lg:flex lg:-w-[100px] xl:w-[200px] 2xl:w-[300px]"
            >
              <!-- Searchbar -->
              <my-trainings-search
                @fetch="reloadAfterSearch"
              />
            </div>

            <!-- Settings on all: icon mdi-dots-vertical -->
            <div
              v-if="isManagerCreatorOrAbove()"
              class="hidden sm:flex sm:h-12 items-center"
            >
              <bkt-learn-list-settings
                :content-values="settingsOnAllItems"
              />
            </div>
          </div>


          <div class=" hidden xl:flex top-0 right-0 pr-5 absolute" />

          <!-- Searchbar
          <div class="w-[300px] hidden xl:flex top-0 right-5 pr-5 absolute">
            <my-trainings-search
              @fetch="reloadAfterSearch"
            />
          </div> -->
        </div>
      </template>
    </bkt-index-skeleton>

    <!-- Mobile view -->
    <div class="w-full relative flex flex-col sm:hidden">
      <!-- Header -->
      <div class="flex flex-col px-6 pb-4 gap-8">
        <div class="flex items-center self-stretch">
          <p class="w-full text-fakeBlack text-center text-xl font-semibold">
            {{ $t('Trainings') }}
          </p>
        </div>

        <div class="flex self-stretch gap-2">
          <my-trainings-search
            @fetch="reloadAfterSearch"
          />

          <!-- Settings on all: icon mdi-dots-vertical -->
          <div
            class="flex h-12 w-12 justify-center items-center"
          >
            <bkt-learn-list-settings
              :content-values="settingsOnAllItems"
            />
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        class="sticky top-0 border-b border-b-lightGrey"
        color="fakeBlack"
        grow
        align-tabs="center"
      >
        <!-- published tab -->
        <v-tab
          value="published"
          color="primary"
        >
          <div class="flex flex-row justify-center items-center">
            <p class="normal-case">
              {{ $t('Launched') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-trainingOrangeSoft"
            >
              {{ learnTrainings?.length || 0 }}
            </span>
          </div>
        </v-tab>

        <!-- Upcoming tab -->
        <v-tab
          v-if="isManagerCreatorOrAbove()"
          value="scheduled"
          color="primary"
        >
          <div class="flex flex-row justify-center items-center">
            <p class="normal-case">
              {{ $t('Upcoming') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-trainingOrangeSoft"
            >
              {{ learnUpcomingTrainings?.length || 0 }}
            </span>
          </div>
        </v-tab>
      </v-tabs>

      <!-- Windows -->
      <v-window
        v-model="tab"
      >
        <v-window-item
          value="published"
          class="mb-24"
        >
          <learn-table
            :trainings="learnTrainings?.sort((a, b) => new Date(b.launched_at) - new Date(a.launched_at))"
            type="published"
            @delete-training="destroyTraining"
          />
        </v-window-item>

        <v-window-item
          value="scheduled"
          class="mb-24"
        >
          <learn-table
            :trainings="learnUpcomingTrainings"
            type="scheduled"
            @delete-training="destroyTraining"
          />
        </v-window-item>
      </v-window>

      <!-- Scroll to top button -->
      <svn-floating-action-button
        square
        mini
        :double-icon="true"
        to-top
      />

      <!-- Create training button -->
      <svn-floating-action-button
        default
        :text="$t('New training')"
        icon="mdi-plus"
        @click="goToCreateTraining"
      />

      <!-- Modal Deleted Items to CUSTOMIZE -->
      <modal-view-deleted-items
        ref="deletedItems"
        content-type="training"
        :contents="trashTrainings"
        @toggle-search="toggleSearchDeletedItems"
        @restore="restoreDeletedItems"
        @delete-permanently="deletePermanentlyDeletedItems"
        @view-item="viewDeletedItem"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import { storeToRefs } from "pinia";
import { useLearnTrainingStore } from "@/store/learn-trainings";
import MyTrainingsSearch from "@/components/trainingApp/myTrainings/MyTrainingsSearch.vue";
import LearnTable from "@/components/trainingApp/myTrainings/LearnTable.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useSnackbar } from "@/store/snackbar";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import i18n from '@/plugins/i18n';
import {debounce} from "lodash";
import {useMobileStore} from "@/store/mobile.js";
const { isManagerCreatorOrAbove } = useUserStore();

import { useBreadcrumbsStore } from '@/store/breadcrumbs';
const breadcrumbsStore = useBreadcrumbsStore();
const { addNode, update } = breadcrumbsStore


onMounted(async() => {
  try {
    await fetchTrainings()
  } catch (error) {

  }

  try {
    await fetchUpcomingTrainings()
  } catch (error) {

  }

  try {
    await fetchTrashTrainings()
  } catch (error) {
    console.log(error)
  }

});

const mobileStore = useMobileStore()
const {isMobile} = storeToRefs(mobileStore)

const tab = ref('published');

const alignTop = ref(false)

const snackbar = useSnackbar()

const router = useRouter()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const deletedItems = ref(null)

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted trainings'),
    onClick: openDeletedItems
  }
])

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashTrainings(value)
  } catch (e) {
    console.error(e)
  }
}, 300)


const viewDeletedItem = async (id) => {
  try {
    goToTraining("Training show", id)
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while showing deleted training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const goToTraining = (title, trainingId) => {
  addNode(title, `/learns/training/${trainingId}/show`)
  update()
  router.push({ name: 'training_show', params: { id: trainingId } })
}

const restoreDeletedItems = async (id) => {
  try {
    await restoreTraining(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Training restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteTrainingPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Training deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const { fetchTrainings, fetchUpcomingTrainings,
  deleteTraining, fetchTrashTrainings, restoreTraining, deleteTrainingPermanently } = useLearnTrainingStore()

const { learnTrainingsLoading ,learnTrainings, learnUpcomingTrainings, trashTrainings } = storeToRefs(useLearnTrainingStore())

const goToCreateTraining = async() => {
  router.push({ name: "training_new" })
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const reloadAfterSearch = async(data) => {
  try {
    if (tab.value == 'published') {
      await fetchTrainings(data)
    } else if (tab.value == 'scheduled') {
      await fetchUpcomingTrainings(data)
    }
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const destroyTraining = async(id) => {
  await deleteTraining(id)

  if (tab.value === 'published') {
    const trashTraining = learnTrainings.value.find(training => training?.id === id)
      trashTraining.deleted_at = new Date()
      trashTrainings.value.push(trashTraining)
    learnTrainings.value = learnTrainings.value.filter((training) => training.id != id)
  } else if (tab.value === 'scheduled') {
    const trashTraining = learnUpcomingTrainings.value.find(training => training?.id === id)
    trashTraining.deleted_at = new Date()
    trashTrainings.value.push(trashTraining)
    learnUpcomingTrainings.value = learnUpcomingTrainings.value.filter((training) => training.id != id)  }
}
</script>
