<template>
  <v-card
    class="rounded-[12px]"
  >
    <div class="relative flex flex-col justify-center items-center p-4">
      <Icon
        icon="carbon-template"
        color="#5C95FF"
        width="32"
      />

      <svn-dots-button
        circle
        variant="hoverNoPrimary"
        class="absolute top-1 right-0"
      >
        <template #items>
          <v-list-item
            value="delete_template"
            class="text-negativeRed"
            @click="openPopUpDelete(template?.id)"
          >
            {{ $t('Delete template') }}
          </v-list-item>
        </template>
      </svn-dots-button>
  
      <p class="text-base text-center text-fakeBlack font-medium mx-11 mt-2 mb-2 text-ellipsis">
        {{ template?.title }}
      </p>
  
      <p class="text-sm text-darkGrey font-normal">
        {{ $t('Due date : ', { dueDate: template?.due_date }) }}
      </p>

      <p class="text-sm text-darkGrey font-normal mb-6">
        {{ $t('Last update : ', { lastUpdate: getUpdatedAtDate(template?.updated_at) }) }}
      </p>
  
      <my-roadmaps-objective-indicator :objective-indicator="template.objective_indicator" />
    </div>
  </v-card>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { usePopUpStore } from '@/store/pop-up';
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import MyRoadmapsObjectiveIndicator from './MyRoadmapsObjectiveIndicator.vue';
import { useRoadmapTemplateStore } from "@/store/roadmap-template.js";
import moment from 'moment';

const props = defineProps({
  template: { type: Object, required: true },
});

const emit = defineEmits(['update']);

const snackbar = useSnackbar();
const { openPopUp, closePopUp } = usePopUpStore();
const { fetchRoadmapTemplates } = useRoadmapTemplateStore();

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this template ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No",
    textConfirm: "Yes, delete this template",
    color: 'red',
    customClass: '',
    async confirm() {
      try {
        await axiosService.delete(`/api/v1/roadmaps/templates/${id}`);
        closePopUp();
        emit('update');
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your template has been deleted !');
        await fetchRoadmapTemplates();
        // await router.push({name: "roadmaps_templates"});
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error deleting your template !');
      }
    },
  })
}

const getUpdatedAtDate = (date) => {
  const newDate = moment(date);
  return newDate.format('YYYY-MM-DD');
}
</script>