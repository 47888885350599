<template>
  <router-view />
</template>
  
<script setup>
  import AppsHeader from '../components/header/AppsHeader.vue'
  import {useRoute} from 'vue-router'
  import {computed, onUpdated, ref} from "vue";
  import {useTheme} from "vuetify";

  let routeName = ref(useRoute().name)

  onUpdated(() => {
    routeName.value = useRoute().name
  })
</script>