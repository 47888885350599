<template>
  <v-data-table
    :loading="!targets"
    :items="targets ? targets : []"
    :items-per-page="-1"
    class="hidden sm:block mt-4"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in targets && targets.length !== 0 ? headers : []"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey text-sm font-medium"
        >
          <span>{{ header.title }}</span>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr>
        <td class="border-r border-r-lightGrey cursor-pointer bkt-bg-light-grey9-hover">
          <div
            class="flex flex-row justify-start items-center h-14"
            @click="goToPath($t('See \'s profile', { reviewers: `${item.raw.firstname} ${item.raw.lastname}` }), `/people/user_show/${item.raw.id}`)"
          >
            <svn-avatar
              :avatar="item.raw.avatars?.['30']"
              :firstname="item.raw.firstname"
              :lastname="item.raw.lastname"
              size="lg"
              class="mr-3"
            />

            <div class="flex flex-col justify-center items-start">
              <div class="flex flex-wrap max-w-[200px]">
                <p class="font-medium text-fakeBlack text-sm">
                  {{ item?.raw.fullname }}
                </p>
              </div>

              <div class="flex flex-wrap max-w-[200px]">
                <p class="font-normal text-darkGrey text-xs">
                  {{ item?.raw.job_title }}
                </p>
              </div>
            </div>
          </div>
        </td>

        <td class="px-0">
          <v-btn
            v-for="(element, idx) in item.raw.objective_elements"
            :key="idx"
            variant="text"
            height="56"
            :class="idx !== item.raw.objective_elements.length -1 ? 'border-b border-b-lightGrey' : ''"
            class="flex flex-col items-start rounded-none px-4 w-full"
            @click="goToTarget(element?.title, element?.id)"
          >
            <div class="max-w-[400px]">
              <p
                class="text-fakeBlack text-sm font-normal normal-case text-truncate"
              >
                {{ element.title }}
              </p>
            </div>
          </v-btn>
        </td>

        <td class="px-0">
          <v-btn
            v-for="(element, idx) in item.raw.objective_elements"
            :key="idx"
            variant="text"
            height="56"
            :class="idx !== item.raw.objective_elements.length -1 ? 'border-b border-b-lightGrey' : ''"
            class="flex flex-col items-start rounded-none px-4 w-full"
            @click="goToTarget(element?.title, element?.id)"
          >
            <div class="flex flex-row justify-start">
              <my-roadmaps-objective-indicator :objective-indicator="element.objective_indicator" />
            </div>
          </v-btn>
        </td>

        <td class="px-0">
          <v-btn
            v-for="(element, idx) in item.raw.objective_elements"
            :key="idx"
            variant="text"
            height="56"
            :class="idx !== item.raw.objective_elements.length -1 ? 'border-b border-b-lightGrey' : ''"
            class="flex flex-col max-w-[225px] items-start rounded-none px-4 w-full"
            @click="goToTarget(element?.title, element?.id)"
          >
            <div class="flex flex-row justify-start">
              <p
                class="text-fakeBlack text-sm font-normal normal-case text-truncate"
              >
                {{ element.due_date }}
              </p>
            </div>
          </v-btn>
        </td>

        <td class="px-0">
          <v-btn
            v-for="(element, idx) in item.raw.objective_elements"
            :key="idx"
            variant="plain"
            height="56"
            :class="idx !== item.raw.objective_elements.length -1 ? 'border-b border-b-lightGrey' : ''"
            class="flex flex-col items-start rounded-none cursor-pointer"
          >
            <svn-dots-button
              table
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  v-if="type === 'unarchived'"
                  value="unarchived"
                  @click="openPopUpArchive(element?.id)"
                >
                  {{ $t('Archive target') }}
                </v-list-item>

                <v-list-item
                  v-if="type !== 'unarchived'"
                  value="different_than_unarchived"
                  @click="openPopUpUnarchive(element?.id)"
                >
                  {{ $t('Unarchive target') }}
                </v-list-item>

                <v-list-item
                  value="delete"
                  class="text-negativeRed"
                  @click="openPopUpDelete(element?.id)"
                >
                  {{ $t('Delete') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </v-btn>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && targets && targets.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="p-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>
        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col jsutify-center items-center my-16">
        <Icon
          class="mb-5"
          icon="noto-winking-face"
          height="24"
          width="24"
        />

        <p class="font-medium text-sm mb-5">
          {{ type === 'unarchived' ? $t('Hmm, it seems you have no targets yet. Create your first target !') :
            $t('Hmm, it seems you have no archived targets.') }}
        </p>
        <bkt-new-target-button />
      </div>
    </template>
  </v-data-table>

  <div
    v-if="!targets"
    class="flex flex-col justify-center items-center sm:hidden px-4 py-4"
  >
    <svn-loader loading-size="xl" class="mt-8 mb-8" />
  </div>

  <div
    v-if="targets && targets.length === 0"
    class="flex flex-col justify-center items-center my-16 sm:hidden text-center mx-9"
  >
    <Icon
      class="mb-5"
      icon="noto-winking-face"
      height="24"
      width="24"
    />

    <p class="font-medium text-sm mb-5">
      {{ type === 'unarchived' ? $t('Hmm, it seems you have no targets yet. Create your first target !') :
        $t('Hmm, it seems you have no archived targets.') }}
    </p>

    <bkt-new-target-button />
  </div>

  <div
    v-for="target in targets"
    :key="target?.id"
    class="block sm:hidden border-b border-b-lightGrey"
  >
    <my-team-roadmaps-card
      :type="type"
      :roadmap="target"
      @update="emit('update')"
    />
  </div>
</template>

<script setup>
import MyRoadmapsObjectiveIndicator from "../MyRoadmapsObjectiveIndicator.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import { Icon } from '@iconify/vue'
import MyTeamRoadmapsCard from "../MyTeamRoadmapsCard.vue";
import { usePopUpStore } from "@/store/pop-up";
import axiosService from "@/tools/axios-service.js";
import { useSnackbar } from "@/store/snackbar.js";
import i18n from "@/plugins/i18n.js";
import { useRouter } from "vue-router";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";

const router = useRouter()
const snackbar = useSnackbar();
snackbar.checkStatus();

const headers = [
  {
    align: "start",
    key: "people",
    sortable: false,
    title: i18n.global.t('People'),
  },
  {
    align: "start",
    key: "targets",
    sortable: false,
    title: i18n.global.t('Targets'),
  },
  {
    align: "start",
    key: "completion",
    sortable: false,
    title: i18n.global.t('Completion'),
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
  }
];

const props = defineProps({
  targets: { type: Array, required: false },
  type: { type: String, required: true },
  pagination: {type: Object, default: () => {return null} }
})

const emit = defineEmits(['update', 'reload-page', 'delete'])

const { goToPath } = useBreadcrumbsStore()
const { openPopUp, closePopUp } = usePopUpStore();
const { addNode, update } = useBreadcrumbsStore()

const goToTarget = (title, id) => {
  addNode(title, `/roadmaps/targets/${id}`)
  update()
  router.push({ name: 'roadmaps_targets_show', params: { id: id } })
}

const openPopUpArchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to archive this target ?',
    subtitle: 'You will find this target in the "Archived targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, archive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'archived'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been archived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while archiving your target !')
      }
    },
  })
}

const openPopUpUnarchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to unarchive this target ?',
    subtitle: 'You will find this target in the "Current targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, unarchive this target",
    color: 'blue',
    customClass: '',
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'opened'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been unarchived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while unarchiving your target !')
      }
    },
  })
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this target ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No",
    textConfirm: "Yes, delete",
    color: 'red',
    customClass: '',
    async confirm() {
      try {
        emit('delete', id)
        closePopUp()
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been deleted.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while deleting your target !')
      }
    },
  })
}

const fetchNewPage = (page) => {
  emit('reload-page', page)
}
</script>
