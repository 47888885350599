<template>
  <div
    v-for="(content, index) in trainingContent"
    :key="index"
    class="w-full"
  >
    <!-- Content is a Module -->
    <module-card-in-training
      v-if="content?.content_type === 'Learn::Module'"
      :module="content"
      :is-module="true"
      @click="goToContentShow(content)"
    />

    <!-- Content is a Playlist -->
    <div
      v-if="content?.content_type === 'Learn::Playlist'"
      class="flex flex-col justify-center items-start gap-3 p-4 rounded-[12px] border border-darkGrey cursor-pointer"
    >
      <!-- Modules count -->
      <div class="flex flex-col items-start gap-1 self-stretch">
        <!-- PLaylist title & icon -->
        <div class="flex items-center gap-2">
          <Icon
            icon="ic:outline-play-circle"
            height="16"
            width="16"
            class="text-darkGrey"
          />

          <!-- Playlist title -->
          <p class="text-fakeBlack text-base font-medium line-clamp-1">
            {{ content?.title }}
          </p>
        </div>

        <!-- Number of modules in the Playlist -->
        <div class="flex items-center">
          <p class="text-darkGrey text-sm font-medium">
            {{ content?.learn_modules?.length }}
            {{ (content?.learn_modules?.length === 0 || content?.learn_modules?.length === 1) ? ' module' : 'modules' }}
          </p>
        </div>
      </div>

      <div
        class="flex flex-col justify-center items-start self-stretch"
      >
        <module-card-in-training
          v-for="(module) in content?.learn_modules"
          :key="module?.id"
          :module="module"
          :is-module="false"
          @click="goToContentShow(module)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import ModuleCardInTraining from './ModuleCardInTraining.vue';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const props = defineProps({
  trainingContent: { type: Array, default: null },
})

const emit = defineEmits(['go-to-content-show'])

const goToContentShow = (content) => {
  updateBreadcrumbs(content?.title)
  emit('go-to-content-show', content)
}

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/catalog')
  update()
}
</script>