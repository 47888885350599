<template>
  <pop-up-skeleton
    :title="$t('Schedule a meeting')"
    icon="material-symbols:today"
  >
    <template #body>
      <div class="flex flex-row mb-2">
        <Icon
          icon="bx-time-five"
          color="darkgrey"
          width="20"
          height="20"
        />
        <p class="text-sm font-medium text-darkGrey text-center">
          {{ $t('Date and time') }}
        </p>
      </div>

      <div class="flex flex-col sm:flex-row">
        <div class="mt-2 w-full">
          <svn-date-picker
            v-model="selectedDate"
            :label="$t('Select a date')"
          />
        </div>

        <div class="sm:w-5 mt-2" />

        <div class="w-full flex flex-row mt-2 justify-center items-center">
          <input
            id="startTimeInput"
            v-model="startSelectedDate"
            class="input w-full h-12"
            type="time"
            :onchange="updateStartTimeValue"
          >

          <Icon
            icon="bi:dash"
            width="25"
            height="25"
          />

          <input
            id="endTimeInput"
            v-model="endSelectedDate"
            class="input w-full h-12"
            type="time"
            :onchange="updateEndTimeValue"
          >
        </div>
      </div>

      <div class="flex justify-between items-center">
        <div class="mr-3">
          <input
            id="check"
            v-model="sendEmailChekbox"
            type="checkbox"
            @click="change($event)"
          >
        </div>

        <p class="text-sm text-start font-normal text-fakeBlack">
          {{ $t('Send an email to notify people and schedule meeting in Agenda') }}
        </p>
      </div>
    </template>

    <template #buttons>
      <div class="w-full flex justify-center">
        <v-btn
          color="primary"
          @click="updateDate"
        >
          {{ $t('Save') }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
  import { ref } from 'vue'
  import { Icon } from "@iconify/vue";
  import axios from "../../tools/axios-service.js"
  import { usePopUpStore } from "../../store/pop-up.js";
  import PopUpSkeleton from './PopUpSkeleton.vue';
  import moment from "moment";

   const props = defineProps({
    campaignId: { type: Number, default: null},
    crossId: { type: Number, default: null},
    startDate: { type: String, default: null},
    endDate: { type: String, default: null},
    date: { type: String, default: null}
  })

  const { closePopUp, callback } = usePopUpStore();
  const sendEmailChekbox = ref("false")

  const dateInput = ref()
  const startTimeInput = ref()
  const endTimeInput = ref()
  const selectedDate = ref()
  const startSelectedDate = ref()
  const endSelectedDate = ref()

  const change = () => {
    if (sendEmailChekbox.value === false) {
      sendEmailChekbox.value = true
      return true
    }
    else {
      sendEmailChekbox.value = false
      return false
    }
  }

  const updateStartTimeValue = (e) => {
    startTimeInput.value = e.target.value
  }

  const updateEndTimeValue = (e) => {
    endTimeInput.value = e.target.value
  }

  const updateDate = async () => {
    const day = moment(dateInput?.value).format('DD')
    const month = moment(dateInput?.value).format('MM')
    const year = moment(dateInput?.value).format('YYYY')
    const startHour = parseInt(startTimeInput?.value?.split(':')[0], 10).toString()
    const endHour = (parseInt(endTimeInput?.value?.split(':')[0], 10)).toString()
    const startMin = startTimeInput?.value?.split(':')[1]
    const endMin = endTimeInput?.value?.split(':')[1]
    const hourDuration = Math.abs(Number(endHour) - Number(startHour))
    const minDuration = Math.abs(Number(endMin) - Number(startMin))
    const duration = hourDuration * 60 + minDuration

    try {
      await axios.patch(`/api/v2/interview_app/campaigns/${props.campaignId}/interviews/${props.crossId}.json`,
        {}, {params: { interview: {
        year: year,
        month: month,
        day: day,
        hour: startHour,
        min: startMin,
        duration: duration,
        offset: ((new Date().getTimezoneOffset()) / 60)
      }, send_email: sendEmailChekbox.value }})
      closePopUp()
    } catch (e) {
    } finally {
      callback()
    }
  }
</script>

<style scoped>
.input {
  border: 1px solid #787878 !important;
  border-radius: 4px;
}
</style>
