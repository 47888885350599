<template>
  <div
    :class="getBgWithBorderByCampaignStatus(status)"
    class="bkt-white rounded py-1 px-2 flex items-center space-x-2 w-fit"
  >
    <div
      :class="getDotColorByCampaignStatus(status)"
      class="h-2 w-2 rounded-full"
    />
    <p class="text-sm font-medium">
      {{ $t($filters.capitalize(status.split('_').join(' '))) }}
    </p>
  </div>
</template>
<script setup>
import useCampaigns from "@/tools/useCampaigns.js";
defineProps({
  status: { type: String, default: '' }
})
const {getBgWithBorderByCampaignStatus, getDotColorByCampaignStatus} = useCampaigns()
</script>
