<template>
  <div
    class="w-full sm:min-w-[100px] md:min-w-[520px] flex flex-col items-center mx-auto
        sm:w-4/5
      lg:w-[52rem]"
  >
    <svn-hello-banner
      app="interview"
      :firstname="firstname"
      :url="myTeamInterviewsBanner?.['500']"
      :subtitle="$t('Here are all the interviews you are in charge of.')"
    />

    <v-tabs
      v-model="tab"
      class="w-full sticky top-[75px] z-20 bg-background border-b mb-2"
      :grow="isMobile"
    >
      <v-tab class="normal-case flex flex-row">
        {{ $t('All') }}
        <div
          class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm"
        >
          <span class="text-xs">{{ campaignsPagi?.total_count || 0 }}</span>
        </div>
      </v-tab>
      <v-tab class="normal-case flex flex-row">
        {{ $t('To do') }}
        <div
          class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm"
        >
          <span class="text-xs">{{ campaignsToDoPagi?.total_count || 0 }}</span>
        </div>
      </v-tab>
      <v-tab class="normal-case flex flex-row">
        {{ $t('Done') }}
        <div
          class="ml-2 py-1 px-1 flex justify-center bg-lightGrey rounded text-sm"
        >
          <span class="text-xs">{{ campaignsDonePagi?.total_count || 0 }}</span>
        </div>
      </v-tab>
    </v-tabs>
    <v-window
      v-model="tab"
      class="w-[102%] px-2"
    >
      <v-window-item
        class="w-full"
        :value="0"
      >
        <MyTeamInterviewList
          v-if="campaigns"
          :campaigns="campaigns"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[0]"
          class="mt-16 max-w-md mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
      <v-window-item
        class="w-full"
        :value="1"
      >
        <MyTeamInterviewList
          v-if="campaignsToDo"
          :campaigns="campaignsToDo"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[1]"
          class="mt-16 max-w-sm mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
      <v-window-item
        class="w-full"
        :value="2"
      >
        <MyTeamInterviewList
          v-if="campaignsDone"
          :campaigns="campaignsDone"
          @pagination="load"
        />
        <BktFailToLoad
          v-else-if="error[2]"
          class="mt-16 max-w-sm mx-auto"
        />
        <div v-else class="flex h-[30vh] justify-center items-center h-full w-full">
          <svn-loader loading-size="lg" />
        </div>
      </v-window-item>
    </v-window>
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>
<script setup>
  import { useCompanyStore } from "@/store/company.js";
  import { useUserStore } from "@/store/user.js";
  import { useMyTeamInterviewsStore } from "@/store/my-team-interviews.js";
  import { storeToRefs } from "pinia";
  import BktFailToLoad from "@/components/BktFailToLoad.vue";
  import {onMounted, ref} from "vue";
  import {useMobileStore} from "@/store/mobile.js";
  import MyTeamInterviewList from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewList.vue";
  import i18n from "@/plugins/i18n.js";
  import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";

  // Refs
  const {
    campaigns,
    campaignsPagi,
    campaignsToDo,
    campaignsToDoPagi,
    campaignsDone,
    campaignsDonePagi
  } = storeToRefs(useMyTeamInterviewsStore());

  const { myTeamInterviewsBanner } = storeToRefs(useCompanyStore());
  const { firstname, lastname, id, avatar } = storeToRefs(useUserStore());
  const { addNode, reset, update } = useBreadcrumbsStore()
  const alignTop = ref(false)
  const {isMobile} = storeToRefs(useMobileStore())
  const tab = ref(0)
  const pages = ref([1, 1, 1])
  const error = ref([false, false,false])

  // Functions
  const { fetchMyTeamInterviews, fetchMyTeamInterviewsToDo, fetchMyTeamInterviewsDone } = useMyTeamInterviewsStore();
  const tabs = [ fetchMyTeamInterviews, fetchMyTeamInterviewsToDo, fetchMyTeamInterviewsDone ]

  const load = async (done) => {
    pages.value[tab.value] += 1
    done(await tabs[tab.value](pages.value[tab.value]))
  }

  // Execute
  onMounted(async () => {
    if (reset('/interviews/my_team_interviews')) {
      addNode(i18n.global.t('My Team Interviews'), '/interviews/my_team_interviews')
      update()
    }
    campaigns.value = null
    campaignsToDo.value = null
    campaignsDone.value = null
    error.value[0] = ((await fetchMyTeamInterviews(1)) === "error")
    error.value[1] = ((await fetchMyTeamInterviewsToDo(1)) === "error")
    error.value[2] = ((await fetchMyTeamInterviewsDone(1)) === "error")
  })

</script>
