<template>
  <v-breadcrumbs
    class="hidden w-full justify-start items-center
     sm:flex p-0"
    data-testid="breadcrumb"
  >
    <div
      v-for="(path, index) in displayedPaths"
      :key="path"
      class="flex flex-row justify-center items-center"
      :data-testid="`div-${index}`"
    >
      <v-breadcrumbs-item class="mx-2">
        <v-btn
          variant="flat"
          class="normal-case font-normal text-darkGrey px-0"
          :data-testid="`title-${index}`"
          @click="goTo(path)"
        >
          {{ path.title }}
        </v-btn>
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider
        class="p-1"
      >
        <Icon
          class="iconify text-middleGrey font-semibold text-xs"
          icon="akar-icons:chevron-right"
        />
      </v-breadcrumbs-divider>
    </div>
    <slot />
  </v-breadcrumbs>
</template>

<script setup>
  import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
  import {storeToRefs} from "pinia";
  import {onMounted, onUnmounted, ref} from "vue";
  import {Icon} from "@iconify/vue";
  import {useRouter} from "vue-router";

  const router = useRouter();

  const { backTo, displayedPaths } = useBreadcrumbsStore()

  const goTo = (o) => {
    backTo(o)
    router.push(o.path)
  }
</script>