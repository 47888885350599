<template>
  <apps-header
    app-icon=""
    app-title=""
  >
    <router-view />
  </apps-header>
</template>
  
<script setup>
  import AppsHeader from '../components/header/AppsHeader.vue'
  import {useRoute} from 'vue-router'
  import {computed, onUpdated, ref} from "vue";
  import {useTheme} from "vuetify";

const theme = useTheme()
theme.global.name.value = 'company'

  let routeName = ref(useRoute().name)

  onUpdated(() => {
    routeName.value = useRoute().name
  })
</script>