<template>
  <div class="w-full flex flex-col relative bg-white">
    <!-- Header component -->
    <my-roadmaps-header
      :user="user"
      :size="64"
      :header-subtitle="'Here you can find all the roadmaps you are in charge of.'"
    />

    <!-- Desktop view -->
    <bkt-index-skeleton body-class="bkt-bg-white rounded mb-32 px-0 pt-0 sm:!pt-5 pb-0 hidden sm:block">
      <template #title>
        <p class="mb-5 hidden sm:block font-medium sm:!mb-0">
          {{ $t("Targets") }}
        </p>
      </template>

      <template #create-index>
        <div class="flex-row justify-center hidden sm:!flex sm:!justify-end mt-5 sm:!mt-0">
          <bkt-new-target-button
            :employees="allUsersInMyTeam.map(user => user.id)"
            from="my_team_roadmaps"
          />
        </div>
      </template>

      <template #body>
        <div
          v-if="user && user.id"
          class="w-full relative"
        >
          <v-tabs
            v-model="tab"
            class="sticky top-0 text-darkGrey"
            color="fakeBlack"
            align-tabs="center"
          >
            <v-tab value="current">
              <div class="flex flex-row justify-center items-center">
                <p>
                  {{ $t('Current targets') }}
                </p>

                <span
                  class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
                  :class="[tab !== 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
                >
                  {{ unarchived?.length || 0 }}
                </span>
              </div>
            </v-tab>

            <v-tab value="archived">
              <div class="flex flex-row justify-center items-center">
                <p>
                  {{ $t('Archived targets') }}
                </p>

                <span
                  class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
                  :class="[tab === 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
                >
                  {{ archived?.length || 0 }}
                </span>
              </div>
            </v-tab>
          </v-tabs>

          <v-window
            v-model="tab"
          >
            <v-window-item value="current">
              <my-team-roadmaps-table
                :targets="unarchived"
                :pagination="unarchivedPagination"
                type="unarchived"
                @reload-page="fetchUnarchived"
                @update="updateValue"
                @delete="deleteTarget($event, 'unarchived')"
              />
            </v-window-item>

            <v-window-item value="archived">
              <my-team-roadmaps-table
                :targets="archived"
                :pagination="archivedPagination"
                type="archived"
                @reload-page="fetchArchived"
                @update="updateValue"
                @delete="deleteTarget($event, 'archived')"
              />
            </v-window-item>
          </v-window>

          <div class="w-[300px] hidden xl:block absolute -top-[10px] right-0 pr-5">
            <my-team-roadmaps-search
              :trashes-targets="trashes"
              @fetch="reloadAfterSearch"
              @search-items="toggleSearchDeletedItems"
              @restore="restoreDeletedItems"
              @delete-permanently="deletePermanentlyDeletedItems"
            />
          </div>
        </div>
      </template>
    </bkt-index-skeleton>

    <!-- Mobile view -->
    <div
      v-if="user && user.id"
      class="w-full relative block sm:hidden"
    >
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        class="sticky top-0 border-b border-b-lightGrey text-darkGrey"
        color="fakeBlack"
        align-tabs="center"
      >
        <v-tab value="current">
          <div class="flex flex-row justify-center items-center">
            <p>
              {{ $t('Current targets') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
              :class="[tab !== 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
            >
              {{ unarchived?.length || 0 }}
            </span>
          </div>
        </v-tab>

        <v-tab value="archived">
          <div class="flex flex-row justify-center items-center">
            <p>
              {{ $t('Archived targets') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
              :class="[tab === 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
            >
              {{ archived?.length || 0 }}
            </span>
          </div>
        </v-tab>
      </v-tabs>

      <!-- Windows -->
      <v-window
        v-model="tab"
      >
        <v-window-item
          value="current"
          class="mb-24"
        >
          <my-team-roadmaps-table
            :targets="unarchived"
            :pagination="unarchivedPagination"
            type="unarchived"
            @reload-page="fetchUnarchived"
            @update="updateValue"
          />
        </v-window-item>

        <v-window-item
          value="archived"
          class="mb-24"
        >
          <my-team-roadmaps-table
            :targets="archived"
            :pagination="archivedPagination"
            type="archived"
            @reload-page="fetchArchived"
            @update="updateValue"
          />
        </v-window-item>
      </v-window>

      <!-- Scroll to top button -->
      <svn-floating-action-button
        square
        mini
        to-top
        :double-icon="true"
      />

      <!-- New target button -->
      <svn-floating-action-button
        default
        :text="$t('New target')"
        icon="mdi-plus"
        @click="openPopUpCreateTarget"
      />
    </div>
  </div>
</template>

<script setup>
import axiosService from "@/tools/axios-service.js";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import { usePopUpStore } from "@/store/pop-up";
import MyTeamRoadmapsSearch from "@/components/roadmapApp/MyTeamRoadmapsSearch.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import MyTeamRoadmapsTable from "@/components/roadmapApp/myTeamRoadmap/table.vue";
import { useRoute } from "vue-router";
import { useSnackbar } from "@/store/snackbar";
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import MyRoadmapsHeader from "@/components/roadmapApp/MyRoadmapsHeader.vue";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import i18n from "@/plugins/i18n.js";
import axios from "@/tools/axios-service.js";
import {useMyTeamRoadmapUnarchivedStore} from "@/store/my-team-roadmap-unarchived.js";
import {useMyTeamRoadmapArchivedStore} from "@/store/my-team-roadmap-archived.js";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import { debounce } from "lodash";
import { useMobileStore } from "@/store/mobile";

const route = useRoute();
const tab = ref('current');
const currentUser = storeToRefs(useUserStore());
const user = ref(null);
const alignTop = ref(false)
const {users: usersUnarchived, pagination: paginationUnarchived} = storeToRefs(useMyTeamRoadmapUnarchivedStore())
const {users: usersArchive, pagination: paginationArchived} = storeToRefs(useMyTeamRoadmapArchivedStore())

const {reset, addNode, update} = useBreadcrumbsStore()
const {fetchMyTeamRoadmapUnarchived} = useMyTeamRoadmapUnarchivedStore()
const {fetchMyTeamRoadmapArchived} = useMyTeamRoadmapArchivedStore()
const { deletePermanentlyRoadmapTarget, restoreRoadmapTarget, deleteRoadmapTarget } = useRoadmapTargetStore();
const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore())
const { openPopUp } = usePopUpStore();

const allUsersInMyTeam = ref([]);
const unarchived = ref([]);
const unarchivedPagination = ref(null);
const archived = ref([]);
const archivedPagination = ref(null);
const trashes = ref([]);

const onPageSet = async () => {
  await updateValue();
  if (reset(history.state.current)) {
    if (user.value.id === currentUser.id.value)
      addNode(i18n.global.t("My team roadmaps"), history.state.current)
    else
      addNode(i18n.global.t("Manager team roadmaps", {fullname: `${user.value.firstname} ${user.value.lastname}`}), history.state.current)
    update()
  }
}

onMounted(async() => {
  onPageSet();
  await fetchTrashes()

  try {

    const { data } = await axios.get(`/api/v1/users/users_search`,
      { params: {
        only_manager_employees: true,
        "page[size]": 25
      }
    });
    allUsersInMyTeam.value = data.users

  } catch (error) {
    snackbar.setBgColor('negativeRed');
    snackbar.setMsg('Failed to get all users')
    snackbar.displaySnackBar()
  }
});

const reloadAfterSearch = async({title}) => {
  if (tab.value === 'current') {
    fetchUnarchived(1, title)
  } else {
    fetchArchived(1, title)
  }
}

const deleteTarget = async(id, type) => {
  await deleteRoadmapTarget(id)
  if (type === 'unarchived') {
    const user = unarchived.value.find(user => user?.objective_elements.find(trash => trash?.id === id))
    const trash = user.objective_elements.find(trash => trash.id === id)

    trash.employee = {
      id: user.id,
      avatar: user.avatar,
      firstname: user.firstname,
      lastname: user.lastname
    }
    trash.deleted_at = new Date()

    if (user && trash) trashes.value.push(trash)

    unarchived.value?.forEach(user => {
      user.objective_elements = user?.objective_elements?.filter(trash => trash?.id !== id)
    })
  } else if (type === 'archived') {
    const user = archived.value.find(user => user?.objective_elements.find(trash => trash?.id === id))
    const trash = user.objective_elements.find(trash => trash.id === id)
    trash.employee = {
      id: user.id,
      avatar: user.avatar,
      firstname: user.firstname,
      lastname: user.lastname
    }
    trash.deleted_at = new Date()
    if (user && trash) trashes.value.push(trash)

    archived.value?.forEach(user => {
      user.objective_elements = user?.objective_elements?.filter(trash => trash?.id !== id)
    })
  }
}

const fetchTrashes = async (title = null) => {
  const trashesReq = await axiosService.get(`/api/v1/roadmaps/employees/${user.value.id}/team_trashed_targets`,
    {
      params: {
        title
      }
    }
  );
  trashes.value = trashesReq?.data?.objective_elements
}

const updateValue = async () => {
  if (route.params.employee_id) {
    const res = await axiosService.get(
      `/api/v1/users/${route.params.employee_id}`
    );

    user.value = res.data.user;
  } else {
    user.value = {
      id: currentUser.id.value,
      firstname: currentUser.firstname.value,
      lastname: currentUser.lastname.value,
      avatar: currentUser.avatar.value,
      job_title: currentUser.job_title.value,
      employee_ids: currentUser.employeeIds,
    };
  }
  try {
    fetchUnarchived()
    await fetchArchived()
  } catch (e) {
    useSnackbar().setStatus("error").displaySnackBar(e?.message);
  }
};

watch(route, onPageSet)

const openPopUpCreateTarget = () => {
  openPopUp({
    componentName: 'pop-up-create-target',
    icon: '',
    title: '',
    subtitle: '',
    customClass: 'xl:w-[800px] px-0',
    usersFromOverlay: null,
  })
}

const fetchUnarchived = async (page = 1, title = '') => {
  unarchived.value = null
  await fetchMyTeamRoadmapUnarchived(user.value.id, page, title)
  unarchived.value = usersUnarchived.value;
  unarchivedPagination.value = paginationUnarchived.value
}

const fetchArchived = async (page = 1, title = '') => {
  archived.value = null
  await fetchMyTeamRoadmapArchived(user.value.id, page, title)
  archived.value = usersArchive.value;
  archivedPagination.value = paginationArchived.value
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashes(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id)
    trashes.value = trashes.value.filter(trash => trash.id !== id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Target restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePermanentlyRoadmapTarget(id)
    trashes.value = trashes.value.filter(trash => trash.id !== id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Target deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}
</script>
