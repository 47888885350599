<template>
  <v-btn
    color="primary"
    prepend-icon="mdi-plus"
    theme="roadmap"
    @click="openCreateTargetPopUp"
  >
    {{ $t('New Target') }}
  </v-btn>
</template>

<script setup>
import { usePopUpStore } from "@/store/pop-up";

const { openPopUp } = usePopUpStore();

const props = defineProps({
  employees: { type: Array, required: false },
  overlayMode: { type: Boolean, required: false },
  createDraft: { type: Boolean, required: false },
  campaignId: { type: Number, required: false },
  from: { type: String, required: false },
})

const openCreateTargetPopUp = () => {
  openPopUp({
    componentName: 'pop-up-create-target',
    icon: '',
    title: '',
    subtitle: '',
    customClass: 'xl:w-[800px] px-0',
    overlayMode: props.overlayMode,
    usersFromOverlay: props.employees,
    createDraft: props.createDraft,
    campaignId: props.campaignId,
    from: props.from
  })
}
</script>
