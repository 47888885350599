<template>
  <div class="my-4 w-full">
    <table>
      <thead>
        <tr
          :class="headerClass"
          class="border-bottom-bkt-light-grey5 bkt-light-grey6"
        >
          <th
            v-for="(col, idx) in headers"
            :key="idx"
          >
            <p class="font-semibold flex items-center">
              <Icon
                v-if="col.icon"
                :flip="col.flip"
                :icon="col.icon"
                class="mr-0_5rem"
              />
              {{ $t(col.text || col) }}
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in tableData"
          :key="row.id"
          :class="{
            'bkt-bg-light-grey10-hover cursor-pointer': withoutHover === false,
          }"
          class="border-bottom-bkt-light-grey5-not-last-child"
          @click="$emit('row-click', row)"
        >
          <slot v-bind="{ ...row, index }" />
        </tr>
      </tbody>
    </table>

    <v-pagination
      v-if="pagination"
      :model-value="pagination.current_page"
      :length="pagination.total_pages"
      :total-visible="6"
      theme="primary"
      variant="text"
      class="mt-16 !list-none"
      @update:model-value="fetchPage"
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";

const emit = defineEmits(["fetch-page", "row-click"]);
const props = defineProps({
  headers: { type: Array, default: null },
  tableData: { type: Array, default: null },
  headerClass: { type: String, default: null },
  withoutHover: { type: Boolean, default: false },
  pagination: { type: Object, default: null },
});

const fetchPage = (page) => {
  emit("fetch-page", page);
};

</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table td,
table th {
  padding: 0 20px 0 16px;

  vertical-align: middle;
}

table tr,
table td {
  height: 50px !important;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}

a.thick {
  font-weight: bold;
}

</style>