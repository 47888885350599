<template>
  <v-data-table
    :headers="headersTmp"
    :items="reports"
    :items-per-page="-1"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in headersTmp"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey font-semibold"
        >
          <div class="flex flex-row items-center">
            <Icon
              :icon="header.icon"
              width="16"
              height="16"
              class="mr-1"
            />
            <span>{{ header.title }}</span>
          </div>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr class="cursor-pointer">
        <td>
          <div class="flex flex-row items-center">
            <svn-avatar
              :avatar="item.raw?.creator?.['avatar']?.['50']"
              :firstname="item.raw?.creator?.['firstname']"
              :lastname="item.raw?.creator?.['lastname']"
              size="lg"
              class="mr-1rem"
            />
            <div class="flex flex-col">
              <p>
                {{ item.raw?.creator?.['firstname'] }} {{ item.raw?.creator?.['lastname'] }}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div>
            <p>{{ $filters.formatSpecificDate(item.raw?.created_at, "YYYY-MM-DDTHH:mm:ss.sssZ", "MM/DD/YY") }}</p>
          </div>
        </td>
        <td>
          <div class="flex items-center pl-4">
            <Icon
              :icon="iconMode(item.raw?.mode)"
              class="bkt-black mr-2"
            />
            <p>{{ strMode(item.raw?.mode) }}</p>
          </div>
        </td>
        <td>
          <div>
            <p>{{ $filters.formatDate(item.raw?.start_time) }}</p>
          </div>
        </td>
        <td>
          <div>
            <p>{{ $filters.formatDate(item.raw?.end_time) }}</p>
          </div>
        </td>
        <td>
          <div
            v-if="item.raw?.state === 'done'"
            class="flex justify-start items-center pl-4"
          >
            <!-- <a :href="backendAutoLogin(csv_path)" target="_blank" class="mr-4">
            <icon icon="fa-solid:file-csv" class="bkt-blue" />
          </a> -->
            <a
              :href="backendAutoLogin(item.raw?.xlsx_path)"
              target="_blank"
            >
              <icon
                icon="mdi-tray-arrow-down"
                class="bkt-blue"
              />
            </a>
          </div>

          <div
            v-else
            class="flex justify-start items-center width-70"
          >
            <Vue3Lottie
              animation-link="https://assets3.lottiefiles.com/packages/lf20_hmnalrkv.json"
              :width="50"
              :height="50"
            />
          </div>
        </td>
        <td class="!px-0">
          <svn-dots-button
            table
            button-size="lg"
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="delete"
                color="primary"
                @click="deleteInterviewReport(item.raw?.id)"
              >
                {{ $t('Delete') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && reports && reports.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="mt-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>
        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>
  </v-data-table>
</template>

<script setup>
import {useReportStore} from "@/store/reports.js";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import { usePopUpStore } from "@/store/pop-up";
import { Icon } from "@iconify/vue/dist/iconify.js";
import i18n from '@/plugins/i18n'

const props = defineProps({
  search: { type: String, default: '' },
})

const { backendAutoLogin } = useTools()
const { reports, pagination} = storeToRefs(useReportStore())
const { openPopUp, closePopUp } = usePopUpStore()
const {fetchReports, deleteReport, updateParams, setReports} = useReportStore()
const headersTmp = [
  {
    align: "start",
    key: "creator",
    sortable: false,
    title: i18n.global.t('Creator'),
    width: 300,
  },
  {
    align: "start",
    key: "created_at",
    sortable: false,
    title: i18n.global.t('Created at'),
    width: 200,
  },
  {
    align: "start",
    key: "type",
    sortable: false,
    title: i18n.global.t('Type'),
    width: 200,
  },
  {
    align: "start",
    key: "from",
    sortable: false,
    title: i18n.global.t('From'),
    width: 200,
  },
  {
    align: "start",
    key: "to",
    sortable: false,
    title: i18n.global.t('To'),
    width: 200,
  },
  {
    align: "start",
    key: "downloads",
    sortable: false,
    title: i18n.global.t('Downloads'),
    width: 50,
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    width: 50
  }
];

const strMode = (mode) => {
  const arr = {
    analytics: "Analytics",
    data: "Global data",
    answers: "Answers",
    roadmap: 'Roadmap'
  }
  return arr[mode]
}
const iconMode = (mode) => {
  const arr = {
    analytics: "mdi-chart-line-variant",
    data: "mdi-folder-outline",
    answers: "mdi-message-outline",
    roadmap: "mdi-bullseye-arrow",
  }
  return arr[mode]
}
const deleteInterviewReport = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t("Are you sure you want to delete this report ?"),
    subtitle: i18n.global.t("This is a permanent action."),
    textClose: "No, cancel",
    textConfirm: "Yes, delete this report",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: 'red',
    customClass: "w-[400px]",
    showClose: false,
    async confirm() {
      await deleteReport(id)
      closePopUp()
    },
  });
}

const fetchNewPage = (e) => {
  setReports(null);
  updateParams({
    search: props?.search,
    "page[number]": e,
  });
  fetchReports(props?.search, e);
};
</script>
