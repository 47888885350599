<template>
    <!-- Bandeau rouge si c'est trash -->
    <trash-warnning
      v-if="isDeleted"
      title="This template is in trash."
      @restore="restoreDeletedItems(interviewForm?.id)"
      @delete-permanently="deletePermanentlyDeletedItems(interviewForm?.id)"
    />

  <div
    class="relative w-full"
  >
    <v-layout
      full-height
      style=" position: fixed; width: 100%; height: calc(100% - 75px); z-index: 0;"
    >
      <v-app-bar
        color="surface"
        elevation="4"
        prominent
      >
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        />

        <v-toolbar-title>
          <div class="flex w-full">
            <div style="width: 345px" />
            <p class="flex text-center justify-center w-full">
              <Icon
                theme="interview"
                :icon="isFeedBack360() ? 'ic:round-360' : (isSurvey() ? 'ic:baseline-bar-chart' : 'uil:exchange')"
                width="30"
                height="30"
                class="mx-2 bkt-blue"
              />
              {{ title }}
            </p>
          </div>
        </v-toolbar-title>

        <!--      <v-spacer />-->

        <div class="w-32 text-end px-3">
          <span
            v-if="isTemplateLoading"
            class="text-info"
          >
            {{ $t("Saving...") }}
          </span>
          <span
            v-else
            class="text-success"
          >
            {{ $t("Saved") }}
          </span>
        </div>

        <interview-template-show />
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        width="290"
        disable-resize-watcher
        class="!h-auto"
      >
        <div class="w-full break-words">
          <div class="w-full text-center font-semibold text-gray-300 text-2xl p-5">
            {{ $t( 'Chapters' ) }}
          </div>
          <div
            v-for="(item, i) in headlines"
            :key="i"
            class="w-full"
            :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
          >
            <div
              v-if="item.type === InterviewQuestionType.CHAPTER"
              class="uppercase font-semibold break-words px-10 pt-6 pb-1.5"
            >
              {{ item.separatorpos }}. {{ item.title }}
            </div>
            
            <v-btn
              v-else-if="item.type === InterviewQuestionType.CREATE_ROADMAP || item.type === InterviewQuestionType.UPDATE_ROADMAP"
              :value="item"
              :to="{path: '', hash: `#${item.id}`}"
              class="flex justify-start active:bg-blue-100 bg-transparent focus:shadow-none w-full shadow-none hover:shadow-none rounded-none break-words"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  size="1em"
                  class="mr-1 bkt-blue inline-block align-middle"
                />
                <span class="first-letter:uppercase normal-case truncate">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>

            <v-btn
              v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
              :to="{path: '', hash: `#${item.id}`}"
              :value="item"
              class="flex justify-start active:bg-blue-100 bg-transparent focus:shadow-none w-full shadow-none hover:shadow-none rounded-none break-words"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  size="1em"
                  class="mr-1 bkt-blue inline-block align-middle"
                />
                <span class="first-letter:uppercase normal-case truncate">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>
          </div>
        </div>
      </v-navigation-drawer>

      <v-main
        class="overflow-y-auto bg-neutral-100"
      >
        <div class="flex bg-neutral-100">
          <div class="w-4/6 flex justify-center question-list-container">
            <template-question-list
              :is-deleted="isDeleted"
            />
          </div>
          <div class="w-2/6 flex flex-col xl:mr-28 mr-8">
            <div class="edit-tags-container">
              <template-edit-type
                v-if="!isFeedBack360() && !isSurvey()"
                :is-deleted="isDeleted"
              />
              <template-edit-tags
                :is-deleted="isDeleted"
              />
            </div>
          </div>
        </div>
      </v-main>
    </v-layout>
  </div>
</template>

<script setup>
import TemplateQuestionList
  from "@/components/interviewApp/template/Edit/TemplateQuestionList.vue";
import {computed, onMounted, onUnmounted, ref } from "vue";
import TemplateEditType
  from "@/components/interviewApp/template/Edit/TemplateEditType.vue";
import TemplateEditTags
  from '@/components/interviewApp/template/Edit/TemplateEditTags.vue'
import {useInterviewFormStore} from "@/store/interview-form.js";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import InterviewTemplateShow
  from "@/components/interviewApp/template/Show/InterviewTemplateShow.vue";
import useCampaigns from "@/tools/useCampaigns.js";
import {Icon} from "@iconify/vue";
import {useMobileStore} from "@/store/mobile.js";
import { useSnackbar } from '@/store/snackbar';
import TrashWarnning from "@/components/TrashWarnning.vue"
import { InterviewQuestionType } from '@/constants/types';

const snackbar = useSnackbar()


const route = useRoute()
const router = useRouter()

const { deduceMatIconFromInterviewQuestionType } = useTools()
const { fetchInterviewForm, clearInterviewForm, isFeedBack360, isSurvey } = useInterviewFormStore()
const { title, isTemplateLoading, interviewQuestions, id, templateType, interviewForm } = storeToRefs(useInterviewFormStore())

const { restoreInterviewForm, deleteInterviewFormPermanently } =
 useInterviewFormStore();

const {isLargeScreen, isMobile} = storeToRefs(useMobileStore())

const { campaign_icon } = useCampaigns();

const drawer = ref(isLargeScreen);

const isDeleted = ref(false)

const headlines = computed(() => {

  let separator_position = 1

  return interviewQuestions.value.map((question) => ({
    id: question.id,
    title: question.text,
    type: question.type,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    separatorpos: question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP ?
      separator_position++ : null,
  }))
})

onMounted(async() => {
  await fetchInterviewForm(route.params.id)

  isDeleted.value = interviewForm.value.status === "trashed"
})

const restoreDeletedItems = async (id) => {
  try {
    await restoreInterviewForm(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Interview form restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    isDeleted.value = false

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteInterviewFormPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Interview form deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "interview_forms" });

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a interview form')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

onUnmounted(() => {
  clearInterviewForm()
})

fetchInterviewForm(route.params.id)
</script>

<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}

.v-btn :deep(.v-btn__overlay){
  display: none;
}

.v-btn :deep(.v-btn__content){
  width: 100%;
}

.question-list-container {
  overflow-y: auto;
}

.edit-tags-container {
  position: sticky;
  top: 0;
}
</style>
