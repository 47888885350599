<template>
  <div
    v-if="logs && logs.length > 0"
    class="w-full h-full flex flex-col"
  >
    <!-- If target is completed -->
    <div
      v-if="indicator?.status === 'completed'"
      class="p-6 flex flex-col justify-center items-center border-b border-b-darkGrey sm:border-b-0"
    >
      <Icon
        icon="noto-trophy"
        height="32"
        width="32"
      />

      <p class="text-base text-fakeBlack font-medium mb-4">
        {{ $t('Target completed!') }}
      </p>

      <div>
        <p class="text-fakeBlack text-center text-sm font-normal mb-2">
          {{ $t('Congratulations! You have reached your target ') }}

          <span class="font-medium">"{{ targetTitle }}"</span>
        </p>
      </div>

      <p class="text-darkGrey font-normal text-xs">
        {{ $t('Target achieved on') }} {{ $filters.formatSpecificDate(indicator.updated_at, "YYYY-MM-DDTHH:mm:ss.sssZ", "YYYY-MM-DD") }}.
      </p>
    </div>

    <!-- List of logs -->
    <div
      class="w-full h-full sm:h-[612px] flex flex-col overflow-y-auto overflow-x-hidden"
    >
      <div
        v-for="(log, idx) in logs"
        :key="idx"
        class="p-4 w-full"
        :class="idx + 1 !== logs.length ? 'border-b border-b-darkGrey' : ''"
      >
        <div class="flex flex-row">
          <svn-avatar
            :avatar="log.user.avatar?.['50']"
            :firstname="log.user.firstname"
            :lastname="log.user.lastname"
            size="lg"
            class="mr-4"
          />

          <div class="flex flex-col sm:flex-row items-start justify-start gap-y-2 self-start" />

          <div class="w-full flex flex-row relative">
            <div class="w-full">
              <div class="flex w-full sm:flex-col sm:justify-between sm:items-start">
                <div class="flex flex-row justify-between w-full">
                  <div class="flex flex-col w-full">
                    <div class="flex flex-row justify-between items-center w-full">
                      <div class="flex flex-row items-center">
                        <p
                          v-if="log?.title"
                          class="text-base text-fakeBlack max-w-[175px] 2xl:max-w-[275px]  font-medium mr-2"
                        >
                          {{
                            log?.comment ?
                              $t("user commented", { user: log?.user?.firstname }) :
                              `${log?.title}`
                          }}
                        </p>

                        <!-- Target log createdAt -->
                        <p
                          v-if="!isMobile"
                          class="w-[125px] text-start text-darkGrey font-normal sm:hidden"
                          :class="isMobile ? 'text-[10px]' : 'text-xs'"
                        >
                          {{
                            $filters.formatSpecificDateCalendar(log.created_at, "YYYY-MM-DDTHH:mm:ss.sssZ")
                          }}
                        </p>
                      </div>

                      <div class="flex flex-row gap-x-4">
                        <div
                          v-if="log.old_value !== log.new_value &&
                            log.state !== 'status_updated' &&
                            log?.title !== 'Title updated' &&
                            log?.title !== 'Description updated' &&
                            log?.title !== 'Due date updated' &&
                            (indicator?.indicator_type === 'percentage' ||
                              indicator?.indicator_type === 'numeric_value')"
                          class="flex flex-row items-center px-2 py-[5px] rounded-[3px] h-[26px]"
                          :class="getStatusBorder(indicator?.indicator_type, log?.old_value, log?.new_value)"
                        >
                          <Icon
                            v-if="indicator?.indicator_type !== 'boolean'"
                            class="mr-1"
                            :icon="getStatusIcon(indicator?.indicator_type, log?.old_value, log?.new_value)"
                            height="16"
                            width="16"
                          />

                          <p class="text-sm max-w-[50px] sm:max-w-[75px] font-medium truncate overflow-ellipsis">
                            {{ getLogStatusText(indicator?.indicator_type, log?.old_value, log?.new_value) }}
                          </p>
                        </div>

                        <!-- When the indicator current value is changed -->
                        <div
                          v-if="log.old_value !== log.new_value &&
                            log.state !== 'status_updated' &&
                            log?.title !== 'Title updated' &&
                            log?.title !== 'Description updated' &&
                            log?.title !== 'Due date updated'"
                          class="flex flex-row items-center mt-[2px] hidden 2xl:flex"
                        >
                          <p class="font-medium text-base text-primary max-w-[50px] sm:max-w-[100px] truncate overflow-ellipsis">
                            {{ getLogValue(indicator?.indicator_type, log.old_value) }}
                          </p>

                          <div>
                            <Icon
                              class="mx-2 font-medium text-base text-primary"
                              icon="mdi-arrow-right"
                              height="24"
                              width="24"
                              color="primary"
                            />
                          </div>

                          <p class="font-medium text-base text-primary max-w-[50px] sm:max-w-[100px] truncate overflow-ellipsis">
                            {{ getLogValue(indicator?.indicator_type, log.new_value) }}
                          </p>
                        </div>

                        <!-- View update button -->
                        <div
                          v-if="
                            log?.title === 'Title updated' ||
                              log?.title === 'Description updated' ||
                              log?.title === 'Due date updated'"
                          class="flex flex-row items-center rounded-[3px]"
                        >
                          <v-btn
                            :text="$t('View update')"
                            color="white"
                            variant="flat"
                            class="text-primary normal-case flex flex-row justify-center items-center !border !border-[#5C95FF] px-4 py-2"
                            @click="openPopUpSeeTargetFieldUpdate(log?.title, log?.old_value, log?.new_value)"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Target log createdAt -->
                    <p
                      v-if="!isMobile"
                      class="w-[125px] text-start text-darkGrey font-normal hidden sm:block"
                      :class="isMobile ? 'text-[10px]' : 'text-xs'"
                    >
                      {{ $filters.formatSpecificDateCalendar(log.created_at, "YYYY-MM-DDTHH:mm:ss.sssZ") }}
                    </p>

                    <!-- Update details(user, date, ...) -->
                    <div
                      class="flex mb-2"
                      :class="log?.title === 'Title updated' ||
                        log?.title === 'Description updated' ||
                        log?.title === 'Due date updated' ? 'flex-col items-start' : 'flex-row items-center'"
                    >
                      <p
                        class="text-sm text-start font-medium max-w-[125px] sm:max-w-[300px] text-truncate w-full"
                        :class="isMobile ? 'text-fakeBlack' : 'text-darkGrey'"
                      >
                        {{ log.user.firstname }} {{ log.user.lastname }}
                      </p>

                      <p
                        v-if="isMobile"
                        class="w-[125px] text-start text-darkGrey font-normal"
                        :class="isMobile ? 'text-[10px] mt-[3px]' : 'text-xs'"
                      >
                        {{ $filters.formatSpecificDateCalendar(log.updated_at, "YYYY-MM-DDTHH:mm:ss.sssZ") }}
                      </p>
                    </div>

                    <!-- When the indicator current value is changed -->
                    <div
                      v-if="log.old_value !== log.new_value &&
                        log.state !== 'status_updated' &&
                        log?.title !== 'Title updated' &&
                        log?.title !== 'Description updated' &&
                        log?.title !== 'Due date updated'"
                      class="flex flex-row items-center mt-[2px] 2xl:hidden"
                    >
                      <p class="font-medium text-base text-primary max-w-[50px] sm:max-w-[75px] truncate overflow-ellipsis">
                        {{ getLogValue(indicator?.indicator_type, log.old_value) }}
                      </p>

                      <div>
                        <Icon
                          class="mx-2 font-medium text-base text-primary"
                          icon="mdi-arrow-right"
                          height="24"
                          width="24"
                          color="primary"
                        />
                      </div>

                      <p class="font-medium text-base text-primary max-w-[50px] sm:max-w-[75px] truncate overflow-ellipsis">
                        {{ getLogValue(indicator?.indicator_type, log.new_value) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- If comment is too long  -->
              <div
                v-if="(log?.comment?.split(' ').filter((word) => { return word !== '' }).length) >= 35"
                class="w-full border-l border-l-middleGrey flex items-end overflow-hidden"
              >
                <p
                  class="text-fakeBlack font-normal text-xs ml-2 relative pr-1 bg"
                  :class="log.displayed ? '' : 'line-clamp-6'"
                >
                  {{ log?.comment }}
                  <span
                    class="absolute bg-white pl-3 right-0.5 -bottom-[0.3px] text-sm text-primary font-normal underline decoration-solid cursor-pointer w-18"
                    @click="log.displayed = !log.displayed"
                  >
                    {{ log.displayed ? $t('See less') : $t('See all') }}
                  </span>
                </p>
              </div>

              <!-- If comment is not too long -->
              <div
                v-else
                class="w-full border-l border-l-middleGrey"
              >
                <p class="text-fakeBlack font-normal text-xs ml-2 w-3/4 break-words whitespace-pre">
                  {{ log?.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- If there are no logs -->
  <div
    v-else-if="logs && logs.length === 0"
    class="w-full h-full flex flex-row justify-center items-center"
  >
    <p class="text-middleGrey font-medium text-sm">
      {{ $t('No recent events yet.') }}
    </p>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { ref } from "vue";
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up";
import i18n from "@/plugins/i18n";

const { openPopUp } = usePopUpStore();
const { isMobile } = storeToRefs(useMobileStore())
const props = defineProps(["indicator", "logs", "targetTitle"]);

const indicatorFormatted = ref({
  id: props.indicator.id,
  indicator_type: props.indicator.indicator_type,
  starting_value: props.indicator.options.starting_value,
  target_value: props.indicator.options.target_value,
  current_value: props.indicator.options.current_value,
  status: props.indicator.status,
  multiChoiceList: [],
});

const regex = new RegExp(/^choice_.*/);
for (const opt in props.indicator.options) {
  if (regex.test(opt))
    indicatorFormatted.value.multiChoiceList.push(props.indicator.options[opt]);
}

const getLogValue = (indicatorType, logValue) => {
  if (indicatorType === 'percentage') {
    return logValue + '%';
  }
  else {
    return logValue;
  }
}

const getStatusBorder = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'multi_choice') {
    return 'text-darkGrey border border-darkGrey bg-lightGrey';
  }
  else {
    if (parseInt(updatedLogValue) - parseInt(initialLogValue) < 0) {
      return 'text-negativeRed border border-negativeRed bg-transparentNegativeRed';
    }
    else {
      return 'text-positiveGreen border border-positiveGreen bg-transparentPositiveGreen';
    }
  }
}

const getLogStatusText = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'percentage') {
    return Math.abs(parseInt(updatedLogValue) - parseInt(initialLogValue)) + '%';
  }
  else if (indicatorType === 'numeric_value') {
    return Math.abs(parseInt(updatedLogValue) - parseInt(initialLogValue));
  }
  else if (indicatorType === 'boolean') {
    return i18n.global.t('Switch');
  }
  else {
    return i18n.global.t('Option');
  }
}

const getStatusIcon = (indicatorType, initialLogValue, updatedLogValue) => {
  if (indicatorType === 'percentage' || indicatorType === 'numeric_value') {
    if (parseInt(updatedLogValue) - parseInt(initialLogValue) > 0) {
      return 'mdi-plus';
    }
    else {
      return 'mdi-minus';
    }
  }
  else if (indicatorType === 'boolean') {
    return '';
  }
  else {
    return 'mdi-swap-horizontal';
  }
}

const openPopUpSeeTargetFieldUpdate = (targetField, initialValue, updatedValue) => {
  openPopUp({
    componentName: 'pop-up-see-target-field-update',
    title: '',
    subtitle: '',
    icon: '',
    targetField: targetField,
    initialValue: initialValue,
    updatedValue: updatedValue,
    customClass: 'max-w-[600px] max-h-[600px]',
  })
}
</script>