<template>
  <div
    v-if="question.type == LearnInputType.CHECKBOX"
    class="flex flex-col items-center gap-6 self-stretch p-6 border border-darkGrey rounded-[12px]"
  >
    <!-- Question title -->
    <div class="flex flex-col items-start gap-2 self-stretch">
      <p
        v-if="showResults"
        class="text-base font-medium"
        :class="getTextColorByStatus()"
      >
        {{ getStatusText() }}
      </p>
      <p class="text-fakeBlack text-base font-medium">
        {{ question?.title }}
      </p>
    </div>
    <!-- Question proposals -->
    <div class="flex flex-col items-start gap-4 self-stretch">
      <quiz-question-card-proposition
        v-for="(proposition, index) in question?.proposals"
        :key="index"
        :proposition="proposition"
        :show-results="showResults"
        :index="index"
        :status="responsesCheckbox?.status"
        :selected-values="responsesCheckbox?.selected_values"
        @toggle-proposition="toggleProposition(proposition)"
        @update="inputChannel?.update"
      />
    </div>
  </div>
  <div
    v-if="question.type == LearnInputType.PARAGRAPH
      && question?.editor_content?.blocks?.length && question?.editor_content?.id"
    class="flex flex-col items-center gap-6 self-stretch"
  >
    <!-- Rich text editor -->
    <svn-tiptap
      :html-data="question?.editor_content?.blocks"
      :create-image-url="`/api/v1/editor_contents/${question.editor_content.id}fake-id/upload_image`"
      :is-editable="false"
    />
  </div>
</template>
<script setup>
import { computed, ref, onBeforeUnmount, watch } from "vue";
import QuizQuestionCardProposition from './QuizQuestionCardProposition.vue';
import { useLearnModuleStore } from "@/store/learn-module";
import { storeToRefs } from "pinia";
import {useActionCable} from "@/store/cable.js";
import i18n from "@/plugins/i18n";
import { LearnInputType } from '@/constants/types';

const { cable } = storeToRefs(useActionCable());
const {
  learnResponsesCheckboxes,
  submissionId,
  submission
} = storeToRefs(useLearnModuleStore())
const props = defineProps({
  question: { type: Object, required: true },
  status: { type: String, default: null },
  showResults: { type: Boolean, default: false },
})
const inputChannel = ref(null)
const responsesCheckbox = computed(() => {
  return learnResponsesCheckboxes.value.find(input => input.input_id == props.question.id)
})
const emit = defineEmits(['toggle-proposition'])
const getTextColorByStatus = () => {
  if (responsesCheckbox.value?.status === 'correct') {
    return 'text-positiveGreen'
  }
  else if (responsesCheckbox.value?.status === 'ambiguous') {
    return 'text-mediumOrange'
  }
  else if (responsesCheckbox.value?.status === 'wrong') {
    return 'text-negativeRed'
  }
}
const getStatusText = () => {
  if (responsesCheckbox.value?.status === 'correct') {
    return i18n.global.t('Right answer')
  }
  else if (responsesCheckbox.value?.status === 'ambiguous') {
    return i18n.global.t('Partially right answer')
  }
  else if (responsesCheckbox.value?.status === 'wrong') {
    return i18n.global.t('Wrong answer')
  }
}
const toggleProposition = async(data) => {
  learnResponsesCheckboxes.value.forEach((input) => {
    if (input.input_id == props.question.id) {
      if (input && input.selected_values && input.selected_values.includes(data.proposal)) {
        input.selected_values = input.selected_values.filter(item => item !== data.proposal)
      } else {
        input.selected_values.push(data.proposal)
      }
    }
  })
}
const websocketMethod = () => {
  const subscribeOptions = {
    channel: "Learn::ResponseChannel", submission_id: submission.value.id
  }
  inputChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },
    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },
    received: function(data) {
      if (data.status === "update") {
        const index = learnResponsesCheckboxes.value.findIndex(x => x.id === data.entity.id)
        learnResponsesCheckboxes.value[index].selected_values = data.entity.selected_values
      }
    },
    update: async function() {
      let data = {
        selected_values: responsesCheckbox.value.selected_values,
        response_id: responsesCheckbox.value.id,
      }
      inputChannel.value.perform('update', data);
      submission.value.status = "in_progress"
    },
  });
}
watch(submission, () => {
  websocketMethod()
})
onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
})
</script>