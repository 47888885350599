<template>
  <div class="flex flex-col w-full lg:max-2xl:px-5 lg:pt-[64px] lg:pb-[120px] lg:gap-y-[80px] max-w-[1320px] mx-auto">
    <!-- Header -->
    <div class="flex flex-col gap-y-4 px-4 my-6 lg:!my-0">
      <svn-hello-banner
        app="learn"
        :firstname="firstname"
        :completed-trainings="myLearningsCompleted?.completed_learn_training_count || 0"
        :modules-acquired="myLearningsCompleted?.completed_learn_modules_count || 0"
        :text-chip-first="myLearningsCompleted?.completed_learn_training_count ? $t('Trainings completed') : $t('Training completed')"
        :text-chip-second="myLearningsCompleted?.completed_learn_modules_count ? $t('Modules acquired') : $t('Module acquired')"
      />
    </div>

    <div class="flex flex-col gap-y-8 lg:gap-y-[80px]">
      <!-- Trainings -->
      <div class="flex flex-col px-4 pt-2 lg:!px-0">
        <div class="flex flex-col gap-y-6 items-center">
          <div class="w-full flex flex-col gap-y-8 lg:flex-row lg:justify-between">
            <div
              v-if="myLearningsTodo?.todo_learn_training_count"
              class="self-center lg:hidden"
            >
              <p class="max-w-[235px] text-center text-darkGrey text-xs font-medium">
                {{ $t('You have more must do trainings, remember to complete them !',
                  myLearningsTodo?.todo_learn_training_count,
                  { count: myLearningsTodo?.todo_learn_training_count } ) }}
              </p>
            </div>

            <div class="flex flex-col gap-y-6 lg:gap-y-2">
              <div class="flex flex-row gap-x-1 justify-center items-center lg:!justify-start">
                <Icon
                  icon="noto:fire"
                  height="24"
                  width="24"
                />

                <p class="text-fakeBlack text-xl font-semibold lg:text-[24px]">
                  {{ $t('My Trainings') }}
                </p>
              </div>

              <div
                v-if="myLearningsTodo?.todo_learn_training_count"
                class="hidden lg:flex"
              >
                <p class="text-darkGrey text-sm font-medium">
                  {{ $t('You have more must do trainings, remember to complete them !',
                  myLearningsTodo?.todo_learn_training_count,
                  { count: myLearningsTodo?.todo_learn_training_count }) }}
                </p>
              </div>
            </div>

            <!-- Tabs -->
            <v-tabs
              v-model="tab"
              bg-color="white"
              color="trainingOrange"
              :height="isXlScreen ? '28' : '16'"
              selected-class="font-semibold text-trainingOrange"
              class="!opacity-100"
              :align-tabs="isXlScreen ? 'end' : 'center'"
              :hide-slider="true"
            >
              <!-- To do tab -->
              <v-tab
                value="todo"
                variant="plain"
                selected-class="text-trainingOrange"
              >
                <template #default>
                  <div class="flex flex-row justify-center items-center gap-x-1 h-4">
                    <p class="text-sm font-medium normal-case">
                      {{ $t('To do') }}
                    </p>

                    <span
                      class="px-1 rounded text-sm font-semibold"
                      :class="tab === 'todo' ? 'bg-trainingOrangeSoft text-trainingOrange' :
                        'bg-veryLightGrey text-darkGrey'"
                    >
                      {{ myLearningsTodo?.todo_learn_training_count || 0 }}
                    </span>
                  </div>
                </template>
              </v-tab>

              <!-- Completed tab -->
              <v-tab
                value="completed"
                variant="plain"
              >
                <template #default>
                  <div class="flex flex-row justify-center items-center gap-x-1 h-4">
                    <p class="text-sm font-medium normal-case">
                      {{ $t('Completed') }}
                    </p>

                    <span
                      class="px-1 rounded text-sm font-semibold"
                      :class="tab === 'completed' ? 'bg-trainingOrangeSoft text-trainingOrange' :
                        'bg-veryLightGrey text-darkGrey'"
                    >
                      {{ myLearningsCompleted?.completed_learn_training_count || 0 }}
                    </span>
                  </div>
                </template>
              </v-tab>
            </v-tabs>
          </div>

          <!-- Windows -->
          <v-window
            v-model="tab"
            class="w-full"
          >
            <!-- ToDo window -->
            <v-window-item value="todo">
              <!-- Skeleton loaders -->
              <div
                v-if="loadingTodo"
                class="flex flex-col justify-center items-start pb-2 lg:!pb-0"
              >
                <div
                  class="w-full infinite overflow-y-hidden overflow-x-visible grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-4 sm:gap-6
                  justify-center items-center md:items-start self-stretch"
                >
                  <skeleton-loader-training-card
                    v-for="item in 5"
                    :key="item"
                  />
                </div>
              </div>

              <!-- Trainings to do -->
              <div
                v-else
                class="flex flex-col justify-center items-start pb-2 lg:!pb-0"
              >
                <!-- No training to do -->
                <div
                  v-if="trainingToDo?.todo_learn_training_count === 0"
                  class="flex flex-col justify-center items-center py-2 gap-y-4 self-center"
                >
                  <p class="text-darkGrey text-sm font-medium text-center max-w-[359px] lg:hidden">
                    {{ $t("You don't have any training to do yet. Come back later to see your trainings !") }}
                  </p>

                  <div class="p-[24.4px] rounded-full flex items-center bg-trainingOrangeSoft">
                    <Icon
                      icon="noto:open-book"
                      height="128"
                      width="128"
                    />
                  </div>

                  <p class="text-darkGrey text-sm font-medium text-center max-w-[359px] hidden lg:flex">
                    {{ $t("You don't have any training to do yet. Come back later to see your trainings !") }}
                  </p>
                </div>

                <!-- Infinite scroll -->
                <v-infinite-scroll
                  v-if="trainingToDo.learn_trainings"
                  :items="trainingToDo.learn_trainings"
                  class="w-full infinite overflow-y-hidden overflow-x-visible grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-4 sm:gap-6
                  justify-center items-center md:items-start self-stretch"
                  color="primary"
                  @load="loadMoreDataTrainingToDo"
                >
                  <training-card
                    v-for="training in trainingToDo?.learn_trainings"
                    :id="training?.id"
                    :key="training?.id"
                    :background-image="training?.cover_url?.['500']"
                    :title="training?.title"
                    :theme-list="training?.module_themes"
                    :duration="training?.all_modules_duration"
                    :completed-trainings="training?.module_completion"
                    :total-trainings="training?.module_count"
                    @click="goToTrainingShow(training)"
                  />
                  <template #empty />
                </v-infinite-scroll>
              </div>
            </v-window-item>

            <!-- Completed window -->
            <v-window-item value="completed">
              <!-- Skeleton loaders -->
              <div
                v-if="loadingCompleted"
                class="flex flex-col justify-center items-start pb-2 lg:!pb-0"
              >
                <div
                  class="w-full infinite overflow-y-hidden overflow-x-visible grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-4 sm:gap-6
                  justify-center items-center md:items-start self-stretch"
                >
                  <skeleton-loader-training-card
                    v-for="item in 5"
                    :key="item"
                  />
                </div>
              </div>

              <!-- Trainings completed -->
              <div
                v-else
                class="flex flex-col justify-center items-start pb-2 lg:!pb-0"
              >
                <!-- No training completed -->
                <div
                  v-if="trainingCompleted?.completed_learn_training_count === 0 &&
                    !trainingCompleted?.learn_trainings?.length"
                  class="flex flex-col justify-center items-center py-2 gap-y-4 self-center"
                >
                  <div class="p-[24.4px] rounded-full flex items-center bg-trainingOrangeSoft">
                    <Icon
                      icon="noto:open-book"
                      height="128"
                      width="128"
                    />
                  </div>

                  <p class="text-darkGrey text-sm font-medium text-center">
                    {{ $t("You haven't completed any training yet.") }}
                  </p>
                </div>

                <!-- Infinite scroll -->
                <v-infinite-scroll
                  v-if="trainingCompleted.learn_trainings"
                  :items="trainingCompleted.learn_trainings"
                  class="w-full infinite overflow-y-hidden overflow-x-visible grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
                  gap-4 sm:gap-6 justify-center items-center md:items-start self-stretch"
                  color="primary"
                  @load="loadMoreDataTrainingCompleted"
                >
                  <training-card
                    v-for="training in trainingCompleted?.learn_trainings"
                    :id="training?.id"
                    :key="training?.id"
                    :background-image="training?.cover_url?.['500']"
                    :title="training?.title"
                    :theme-list="training?.module_themes"
                    :duration="training?.all_modules_duration"
                    :completed-trainings="training?.module_completion"
                    :total-trainings="training?.module_count"
                    @click="goToTrainingShow(training)"
                  />

                  <template #empty />
                </v-infinite-scroll>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </div>

      <!-- Discover -->
      <div
        class="flex flex-col py-2 mb-[80px] lg:!py-0"
      >
        <div
          class="flex flex-col items-center gap-y-6 overflow-hidden"
        >
          <!-- Header with texts -->
          <div class="w-full flex flex-col px-4 lg:!px-0">
            <div class="w-full flex flex-row justify-center items-center gap-x-1 lg:!justify-normal">
              <Icon
                icon="noto-eyes"
                height="20"
                width="20"
              />

              <p class="text-fakeBlack text-xl font-semibold">
                {{ $t('New modules to discover') }}
              </p>
            </div>

            <div class="hidden lg:flex">
              <p class="text-darkGrey text-xm font-medium">
                {{ $t('New modules have been added to the catalog, go take a look to it.') }}
              </p>
            </div>
          </div>

          <!-- Skeleton loaders -->
          <div
            v-if="loadingModules"
            class="flex flex-col justify-center px-4 items-start self-stretch
              overflow-x-auto lg:!px-0 lg:!overflow-x-visible"
          >
            <div
              class="flex justify-center items-start py-2 gap-2
                  lg:gap-6 lg:!overflow-x-visible lg:!justify-start"
            >
              <skeleton-loader-module-card
                v-for="item in 4"
                :key="item"
              />
            </div>
          </div>

          <!-- Finished loading -->
          <div
            v-else
            class="flex flex-col justify-center px-4 items-start self-stretch
            overflow-x-auto lg:!px-0"
          >
            <!-- Modules card list -->
            <div
              v-if="myLearningsModulesToDiscover"
              class="flex justify-center items-start py-2 gap-2
                lg:gap-6 lg:!overflow-x-visible lg:!justify-start 2xl:w-full"
            >
              <module-card
                v-for="(module) in myLearningsModulesToDiscover"
                :id="module?.id"
                :key="module?.id"
                class="!w-[300px] lg:!w-[328px]"
                :module-creator-id="module?.creator_id"
                :to-discover="true"
                :background-image="module?.cover_url?.['500']"
                :title="module?.title"
                :reactions="module?.reactions?.user_reactions"
                :is-favorite="module?.connected_user_favorited"
                :theme-list="module?.themes"
                :duration="module?.duration"
                :updated-at="filters.formatDate(module?.updated_at, 'DD/MM/YYYY')"
                :submission-status="module?.submission_status"
                :submission-updated-at="module?.submission_updated_at"
                @toggle-favorite="toggleModuleFavorite(module?.id, module?.status)"
                @module-show="goToModuleShow(module)"
              />

              <!-- See all modules -->
              <div
                v-if="myLearningsModulesToDiscover?.length !== learnModules?.length"
                class="flex flex-col justify-center items-start self-stretch lg:hidden"
              >
                <div
                  class="w-[110px] flex justify-center items-center h-10 px-4 gap-x-2 cursor-pointer"
                  @click="seeAllModules"
                >
                  <div class="flex justify-center items-center py-[10px]">
                    <p class="text-xs font-medium text-trainingOrange">
                      {{ $t('See all') }}
                    </p>
                  </div>

                  <div class="flex justify-center items-center self-stretch">
                    <Icon
                      icon="mdi:arrow-right"
                      height="16"
                      width="16"
                      class="text-trainingOrange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- See all modules -->
          <div
            v-if="myLearningsModulesToDiscover?.length !== learnModules?.length"
            class="lg:flex-col items-end self-stretch hidden lg:flex"
            @click="seeAllModules"
          >
            <div class="flex h-10 px-4 py-[10px] justify-center items-center cursor-pointer">
              <p class="text-xs font-medium text-trainingOrange">
                {{ $t('See all') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Scroll to top button -->
    <svn-floating-action-button
      square
      to-top
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import TrainingCard from "@/components/trainingApp/TrainingCard.vue";
import ModuleCard from "@/components/trainingApp/ModuleCard.vue";
import HeaderChip from "@/components/trainingApp/HeaderChip.vue";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import SkeletonLoaderModuleCard from "@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue";
import SkeletonLoaderTrainingCard from "@/components/skeletonLoaders/SkeletonLoaderTrainingCard.vue";
import { useLearnMyLearningsStore } from "@/store/learn-my-learnings.js";
import { useLearnModuleStore } from "@/store/learn-module";
import { useBreadcrumbsStore } from "@/store/breadcrumbs";
import filters from "@/tools/filters";
import { useSnackbar } from "@/store/snackbar";

onMounted(async() => {
  try {
    await fetchMyLearningsTodo().then((res) => {
      trainingToDo.value = res.data.my_learnings
      trainingToDo.value.learn_trainings = trainingToDo?.value?.learn_trainings?.sort((a, b) =>
        new Date(b.launched_at) - new Date(a.launched_at))
      // totalPagesTrainingToDo.value = res.data.meta.pagination.total_pages
      loadingTodo.value = false
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings to do')
    snackbar.displaySnackBar()
  }

  try {
    await fetchMyLearningsCompleted().then((res) => {
      trainingCompleted.value = res.data.my_learnings
      // totalPagesTrainingCompleted.value = res.data.meta.pagination.total_pages
      loadingCompleted.value = false
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings completed')
    snackbar.displaySnackBar()
  }

  try {
    await fetchModulesToDiscover()
    loadingModules.value = false
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules completed')
    snackbar.displaySnackBar()
  }
})

const pageTrainingToDo = ref(1)

const pageTrainingCompleted = ref(1)

const pageModules = ref(1)

const tab = ref(null);

const router = useRouter()

const alignTop = ref(false)

const loadingTodo = ref(true)

const loadingCompleted = ref(true)

const loadingModules = ref(true)

const trainingToDo = ref([])

const trainingCompleted = ref([])

const totalPagesTrainingToDo = ref(null)

const totalPagesTrainingCompleted = ref(null)

const { addNode, update } = useBreadcrumbsStore()

const { toggleFavorite } = useLearnModuleStore()

const { fetchMyLearningsTodo, fetchMyLearningsCompleted, fetchModulesToDiscover } = useLearnMyLearningsStore()

const { isMobile, isXlScreen } = storeToRefs(useMobileStore())

const { firstname } = storeToRefs(useUserStore());

const snackbar = useSnackbar()

const { learnModules } = storeToRefs(useLearnModuleStore())

const { myLearningsTodo, myLearningsCompleted, myLearningsModulesToDiscover } = storeToRefs(useLearnMyLearningsStore())

const seeAllModules = () => {
  router.push({ name: 'catalog' })
}

const toggleModuleFavorite = async(moduleId, status) => {
  try {
    const updatedModule = await toggleFavorite(moduleId, status)
    for (let element of myLearningsModulesToDiscover?.value) {
      if (element?.id === moduleId) {
        element.connected_user_favorited = updatedModule?.connected_user_favorited
      }
    }
    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar(updatedModule?.connected_user_favorited ? 'Module added to favorites' : 'Module removed from favorites')
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar('Error toggling favorite')
  }
}

const goToModuleShow = (module) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'module_show', params: { id: module?.id } })
}

const goToTrainingShow = (training) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'training_show', params: { id: training?.id } })
}

const loadMoreDataTrainingToDo = async({ done }) => {
  if (pageTrainingToDo.value < totalPagesTrainingToDo.value) {
    try {
      const res = await fetchMyLearningsTodo(pageTrainingToDo.value + 1)

        if (res.data.my_learnings.length) {
          trainingToDo.value = [...trainingToDo.value, ...res.data.my_learnings]
        }
        done('ok')
        pageTrainingToDo.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const loadMoreDataTrainingCompleted = async({ done }) => {
  if (pageTrainingCompleted.value < totalPagesTrainingCompleted.value) {
    try {
      const res = await fetchMyLearningsCompleted(pageTrainingCompleted.value + 1)

        if (res.data.my_learnings.length) {
          trainingCompleted.value = [...trainingCompleted.value, ...res.data.my_learnings]
        }
        done('ok')
        pageTrainingCompleted.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/my_learnings')
  update()
}
</script>

<style scoped>
.skeleton :deep(.v-skeleton-loader__bone) {
  margin-left: 0;
  margin-right: 0;
}

.table :deep(.v-table__wrapper td) {
  padding-left: 0;
  padding-right: 0;
}

.infinite :deep(.v-infinite-scroll__side) {
  display: none;
}
</style>
