<template>
  <div class="w-full flex flex-col max-w-[1280px] mx-auto md:py-[64px] overflow-hidden">
    <!-- Header -->
    <div
      class="flex flex-col items-start self-stretch px-6 lg:!px-12 2xl:!px-0 py-8
      md:flex-row md:justify-between md:!px-0 md:!py-0 md:!mb-[64px] md:!ml-6 lg:!ml-0"
    >
      <div class="flex flex-col items-start gap-1 self-stretch">
        <div class="flex justify-center items-center gap-2 self-stretch md:!justify-start">
          <Icon
            icon="noto:puzzle-piece"
            :height="isMdScreen ? '48' : '20'"
            :width="isMdScreen ? '48' : '20'"
          />

          <p class="text-fakeBlack text-xl font-medium md:text-[40px]">
            {{ $t('Catalog') }}
          </p>
        </div>

        <p class="text-darkGrey self-center text-sm font-medium md:!self-start">
          {{ $t('Want to learn ? Take a look at our contents!') }}
        </p>
      </div>

      <!-- New playlist/New module button (Hidden on mobile devices) -->
      <div
        v-if="isManagerCreatorOrAbove()"
        class="items-start h-10 gap-4 hidden md:flex md:!mr-6 lg:!mr-0"
      >
        <dialog-create-new-playlist
          title="Create new playlist"
          text-confirm="Validate"
        >
          <template #button>
            <v-btn
              color="primary"
              variant="outlined"
              prepend-icon="mdi-plus"
              class="normal-case text-xs font-medium"
            >
              {{ $t("New playlist") }}
            </v-btn>
          </template>

          <template #close="{ close }">
            <div
              class="self-end"
              @click="close"
            >
              <Icon
                :icon="'material-symbols:close'"
                class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
              />
            </div>
          </template>
        </dialog-create-new-playlist>

        <v-btn
          color="primary"
          variant="flat"
          prepend-icon="mdi-plus"
          class="normal-case text-xs font-medium"
          @click="goToNewModule"
        >
          {{ $t("New module") }}
        </v-btn>
      </div>
    </div>

    <!-- Tabs -->
    <v-tabs
      v-model="tab"
      class="w-full md:w-fit md:ml-6 lg:!ml-12 2xl:!ml-0 border-b border-b-lightGrey"
      align-tabs="center"
    >
      <v-tab
        v-for="(tab_data, index) in tab_datas"
        :key="index"
        :value="tab_data.key"
        class="normal-case"
        :class="tab_datas.length === 2 ? 'w-1/2' : 'w-1/3'"
        color="trainingOrange"
        :grow="true"
      >
        {{ $t(tab_data.title) }}
      </v-tab>
    </v-tabs>

    <!-- Windows -->
      <v-window
        v-model="tab"
        class="w-full !overflow-visible max-w-[1280px]"
      >
        <!-- Modules tab -->
        <catalog-module :themes="learnThemes" />

        <!-- Playlists tab -->
        <catalog-playlist :themes="learnThemes" />

        <!-- Modules Drafts tab -->
        <catalog-module-draft :themes="learnThemes" />

      </v-window>

  </div>

  <!-- SpeedDial button -->
  <svn-floating-action-button
    :menu="true"
    icon="ic:baseline-plus"
  >
    <template #default>
      <div class="flex flex-col !items-end gap-4">
        <!-- New module button -->
        <div
          class="flex justify-center items-center gap-2 cursor-pointer"
          @click="goToNewModule"
        >
          <p class="text-white text-xl font-semibold textShadow">
            {{ $t('New module') }}
          </p>

          <div
            class="w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-full bg-trainingOrange"
          >
            <Icon
              icon="mdi:puzzle"
              height="24"
              width="24"
              class="text-white"
            />
          </div>
        </div>

        <!-- New playlist button -->
        <dialog-create-new-playlist
          title="Create new playlist"
          text-confirm="Validate"
        >
          <template #button>
            <div class="flex justify-center items-center gap-2 cursor-pointer">
              <p class="text-white text-xl font-semibold textShadow">
                {{ $t('New playlist') }}
              </p>

              <div
                class="w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-full bg-trainingOrange"
              >
                <Icon
                  icon="ic:outline-play-circle"
                  height="24"
                  width="24"
                  class="text-white"
                />
              </div>
            </div>
          </template>

          <template #close="{ close }">
            <div
              class="self-end"
              @click="close"
            >
              <Icon
                :icon="'material-symbols:close'"
                class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
              />
            </div>
          </template>
        </dialog-create-new-playlist>
      </div>
    </template>
  </svn-floating-action-button>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import CatalogModule from "./CatalogModule.vue";
import CatalogPlaylist from "./CatalogPlaylist.vue";
import CatalogModuleDraft from "./CatalogModuleDraft.vue";
import { useMobileStore } from "@/store/mobile";
import { useUserStore } from "@/store/user.js";
import { useRoute, useRouter } from "vue-router";
import { useLearnModuleStore } from "@/store/learn-module";
import { useSnackbar } from "@/store/snackbar.js";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import { useLearnThemesStore } from "@/store/learn-themes.js";
import DialogCreateNewPlaylist from "@/components/BktPopUp/Dialogs/learn/DialogCreateNewPlaylist.vue";
import i18n from "@/plugins/i18n.js";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"

onMounted(async() => {
  window.addEventListener("scroll", alignFunc);

  try {
    await fetchThemes()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching themes')
    snackbar.displaySnackBar()
  }
})

const router = useRouter()

const alignTop = ref(false)

const tab = ref(null);

const route = useRoute()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const snackbar = useSnackbar();

const { fetchThemes } = useLearnThemesStore();

const { createModule, updateModuleImage } = useLearnModuleStore()

const { isManagerCreatorOrAbove } = useUserStore();

const breadcrumbsStore = useBreadcrumbsStore();

const { reset, addNode, update } = breadcrumbsStore

const { isMobile } = storeToRefs(useMobileStore())

const { learnThemes } = storeToRefs(useLearnThemesStore());

const { isMdScreen } = storeToRefs(useMobileStore());

const tab_datas = computed(() => {
  if (isManagerCreatorOrAbove()) {
    return [
      {
        id: 0,
        title: "Modules",
        key: "module",
      },
      {
        id: 1,
        title: "Playlists",
        key: "playlist",
      },
      {
        id: 2,
        title: "Drafts",
        key: "draft",
        noContentText: "There are no drafts. Create your first module !",
      },
    ]
  }
  else {
    return [
      {
        id: 0,
        title: "Modules",
        key: "module",
        noContentText: "There are no modules for the moment, come later to see what’s new !",
      },
      {
        id: 1,
        title: "Playlists",
        key: "playlist",
        noContentText: "There are no playlists. Create your first playlist !",
      },
    ]
  }
})

const goToNewModule = async() => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    let module = await createModule("Module title", "30 minutes")

    loadXHR(randomImage).then(async(blob) => {
      updateModuleImage(module?.data?.id, blob)
    })

    router.push({ name: "module_edit", params: { id: module?.data?.id } })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const alignFunc = () => {
  alignTop.value = (document.body.scrollTop > window.innerHeight / 4 ||
  document.documentElement.scrollTop > window.innerHeight / 4)
}
</script>

<style scoped>
.input :deep(.v-input__control) {
  border-radius: 4px;
}

.textShadow {
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {
  :deep(.v-window-item) {
    transition: 0.8s cubic-bezier(.24, .2, .56, 1) !important;
  }
}
</style>
