<template>
  <v-card-text
    class="h-[500px] p-0 mt-3"
  >
    <div class="flex flex-row justify-between items-center my-4 font-medium">
      {{ $t("Selected participants (all employees in the categories below) : ") }}
      {{ usersSelected }}

      <v-btn
        v-if="selectedTagsList?.length >= 1"
        class="px-4 py-3 normal-case"
        variant="text"
        color="primary"
        :text="$t('Clear all')"
        @click="clearAllSelectedTags"
      />
    </div>

    <v-combobox
      variant="outlined"
      :items="tagSearch === '' ? tagCategories : searchedTagsNames"
      :label="$t('Category...')"
      item-value="tagName"
      :item-title="(item) => { return item.tagName; }"
      hide-details="auto"
      prepend-inner-icon="mdi-magnify"
      color="primary"
      class="input"
      base-color="middleGrey"
      @input="updateTagSearch"
    >
      <template #item="{ props, item }">
        <v-menu
          v-if="tagSearch === ''"
          location="end"
          content-class="max-h-[280px] rounded-xl"
          open-on-hover
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              class="cursor-pointer bkt-bg-light-grey10-hover"
              open
            >
              <v-list-item-title class="text-darkGrey">
                {{ item?.raw?.name }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <div class="bg-white border border-darkGrey w-[300px] max-h-[300px] ml-4 overflow-y-auto">
            <v-list-item
              v-for="(element, index) in item?.raw?.tags"
              :key="index"
              class="h-[50px] py-0 sm:hover:cursor-pointer hover:bg-[#F6F6F6]"
            >
              <div
                class="flex flex-row justify-start items-center"
                @click.stop="toggleTagSelection(element)"
              >
                <Icon
                  :icon="selectedTagsList?.find(tag => tag.id === element.id) !== undefined ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                  color="darkGrey"
                  width="18"
                  height="18"
                />

                <p class="font-normal text-darkGrey text-base ml-2">
                  {{ element?.tagName }}
                </p>
              </div>
            </v-list-item>
          </div>
        </v-menu>

        <v-menu
          v-else
          location="end"
          class="max-h-[280px]"
        >
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              class="cursor-pointer bkt-bg-light-grey10-hover"
            >
              <v-list-item-title
                class="flex flex-row justify-start items-center text-darkGrey"
                @click.stop="toggleTagSelection(item?.raw)"
              >
                <Icon
                  :icon="selectedTagsList?.find(tag => tag.id === item?.raw?.id) !== undefined ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                  color="darkGrey"
                  width="18"
                  height="18"
                />

                <p class="font-normal text-darkGrey text-base ml-2">
                  {{ item?.raw?.tagName }}
                </p>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-menu>
      </template>

      <template
        v-if="!tagSearch"
        #prepend-item
      >
        <v-list-item
          class="cursor-pointer bkt-bg-light-grey10-hover border-t border-t-darkGrey"
          base-color="middleGrey"
          variant="outlined"
          @click="toggleAllTagsSelection"
        >
          <p class="text-darkGrey font-normal text-base">
            {{ $t('All categories') }}
          </p>
        </v-list-item>
      </template>
    </v-combobox>

    <div>
      <div
        v-if="selectedTagsList?.length === 0"
        class="flex flex-col justify-center items-center py-20"
      >
        <Icon
          icon="noto-index-pointing-up"
          width="32"
          height="32"
        />

        <p class="mt-5 font-medium text-sm text-fakeBlack text-center">
          {{ $t('Hmm, it seems you have not selected a category yet. Search for a category in the search bar above.') }}
        </p>
      </div>

      <div
        v-else
        class="h-[350px] overflow-y-auto"
      >
        <div
          v-if="selectedTagsList?.length !== allTagsList.length"
          class="w-full pb-8 flex flex-wrap "
        >
          <div
            v-for="(tag, index) in selectedTagsList"
            :key="index"
            variant="outlined"
            class="flex flex-row justify-center items-center h-full bg-veryLightGrey rounded-[8px] border border-darkGrey px-2 py-[6px] m-2 cursor-pointer"
          >
            <p class="mr-2 text-darkGrey">
              {{ tag.tagName }}
            </p>

            <div
              @click="toggleTagSelection(tag)"
            >
              <Icon
                icon="mdi-close"
                width="16"
                height="16"
                color="darkGrey"
              />
            </div>
          </div>
        </div>

        <div
          v-else
          class="w-[200px] flex flex-row justify-center items-center self-center bg-veryLightGrey rounded-[8px] border border-darkGrey px-2 py-[6px] m-2 cursor-pointer"
        >
          <p class="mr-2 text-darkGrey">
            {{ $t('All categories') }}
          </p>

          <div
            @click="toggleAllTagsSelection"
          >
            <Icon
              icon="mdi-close"
              width="16"
              height="16"
              color="darkGrey"
            />
          </div>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import {useCompanyStore } from "@/store/company.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { ref, onMounted } from "vue";
import axiosService from "@/tools/axios-service.js";

const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { tagCategories } = storeToRefs(useCompanyStore());
const { fetchCompany } = useCompanyStore();

const allTagsList = ref([]);
const searchedTagsNames = ref([]);
const selectedTagsList = ref([]);
const tagSearch = ref('');
const tagCategoriesNames = ref(null);
const usersSelected = ref(null)

onMounted(async() => {
  fetchCompany();

  tagCategoriesNames.value = tagCategories.value.map((tagCategory) => tagCategory.name );

  tagCategories.value.forEach((tagCategory) => allTagsList.value.push(...tagCategory.tags));

  selectedTagsList.value = campaignDraft.value.participant_filter_tag_ids?.map((id) => allTagsList.value.find((element) => element.id === id));
  updateTagsRequest();
})

const updateTagsRequest = async() => {
  try {
    const { data } = await axiosService.get('/api/v1/users/filter_by_tag_categories', {
      params: {
        tag_ids: campaignDraft.value.participant_filter_tag_ids,
      }
    })
    usersSelected.value = data.users;
  } catch (error) {
    console.log(error);
  }
}

const toggleTagSelection = (element) => {
  const found = selectedTagsList.value?.find(tag => tag.id === element.id);

  if (found) {
    selectedTagsList.value?.splice(selectedTagsList.value?.findIndex((tag) => tag.id === element.id), 1);
    campaignDraft.value.participant_filter_tag_ids.splice(campaignDraft.value.participant_filter_tag_ids.findIndex((tagId) => tagId === element.id), 1);

    updateTagsRequest();
  }
  else {
    selectedTagsList.value?.push(element);
    campaignDraft.value.participant_filter_tag_ids?.push(element.id);

    updateTagsRequest();
  }
}

const toggleAllTagsSelection = () => {
  if (selectedTagsList.value?.length !== allTagsList.value.length) {
    selectedTagsList.value = allTagsList.value.slice();
    campaignDraft.value.participant_filter_tag_ids = selectedTagsList.value?.map(tag => tag.id)

    updateTagsRequest();
  }
  else {
    selectedTagsList.value = [];
    campaignDraft.value.participant_filter_tag_ids = [];

    updateTagsRequest();
  }
}

const clearAllSelectedTags = () => {
  selectedTagsList.value = [];
  campaignDraft.value.participant_filter_tag_ids = [];

  updateTagsRequest();
}

const updateTagSearch = (e) => {
  tagSearch.value = e.target.value;

  allTagsList.value.forEach((tag) => {
    if ((tag.tagName).startsWith(tagSearch.value)) {
      searchedTagsNames.value = allTagsList.value.filter((element) => (element.tagName).startsWith(tagSearch.value))
    }
  })
}
</script>

<style scoped>
.input :deep(.v-field__input) {
  min-height: 48px;
}
</style>