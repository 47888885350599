<template>
  <div class="w-full flex flex-col justify-center items-center sm:flex-row gap-4 mb-4 mt-4">
    <v-text-field
      v-model="text"
      variant="outlined"
      prepend-inner-icon="mdi-magnify"
      clearable
      class="w-full hidden sm:block text-darkGrey"
      color="primary"
      hide-details
      :label="$t('Search')"
      @update:model-value="updateText"
    />

    <!-- Settings on all: icon mdi-dots-vertical -->
    <bkt-learn-list-settings
      :content-values="settingsOnAllItems"
    />

    <!-- Modal Deleted Items to CUSTOMIZE -->
    <modal-view-deleted-items
      ref="deletedItems"
      content-type="template"
      :contents="roadmapTrashesTemplates"
      @toggle-search="emit('search-items', $event)"
      @restore="emit('restore', $event)"
      @delete-permanently="emit('delete-permanently-template', $event)"
      @view-item="router.push({ name: 'roadmaps_templates_show', params: { id: $event} })"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoadmapTemplateStore } from "@/store/roadmap-template";
import { debounce } from "lodash";
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from "@/plugins/i18n.js";
import {useRouter} from "vue-router";

const router = useRouter();

const { params } = storeToRefs(useRoadmapTemplateStore());

const props = defineProps({
  roadmapTrashesTemplates: { type: Object, required: true }
})

const emit = defineEmits(["fetch", "search-items", "restore", "delete-permanently-template"]);
const text = ref(params.value.title);
const deletedItems = ref(null)

const updateText = debounce((e) => {
  text.value = e;
  emit("fetch", {
    title: text.value,
  });
}, 300);

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted templates'),
    onClick: openDeletedItems
  }
])

</script>