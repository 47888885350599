<template>
  <bkt-pop-up-modal-skeleton
    v-model="modalDeletedItems"
    :content-class="isMobile ? '!rounded-none bg-white bg-white w-full min-h-[320px]' : '!rounded-xl bg-white min-w-[600px] min-h-[320px]'"
  >
    <!-- To remove the "Open Dialog" test, here by default with bkt-pop-up-modal-skeleton. Removing the v-bind  -->
    <template #button-div>
      {{ "" }}
    </template>

    <template #modal>
      <!-- HEADER -->
      <div
        class="w-full h-full flex-col"
      >
        <!-- Desktop -->
        <div
          class="hidden lg:flex gap-2 w-full relative px-4 py-5 items-center justify-center border-b border-middle-grey"
        >
          <div class="flex flex-grow justify-center ml-6">
            <p
              class="font-medium text-xl text-fakeBlack line-clamp-1"
            >
              {{ $t(`Deleted ${capitalizedContentType}s`) }}
            </p>
          </div>

          <div
            class="self-end"
            @click="closeModal"
          >
            <Icon
              :icon="'material-symbols:close'"
              height="24"
              width="24"
              class="text-dark-grey hover:text-fake-black cursor-pointer"
            />
          </div>
        </div>

        <!-- Mobile -->
        <div
          class="lg:hidden flex flex-col w-full relative items-center justify-center"
        >
          <div class="flex justify-end pt-4 pr-4 w-full">
            <div @click="closeModal">
              <Icon
                :icon="'material-symbols:close'"
                class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
              />
            </div>
          </div>

          <div class="px-4 gap-2 w-full flex justify-center items-center border-b border-middle-grey">
            <p
              class="font-medium text-xl text-fakeBlack line-clamp-1 pb-4"
            >
              {{ $t(`Deleted ${capitalizedContentType}s`) }}
            </p>
          </div>
        </div>

        <!-- BODY -->
        <div
          class="flex flex-col h-full"
        >
          <!-- Warning and Searchbar -->
          <div
            class="flex flex-col p-4 gap-4 justify-center"
          >
            <p
              class="flex-grow text-dark-grey text-center font-normal text-xs pb-4"
            >
              {{ $t(`Deleted ${contentType}s are stored here for 30 days. After this period, they will be permanently deleted.`) }}
            </p>

            <!-- Barre de recherche -->
            <v-text-field
              v-model="searchText"
              hide-details
              class="text-sm font-normal w-full custom-height"
              variant="outlined"
              :label="$t('Search')"
              prepend-inner-icon="mdi-magnify"

              subtitles="myS"
              base-color="darkGrey"
              color="darkGrey"
              @input="emit('toggle-search', searchText)"
            />
          </div>

          <!-- Liste des items -->
          <div
            v-if="contents?.length"
            class="flex flex-col sm:max-h-[330px] overflow-y-auto mb-3"
          >
            <div
              v-for="(item, index) in contents"
              :key="index"
              class="px-6 py-4 flex gap-3 flex-row justify-between border-b text-fake-black text-sm items-center cursor-pointer hover:bg-[#F6F6F6]"
              @click="emit('view-item', item.id)"
            >
              <span
                v-if="hasTitle"
                class="ml-1 flex basis-1/3 truncate"
              >
                <Icon
                v-if="item?.campaign_type"
                :icon="types[item.campaign_type]?.icon"
                width="16"
                height="16"
                class="mr-1"
              />
                <p
                  class="flex-grow font-medium items-center ml-1 truncate"
                >

                  {{ item[title] }}
                </p>
              </span>

              <div v-if="hasUser" class="flex flex-row items-center basis-1/3 truncate">
                <svn-avatar
                  :avatar="item?.[userAttributeName]?.['avatar']?.['30']"
                  :firstname="item?.[userAttributeName]?.['firstname']"
                  :lastname="item?.[userAttributeName]?.['lastname']"
                  size="sm"
                  :class="avatarSize > 35 ? '!text-[18px]' : '!text-[13px]'"
                  class="mr-1rem"
                />
                <div class="flex flex-col truncate">
                  <p :class="fontWeight" class="truncate">{{ item?.[userAttributeName]?.['firstname'] }} {{ item?.[userAttributeName]?.['lastname'] }}</p>
                  <p v-if="hasUserWithCreatedAt" class="text-darkGrey">
                    {{ $filters.formatSpecificDate(item.created_at, "YYYY-MM-DD hh:mm:ss ZZ", "DD MMM, YYYY") }}
                  </p>
                </div>
              </div>
              <div
                class="flex gap-6 justify-start basis-1/3"
              >
                <div
                  class="flex gap-2 items-center"
                >
                  <Icon
                    icon="mdi:calendar-remove-outline"
                    class="h-4 w-4"
                  />
                  <p
                    class="min-w-[92px]"
                  >
                    <!-- Tooltip date de suppression -->
                    <svn-tooltip
                      location="top start"
                      origin="bottom end"
                    >
                      <template #activator-content>
                        {{ $filters.formatSpecificDate(item.deleted_at, "YYYY-MM-DD hh:mm:ss ZZ", "DD MMM, YYYY") }}
                      </template>

                      <template #tooltip-content>
                        {{ $t(`Deletion date`) }}
                      </template>
                    </svn-tooltip>
                  </p>
                </div>

                <div
                  class="flex gap-2 text-middle-grey "
                >
                  <!-- Tooltip restore -->
                  <svn-tooltip
                    location="top start"
                    origin="bottom end"
                  >
                    <template #activator-content>
                      <Icon
                        icon="mdi-restore"
                        height="24"
                        width="24"
                        class="hover:text-dark-grey"
                        @click="emit('restore', item.id)"
                      />
                    </template>

                    <template #tooltip-content>
                      {{ $t(`Restore ${contentType}`) }}
                    </template>
                  </svn-tooltip>

                  <!-- Tooltip Delete -->
                  <svn-tooltip
                    location="top start"
                    origin="bottom end"
                  >
                    <template #activator-content>
                      <dialog-validation
                        :icon="'mdi-trash-can-outline'"
                        :title="`Do you really want to delete this ${contentType} permanently ?`"
                        :display-close="false"
                        primary-button-text="Yes, delete it"
                        secondary-button-text="Cancel"
                        button-primary-color="negativeRed"
                        @click-primary-button="emit('delete-permanently', item.id)"
                      >
                        <template #button>
                          <Icon
                            :icon="'mdi-trash-can-outline'"
                            height="24"
                            width="24"
                            class="hover:text-dark-grey"
                          />
                        </template>
                      </dialog-validation>
                    </template>

                    <template #tooltip-content>
                      {{ $t('Delete permanently') }}
                    </template>
                  </svn-tooltip>
                </div>
              </div>
            </div>
          </div>

          <!-- No deleted item -->

          <div
            v-else-if="searchText.length"
            class="flex flex-col justify-center items-center py-5"
          >
            <Icon
              class="mb-5"
              icon="noto-face-with-monocle"
              height="24"
              width="24"
            />

            <p class="font-medium text-sm mb-5">
              {{ $t("Hmm, it seems we can't find any results based on your search.") }}
            </p>
          </div>

          <div
            v-else
            class="flex flex-col justify-center items-center py-5"
          >
            <Icon
              class="mb-5"
              icon="noto-winking-face"
              height="24"
              width="24"
            />

            <p class="font-medium text-sm mb-5">
                {{ $t(`Hmm, it seems you have no deleted ${contentType}s.`) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </bkt-pop-up-modal-skeleton>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import DialogValidation from './DialogValidation.vue';
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  contentType: { type: String, required: true },
  contents: { type: Array, required: true },
  title: { type: String, default: 'title' },
  hasUser: { type: Boolean, default: false },
  hasTitle: { type: Boolean, default: true },
  userAttributeName: { type: String, default: 'employee' },
  hasUserWithCreatedAt: { type: Boolean, default: false },
  fontWeight: { type: String, default: 'font-normal' },
  avatarSize: { type: Number, default: 24 },
})

const mobileStore = useMobileStore()
const { isMobile } = storeToRefs(mobileStore);

const modalDeletedItems = ref(false)
const searchText = ref('')

const emit = defineEmits(['toggle-search', 'delete-permanently', 'restore', 'view-item'])

defineExpose({
  modalDeletedItems
})

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const capitalizedContentType = capitalizeFirstLetter(props.contentType);

const closeModal = () => {
  modalDeletedItems.value = false
}

const types = {
  Survey: {
    icon: "noto:bar-chart"
  },
  OneToOne: {
    icon: "twemoji:handshake"
  },
  Feedback: {
    icon: "noto:memo"
  }
}

</script>

<style>
.v-text-field input.v-field__input {
  height: 48px !important;  /* Set the custom height */
  min-height: 48px !important;
}
</style>
