<template>
  <div class="w-full flex flex-col-reverse gap-2 pt-6 items-start md:flex-row md:justify-between md:items-center md:gap-0  md:!py-0">
    <v-tabs
      v-model="page"
      :grow="isMobile"
      class="text-darkGrey"
      color="fakeBlack"
    >
      <div
        class="flex grow items-center w-full justify-start z-20 bkt-bg-white border-solid border-lightGrey border-b-[1px]
        md:grow-0 md:w-fit md:top-[75px] md:border-b-2"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab"
          :value="tab.value"
        >
          <div class="flex w-full items-center py-4 pb-3">
            <p class="!normal-case">
              {{ tab.title }}
            </p>
          </div>
        </v-tab>
      </div>
    </v-tabs>

    <div class="w-full md:w-fit">
      <v-text-field
        v-model="searchText"
        color="primary"
        class="w-full text-darkGrey md:w-[258px]"
        variant="outlined"
        density="compact"
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
      />
    </div>
  </div>

  <!-- Contenu des onglets -->
  <v-window v-model="page" class="!w-full">
    <v-window-item value="modules">
      <sub-tabs-in-learnings-module
        :headers="learningsHeadersModules"
        :user="user"
        :search-text="searchText"
      />
    </v-window-item>

    <v-window-item value="trainings">
      <sub-tabs-in-learnings-training
        :headers="learningsHeadersTrainings"
        :user="user"
        :search-text="searchText"
        />
      </v-window-item>
  </v-window>

  <!-- Floating Button ton top -->
  <svn-floating-action-button
    square
    to-top
  />
</template>

<script setup>
import { ref, computed } from "vue";
import i18n from '@/plugins/i18n';
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import SubTabsInLearningsModule from "@/components/BktSubTabsLearningsInProfileModule.vue"
import SubTabsInLearningsTraining from "@/components/BktSubTabsLearningsInProfileTraining.vue"

const searchText = ref('')
const page = ref('information');

const mobileStore = useMobileStore()
const { isMobile } = storeToRefs(mobileStore);

const props = defineProps({
  user: { type: Object, required: true }
});

const tabs = [
  {
    value: "modules",
    title: i18n.global.t("Modules")
  },
  {
    value: "trainings",
    title: i18n.global.t("Trainings")
  }
]

const learningsHeadersModules = computed(() => {
  return [
    { title: "Name",
      key: "name",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "Progress",
      key: "progress",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Acquired on",
      key: "acquired",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
});

const learningsHeadersTrainings = computed(() => {
  return [
    { title: "Name",
      key: "name",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Acquired on",
      key: "acquired",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
});
</script>

<style scoped>

:deep(.v-input__control) {
  color: #333333;
  font-weight: 500;
}

</style>
