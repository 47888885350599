<template>
  <v-data-table
    v-if="interviewForms"
    :headers="headersTmp"
    :items="interviewForms"
    :loading="loading"
    :items-per-page="-1"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in headersTmp"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey font-semibold"
        >
          <div class="flex flex-row items-center">
            <Icon
              :icon="header.icon"
              width="16"
              height="16"
              class="mr-1"
            />
            <span>{{ header.title }}</span>
          </div>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey10-hover"
        @click="$router.push({
          name: 'interviews_template_edit',
          params: { id: item.raw?.id },
        })"
      >
        <td class="truncate">
          <div>
            <p :title="item.raw?.title">
              {{ resizeTitle(item.raw?.title) }}
            </p>
          </div>
        </td>

        <td>
          <div>
            <p>{{ $t(interviewFormTypeToStr[item.raw?.template_type]) }}</p>
          </div>
        </td>

        <td>
          <div>
            <p>{{ item.raw?.interview_question_count }}</p>
          </div>
        </td>

        <td>
          <div>
            <p>{{ $filters.formatDate(item.raw?.updated_at) }}</p>
          </div>
        </td>

        <td class="width-24rem ml-4">
          <div>
            <display-tag-in-index :tags="item.raw?.categories" />
          </div>
        </td>

        <td class="!w-[50px] pa-0">
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <dialog-edit-template-tags
                fetch-tags-from-entity-path="categories_from_template"
                route="forms/templates"
                :entity-id="item.raw?.id"
                @refresh="fetchInterviewForms"
              >
                <template #activator>
                  <v-list-item
                    value="edit_tags"
                  >
                    {{ $t('Edit template tags') }}
                  </v-list-item>
                </template>
              </dialog-edit-template-tags>

              <v-list-item
                value="duplicate"
                @click="duplicate(item?.raw?.id)"
              >
                {{ $t('Duplicate') }}
              </v-list-item>

              <v-list-item
                value="delete"
                class="text-negativeRed"
                @click="deleteInterviewFormPop(item?.raw?.id)"
              >
                {{ $t('Delete') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && interviewForms && interviewForms.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="mt-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>

        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>
  </v-data-table>
</template>

<script setup>
import { useInterviewFormStore } from "@/store/interview-form.js";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { usePopUpStore } from "@/store/pop-up";
import DisplayTagInIndex from "@/components/DisplayTagInIndex.vue";
import axiosService from "@/tools/axios-service.js";
import { ref } from "vue";
import { interviewFormTypeToStr } from "@/constants/types.js";
import { useRouter } from "vue-router";
import i18n from "@/plugins/i18n";
import DialogEditTemplateTags from "@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue";
import {useMobileStore} from "@/store/mobile.js";
import { useSnackbar } from "@/store/snackbar";

const snackbar = useSnackbar();

const router = useRouter();

const headersTmp = [
{
    align: "start",
    key: "title",
    sortable: false,
    title: i18n.global.t('Template title'),
    width: 300,
    icon: "ic-baseline-title"
  },
  {
    align: "start",
    key: "type",
    sortable: false,
    title: i18n.global.t('Type'),
    width: 200,
    icon: "ic-outline-arrow-drop-down-circle"
  },
  {
    align: "start",
    key: "questions",
    sortable: false,
    title: i18n.global.t('Questions'),
    width: 100,
    icon: "ic-person-outline"
  },
  {
    align: "start",
    key: "last_update",
    sortable: false,
    title: i18n.global.t('Last Update'),
    width: 200,
    icon: "akar-icons:calendar"
  },
  {
    align: "start",
    key: "tags",
    sortable: false,
    title: i18n.global.t('Tags'),
    width: 200,
    icon: "ph-tag-bold"
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    width: 50
  }
];

const editModal = ref(false);
const {isLargeScreen} = useMobileStore()


const { openPopUp, closePopUp } = usePopUpStore();
const { interviewForms, pagination, loading } = storeToRefs(useInterviewFormStore());
const { updateParams, fetchInterviewForms, deleteInterviewForm, setInterviewForms, fetchCampaignDraftsExist } =
  useInterviewFormStore();

const duplicate = async (id) => {
  try {
    const res = await axiosService.post(
      `/api/v2/interview_app/forms/templates/${id}/duplicate`
    );

    await router.push({
      name: "interviews_template_edit",
      params: { id: res.data.template_id },
    });
  } catch (e) {
    console.log("error", e);
  }
};
const resizeTitle = (title) => {
  if (!title) return title
  const maxChars = isLargeScreen.value ? 30 : 20
  return title.length > maxChars ? title.substring(0, maxChars) + '...' : title
};


const deleteInterviewFormPop = async(id) => {
  try {
    const campaignDraftsExist = await fetchCampaignDraftsExist(id)

    if (campaignDraftsExist) {
      openPopUp({
        componentName: "pop-up-validation",
        title: i18n.global.t("Are you sure you want to delete this template ?"),
        subtitle: i18n.global.t("Attention: Deleting the template will result in the deletion of all campaign drafts linked to this template."),
        textClose: "No, cancel",
        textConfirm: "Yes, delete this template",
        textLoading: "Deleting ...",
        icon: "noto-warning",
        color: "red",
        customClass: "w-[400px]",
        showClose: false,
        async confirm() {
          await deleteInterviewForm(id);
          snackbar.setBgColor('fakeBlack');
          snackbar.setMsg('Your template has been deleted !')
          snackbar.displaySnackBar()
          closePopUp();
        },
      });
    }
    else {
      openPopUp({
        componentName: "pop-up-validation",
        title: i18n.global.t("Are you sure you want to delete this template ?"),
        subtitle: i18n.global.t("This is a permanent action."),
        textClose: "No, cancel",
        textConfirm: "Yes, delete this template",
        textLoading: "Deleting ...",
        icon: "mdi-trash-can-outline",
        color: "red",
        customClass: "w-[400px]",
        showClose: false,
        async confirm() {
          await deleteInterviewForm(id);
          snackbar.setBgColor('fakeBlack');
          snackbar.setMsg('Your template has been deleted !')
          snackbar.displaySnackBar()
          closePopUp();
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const fetchNewPage = (e) => {
  setInterviewForms(null);
  updateParams({
    "page[number]": e,
  });
  fetchInterviewForms();

};
</script>
