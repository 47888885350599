<template>
  <v-app>
    <v-main class="relative">
      <BktToasts />

      <bkt-impersonate />

      <div class="h-full">
        <router-view />

        <v-dialog
          v-model="data.open"
          width="auto"
          :persistent="data.event !== null"
          @click:outside="clickOutside"
        >
          <bkt-pop-up />
        </v-dialog>
      </div>

      <v-snackbar
        v-model="snackbar.display"
        :color="snackbar.bgColor"
        :timeout="snackbar.timeout"
        :vertical="isActionLong(snackbar)"
        :content-class="[snackbar.customClass, getContentClass(snackbar)]"
      >
        <div :style="{ color: snackbar.textColor }">
          {{ $t(snackbar.msg) }}
        </div>

        <template #actions>
          <div
            :class="isActionLong(snackbar) ? 'flex items-end w-full' : 'flex flex-row items-center justify-end w-full'"
          >
            <v-btn
              v-if="snackbar.action !== null"
              color="white"
              class="color-veryLightGrey normal-case"
              @click="snackbar?.action?.callback()"
            >
              {{ snackbar.action.name }}
            </v-btn>

            <v-btn
              v-if="snackbar.closeButton !== null"
              icon
              :color="snackbar.closeColor"
              variant="text"
              @click="snackbar.closeSnackBar"
            >
              <v-icon
                :color="snackbar.closeColor"
                :icon="snackbar.closeLogo"
              />
            </v-btn>
          </div>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script setup>
import BktPopUp from "./components/popUpComponents/BktPopUp.vue";
import { usePopUpStore } from "./store/pop-up.js";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar.js";
import BktImpersonate from "@/components/impersonate/BktImpersonate.vue";
import BktToasts from "@/components/toastsComponent/BktToasts.vue";
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import i18n from "@/plugins/i18n.js";
import {useToastStore} from "@/store/toast.js";
import {useActionCable} from "@/store/cable.js";
import {useUserStore} from "@/store/user.js";

const { data } = storeToRefs(usePopUpStore());
const { closePopUp } = usePopUpStore();
const snackbar = useSnackbar();

const { cable } = storeToRefs(useActionCable())
const { id } = storeToRefs(useUserStore())
const { addToast } = useToastStore();

snackbar.init();

const clickOutside = () => {
  closePopUp()
  try {
    data?.value?.event();
  } catch (error) {}
};

const getContentClass = (snackbar) => {
  if (snackbar?.action && isActionLong()){
    return "flex flex-col items-start w-full";
  }
  return "";
};

const isActionLong = (snackbar) => {
  if (snackbar?.action){
    return snackbar?.action?.name?.length > 8;
  }
  return false
};

const notificationChannel = ref(null)

watch(id, () => {
  if (id.value) {
    if (cable.value) {
      const subscribeOptions =
        {
          channel: "NotificationChannel", user_id: id.value
        }

      notificationChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
        connected: function() {
          // Called when the subscription is ready for use on the server
        },

        disconnected: function() {
          // Called when the subscription has been terminated by the server
        },

        received: function(data) {
          // Called when there's incoming data on the websocket for this channel
          if (data.status === "send") {
            addToast(
              'info',
              data.content.title,
              data.content.description,
              false,
              {name: 'view badges', link: '/people/user_show/' + id.value},
              data.content.notification_type,
              data.content.icon,
              data.content.badge,
            )
          }
        },

        update: function() {
        },
      });
    }
  }
})

onBeforeUnmount(() => {
  notificationChannel.value.unsubscribe();
})

</script>

<style lang="scss">
@import "@/assets/stylesheets/config/nest.scss";

</style>
