<template>
  <dialog-validation
    v-if="selected && ['remark', 'question'].includes(type)"
    :display-close="false"
    :secondary-button-text="'No, cancel'"
    :primary-button-text="'Yes, remove reaction'"
    :button-primary-color="'negativeRed'"
    :icon="'mdi-trash-can-outline'"
    :title="'Are you sure you want to remove this reaction ?'"
    :description="'By deleting it, the associated comment will also be deleted.'"
    @click-primary-button="emit('toggle-remove-reaction-pop-up')"
  >
    <template #button>
      <v-hover>
        <template #default="{ isHovering, props }">
          <div class="relative w-full">
            <div
              class="w-[150px] h-[192px] p-2 flex flex-col gap-2 w-full rounded-[8px]
              border-0 "
              :class="isHovering ? ' !bg-[#F6F6F6] !shadow-[0_1px_5px_0px_rgba(0,0,0,0.2)]' :
                selected ? 'border !border-[#EF8C64] !shadow-[0_1px_5px_0px_rgba(0,0,0,0.2)]' : 'border-[#FFFFFF]'"
            >
              <p
                class="h-[46px] text-xl font-medium w-full text-center"
                :class="[(isHovering ? 'text-[#787878]' : '!text-middleGrey'),
                         (selected ? '!text-[#787878]' : 'text-middleGrey')]"
              >
                {{ $t(name) }}
              </p>

              <div class="w-[60px] h-[90px] flex justify-center items-center self-center">
                <iframe
                  :src="icon"
                  width="60"
                  height="60"
                />
              </div>

              <div class="flex justify-center items-center bg-training-orange-soft self-center rounded-[8px] px-1">
                <p class="text-training-orange text-sm">
                  {{ count }}
                </p>
              </div>
            </div>
            <div
              v-bind="props"
              class="absolute flex top-0 w-full h-full rounded-[8px] cursor-pointer"
              @click="onCardClick"
            />
          </div>
        </template>
      </v-hover>
    </template>
  </dialog-validation>

  <dialog-reaction-click
    v-else
    :reaction-type="type"
    :selected="selected"
    :bind="bind"
    @toggle-reaction="emit('toggle-reaction-pop-up', $event)"
  >
    <template #button>
      <v-hover>
        <template #default="{ isHovering, props }">
          <div class="relative w-full">
            <div
              class="w-[150px] h-[192px] p-2 flex flex-col gap-2 w-full rounded-[8px]
              border-0 "
              :class="isHovering ? ' !bg-[#F6F6F6] !shadow-[0_1px_5px_0px_rgba(0,0,0,0.2)]' :
                selected ? 'border !border-[#EF8C64] !shadow-[0_1px_5px_0px_rgba(0,0,0,0.2)]' : 'border-[#FFFFFF]'"
            >
              <p
                v-if="name"
                class="h-[46px] text-xl font-medium w-full text-center"
                :class="[(isHovering ? 'text-[#787878]' : '!text-middleGrey'),
                         (selected ? '!text-[#787878]' : 'text-middleGrey')]"
              >
                {{ $t(name) }}
              </p>

              <div class="w-[60px] h-[90px] flex justify-center items-center self-center">
                <iframe
                  :src="icon"
                  width="60"
                  height="60"
                />
              </div>

              <div class="flex justify-center items-center bg-training-orange-soft self-center rounded-[8px] px-1">
                <p class="text-training-orange text-sm">
                  {{ count }}
                </p>
              </div>
            </div>
            <div
              v-bind="props"
              class="absolute flex top-0 w-full h-full rounded-[8px] cursor-pointer"
              @click="onCardClick"
            />
          </div>
        </template>
      </v-hover>
    </template>
  </dialog-reaction-click>
</template>

<script setup>
import DialogReactionClick from '@/components/BktPopUp/Dialogs/learn/DialogReactionClick.vue';
import DialogValidation from '@/components/BktPopUp/Dialogs/learn/DialogValidation.vue';

const props = defineProps({
  name: { type: String, required: true },
  icon: { type: String, required: true },
  count: { type: Number, required: true },
  type: { type: String, required: true },
  selected: { type: Boolean, default: false },
  bind: { type: Boolean, default: false },
})

const emit = defineEmits(['toggle-reaction', 'toggle-reaction-pop-up', 'toggle-remove-reaction-pop-up'])

const onCardClick = () => {
  if (props?.type !== 'remark' && props?.type !== 'question') {
    emit('toggle-reaction')
  }
}
</script>

<style scoped>
.card :hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>