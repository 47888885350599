<template>
  <div class="w-full flex flex-col relative bg-white">
    <bkt-index-skeleton body-class="bkt-bg-white rounded mt-20 mb-32 px-0 pt-0 sm:!pt-5 pb-0">
      <template #title>
        <p class="mt-5 sm:!mt-0 mb-2 font-medium sm:!mb-0">
          {{ $t("Targets") }}
        </p>
      </template>

      <template #create-index>
        <div class="flex flex-row justify-center sm:!justify-end mt-2 mb-5 sm:!mt-0 sm:!mb-0">
          <bkt-new-target-button />
        </div>
      </template>

      <template #body>
        <target-index-search
          :trashes-targets="roadmapTrashesTargets"
          @fetch="reloadAfterSearch"
          @update-table="reloadAfterArchiveOrDelete"
          @search-items="toggleSearchDeletedItems"
          @restore="restoreDeletedItems"
          @delete-permanently="deletePermanentlyDeletedItems"
        />

        <roadmap-targets-table
          v-if="roadmapTargets && roadmapTargets.length > 0"
          :roadmap-targets="roadmapTargets"
          @delete="deleteRoadmapTarget($event)"
        />

        <bkt-create-entity-from-index
          v-else-if="roadmapTargets && !roadmapTargets.length &&
            !(params.title || params.indicator_type ||
              params.indicator_status || params.from || params.to)"
          to="roadmaps_targets_new"
        >
          {{ $t("employee target") }}
        </bkt-create-entity-from-index>

        <bkt-no-entity-from-index
          v-else-if="roadmapTargets && !roadmapTargets.length &&
            (params.title || params.indicator_type ||
              params.indicator_status || params.from || params.to)"
        />

        <div v-else-if="!roadmapTargets" class="flex justify-center h-[30vh] items-center w-full">
          <svn-loader loading-size="lg" />
        </div>
      </template>
    </bkt-index-skeleton>
  </div>
</template>
<script setup>
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import BktNoEntityFromIndex from "@/components/BktNoEntityFromIndex.vue";
import { storeToRefs } from "pinia";
import RoadmapTargetsTable from "@/components/roadmapApp/target/Table.vue";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import TargetIndexSearch from "@/components/roadmapApp/target/TargetIndexSearch.vue";
import { useUserStore } from "@/store/user.js";
import { useRouter } from "vue-router";
import {onMounted} from "vue";
import i18n from "@/plugins/i18n.js";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
import { debounce } from "lodash";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";

const { roadmapTargets, roadmapTrashesTargets, params } = storeToRefs(useRoadmapTargetStore());
const { fetchRoadmapTargets, deletePermanentlyRoadmapTarget, fetchRoadmapTrashesTarget,
  restoreRoadmapTarget, updateParams,
  deleteRoadmapTarget, setRoadmapTargets } = useRoadmapTargetStore();
const router = useRouter();
const { accessLevel } = storeToRefs(useUserStore());
const { addNode, reset, update } = useBreadcrumbsStore()
const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore())
const authorizedAccessLevel = ["super_admin", "admin", "manager_creator"];

onMounted(() => {
  if (reset('/roadmaps/targets/index')) {
    addNode(i18n.global.t('Targets'), '/roadmaps/targets/index')
    update()
  }
})

if (!authorizedAccessLevel.includes(accessLevel.value))
  router.push({ name: "my_roadmaps" });

const reloadAfterSearch = (params) => {
  setRoadmapTargets(null);
  updateParams({
    title: params.title,
    indicator_type: params.indicator_type,
    indicator_status: params.indicator_status,
    from: params.from,
    to: params.to,
    "page[number]": 1,
  });
  fetchRoadmapTargets();
}

const reloadAfterArchiveOrDelete = () => {
  setRoadmapTargets(null);
  fetchRoadmapTargets()
}
fetchRoadmapTargets();
fetchRoadmapTrashesTarget()

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    updateParams({
      title: value
    })
    await fetchRoadmapTrashesTarget()
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Target restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePermanentlyRoadmapTarget(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Target deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}
</script>
