<template>
  <div
    class="w-full h-fit flex flex-col"
  >
    <v-tabs
      v-model="tab"
      grow
      align-tabs="center"
      color="primary"
      class="border-b border-b-darkGrey"
    >
      <v-tab
        v-for="(detail, index) in details"
        :key="index"
        :value="detail?.type"
      >
        <div class="flex items-center gap-2">
          <div class="hidden sm:block">
            <Icon
              :icon="getTabIcon(detail?.type)"
              height="20"
              width="20"
              :class="tab === detail?.type ? 'text-fakeBlack' : 'text-darkGrey'"
            />
          </div>

          <p
            class="text-sm font-medium capitalize"
            :class="tab === detail?.type ? 'text-fakeBlack' : 'text-darkGrey'"
          >
            {{ $t(detail?.type) }}
          </p>

          <div
            class="w-[22px] h-[22px] flex items-center justify-center rounded-[3px] bg-lightGrey"
            :class="tab === detail?.type ? 'text-fakeBlack' : 'text-darkGrey'"
          >
            {{ detail?.changes?.length || 0 }}
          </div>
        </div>
      </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item
        v-for="(detail, index) in details"
        :key="index"
        :value="detail?.type"
      >
        <div v-if="detail?.changes?.length" class="w-full h-full sm:h-[450px] flex flex-col items-start p-4 gap-2 overflow-y-auto">

          <div
            v-for="(change, index) in detail?.changes"
            :key="index"
            class="flex items-center px-2 py-1 self-stretch gap-2 border border darkGrey rounded-[8px]"
          >
            <div>
              <Icon
                :icon="getetailIcon(change?.key)"
                height="24"
                width="24"
                class="text-middleGrey"
              />
            </div>
            <div class="flex flex-col justify-center items-start flex-1">
              <p class="text-fakeBlack text-sm font-medium">
                {{ change?.fullname }} {{ change?.name }}
              </p>

              <p class="text-darkGrey text-xs font-normal">
                {{ change?.email || change?.key }}
              </p>

              <div
                v-if="change?.access_level"
                class="flex items-center gap-1"
              >
                <div>
                  <Icon
                    icon="fluent:key-20-regular"
                    height="12"
                    width="12"
                    class="text-darkGrey"
                  />
                </div>

                <p class="text-darkGrey text-xs font-normal">
                  {{ change?.access_level }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="w-full h-full sm:h-[450px] flex flex-col items-center p-8 gap-2 overflow-y-auto">
          <p class="text-sm font-medium text-center">
            {{ detail.type == 'created' ?
              'No new content added during import.' : detail.type == 'updated' ?
              'No content modified during import.' : detail.type == 'deleted' ?
              'No content deleted during import.' :  ''
            }}
          </p>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps({
  details: { type: Object, default: () => {} }
})

const tab = ref(null)

const getetailIcon = (key) => {
  if (key === 'user') {
    return 'ic:baseline-person'
  }
  else if (key === 'tag_category') {
    return 'mdi:tags'
  }
  else if (key === 'tag') {
    return 'mdi:tag'
  }
}

const getTabIcon = (type) => {
  if (type === 'created') {
    return 'mdi-add'
  }
  else if (type === 'updated') {
    return 'grommet-icons:update'
  }
  else if (type === 'deleted') {
    return 'mdi-trash-can-outline'
  }
}
</script>
