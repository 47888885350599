<template>
  <v-card
    class="m-3"
  >
    <v-card-item>
      <div class="my-3 flex flex-row items-center justify-center">
        <campaign-show-search
          :campaign="campaign"
          :overview="overview"
          :campaign-overview="campaignOverview"
          :set="set"
          @fetch="
            fetchFromCampaignShowSearch(
              $event,
              campaignId || route.params.id
            )
          "
        />

        <svn-dots-button
          table
          :show="!isTrashed"
          circle
          variant="hoverNoPrimary"
          class="self-justify-start !items-start"
        >
          <template #items>
            <v-list-item
              v-if="!anonymous"
              value="add_participant"
              @click="openAddParticipant"
            >
              {{ $t('+ Add participant') }}
            </v-list-item>

            <v-list-item
              value="send_invitation_to_all"
              @click="sendInvitationToAll"
            >
              {{ $t('Send invitation to all') }}
            </v-list-item>

            <v-list-item
              value="send_reminder_to_all"
              @click="sendReminderToAll"
            >
              {{ $t('Send reminder to all') }}
            </v-list-item>
          </template>
        </svn-dots-button>
      </div>

      <div v-if="tableData && set">
        <v-data-table
          v-if="tableData.length > 0"
          v-model:items-per-page="items"
          :headers="headers"
          :items="tableData"
          fixed-header
          :height="60 + (tableData.length > 10 ? 55 * 10 : 55 * tableData.length)"
          :hover="true"
          @click:row="clickRow"
        >
          <template #item.participant="item">
            <div class="flex flex-row items-center justify-start space-x-2">
              <v-icon
                v-if="anonymous"
                size="35"
                color="blue"
              >
                mdi-account
              </v-icon>

              <svn-avatar
                v-else
                :avatar="item.item.raw.avatar?.['100']"
                :firstname="item.item.raw.fullname?.[0]"
                :lastname="item.item.raw.fullname?.[1]"
                size="md"
              />

              <div>
                <p class="text-sm">
                  {{ anonymous ? `Participant ${item.index}` : item.item.raw.fullname }}
                </p>
              </div>
            </div>
          </template>

          <template #item.job_title="item">
            <div class="flex justify-start items-center">
              <p class="text-sm">
                {{ item.item.raw.job_title }}
              </p>
            </div>
          </template>

          <template #item.completion="item">
            <campaign-status-chip :status="item.item.raw.completion" />
          </template>

          <template #item.action="item">
            <div class="w-full flex justify-end">
              <svn-dots-button
                table
                :show="!isTrashed"
                circle
                variant="hoverNoPrimary"
                class="self-justify-end"
              >
                <template #items>
                  <v-list-item
                    v-if="!anonymous"
                    value="see_profile"
                    @click="goToProfile(item.item.raw.fullname, { params: { id: item.item.raw.employeeId } })"
                  >
                    {{ $t('See participant profile') }}
                  </v-list-item>

                  <v-list-item
                    value="send_invitation"
                    @click="sendNotif('invite', item.item.raw.employeeId)"
                  >
                    {{ $t('Send invitation email') }}
                  </v-list-item>

                  <v-list-item
                    value="send_reminder"
                    @click="sendNotif('remind', item.item.raw.employeeId)"
                  >
                    {{ $t('Send reminder email') }}
                  </v-list-item>

                  <v-list-item
                    v-if="!anonymous"
                    value="remove_from_campaign"
                    @click="removeFromCampaign(item.item.raw.employeeId)"
                  >
                    {{ $t('Remove from campaign') }}
                  </v-list-item>
                </template>
              </svn-dots-button>
            </div>
          </template>

          <template #bottom>
            <div class="text-center pt-2">
              <v-pagination
                v-model="currentPage"
                class="w-full"
                :length="props.pagination.total_pages"
                theme="primary"
                :total-visible="6"
                variant="text"
                @update:model-value="fetchPage"
              />
            </div>
          </template>
        </v-data-table>

        <p
          v-else-if="tableData.length === 0"
          class="text-center w-full text-xl font-semibold my-8"
        >
          No participant found !
        </p>
      </div>
      <div v-else class="flex justify-center h-[30vh] items-center w-full">
        <svn-loader loading-size="lg" />
      </div>
    </v-card-item>
  </v-card>
</template>

<script setup>
import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import CampaignStatusChip from "../campaign/CampaignStatusChip.vue";
import { usePopUpStore } from "@/store/pop-up";
import axios from "@/tools/axios-service.js";
import i18n from "@/plugins/i18n";
import { useCampaignStore } from "@/store/campaign.js";
import CampaignShowSearch from "@/views/interviews/campaigns/CampaignShowSearch.vue";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";

const { fetchCampaign, setParams, updateParams, fetchCampaignSurvey } = useCampaignStore();
const {openPopUp, closePopUp} = usePopUpStore()
const route = useRoute();
const router = useRouter()
const { addNode, update } = useBreadcrumbsStore()
const emit = defineEmits(["fetch-page", "row-click"]);

const props = defineProps({
  headers: { type: Array, default: null },
  tableData: { type: Array, default: null },
  pagination: { type: Object, default: null },
  anonymous: {type: Boolean, default: true},
  deadline: {type: String, default: ""},
  campaignId: {type: Number, default: null, required: true},
  campaign: { type: Object, default: null },
  overview: { type: String, default: null },
  campaignOverview: { type: Object, required: true },
  isTrashed: { type: Boolean, default: true },
  set: { type: Array, required: true },
});

const currentPage = ref(props.pagination.current_page)

const removeFromCampaign = async (employeeId) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t(`Are you sure you want to remove this participant ?<br/>(This is a permanent action)`),
    textClose: "No",
    textConfirm: "Yes, delete",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: "red",
    close() {},
    async confirm() {
      try {
        await axios.delete(
          `/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/delete_sets.json`,
          {
            params: {
              user_id: employeeId,
            },
          }
        );
      } finally {
        closePopUp();
        fetchPage();
      }
    },
  });
};

const items = ref(25)

const fetchPage = (page) => {
  emit("fetch-page", page);
};

const openAddParticipant = () => {
  const cId = props.campaignId || route.params.id;
  openPopUp({
    componentName: "pop-up-set-another-participant",
    title: i18n.global.t('Add participants'),
    set: props.set,
    campaignId: cId,
    icon: 'ic-baseline-person-add',
    customClass: "lg:w-[560px] !justify-normal",
    callback: () => {
      fetchCampaign(cId);
      fetchCampaignSurvey(props.campaignId || route.params.id);
    },
    close() {},
  });
};

const sendReminderToAll = async () => {
  try {
    await axios.get(
        `/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email.json`,
      {
        params: {
          email_type: "remind",
          format: "json",
        },
      }
    );

    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Reminder is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-bell',
      appName: "Interviews",
      confirm() {
        closePopUp();
      },
    });
  } catch (e) {}
};
const sendInvitationToAll = async () => {
  try {
    await axios.get(
        `/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email.json`,
      {
        params: {
          email_type: "invite",
          format: "json",
        },
      }
    );

    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Invitation is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-incoming-envelope',
      appName: "Interviews",
      confirm() {
        closePopUp();
      },
    });
  } catch (e) {
    console.log(e)
  }
};
const sendNotif = async (emailType, employeeId) => {
  try {
    await axios.get(
      `/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email`,
      {
        params: {
          email_type: emailType,
          user_id: employeeId,
        },
      }
    );

    if (emailType === "invite") {
      openPopUp({
        componentName: "pop-up-action-done",
        title: i18n.global.t(`Invitation is on its way !`),
        description: "The employee will receive an email in a few moments.",
        textConfirm: "Great !",
        icon: 'noto-incoming-envelope',
        appName: "Interviews",
        confirm() {
          closePopUp();
        },
      });
    } else {
      openPopUp({
        componentName: "pop-up-action-done",
        title: i18n.global.t(`Reminder is on its way !`),
        description: "The employee will receive an email in a few moments.",
        textConfirm: "Great !",
        icon: 'noto-bell',
        appName: "Interviews",
        confirm() {
          closePopUp();
        },
      });
    }
  } catch (e) {
    console.log(e)
  }
};

const fetchFromCampaignShowSearch = (event, campaignId) => {
  setParams(event);
  updateParams({ tags: [] });
  fetchCampaignSurvey(campaignId);
};

const goToProfile = async (fullname, params) => {
  addNode(
    i18n.global.t("See 's profile", {reviewers: `${fullname}`}),
    `/people/user_show/${params.params.id}`
  )
  update()
  await router.push({ name: "people_show", ...params, query: { page: 'interviews' }})
}
</script>
