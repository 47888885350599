<template>
  <div
    v-if="!showResults"
    class="p-4 flex items-center gap-4 self-stretch rounded-[8px] cursor-pointer"
    :class="isOptionSelected? 'border border-trainingOrange bg-trainingOrangeSoft' : 'border border-darkGrey bg-white'"
    @click="toggleProposition"
  >
    <!-- Proposition Letter -->
    <div
      class="flex flex-col justify-center items-center gap-2 p-2 rounded-[8px] w-[27px] h-[27px]"
      :class="isOptionSelected? 'bg-trainingOrange' : 'bg-veryLightGrey'"
    >
      <p
        class="text-base font-normal"
        :class="isOptionSelected? 'text-trainingOrangeSoft' : 'text-fakeBlack'"
      >
        {{ getCapitalLetter }}
      </p>
    </div>

    <!-- Proposition Value -->
    <p
      class="text-fakeBlack text-base font-normal"
      :class="isOptionSelected? 'text-trainingOrange' : 'text-fakeBlack'"
    >
      {{ proposition?.proposal }}
    </p>
  </div>

  <div
    v-else
    class="p-4 flex items-center gap-4 self-stretch rounded-[8px] cursor-pointer"
    :class="wasAnswerChosen ? getBorderAndBgColorByStatus(status) : 'border border-darkGrey bg-white'"
  >
    <!-- Proposition Letter -->
    <div
      class="flex flex-col justify-center items-center gap-2 p-2 rounded-[8px] w-[27px] h-[27px]"
      :class="wasAnswerChosen ? getBgColorByStatus(status) : 'bg-veryLightGrey'"
    >
      <p
        class="text-base font-normal"
        :class="wasAnswerChosen ? 'text-trainingOrangeSoft' : 'text-fakeBlack'"
      >
        {{ getCapitalLetter }}
      </p>
    </div>

    <!-- Proposition Value -->
    <p
      class="text-fakeBlack text-base font-normal"
      :class="wasAnswerChosen ? getPropositionTextColorByStatus(status) : 'text-fakeBlack'"
    >
      {{ proposition?.proposal }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  proposition: { type: Object, required: true },
  showResults: { type: Boolean, default: false },
  status: { type: String, default: null },
  selectedValues: { type: Object, default: null },
  index: { type: Number, default: null },
})

const emit = defineEmits(['toggle-proposition', 'update'])

const wasAnswerChosen = computed(() => {
  if (isOptionSelected.value) {
    return true
  }
  else {
    return false
  }
})

const isOptionSelected = computed(() => {
  return props.selectedValues && props.selectedValues?.includes(props.proposition?.proposal)
})

const toggleProposition = () => {
  emit('toggle-proposition')
  emit('update')
}

const getCapitalLetter = computed(() => {
  if (props.index >= 0 && props.index < 26) {
    return String.fromCharCode(65 + props.index)
  } else {
    return 'Invalid index'
  }
})

const getBorderAndBgColorByStatus = () => {
  if (props.status === 'correct') {
    return 'bg-[#E7F4E7] border !border-[#7BC079]'
  }
  else if (props.status === 'ambiguous') {
    return 'bg-[#FFFDEA] border !border-[#FFC85C]'
  }
  else if (props.status === 'wrong') {
    return 'bg-[#FFECEF] border !border-[#FF5656]'
  }
}

const getBgColorByStatus = () => {
  if (props.status === 'correct') {
    return 'bg-positiveGreen'
  }
  else if (props.status === 'ambiguous') {
    return 'bg-mediumOrange'
  }
  else if (props.status === 'wrong') {
    return 'bg-negativeRed'
  }
}

const getPropositionTextColorByStatus = () => {
  if (props.status === 'correct') {
    return 'text-positiveGreen'
  }
  else if (props.status === 'ambiguous') {
    return 'text-mediumOrange'
  }
  else if (props.status === 'wrong') {
    return 'text-negativeRed'
  }
}
</script>