<template>
  <div class="flex flex-col sm:flex-row items-center !w-full">
    <v-text-field
      v-model="searchText"
      color="primary"
      variant="outlined"
      class="text-darkGrey"
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify"
      :label="$t('Search')"
      density="comfortable"
      @update:model-value="updateText"
    />

    <div class="ml-3">
      <bkt-learn-list-settings
        :content-values="settingsOnAllItems"
      />
    </div>

    <!-- Modal Deleted Items to CUSTOMIZE -->
    <modal-view-deleted-items
      ref="deletedItems"
      content-type="target"
      :has-user="true"
      :contents="trashesTargets"
      @toggle-search="emit('search-items', $event)"
      @restore="emit('restore', $event)"
      @delete-permanently="emit('delete-permanently', $event)"
      @view-item="router.push({ name: 'roadmaps_targets_show', params: { id: $event}, query: { from: 'my_team_roadmaps' } })"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { useRoadmapEmployeeStore } from "@/store/roadmap-employee";
import i18n from '@/plugins/i18n';
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import {useRouter} from "vue-router";

const router = useRouter();
const { params } = storeToRefs(useRoadmapEmployeeStore());


const props = defineProps({
  trashesTargets: { type: Object, required: true }
})

const emit = defineEmits(["fetch", "search-items", "restore", "delete-permanently"]);

const searchText = ref(params.value.title);
const deletedItems = ref(null)

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", {
    title: searchText.value,
  });
}, 300)

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted targets'),
    onClick: openDeletedItems
  }
])
</script>
