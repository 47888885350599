<template>
  <div
    class="flex flex-col items-start justify-end items-start min-w-[300px] sm:w-full h-[338px]
    rounded-[12px] border border-darkGrey
    hover:shadow-[0_1px_5px_rgba(0,0,0,0.20)] overflow-hidden cursor-pointer"
  >
    <div
      class="flex justify-center items-center h-[160px] bg-cover w-full"
      :style="{'background-image': `url('${backgroundImage}')`} "
    />

    <div class="w-full flex flex-col items-start h-[178px] p-4 gap-y-4">
      <!-- Training Infos -->
      <div class="flex flex-col items-start gap-y-2 min-h-[90px]">
        <!-- Training title -->
        <p class="text-fakeBlack text-sm font-medium max-h-[60px]">
          {{ title }}
        </p>

        <!-- If there is 1 or less themes -->
        <div
          v-if="themeList?.length !== 0 && themeList?.length === 1"
          class="flex items-center gap-x-2"
        >
          <div
            class="flex h-[22px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              {{ themeList?.[0] }}
            </p>
          </div>
        </div>

        <!-- if there is more than 1 theme -->
        <div
          v-if="themeList?.length !== 0 && themeList?.length > 1"
          class="flex items-center gap-x-2"
        >
          <div
            v-for="(theme) in slicedThemesList"
            :key="theme"
            class="flex h-[22px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              {{ theme }}
            </p>
          </div>

          <div
            v-if="(themeList?.length - slicedThemesList?.length) > 0"
            class="flex h-[22px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              +{{ themeList?.length - slicedThemesList?.length }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex items-center self-stretch">
        <div class="flex flex-col justify-center items-start gap-y-1 flex-1">
          <div class="flex items-start self-stretch">
            <div class="flex items-center gap-x-1">
              <Icon
                icon="mdi:clock-outline"
                height="12"
                width="12"
                class="text-darkGrey"
              />

              <p class="text-darkGrey text-xs font-normal">
                {{ duration }}
              </p>
            </div>
          </div>

          <div class="flex items-center self-stretch gap-x-2">
            <div class="w-full flex-1">
              <v-progress-linear
                :model-value="((completedTrainings * 100) / totalTrainings)"
                :color="completedTrainings === totalTrainings ? 'positiveGreen' : 'trainingOrange'"
                bg-color="lightgrey"
                :height="8"
                rounded
                :rounded-bar="true"
              />
            </div>

            <div class="flex flex-row">
              <p class="text-darkGrey text-sm font-medium">
                {{ completedTrainings }}
              </p>

              <p class="text-darkGrey text-sm font-medium">
                /
              </p>

              <p class="text-darkGrey text-sm font-medium">
                {{ totalTrainings }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const props = defineProps({
  id: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  title: { type: String, default: '' },
  themeList: { type: Array, default: null },
  duration: { type: String, default: '' },
  completedTrainings: { type: Number, default: null },
  totalTrainings: { type: Number, default: null },
})

const { isMobile } = storeToRefs(useMobileStore())

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props?.themeList?.slice(0, 1)
  }
  else {
    return props?.themeList?.slice(0, 2)
  }
})
</script>

<style scoped>
.borderCard {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>