<template>
  <div
    v-if="campaign"
    class="overflow-auto"
  >
    <index-table
      v-if="campaign.campaign_type === CampaignTypeBackend.FEEDBACK_360"
      :headers="feedback_headers"
      :table-data="set"
      :pagination="pagination"
      class="cursor-pointer"
      @fetch-page="customFetchCamp"
      @row-click="
        $router.push({ name: 'feedback_answer', params: { id: $event.id } })
      "
    >
      <template
        #default="{
          id,
          participant,
          person_in_charge,
          campaign,
          interview_form,
          interviews,
          employee_interview,
          manager_interview,
          all_interview_count,
          answer_count
        }"
      >
        <td>
          <v-list-item>
            <template #prepend>
              <svn-avatar
                :avatar="participant?.avatar?.['30']"
                :firstname="participant?.firstname"
                :lastname="participant?.lastname"
                size="md"
                class="mr-2"
              />
            </template>

            <v-list-item-title>
              {{ participant.fullname }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ participant.job_title }}
            </v-list-item-subtitle>
          </v-list-item>
        </td>

        <td>
          <p
            class="max-w-20rem font-weight-500 bkt-light-grey6 overflow-ellipsis"
            :title="interview_form?.title"
          >
            {{ interview_form?.title }}
          </p>
        </td>

        <td>
          <user-info-in-table :user="person_in_charge" />
        </td>

        <td v-if="interview_form?.categories">
          <display-tag-in-index
            class="mr-3"
            :tags="interview_form?.categories"
          />
        </td>
        <td>
          <div
            v-if="all_interview_count > 0"
            :class="
              answer_count === 0
                ? 'bg-red-400'
                : answer_count === all_interview_count
                  ? 'bg-green-400'
                  : 'bg-yellow-500'
            "
            class="w-20 font-medium text-white p-1 text-center rounded-md"
          >
            {{ answer_count }} / {{ all_interview_count }}
          </div>

          <div v-else>
            {{ $t("No reviewers selected") }}
          </div>
        </td>

        <td>
          <div class="flex justify-end items-center">
            <svn-dots-button
              table
              :show="!isTrashed"
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  v-if="participant?.company_id === person_in_charge?.company_id"
                  value="see_interviewee_profile"
                  @click="router.push({
                    name: 'people_show',
                    params: {id: participant.id},
                    query: {
                      campaignId: campaign?.campaign?.id,
                      campaignName: campaign?.campaign?.title,
                      page: 'interviews'
                    }
                  })"
                >
                  {{ $t('See interviewee profile') }}
                </v-list-item>

                <v-list-item
                  value="manage_reviewers"
                  @click="openPairsOverview(participant, id, interviews)"
                >
                  {{ $t('Manage Reviewers') }}
                </v-list-item>

                <v-list-item
                  value="set_another_person_in_charge"
                  @click="openSetAnotherInterviewer(participant?.id)"
                >
                  {{ $t('Set another person in charge') }}
                </v-list-item>

                <v-list-item
                  value="send_invitation_email"
                  @click="sendFeedbackNotif('invite', id)"
                >
                  {{ $t('Send invitation email') }}
                </v-list-item>

                <v-list-item
                  value="remove_participant"
                  class="text-negativeRed"
                  @click="removeParticipantFeedback(id)"
                >
                  {{ $t('Remove participant') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </td>
      </template>
    </index-table>

    <index-table
      v-else-if="campaign.campaign_type === CampaignTypeBackend.ONE_TO_ONE"
      :headers="headers"
      :table-data="set"
      :pagination="pagination"
      class="cursor-pointer"
      @fetch-page="customFetchCamp"
      @row-click="rowClick"
    >
      <template
        #default="{ manager_interview, employee_interview, crossed_interview, employee, interview_form, interviewer }"
      >
        <td>
          <user-info-in-table
            :show-interview="null"
            :user="employee"
          />
        </td>

        <td>
          <p
            class="max-w-20rem font-weight-500 bkt-light-grey6 overflow-ellipsis"
            :title="
              interview_form.title
            "
          >
            {{
              interview_form.title
            }}
          </p>
        </td>

        <td>
          <user-info-in-table
            v-if="interviewer"
            :user="
              interviewer
            "
          />
        </td>

        <td>
          <interview-status
            :set-interview="{
              manager_interview,
              employee_interview,
              crossed_interview,
            }"
          />
        </td>

        <td>
          <div class="flex justify-end items-center">
            <svn-dots-button
              table
              circle
              :show="!isTrashed"
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  v-if="employee.company_id ===
                    interviewer?.company_id"
                  value="see_interviewee_profile"
                  @click="goToProfile(employee?.fullname,
                                      {
                                        params: {id: employee?.id},
                                      })"
                >
                  {{ $t('See interviewee profile') }}
                </v-list-item>

                <v-list-item
                  value="set_another_interviewer"
                  @click="openSetAnotherInterviewer(employee?.id)"
                >
                  {{ $t('Set another interviewer') }}
                </v-list-item>

                <v-list-item
                  value="send_invitation_email"
                  @click="sendNotif('invite', employee?.id)"
                >
                  {{ $t('Send invitation email') }}
                </v-list-item>

                <v-list-item
                  value="send_reminder_email"
                  @click="sendNotif('remind', employee?.id)"
                >
                  {{ $t('Send reminder email') }}
                </v-list-item>

                <v-list-item
                  value="schedule_meeting"
                  @click="shiftCrossInterviewDate(crossed_interview)"
                >
                  {{ $t('Schedule a meeting') }}
                </v-list-item>

                <v-list-item
                  v-if="employee_interview &&
                    employee_interview?.status === 'submitted' &&
                    overview === 'overview'"
                  value="unlock_one"
                  :disabled="crossed_interview !== undefined &&
                    crossed_interview?.status === 'submitted'"
                  @click="unlockInterview(employee_interview?.id, 'Interviewee')"
                >
                  {{ $t(
                    `Unlock ${setUnlockText(employee_interview)[0]} review (${setUnlockText(employee_interview)[1]})`
                  ) }}
                </v-list-item>

                <v-list-item
                  v-if="(currentUser?.accessLevel.value === 'admin' || currentUser?.accessLevel.value === 'super_admin')
                    && employee_interview && employee_interview?.status !== 'submitted' &&
                    overview === 'overview'"
                  value="lock_one"
                  :disabled="crossed_interview !== undefined &&
                    crossed_interview?.status === 'submitted'"
                  @click="lockInterview(employee_interview?.id, 'Interviewer')"
                >
                  {{ $t(
                    `Submit ${setLockText(employee_interview)[0]} review (${setLockText(employee_interview)[1]})`
                  ) }}
                </v-list-item>

                <v-list-item
                  v-if="manager_interview &&
                    manager_interview?.status === 'submitted' &&
                    overview === 'overview'"
                  value="unlock_two"
                  :disabled="crossed_interview !== undefined &&
                    crossed_interview?.status === 'submitted'"
                  @click="unlockInterview(manager_interview?.id, 'Interviewer')"
                >
                  {{ $t(
                    `Unlock ${setUnlockText(manager_interview)[0]} review (${setUnlockText(manager_interview)[1]})`
                  ) }}
                </v-list-item>

                <v-list-item
                  v-if="(currentUser?.accessLevel.value === 'admin' || currentUser?.accessLevel.value === 'super_admin')
                    && manager_interview && manager_interview?.status !== 'submitted' &&
                    overview === 'overview'"
                  value="lock_two"
                  :disabled="crossed_interview !== undefined &&
                    crossed_interview?.status === 'submitted'"
                  @click="lockInterview(manager_interview?.id, 'Interviewer')"
                >
                  {{ $t(
                    `Submit ${setLockText(manager_interview)[0]} review (${setLockText(manager_interview)[1]})`
                  ) }}
                </v-list-item>

                <v-list-item
                  v-if="crossed_interview &&
                    crossed_interview?.status === 'submitted' &&
                    overview === 'overview'"
                  value="unlock_cross"
                  @click="unlockInterview(crossed_interview?.id, 'Cross')"
                >
                  {{ $t('Unlock Cross Review (CR)') }}
                </v-list-item>

                <v-list-item
                  v-if="(currentUser?.accessLevel.value === 'admin' || currentUser?.accessLevel.value === 'super_admin')
                    && crossed_interview && crossed_interview?.status !== 'submitted' && crossed_interview?.status !== 'not_available_yet' &&
                    employee_interview && employee_interview?.status === 'submitted' &&
                    manager_interview && manager_interview?.status === 'submitted' &&
                    overview === 'overview'"
                  value="lock_cross"
                  @click="lockInterview(crossed_interview?.id, 'Cross')"
                >
                  {{ $t('Submit Cross Review (CR)') }}
                </v-list-item>

                <v-list-item
                  v-if="overview === 'overview'"
                  value="remove_from_campaign"
                  class="text-negativeRed"
                  @click="removeFromCampaign(employee)"
                >
                  {{ $t('Remove from campaign') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </td>
      </template>
    </index-table>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import IndexTable from "../../../components/IndexTable.vue";
import DisplayTagInIndex from "../../../components/DisplayTagInIndex.vue";
import InterviewStatus from "../../../components/interviewApp/InterviewStatus.vue";
import UserInfoInTable from "../../../components/UserInfoInTable.vue";
import { useCampaignStore } from "@/store/campaign.js";
import { useUserStore } from "@/store/user";
import {useSnackbar} from "@/store/snackbar.js";
import { usePopUpStore } from "@/store/pop-up";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import useTools from "@/tools/useTools.js";
import { useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import { useI18n } from "vue-i18n";
import { storeToRefs} from "pinia";
import i18n from "@/plugins/i18n";
import { useFeedbackStore } from "@/store/feedback.js";

const snackbar = useSnackbar();

const currentUser = storeToRefs(useUserStore());
const { addNode, update } = useBreadcrumbsStore()

const { campaign } = storeToRefs(useCampaignStore());
const { updateParams,
        fetchCampaign,
        fetchCampaignInterview,
        fetchCampaignFeedback } = useCampaignStore();

const {deleteFeedback} = useFeedbackStore();

const { fetchPage } = useTools();
const router = useRouter();
const {openPopUp, closePopUp} = usePopUpStore()
const { t } = useI18n();
const props = defineProps({
  overview: { type: String, default: null },
  campaign: { type: Object, default: null },
  currentUserId: { type: String, default: null },
  fetch: { type: Function, default: null },
  setParams: { type: Function, default: null },
  campaignOverview: { type: Object, required: true },
  set: { type: Array, required: true },
  pagination: { type: Object, required: true} ,
  isTrashed: { type: Boolean, default: true }
});
const headers = reactive([
  "Interviewee",
  "Template",
  "Interviewer",
  "Completion",
  "",
]);
const feedback_headers = reactive([
  "Participant",
  "Template",
  "Person in charge",
  "Answers",
  "",
]);

const setUnlockOptionColor = (interview, crossed_interview) => {
  if (
    crossed_interview !== undefined &&
    crossed_interview?.status === "submitted"
  ) {
    return "bkt-light-grey5";
  } else {
    return "";
  }
};

const setUnlockText = (interview) => {
  if (interview?.label === "Employee") {
    return ["Interviewee", "IE"];
  } else {
    return ["Interviewer", "IR"];
  }
};

const setLockText = (interview) => {
  if (interview?.label === "Employee") {
    return ["Interviewee", "IE"];
  } else {
    return ["Interviewer", "IR"];
  }
}

const openSetAnotherInterviewer = (employeeId) => {
  openPopUp({
    componentName: "pop-up-set-another-interviewer",
    title: campaign?.campaign_type === 'OneToOne'
        ? i18n.global.t('Select another interviewer')
        : i18n.global.t('Select another person in charge'),
    campaignId: props.campaign.id,
    icon: 'ic-baseline-person',
    employeeId: employeeId,
    customClass: "",
    showClose: true,
    callback: () => {
      fetchCampaign(props.campaign.id);
      fetchCurrentCampaign();
    },
    close() {},
  });
};

const openPairsOverview = (participant, feedbackId, feedbackInterviews) => {
  openPopUp({
    componentName: "PopUpManagePairs",
    title: i18n.global.t('Manage reviewers'),
    participantId: participant.id,
    feedbackId: feedbackId,
    campaignId: props.campaign.id,
    icon: '',
    feedbackInterviews: feedbackInterviews,
    customClass: "h-[573px] lg:w-[800px] !justify-normal",
    showClose: true,
    async close() {
      await fetchCampaignFeedback(props.campaign.id)
    },
  });
};

const sendFeedbackNotif = (emailType, feedbackId) => {
  try {
    axiosService.get(
      `/api/v2/interview_app/campaigns/${props.campaign.id}/send_notification_email`,
      {
        params: {
          email_type: emailType,
          feedback_id: feedbackId,
        },
      }
    );
  } catch (e) {
    console.log(e)
  }

  if (emailType === "invite") {
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Invitation is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-incoming-envelope',
      appName: "Interviews",
      customClass: "",
      showClose: true,
      confirm() {
        closePopUp();
      },
    });
  } else {
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Reminder is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-bell',
      appName: "Interviews",
      customClass: "",
      showClose: true,
      confirm() {
        closePopUp();
      },
    });
  }
};

const sendNotif = (emailType, employeeId) => {
  axiosService.get(
    `/api/v2/interview_app/campaigns/${props.campaign.id}/send_notification_email`,
    {
      params: {
        email_type: emailType,
        user_id: employeeId,
      },
    }
  );

  if (emailType === "invite") {
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Invitation is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-incoming-envelope',
      appName: "Interviews",
      customClass: "",
      showClose: true,
      confirm() {
        closePopUp();
      },
    });
  } else {
    openPopUp({
      componentName: "pop-up-action-done",
      title: i18n.global.t(`Reminder is on its way !`),
      description: "The employee will receive an email in a few moments.",
      textConfirm: "Great !",
      icon: 'noto-bell',
      appName: "Interviews",
      customClass: "",
      showClose: true,
      confirm() {
        closePopUp();
      },
    });
  }
};

const shiftCrossInterviewDate = (cross) => {
  openPopUp({
    componentName: "pop-up-shift-cross-interview-date",
    title: i18n.global.t('Schedule a meeting'),
    campaignId: cross.campaign_id,
    crossId: cross.id,
    startDate: cross.starts_at,
    endDate: cross.ends_at,
    icon: 'material-symbols:today',
    date: cross.date,
    customClass: "",
    showClose: true,
    callback: () => {
      fetchPage(
        updateParams,
        fetchCampaign(props.campaign.id),
        pagination.value.current_page
      );
    },
    close() {},
  });
};

const removeFromCampaign = async (employee) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t(`Are you sure you want to remove this participant ?<br/>(This is a permanent action)`),
    textClose: "No",
    textConfirm: "Yes, delete",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: "red",
    customClass: "",
    showClose: true,
    close() {},
    async confirm() {
      try {
        await axiosService.delete(
          `/api/v2/interview_app/campaigns/${props.campaign.id}/interview_sets/delete_sets.json`,
          { data: { user_id: employee.id } }
        );
      } catch (e) {
      } finally {
        closePopUp();
        fetchPage(
          updateParams,
          fetchCurrentCampaign(),
          pagination.value.current_page
        );
      }
    },
  });
};

const removeParticipantFeedback = async (id) => {
  await deleteFeedback(id, props.campaign.id)
}

const rowClick = (row) => {
  const setInterview = JSON.parse(JSON.stringify(row));
  openPopUp({
    componentName: "pop-up-set-interview",
    overview: props.overview,
    managerInterview: setInterview.manager_interview,
    crossedInterview: setInterview.crossed_interview,
    employeeInterview: setInterview.employee_interview,
    employee: setInterview.employee,
    interviewer: setInterview.interviewer,
    currentUserId: props.currentUserId,
    icon: '',
    customClass: "",
    showClose: true,
    callback: () => {
      fetchPage(
        updateParams,
        fetchCampaign(props.campaign.id),
        pagination.value.current_page
      );
    },
    close() {},
  });
};

const unlockInterview = async (id, role) => {
  try {
    document.querySelector("body").classList.add("wait");
    await axiosService.put(`/api/v2/interview_app/interviews/${id}/unlock.json`).then(() => {
      snackbar.setMsg(t('Review has been unlocked.', {role: role}));
      snackbar.setBgColor('fakeBlack')
      snackbar.setTextColor('veryLightGrey')
      snackbar.displaySnackBar()
    });
  } catch (e) {
  } finally {
    document.querySelector("body").classList.remove("wait");
    fetchPage(
      updateParams,
      fetchCurrentCampaign(),
      pagination.value.current_page
    );
  }
};

const lockInterview = async (id, role) => {
  try {
    document.querySelector("body").classList.add("wait");
    await axiosService.put(`/api/v2/interview_app/interviews/${id}/lock.json`).then(() => {
      snackbar.setMsg(t('Review has been submitted.', {role: role}));
      snackbar.setBgColor('fakeBlack')
      snackbar.setTextColor('veryLightGrey')
      snackbar.displaySnackBar()
    });
  } catch (e) {
  } finally {
    document.querySelector("body").classList.remove("wait");
    fetchPage(
      updateParams,
      fetchCurrentCampaign(),
      pagination.value.current_page
    );
  }
};

const customFetchCamp = (page) => {
  updateParams({
    "page[number]": page,
  });
  fetchCurrentCampaign();
}

const fetchCurrentCampaign = async () => {
  switch (props.campaign?.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      await fetchCampaignInterview(props.campaign.id)
      return
    case CampaignTypeBackend.FEEDBACK_360:
      await fetchCampaignFeedback(props.campaign.id)
      return
    default:
      return
  }
}

const goToProfile = async (fullname, params) => {
  addNode(
    i18n.global.t("See 's profile", {reviewers: `${fullname}`}),
    `/people/user_show/${params.params.id}`
  )
  update()
  await router.push({ name: "people_show", ...params, query: { page: "interviews" } })
}

</script>

<style scoped>
.masthead-table table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  vertical-align: middle;
}

td {
  padding-left: 16px !important;
}
</style>
