<template>
  <div class="w-full flex flex-col items-start p-4 gap-4 self-stretch">
    <!-- Chip is the comment has a question or remark associated with it -->
    <div
      v-if="comment?.reaction_type === 'remark' || comment?.reaction_type === 'question'"
      class="flex items-center gap-1 px-2 py-[6px] border-[0.5px] border-middleGrey rounded-[8px]"
    >
      <div>
        <icon
          :icon="commentIcon(comment?.reaction_type)"
          height="16"
          width="16"
        />
      </div>

      <p class="text-darkGrey text-xs font-normal">
        {{ comment?.reaction_type }}
      </p>
    </div>

    <!-- Main comment section -->
    <div class="flex flex-col items-start gap-2 self-stretch">
      <!-- Comment user infos -->
      <div class="w-full flex items-start gap-2 self-stretch relative">
        <!-- Comment user avatar -->
        <svn-avatar
          :avatar="comment?.user?.avatar?.['50']"
          :firstname="comment?.user?.firstname"
          :lastname="comment?.user?.lastname"
          size="lg"
        />

        <!-- User & publish date -->
        <div class="flex flex-col items-start">
          <!-- Comment user fullname -->
          <div class="w-full flex items-center gap-2">
            <div class="max-w-[30%] sm:max-w-[50%]">
              <p class="text-fakeBlack text-base font-medium truncate overflow-ellipsis">
                {{ comment?.user?.fullname }}
              </p>
            </div>

            <!-- Comment user is the module author -->
            <div
              v-if="comment?.user?.module_creator"
              class="flex justify-center items-center gap-1 rounded-[8px]
                border-[0.5px] border-middleGrey px-2 py-1 mr-1"
            >
              <div>
                <Icon
                  icon="mdi-pencil-outline"
                  height="16"
                  width="16"
                  class="text-darkGrey"
                />
              </div>

              <p class="text-darkGrey text-xs font-normal">
                {{ $t('Module author') }}
              </p>
            </div>
          </div>

          <!-- Comment published date -->
          <p class="text-darkGrey text-xs font-normal line-clamp-1 w-[90%]">
            {{ moment(comment?.created_at, "YYYY-MM-DD HH:mm:ss [UTC]").format("dddd Do MMMM, h:mm a") }}
          </p>
        </div>

        <!-- Options button -->
        <div
          v-if="amICreatorOfEntityOrAdmin(learnModule.creator_id) || comment?.user?.id === id"
          class="absolute top-0 right-0 rotate-90"
        >
          <svn-dots-button
            circle
            button-size="sm"
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="delete_comment"
                class="text-negativeRed"
                @click="deleteComment(comment)"
              >
                {{ $t('Delete comment') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>

      <!-- Actual comment -->
      <p class="text-fakeBlack text-sm font-normal">
        {{ comment?.text }}
      </p>

      <!-- Answer button -->
      <v-btn
        variant="plain"
        prepend-icon="mdi-message-outline"
        :text="$t('Answer')"
        class="normal-case text-xs text-darkGrey font-medium"
        @click="inputThreadVisible = !inputThreadVisible"
      />

      <!-- Input section -->
      <div
        v-if="!comment?.replies?.length"
        class="w-full bg-white h-fit p-0 flex gap-2 items-center"
        :class="`${inputThreadVisible ? 'block' : 'hidden'}`"
      >
        <!-- User avatar image -->
        <svn-avatar
          :avatar="avatar"
          :firstname="firstname"
          :lastname="lastname"
          size="lg"
        />

        <!-- Text area to add comment -->
        <v-textarea
          v-model="userComment"
          hide-details
          density="compact"
          variant="outlined"
          class="flex-1"
          :placeholder="$t('Leave a comment')"
          :auto-grow="false"
          :no-resize="true"
          :rows="1"
        />

        <!-- Submit comment button -->
        <v-btn
          icon="mdi-send"
          color="primary"
          variant="plain"
          :height="48"
          :width="48"
          :disabled="!userComment?.length"
          @click="addCommentReply"
        />
      </div>
    </div>

    <!-- Threads -->
    <div
      v-for="(element, index) in comment?.replies"
      :key="index"
      class="w-full flex flex-col items-start pl-4 py-0 gap-4 self-stretch border-l border-l-middleGrey"
    >
      <!-- Chip is the comment has a question or remark associated with it -->
      <div
        v-if="element?.reaction_type === 'remark' || element?.reaction_type === 'question'"
        class="flex items-center gap-1 px-2 py-[6px] border-[0.5px] border-middleGrey rounded-[8px]"
      >
        <div>
          <icon
            :icon="commentIcon(comment?.reaction_type)"
            height="16"
            width="16"
          />
        </div>

        <p class="text-darkGrey text-xs font-normal">
          {{ element?.reaction_type }}
        </p>
      </div>

      <!-- Main comment section -->
      <div class="flex flex-col items-start gap-2 self-stretch">
        <!-- Comment user infos -->
        <div class="flex items-start gap-2 self-stretch relative">
          <!-- Comment user avatar -->
          <svn-avatar
            :avatar="element?.user?.avatar?.['50']"
            :firstname="element?.user?.firstname"
            :lastname="element?.user?.lastname"
            size="lg"
          />

          <!-- User & publish date -->
          <div class="w-full flex flex-col items-start">
            <!-- Comment user fullname -->
            <div class="w-full flex items-center gap-2">
              <div class="max-w-[30%] sm:max-w-[50%]">
                <p class="text-fakeBlack text-base font-medium truncate overflow-ellipsis">
                  {{ element?.user?.fullname }}
                </p>
              </div>

              <!-- Comment user is the module author -->
              <div
                v-if="element?.user?.module_creator"
                class="flex justify-center items-center gap-1 rounded-[8px]
                border-[0.5px] border-middleGrey px-2 py-1 mr-1"
              >
                <div>
                  <Icon
                    icon="mdi-pencil-outline"
                    height="16"
                    width="16"
                    class="text-darkGrey"
                  />
                </div>

                <p class="text-darkGrey text-xs font-normal">
                  {{ $t('Module author') }}
                </p>
              </div>
            </div>

            <!-- Comment published date -->
            <p class="text-darkGrey text-xs font-normal line-clamp-1 w-[90%]">
              {{ moment(element?.created_at, "YYYY-MM-DD HH:mm:ss [UTC]").format("dddd Do MMMM, h:mm a") }}
            </p>
          </div>

          <!-- Options button -->
          <div
            v-if="(isManagerCreatorOrAbove() || element?.user?.id === id)"
            class="absolute top-0 right-0 rotate-90"
          >
            <svn-dots-button
              circle
              button-size="sm"
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  value="delete_comment"
                  class="text-negativeRed"
                  @click="deleteComment(element)"
                >
                  {{ $t('Delete comment') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </div>

        <!-- Actual comment -->
        <p class="text-fakeBlack text-sm font-normal">
          {{ element?.text }}
        </p>

        <!-- Answer button -->
        <v-btn
          variant="plain"
          prepend-icon="mdi-message-outline"
          :text="$t('Answer')"
          class="normal-case text-xs text-darkGrey font-medium"
          @click="inputThreadVisible = !inputThreadVisible"
        />
      </div>
    </div>

    <!-- Input section -->
    <div
      v-if="comment?.replies?.length"
      class="w-full bg-white h-fit pl-8 pr-6 flex gap-2 items-center"
      :class="`${inputThreadVisible ? 'block' : 'hidden'}`"
    >
      <!-- User avatar image -->
      <svn-avatar
        :avatar="avatar"
        :firstname="firstname"
        :lastname="lastname"
        size="lg"
      />

      <!-- Text area to add comment -->
      <v-textarea
        v-model="userComment"
        hide-details
        density="compact"
        variant="outlined"
        class="flex-1"
        :placeholder="$t('Leave a comment')"
        :auto-grow="false"
        :no-resize="true"
        :rows="1"
      />

      <!-- Submit comment button -->
      <v-btn
        icon="mdi-send"
        color="primary"
        variant="plain"
        :height="48"
        :width="48"
        :disabled="!userComment?.length"
        @click="addCommentReply"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import { useUserStore } from '@/store/user';
import { useLearnModuleStore } from '@/store/learn-module';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import { debounce } from 'lodash';
import { useSnackbar } from '@/store/snackbar';

const props = defineProps({
  comment: { type: Object, required: true },
})

const emit = defineEmits(['delete-comment'])

const inputThreadVisible = ref(false)

const userComment = ref(null)

const snackbar = useSnackbar()

const { addReaction } = useLearnModuleStore()

const { isManagerCreatorOrAbove, amICreatorOfEntityOrAdmin } = useUserStore()

const { id, avatar, firstname, lastname } = storeToRefs(useUserStore())
const {learnModule} = storeToRefs(useLearnModuleStore())

const addCommentReply = debounce(async() => {
  try {
    await addReaction(props?.comment?.messageable_id, '',
      userComment.value, props?.comment?.id)

    userComment.value = ''
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error adding comment')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[25px]')
    snackbar.displaySnackBar()
  }
}, 300)

const deleteComment = (comment) => {
  emit('delete-comment', comment)
}

const commentIcon = () => {
  if (props?.comment?.reaction_type === 'question') {
    return 'noto:thinking-face'
  }
  else if (props?.comment?.reaction_type === 'remark') {
    return 'noto:index-pointing-up'
  }
  else {
    return ''
  }
}
</script>
