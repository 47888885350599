<template>
  <modal-skeleton
    ref="modalImportUsers"
    :title="showResults ? 'Import details' : 'Import users'"
    :width="isImporting ? showResults ? 640 : 362 : 640"
    :height="isImporting ? showResults ? 600 : 464 : 600"
    title-center
    :persistent="isImporting && !importError && importProgress === 100"
    :display-close="(isImporting && !importError) ? false : true"
    :display-back="showResults"
    :primary-button-closes-modal="false"
    :secondary-button-closes-modal="false"
    :close-button-closes-modal="false"
    :prepend-icon="showResults ? 'mdi-arrow-left' : ''"
    :prepend-text="showResults ? 'Back' : ''"
    :buttons-class="isMobile ? 'flex flex-col-reverse !gap-3' : 'flex'"
    :secondary-button-class="isMobile ? 'w-full' : 'w-fit'"
    :display-secondary-button="(isImporting && importProgress === 100 && importError) ? false : true"
    :primary-button-class="isMobile ? 'w-full' : '!w-fit'"
    :secondary-button-text="(isImporting && importProgress === 100) ? 'See details' : 'Cancel'"
    :primary-button-text="(isImporting && importProgress === 100) ? 'Finish' : 'Apply changes and import file'"
    :action-buttons="((fileVerified && !errorNoData && !errorOtherData && !isImporting) ||
      (isImporting && importProgress === 100 && !importError && !showResults)) ? true : false"
    @prepend-click="backToDragNDrop()"
    @click-primary-button="isImporting ? closeModal() : startImporting()"
    @click-secondary-button="isImporting ? (showResults = true) : openConfirmExitDialog()"
    @close-dialog-and-action="openConfirmExitDialog"
  >
    <template #button>
      <slot name="button" />
    </template>

    <template #body-content>
      <!-- Import has started and no errors -->
      <div
        v-if="isImporting && !importError && !showResults"
        class="w-full h-full flex flex-col items-start flex-1 p-8"
      >
        <div class="flex flex-col items-center gap-12 sm:gap-8 flex-1 self-stretch">
          <div class="flex flex-col justify-center items-center gap-1">
            <!-- Circular Progress bar -->
            <div class="p-4 sm:p-[10px]">
              <svn-loader
                :model-value="inspectProgress"
                :size="150"
                :width="15"
                class="w-full text-fakeBlack font-medium"
                :class="isMobile ? 'text-[36px]' : 'text-base'"
              >
                <template #default>
                  {{ inspectProgress }}%
                </template>
              </svn-loader>
            </div>

            <!-- Import completed text -->
            <p class="text-fakeBlack text-base sm:text-sm font-medium">
              {{ importProgress === 100 ? $t('Import completed !') : $t('Importing...') }}
            </p>
          </div>

          <!-- List of modifications -->
          <div class="flex flex-col items-start self-stretch">
            <div
              v-for="(modif, index) in modifications"
              :key="index"
              class="flex items-center justify-center h-10"
            >
              <div
                class="flex w-10 h-10 justify-center items-center"
              >
                <Icon
                  :icon="modif?.progress ? 'icon-park-solid:check-one' : 'tabler:point-filled'"
                  height="20"
                  width="20"
                  class="transition-all duration-600"
                  :class="modif?.progress ? 'text-positiveGreen' : 'text-middleGrey'"
                />
              </div>

              <v-fade-transition>
                <p class="text-fakeBlack text-base font-medium transition ease-in-out duration-600">
                  {{ $t(modif?.type) }}{{ modif?.progress === 100 ? $t(' imported') : '...' }}
                </p>

                <!-- <div
                  v-if="modif?.progress"
                  class="flex w-10 h-10 justify-center items-center"
                >
                  <v-progress-circular
                    v-model="modif.progress"
                    :size="25"
                    color="positiveGreen"
                  />
                </div> -->
              </v-fade-transition>
            </div>
          </div>
        </div>
      </div>

      <!-- Import has started and there are errors -->
      <!-- <div
        v-else-if="isImporting && importError && !showResults"
        class="w-full h-full flex flex-col justify-center items-center flex-1 p-8"
      >
        <div class="flex flex-col justify-center items-center gap-4 sm:gap-0self-stretch">
          <div class="flex flex-col justify-center items-center gap-2 self-stretch">
            <div>
              <Icon
                icon="material-symbols:info"
                height="24"
                width="24"
                class="text-negativeRed"
              />
            </div>

            <p class="text-negativeRed text-base font-medium">
              {{ $t('Error during import') }}
            </p>

            <p class="text-fakeBlack text-sm text-center font-normal self-stretch">
              {{ $t('An error occured during import. Please try again.') }}
            </p>
          </div>

          <v-btn
            class="normal-case"
            variant="flat"
            :text="$t('Retry')"
            height="40"
            color="primary"
            @click="retryImport"
          />
        </div>
      </div> -->

      <!-- Import is done, show results -->
      <import-details
        v-else-if="isImporting && !importError && showResults"
        :details="importDetails"
      />

      <!-- Not in import state yet -->
      <div
        v-if="!isImporting"
        class="flex flex-col h-full flex-1 flex flex-col justify-center items-center
          self-stretch gap-8 items-start p-8"
      >
        <!-- File has been verified and there are arrors -->
        <div
          v-if="fileVerified && (errorNoData || errorOtherData)"
          class="w-full h-full sm:w-[362px] flex flex-col p-4 border border-darkGrey
            rounded-[8px] justify-center gap-2 overflow-hidden"
        >
          <!-- File contains errors Text -->
          <div class="flex flex-col gap-2 justify-center items-center">
            <div>
              <Icon
                icon="noto:warning"
                height="24"
                width="24"
              />
            </div>

            <p class="text-fakeBlack text-base font-medium text-center">
              {{ $t('Your file contains errors') }}
            </p>

            <p class="text-fakeBlack text-sm font-normal text-center">
              {{ $t('Please correct the errors below and load the file again.') }}
            </p>
          </div>

          <!-- List of errors -->
          <div class="flex flex-col h-fit gap-2 self-stretch overflow-auto">
            <!-- Error empty data (Empty .xlsx file) -->
            <div
              v-if="errorNoData"
              class="flex flex-col justify-center items-start self-stretch px-2 py-1
                  rounded border border-darkGrey"
            >
              <p class="text-fakeBlack text-sm font-medium">
                {{ errorList?.[0]?.title }}
              </p>

              <p class="text-darkGrey text-sm font-normal">
                {{ errorList?.[0]?.description }}
              </p>
            </div>

            <!-- Other types of erros -->
            <div
              v-else-if="errorOtherData"
              class="flex flex-col gap-2 self-stretch"
            >
              <!-- Error List -->
              <div
                v-for="(error, index) in errorList"
                :key="index"
                class="flex flex-col justify-center items-start self-stretch px-2 py-1
                  rounded border border-darkGrey"
              >
                <p
                  v-if="error?.title"
                  class="text-fakeBlack text-sm font-medium"
                >
                  {{ error?.title }}
                </p>

                <p
                  v-if="error?.line"
                  class="text-negativeRed text-xs font-normal"
                >
                  {{ $t('On line') + error?.line }}
                </p>

                <p
                  v-if="error?.description"
                  class="text-darkGrey text-sm font-normal italic"
                >
                  {{ error?.description }}
                </p>
              </div>
            </div>
          </div>

          <!-- Button upload another file -->
          <div class="self-center pt-8">
            <v-btn
              variant="flat"
              color="primary"
              height="40"
              @click="uploadAnotherFile"
            >
              <div class="flex gap-2">
                <Icon
                  icon="material-symbols:upload"
                  height="16"
                  width="16"
                />

                <p class="text-xs font-medium normal-case">
                  {{ $t('Upload another file') }}
                </p>
              </div>
            </v-btn>
          </div>
        </div>

        <div
          v-else-if="isImporting == null"
          class="w-full sm:w-[368px] flex flex-col justify-center items-center
            flex-1 p-4 border border-dashed rounded-[8px]"
        >
          <div class="flex flex-col gap-2 justify-center items-center">
            <div class="text-negativeRed">
              <Icon
                icon="material-symbols:error"
                height="24"
                width="24"
              />
            </div>

            <p class="text-base font-medium text-center text-negativeRed">
              {{ $t('Impossible to import') }}
            </p>

            <p class="text-fakeBlack text-sm font-normal text-center">
              {{ $t('Your file format is not accepted. Only .xlsx format is accepted.') }}
            </p>
          </div>

          <v-btn
            class="mt-5"
            variant="flat"
            color="primary"
            height="40"
            @click="uploadAnotherFile"
          >
            <div class="flex gap-2">
              <Icon
                icon="material-symbols:upload"
                height="16"
                width="16"
              />

              <p class="text-xs font-medium normal-case">
                {{ $t('Upload another file') }}
              </p>
            </div>
          </v-btn>
        </div>

        <!-- File is not verified or is being verified -->
        <div
          v-else-if="!fileVerified"
          class="w-full sm:w-[368px] flex flex-col justify-center items-center
            flex-1 p-4 border border-dashed rounded-[8px]"
          :class="isVerifyingFile ? 'gap-[48px]' : dragActive ?
            '!border-[#333333] !bg-[#F6F6F6] gap-2' : '!border-[#CDCDCD] !bg-white gap-2'"
          @drop.prevent="onDrop"
          @dragenter.prevent="setActiveDrag"
          @dragover.prevent="setActiveDrag"
          @dragleave.prevent="setInactiveDrag"
        >
          <div
            v-if="isVerifyingFile && !fileVerified"
            class="flex flex-col justify-center items-center gap-1"
          >
            <div class="flex justify-center items-center p-[10px]">
              <svn-loader
                :model-value="inspectProgress"
                :size="150"
                :width="15"
                class="w-full text-fakeBlack font-medium"
                :class="isMobile ? 'text-[36px]' : 'text-base'"
              >
                <template #default>
                  {{ inspectProgress }}%
                </template>
              </svn-loader>
            </div>

            <p class="text-fakeBlack text-base font-medium">
              {{ $t('Inspecting file...') }}
            </p>
          </div>

          <v-btn
            v-if="isVerifyingFile && !fileVerified"
            variant="outlined"
            :text="$t('Cancel')"
            height="40"
            class="normal-case"
            @click="openConfirmExitDialog"
          />

          <!-- Drag & Drop block -->
          <div
            v-if="!isVerifyingFile && !fileVerified"
            class="flex flex-col h-full sm:h-[464px] justify-center items-center"
          >
            <div>
              <Icon
                icon="material-symbols:upload"
                height="48"
                width="48"
                :class="dragActive ? 'text-fakeBlack' : 'text-middleGrey'"
              />
            </div>

            <div v-if="isImporting != null" class="flex flex-col justify-center items-center gap-1 self-stretch">
              <p
                v-if="!dragActive"
                class="text-fakeBlack text-sm font-normal text-center"
              >
                {{ $t('Drag & drop or') }}
                <span
                  class="text-[#5C95FF] underline cursor-pointer"
                  @click="fileInput.click()"
                >
                  {{ $t('chose file') }}
                </span>
                {{ $t('to upload') }}
              </p>

              <p
                v-if="!dragActive"
                class="text-darkGrey text-xs font-normal"
              >
                {{ $t('Only .xlsx format is accepted') }}
              </p>

              <p
                v-if="dragActive"
                class="text-fakeBlack text-sm font-normal"
              >
                {{ $t('Drop your file here !') }}
              </p>

              <p
                v-if="dragActive"
                class="text-transparent text-xs font-normal"
              >
                {{ $t('Only .xlsx format is accepted') }}
              </p>
            </div>
          </div>
        </div>

        <!-- File has beeen verified and there are no errors -->
        <div
          v-else-if="fileVerified && !errorNoData && !errorOtherData"
          class="w-full h-full flex flex-col gap-8 flex-1"
        >
          <!-- File Ready for import block -->
          <div
            class="flex flex-col justify-center items-center p-4 gap-2
            rounded-[8px] border border-darkGrey sm:w-[368px] sm:self-center"
          >
            <div>
              <Icon
                icon="gg:check-o"
                height="22"
                width="22"
                class="text-positiveGreen"
              />
            </div>

            <p class="text-positiveGreen text-base font-medium">
              {{ $t('File ready for import') }}
            </p>

            <p class="text-fakeBlack text-sm font-normal">
              {{ $t('No errors detected.') }}
            </p>
          </div>

          <!-- Modifications block -->
          <div class="flex flex-col gap-2 sm:gap-4 flex-1">
            <p class="text-fakeBlack text-base font-medium">
              {{ $t('Modifications') }}
            </p>

            <!-- List of modifications -->
            <div class="flex flex-1 flex-col items-start gap-2">
              <div
                v-for="(modification, index) in modifications"
                :key="index"
                class="w-full flex flex-col items-start"
              >
                <div class="w-full flex flex-col sm:flex-row">
                  <p class="text-fakeBlack text-base sm:w-[175px] font-normal">
                    {{ modification?.type }} :
                  </p>

                  <div class="w-full flex justify-between">
                    <div
                      v-for="(change, index) in modification?.changes"
                      :key="index"
                      class="flex items-center gap-1"
                    >
                      <div>
                        <Icon
                          :icon="getModificationDetail(change?.type)?.[0]"
                          height="16"
                          width="16"
                          :class="`text-${getModificationDetail(change?.type)?.[1]}`"
                        />
                      </div>

                      <p class="text-fakeBlack text-base font-normal">
                        {{ change?.count }} {{ change?.type }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  v-if="index !== modifications?.length - 1"
                  class="w-full border-t border-t-darkGrey mt-2"
                />
              </div>
            </div>

            <!-- Send invitation emails block -->
            <div class="flex gap-2 items-center">
              <v-checkbox
                v-model="sendInvitations"
                hide-details
                color="primary"
                :label="$t('Send invitation emails to all new users')"
              />
            </div>
          </div>
        </div>

        <!-- Help text -->
        <div
          v-if="!fileVerified"
          class="flex flex-col sm:flex-row justify-center items-center gap-4 self-stretch"
        >
          <div class="flex flex-col items-start self-stretch">
            <p class="text-fakeBlack text-sm font-medium">
              {{ $t("You don't have a file ?") }}
            </p>

            <div class="flex flex-col">
              <p class="text-darkGrey text-xs font-normal">
                {{ $t('Download and complete the template provided.') }}
              </p>

              <p class="text-darkGrey text-xs font-normal">
                {{ $t("You can use the 'Help' tab in the file to fill in the template.") }}
              </p>
            </div>
          </div>
          <a href="/template_import.xlsx" target="_blank">
            <v-btn
              variant="text"
              color="primary"
              height="40"
            >
              <div class="flex gap-2">
                <Icon
                  icon="material-symbols:download"
                  height="16"
                  width="16"
                  class="text-fakeBlack"
                />

                <p class="text-fakeBlack text-xs font-medium normal-case">
                  {{ $t('Download template') }}
                </p>
              </div>
            </v-btn>
          </a>
        </div>
      </div>

      <v-file-input
        ref="fileInput"
        color="primary"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        counter
        variant="outlined"
        class="hidden"
        @change="handleFileChange"
      />

      <dialog-validation
        ref="dialog"
        icon="mdi:exit-to-app"
        title="Are you sure you want to leave"
        primary-button-text="Yes, leave"
        secondary-button-text="Cancel"
        :display-close="false"
        description="The import operation will be abandonned and you will have to start it over."
        button-primary-color="negativeRed"
        @click-primary-button="cancelEverything"
      >
        <template #button>
          {{ }}
        </template>
      </dialog-validation>
    </template>
  </modal-skeleton>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { ref, onMounted, onUnmounted } from "vue";
import ModalSkeleton from '../skeleton/BktPopUpModalSkeleton.vue';
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import DialogValidation from "../Dialogs/DialogValidation.vue";
import ImportDetails from "@/components/companyApp/ImportDetails.vue";
import axios from 'axios';
import { debounce } from "lodash";
import { useActionCable } from "@/store/cable.js";
import { useCompanyStore } from "@/store/company.js";
import { useUserStore } from "@/store/user.js";

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, (e) => {
      e.preventDefault()
    })
  })
})

const { fetchUsersForImport } = useUserStore();
const { fetchCompany } = useCompanyStore();
const { isMobile } = storeToRefs(useMobileStore())

const { history, modifications } = storeToRefs(useCompanyStore())

const snackbar = useSnackbar()

const fileInput = ref(null)
const isVerifyingFile = ref(false)
const fileVerified = ref(false)
const inspectProgress = ref(0)
const interval = ref(0)
const errorNoData = ref(false)
const errorOtherData = ref(false)
const sendInvitations = ref(false)
const isImporting = ref(false)
const importProgress = ref(0)
const importError = ref(false)
const showResults = ref(false)
const draggedFile = ref(null)
const dragActive = ref(false)
const dragTimeout = ref(null)
const dialog = ref(null)
const modalImportUsers = ref(null)
const importUserChannel = ref(null)
const { cable } = storeToRefs(useActionCable());
const errorList = ref([])
const importDetails = ref([])
const events = ['dragenter', 'dragover', 'dragleave', 'drop']

const setUpImportDetails = (history) => {
  let created = []
  if (history.created?.length) {
    created = history.created.concat(history.tags_added).concat(history.tag_categories_added)
  }
  importDetails.value = [
    {
      type: 'created',
      changes: created
    },
    {
      type: 'updated',
      changes: history.updated
    },
    {
      type: 'deleted',
      changes: history.removed
    }
  ]
}

const handleFileChange = async(e) => {
  if (e.target.files.length == 0) return

  const file = e.target.files[0]
  if (fileHasCorrectFormat(file)) {
    isVerifyingFile.value = true

    if (isVerifyingFile.value) {
      importFile(file)
    }
  }
  else {
    isImporting.value = null
    importError.value = true

    displaySnackbarIncorrectFormat()
  }
}

const onDrop = (e) => {
  draggedFile.value = e.dataTransfer.files?.[0]
  if (fileHasCorrectFormat(draggedFile.value)) {
    isVerifyingFile.value = true

    if (isVerifyingFile.value) {
      importFile(draggedFile.value)
    }
  }
  else {
    isImporting.value = null
    importError.value = true

    displaySnackbarIncorrectFormat()
  }
}

const importFile = async (file) => {
  try {
    const headers = { "Content-Type": "multipart/form-data" };
    await axios.post(
      `/api/v1/companies/import_file`, {
      import_file: file
    }, { headers })
  } catch (e) {
    console.log(e);
  }
};

const importData = async () => {
  try {
    importProgress.value = 0
    const headers = { "Content-Type": "application/json" };
    await axios.post(
      `/api/v1/companies/import_data`,
      { history_id: history.value.id, send_invite: sendInvitations.value }
    )
  } catch (e) {
    console.log(e);
  }
};

const parseImportFile = debounce(async() => {
  const subscribeOptions =
    {
      channel: "ImportUserChannel",
      room: "parseImportFile"
    }
    importUserChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function() {
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      if (data.status === 'ok') {
        inspectProgress.value = 100
        history.value = data.history
        modifications.value = data?.history?.modifications
        fileVerified.value = true
      }
      else if (data.status === 'unprocessable_entity') {
        errorList.value = data.message
        errorOtherData.value = true
        fileVerified.value = true
        return
      }
      inspectProgress.value = data.progression
    },

    update: async function() {
    },
  });
}, 200)

parseImportFile()

const importFileData = debounce(async() => {
  const subscribeOptions =
    {
      channel: "ImportUserChannel",
      room: "importFileData"
    }
    importUserChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function() {
    },

    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },

    received: function(data) {
      if (data.status === 'ok') {
        importProgress.value = 100
        history.value = data.history
        modifications.value = data.history?.modifications
        setUpImportDetails(data.history)
      }
      else if (data.status === 'unprocessable_entity') {
        showResults.value = true
        importError.value = true
      }
      else if (data.status === 'in_progress') {
        modifications.value = data.history?.modifications
        importProgress.value = data.progression
      }
    },

    update: async function() {
    },
  });
}, 200)

importFileData()

const uploadAnotherFile = () => {
  errorOtherData.value = false
  fileVerified.value = false
  isImporting.value = false
  showResults.value = false
  isVerifyingFile.value = false
  importError.value = false
  inspectProgress.value = 0
  importProgress.value = 0
  errorList.value = []
  fileInput.value.click()
}

const openConfirmExitDialog = () => {
  dialog.value.dialogRef.dialog = true
}

const cancelEverything = () => {
  clearInterval(interval.value)
  setInactiveDrag()
  isVerifyingFile.value = false
  fileVerified.value = false
  isImporting.value = false
  importError.value = false
  showResults.value = false
  importProgress.value = 0
  errorList.value = []
  inspectProgress.value = 0
  if (dialog.value?.dialogRef?.dialog) dialog.value.dialogRef.dialog = false
  modalImportUsers.value.dialog = false
  sendInvitations.value = false
}

const getModificationDetail = (type) => {
  if (type === 'created') {
    return ['mdi-add', 'positiveGreen']
  }
  else if (type === 'modified') {
    return ['grommet-icons:update', 'mediumOrange']
  }
  else if (type === 'removed') {
    return ['mdi-trash-can-outline', 'negativeRed']
  }
}

const startImporting = () => {
  isImporting.value = true
  importData()
}

const retryImport = () => {
  isImporting.value = false
  importProgress.value = 0
  importError.value = false
}

const closeModal = async() => {
  modalImportUsers.value.dialog = false
  setTimeout(() => {
    cancelEverything()
  }, 250);
  await fetchCompany()
  await fetchUsersForImport()
}

const setActiveDrag = () => {
  dragActive.value = true
  clearTimeout(dragTimeout.value)
}

const setInactiveDrag = () => {
  dragTimeout.value = setTimeout(() => {
    dragActive.value = false
  }, 50)
}

const fileHasCorrectFormat = (file) => {
  return file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

const displaySnackbarIncorrectFormat = () => {
  setInactiveDrag()
  snackbar.setBgColor('negativeRed')
  snackbar.setMsg('Your file has the incorrect format, please select a file with the .xlsx format')
  snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
  snackbar.displaySnackBar()
}

const backToDragNDrop = () => {
  showResults.value = false
  fileVerified.value = false
  dragActive.value = false
  isVerifyingFile.value = false
}

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, (e) => {
      e.preventDefault()
    })
  })
})
</script>
