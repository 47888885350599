<template>
  <v-card
    width="700"
    class="my-10"
  >
    <v-card-actions>
      <v-btn
        :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        @click="show = !show"
        :role="show ? 'up' : 'down'"
      />
      <slot name="title" />

      <v-spacer />

      <v-btn
        v-if="moveUp"
        color="primary"
        role="move-up"
        icon="mdi-chevron-up"
        :disabled="isDeleted"
        @click="$emit('moveUp')"
      />
      <v-btn
        v-if="moveDown"
        color="primary"
        role="move-down"
        icon="mdi-chevron-down"
        :disabled="isDeleted"
        @click="$emit('moveDown')"
      />
      <v-btn
        v-if="copy"
        color="primary"
        icon="mdi-content-copy"
        role="copy"
        :disabled="isDeleted"
        @click="$emit('copy-bkt')"
      />
      <v-btn
        v-if="$props.delete"
        color="error"
        role="delete"
        icon="mdi-trash-can-outline"
        :disabled="isDeleted"
        @click="$emit('delete')"
      />
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider />

        <v-card-text>
          <slot name="body" />
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import {ref} from "vue";

defineProps({
  moveUp: {type: Boolean, default: true},
  moveDown: {type: Boolean, default: true},
  copy: {type: Boolean, default: true},
  delete: {type: Boolean, default: true},
  isDeleted: {type: Boolean, required: true}
})

const show = ref(true)
</script>
