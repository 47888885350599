<template>
  <div class="flex flex-col gap-4 sm:gap-6">
    <div class="flex items-center !w-full border-b-0 border-b-lightGrey">
      <v-text-field
        v-model="searchText"
        class="flex-1 w-full input mr-4"
        color="primary"
        variant="outlined"
        hide-details
        prepend-inner-icon="mdi-magnify"
        clearable
        :label="$t('Search')"
        @update:model-value="updateText"
      />

      <svn-filter
        v-if="headers"
        :table-name="tableName"
        :filter-columns="filterList"
        :show-results="showResults"
        :selected-filters="getActiveFiltersCount()"
        @add-filter="addFilter"
        @activator-clicked="displayOrHideFilterResulsts"
        @get-filter-columns="getFilterColumns"
      />

      <v-menu>
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon
            class="flex justify-center shadow-none bkt-light-grey5 rounded
              hover:shadow-none bkt-bg-light-grey5-hover bkt-light-grey6-hover
              focus:shadow-none
              bkt-white-onclick"
            height="3.2em"
            width="3.2em"
          >
            <v-icon
              icon="mdi-dots-vertical"
            />
          </v-btn>
        </template>

        <v-list class="!py-0">
          <v-list-item
            :disabled="users?.length < 1"
            :href="backendAutoLogin('/api/v1/users/export', {company_id: id})"
            target="_blank"
          >
            <v-list-item-title>
              {{ $t('Export all users') }}
            </v-list-item-title>
          </v-list-item>

          <modal-view-deactivated-users @fetch-users="emit('fetch-users')">
            <template #button>
              <v-list-item
                v-if="isAdminOrAbove()"
                :value="'deactivate_users'"
              >
                <v-list-item-title>
                  {{ $t('View deactivated users') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </modal-view-deactivated-users>

          <dialog-validation
            :display-close="false"
            title="Are you sure you want to remove these users from your company ?"
            description="This is a permanent action"
            primary-button-text="Yes, remove"
            button-primary-color="negativeRed"
            secondary-button-text="No, cancel"
            :bind="selectedUsers?.length ? true : false"
            @click-primary-button="removeUsers"
          >
            <template #button>
              <v-list-item
                class="hover:!bg-[#F6F6F6] cursor-pointer"
                :disabled="!selectedUsers?.length"
              >
                <v-list-item-title>
                  <p class="normal-case text-negativeRed">
                    {{ $t("Deactivate selected users") }}
                  </p>
                </v-list-item-title>
              </v-list-item>
            </template>
          </dialog-validation>
        </v-list>
      </v-menu>
    </div>

    <svn-filter-results
      :data="users"
      :table-name="tableName"
      :headers="headers"
      :show-results="showResults"
      :filter-list="filterList"
      :selected-filters="selectedFilters"
      :show-filter-resulsts="showFilterResulsts"
      @add-filter="addFilter"
      @delete-filter="deleteFilter"
      @clear-selected-filters="clearSelectedFilters"
      @remove-empty-filter="removeEmptyFilter"
      @update-selected-data-filters="updateSelectedDataFilters"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import axios from "../../tools/axios-service.js";
import DialogValidation from "@/components/BktPopUp/Dialogs/DialogValidation.vue";
import { useCompanyStore } from "@/store/company.js";
import { debounce } from "lodash";
import useTools from "../../tools/useTools.js";
import { useUserStore } from "@/store/user.js";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import { useFilterStore } from "@/store/filters";
import i18n from "@/plugins/i18n";
import SvnFilter from "@/components/SvnFilter.vue";
import axiosService from "@/tools/axios-service.js";
import SvnFilterResults from "@/components/SvnFilterResults.vue";
import ModalViewDeactivatedUsers from "@/components/BktPopUp/Modals/ModalViewDeactivatedUsers.vue";

const emit = defineEmits(["fetch", "fetch-users"]);

const snackbar = useSnackbar();
const { id } = useCompanyStore();
const { backendAutoLogin } = useTools();
const { fetchFilterMetadata } = useFilterStore();
const { isAdminOrAbove } = useUserStore()

const { columns } = storeToRefs(useFilterStore());
const { users, params, selectedUsers, inactiveUsers } = storeToRefs(useUserStore());
const { isMobile } = storeToRefs(useMobileStore());

const searchText = ref(params?.value?.title);
const tableName = ref("users");
const showResults = ref(false);
const filterList = ref([]);
const filterData = ref([]);
const selectedFilters = ref([]);
const showFilterResulsts = ref(true);
const headers = ref([
  {
    name: "firstname",
    key: "firstname",
    title: i18n.global.t("Name"),
    icon: "",
  },
  {
    name: "access_level",
    key: "access_level",
    title: i18n.global.t("Access level"),
    icon: "",
  },
  {
    name: "manager_id",
    key: "manager_id",
    title: i18n.global.t("Manager"),
    icon: "",
  },
  {
    name: "hire_date",
    key: "hire_date",
    title: i18n.global.t("Hire date"),
    icon: "",
  },
]);

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", {
    title: searchText.value,
  });
}, 300)

const removeUsers = async () => {
  users.value = null;
  try {
    const res = await axios.patch(`/api/v1/users/disabled_users`, {
      users_ids: selectedUsers.value,
    });
    if (res.status == 200) {
      emit('fetch-users')
      inactiveUsers.value = [ ...inactiveUsers?.value, ...res?.data?.users ]
    }
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('The selected user(s) has/have been removed from company.')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (e) {
    console.log("Cannot remove users, error occured", e);
  }
};

const getFilterColumns = async() => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(tableName?.value)
      for (let column of columns?.value) {
        let foundCol = headers?.value?.find(el => el?.name === column?.name)
        if (foundCol) {
          let newCol = { ...column, key: foundCol?.key, name: foundCol?.title, icon: foundCol?.icon || "" }
          filterList?.value?.push(newCol)
        }
      }
    } catch (error) {
      console.log('Error')
    }
  }
}

const addFilter = (item) => {
  selectedFilters.value.push({ ...item, active: false })
  showFilterResulsts.value = true
}

const removeEmptyFilter = (item) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === item?.id), 1)
}

const deleteFilter = async(id) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === id), 1)
  filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === id), 1)
  checkShowResulsts()

  if (filterData?.value?.length) {
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  } else {
    params.value = {}
  }

  await fetchUsersAfterFilter()
}

const clearSelectedFilters = async() => {
  selectedFilters.value = []
  showFilterResulsts.value = false
  showResults.value = false
  
  params.value = {}
  if (filterData?.value?.length) {
    filterData.value = []
    await fetchUsersAfterFilter()
  }
}

const updateSelectedDataFilters = async(data) => {
  let found = selectedFilters?.value?.find(el => el?.id === data?.id)
  let filter = filterData?.value?.find(element => element?.id === data?.event?.id)

  if (filter) {
    filter.operator = data?.event?.operator
    filter.value = data?.event?.value
  } else {
    filterData?.value?.push({
      id: data?.event?.id,
      name: data?.event?.name,
      operator: data?.event?.operator?.toLowerCase(),
      value: data?.event?.value,
    })
  }

  if (data?.event?.value?.length) {
    found.active = true
    checkShowResulsts()
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  }
  else {
    found.active = false
    checkShowResulsts()
    filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === data?.id), 1)
    if (filterData?.value?.length) {
      params.value = {
        filter_data: JSON.stringify(filterData?.value),
      }
    } else {
      params.value = {}
    }
  }

  await fetchUsersAfterFilter()
}

const getActiveFiltersCount = () => {
  return selectedFilters?.value?.filter(el => el.active === true).length
}

const displayOrHideFilterResulsts = () => {
  showFilterResulsts.value = !showFilterResulsts.value
}

const checkShowResulsts = () => {
  if (selectedFilters?.value?.filter(el => el.active === true).length) {
    showResults.value = true
  }
  else {
    showResults.value = false
  }
}

const fetchUsersAfterFilter = async() => {
  emit('fetch')
}

watch(selectedFilters.value, (newValue, oldValue) => {
  if (newValue.length > 0) {
    showFilterResulsts.value = true
  }
  else {
    showFilterResulsts.value = false
  }
})
</script>

<style scoped>
.input :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
