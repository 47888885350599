<template>
  <div class="flex flex-col items-center justify-center mx-auto mt-8 px-4">
    <div class="w-full sm:4/5 xl:w-[56rem] mx-auto mb-6">
      <div class="flex flex-col sm:flex-row w-full sm:space-x-4">
        <v-select
          v-model="answer"
          class="w-full sm:w-3/5"
          item-title="title"
          item-value="value"
          variant="outlined"
          label="Question"
          :items="answers_items"
          @update:model-value="() => {
            if (answer?.charts) {
              switch (answer.type) {
              case InterviewQuestionType.RATING:
                ratingData.datasets[0].data = Object.values(answer?.charts);
                ratingData.labels = Object.keys(answer?.charts);
                break;
              case InterviewQuestionType.MCQ:
                mcqData.datasets[0].data = Object.values(answer?.charts);
                mcqData.labels = Object.keys(answer?.charts);
                break;
              }
            }

            fetchSurveyAnswers(campaign?.default_interview_form_id , answer.id, Number.isInteger(tag) ? tag : null)
          }"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="tagCat"
          item-title="title"
          item-value="value"
          :items="tag_cats"
          variant="outlined"
          :label="$t('Tag category')"
          class="w-full sm:w-1/5"
          @update:model-value="() => {
            tag = 'all'
            if(tagCat === 'all') {
              fetchSurveyAnswers(campaign?.default_interview_form_id , answer.id)
            }
          }"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
        <v-select
          v-model="tag"
          item-title="title"
          item-value="value"
          variant="outlined"
          label="Tag"
          :items="tags[tagCat]"
          class="w-full sm:w-1/5"
          :disabled="tagCat === 'all'"
          @update:model-value="() => {
            fetchSurveyAnswers(campaign?.default_interview_form_id , answer.id, tag)
          }"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
      </div>
      <v-card
        class="m-1 py-5"
      >
        <div class="px-5 py-2 font-medium !break-words text-xl">
          {{ answer?.text }}
        </div>
        <v-card-subtitle class="px-5">
          {{ answers.length }} {{ $t(`answer${ answers.length > 1 ? 's': '' }`) }}
        </v-card-subtitle>
        <v-divider
          v-if="answer?.type === InterviewQuestionType.RATING || answer?.type === InterviewQuestionType.MCQ"
          thickness="1"
          class="border-opacity-100 mt-3"
        />
        <v-card-item
          v-if="answer?.type === InterviewQuestionType.RATING || answer?.type === InterviewQuestionType.MCQ"
          class="sm:mx-16 sm:my-3"
        >
          <div>
            <BktBar
              v-if="answer?.type === InterviewQuestionType.RATING"
              :key="answer.id"
              :data="ratingData"
              :options="RatingOptions"
            />
            <BktBar
              v-else-if="answer?.type === InterviewQuestionType.MCQ"
              :key="answer.id"
              :data="mcqData"
              :options="McqOptions"
            />
          </div>
        </v-card-item>
      </v-card>
    </div>
    <div class="w-full sm:4/5 xl:w-[56rem] mx-auto flex flex-col justify-center items-center">
      <v-tabs
        v-model="tab"
        :grow="isMobile"
        class="w-full sm:self-start"
      >
        <v-tab
          :value="1"
          class="border-b-2"
        >
          {{ $t('All') }}
          <div class="rounded-sm bkt-bg-light-blue ml-1 p-1">
            {{ answers ? answers.length : 0 }}
          </div>
        </v-tab>
        <v-tab
          :value="2"
          class="border-b-2"
        >
          {{ $t('Pinned') }}
          <div class="rounded-sm bkt-bg-light-blue ml-1 p-1">
            {{ pinned_answers ? pinned_answers.length : 0 }}
          </div>
        </v-tab>
      </v-tabs>
      <v-window
        v-model="tab"
        class="w-full py-4"
      >
        <v-window-item
          :key="1"
          :value="1"
        >
          <bkt-survey-all-answers
            :answers="answers"
            :answer="answer"
            :is-trashed="isTrashed"
            :is-anonymous="isAnonymous"
            :interview-form-id="campaign?.default_interview_form_id"
            @pin="pin"
            @open="open"
          />
        </v-window-item>

        <v-window-item
          :key="2"
          :value="2"
        >
          <bkt-survey-all-answers
            :answers="pinned_answers"
            :answer="answer"
            :is-trashed="isTrashed"
            :is-anonymous="isAnonymous"
            :interview-form-id="campaign?.default_interview_form_id"
            @pin="pin"
            @open="open"
          />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import BktBar from "@/components/chart/BktBar.vue";
import {useUserStore} from "@/store/user.js";
import {useCompanyStore} from "@/store/company"
import {storeToRefs} from "pinia";
import axios from "@/tools/axios-service.js";
import {useSurveyStore} from "@/store/survey.js";
import {useRoute} from "vue-router";
import BktSurveyAllAnswers from "@/components/surveyApp/BktSurveyAllAnswers.vue";
import {useMobileStore} from "@/store/mobile.js";
import { InterviewQuestionType } from '@/constants/types';

// Store variables
  const { t } = useI18n()
  const {fetchUser} = useUserStore()
  const {fetchCompany} = useCompanyStore()
  const {isMobile} = storeToRefs(useMobileStore())
  const {fetchSurveyQuestions, fetchSurveyAnswers, surveyPinAnswers} = useSurveyStore()

  const props = defineProps({
    campaign: { type: Object, default: () => {} },
    isTrashed: { type: Boolean, default: true },
  })

  // Const variables
  const CHART_COLORS = ['#5C95FF']

  // Refs
  const { id, firstname, lastname, email, avatar } = storeToRefs(useUserStore());
  const { questions, answers, pinned_answers } = storeToRefs(useSurveyStore());
  const route = useRoute()

  const answers_items = ref([])
  const answer = ref(null)
  const tagCat = ref("all")
  const tag = ref("all")
  const tab = ref('0')
  const mcqData = ref({
    labels: answer.value ? Object.keys(answer.value?.charts) : [],
    datasets: [
      {
        backgroundColor: CHART_COLORS[0],
        data: answer.value ? Object.values(answer.value?.charts) : [],
        skipNull: true,
        maxBarThickness: 7,
      },
    ]
  });

  const ratingData = ref({
    labels: answer.value ? Object.keys(answer.value?.charts) : [],
    datasets: [
      {
        backgroundColor: CHART_COLORS[0],
        data: answer.value ? Object.values(answer.value?.charts) : [],
        skipNull: true,
      },
    ]
  });

  const McqOptions = ref({
    scaleShowValues: true,
    scales: {
      y: {
        ticks: {
          autoSkip: false
        }
      }
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        display: false
      }
    }
  })

  const RatingOptions = ref({
    legend: {
      display: false,
      title: {
        display: false,
      },
    },
    scaleShowValues: true,
    scales: {
      y: {
        ticks: {
          autoSkip: false
        }
      }
    },
    maintainAspectRatio: false,
    indexAxis: 'x',
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        display: false
      }
    }
  })

  const tag_cats = ref([
    {title: t("All"), value: "all"},
  ])

  const tags = ref({
    "all": [{title: t("All"), value: "all"}],
  })

  // Variables
  const isAnonymous = ref(true)

  // Functions
  onMounted(() => {
    fetchCompany()
    fetchUser()
    getTagCategory()

    fetchSurveyQuestionsFunction()
  })

  const fetchSurveyQuestionsFunction = () => {
    fetchSurveyQuestions(route.params.id).then(() => {
      answers_items.value = questions.value
          .filter((quest) => {
            return [InterviewQuestionType.RATING, InterviewQuestionType.MCQ, InterviewQuestionType.OPEN].includes(quest.type)
          })
          .map((question, idx) => ({
        title: (idx + 1) + ". " + question.text,
        value: question,
      }))

      if(!answer.value){
        answer.value = answers_items?.value?.[0]?.value

        if (answer.value) {
          switch (answer.value?.type) {
            case InterviewQuestionType.RATING:
              ratingData.value.datasets[0].data = Object.values(answer.value?.charts);
              ratingData.value.labels = Object.keys(answer.value?.charts);
              break;
            case InterviewQuestionType.MCQ:
              mcqData.value.datasets[0].data = Object.values(answer.value?.charts);
              mcqData.value.labels = Object.keys(answer.value?.charts);
              break;
            default:
              break;
          }
        }
      }

      if (props.campaign?.default_interview_form_id && answer.value?.id) {
        if (tagCat?.value !== "all") {
          fetchSurveyAnswers(props.campaign?.default_interview_form_id , answer?.value?.id, tag?.value)
        } else {
          fetchSurveyAnswers(props.campaign.default_interview_form_id , answer.value.id)
        }
      }

      isAnonymous.value = props.campaign?.anonymize_answers
    })
  }

  const getTagCategory = async () => {
    const res = await axios.get('/api/v1/companies/tag_categories', {
      params: {
        job_title: true,
        format: 'json'
      }
    })
    if (!res.data.tag_categories)
      return tag_cats.value

    res.data.tag_categories.forEach((cat) => {
      tag_cats.value = tag_cats.value.concat({
          title: cat.name,
          value: cat.id
      })
      tags.value[cat.id] = cat.tags.map((tag) => {
        return {
          title: tag.tag_name,
          value: tag.id
        }
      })
    })
  }

  const pin = (idx, question_id, interview_form_id, interview_answer_id, pin_answer) => {
    if (tab.value === "2") {
      pinned_answers.value[idx].pinned = pin_answer
    } else {
      answers.value[idx].pinned = pin_answer
    }
    surveyPinAnswers(question_id, interview_form_id, interview_answer_id, pin_answer)
    fetchSurveyQuestionsFunction()
  }

  const open = (idx) => {
    if (tab.value === "2") {
      pinned_answers.value[idx].open = !pinned_answers.value[idx].open
    } else {
      answers.value[idx].open = !answers.value[idx].open
    }
  }

</script>
