<template>
  <v-card
    v-if="campaignInterviews"
    class="w-full flex flex-col rounded-lg p-6 md:max-w-4xl"
  >
    <v-card-title
      class="flex flex-col items-center p-0
        md:flex-row md:justify-start"
    >
      <my-interview-card-header
        :campaign-deadline="campaignDeadline"
        :campaign-type="campaignType"
        :campaign-name="campaignTitle"
      />
    </v-card-title>

    <div
      v-for="(interview, index) in campaignInterviews"
      :key="interview.id"
    >
      <div class="relative flex flex-col mt-4">
        <div class="flex flex-col md:flex-row md:max-w-xl">
          <svn-avatar
            :avatar="interview?.employee?.avatar?.['30']"
            :firstname="interview?.employee?.fullname.split(' ')[1]"
            :lastname="interview?.employee?.fullname.split(' ')[0]"
            size="md"
            class="mb-4 md:!mb-0 self-center md:mr-3"
          />

          <div class="flex flex-col items-center md:items-start">
            <v-list-item-title
              class="font-medium text-sm text-center md:!text-start w-full"
            >
              <svn-tooltip>
                <template #activator-content>
                  {{ interview?.employee?.fullname }}
                </template>

                <template #tooltip-content>
                  {{ interview?.employee?.fullname }}
                </template>
              </svn-tooltip>
            </v-list-item-title>

            <div
              v-if="interview?.interview_set?.employee_interview === null"
              class="text-sm flex flex-row justify-center items-center"
            >
              <div
                class="w-[8px] h-[8px] mr-1 rounded-full"
                :class="getBgByCampaignStatus('not_available_yet')"
              />
              <p
                data-testid="employee"
                class="font-medium text-sm"
                :class="getColorByCampaignStatus('not_available_yet')"
              >
                {{ $t('No interview') }}
              </p>
            </div>

            <div
              v-else
              class="text-sm flex flex-row justify-center items-center"
            >
              <div
                class="w-[8px] h-[8px] mr-1 rounded-full"
                :class="getBgByCampaignStatus(interview?.interview_set?.employee_interview?.status)"
              />
              <p
                class="font-medium"
                :class="getColorByCampaignStatus(interview?.interview_set?.employee_interview?.status)"
                data-testid="employee"
              >
                {{ capitalize($t(interview?.interview_set?.employee_interview?.status)) }}
              </p>
            </div>
          </div>

          <v-divider
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="border-opacity-100 m-3 md:hidden"
          />
          <v-divider
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="md:border-opacity-100 mx-0 md:!mx-3 md:block hidden opacity-100"
            vertical
          />

          <div
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview === null"
            class="flex flex-col md:flex-row md:items-start"
          >
            <v-divider
              class="border-opacity-100 m-3 md:hidden"
            />
            <v-divider
              class="md:border-opacity-100 mx-0 md:!mx-3 md:block hidden opacity-100"
              vertical
            />

            <div
              class="flex flex-col items-center md:items-start"
            >
              <v-list-item-title
                class="font-medium text-sm text-center"
              >
                {{ $t('Interviewer (you)') }}
              </v-list-item-title>

              <interview-status-chip
                :status="interview?.interview_set?.manager_interview?.status"
                data-testid="manager"
              />
            </div>
          </div>

          <div
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="flex flex-col items-center md:items-start"
          >
            <v-list-item-title
              class="font-medium text-sm text-center"
            >
              {{ $t('Interviewer (you)') }}
            </v-list-item-title>

            <interview-status-chip
              :status="interview?.interview_set?.manager_interview?.status"
              data-testid="manager"
            />
          </div>

          <v-divider
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="border-opacity-100 m-3 md:hidden"
          />
          <v-divider
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="md:border-opacity-100 mx-0 md:!mx-3 md:block hidden opacity-100"
            vertical
          />

          <div
            v-if="interview?.interview_set?.manager_interview !== null &&
              interview?.interview_set?.crossed_interview !== null"
            class="flex flex-col items-center md:items-start"
          >
            <v-list-item-title
              class="font-medium text-sm text-center"
            >
              {{ $t('Cross Review') }}
            </v-list-item-title>

            <interview-status-chip
              :status="interview?.interview_set?.crossed_interview?.status"
              data-testid="cross"
            />
          </div>
        </div>

        <svn-dots-button
          circle
          variant="hoverNoPrimary"
          class="absolute -right-0 top-0"
        >
          <template #items>
            <v-list-item
              value="see_participant_profile"
              @click="goToPath($t('See \'s profile', { reviewers: interview?.employee?.fullname }),
                               '/people/user_show/' + interview?.employee?.id)"
            >
              {{ $t('See participant profile') }}
            </v-list-item>

            <v-list-item
              value="send_invitation_email"
              @click="sendInvitationEmail('invite', interview?.employee?.id)"
            >
              {{ $t('Send invitation email') }}
            </v-list-item>

            <v-list-item
              value="send_reminder_email"
              @click="sendInvitationEmail('reminder', interview?.employee?.id)"
            >
              {{ $t('Send reminder email') }}
            </v-list-item>

            <v-list-item
              value="set_person_incharge"
              @click="openSetAnotherInterviewer(interview?.employee?.id)"
            >
              {{ $t('Set another person in charge') }}
            </v-list-item>

            <v-list-item
              v-if="interview.label === InterviewOneToOneLabels.CROSSED && interview.status !== InterviewStatuses.SUBMITTED"
              value="schedule_a_meeting"
              @click="planMeeting(interview?.interview_set?.crossed_interview)"
            >
              {{ $t('Schedule a meeting') }}
            </v-list-item>
          </template>
        </svn-dots-button>

        <div
          v-if="interview?.interview_set?.manager_interview !== null &&
            interview?.interview_set?.crossed_interview !== null"
          class="flex flex-col md:flex-row md:self-end mb-4 md:mb-6"
        >
          <v-btn
            v-if="interview?.interview_set?.crossed_interview?.status === 'not_started' &&
              interview?.interview_set?.employee_interview?.status === 'submitted' ||
              interview?.interview_set?.employee_interview?.status === 'submitted' &&
              interview?.interview_set?.crossed_interview?.status === 'not_available_yet'"
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.employee_interview?.id
              },
            }"
            variant="outlined"
            class="mt-4 sm:!mr-4 normal-case"
            color="primary"
            data-testid="viewEmployeeAnswers"
          >
            {{ $t("View interviewee's answers") }}
          </v-btn>

          <v-btn
            v-if="interview?.interview_set?.manager_interview?.status === 'submitted'"
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.manager_interview?.id
              },
            }"
            variant="outlined"
            class="mt-4 sm:!mr-4 normal-case"
            color="primary"
            data-testid="viewAnswersBtn"
          >
            {{ $t("View my answers") }}
          </v-btn>

          <v-btn
            v-if="interview?.interview_set?.manager_interview?.status !== 'submitted' ||
              interview?.interview_set?.crossed_interview?.status !== 'not_available_yet'
            "
            class="mt-4 sm:!mx-0 normal-case"
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.manager_interview?.status !== 'submitted' ?
                  interview?.interview_set?.manager_interview?.id :
                  interview?.interview_set?.crossed_interview?.id
              },
            }"
            :variant="interview?.interview_set?.crossed_interview?.status === 'submitted' ? 'outlined' : 'flat'"
            :append-icon="interview?.interview_set?.crossed_interview?.status === 'in_progress' ||
              interview?.interview_set?.crossed_interview?.status === 'not_available_yet' ||
              interview?.interview_set?.crossed_interview?.status === 'not_started' ? 'mdi-arrow-right' : ''"
            color="primary"
            data-testid="continueBtn"
          >
            {{ $t(getInterviewButtonText(interview?.interview_set?.employee_interview?.status,
                                         interview?.interview_set?.manager_interview?.status,
                                         interview?.interview_set?.crossed_interview?.status)) }}
          </v-btn>
        </div>

        <div
          v-else-if="interview?.interview_set?.manager_interview !== null &&
            interview?.interview_set?.employee_interview !== null &&
            interview?.interview_set?.crossed_interview === null"
          class="flex flex-col md:flex-row md:self-end mb-4 md:mb-6"
        >
          <v-btn
            :disabled="interview?.interview_set?.employee_interview.status !== 'submitted'"
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.employee_interview?.id
              },
            }"
            variant="outlined"
            class="mt-4 sm:!mx-0 sm:!mr-4  normal-case"
            color="primary"
            data-testid="viewEmployeeAnswers"
          >
            {{ $t("View interviewee's answers") }}
          </v-btn>

          <v-btn
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.manager_interview?.id
              },
            }"
            variant="outlined"
            class="mt-4 sm:!mr-4 normal-case"
            color="primary"
            data-testid="viewAnswersBtn"
          >
            {{ $t(getInterviewButtonText(interview?.interview_set?.employee_interview?.status,
                                         interview?.interview_set?.manager_interview?.status,
                                         interview?.interview_set?.crossed_interview?.status)) }}
          </v-btn>
        </div>

        <div
          v-else-if="interview?.interview_set?.employee_interview === null"
          class="flex flex-col md:flex-row md:self-end mb-4 md:mb-6"
        >
          <v-btn
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.manager_interview?.id
              },
            }"
            variant="outlined"
            class="mt-4 sm:!mr-4 normal-case"
            color="primary"
            data-testid="viewAnswersBtn"
          >
            {{ $t("View my answers") }}
          </v-btn>
        </div>

        <div
          v-else
          class="flex flex-col md:flex-row md:self-end mb-4 md:mb-6"
        >
          <v-btn
            v-if="interview?.interview_set?.employee_interview?.status"
            :to="{
              name: 'interview_answer',
              params: {
                id: interview?.interview_set?.employee_interview?.id
              },
            }"
            variant="outlined"
            :disabled="interview?.interview_set?.employee_interview?.status === 'not_started'"
            class="mt-4 sm:!mx-0  normal-case"
            :color="interview?.interview_set?.employee_interview?.status !== 'not_started' ? 'primary' : 'darkGrey'"
            data-testid="viewEmployeeAnswers"
          >
            {{ $t("View interviewee's answers") }}
          </v-btn>
        </div>

        <v-divider
          v-if="index + 1 !== campaignInterviews.length"
          class="border-opacity-100 ml-4 mr-4 my-8 md:mr-6 md:ml-0"
        />
      </div>
    </div>
  </v-card>
</template>

<script setup>
import {capitalize} from "vue";
import { useCampaignStore } from "@/store/campaign.js";
import InterviewStatusChip from "../InterviewStatusChip.vue";
import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
import axiosService from "@/tools/axios-service.js";
import { usePopUpStore } from "@/store/pop-up.js";
import i18n from "@/plugins/i18n.js";
import { useMyTeamInterviewsStore } from "@/store/my-team-interviews.js";
import { storeToRefs } from "pinia";
import useCampaigns from "@/tools/useCampaigns.js";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
import { InterviewOneToOneLabels, InterviewStatuses } from '@/constants/types';

// Props
const props = defineProps({
  campaignId: {type: Number, required: true},
  campaignTitle: {type: String, required: true},
  campaignDeadline: {type: String, required: true},
  campaignType: {type: String, required: true},
  campaignInterviews: {type: Array, required: true}
})

const emit = defineEmits(['update-card'])

const { goToPath } = useBreadcrumbsStore()
const {openPopUp, closePopUp} = usePopUpStore();
const { fetchCampaign } = useCampaignStore();
const { getBgByCampaignStatus, getColorByCampaignStatus } = useCampaigns();
const { resetStates, fetchMyTeamInterviews, fetchMyTeamInterviewsToDo, fetchMyTeamInterviewsDone } = useMyTeamInterviewsStore();

const { campaign } = storeToRefs(useCampaignStore());
const getInterviewButtonText = (employee, manager, crossed) => {
  if (manager === 'not_started') {
    return "Start my interview"
  }
  else if (manager === 'in_progress') {
    return "Continue my interview"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'not_started') {
    return "Start Cross Review"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'in_progress') {
    return "Continue Cross Review"
  }
  else if (employee === 'submitted' && manager === 'submitted' && crossed === 'submitted') {
    return "View Cross Review answers"
  }
  else if (manager === 'submitted') {
    return "View my answers"
  }
  else {
    return ""
  }
}

const sendInvitationEmail = async (emailType, employeeId) => {
  try {
    await axiosService.get(
    `/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email`,
    {
      params: {
        email_type: emailType,
        user_id: employeeId,
      },
    });
    if (emailType === "invite") {
      openPopUp({
        componentName: "pop-up-action-done",
        icon: "noto-incoming-envelope",
        title: i18n.global.t(`Invitation is on its way !`),
        description: "The employee will receive an email in a few moments.",
        textConfirm: "Great !",
        appName: "Interviews",
        confirm() {
          closePopUp();
        },
      });
    } else {
      openPopUp({
        componentName: "pop-up-action-done",
        icon: "noto-bell",
        title: i18n.global.t(`Reminder is on its way !`),
        description: "The employee will receive an email in a few moments.",
        textConfirm: "Great !",
        appName: "Interviews",
        confirm() {
          closePopUp();
        },
      });
    }
  } catch (error) {
    console.log('send invitation error', error)
  }
};

const openSetAnotherInterviewer = (employeeId) => {
  openPopUp({
    componentName: "pop-up-set-another-interviewer",
    title: campaign?.campaign_type === 'OneToOne'
      ? i18n.global.t('Select another interviewer')
      : i18n.global.t('Select another person in charge'),
    icon: 'ic-baseline-person',campaignId: props.campaignId,
    employeeId: employeeId,
    callback: () => {
      resetStates()
      fetchMyTeamInterviews()
      fetchMyTeamInterviewsToDo()
      fetchMyTeamInterviewsDone()
    },
  });
};

const planMeeting = (cross) => {
  openPopUp({
    open: true,
    componentName: "pop-up-shift-cross-interview-date",
    title: i18n.global.t('Schedule a meeting'),
  icon: 'material-symbols:today',campaignId: props.campaignId,
    crossId: cross.id,
    startDate: cross.startDate,
    endDate: cross.endDate,
    date: cross.date,
    callback: () => {
      fetchCampaign(props.campaignId);
    },
  });
};
</script>
