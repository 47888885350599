<template>
  <div>
    <div class="elevation-2 rounded-xl p-6 flex flex-col gap-4">
      <div class="p-0 font-normal text-base">
        {{ $t("Choose how you want to display template(s)") }}
      </div>
      <div
        v-if="campaignDraft.campaign_type === 'Survey'"
        class="text-disabled"
      >
        {{
          $t("All participants will have this template for the survey")
        }}
      </div>
      <div class="flex flex-col gap-6">
        <v-item-group
          v-if="campaignDraft.campaign_type !== 'Survey'"
          class="p-0"
          :model-value="
            templatesSelectionMethodList.findIndex(
              (item) => item.value === campaignDraft.templates_selection_method
            )
          "
          mandatory
          @update:model-value="
            campaignDraft.templates_selection_method =
              templatesSelectionMethodList[$event].value
          "
        >
          <v-container
            class="grid grid-rows-1 grid-cols-2 gap-x-4 p-0"
          >
            <v-item
              v-for="(item, idx) in templatesSelectionMethodList"
              :key="idx"
              v-slot="{ isSelected, toggle }"
            >
              <bkt-radio-text-icon-button
                :title="item.title"
                :description="item.subtitle"
                :condition="isSelected"
                :on-click="toggle"
                :icon="item.icon"
              />
            </v-item>
          </v-container>
        </v-item-group>
        <v-window v-model="campaignDraft.templates_selection_method">
          <v-window-item
            v-for="(item, idx) in templatesSelectionMethodList"
            :key="idx"
            :value="item.value"
            class="flex flex-col gap-6"
          >
            <component
              :is="item.component"
              class="mt-1"
            />
          </v-window-item>
        </v-window>
      </div>
      <div class="flex">
        <v-spacer />
        <v-btn
          :disabled="
            !campaignDraft.default_template_id ||
              (campaignDraft.templates_selection_method === 'multiple' &&
                !campaignDraft.multi_templates_tag_category_id)
          "
          :loading="campaignDraftLoading"
          class="bkt-bg-blue-important bkt-white"
          color="primary"
          variant="elevated"
          @click="next"
        >
          <span
            class="normal-case text-sm flex items-center gap-2"
          >
            {{ $t("Next") }}
            <Icon
              :icon="'material-symbols:arrow-forward-rounded'"
              class="h-4 w-4"
            />
          </span>
        </v-btn>
      </div>
      <v-dialog
        v-model="dialog"
        class="flex"
      >
        <v-card class="sm:w-2/5 mx-auto rounded-lg p-4">
          <v-card-title class="font-bold text-center">
            {{ $t("Some tags have no template assigned.") }}
          </v-card-title>
          <v-card-text class="text-center">
            {{
              $t(
                "If you continue, the default template will be assigned to those tags"
              )
            }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">
              {{ $t("Assign templates") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="submit"
            >
              {{ $t("Continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import TemplatesSingle from "@/components/interviewApp/campaignDraft/templates/TemplatesSingle.vue";
import TemplatesMultiple from "@/components/interviewApp/campaignDraft/templates/TemplatesMultiple.vue";
import { useSnackbar } from "@/store/snackbar.js";
import { useI18n } from "vue-i18n";
import { Icon } from "@iconify/vue";
import BktRadioTextIconButton from "@/components/button/BktRadioTextIconButton.vue";

export default {
  components: {
    BktRadioTextIconButton,
    Icon,
    TemplatesSingle,
    TemplatesMultiple,
  },
  setup() {
    const { t } = useI18n();
    const { campaignDraft, loading: campaignDraftLoading } = storeToRefs(
      useCampaignDraftStore()
    );
    const { fetchParticipantTags } = useCampaignDraftStore();

    const templatesSelectionMethodList = [
      {
        icon: "noto:clipboard",
        title: t("Single template"),
        subtitle: t("Every participants have the same template."),
        value: "single",
        component: "templates-single",
      },
      {
        icon: "noto:bookmark-tabs",
        title: t("Multiple templates"),
        subtitle: t(
          "Define dynamic templates depending on participants informations"
        ),
        value: "multiple",
        component: "templates-multiple",
      },
    ];

    if (campaignDraft.value.multi_templates_tag_category_id) {
      fetchParticipantTags(campaignDraft.value.multi_templates_tag_category_id);
    }

    return {
      campaignDraft,
      campaignDraftLoading,
      templatesSelectionMethodList,
    };
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    next() {
      const { campaignDraft, participantTags } = storeToRefs(
        useCampaignDraftStore()
      );
      const tagsFilled = campaignDraft.value.multi_templates_tags_ids.filter(
        (item) => item.form_id
      );

      if (
        campaignDraft.value.templates_selection_method === "multiple" &&
        participantTags.value.length !== tagsFilled.length
      ) {
        this.dialog = true;
      } else {
        this.submit();
      }
    },
    async submit() {
      const { updateCampaignDraft } = useCampaignDraftStore();
      const snackbar = useSnackbar();

      const { campaignDraft } = storeToRefs(useCampaignDraftStore());

      this.dialog = false;
      try {
        await updateCampaignDraft({
          templates_selection_method:
            campaignDraft.value.templates_selection_method,
          default_template_id: campaignDraft.value.default_template_id,
          multi_templates_tag_category_id:
            campaignDraft.value.multi_templates_tag_category_id,
          multi_templates_tags_ids:
            campaignDraft.value.multi_templates_tags_ids,
        });
        this.$emit("next");
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(', '))
            .checkStatus();
        } else {
          snackbar
            .setStatus("error", "Our server is experiencing some problems")
            .checkStatus();
        }
      }
    },
  },
};
</script>