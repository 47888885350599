<template>
  <div
    class="w-full flex items-center self-stretch gap-3 p-4 rounded-[12px] border border-darkGrey relative cursor-pointer"
  >
    <!-- Options button -->
    <div class="absolute top-4 right-3 ">
      <svn-dots-button
        circle
        button-size="sm"
        variant="hoverNoPrimary"
      >
        <template #items>
          <v-list-item
            value="edit_content"
            @click="editContent(content?.content_type)"
          >
            {{ content?.content_type === 'Learn::Module' ? $t('Edit module') : $t('Edit playlist') }}
          </v-list-item>
  
          <v-list-item
            value="remove_from_training"
            class="text-negativeRed"
            @click="removeFromTraining(content?.content_type)"
          >
            {{ $t('Remove from training') }}
          </v-list-item>
        </template>
      </svn-dots-button>
    </div>

    <!-- Card image -->
    <img
      v-if="content?.content_type === 'Learn::Module'"
      :src="content?.cover_url?.['500']"
      class="rounded-[5px] sm:!rounded-[4px] w-[140px] h-[110px]"
      draggable="false"
      style="object-fit: cover;"
    >

    <!-- Playlists images -->
    <div
      v-else-if="content?.content_type === 'Learn::Playlist'"
      class="flex flex-col justify-center items-center h-[110px] w-[140px] bg-cover rounded-[5px]"
      :class="content?.cover_urls?.length === 0 ? 'bg-lightGrey' : ''"
      :style="{'background-image': content?.cover_urls?.length === 1 ?
        `url('${content?.cover_urls?.[0]?.['500']}')` : ''}"
    >
      <!-- 2 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length === 2"
        class="w-full h-full flex flex-row rounded-[4px] overflow-hidden"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
        />
      </div>

      <!-- 3 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length === 3"
        class="w-full h-full flex flex-col rounded-[4px] overflow-hidden"
      >
        <div class="flex w-full h-1/2">
          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
          />

          <div
            class="flex flex-col justify-center items-center w-1/2 h-full bg-cover"
            :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
          />
        </div>

        <div
          class="flex flex-col justify-center items-center w-full h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[2]?.['500']}')`}"
        />
      </div>

      <!-- At least 4 modules in the playlist -->
      <div
        v-if="content?.cover_urls?.length >= 4"
        class="w-full h-full flex flex-wrap rounded-[4px] overflow-hidden"
      >
        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[0]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[1]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover"
          :style="{'background-image': `url('${content?.cover_urls?.[2]?.['500']}')`}"
        />

        <div
          class="flex flex-col justify-center items-center w-1/2 h-1/2 bg-cover relative bg-[50%] grayscale"
          :style="{'background-image': `url('${content?.cover_urls?.[3]?.['500']}')`}"
        >
          <div
            v-if="content?.cover_urls?.length > 4"
            class="absolute left-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-[0.5]"
          />

          <p
            v-if="content?.cover_urls?.length > 4"
            class="!text-[#FFFFFF] text-base font-medium z-[9999]"
          >
            +{{ content?.cover_urls?.length - 3 }}
          </p>
        </div>
      </div>

      <!-- No module in the playlist -->
      <div v-if="content?.cover_urls?.length === 0">
        <Icon
          icon="ic:outline-play-circle"
          height="48"
          width="48"
          class="text-middleGrey"
        />
      </div>
    </div>

    <div class="flex flex-col items-start gap-2 flex-1">
      <!-- Module title and Icon -->
      <div class="flex items-start gap-2 self-stretch">
        <div class="flex py-1">
          <!-- Icon -->
          <Icon
            :icon="content?.content_type === 'Learn::Module' ? 'mdi:puzzle' : 'ic:outline-play-circle'"
            height="16"
            width="16"
            class="text-darkGrey"
          />
        </div>

        <!-- Title -->
        <p class="flex-1 line-clamp-2 text-fakeBlack text-sm font-medium sm:!line-clamp-1">
          {{ content?.title }}
        </p>
      </div>

      <!-- Modules count (if content is a playlist) -->
      <div v-if="content?.content_type === 'Learn::Playlist'">
        <p class="text-darkGrey text-sm font-medium">
          {{ content?.cover_urls?.length }}
          {{ content?.cover_urls?.length <= 1 ? $t('module') : $t('modules') }}
        </p>
      </div>

      <!-- Module duration -->
      <div
        v-if="content?.content_type === 'Learn::Module'"
        class="flex items-start self-stretch"
      >
        <div class="flex items-center gap-1">
          <div class="flex items-center">
            <Icon
              icon="mdi:clock-outline"
              height="16"
              width="16"
              class="text-darkGrey"
            />
          </div>

          <p class="text-darkGrey text-sm font-medium">
            {{ content?.duration }}
          </p>
        </div>
      </div>

      <!-- Module Themes -->
      <div
        v-if="content?.content_type === 'Learn::Module'"
        class="flex flex-col gap-2 sm:!flex-row sm:!w-full sm:!gap-0 sm:!justify-between sm:!items-center sm:mt-4"
      >
        <div class="flex items-center gap-2 self-stretch">
          <div class="flex items-center gap-2 flex-1">
            <!-- There is only 1 themes or less -->
            <div
              v-for="(theme) in slicedThemesList"
              :key="theme"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
            >
              <p
                class="flex text-xs font-normal items-center text-fakeBlack"
              >
                {{ theme?.name }}
              </p>
            </div>

            <!-- If there is more than 1 themes -->
            <div
              v-if="content?.themeList?.length > 1"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
            >
              <p
                class="flex text-xs font-normal items-center text-fakeBlack"
              >
                +{{ content?.themeList?.length - slicedThemesList?.length }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  content: { type: Object, default: null },
})

const emit = defineEmits(['edit-content', 'remove-from-training'])

const editContent = (type) => {
  emit('edit-content', type)
}

const removeFromTraining = () => {
  emit('remove-from-training')
}

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props.content?.themes?.slice(0, 1)
  }
  else {
    return props.content?.themes?.slice(0, 2)
  }
})
</script>