<template>
  <bkt-index-skeleton body-class="bkt-bg-white box-shadow-none !px-0">
    <template #title>
      <div
        v-if="campaign"
        class="flex items-center text-sm"
      >
        <bkt-button
          :href="'/interviews/my_team_interviews'"
          class=""
        >
          <span class="normal-case text-sm font-medium bkt-fakeblack-grey">
            {{ $t("My team interview") }}
          </span>
        </bkt-button>
        <Icon
          class="iconify bkt-light-grey font-semibold text-xs"
          icon="akar-icons:chevron-right"
        />
        <span class="font-medium bkt-fakeblack-grey p-3">
          {{ campaign?.title }}
        </span>
        <campaign-status-chip :status="campaign.status" />
      </div>
    </template>

    <template
      v-if="campaign"
      #create-index
    >
      <div class="flex items-center text-sm h-full">
        <p class="bkt-light-grey">
          {{ $t("Deadline") }} : {{ campaign.deadline }}
        </p>
      </div>
    </template>

    <template #body>
      <BktSurveyAnswers
        v-if="campaign"
        class="w-full"
        :campaign="campaign"
      />
      <div v-else class="flex justify-center h-[30vh] items-center w-full">
        <svn-loader loading-size="lg" />
      </div>
    </template>
  </bkt-index-skeleton>
</template>

<script setup>
import BktSurveyAnswers from "@/components/BktSurveyAnswers.vue";
import {storeToRefs} from "pinia";
import {useCampaignStore} from "@/store/campaign.js";
import {onMounted, onUnmounted} from "vue";
import {useRoute} from "vue-router";
import CampaignStatusChip from "@/components/campaign/CampaignStatusChip.vue";
import BktButton from "@/components/button/BktButton.vue";
import {Icon} from "@iconify/vue";
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
const { campaign } = storeToRefs(useCampaignStore());
const { fetchCampaign, resetStates } = useCampaignStore();

const route = useRoute()

onMounted(() => {
  fetchCampaign(route.params.id);
})

onUnmounted(() => {
  resetStates()
})

</script>

<style scoped>

</style>
