<template>
  <div class="flex flex-col items-center space-y-4">
    <p class="text-3xl">
      😣
    </p>
    <p class="text-md fakeBlack font-medium text-center">
      {{
        $t("It seems we failed to load this section, try to reload this page by clicking the button below.")
      }}
    </p>
    <v-btn
      color="primary"
      class="normal-case px-3"
      prepend-icon="mdi-reload"
      @click="reload"
    >
      Reload
    </v-btn>
    <p class="text-sm text-center">
      If the issue is persistent, contact our support at:<br><span class="font-medium">no-reply@byseven.co</span>
    </p>
  </div>
</template>

<script setup>
  const reload = () => {
    window.location.reload()
  }
</script>

<style scoped>

</style>