import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useLearnModuleStore = defineStore({
  id: "learn-module",
  state: () => ({
    id: null,
    editorContentId: null,
    learnModuleReactions: null,
    learnModule: {},
    learnModules: [],
    learnTrashModules: [],
    editorContentData: {
      blocks: [],
    },
    learnModulesToDiscover: [],
    learnModulesDraft: [],
    inputs: [],
    inputsFaceToFace: [],
    submissionId: null,
    piecesQuizId: null,
    learnResponsesCheckboxes: [],
    learnResponsesAnswers: [],
    modulesByUser: [],
    submission: {},
    learnApprovalInput: {},
    submissionStatus: "",
    learnModuleSubmissionUpdatedAt: '',
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async resetStates() {
      this.learnModuleReactions = []
      this.learnModulesToDiscover = []
      this.editorContentData = []
      this.learnModules = []
      this.learnResponsesCheckboxes = []
      this.learnResponsesAnswers = []
      this.inputs = []
      this.inputsFaceToFace = []
      this.learnModules = []
      this.learnModulesDraft = []
      this.learnModule = {}
      this.submission = {}
      this.learnApprovalInput = {}
      this.submissionStatus = ""
      this.learnModuleSubmissionUpdatedAt = ""
      this.submissionId = null
      this.piecesQuizId = null
      this.editorContentId = null
    },
    async fetchModule(id) {
      this.resetStates()
      let res = null
      try {
        res = await axiosService.get(`/api/v1/learn/modules/${id}`)
      } catch (error) {
        if (error?.response?.status === 403) { // Pundit not authorize
          await this.router.push({name: 'not-auth'})
        }
      }
      this.learnModule = res.data?.learn_module
      this.submissionId = this.learnModule.submission?.id
      this.submission = this.learnModule.submission
      this.submissionStatus = this.learnModule.submission_status
      this.learnModuleSubmissionUpdatedAt = this.learnModule.submission_updated_at
      this.editorContentId = this.learnModule.editor_content_id
      if (this.learnModule.has_learn_pieces_quiz) {
        this.piecesQuizId = this.learnModule.learn_pieces_quiz.id
      }

      if (this.learnModule.has_learn_pieces_approval) {
        this.learnApprovalInput = this.learnModule?.learn_pieces_approval?.learn_inputs_simple_text
      }
      await this.userReactionWithIcons()
    },
    async fetchModulesOnlyWithPages(page = 1) {
      const res = await axiosService.get(`api/v1/learn/modules`, {
        params: {
          "page[number]": page,
        }
      })
      //this.learnModules = res.data.learn_modules
      this.learnModulesToDiscover = [...this.learnModules].sort((a, b) => b.updated_at > a.updated_at).splice(0, 4)
      return res
    },
    async fetchModules(text = '', themeIds = [], favorite = false, sortBy = 'desc-publication') {
      const res = await axiosService.get(`api/v1/learn/modules`, {
        params: {
          text: text,
          theme_ids: themeIds,
          favoritable_modules: favorite,
          sort_by: sortBy,
        }
      })
      this.learnModules = res.data.learn_modules
      this.learnModulesToDiscover = [...this.learnModules].sort((a, b) => b.updated_at > a.updated_at).splice(0, 4)
      return res
    },
    async fetchModulesDraft(text = '', themeIds = [], page = 1) {
      const res = await axiosService.get(`api/v1/learn/modules/drafts`, {
        params: {
          "page[number]": page,
          text: text,
          theme_ids: themeIds,
        }
      })
      this.learnModulesDraft = res.data.learn_modules
      return res
    },
    async fetchTrashModules(text = '') {
      const res = await axiosService.get(`api/v1/learn/modules/trashes`, {
        params: {
          text: text,
        }
      })

      this.learnTrashModules = res.data.learn_modules
    },

    async restoreModule(id) {
      await this.updateEntity(`api/v1/learn/modules/${id}/restore`)
      this.learnModulesDraft.push(this.learnTrashModules.find(trash => trash?.id === id))
      this.removeModuleFromTrashes(id)
    },
    async deleteModule(id) {
      await this.deleteEntity(`api/v1/learn/modules/${id}`)

      const trashModule = this.learnModules.find(mod => mod?.id === id)
      if (trashModule) {
        trashModule.deleted_at = new Date()
        this.learnTrashModules.push(trashModule)
      }

      this.learnModules = this.learnModules.filter(mod => mod?.id !== id)

    },
    async deleteModulePermanently(id) {
      await this.deleteEntity(`api/v1/learn/modules/${id}/destroy_permanently`)
      this.removeModuleFromTrashes(id)
    },
    removeModuleFromTrashes (id) {
      this.learnTrashModules = this.learnTrashModules.filter(trash => trash?.id !== id)
    },
    async getModulesByUser(userId, searchText = "", page = 1) {
      this.loading = true

      try {
        const res = await axiosService.get(`/api/v1/users/${userId}/learnings`, {
          params: {
            learn: "modules",
            search: searchText,
            "page[number]": page
          }
        })

        this.pagination = res.data.meta.pagination

        this.modulesByUser = res.data.learn_modules
      } catch (error) {
        console.log(error);
      } finally {

        this.loading = false
      }
    },
    async createModule(title, duration) {
      return await axiosService.post('api/v1/learn/modules', {
        title: title,
        duration: duration,
      })
    },
    async getAdditionalModulesByUser(userId, searchText = "", page = 1) {
      try {
        const res = await axiosService.get(`/api/v1/users/${userId}/learnings`, {
          params: {
            learn: "modules",
            search: searchText,
            "page[number]": page
          }
        })

        this.pagination = res.data.meta.pagination

        if (res.data.learn_modules.length) {
          this.modulesByUser = [...this.modulesByUser, ...res.data.learn_modules]
        }

      } catch (error) {
        console.log(error);
      }
    },
    async addThemeToModule(id, name) {
      const res = await axiosService.put(`api/v1/learn/modules/${id}/themes/add_module`, {
        name: name,
      })
      this.learnModule = res.data.learn_module
    },
    async changeSubmissionPieceType(type) {
      const res = await axiosService.put(`/api/v1/learn/modules/${this.learnModule.id}/piece`, {
        piece_type: type,
      });
      const data = {
        id: res?.data?.id,
        created_at: res?.data?.created_at,
        creator: res?.data?.creator,
        has_custom_inputs: res?.data?.has_custom_inputs,
      }
      this.learnModule.submission_type = res?.data?.type;
      this.learnModule.piece_id = res?.data?.id;
      if (type === 'Approval') {
        this.learn_pieces_approval = data;
        this.learnModule.learn_pieces_approval = res?.data;
        this.learnModule.learn_pieces_face_to_face_evaluation = {};
        this.learnModule.has_learn_pieces_approval = true;
        this.learnModule.learn_pieces_quiz = {};
        this.learnApprovalInput = res?.data?.learn_inputs_simple_text
      } else if (type === 'Quiz') {
        this.learnModule.approval = {};
        this.learnModule.learn_pieces_face_to_face_evaluation = {};
        this.learnModule.has_learn_pieces_approval = false;
        this.learnModule.learn_pieces_quiz = data;
        this.learnApprovalInput = null
      } else if (type === 'FaceToFaceEvaluation') {
        this.learnModule.approval = {};
        this.learnModule.learn_pieces_quiz = {};
        this.learnModule.has_learn_pieces_approval = false;
        this.learnModule.learn_pieces_face_to_face_evaluation = data;
        this.learnApprovalInput = null
      }
    },
    async fetchInputs(id) {
      const res = await axiosService.get(`/api/v1/learn/quizzes/${id}/inputs`)
      this.inputs = res.data.inputs

    },
    async fetchInputsFaceToFace(evaluationId) {
      const res = await axiosService.get(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/inputs`)
      this.inputsFaceToFace = res.data.inputs

    },
    async putEditorContent(editorId, data) {
      return await axiosService.put(`/api/v1/editor_contents/${editorId}/update_blocks`, { data })
    },
    async putEditorContentForInput(editorId, data) {
      const res = await this.putEditorContent(editorId, data)

      this.inputs.forEach((input) => {
        if (input?.editor_content?.id === editorId) {
          input.editor_content.blocks = res.data.blocks
        }
      })
    },
    async postInputs(quizId, input) {
      await axiosService.post(`/api/v1/learn/quizzes/${quizId}/inputs/checkbox_question`, input)
      await this.fetchInputs(quizId)
    },
    async postInputsFaceToFace(evaluationId, input) {
      await axiosService.post(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/inputs`, input)
      await this.fetchInputsFaceToFace(evaluationId)
    },
    async postParagraph(quizId, input) {
      await axiosService.post(`/api/v1/learn/quizzes/${quizId}/inputs/paragraph`, input)
      await this.fetchInputs(quizId)
    },
    async postParagraphFaceToFace(evaluationId, input) {
      await axiosService.post(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/inputs/create_paragraph`, input)
      await this.fetchInputsFaceToFace(evaluationId)
    },
    async updateInputs(quizId, id, input) {
      await axiosService.put(`/api/v1/learn/quizzes/${quizId}/inputs/${id}`, input)
    },
    async updateInputsFaceToFace(evaluationId, id, input) {
      await axiosService.put(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/inputs/${id}`, input)
    },
    async deleteInputQuestion(quizId, inputId) {
      await axiosService.delete(`/api/v1/learn/quizzes/${quizId}/inputs/${inputId}`)
      await this.fetchInputs(quizId)
    },
    async deleteOpenQuestion(evaluationId, inputId) {
      await axiosService.delete(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/inputs/${inputId}`)
      await this.fetchInputsFaceToFace(evaluationId)
    },
    async getEditorContent(editorId) {
      const res = await axiosService.get(`/api/v1/editor_contents/${editorId}`)
      this.fillEditorContentData(res)
    },
    fillEditorContentData(res) {
      this.editorContentData.blocks = res?.data?.blocks
    },
    async createSubmission(quizId) {
      const res = await axiosService.post(`/api/v1/learn/quizzes/${quizId}/submission`)
      this.submission = res.data;
      this.submissionId = this.submission.id;
      this.learnResponsesCheckboxes = this.submission.learn_responses_checkboxes;
    },
    async createSubmissionFaceToFace(evaluationId) {
      const res = await axiosService.post(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/submission`)
      this.submission = res.data;
      this.learnModule.submission = res.data
      this.submissionId = this.submission.id;
      this.learnResponsesAnswers = this.submission.learn_responses_answers;
    },
    async getSubmission(quizId) {
      try {
        const res = await axiosService.get(`/api/v1/learn/quizzes/${quizId}/submission`)
        this.submission = res.data;
        this.submissionId = this.submission.id;
        this.learnResponsesCheckboxes = this.submission.learn_responses_checkboxes;
      } catch (e) {
        this.createSubmission(quizId)
      }
    },
    async getSubmissionFaceToFace(evaluationId) {
      try {
        const res = await axiosService.get(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/submission`)
        this.submission = res.data;
        this.submissionId = this.submission.id;
        this.learnResponsesAnswers = this.submission.learn_responses_answers;
      } catch (e) {
        this.createSubmissionFaceToFace(evaluationId);
      }
    },
    async validateSubmission(quizId) {
      const res = await axiosService.post(`/api/v1/learn/quizzes/${quizId}/submission/verify`)
      this.submission = res.data;
      this.submissionId = this.submission.id;
      this.learnResponsesCheckboxes = this.submission.learn_responses_checkboxes;
      this.learnModuleSubmissionUpdatedAt = this.submission.updated_at
      this.submissionStatus = this.submission.status
    },
    async validateSubmissionFaceToFace(evaluationId, params) {
      const res = await axiosService.post(`/api/v1/learn/face_to_face_evaluations/${evaluationId}/submission/verify`, params)
      this.submission = res.data;
      this.submissionId = this.submission.id;
      this.learnResponsesAnswers = this.submission.learn_responses_answers;
      this.learnModuleSubmissionUpdatedAt = this.submission.updated_at
      this.submissionStatus = this.submission.status
    },
    async removeThemeFromModule(id, name) {
      const res = await axiosService.put(`api/v1/learn/modules/${id}/themes/remove_module`, {
        name: name,
      })
      this.learnModule = res.data.learn_module
    },
    async updateModuleTitle(id, title) {
      const res = await axiosService.put(`api/v1/learn/modules/${id}`, {
        title: title,
      })
      this.learnModule = res.data
    },
    async updateModule({id, title, duration, cover_offset_left, cover_offset_top}) {

      const res = await axiosService.put(`api/v1/learn/modules/${id}`, {
        title,
        duration,
        cover_offset_left,
        cover_offset_top
      })
      this.learnModule = res.data
    },
    async updateModuleImage(id, cover) {
      const headers = { "Content-Type": "multipart/form-data" };

      const res = await axiosService.put(`api/v1/learn/modules/${id}`, {
        cover: cover,
      }, { headers })
      this.learnModule = res.data
    },
    async publishModule(moduleId, trainingId = null) {
      const res = await axiosService.put(`api/v1/learn/modules/${moduleId}/publish`,
          {
            training_id: trainingId
          }
      )

      this.learnModule = res.data.learn_module
    },
    async duplicateModule(moduleId, title) {
      const res = await axiosService.post(`api/v1/learn/modules/${moduleId}/duplicate_module`)
      this.updateModule({
        id: res.data.learn_module.id,
        title: title
      })
      return res.data.learn_module
    },
    async toggleFavorite(id, tab) {
      const res = await axiosService.post(`api/v1/learn/modules/${id}/favorite`)
      if (tab === "draft") {
        if (this.learnModulesDraft.length) {
          this.learnModulesDraft?.forEach(module => {
            if (module?.id === id) {
              module.connected_user_favorited = !module.connected_user_favorited
            }
          })
        } else {
          this.learnModuleDraft.connected_user_favorited = res.data.learn_module.connected_user_favorited
        }
      }
      else {
        if (this.learnModules.length) {
          this.learnModules?.forEach(module => {
            if (module?.id === id) {
              module.connected_user_favorited = !module.connected_user_favorited
            }
          })
        } else {
          this.learnModule.connected_user_favorited = res.data.learn_module.connected_user_favorited
        }
      }
      return res?.data?.learn_module;
    },
    async fetchModuleReactions(moduleId) {
      const res = await axiosService.get(`/api/v1/learn/modules/${moduleId}/messages`)
      this.learnModuleReactions = res.data?.messages
    },
    async addReaction(moduleId, type, text = null, parentId = null) {
      await axiosService.post(`/api/v1/learn/modules/${moduleId}/messages`, {
        type: type,
        text: text,
        parent_id: parentId,
      })
      const res = await axiosService.get(`/api/v1/learn/modules/${moduleId}`)
      this.learnModule = res.data?.learn_module
      await this.userReactionWithIcons()
      this.fetchModuleReactions(moduleId)
    },
    async toggleReaction(moduleId, type, text = '') {
      await axiosService.put(`/api/v1/learn/modules/${moduleId}/reactions/toggle_reaction`, {
        type: type,
        text: text,
      })
      const res = await axiosService.get(`/api/v1/learn/modules/${moduleId}`)
      this.learnModule = res.data?.learn_module
      this.fetchModuleReactions(moduleId)
      await this.userReactionWithIcons()
    },
    async updateReaction(moduleId, type, text, reactionId) {
      await axiosService.put(`/api/v1/learn/modules/${moduleId}/messages/${reactionId}`, {
        reaction_type: type,
        text: text,
        parent_id: reactionId,
      })
      this.fetchModuleReactions(moduleId)
    },
    async deleteReaction(moduleId, reactionId) {
      await axiosService.delete(`api/v1/learn/modules/${moduleId}/messages/${reactionId}`)
      const res = await axiosService.get(`/api/v1/learn/modules/${moduleId}`)
      this.learnModule = res.data?.learn_module
      this.fetchModuleReactions(moduleId)
      await this.userReactionWithIcons()
    },
    async userReactionWithIcons() {
      this.learnModule.user_reactions = this.learnModule?.user_reactions?.map(reaction => {
        if (reaction?.type === 'like') {
          reaction.icon = 'https://lottie.host/?file=1d730887-ff07-4bcb-916a-1c47a1fc2191/tBJxPeERxp.json'
          reaction.name = 'I like'
          reaction.bind = false
        }
        else if (reaction?.type === 'recommend') {
          reaction.icon = 'https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json'
          reaction.name = 'I recommend'
          reaction.bind = false
        }
        else if (reaction?.type === 'question') {
          reaction.icon = 'https://lottie.host/?file=e4cd4916-3bb6-418f-acec-0d4fa627bdcf/SXP8YkJQSb.json'
          reaction.name = 'I have a question'
          reaction.bind = true
        }
        else if (reaction?.type === 'remark') {
          reaction.icon = 'https://lottie.host/?file=ef25fb66-24e2-445b-a9cb-9ed22534d04e/fDPUISSyuB.json'
          reaction.name = 'I have a remark'
          reaction.bind = true
        }
        return reaction
      })
    },
    async removeModuleElement(courseId, inputId) {
      await axiosService.delete(`/api/v1/learn/courses/${courseId}/inputs/${inputId}`);
      await this.fetchModule(this.id)
    },
    async reportIssue(message) {
      await axiosService.post(`/api/v1/learn/modules/${this.learnModule.id}/report_issue`, {
        message: message
      })
    },
    async approvalSubmission() {
      const submission = await axiosService.post(`/api/v1/learn/approvals/${this.learnModule.learn_pieces_approval?.id}/submission`, {
        "learned": true
      })
      this.learnModule.submission = submission.data
      this.learnModuleSubmissionUpdatedAt = submission.data.updated_at
      this.submission = submission.data
    },
    async updateApprovalSubmissionDate() {
      const submission = await axiosService.put(`/api/v1/learn/approvals/${this.learnModule.learn_pieces_approval?.id}/submission`)
      this.learnModule.submission = submission.data
      this.learnModuleSubmissionUpdatedAt = submission.data.updated_at
      this.submission = submission.data
    },
    async updateApprovalInput(data) {
      await axiosService.put(`/api/v1/learn/approvals/${this.learnModule.learn_pieces_approval?.id}/input`, data)
    }
  },
});
