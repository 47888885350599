<template>
  <v-dialog
    v-model="dialogAddToPlaylist"
    :fullscreen="isMobile ? true : false"
    :content-class="isMobile ? 'bg-white h-full' : '!rounded-[12px] bg-white'"
    :width="580"
  >
    <div
      class="flex flex-col justify-start items-center gap-4 w-full h-full relative pb-4 sm:!pb-0"
    >
      <!-- Close button -->
      <div
        class="absolute top-6 right-6"
        @click="emit('close-dialog')"
      >
        <Icon
          class="text-darkGrey cursor-pointer"
          icon="mdi-close"
          height="24"
        />
      </div>

      <!-- Title -->
      <div class="flex flex-col justify-center items-center stretch-center px-6 pt-[32px] pb-6">
        <p class="text-xl font-medium text-fakeBlack">
          {{ $t('Add this module to playlists') }}
        </p>
      </div>

      <!-- Search bar -->
      <div class="w-full px-4">
        <v-text-field
          v-model="searchText"
          hide-details
          variant="outlined"
          :label="$t('Search')"
          prepend-inner-icon="mdi-magnify"
          density="comfortable"
          @input="search"
        />
      </div>

      <!-- Playlists list -->
      <div
        v-if="learnPlaylists?.length"
        class="w-full max-h-full pb-6 overflow-y-auto"
      >
        <div class="flex flex-col gap-1">
          <div
            v-for="(playlist, index) in learnPlaylists"
            :key="index"
            class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer h-[82px]"
            :class="playlist?.learn_module_ids.includes(moduleId) ?
              'bg-trainingOrangeSoft rounded-[8px]' : 'bg-white'"
            @click="toggledialogAddToPlaylist(playlist)"
          >
            <!-- Checkbox -->
            <div>
              <Icon
                :icon="playlist?.learn_module_ids.includes(moduleId) ?
                  'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                height="18"
                width="18"
                :color="playlist?.learn_module_ids.includes(moduleId) ?
                  '#EF8C64' : 'black'"
              />
            </div>

            <!-- Playlist Card -->
            <playlist-card-list-item :playlist="playlist" />
          </div>
        </div>
      </div>

      <!-- No playlists in list -->
      <div
        v-else
        class="h-full sm:!h-[300px] flex justify-center items-center"
      >
        <p class="text-middleGrey text-sm font-normal">
          {{ $t('No playlists to add for the moment') }}
        </p>
      </div>

      <!-- Validate button -->
      <!-- <div class="px-4 w-full">
        <v-btn
          variant="flat"
          class="w-full normal-case"
          color="primary"
          @click="addModuleToPlaylists(selectedPlaylists)"
        >
          <p class="text-white text-xs font-medium">
            {{ $t('Validate') }}
          </p>
        </v-btn>
      </div> -->
    </div>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import { useLearnPlaylistStore } from "@/store/learn-playlist";
import PlaylistCardListItem from "@/components/trainingApp/PlaylistCardListItem.vue";
import { storeToRefs } from "pinia";
import { debounce } from "lodash";

const props = defineProps({
  moduleId: { type: Number, default: null },
})

const emit = defineEmits(['close-dialog'])

const { isMobile } = storeToRefs(useMobileStore())

const { learnPlaylists } = storeToRefs(useLearnPlaylistStore())

const { fetchPlaylists, togglePlaylistInfos } = useLearnPlaylistStore()

const dialogAddToPlaylist = ref(false)

const searchText = ref('')

const snackbar = useSnackbar()

const toggledialogAddToPlaylist = debounce(async(playlist) => {
  try {
    await togglePlaylistInfos(playlist, props?.moduleId)
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error adding module to playlist(s)')
    snackbar.displaySnackBar()
  }
}, 250)

const search = async() => {
  try {
    await fetchPlaylists(searchText.value)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching playlists')
    snackbar.displaySnackBar()
  }
}

defineExpose({
  dialogAddToPlaylist
})
</script>
