import {defineStore} from "pinia";
import {ref} from "vue";
import useTools from "@/tools/useTools.js";

export const useToastStore = defineStore('toast', () => {
  const toasts = ref([])
  const forceChange = ref([false, false, false])

  /**
   * Add a toast to toasts list
   * @param {('info'|'success'|'warning'|'error')} type
   * @param {String} title
   * @param {Html} body
   * @param {Boolean} progress
   * @param {?{name: String, link}} action
   * @param {String} notificationType
   * @param {String} icon
   * @param {String} badge
   */
  function addToast(type, title, body, progress = false, action = null, notificationType = "", icon = "", badge = "") {
    const id = useTools().generateId()
    toasts.value.push({
      id,
      type,
      title,
      body,
      progress,
      action,
      notificationType,
      icon,
      badge,
    })

    return id
  }

  /**
   * Remove a toast from the toasts list depending on the index
   * @param {Number} idx
   */
  function removeToast(idx) {
    toasts.value.splice(idx, 1)
  }

  /**
   * Close a toast depending on title
   * If the toast is being displayed, remove it force close it
   * Otherwise, juste remove it
   * @param {String} id
   */
  function closeToast(id) {
    if (!toasts.value) {
      return
    }
    for (let i = 0; i < 3; ++i) {
      if (toasts.value[i].id === id) {
        forceChange.value[i] = true
        return;
      }
    }
    removeToast(toasts.value.findIndex(toast => toast.id === id))
  }
  function resetToasts() {
    toasts.value = []
  }

  return {
    toasts,
    forceChange,
    addToast,
    removeToast,
    closeToast,
    resetToasts
  }
})