<template>
  <pop-up-skeleton>
    <template #title>
      <p class="text-fakeBlack text-xl font-medium">
        {{ $t(`Edit ${data?.targetField}`) }}
      </p>
    </template>

    <template #body>
      <div class="flex flex-col justify-start mb-8">
        <p class="text-fakeBlack text-start text-sm font-medium mb-2">
          {{ $t(`${data?.targetField}`) }}
        </p>

        <v-text-field
          v-if="data?.targetField === 'Title'"
          v-model="title"
          hide-details
          variant="outlined"
          color="fakeBlack"
          :label="$t(`New ${data?.targetField}`)"
        />

        <div
          v-else-if="data?.targetField === 'Description'"
        >
          <v-textarea
            v-model="description"
            hide-details
            :no-resize="true"
            rows="3"
            row-height="164"
            variant="outlined"
            color="fakeBlack"
            :label="$t(`New ${data?.targetField}`)"
          />
        </div>

        <div v-else-if="data?.targetField === 'Due date'">
          <svn-date-picker
            v-model="dueDate"
            :label="$t('Select a date')"
          />
        </div>
      </div>
    </template>

    <template #buttons>
      <div class="w-full flex flex-col justify-center items-center">
        <v-btn
          variant="outlined"
          class="normal-case text-white bg-primary font-normal w-full sm:w-1/3 text-xs"
          @click="saveNewValue(data?.targetField)"
        >
          {{ $t('Save') }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from '@/store/pop-up';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useSnackbar } from '@/store/snackbar';

const { data } = storeToRefs(usePopUpStore());
const { closePopUp, callback } = usePopUpStore();
const snackbar = useSnackbar();

const title = ref("");
const description = ref("");
const dueDate = ref("");

const saveNewValue = async(field) => {
  try {
    if (field === 'Title') {
      await axios.patch(`/api/v1/roadmaps/targets/${data?.value?.targetId}`, {
        title: title.value,
      })
    } else if (field === 'Description') {
      await axios.patch(`/api/v1/roadmaps/targets/${data?.value?.targetId}`, {
        description: description.value,
      })
    } else if (field === 'Due date') {
      await axios.patch(`/api/v1/roadmaps/targets/${data?.value?.targetId}`, {
        due_date: dueDate.value,
      })
    }
    snackbar.setMsg(`${field} has been updated!`);
    snackbar.setBgColor('fakeBlack');
    snackbar.displaySnackBar();
    closePopUp();
    callback();
  } catch (error) {
    snackbar.setMsg(`Error updating ${field}!`);
    snackbar.setBgColor('error');
    snackbar.displaySnackBar();
  }
}
</script>