<template>
  <div
    class="w-full lg:!w-1/3 flex flex-row justify-start items-center space-x-3
      sm:justify-start overflow-hidden"
  >
    <div>
      <svn-avatar
        v-if="type !== 'cross'"
        :avatar="person?.avatar?.['30']"
        :firstname="person?.fullname?.split(' ')?.[0]"
        :lastname="person?.fullname?.split(' ')?.[1]"
        size="md"
      />

      <div
        v-else
        class="flex w-[52px] -space-x-[14px]"
      >
        <svn-avatar
          :avatar="person?.avatar?.['30']"
          :firstname="person?.fullname?.split(' ')?.[0]"
          :lastname="person?.fullname?.split(' ')?.[1]"
          size="md"
        />

        <svn-avatar
          :avatar="otherPerson?.avatar?.['30']"
          :firstname="otherPerson?.fullname?.split(' ')?.[0]"
          :lastname="otherPerson?.fullname?.split(' ')?.[1]"
          size="md"
          class="!border !border-white"
        />
      </div>
    </div>
    <div
      class="w-full flex flex-col justify-start pb-1 sm:items-start"
    >
      <div
        class="w-full text-fakeBlack text-xs font-medium gap-[2px] flex flex-row
          sm:text-sm"
      >
        <svn-tooltip
          v-if="person.fullname !== 'Cross Review' && type !== 'cross'"
        >
          <template #activator-content>
            <p class="w-full truncate text-sm font-medium">
              {{ person.fullname }}
            </p>
          </template>

          <template #tooltip-content>
            {{ person.fullname }}
          </template>
        </svn-tooltip>
        <p
          v-else
          class="truncate text-sm font-medium"
        >
          {{ type === 'cross' ? 'Cross Review' : person.fullname }}
        </p>
        <div
          v-if="person.fullname !== 'Cross Review' && type !== 'cross'"
          class="flex"
        >
          <p
            class="text-darkGrey flex flex-row ml-0 pl-0 text-sm font-medium"
          >
            <span class="mr-1">-</span>{{ $t(capitalize(type)) }}
          </p>
        </div>
      </div>
      <InterviewStatusChip
        :status="status"
        :status-test-id="statusTestId"
        :text-statustest-id="textStatustestId"
      />
    </div>
  </div>
</template>

<script setup>
import {capitalize} from "vue";
import InterviewStatusChip from "@/components/interviewApp/InterviewStatusChip.vue";

// Props
const props = defineProps({
  person: { type: Object, default: () => {} },
  otherPerson: { type: Object, default: () => {} },
  status: { type: String, required: true },
  type: { type: String, required: true },
})

// Const
const statusTestId = `${props.type}-status`
const textStatustestId = `${props.type}-text-status`

</script>
