<template>
  <v-layout
    full-height
    style="position: fixed; width: 100%; height: calc(100% - 75px); z-index: 0"
  >
    <v-app-bar
      color="surface"
      elevation="4"
      prominent
      class="overflow-visible px-4 sm:px-8 py-0 sm:py-0.5"
    >
      <v-app-bar-nav-icon
        @click.stop="toggleDrawers"
      >
        <Icon
          :icon="isMobile ? 'ic-baseline-keyboard-double-arrow-right' : 'mdi:hamburger-menu'"
          color="darkGrey"
          width="24"
          height="24"
        />
      </v-app-bar-nav-icon>

      <div class="flex w-full" />

      <v-app-bar-nav-icon
        class="hidden sm:!block"
        @click.stop="historyTooltip = !historyTooltip"
      >
        <Icon
          icon="mdi-clock-outline"
          color="fakeBlack"
        />

        <v-dialog
          v-if="historyTooltip && !isMobile"
          v-model="historyTooltip"
          style="width: 480px; top: -90px;"
          :absolute="true"
          scrim="white"
          location="bottom"
        >
          <pop-up-history-updates
            :interview-id="Number(route?.params?.id)"
            @close-drawer="historyTooltip = !historyTooltip"
          />
        </v-dialog>
      </v-app-bar-nav-icon>

      <div
        v-if="!locked"
        class="px-3"
      >
        <span
          v-if="isInterviewLoading"
          class="text-mediumOrange"
        >
          {{ $t("Saving...") }}
        </span>
        <span
          v-else
          class="text-positiveGreen"
        >
          {{ $t("Saved") }}
        </span>
      </div>

      <bkt-download-button
        :title="'download pdf'"
        loading-text="Downloading PDF..."
        :loading="downloadLoading"
        :toast="toast"
        @on-click="clickDownload"
      />

      <v-app-bar-nav-icon
        icon="mdi-clock-outline"
        color="darkGrey"
        class="block sm:hidden"
        @click.stop="historyTooltip = !historyTooltip"
      />

      <div class="hidden relative sm:flex">
        <svn-tooltip
          v-if="interviewTouched"
          location="center"
          origin="top end"
        >
          <template #activator-content>
            <bkt-submit-interview-answer-button
              v-if="!locked && interviewForm && interviewAnswers && interviewKind"
              @submit-button-clicked="updateAnswersErrorState"
            />
          </template>

          <template #tooltip-content>
            <p>
              {{ $t('To submit the form, all required fields must be completed.') }}
            </p>
          </template>
        </svn-tooltip>

        <div v-else>
          <bkt-submit-interview-answer-button
            v-if="!locked && interviewForm && interviewAnswers && interviewKind"
            @submit-button-clicked="updateAnswersErrorState"
          />
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      :model-value="isLargeScreen ? drawerLargeOpen : drawer"
      width="400"
      disable-resize-watcher
      class="!h-auto"
    >
      <div class="w-full break-words">
        <div
          class="flex justify-center items-center gap-3 w-full text-center font-semibold text-gray-300 text-2xl p-5"
        >
          {{ $t("Chapters") }}
          <svn-tooltip
            location="bottom start"
            origin="top end"
          >
            <template #activator-content>
              <Icon
                icon="mdi:information-outline"
                class="text-gray-300 h-5 w-5"
                @mouseover="setTemplateTitle"
              />
            </template>

            <template #tooltip-content>
              {{ $t("Template used") }}: {{ template_title ? template_title : $t('No template used') }}
            </template>
          </svn-tooltip>
        </div>

        <div
          v-for="(item, i) in headlines"
          :key="i"
          class="w-full"
          :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
        >
          <div
            v-if="
              interviewKind === 'crossed' ||
                item.visible_for === interviewKind ||
                item.visible_for === 'all' && item.type !== InterviewQuestionType.PARAGRAPH
            "
          >
            <div
              v-if="item.type === InterviewQuestionType.CHAPTER"
              class="uppercase font-semibold break-words px-10 pt-6 pb-1.5"
            >
              {{ item.separatorpos }}. {{ item.title }}
            </div>

            <v-btn
              v-else-if="item.type === 'roadmap'"
              class="flex justify-start bg-transparent w-full shadow-none rounded-none break-words
               active:bg-blue-100
               focus:shadow-none
               hover:shadow-none"
              :value="item"
              @click="scrollQuestions(item.id)"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  class="mr-1 bkt-blue inline-block align-middle"
                  size="1em"
                />
                <span class="first-letter:uppercase normal-case truncate text-start">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>

            <v-btn
              v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
              :value="item"
              class="flex justify-start rounded-none break-words w-full shadow-none bg-transparent
                active:bg-blue-100
                focus:shadow-none
                hover:shadow-none"
              @click="scrollQuestions(item.id)"
            >
              <div class="flex w-full px-5">
                <v-icon
                  :icon="item.icon"
                  class="mr-1 bkt-blue inline-block align-middle"
                  size="1em"
                />

                <span class="first-letter:uppercase normal-case truncate text-start">
                  {{ item.title }}
                </span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <v-main class="overflow-y-auto relative">
      <div class="flex justify-center bg-neutral-100">
        <div class="w-full mx-4 sm:!w-4/6 flex justify-center mb-[30vh] relative">
          <interview-answer-list />

          <div class="block sm:hidden">
            <bkt-download-button
              title=""
              loading-text=""
              :loading="downloadLoading"
              @on-click="clickDownload"
            />

            <svn-tooltip
              v-if="interviewTouched"
              location="center"
              origin="top end"
            >
              <template #activator-content>
                <bkt-submit-interview-answer-button
                  v-if="!locked && interviewForm && interviewAnswers && interviewKind"
                  @submit-button-clicked="updateAnswersErrorState"
                />
              </template>

              <template #tooltip-content>
                <p>
                  {{ $t('To submit the form, all required fields must be completed.') }}
                </p>
              </template>
            </svn-tooltip>

            <div v-else>
              <bkt-submit-interview-answer-button
                v-if="!locked && interviewForm && interviewAnswers && interviewKind"
                @submit-button-clicked="updateAnswersErrorState"
              />
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-layout>

  <v-navigation-drawer
    v-if="isMobile"
    v-model="historyTooltip"
    :width="drawerWidth"
    location="end"
  >
    <pop-up-history-updates
      :interview-id="Number(route?.params?.id)"
      @close-drawer="historyTooltip = !historyTooltip"
    />
  </v-navigation-drawer>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import useTools from "@/tools/useTools.js";
import BktDownloadButton from "@/components/button/BktDownloadButton.vue";
import BktSubmitInterviewAnswerButton from "@/components/button/BktSubmitInterviewAnswerButton.vue";
import { useInterviewStore } from "@/store/interview.js";
import PopUpHistoryUpdates from "@/components/popUpComponents/PopUpHistoryUpdates.vue";
import InterviewAnswerList from "@/components/interviewApp/interview_answers/InterviewAnswerList.vue";
import { useSnackbar } from "@/store/snackbar.js";
import { downloadPdf } from "@/tools/pdf-proxy-service.js";
import { useMobileStore } from "@/store/mobile.js";
import { usePopUpStore } from "@/store/pop-up";
import { useUserStore } from "@/store/user.js";
import { InterviewQuestionType } from '@/constants/types';

const snackbar = useSnackbar();
snackbar.checkStatus();

const route = useRoute();
const { id: currentUserId } = storeToRefs(useUserStore());

const { deduceMatIconFromInterviewQuestionType } = useTools();
const { openPopUp } = usePopUpStore();
const { fetchInterview } = useInterviewStore();
const { isMobile, isLargeScreen } = storeToRefs(useMobileStore());
const {
  campaign,
  interviewKind,
  isInterviewLoading,
  interviewForm,
  locked,
  interviewAnswers,
  interviewTouched,
  isFinalUpdate,
  employee,
  interviewer,
  label
} = storeToRefs(useInterviewStore());

const toggleDrawers = () => {
  drawer.value = !drawer.value;
  drawerLargeOpen.value = !drawerLargeOpen.value;
}

const drawerLargeOpen = ref(true);
const historyTooltip = ref(false);
const drawerWidth = ref();
const drawer = ref(false);
const template_title = ref("");
const downloadLoading = ref(false);
const errorQuestions = ref([])
const toast = ref({})

onMounted(() => {
  fetchInterview(route.params.id);

  drawerWidth.value = window.innerWidth;
})

const headlines = computed(() => {
  if (!interviewForm.value) return [];

  let separator_position = 1;
  return interviewForm.value.questions.map((question) => ({
    id: question.id,
    title: question.text,
    type: question.type,
    separatorpos:
      question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP
        ? separator_position++
        : null,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    visible_for: question.visible_for,
  }));
});

const setTemplateTitle = () => {
  template_title.value = interviewForm.value ? interviewForm.value.title : "";
};

const clickDownload = async () => {
  downloadLoading.value = true;
  await downloadPdf(`/pdf/interview_answer/${route.params.id}`, `${employee.value?.fullname}_${campaign?.value?.title}_${campaign?.value?.campaign_type}`.toUpperCase())
  downloadLoading.value = false;
}

const isRequired = (interviewQuestion) => {
  if (interviewQuestion.required_for == 'none') return false
  if (interviewQuestion.required_for == 'all') return true
  if (interviewQuestion.required_for == 'manager') return currentUserId.value === interviewer.value.id && label.value !== 'Employee'
  if (interviewQuestion.required_for == 'employee') return currentUserId.value === employee.value.id && label.value === 'Employee'
}

const updateAnswersErrorState = () => {
  if (!interviewAnswers.value.length) {
    interviewTouched.value = true;
  }

  for (let question of interviewForm.value.questions) {
    if (isRequired(question) && !interviewAnswers.value.find(answer => answer.question_id === question.id)?.answer) {
      errorQuestions.value.push(question.id)
    }
  }
  if (errorQuestions.value.length) {
    interviewTouched.value = true
    let element = document.getElementById(`${errorQuestions.value[0]}`)
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  else {
    openLockInterviewPopUp()
  }
  errorQuestions.value = []
}

const scrollQuestions = (id) => {
  let element = document.getElementById(`${id}`)
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const openLockInterviewPopUp = () => {
  const hasRoadmap = interviewForm.value.questions.some((question) => question.type === InterviewQuestionType.CREATE_ROADMAP || question.type === InterviewQuestionType.UPDATE_ROADMAP)

  if (isFinalUpdate?.value === true && campaign?.value?.campaign_type === 'OneToOne' && hasRoadmap) {
    openPopUp({
      componentName: 'pop-up-submit-answers-with-roadmap',
      title: '',
      showClose: false,
      customClass: 'max-w-[578px]',
    })
  }
  else {
    openPopUp({
      componentName: 'pop-up-submit-interview-answers',
      title: '',
      showClose: false,
    })
  }
}
</script>

<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  opacity: 0;
}

.v-btn :deep(.v-btn__overlay) {
  display: none;
}

.v-btn :deep(.v-btn__content) {
  width: 100%;
}

* :deep(.v-toolbar__content > .v-btn:first-child) {
  margin-inline-start: 0px !important;
}
</style>
