<template>
  <v-card
    v-if="feedback"
    class="w-full flex flex-col items-start justify-center p-6 rounded-lg md:rounded-xl drop-shadow"
  >
    <v-card-title
      class="flex flex-col items-center w-full p-0 md:flex-row md:justify-start"
    >
      <MyInterviewCardHeader
        :campaign-deadline="campaign.deadline"
        :campaign-type="campaign.campaign_type"
        :campaign-name="campaign.title"
      />
    </v-card-title>
    <div
      v-if="'interview_reviewers' in feedback"
      class="w-full"
    >
      <v-card-text
        class="w-full flex flex-col justify-start space-x-0 p-0 !mt-6 mb-2
        lg:flex-row lg:justify-start lg:items-center lg:space-x-3"
      >
        <p
          v-if="feedback.interview_reviewers.length === 0 && !canManageReviewers"
          data-testid="no-reviewer"
          class="text-darkGrey font-medium text-center
            md:text-left"
        >
          {{ $t('No reviewer for the moment') }}
        </p>
        <p
          v-else-if="feedback.interview_reviewers.length === 0 && canManageReviewers || feedback.interview_reviewers.length === 1 && feedback.interview_reviewers?.[0].employee === null && canManageReviewers"
          data-testid="no-reviewer"
          class="text-darkGrey font-medium text-center md:text-left"
        >
          {{ $t('Choose reviewers before continue') }}
        </p>
        <div
          v-else
          class="flex flex-wrap"
          data-testid="reviewers"
        >
          <my-interview-feedback-card-people
            v-for="(reviewer, index) in feedback.interview_reviewers.filter(inter => inter.employee !== null)"
            :key="index"
            :status="reviewer.status"
            :user="reviewer.employee"
          />
        </div>
      </v-card-text>
      <div
        v-if="feedback.interview_reviewers.length === 0 && canManageReviewers || feedback.interview_reviewers.length === 1 && feedback.interview_reviewers?.[0].employee === null && canManageReviewers"
        class="w-full flex flex-row justify-center m-0 p-0 md:!justify-end"
      >
        <v-btn
          :block="isMobile"
          data-testid="button-manage"
          variant="flat"
          color="primary"
          class="px-4 normal-case w-full text-xs
            md:w-fit md:text-sm"
          height="40"
          @click="managePairs"
        >
          <Icon
            class="mr-1.5"
            icon="material-symbols:person-add"
          />
          {{ $t('Choose reviewers') }}
        </v-btn>
      </div>
      <div
        v-else-if="feedback.interview_reviewers.length > 0"
        class="w-full flex flex-col space-x-0 space-y-3 justify-center m-0 p-0 text-xs
          md:flex-row md:space-x-4 md:space-y-0 md:!justify-end"
      >
        <v-btn
          v-if="canManageReviewers"
          :block="isMobile"
          data-testid="button-manage"
          variant="outlined"
          color="primary"
          class="px-4 normal-case w-full text-xs
            md:w-fit md:text-sm"
          height="40"
          @click="managePairs"
        >
          <Icon
            icon="material-symbols:person"
            class="mr-1.5"
            height="15"
          />
          {{ $t('Manage reviewers') }}
        </v-btn>
        <v-btn
          v-if="canConsultAnswers"
          :block="isMobile"
          data-testid="button-answers"
          variant="outlined"
          color="primary"
          class="px-4 normal-case w-full text-xs
            md:w-fit md:text-sm"
          height="40"
          :disabled="feedback.interview_reviewers.filter(interview => interview.status === 'submitted').length === 0"
          @click="consultAnswers"
        >
          {{ $t('Consult answers') }}
          ({{ feedback.interview_reviewers.filter(interview => interview.status === "submitted").length }})
        </v-btn>
        <v-btn
          v-if="selfReview"
          :block="isMobile"
          data-testid="button-eval"
          :variant="selfReview?.status !== 'submitted' ? 'flat' : 'outlined'"
          color="primary"
          class="px-4 normal-case w-full text-xs
            md:w-fit md:text-sm"
          height="40"
          @click="selfEvaluation"
        >
          {{ selfAnswerButtonText() }}
          <Icon
            v-if="selfReview?.status !== 'submitted'"
            icon="ic:round-arrow-forward"
            class="ml-1.5"
            height="15"
          />
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col w-full"
    >
      <div
        v-for="(feedback_participant, index) in feedback?.feedback_participants"
        :key="index"
        class="flex flex-col md:flex-row border-opacity-100 [&:not(:last-child)]:border-b border-middleGrey"
      >
        <v-card-text
          class="md:w-1/2 flex flex-col justify-start space-x-0 p-0 mt-4 mb-2
        lg:flex-row lg:justify-start lg:items-center lg:space-x-3"
        >
          <my-interview-feedback-card-people
            :user="feedback_participant?.participant"
            :is-participant="true"
          />
        </v-card-text>

        <v-spacer />
        <div
          class="flex flex-row justify-center m-0 p-0 items-center
            md:!justify-end md:w-1/2"
        >
          <v-btn
            :block="isMobile"
            :variant="feedback_participant?.interview_employee?.status === 'submitted' ? 'outlined' : 'flat'"
            :data-testid="dataTestIdGiveFeedBack(index)"
            color="primary"
            class="px-4 normal-case w-full text-xs
              md:w-fit md:text-sm"
            height="40"
            @click="giveFeedback(feedback_participant?.interview_employee?.id)"
          >
            {{ feedbackAnswerButtonText(feedback_participant?.interview_employee?.status, feedback_participant?.participant) }}
            <Icon
              v-if="feedback_participant?.interview_employee?.status !== 'submitted'"
              icon="ic:round-arrow-forward"
              class="ml-1.5"
              height="15"
            />
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup>
  import {Icon} from "@iconify/vue";
  import MyInterviewFeedbackCardPeople from "@/components/interviewApp/MyInterview/MyInterviewFeedbackCardPeople.vue";
  import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
  import {useMobileStore} from "@/store/mobile.js";
  import {storeToRefs} from "pinia";
  import {usePopUpStore} from "@/store/pop-up.js";
  import {useI18n} from "vue-i18n";
  import {useUserStore} from "@/store/user.js";
  import router from "@/router/index.js";
import i18n from "@/plugins/i18n";
  import {computed} from "vue";

  // Props
  const props = defineProps({
    id: {type: Number, required: true },
    campaign: { type: Object, required: true },
    feedback: { type: Object, required: true },
    canManageReviewers: { type: Boolean, required: true },
    canConsultAnswers: { type: Boolean, required: true },
    canAutoEvaluation: { type: Boolean, required: true },
  })

  // Refs
  const { isMobile } = storeToRefs(useMobileStore())
  const { id } = storeToRefs(useUserStore())

  // Const
  const selfReview = computed(() =>  props.feedback?.interview_reviewers?.find(
        // little trick to mix interviews and feedbacks
        review => review.employee?.id === props.feedback.participant.id || review.label === "PairsShadow"
))

  // Functions
  const { openPopUp } = usePopUpStore()
  const { t } = useI18n()

  const dataTestIdGiveFeedBack = (index) => {
    return `give-answer-${index}`
  }

  const managePairs = () => {
    openPopUp({
      componentName: "PopUpManagePairs",
      title: i18n.global.t('Manage reviewers'),
      participantId: props.feedback.participant.id,
      feedbackId: props.feedback.id,
      feedback: props.feedback,
      canAutoEvaluation: props?.canAutoEvaluation,
      campaignId: props.campaign.id,
      icon: '',
      feedbackInterviews: props.feedback.interview_reviewers,
      customClass: "h-[573px] lg:w-[800px] !justify-normal",
      close() {},
    });
  };

  const consultAnswers = async () => {
    await router.push({name: 'feedback_answer', params: {id: props.feedback.id}})
  }

  const selfEvaluation = async () => {
    await router.push({name: 'interview_answer', params: {id: props.id}})
  }

  const selfAnswerButtonText = () => {
    switch (selfReview.value?.status) {
      case "not_started":
        return t('Start self evaluation')
      case "in_progress":
        return t('Continue self evaluation')
      case "submitted":
        return t('View my answers')
      default:
        return "error"
    }
  }

  const feedbackAnswerButtonText = (status, participant) => {
    switch (status) {
      case "not_started":
        return t('Start my feedback on', {firstname: participant.fullname?.split(' ')[0]})
      case "in_progress":
        return t('Continue my feedback on', {firstname: participant.fullname?.split(' ')[0]})
      case "submitted":
        return t('View my answers')
    }
  }

  const displaySelfEval = computed(
      () => {
        if (props.canAutoEvaluation) {
          for (const review of props.feedback.interview_reviewers) {
            if (review?.employee?.id === props.feedback.participant.id) {
              if (review.status === "submitted") {  // If review is submitted
                return !props.canConsultAnswers     // We want to display View my answers only if user can't consult answers
              }
            }
          }
          return true
        }
        return false
      }
  )

  const giveFeedback = (id) => {
    router.push({
      name: 'interview_answer',
      params: { id: id }
    })
  }

</script>

<style scoped>

</style>