<template>
  <div
    class="flex flex-col justify-start items-center gap-4 pb-[80px] sm:!pb-[20px] sm:w-[580px] relative"
  >
    <!-- Close button -->
    <div
      class="absolute top-6 right-6"
      @click="closeDialog"
    >
      <Icon
        class="text-darkGrey cursor-pointer"
        icon="mdi-close"
        height="24"
      />
    </div>

    <!-- Title -->
    <div class="flex flex-col justify-center items-center stretch-center px-6 pt-[56px] sm:pt-12 pb-6">
      <p class="text-xl font-medium text-fakeBlack">
        {{ (selectedModules.length === 0 || selectedModules.length === 1) ?
          $t('Select module to add ()', { selectedModules: selectedModules.length }) :
          $t('Select modules to add ()', { selectedModules: selectedModules.length }) }}
      </p>
    </div>

    <!-- Inputs -->
    <div class="flex flex-start px-4 gap-2 self-stretch">
      <v-text-field
        v-model="searchText"
        hide-details
        class="text-sm font-normal"
        variant="outlined"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        density="compact"
      />

      <!-- Filter -->
      <v-menu
        :close-on-content-click="false"
        location="bottom"
        :content-class="'w-[300px] h-[300px]'"
      >
        <!-- Menu activator -->
        <template #activator="{ props }">
          <div
            v-bind="props"
            class="flex justify-center items-center w-[48px] h-[48px] px-4 border border-darkGrey rounded cursor-pointer"
          >
            <div class="flex justify-center items-center self-stretch">
              <Icon
                icon="mdi:filter-variant"
                height="16"
                width="16"
              />
            </div>
          </div>
        </template>

        <!-- List -->
        <v-list class="!py-0 !rounded-[8px] !border !border-fakeBlack">
          <template #default>
            <div
              class="flex flex-col"
            >
              <!-- Theme list -->
              <v-list-item
                v-for="(item, index) in mockThemes"
                :key="index"
                class="!p-0 cursor-pointer"
                :class="selectedThemesList.find(theme => theme === item) !== undefined ?
                  'bg-veryLightGrey' : ''"
                @click.stop="toggleThemeSelection(item)"
              >
                <template #default>
                  <div class="flex h-10 px-4 gap-2 items-center">
                    <Icon
                      :icon="selectedThemesList.find(theme => theme === item) !== undefined ?
                        'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                      color="darkGrey"
                      width="18"
                      height="18"
                    />

                    <div class="w-full flex items-center">
                      <p class="text-darkGrey text-base font-normal">
                        {{ item }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-list-item>

              <!-- No result -->
              <div
                v-if="mockThemes.length === 0"
                class="w-full h-[120px] flex justify-center items-center self-stretch p-2"
              >
                <p class="text-middleGrey text-base font-normal">
                  {{ $t('No theme to display') }}
                </p>
              </div>
            </div>
          </template>
        </v-list>
      </v-menu>

      <v-btn
        class="text-middleGrey border border-darkGrey"
        size="48"
        variant="plain"
        @click="goToCreateModule"
      >
        <Icon
          icon="mdi:add"
          height="16"
          width="16"
        />
      </v-btn>
    </div>

    <!-- Filter results -->
    <div
      v-if="selectedThemesList.length > 0"
      class="flex justify-between items-start self-stretch px-4"
    >
      <div class="flex items-start content-start gap-2 flex-1 flex-wrap">
        <!-- Theme chips -->
        <div
          v-for="(theme, index) in selectedThemesList"
          :key="index"
          class="flex px-2 py-[6px] justify-center items-center rounded-[8px] bg-veryLightGrey !border !border-middleGrey"
        >
          <div class="flex justify-center items-center gap-2">
            <p class="text-darkGrey text-xs font-normal">
              {{ theme }}
            </p>

            <!-- Close icon -->
            <div
              class="cursor-pointer"
              @click="toggleThemeSelection(theme)"
            >
              <Icon
                icon="mdi-close"
                height="16"
                width="16"
                class="text-darkGrey"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selectedThemesList.length > 1"
        class="flex justify-center items-center px-4 py-[10px]"
        @click="clearAllSelectedThemes"
      >
        <p class="text-xs text-trainingOrange font-medium cursor-pointer">
          {{ $t('Clear all') }}
        </p>
      </div>
    </div>

    <!-- Playlists list -->
    <div
      v-if="modulesList.length"
      class="w-full max-h-full pb-6"
      :class="!isMobile ? 'px-4' : ''"
    >
      <div class="flex flex-col gap-1">
        <div
          v-for="(module, index) in modulesList"
          :key="index"
          class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer h-[82px]"
          :class="selectedModules.includes(module) ?
            !isMobile ?
              'bg-trainingOrangeSoft rounded-[8px]' : 'bg-trainingOrangeSoft rounded-none'
            : !isMobile ? 'bg-white' : 'bg-white'"
          @click="toggleAddPlaylist(module)"
        >
          <div>
            <Icon
              :icon="selectedModules.includes(module) ?
                'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
              height="18"
              width="18"
              :color="selectedModules.includes(module) ?
                '#EF8C64' : 'black'"
            />
          </div>

          <module-card-list-item
            :id="module?.id"
            :key="index"
            :background-image="module?.backgroundImage"
            :is-favorite="module?.isFavorite"
            :title="module?.title"
            :theme-list="module?.themeList"
            :duration="module?.duration"
            :updated-at="module?.updatedAt"
            :is-acquired="module?.isAcquired"
            :acquisition-date="module?.acquisitionDate"
          />
        </div>
      </div>
    </div>

    <!-- Validate button -->
    <div class="w-full px-4">
      <v-btn
        variant="flat"
        class="w-full normal-case"
        color="primary"
        :disabled="selectedModules.length === 0"
        @click="addModulesToPlaylist(selectedModules)"
      >
        <p class="text-white text-xs font-medium">
          {{ $t('Validate') }}
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import { usePopUpStore } from '@/store/pop-up';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import ModuleCardListItem from '../trainingApp/ModuleCardListItem.vue';
import router from '@/router';

const emit = defineEmits(['close', 'modules-added'])

const { closePopUp } = usePopUpStore()

const { isMobile, isXlScreen } = storeToRefs(useMobileStore())

const searchText = ref('')

const modulesList = ref([
  {
    id: 0,
    backgroundImage: "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    title: "Titre du premier module. Title of first module. Titulo del prim module",
    themeList: ["Theme 1", "Theme 2", "Theme 3", "Theme 4", "Theme 5", "Theme 6"],
    link: "https://www.google.com",
    reactions: {
      totalCount: 23,
      reactionsList: [
        {
          id: 0,
          type: 'like',
          count: 10,
        },
        {
          id: 1,
          type: 'recommend',
          count: 10,
        },
        {
          id: 2,
          type: 'question',
          count: 2,
        },
        {
          id: 2,
          type: 'remark',
          count: 1,
        },
      ],
    },
    duration: "20 min",
    updatedAt: "24/02/2023",
    isFavorite: true,
    isAcquired: true,
    acquisitionDate: '22/12/2023',
  },
  {
    id: 1,
    backgroundImage: "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    title: "Titre du premier module. Title of first module. Titulo del prim module",
    link: "https://www.google.com",
    reactions: {
      totalCount: 0,
      reactionsList: [],
    },
    themeList: [],
    duration: "20 min",
    updatedAt: "24/02/2023",
    isFavorite: false,
    isAcquired: false,
    acquisitionDate: '13/6/2023',
  },
  {
    id: 2,
    backgroundImage: "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    title: "Titre du premier module. Title of first module. Titulo del prim module",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2", "Theme 3"],
    reactions: {
      totalCount: 12,
      reactionsList: [
        {
          id: 0,
          type: 'like',
          count: 7,
        },
        {
          id: 1,
          type: 'recommend',
          count: 5,
        },
      ],
    },
    duration: "20 min",
    updatedAt: "24/02/2023",
    isFavorite: false,
    isAcquired: true,
    acquisitionDate: '13/6/2023',
  },
  {
    id: 3,
    backgroundImage: "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    title: "Titre du premier module. Title of first module. Titulo del prim module",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2", "Theme 3", "Theme 4", "Theme 5"],
    reactions: {
      totalCount: 0,
      reactionsList: [],
    },
    duration: "20 min",
    updatedAt: "24/02/2023",
    isFavorite: true,
    isAcquired: false,
    acquisitionDate: '',
  },
  {
    id: 4,
    backgroundImage: "https://www.powertrafic.fr/wp-content/uploads/2023/04/image-ia-exemple.png",
    title: "Titre du premier module. Title of first module. Titulo del prim module",
    link: "https://www.google.com",
    themeList: ["Theme 1", "Theme 2", "Theme 3"],
    reactions: {
      totalCount: 0,
      reactionsList: [],
    },
    duration: "20 min",
    updatedAt: "24/02/2023",
    isFavorite: false,
    isAcquired: true,
    acquisitionDate: '08/05/2023',
  },
])

const snackbar = useSnackbar()

const selectedModules = ref([])

const selectedThemesList = ref([])

const addModulesToPlaylist = (list) => {
  snackbar.setBgColor('positiveGreen')
  snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-0')
  snackbar.setMsg((selectedModules.value.length === 0 || selectedModules.value.length === 1) ?
    `Module successfully added to playlist.` :
    `Modules successfully added to playlist.`)
  snackbar.displaySnackBar()

  if (selectedModules.value.length) {
    emit('modules-added', selectedModules.value)
  }
  emit('close')
}

const toggleAddPlaylist = (module) => {
  const found = selectedModules.value.find((element) => element === module)

  if (found) {
    selectedModules.value.splice(selectedModules.value.findIndex((element) => element === module), 1)
  }
  else {
    selectedModules.value.push(module)
  }
}

const closeDialog = () => {
  emit('close')
  closePopUp()
}

const mockThemes = ref([
  "Theme 1",
  "Theme 2",
  "Theme 3",
  "Theme 4",
  "Theme 5",
  "Theme 6",
  "Theme 7",
  "Theme 8",
  "Theme 9",
  "Theme 10",
  "Theme 11",
  "Theme 12",
  "Theme 13",
  "Theme 14",
  "Theme 15",
])

const toggleThemeSelection = (item) => {
  const found = selectedThemesList?.value?.find(theme => theme === item);

  if (found) {
    selectedThemesList.value?.splice(selectedThemesList.value?.findIndex((theme) => theme === item), 1);
  }
  else {
    selectedThemesList.value?.push(item);
  }
}

const clearAllSelectedThemes = () => {
  selectedThemesList.value = [];
}

const goToCreateModule = () => {
  closePopUp()

  setTimeout(() => {
    router.push({ name: "module_new" })
  }, 200)
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
