<template>
  <div
    v-if="unarchived?.length"
    :id="question.id"
  >
    <div
      id="roadmapBlock"
      class="flex flex-col gap-2 border-[0.5px] border-middle-grey bg-white rounded-[12px] p-10"
    >
      <p class="text-2xl font-semibold">
        {{ question.text }}
      </p>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />
    </div>

    <div
      v-if="!unarchived"
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('You don\u0027t have any targets for the moment.') }}
      </p>
    </div>

    <div
      v-if="!unarchived?.length"
      class="flex flex-col justify-center items-center mx-auto py-10"
    >
      <svn-loader loading-size="lg" />
    </div>

    <div
      v-if="unarchived?.length"
      class="w-full flex flex-col gap-2 sm:!gap-8 mt-2 sm:!mt-8"
    >
      <roadmap-block-target
        v-for="(target, idx) in unarchived"
        :key="idx"
        :target="target"
        :question="question"
        :is-draft="false"
        :employee-id="employee?.id"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { useInterviewStore } from "@/store/interview.js";
import RoadmapBlockTarget from "./RoadmapBlockTarget.vue";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";

const { fetchTargetsSuggestionsUpdated } = useTargetSuggestionsStore();
const { targetsSuggestionsUpdated } = storeToRefs(useTargetSuggestionsStore());
const { employee, campaignId, id } = storeToRefs(useInterviewStore())
const unarchived = ref([])
const snackbar = useSnackbar();

const props = defineProps(['question'])

const fetchTargets = async () => {
  try {
    await fetchTargetsSuggestionsUpdated(employee?.value?.id, campaignId?.value, id.value)

    if (targetsSuggestionsUpdated?.value?.length === 0) {
      unarchived.value = null
    }
    else {
      unarchived.value = targetsSuggestionsUpdated.value
    }
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching targets !')
    snackbar.displaySnackBar()
  }
}

fetchTargets()
</script>
