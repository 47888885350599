import { defineStore } from "pinia";
import { genericFunctions, genericStates } from "@/tools/genericFunctions.js";

export const useCampaignTrashStore = defineStore({
  id: "campaignTrash",
  state: () => ({
    trashedCampaigns: [],
    meta: null,
    ...genericStates,
  }),
  getters: {},
  actions: {
    ...genericFunctions,
    async restoreCampaign(id) {
      try {
        await this.updateEntity(`/api/v2/interview_app/campaigns/${id}/restore`)
      } catch (e) {
        console.log(e)
      }
    },
    async destroyPermanentlyCampaign(id) {
      try {
        await this.deleteEntity(`/api/v2/interview_app/campaigns/${id}/destroy_permanently`)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchTrashCampaign(search = null) {
      try {
        this.updateParams( { "title": search })
        await this.fetchEntity("/api/v2/interview_app/campaigns/trashes", 'campaigns', 'trashedCampaigns' )
      } catch (e) {
        console.log(e)
      }
    }
  }
});
