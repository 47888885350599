<template>
  <svn-modal-skeleton
    ref="modalSettings"
    :persistent="true"
    no-click-animation
    :modal-title="$t('Settings')"
    :modal-title-center="!isMobile"
    :append-icon-close-modal="false"
    @append-icon-click="openConfirmDialog"
    @on-click-outside="openConfirmDialog"
  >
    <template #activator-div>
      {{ null }}
    </template>

    <template #content-body>
      <div class="w-full h-full flex flex-col gap-6 items-start self-stretch px-5 pt-6">
        <!-- Personal information -->
        <div class="w-full flex flex-col gap-4">
          <svn-text sm medium class="uppercase" color="dark-grey">
            {{ $t('Personal information') }}
          </svn-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
            <v-text-field
              v-model="editUser.firstname"
              color="primary"
              class="w-full"
              base-color="middleGrey"
              :label="$t('First name')"
              hide-details
              variant="outlined"
              @input="changed = true"
            />

            <v-text-field
              v-model="editUser.lastname"
              color="primary"
              class="w-full"
              base-color="middleGrey"
              :label="$t('Last name')"
              hide-details
              variant="outlined"
              @input="changed = true"
            />

            <v-text-field
              v-model="editUser.email"
              class="w-full"
              color="primary"
              base-color="middleGrey"
              :label="$t('Email')"
              hide-details
              variant="outlined"
              @input="changed = true"
            >
              <template #prepend-inner>
                <Icon
                  color="darkGrey"
                  icon="ic:outline-mail"
                  width="24"
                  height="24"
                />
              </template>
            </v-text-field>

            <svn-date-picker
              v-model="editUser.birthdate"
              :label="$t('Birth date')"
              @update:model-value="changed = true"
              class="w-full"
            />

            <v-select
              v-if="isAdminOrAbove()"
              v-model="editUser.accessLevel"
              :items="isSuperAdmin() ? AccessLevelListSuperAdmin : AccessLevelList"
              class="grow"
              :label="$t('Access level')"
              hide-details
              density="compact"
              color=primary
              base-color="middleGrey"
              variant="outlined"
              @update:model-value="changed = true"
            >
              <template #selection="{ item }">
                <svn-text base regular class="w-full truncate">
                  {{ item.title }}
                </svn-text>
              </template>

              <template #prepend-inner>
                <Icon
                  color="darkGrey"
                  icon="material-symbols:passkey-outline"
                  width="24"
                  height="24"
                />
              </template>
            </v-select>
          </div>
        </div>

        <!-- Hr information -->
        <div class="w-full flex flex-col gap-4">
          <svn-text sm medium class="uppercase" color="dark-grey">
            {{ $t('Hr information') }}
          </svn-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <!-- Manager -->
            <svn-autocomplete
              v-model="editUser.manager"
              :label="$t('Manager')"
              :items="managers"
              :overflow-y="false"
              class="w-full"
              :loading="managerLoading"
              color="primary"
              hide-details
              required
              return-object
              prepend-inner-icon="mdi-account-multiple-outline"
              clearable
              :disabled="!isAdminOrAbove()"
              @update:model-value="changed = true"
            >
              <template #main-content>
                {{ null }}
              </template>

              <template #append-content>
                {{ null }}
              </template>
            </svn-autocomplete>

            <!-- Hire Date -->
            <svn-date-picker
              v-model="editUser.hiredate"
              :label="$t('Hire date')"
              density="compact"
              @update:model-value="changed = true"
              class="w-full"
            />

            <!-- Tags -->
            <svn-autocomplete
              v-for="(category, index) in tagCategories"
              :key="editUser.tags[category.id]"
              v-model="editUser.tags[category.id]"
              :items="category.tags"
              item-title="tagName"
              :overflow-y="false"
              :label="capitalize(category.name)"
              class="!w-full"
              color="primary"
              required
              hide-details
              return-object
              prepend-inner-icon=""
              @update:search="tagCategorySearch[index] = $event"
              @update:model-value="changed = true"
            >
              <template #main-content>
                {{ null }}
              </template>

              <template #prepend-details>
                {{ null }}
              </template>

              <template #append-content>
                {{ null }}
              </template>

              <template #no-data>
                <div
                  class="flex cursor-pointer hover:bg-gray-200"
                  @click="createTagInTagCategory(index)"
                  @input="changed = true"
                >
                  <span class="mx-4">
                    {{ $t("create +") }}
                  </span>

                  <p>'{{ tagCategorySearch[index] }}'</p>
                </div>
              </template>
            </svn-autocomplete>
          </div>
        </div>

        <!-- Language -->
        <div class="w-full flex flex-col gap-4">
          <svn-text sm medium class="uppercase" color="dark-grey">
            {{ $t('Language') }}
          </svn-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <select-language :language="editUser.lang" @change-lang="changeLang" />

            <div class="hidden md:flex w-full" />
          </div>
        </div>

        <!-- Password -->
        <div class="flex flex-col gap-4">
          <svn-text sm medium class="uppercase" color="dark-grey">
            {{ $t('Password') }}
          </svn-text>

          <dialog-set-another-password
            ref="changePasswordRef"
            @change-password="changePassword"
          >
            <template #button>
              <svn-button
                variant="outlined"
                :text="$t('Set another password')"
              />
            </template>
          </dialog-set-another-password>
        </div>

        <!-- Date & Time -->
        <div class="flex flex-col gap-4">
          <svn-text sm medium class="uppercase" color="dark-grey">
            {{ $t('Date & time') }}
          </svn-text>

          <svn-text xs medium class="leading-4" color="dark-grey">
            {{ $t('Time Zone detected : ') }} {{ timezone }} - {{ timezoneInfo }}
          </svn-text>
        </div>
      </div>
    </template>

    <template #bottom-content>
      <div class="p-5">
        <svn-button
          :text="$t('Save changes')"
          :loading="updateLoading"
          class="w-full"
          @click="updateUser"
        />
      </div>
    </template>
  </svn-modal-skeleton>

  <!-- Quit without saving || Click on close -->
  <svn-dialog-skeleton
    ref="confirmDialog"
    icon="noto:warning"
    :title="$t('Your modifications will be lost.')"
    :description="$t('If you leave without save, your modifications will be lost.')"
    :primary-button-text="$t('Save and quit')"
    :secondary-button-text="$t('Quit without saving')"
  >
    <template #activator-div>
      {{ null }}
    </template>

    <template #primary-button>
      <svn-button
        :text="$t('Save and quit')"
        class="w-full"
        @click="saveAndQuit"
      />
    </template>

    <template #secondary-button>
      <svn-button
        :text="$t('Quit without saving')"
        variant="outlined"
        class="w-full"
        @click="clickWithoutSaving"
      />
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import useTools from "@/tools/useTools.js";
import axios from "../../../tools/axios-service.js";
import { useMobileStore } from "@/store/mobile";
import { useUserStore } from "@/store/user.js";
import caseStyles from "@/tools/caseStyles.js";
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import { onBeforeRouteLeave } from "vue-router";
import SelectLanguage from "@/components/language/SelectLanguage.vue";
import DialogSetAnotherPassword from "@/components/BktPopUp/Dialogs/DialogSetAnotherPassword";
import { AccessLevelList, AccessLevelListSuperAdmin } from "@/constants/types.js";

const props = defineProps({
  user: { type: Object, required: true },
  updateUserProfile: { type: Function, required: true }
});

onMounted(async () => {
  setUser();
  await getManagers();
});

const snackbar = useSnackbar();
const { sortTagsWithTagsCategory } = useTools();
const { isMobile } = storeToRefs(useMobileStore());
const { fetchCompanyManagers, fetchCompany } = useCompanyStore();

const { tagCategories, managers } = storeToRefs(useCompanyStore());
const { isSuperAdmin, isAdminOrAbove, timezone, timezoneInfo } = useUserStore();

const changed = ref(false);
const loadingForm = ref(false);
const tagCategorySearch = ref(tagCategories.value.map((_) => ""));
const editUser = ref({
  id: null,
  accessLevel: null,
  accessLevelPreset: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birthdate: null,
  hiredate: null,
  gender: null,
  avatar: null,
  uploaded_avatar: null,
  lang: null,
  avatars: {},
  tags: null,
  jobTitle: "",
  manager_id: null,
});
const managerLoading = ref(true);
const updateLoading = ref(false);
const changePasswordRef = ref(null);
const confirmDialog = ref(null);
const modalSettings = ref(null);

const updateUser = async () => {
  updateLoading.value = true;
  try {
    loadingForm.value = true;

    let error = "Please fill";

    if (editUser.value.firstname === null || editUser.value.firstname === "")
      error += " the firstname,";
    if (editUser.value.lastname === null || editUser.value.lastname === "")
      error += " the lastname,";
    if (editUser.value.email === null || editUser.value.email === "")
      error += " the email,";
    if (error !== "Please fill") {
      snackbar
        .setDefaultColor("failed")
        .displaySnackBar(error.slice(0, -1) + ".");
      snackbar.checkStatus();
      return;
    }
    const formData = new FormData();

    if (editUser.value.accessLevel)
      formData.append("user[access_level]", editUser.value.accessLevel);
    if (editUser.value.firstname)
      formData.append("user[firstname]", editUser.value.firstname);
    if (editUser.value.lastname)
      formData.append("user[lastname]", editUser.value.lastname);
    if (editUser.value.email)
      formData.append("user[email]", editUser.value.email);
    if (editUser.value.birthdate)
      formData.append(
        "user[birth_date]",
        JSON.stringify(editUser.value.birthdate)
      );
    if (editUser.value.hiredate)
      formData.append(
        "user[hire_date]",
        JSON.stringify(editUser.value.hiredate)
      );
    if (editUser.value.gender)
      formData.append("user[gender]", editUser.value.gender);
    if (editUser.value.lang)
      formData.append("user[lang]", editUser.value.lang);
    if (editUser.value.jobTitle)
      formData.append("user[job_title]", editUser.value.jobTitle);
    if (editUser.value.manager) {
      formData.append("user[manager_id]", editUser.value.manager.id);
    } else {
      formData.append("user[manager_id]", "");
    }
    if (editUser.value.tags)
      formData.append("user[tags]", JSON.stringify(caseStyles.convertKeysToSnake(editUser.value.tags)));

    const headers = { "Content-Type": "multipart/form-data" };
    await axios.patch(`/api/v1/users/${editUser.value.id}`, formData, {
      headers,
    });

    snackbar.setDefaultColor("updated")
    snackbar.displaySnackBar("Your settings have been updated", 3000);
  } catch (e) {
    snackbar.setBgColor("negativeRed")
    snackbar.displaySnackBar("Error updating your settings",3000);
  } finally {
    loadingForm.value = false;
    props.updateUserProfile();
    fetchCompany();
  }
  changed.value = false;
  modalSettings.value.dialog = false;
  updateLoading.value = false;
};

const changePassword = async (password) => {
  editUser.value.password = password;
  try {
    await axios.patch(`/api/v1/users/${editUser.value.id}`, {
      user: editUser.value,
    });
    changePasswordRef.value.dialogRef.dialog = false;

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Password has been updated')
    snackbar.setCustomClass('mb-20')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error ! Your password has not been updated')
    snackbar.setCustomClass('mb-20')
    snackbar.displaySnackBar()
  }
}

const changeLang = (lang) => {
  editUser.value.lang = lang;
}

const setUser = () => {
  editUser.value = Object.create(props.user);
  editUser.value.birthdate = props.user.birthDate;
  editUser.value.hiredate = props.user.hireDate;
  editUser.value.tags = sortTagsWithTagsCategory(props.user.tags, tagCategories.value);
};

const getManagers = async () => {
  try {
    await fetchCompanyManagers();
    managers.value = managers.value.filter((manager) => {
      return manager.id !== editUser.value.id;
    });
  } catch (e) {
    console.log("error", e);
  } finally {
    managerLoading.value = false;
  }
};

const createTagInTagCategory = async (index) => {
  const search = tagCategorySearch.value[index];
  const tagCategoryId = tagCategories.value[index].id;

  try {
    const res = await axios.post(
      `/api/v1/companies/tag_categories/${tagCategoryId}/tags`,
      { tag_name: search }
    );

    editUser.value.tags[tagCategoryId] = caseStyles.convertKeysToCamel(res.data.tag);
    changed.value = true;
  } catch (e) {
    console.log(e);
  }
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

onBeforeRouteLeave(async (_, __) => {
  return await new Promise((resolve, _) => {
    if (!changed.value) resolve(true);
    else {
      leaveResolve = resolve;
      leave.value = true;
    }
  });
});

const openConfirmDialog = () => {
  confirmDialog.value.dialog = true;
}

const saveAndQuit = () => {
  confirmDialog.value.dialog = false;
  updateUser();
}

const clickWithoutSaving = () => {
  confirmDialog.value.dialog = false;
  modalSettings.value.dialog = false;
  setUser();
}

watch(editUser, (newValue, oldValue) => {
  if (newValue) {
    editUser.value = newValue
  }
});

defineExpose({
  modalSettings
});
</script>

<style scoped>
* :deep(.v-field){
  border-radius: 8px;
}

* :deep(input) {
  color: #333333 !important;
  font-weight: 400 !important;
}

.white-space-wrap :deep(.v-btn__content) {
  white-space: normal;
}

.unclearable :deep(.v-field__clearable) {
  display: none
}
</style>