<template>
  <div>
    <v-card class="elevation-2 rounded-xl p-6">
      <v-card-title class="p-0 font-normal text-base">
        {{ $t("Summary of your campaign") }}
      </v-card-title>
      <v-card-text class="p-0">
        <v-container class="font-medium">
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Campaign Type") }} :
            </v-col>
            <v-col>
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{ campaignDraft.campaign_type }}
              </span>
            </v-col>
          </v-row>
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Campaign title") }} :
            </v-col>
            <v-col>
              {{ campaignDraft.title }}
            </v-col>
          </v-row>
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Recurrent campaign") }} :
            </v-col>
            <v-col>
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{ campaignDraft.is_recurrent ? $t(`true`) : $t('false') }}
              </span>
            </v-col>
          </v-row>
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Campaign period") }} :
            </v-col>
            <v-col class="flex items-center">
              {{
                new Date(campaignDraft.startline).toLocaleString(lang === "fr" ? "fr-FR" : "en-GB", {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })
              }}
              <Icon
                icon="ph:arrow-right-thin"
                class="mx-2"
              />
              {{
                new Date(campaignDraft.deadline).toLocaleString(lang === "fr" ? "fr-FR" : "en-GB",{
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })
              }}
            </v-col>
          </v-row>
          <v-row
            v-if="campaignDraft.campaign_type === CampaignTypeEnum.SURVEY"
            class="border-b"
          >
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t('People viewing answers') }} :
            </v-col>
            <v-col
              class="flex items-center"
            >
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{
                  campaignDraft?.interviewer_ids?.length + ' ' + $t("People").toLocaleLowerCase()
                }}
              </span>
              <svn-tooltip v-if="campaignDraft?.interviewer_ids?.length > 0">
                <template #activator-content>
                  <Icon
                    icon="mdi:information-outline"
                    class="text-dark-grey h-4 w-4"
                  />
                </template>

                <template #tooltip-content>
                  {{ managersInCharge }}
                </template>
              </svn-tooltip>
            </v-col>
          </v-row>
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Participants") }} :
            </v-col>
            <v-col>
              {{ campaignDraft.interviewee_selection_method === 'manual' ? campaignDraft.interviewee_ids.length : participantsCount }}
            </v-col>
          </v-row>
          <v-row
            v-if="campaignDraft.campaign_type !== CampaignTypeEnum.SURVEY"
            class="border-b"
          >
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Person in charge") }} :
            </v-col>
            <v-col>
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{
                  $t(campaignDraft.interviewer_selection_method === "manager" ?
                    "Direct manager" :
                    "Specific manager")
                }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="campaignDraft.campaign_type !== CampaignTypeEnum.SURVEY"
            class="border-b text-neutral-500 leading-6"
          >
            <v-col class="text-end font-medium leading-7">
              {{ $t("Default person in charge") }} :
            </v-col>
            <v-col class="flex">
              <span
                v-if="defaultInterviewer"
                class="border rounded-full p-0.5 flex"
              >
                <v-img
                  :src="defaultInterviewer?.avatar ? defaultInterviewer.avatar['30'] : null"
                  class="rounded-full mr-2"
                  max-height="24"
                  min-height="24"
                  max-width="24"
                  min-width="24"
                />
                <span class="mr-1">
                  {{ defaultInterviewer.fullname }}
                </span>
              </span>
              <span
                v-else
              >
                {{ $t("Looking for interviewer") }}
              </span>
            </v-col>
          </v-row>
          <v-row class="border-b">
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t("Template(s) used") }} :
            </v-col>
            <v-col>
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{
                  campaignDraft.templates_selection_method === "single" &&
                    defaultInterviewForm
                    ? defaultInterviewForm.title
                    : $t("Multiples templates")
                }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="campaignDraft.campaign_type === CampaignTypeEnum.SURVEY"
            class="border-b mb-3"
          >
            <v-col class="text-end font-medium text-neutral-500">
              {{ $t('Anonymise answers') }} :
            </v-col>
            <v-col>
              <span class="bg-neutral-100 text-neutral-500 rounded-md p-2">
                {{
                  campaignDraft?.anonymize_answers ? $t('Yes') : $t('No')
                }}
              </span>
            </v-col>
          </v-row>
          <div
            v-if="campaignDraft.campaign_type === CampaignTypeEnum.FEEDBACK_360"
          >
            <v-row class="border-b leading-7">
              <v-col class="text-end font-medium text-neutral-500">
                {{ $t("Participants choose their reviewers") }} :
              </v-col>
              <v-col class="flex">
                <span class="bg-neutral-100 text-neutral-500 rounded-md px-2 flex items-center">
                  {{ $t(campaignDraft.can_choose_pairs ? 'Yes' : 'No') }}
                </span>
              </v-col>
            </v-row>
            <v-row class="border-b leading-7">
              <v-col class="text-end font-medium text-neutral-500">
                {{ $t("Self evaluation") }} :
              </v-col>
              <v-col class="flex">
                <span class="bg-neutral-100 text-neutral-500 rounded-md px-2 flex">
                  {{ $t(campaignDraft.can_self_evaluate ? 'Yes' : 'No') }}
                </span>
              </v-col>
            </v-row>
            <v-row class="leading-7">
              <v-col class="text-end font-medium text-neutral-500">
                {{ $t("Participants view reviewers answers") }} :
              </v-col>
              <v-col class="flex">
                <span class="bg-neutral-100 text-neutral-500 rounded-md px-2 flex items-center">
                  {{ $t(campaignDraft.can_view_pairs_answers ? 'Yes' : 'No') }}
                </span>
              </v-col>
            </v-row>
          </div>
        </v-container>

        <v-card-actions>
          <v-btn
            class="m-auto mt-4"
            color="primary"
            variant="elevated"
            @click="openLaunchCampaignPopUp"
          >
            {{ $t("Launch campaign") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { useCompanyStore } from "@/store/company.js";
import {computed, ref} from "vue";
import { usePopUpStore } from "@/store/pop-up";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import {CampaignTypeToStr} from "@/constants/CampaignTypeEnum";
import {Icon} from "@iconify/vue";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum.js";
import { useUserStore } from "@/store/user.js";
import i18n from "@/plugins/i18n";
import axios from "axios";

const participantsCount = ref(0);
const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { managers, interviewForms } = storeToRefs(useCompanyStore());
const { lang } = storeToRefs(useUserStore());
const { openPopUp, closePopUp } = usePopUpStore();
const { fetchCompanyManagers, fetchInterviewForms } = useCompanyStore();
const { createCampaign } = useCampaignDraftStore();
const snackbar = useSnackbar();
const router = useRouter();

fetchCompanyManagers();
fetchInterviewForms();

const defaultInterviewer = computed(() => {
  return managers.value.find(
    (manager) => manager.id === campaignDraft.value.default_interviewer_id
  );
});

const defaultInterviewForm = computed(() => {
  return interviewForms.value.find(
    (interviewForm) =>
      interviewForm.id === campaignDraft.value.default_template_id
  );
});

const openLaunchCampaignPopUp = async () => {
  let startline = new Date(campaignDraft.value.startline)
  let today = new Date();

  if (startline > today) {
    openPopUp({
      componentName: "pop-up-launch-campaign",
      title: `When do you want to launch this campaign ?`,
      subtitle: `The campaign start date is later than the current date.`,
      textClose: "Launch now",
      textConfirm: i18n.global.t("Launch on", {date: i18n.global.d(startline, 'short')}),
      textLoading: "",
      customClass: "w-full sm:!max-w-[400px]",
      icon: 'material-symbols:today',
      color: "blue",
    });
  }
  else {
    openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t(`Send invitation emails`),
    subtitle: "Do you wish to send invitations by email for this campaign ? They will be sent now.",
    textClose: "No, thanks",
    textConfirm: i18n.global.t(`Yes, send emails`),
    icon: 'noto-incoming-envelope',
    color: "blue",
    appName: "Interviews",
    sendWithoutEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: false, launchSchedule: false });
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
    sendWithEmail: async () => {
      try {
        closePopUp();
        await createCampaign({ sendEmail: true, launchSchedule: false });
        await router.push({ name: "campaigns" });
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData && errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(' '))
            .checkStatus();
        } else {
          snackbar.setStatus("error", error.message).checkStatus();
        }
      }
    },
  });
  }
};

const managersInCharge = computed(() => {
  const managersInCharge = [];
  for (const manager of managers.value) {
    if (campaignDraft?.value?.interviewer_ids.includes(manager.id)) {
      managersInCharge.push(manager.fullname);
    }
  }
  return managersInCharge.join(', ');
});

const getParticipantsCount = async() => {
  try {
    const { data } = await axios.get('/api/v1/users/filter_by_tag_categories', {
      params: {
        tag_ids: campaignDraft.value.participant_filter_tag_ids,
      }
    })
    participantsCount.value = data.users
  } catch (error) {
    console.log(error);
  }
}

getParticipantsCount()
</script>
