<template>
  <svn-data-table
    :header-items="isMobile ? [] : headers"
    :pagination-total-pages="pagination?.total_pages"
    :items="formattedUsers"
    :mobile="isMobile"
    :loading="usersLoading"
    :page="pagination.current_page"
    :show-select="true"
    @load-more="loadMore"
    @update-pagination="fetchPage"
  >
    <template #item="{ item }">
      <tr class="cursor-pointer h-16">
        <td>
          <v-checkbox
            v-model="selectedUsers"
            hide-details
            :value="item.value"
            @update:model-value="updateCheckbox"
          />
        </td>

        <td @click="goToUserProfile(item.raw.id)">
          <div class="flex flex-row items-center">
            <svn-avatar
              :avatar="item.raw?.avatar"
              :firstname="item.raw?.firstname"
              :lastname="item.raw?.lastname"
              size="lg"
              class="mr-1rem"
            />

            <div class="flex flex-col">
              <p>
                {{ item.raw?.fullname }}
              </p>

              <p class="text-darkGrey">
                {{ item.raw?.email }}
              </p>
            </div>
          </div>
        </td>

        <td @click="goToUserProfile(item.raw.id)">
          <div>
            <p>{{ item.raw?.access_level }}</p>
          </div>
        </td>

        <td @click="goToUserProfile(item.raw.id)">
          <div class="flex flex-row items-center">
            <svn-avatar
              :avatar="item.raw?.manager?.avatar?.['500']"
              :firstname="item.raw?.manager?.firstname"
              :lastname="item.raw?.manager?.lastname"
              size="lg"
              class="mr-1rem"
            />

            <div class="flex flex-col">
              <p>
                {{ item.raw?.manager?.fullname }}
              </p>

              <p class="text-darkGrey">
                {{ item.raw?.manager?.email }}
              </p>
            </div>
          </div>
        </td>

        <td @click="goToUserProfile(item.raw.id, item.raw?.fullname)">
          <div>
            <p>{{ item.raw?.hire_date }}</p>
          </div>
        </td>

        <td
          v-for="tagCat in tagCategories"
          :key="tagCat.id"
          @click="goToUserProfile(item.raw.id)"
        >
          {{ item.raw?.tags.find((tag) => tag.tag_category.id === tagCat.id)?.['tag_name'] }}
        </td>
      </tr>
    </template>

    <template #mobile>
      <div class="w-full h-full flex flex-col">
        <div
          v-for="user in usersMobile"
          :key="user?.id"
          class="w-full flex items-center justify-center self-stretch gap-6 p-4 border-t border-t-light-grey"
          @click="goToUserProfile(user?.id)"
        >
          <div>
            <v-checkbox
              v-model="selectedUsers"
              :value="user?.id"
              hide-details
              class="select"
              density="compact"
              base-color="#49454F"
              color="#49454F"
              @click.stop
              @update:model-value="updateCheckbox"
            />
          </div>

          <div class="w-full flex flex-col gap-2 overflow-hidden">
            <div class="w-full flex flex-1 gap-2">
              <svn-avatar
                :avatar="user?.avatar"
                :firstname="user?.firstname"
                :lastname="user?.lastname"
                size="lg"
              />
  
              <div class="flex flex-col">
                <svn-text sm medium class="leading-5 truncate">
                  {{ user?.fullname }}
                </svn-text>
  
                <svn-text sm normal color="dark-grey" class="leading-5 truncate">
                  {{ user?.email }}
                </svn-text>
              </div>
            </div>

            <div class="w-full flex flex-col">
              <div class="flex gap-1 items-center">
                <div>
                  <Icon
                    icon="ph:keyhole-light"
                    height="16"
                    width="16"
                    class="text-fake-black"
                  />
                </div>

                <svn-text sm normal class="leading-5 truncate">
                  {{ getAccessLevel(user?.access_level) }}
                </svn-text>
              </div>

              <div class="flex gap-1 items-center">
                <div>
                  <Icon
                    icon="ph:users-light"
                    height="16"
                    width="16"
                    class="text-fake-black"
                  />
                </div>

                <svn-text sm normal class="leading-5 truncate">
                  {{ user?.manager?.email || $t('No manager') }}
                </svn-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </svn-data-table>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Icon } from "@iconify/vue";
import { useUserStore } from "@/store/user.js";
import { storeToRefs } from "pinia";
import { useCompanyStore } from "@/store/company.js";
import i18n from "@/plugins/i18n.js";
import { useMobileStore } from "@/store/mobile.js";
import { useRouter } from "vue-router";
import axiosService from "../../tools/axios-service.js";

const { isMobile } = storeToRefs(useMobileStore());
const { users, pagination, loading: usersLoading } = storeToRefs(useUserStore());
const { tagCategories } = storeToRefs(useCompanyStore());

const router = useRouter();
const { fetchCompanyUsers } = useCompanyStore();
const { fetchUsers, updateParams, setUsers, setSelectedUsers, searchEmployess } = useUserStore();

const page = ref(1);
const headers = ref([]);
const formattedUsers = ref([]);
const selectedUsers = ref([]);
const usersMobile = ref([]);
const paginationMobile = ref({});

const emit = defineEmits(["next"]);

onMounted(async () => {
  // putting this here because v-data-table is in beta and have some trouble to display loading text
  const headersTmp = [
    {
      align: "start",
      key: "select",
      sortable: false,
      title: "",
    },
    {
      align: "start",
      key: "fullname",
      sortable: true,
      title: i18n.global.t("Name"),
    },
    {
      align: "start",
      key: "access_level",
      sortable: true,
      title:  i18n.global.t("Access level"),
    },
    {
      align: "start",
      key: "manager",
      sortable: true,
      title:  i18n.global.t("Manager"),
    },
    {
      align: "start",
      key: "hire_date",
      sortable: true,
      title:  i18n.global.t("Hire date"),
    },
  ];

  if (!users?.value?.length) await fetchCompanyUsers();

  const formattedTagCat = tagCategories?.value?.map((obj) => {
    return {
      key: obj.name,
      sortable: true,
      title: obj.name,
      width: 300,
    };
  });
  headers.value = headersTmp.concat(formattedTagCat);

  formattedUsers.value = users?.value?.map((obj) => {
    const toRet = {
      id: obj.id,
      fullname: obj.fullname,
      firstname: obj.firstname,
      lastname: obj.lastname,
      email: obj.email,
      access_level: obj.access_level,
      manager: obj.manager,
      hire_date: obj.hire_date,
      avatar: obj.avatar,
      tags: obj.tags,
      checkbox: obj.checkbox,
    };
    tagCategories?.value.map((tagCat) => {
      const found = obj.tags.find((tag) => tag.tag_category.id == tagCat.id);

      if (found) toRet[tagCat.name] = found.name;
      else toRet[tagCat.name] = "";
    });
    return toRet;
  });

  usersMobile.value = users.value;
  paginationMobile.value = pagination.value;
});

const updateCheckbox = () => {
  setSelectedUsers(selectedUsers.value);
}

const getAccessLevel = (access_level) => {
  switch (access_level) {
    case "employee":
      return "Employee"
    case "manager":
      return "Manager"
    case "manager_creator":
      return "Manager creator"
    case "admin":
      return "Admin"
    case "super_admin":
      return "Super admin"
    default:
      return ""
  }
}

const loadMore = async ({ done }) => {
  if (isMobile.value) {
    if (page.value < paginationMobile.value.total_pages) {
      page.value++
      try {
        const { data } = await axiosService.get("/api/v1/users/users_search", {
          params: {
            text: '',
            global: true,
            "page[size]": 10,
            "page[number]": page.value,
            view: "fullname_avatar_manager_access_level_with_email"
          },
        });
        usersMobile.value = [...usersMobile.value, ...data?.users];
        paginationMobile.value = data?.meta?.pagination;
        done("ok");
      } catch (error) {
        done("error")
        console.log(error)
      }
    } else {
      done("empty")
    }
  }
}

const goToUserProfile = (userId) => {
  const routeData = router.resolve({ name: "people_show", params: { id: userId }, query: { page: 'interviews' } });
  window.open(routeData.href, '_blank');
}

const fetchPage = (e) => {
  setUsers(null);
  updateParams({
    "page[number]": e,
  });
  fetchUsers();
};
</script>

<style scoped>
.v-data-table .v-table__wrapper>table>thead>tr>td.v-data-table-column--no-padding,
.v-data-table .v-table__wrapper>table>thead>tr th.v-data-table-column--no-padding,
.v-data-table .v-table__wrapper>table tbody>tr>td.v-data-table-column--no-padding,
.v-data-table .v-table__wrapper>table tbody>tr th.v-data-table-column--no-padding {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

/* * :deep(.v-infinite-scroll__side) {
  display: none !important;
} */

* :deep(.v-checkbox .v-selection-control) {
  min-height: 100% !important;
}

* .select :deep(.v-selection-control--density-compact) {
  --v-selection-control-size: auto !important;
}

* .select :deep(.v-selection-control__input > .v-icon) {
  opacity: 100% !important;
}

* .select :deep(.v-input) {
  flex: auto;
}

* :deep(.v-label) {
  opacity: 100% !important;
}

* :deep(.v-icon--size-default) {
  font-size: 18px !important;
}
</style>