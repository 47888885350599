<template>
  <div
    v-if="!loadingCompletionByCategory"
    class="md:h-[300px] shadow rounded"
    :class="barClass()"
  >
    <div class="w-full min-h-1/4 flex border-b-2 px-10 flex">
      <div class="py-4">
        <div class="font-medium uppercase">
          {{ $t(completionCategoryText()) }}
        </div>
        <div class="text-sm">
          {{ $t('Compare completion by categories') }}
        </div>
      </div>
      <v-spacer />
      <v-select
        v-model="chartCompletionCategorySelected"
        item-title="name"
        item-value="id"
        :items="chartCompletionCategorySelectOptions"
        variant="underlined"
        label="name"
        single-line
        hide-details
        @update:model-value="initCompletionCategory()"
      >
        <template #selection="{ item }">
          <div class="w-full truncate">{{ item.title }}</div>
        </template>
      </v-select>
    </div>
    <div class="w-full h-3/4 flex px-10 py-4">
      <bkt-bar
        :data="chartCompletionCategory"
        :options="chartCompletionCategoryOptions"
      />
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-full w-full">
    <svn-loader loading-size="lg" />
  </div>
</template>
<script setup>
import {CampaignTypeBackend} from "@/constants/CampaignTypeEnum.js";
import BktBar from "@/components/chart/BktBar.vue";
import { ref, computed } from "vue";
import {useCampaignStore} from "@/store/campaign.js";
import {storeToRefs} from "pinia";
import {useCompanyStore} from "@/store/company"
const { t } = useI18n();
import {useI18n} from "vue-i18n";

const chartColors = ref(['#7BC079', '#FFC85C', '#FF5656', '#787878']);
const { tagCategories } = storeToRefs(useCompanyStore());
const chartLabels = ref([t('Submitted'), t('In progress'), t('Not started'), t('Not available yet')]);

const { campaign, completionByCategory, loadingCompletionByCategory } = storeToRefs(useCampaignStore());
const { fetchCompletionByCategory } = useCampaignStore();
const props = defineProps({
  chartCompletionCategorySelected: { type: Object, default: () => {} },
  chartCompletionCategorySelectOptions: { Array: Object, default: () => [] },
  chartCompletionCategory: { type: Object, default: () => {} },
  chartCompletionCategoryOptions: { type: Object, default: () => {} },
  campaign: { type: Object, default: () => {} }
})
const chartCompletionCategorySelected = ref();

const initCompletionCategory = async () => {
  await fetchCompletionByCategory(props.campaign.id || route.params.id, chartCompletionCategorySelected.value);
};

const chartCompletionCategorySelectOptions = computed(() => {
  return tagCategories.value.map(category => {
    return { id: category.id, name: category.name }
  })
})

const barClass = () => {
  switch (campaign.value.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return 'w-full'
    case CampaignTypeBackend.FEEDBACK_360 || CampaignTypeBackend.SURVEY:
      return 'lg:w-2/3 sm:w-full'
    case CampaignTypeBackend.SURVEY:
      return 'lg:w-2/3 sm:w-full'
    default:
      return ''
  }
}

const completionCategoryText = () => {
  switch (campaign.value.campaign_type) {
    case CampaignTypeBackend.ONE_TO_ONE:
      return "Interview completion - Category"
    case CampaignTypeBackend.FEEDBACK_360:
      return "Feedback completion - Category"
    case CampaignTypeBackend.SURVEY:
      return "Survey completion - Category"
    default:
      return ''
  }
}
chartCompletionCategorySelected.value = chartCompletionCategorySelectOptions.value[0].id
initCompletionCategory()

const chartCompletionCategoryOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true
    },
    x: {
      stacked: true
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "start"
    }
  },
}

const chartCompletionCategory = computed(() => {
  if (completionByCategory.value && completionByCategory.value.tags) {
    return {
      labels: completionByCategory.value.tags.map(tag => tag.tag) || [],
      datasets: [
        {
          label: chartLabels.value[0],
          backgroundColor: chartColors.value[0],
          data: completionByCategory.value.tags.map(tag => tag.data.submitted)
        },
        {
          label: chartLabels.value[1],
          backgroundColor: chartColors.value[1],
          data: completionByCategory.value.tags.map(tag => tag.data.in_progress)
        },
        {
          label: chartLabels.value[2],
          backgroundColor: chartColors.value[2],
          data: completionByCategory.value.tags.map(tag => tag.data.not_started)
        }
      ]
    };
  } else {
    return null
  }
})
</script>
