<template>
  <v-infinite-scroll
    class="p-1"
    :items="campaigns"
    data-testid="container_campaigns"
    @load="onLoad"
  >
    <div
      v-for="(campaign, index) in campaigns"
      :key="index"
      class="!mb-4"
    >
      <MyTeamInterviewOneToOneCard
        v-if="campaign.campaign_type === CampaignTypeBackend.ONE_TO_ONE"
        :campaign-interviews="campaign.interviews"
        :campaign-type="campaign.campaign_type"
        :campaign-deadline="campaign.deadline"
        :campaign-title="campaign.title"
        :campaign-id="campaign.id"
      />
      <MyTeamInterviewFeedbackCard
        v-else-if="campaign.campaign_type === CampaignTypeBackend.FEEDBACK_360"
        :campaign-feedbacks="campaign.person_in_charge_feedbacks"
        :campaign-type="campaign.campaign_type"
        :campaign-deadline="campaign.deadline"
        :campaign-title="campaign.title"
        :campaign-id="campaign.id"
      />
      <MyTeamInterviewsSurveyCard
        v-else-if="campaign.campaign_type === CampaignTypeBackend.SURVEY"
        :id="campaign.id"
        :title="campaign.title"
        :submitted-answers="campaign.submitted_interviews"
        :total-answers="campaign.total_interviews"
        :deadline="campaign.deadline"
        :campaign-type="campaign.campaign_type"
      />
    </div>
  </v-infinite-scroll>
</template>

<script setup>
  import MyTeamInterviewOneToOneCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewOneToOneCard.vue";
  import MyTeamInterviewFeedbackCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewFeedbackCard.vue";
  import MyTeamInterviewsSurveyCard from "@/components/interviewApp/MyTeamInterviews/MyTeamInterviewsSurveyCard.vue";
  import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";

  // Props
  const props = defineProps({
    campaigns: {type: Array, required: true}
  })

  // Emits
  const emits = defineEmits(["pagination"])

  // Functions
  const onLoad = async ({done}) => {
    emits("pagination", done)
  }

</script>

<style scoped>

</style>