<template>
  <div class="w-full flex flex-col px-4 gap-4 sm:max-w-[758px] sm:mx-auto">
    <!-- Picture, Themes, updatedAt & Duration -->
    <div class="flex flex-col pt-4 items-center gap-2 self-stretch sm:!pt-6 sm:gap-4">
      <!-- Card image -->
      <bkt-image-cover-position
          class="my-4 lg:!my-[0px] "
          mode="show"
          :url="training?.cover_url?.['original'] ? training?.cover_url?.['original'] : ''"
          :coordinates="{left: training?.cover_offset_left ?? 0, top: training?.cover_offset_top ?? 0,}"
      />

      <!-- Infos -->
      <div class="flex justify-between items-start self-stretch">
        <!-- Themes -->
        <div
          v-if="training?.module_themes?.length"
          class="flex items-center gap-2 flex-1 flex-wrap"
        >
          <!-- Themes list -->
          <div
            v-for="(theme) in training?.module_themes"
            :key="theme"
            class="flex px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal text-fakeBlack items-center">
              {{ theme }}
            </p>
          </div>
        </div>

        <!-- No theme -->
        <div
          v-else
          class="w-[12%] flex px-2 py-[6px] justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
        >
          <p class="text-xs font-normal text-fakeBlack items-center">
            {{ $t('No theme') }}
          </p>
        </div>

        <!-- updatedAt and Duration -->
        <div
          class="flex justify-end items-center gap-4"
          :class="training?.module_themes?.length ? '' : 'w-full'"
        >
          <!-- Training updatedAt -->
          <div
            class="flex items-center gap-1"
          >
            <div class="flex items-center">
              <Icon
                icon="mdi:update"
                height="16"
                width="16"
                class="text-darkGrey"
              />
            </div>

            <p class="text-darkGrey text-sm font-medium">
              {{ filters.formatDate(training?.updated_at, "DD/MM/YYYY") }}
            </p>
          </div>

          <!-- Training duration -->
          <div class="flex items-center gap-1">
            <div class="flex items-center">
              <Icon
                icon="mdi:clock-outline"
                height="16"
                width="16"
                class="text-darkGrey"
              />
            </div>

            <p class="text-darkGrey text-sm font-medium">
              {{ training?.all_modules_duration || $t('No duration') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Description -->
    <div
      v-if="training?.description"
      class="flex items-center self-stretch"
    >
      <p class="text-fakeBlack text-sm font-normal lg:max-h-[700px] lg:overflow-y-auto">
        {{ training?.description }}
      </p>
    </div>

    <!-- Content -->
    <div
      v-if="training?.modules_and_playlist?.length"
      class="flex flex-col items-start gap-2 slef-stretch"
    >
      <p class="text-fakeBlack text-base font-medium">
        {{ $t('Content') }}
      </p>

      <module-or-playlist-in-training
        v-if="!isDeleted"
        :training-content="training?.modules_and_playlist"
        @go-to-content-show="goToContentShow"
      />

      <module-or-playlist-in-training
        v-else
        :training-content="training?.modules_and_playlist"
      />
    </div>

    <!-- No Content in the training -->
    <div
      v-else
      class="flex flex-col items-start gap-2 slef-stretch"
    >
      <p class="text-fakeBlack text-base font-medium">
        {{ $t('Content') }}
      </p>

      <div class="w-full flex flex-col justify-center items-center py-10 slef-stretch">
        <p class="text-darkGrey text-sm text-center font-normal">
          {{ $t('No content in the training for the moment.') }}
        </p>
      </div>

      <module-or-playlist-in-training :training-content="training?.modules_and_playlist" />
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import ModuleOrPlaylistInTraining from '@/components/trainingApp/ModuleOrPlaylistInTraining.vue';
import filters from '@/tools/filters';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import { useRouter, useRoute } from 'vue-router';
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";

const props = defineProps({
  training: { type: Object, required: true },
  isDeleted: {type: Boolean, required: true}
})

const router = useRouter()

const route = useRoute()

const { reset, addNode, update } = useBreadcrumbsStore()

const goToContentShow = (content) => {
  router.push({ name: 'module_show', params: { id: content?.id }, query: { trainingId: props?.training?.id }})
}
</script>
