<template>
  <div class="w-full h-full flex flex-col items-center gap-5 sm:gap-10">
    <div class="w-full" />

    <v-tabs v-model="tab" grow color="primary" class="w-full max-w-[928px] xl:max-w-[1136px] border-b border-b-light-grey">
      <!-- Users list tab -->
      <v-tab value="users_list">
        <div class="flex items-center gap-2">
          <div>
            <Icon
              icon="mingcute:group-3-line"
              height="24"
              width="24"
              :class="tab === 'users_list' ? 'text-primary' : 'text-dark-grey'"
            />
          </div>

          <svn-text sm medium class="normal-case" :color="tab === 'users_list' ? 'fake-black' : 'dark-grey'">
            {{ $t('Users list') }}
          </svn-text>
        </div>
      </v-tab>

      <!-- Categories tab -->
      <v-tab value="categories">
        <div class="flex items-center gap-2">
          <div>
            <Icon
              icon="ph:tag-bold"
              height="24"
              width="24"
              :class="tab === 'categories' ? 'text-primary' : 'text-dark-grey'"
            />
          </div>

          <svn-text sm medium class="normal-case" :color="tab === 'categories' ? 'fake-black' : 'dark-grey'">
            {{ $t('Categories') }}
          </svn-text>
        </div>
      </v-tab>
    </v-tabs>

    <v-window v-model="tab" class="w-full max-w-[928px] xl:max-w-[1136px] sm:pb-[60px]">
      <v-window-item value="users_list">
        <div class="w-full h-full flex flex-col px-5 sm:px-6 pt-5 sm:pt-6 rounded-0 sm:!rounded-[5px] border-0 sm:!border-[0.5px] border-middle-grey">
          <div class="w-full items-center justify-between pb-4 hidden sm:flex">
            <svn-title h2 medium class="leading-10">
              {{ $t('Users') }}
              <span v-if="pagination">({{ pagination?.total_count }})</span>
            </svn-title>

            <svn-dropdown>
              <template #activator>
                <svn-button
                  prepend-icon="mdi-add"
                  :text="$t('Add users')"
                />
              </template>

              <template #dropdown>
                <v-list class="!py-0">
                  <!-- Add user button -->
                  <modal-add-new-user title="Add single user">
                    <template #button>
                      <v-list-item value="add-new-user">
                        {{ $t('Add a new user') }}
                      </v-list-item>
                    </template>
                  </modal-add-new-user>

                  <!-- Import users button -->
                  <modal-import-users>
                    <template #button>
                      <v-list-item value="import-user">
                        {{ $t('Import users') }}
                      </v-list-item>
                    </template>
                  </modal-import-users>
                </v-list>
              </template>
            </svn-dropdown>
          </div>

          <user-search @fetch="reloadAfterSearch" @fetch-users="fetchUsers" />

          <user-table
            v-if="users"
            class="overflow-x-auto"
            style="max-width: inherit"
          />

          <bkt-no-entity-in-table
            v-else-if="users?.length === 0"
            title="user"
          />

          <bkt-no-entity-from-index v-if="users?.length === 0" />

          <div v-if="!users" class="flex h-[30vh] justify-center items-center w-full">
            <svn-loader loading-size="lg" />
          </div>
        </div>
      </v-window-item>

      <v-window-item value="categories">
        <div class="w-full h-full flex flex-col gap-6 px-5 sm:px-6 lg:!px-0 pb-[60px]">
          <!-- Title & subtitle -->
          <div class="flex flex-col gap-2 items-start self-stretch">
            <svn-text base medium color="dark-grey">
              {{ $t('CATEGORIES AND TAGS') }}
            </svn-text>

            <svn-text sm normal color="dark-grey">
              {{ $t('Edit your categories and tags Aleph to fit your needs by easily editing employee data. Keep things organized and up-to-date with a few simple clicks.') }}
            </svn-text>
          </div>

          <!-- Tag categories list -->
          <div class="w-full max-w-[560px] flex flex-col gap-4 self-stretch mx-auto">
            <div
              v-for="(tagCategory, index) in tagCategories"
              :key="tagCategory?.id"
            >
              <tag-category-card 
                :current-tag-category="tagCategory" 
                :index="index"
                :tag-categories="tagCategories" 
                @recall-tagcategories="onRecallTagCategories"
              />
            </div>

            <!-- TagCategory to add -->
            <div
              v-if="addNewBlock"
              class="w-full flex flex-col p-4 items-end gap-4 border border-darkGrey rounded-[8px]"
            >
              <div class="w-full flex flex-col gap-4">
                <p class="text-darkGrey text-sm self-start font-medium uppercase">
                  {{ $t('Tag category') }}
                </p>
  
                <v-text-field
                  v-model="tagCategoryName"
                  :label="$t('Tag category')"
                  variant="outlined"
                  density="compact"
                  hide-details
                  class="input text-base font-normal"
                />
  
                <!-- Buttons -->
                <div class="flex justify-end gap-2">
                  <!-- Delete Tag Category -->
                  <dialog-validation
                    title="Are you sure you want to delete this label ?"
                    description="This is a permanent action."
                    icon="mdi:trash-can-outline"
                    :display-close="false"
                    primary-button-text="Yes, remove"
                    button-primary-color="negativeRed"
                    secondary-button-text="No, cancel"
                    @click-primary-button="deleteLabel"
                  >
                    <template #button>
                      <v-btn
                        color="negativeRed"
                        variant="outlined"
                        :text="$t('Delete')"
                        hide-details
                        height="40"
                        class="normal-case"
                      />
                    </template>
                  </dialog-validation>
  
                  <!-- Save button -->
                  <v-btn
                    color="primary"
                    variant="outlined"
                    :text="$t('Save')"
                    hide-details
                    :disabled="!tagCategoryName"
                    height="40"
                    class="normal-case"
                    @click="saveLabelAndExit(tagCategoryName)"
                  />
                </div>
              </div>
            </div>
  
            <!-- Add Category button -->
            <div class="self-end">
              <v-btn
                prepend-icon="mdi-plus"
                color="primary"
                variant="flat"
                :text="$t('Add category')"
                hide-details
                height="40"
                class="normal-case"
                @click="addLabel"
              />
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>

  <!-- Floating action button -->
  <svn-floating-action-button v-if="tab === 'users_list'" :menu="true" icon="ic:baseline-plus">
    <template #default>
      <div class="flex flex-col gap-4 items-end">
        <!-- Add a single user button -->
        <modal-add-new-user title="Add single user">
          <template #button>
            <div class="flex gap-2 justify-center items-center">
              <p class="text-white text-xl font-semibold text">
                {{ $t('Add single user') }}
              </p>

              <v-btn
                icon
                variant="flat"
                color="primary"
              >
                <Icon
                  icon="mdi-person-add"
                  height="21"
                  width="21"
                />
              </v-btn>
            </div>
          </template>
        </modal-add-new-user>

        <!-- Import users button -->
        <modal-import-users>
          <template #button>
            <div class="flex gap-2 justify-center items-center">
              <p class="text-white text-xl font-semibold text">
                {{ $t('Import users') }}
              </p>

              <v-btn
                icon
                variant="flat"
                color="primary"
              >
                <Icon
                  icon="material-symbols:upload"
                  height="21"
                  width="21"
                />
              </v-btn>
            </div>
          </template>
        </modal-import-users>
      </div>
    </template>
  </svn-floating-action-button>

  <!-- <bkt-index-skeleton body-class="rounded-0 sm:!rounded-[5px] border-0 border-[0.5px] border-middle-grey">
    <template #title>
      <p class="mb-5 sm:!mb-0 font-medium">
        {{ $t("People") }}
        <span v-if="pagination">({{ pagination.total_count }})</span>
      </p>
    </template>

    <template #create-index>
      <v-btn
        color="primary"
        type="employee"
        @click="openAddUserPopUp"
      >
        + {{ $t("Add users") }}
      </v-btn>
    </template>

    <template #body>
      <user-search @fetch="reloadAfterSearch" />

      <user-table
        v-if="users"
        class="overflow-x-auto"
        style="max-width: inherit"
      />

      <bkt-no-entity-in-table
        v-else-if="users?.length === 0"
        title="user"
      />

      <bkt-no-entity-from-index v-if="users?.length === 0" />

      <div v-if="!users" class="flex h-[30vh] justify-center items-center h-full w-full">
        <svn-loader loading-size="lg" />
      </div>
    </template>
  </bkt-index-skeleton> -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Icon } from "@iconify/vue";
import BktIndexSkeleton from "../../components/BktIndexSkeleton.vue";
import { storeToRefs } from "pinia";
import {  useUserStore } from "@/store/user.js";
import UserSearch from "./UserSearch.vue";
import UserTable from "./UserTable.vue";
import i18n from "@/plugins/i18n";
import { useSnackbar } from '@/store/snackbar';
import { useCompanyStore } from '@/store/company';
import TagCategoryCard from '@/components/companyApp/TagCategoryCard.vue';
import DialogValidation from '@/components/BktPopUp/Dialogs/DialogValidation.vue';
import BktNoEntityFromIndex from "../../components/BktNoEntityFromIndex.vue";
import BktNoEntityInTable from "@/components/BktNoEntityInTable.vue";
import ModalAddNewUser from '@/components/BktPopUp/Modals/ModalAddNewUser.vue';
import ModalImportUsers from '@/components/BktPopUp/Modals/ModalImportUsers.vue';

const snackbar = useSnackbar();
const { fetchUsers, updateParams, setUsers } = useUserStore();
const { createTagCategory, deleteTagCatagory } = useCompanyStore();

const { tagCategories } = storeToRefs(useCompanyStore());
const { users, pagination } = storeToRefs(useUserStore());

const tab = ref('users_list');
const tagCategoryName = ref(null);
const addNewBlock = ref(false);
const allTagCategories = ref([]);

onMounted(() => {
  fetchUsers();
  setCategories();
});

const addLabel = () => {
  addNewBlock.value = true
}

const onRecallTagCategories = () => {
  allTagCategories.value = tagCategories.value
}

const saveLabelAndExit = async(name) => {
  try {
    await createTagCategory(name)
    addNewBlock.value = false
    allTagCategories.value = tagCategories.value
    tagCategoryName.value = null
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Tag Category has been created.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {é
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating Tag category.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const deleteLabel = async(id) => {
  if (id) {
    try {
      await deleteTagCatagory(id)
      allTagCategories.value.splice(allTagCategories.value.findIndex(category => category?.id === id), 1)
      snackbar.setBgColor('fakeBlack')
      snackbar.setMsg('Your Tag category has been deleted.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
      snackbar.displaySnackBar()
    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error deleting Tag category.')
      snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
      snackbar.displaySnackBar()
    }
  } else {
    addNewBlock.value = false
    tagCategoryName.value = null
  }
}

const setCategories = () => {
  allTagCategories.value = Object.create(tagCategories.value)
}

const reloadAfterSearch = (params) => {
  setUsers(null);
  updateParams({
    title: params?.title,
    "page[number]": 1,
  });
  fetchUsers();
}
</script>
