<template>
  <v-btn
    :disabled="disabled"
    :class="isMobile ? 'rounded-2xl bg-primary fixed bottom-4 right-8 flex flex-row justify-center items-center p-4' :
      'rounded bg-primary flex flex-row justify-center items-center mr-4'"
    :height="isMobile ? 56 : 46"
    @click.prevent="emit('submit-button-clicked')"
  >
    <p
      class="font-medium text-sm text-white normal-case mr-2"
      :class="isMobile ? 'text-sm' : 'text-xs'"
    >
      {{ $t('Submit') }}
    </p>
    
    <div>
      <Icon
        class="text-white"
        icon="ic-sharp-send"
        width="16"
        height="16"
      />
    </div>
  </v-btn>
</template>
    
<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { Icon } from '@iconify/vue';

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  disabled: {type: Boolean, default: false},
});

const emit = defineEmits(['submit-button-clicked'])
</script>