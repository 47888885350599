<template>
  <div class="w-full flex flex-col">
    <!-- Bandeau rouge si c'est trash -->
    <trash-warnning
      v-if="isDeleted"
      title="This training is in trash."
      @restore="restoreDeletedItems(learnTraining?.id)"
      @delete-permanently="deletePermanentlyDeletedItems(learnTraining?.id)"
    />

    <!-- Title & buttons -->
    <div class="w-full flex flex-col justify-center items-center relative pt-2 px-2 h-fit xl:!h-[164px]">
      <!-- Breadcrumbs -->
      <div class="absolute hidden lg:flex lg:px-[180px] -ml-[60%]">
        <!-- <bkt-breadcrumbs>
          <div class="w-[150px]">
            {{ learnTraining?.title }}
          </div>
        </bkt-breadcrumbs> -->
      </div>

      <!-- Buttons -->
      <div
        v-if="amICreatorOfEntityOrAdmin(id)"
        class="flex items-center gap-2 absolute top-2 right-2 sm:!top-[43px] sm:!right-[5%]"
      >
        <!-- Edit training button  -->
        <svn-icon-button
          v-if="!isDeleted"
          icon="material-symbols:edit-outline"
          variant="noOutlined"
          circle
          :button-size="!isMobile ? 'xl' : 'lg'"
          color-class="!bg-white"
          :class="!isMobile ? 'text-fakeBlack shadow' : 'text-darkGrey'"
          @click="editTrainingDetails"
        />

        <!-- Edit & delete buttons -->
        <div
          v-if="!isDeleted"
          class="flex flex-col justify-center items-center p-1 bg-veryLightGrey/50 rounded-full"
        >
          <svn-dots-button
            circle
            variant="hoverNoPrimary"
            class="w-[50px] h-[50px] flex items-center justify-center hover:bg-very-light-grey rounded-full
              cursor-pointer shadow-none sm:shadow-md"
          >
            <template #items>
              <v-list-item
                value="send_reminder"
                @click="openSendReminderEmailDialog(route?.params?.id)"
              >
                {{ $t('Send reminder email') }}
              </v-list-item>

              <v-list-item
                value="delete_training"
                class="text-negativeRed"
                @click="openDeleteTrainingDialog(route?.params?.id)"
              >
                {{ $t('Delete training') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>

      <!-- Title -->
      <div
        class="flex flex-col justify-center w-[300px] pt-[48px] pb-5
          sm:!py-[64.5px] sm:!items-center lg:!w-[400px] xl:!w-[600px]"
      >
        <p class="text-fakeBlack text-center text-xl font-semibold line-clamp-2">
          {{ learnTraining?.title }}
        </p>
      </div>
    </div>

    <!-- Tabs -->
    <v-tabs
      v-if="amICreatorOfEntityOrAdmin(id)"
      v-model="tab"
      class="sticky top-0"
      color="fakeBlack"
      :grow="isMobile ? true : false"
      align-tabs="center"
    >
      <!-- Overview tab -->
      <v-tab
        value="overview"
        color="primary"
        class="border-b border-b-lightGrey"
      >
        <p class="normal-case">
          {{ $t('Overview') }}
        </p>
      </v-tab>

      <!-- Analytics tab -->
      <v-tab
        value="analytics"
        color="primary"
        class="border-b border-b-lightGrey"
      >
        <p class="normal-case">
          {{ $t('Analytics') }}
        </p>
      </v-tab>
    </v-tabs>

    <!-- Windows -->
    <v-window
      v-model="tab"
    >
      <v-window-item
        value="overview"
        class="mb-24"
      >
        <overview
          :training="learnTraining"
          :is-deleted="isDeleted"
        />
      </v-window-item>

      <v-window-item
        v-if="amICreatorOfEntityOrAdmin(id)"
        value="analytics"
        class="mb-24"
      >
        <analytics
          :status="learnTraining.status"
          :training-id="route.params.id.toString()"
          :training-completion-all="trainingCompletionAll"
          :completion-by-module="completionByModule"
          :is-deleted="isDeleted"
          :training-participants-list="trainingParticipantsList"
          @get-training-participants="getTrainingParticipantsList(route?.params?.id, $event)"
        />
      </v-window-item>
    </v-window>

    <dialog-send-reminder-email-training
      ref="sendReminderEmailDialog"
      @send-reminder="sendEmailReminders"
    />

    <dialog-delete-training
      ref="deleteTrainingDialog"
      @delete-training="deleteLearnTraining"
    />

    <!-- Scroll to top button -->
    <svn-floating-action-button
      v-if="isMobile"
      square
      to-top
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Icon } from '@iconify/vue';
import Overview from './Overview.vue';
import Analytics from './Analytics.vue';
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from '@/store/mobile';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import DialogSendReminderEmailTraining from '@/components/BktPopUp/Dialogs/learn/DialogSendReminderEmailTraining.vue';
import DialogDeleteTraining from '@/components/BktPopUp/Dialogs/learn/DialogDeleteTraining.vue'
import { useLearnTrainingStore } from '@/store/learn-trainings';
import {useAnalyticTrainingsStore} from "@/store/analytic-trainings.js";
import TrashWarnning from "@/components/TrashWarnning.vue"

onMounted(async() => {
  resetTraining()

  getTrainingParticipantsList(route?.params?.id, '')
  getCompletionByModule(route?.params?.id)
  getTrainingCompletionAll(route?.params?.id)

  try {
    await fetchTraining(route?.params?.id)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }

  isDeleted.value = learnTraining.value.status === "trash"

})

const analyticTrainingsStore = useAnalyticTrainingsStore()
const {getTrainingParticipantsList, getCompletionByModule, getTrainingCompletionAll} = analyticTrainingsStore
const { completionByModule, trainingCompletionAll, trainingParticipantsList } = storeToRefs(analyticTrainingsStore)

const tab = ref(null)

const route = useRoute()

const router = useRouter()

const alignTop = ref(false)

const isDeleted = ref(false)

const snackbar = useSnackbar()

const sendReminderEmailDialog = ref(null)

const deleteTrainingDialog = ref(null)

const { fetchTraining, resetTraining, deleteTraining, sendReminder, deleteTrainingPermanently, restoreTraining } = useLearnTrainingStore()

const { reset, addNode, update } = useBreadcrumbsStore();

const { amICreatorOfEntityOrAdmin } = useUserStore();

const {id} = storeToRefs(useUserStore())

const { isMobile } = storeToRefs(useMobileStore())

const { learnTraining } = storeToRefs(useLearnTrainingStore())

const editTrainingDetails = () => {
  router.push({ name: "training_edit", params: { id: learnTraining?.value?.id } })
}

const openSendReminderEmailDialog = () => {
  sendReminderEmailDialog.value.dialogReminderEmailTraining = true
}

const sendEmailReminders = async () => {
  try {
    await sendReminder(learnTraining?.value?.id)
    sendReminderEmailDialog.value.dialogReminderEmailTraining = false

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Reminder email has been sent')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Reminder email has not been sent')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar()
  }
}

const openDeleteTrainingDialog = () => {
  deleteTrainingDialog.value.dialogDeleteTraining = true
}

const deleteLearnTraining = async() => {
  try {
    await deleteTraining(learnTraining?.value?.id)

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('This training has been deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar()

    router.push({ name: "trainings" });
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const restoreDeletedItems = async (id) => {
  try {
    await restoreTraining(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Training restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    isDeleted.value = false

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteTrainingPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Training deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    await router.push({ name: "trainings" });

  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>
.shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
</style>
