<template>
  <v-card
    v-if="campaignFeedbacks"
    class="w-full flex flex-col rounded-lg p-6 md:max-w-4xl"
  >
    <v-card-title
      class="flex flex-col items-center p-0
        md:flex-row md:justify-start"
    >
      <my-interview-card-header
        :campaign-deadline="campaignDeadline"
        :campaign-type="campaignType"
        :campaign-name="campaignTitle"
      />
    </v-card-title>

    <div
      v-for="(feedback, index) in campaignFeedbacks"
      :key="feedback.id"
    >
      <div class="relative flex flex-col w-full">
        <div class="flex flex-col w-full md:mt-6 md:flex md:flex-row">
          <v-divider
            class="border-opacity-100 m-3 md:hidden"
          />

          <div
            class="w-full flex flex-col justify-center sm:!px-0
              md:flex-row md:items-start md:!justify-start md:max-w-[20%] md:w-full md:mr-8"
          >
            <div class="flex flex-col justify-center items-center mb-2 md:mb-0 md:mr-2">
              <svn-avatar
                :avatar="feedback?.participant?.avatar?.['30']"
                :lastname="feedback?.participant?.fullname.split(' ')[0]"
                :firstname="feedback?.participant?.fullname.split(' ')[1]"
                size="md"
              />
            </div>

            <div class="flex flex-col">
              <v-list-item-title
                class="font-medium w-fit max-w-[80%] self-center text-md text-center bkt-unwrap
                  md:!text-start md:!px-0 md:self-start md:max-w-[110px]"
              >
                <svn-tooltip>
                  <template #activator-content>
                    {{ feedback?.participant?.fullname }}
                  </template>

                  <template #tooltip-content>
                    {{ feedback?.participant?.fullname }}
                  </template>
                </svn-tooltip>
              </v-list-item-title>

              <div
                class="text-center w-full text-xs font-normal flex flex-row justify-center
                md:justify-start items-center md:self-start md:!text-start mb-5 md:mb-0"
              >
                <p
                  data-testid="employee"
                  class="max-w-[110px]"
                >
                  {{ feedback?.participant?.job_title }}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full md:flex-row">
            <v-divider
              vertical
              class="md:border-opacity-100 md:ml-2 md:my-3 md:block hidden opacity-100"
            />

            <div class="relative flex flex-col w-full">
              <div
                v-if="feedback.interviews.length !== 0"
                class="flex flex-wrap md:mt-10 mx-2"
              >
                <template
                  v-for="interview in feedback.interviews"
                  :key="interview.id"
                >
                  <div
                    v-if="interview?.employee"
                    class="flex flex-row items-center p-1 mx-2 my-1 bg-veryLightGrey rounded"
                  >
                    <svn-avatar
                      :avatar="interview?.employee?.avatar?.['30']"
                      :firstname="interview?.employee?.fullname.split(' ')?.[0]"
                      :lastname="interview?.employee?.fullname.split(' ')?.[1]"
                      size="md"
                      class="self-center ml-1 mr-1 md:mr-3"
                    />

                    <div class="flex flex-col justify-center items-start">
                      <v-list-item-title
                        class="font-medium text-sm text-start w-full max-w-[125px] text-ellipsis"
                      >
                        <svn-tooltip>
                          <template #activator-content>
                            {{ interview?.employee?.fullname }}
                          </template>

                          <template #tooltip-content>
                            {{ interview?.employee?.fullname }}
                          </template>
                        </svn-tooltip>
                      </v-list-item-title>

                      <interview-status-chip
                        :status="interview?.status"
                        text-statustest-id="manager"
                      />
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-else
                class="mb-4 font-medium text-sm text-darkGrey md:ml-4"
              >
                <p class="block text-center text-ellipsis md:text-start">
                  {{ $t('Choose reviewers before continue.') }}
                </p>
              </div>

              <div class="flex flex-col mt-4 mx-4 md:flex-row md:self-end sm:!mx-0">
                <v-btn
                  v-if="feedback.interviews"
                  :variant="feedback.interviews.length > 0 ? 'outlined' : 'elevated'"
                  class="normal-case mb-2 mr-0 md:!mr-4 md:mb-0 h-10"
                  append-icon="mdi-account-plus"
                  color="primary"
                  data-testid="reviewer"
                  @click="managePairs(feedback?.id, feedback?.interviews)"
                >
                  {{ $t("Manage reviewers") }}
                </v-btn>
                <v-btn
                  v-if="feedback.interviews.length > 0"
                  variant="outlined"
                  :disabled="feedback.interviews.filter((interview) => interview.status === 'submitted').length === 0"
                  class="normal-case ml-0 md:ml-4 h-10"
                  :color="feedback.interviews.filter((interview) => interview.status === 'submitted').length !== 0 ?
                    'primary' : 'darkGrey'"
                  data-testid="consultAnswers"
                  :to="{
                    name: 'feedback_answer',
                    params: { id: feedback?.id },
                  }"
                >
                  {{ $t("Consult answers ()", {
                    answers: feedback.interviews.filter((interview) => interview.status === 'submitted').length
                  }) }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <svn-dots-button
          circle
          variant="hoverNoPrimary"
          class="absolute -right-0 top-5 sm:top-4"
        >
          <template #items>
            <v-list-item
              value="see_participant_profile"
              @click="goToPath($t('See \'s profile', { reviewers: feedback?.participant?.fullname }),
                               '/people/user_show/' + feedback?.participant?.id)"
            >
              {{ $t('See participant profile') }}
            </v-list-item>

            <v-list-item
              value="send_invitation_email"
              @click="sendInvitationEmail('invite', feedback?.id)"
            >
              {{ $t('Send invitation email') }}
            </v-list-item>

            <v-list-item
              value="send_reminder_email"
              @click="sendInvitationEmail('reminder', feedback?.id)"
            >
              {{ $t('Send reminder email') }}
            </v-list-item>

            <v-list-item
              value="set_person_incharge"
              @click="openSetAnotherInterviewer(feedback?.participant?.id)"
            >
              {{ $t('Set another person in charge') }}
            </v-list-item>
          </template>
        </svn-dots-button>

        <v-divider
          v-if="index !== campaignFeedbacks.length - 1"
          class="hidden border-opacity-100 md:block opacity-100 md:mx-6"
        />
      </div>
    </div>
  </v-card>
</template>

<script setup>
  import { useCampaignStore } from "@/store/campaign.js";
  import MyInterviewCardHeader from "@/components/interviewApp/InterviewCardHeader.vue";
  import axiosService from "@/tools/axios-service.js";
  import { storeToRefs } from "pinia";
  import InterviewStatusChip from "../InterviewStatusChip.vue";
  import { useUserStore } from "@/store/user.js";
  import { usePopUpStore } from "@/store/pop-up.js";
  import { useRouter } from "vue-router";
  import useCampaigns from "@/tools/useCampaigns.js";
  import i18n from "@/plugins/i18n";
  import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";

  // Props
  const props = defineProps({
    campaignId: {type: Number, required: true},
    campaignTitle: {type: String, required: true},
    campaignDeadline: {type: String, required: true},
    campaignType: {type: String, required: true},
    campaignFeedbacks: {type: Array, required: true}
  })

  const router = useRouter();

  const { goToPath } = useBreadcrumbsStore()
  const {closePopUp, openPopUp} = usePopUpStore();
  const { fetchCampaign } = useCampaignStore();

  const { campaign } = storeToRefs(useCampaignStore());

  const { id: currentUserId } = storeToRefs(useUserStore());

  const { getBgByCampaignStatus, getColorByCampaignStatus } = useCampaigns();

  const managePairs = (feedbackId, feedbackInterviews) => {
    openPopUp({
      componentName: "PopUpManagePairs",
      title: i18n.global.t('Manage reviewers'),
      icon: '',
      participantId: currentUserId.value,
      feedbackId: feedbackId,
      campaignId: props.campaignId,
      feedbackInterviews: feedbackInterviews,
    customClass: "h-[573px] lg:w-[800px] !justify-normal",})
  }

  const sendInvitationEmail = async (emailType, feedbackId) => {
    try {
      await axiosService.get(
        `/api/v2/interview_app/campaigns/${props.campaignId}/send_notification_email`,
        {
          params: {
            email_type: emailType,
            feedback_id: feedbackId,
          },
        }
      );

      if (emailType === "invite") {
        openPopUp({
          componentName: "pop-up-action-done",
          icon: "noto-incoming-envelope",
          title: i18n.global.t(`Invitation is on its way !`),
          description: "The employee will receive an email in a few moments.",
          textConfirm: "Great !",
          appName: "Interviews",
          confirm() {
            closePopUp();
          },
        });
      } else {
        openPopUp({
          componentName: "pop-up-action-done",
          icon: "noto-bell",
          title: i18n.global.t(`Reminder is on its way !`),
          description: "The employee will receive an email in a few moments.",
          textConfirm: "Great !",
          appName: "Interviews",
          confirm() {
            closePopUp();
          },
        });
      }
    } catch (error) {

    }
  };

  const openSetAnotherInterviewer = (employeeId) => {
      openPopUp({
        componentName: "pop-up-set-another-interviewer",
        title: campaign?.campaign_type === 'OneToOne'
          ? i18n.global.t('Select another interviewer')
          : i18n.global.t('Select another person in charge'),
        icon: 'ic-baseline-person',
        campaignId: props.campaignId,
        employeeId: employeeId,
        callback: () => {
          fetchCampaign(props.campaignId);
        },
        close() {},
      });
  };
</script>
