<template>
  <div class="mb-16">
    <div
      class="elevation-2 rounded-xl p-6 flex flex-col gap-8"
    >
      <div class="flex flex-col gap-3">
        <div
          class="font-normal text-base"
        >
          {{ $t("Choose your settings") }}
        </div>

        <div class="flex flex-col gap-1 mx-3">
          <v-switch
            :model-value="isCampaignRecurrent"
            color="primary"
            density="compact"
            hide-details="auto"
            @update:model-value="updateIsCampaignRecurrent"
          >
            <template #label>
              <span class="font-medium text-sm text-black">
                {{ $t('Recurrent campaign') }}
              </span>
            </template>
          </v-switch>
          <p class="text-dark-grey text-sm">
            {{ $t('A recurrent campaign is a campaign which is repeated several times.') }}
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <div
          class="font-normal text-base"
        >
          {{ $t("Define your campaign period") }}
        </div>

        <div class="flex flex-col gap-8">
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row items-center"
              :class="isCampaignRecurrent ? 'space-x-[33px]' : ''"
            >
              <div
                class="flex flex-col gap-4"
                :class="isCampaignRecurrent ? 'w-[60%]' : 'w-full'"
              >
                <div
                  class="flex items-center gap-2"
                  style="min-width: 110px"
                >
                  <svn-tooltip>
                    <template #activator-content>
                      <Icon
                        icon="mdi:information-outline"
                        class="text-dark-grey h-4 w-4"
                      />
                    </template>

                    <template #tooltip-content>
                      {{ $t('The campaign will become available from the start date.') }}
                    </template>
                  </svn-tooltip>

                  <span class="font-medium text-sm"> {{ $t("Campaign will start on :") }} </span>
                </div>

                <svn-date-picker
                  v-model="campaignDraft.startline"
                  variant="outlined"
                  color="primary"
                  base-color="middleGrey"
                />
              </div>

              <div
                v-if="isCampaignRecurrent"
                class="flex flex-col w-[40%] gap-4"
              >
                <div
                  class="flex items-center gap-2"
                  style="min-width: 110px"
                >
                  <svn-tooltip>
                    <template #activator-content>
                      <Icon
                        icon="mdi:information-outline"
                        class="text-dark-grey h-4 w-4"
                      />
                    </template>

                    <template #tooltip-content>
                      {{ $t('The recurrent campaign will start at the time defined below.') }}
                    </template>
                  </svn-tooltip>

                  <span class="font-medium text-sm">
                    {{ $t("At :") }}
                  </span>
                </div>

                <div class="flex flex-row space-x-2 justify-center items-center">
                  <v-select
                    v-model="campaignDraft.will_launch_at"
                    :label="$t('Time')"
                    :items="time"
                    hide-details
                    variant="outlined"
                    color="primary"
                    base-color="middleGrey"
                  />

                  <v-combobox
                    v-model="campaignDraft.recurrent_time_zone"
                    :label="$t('Zone')"
                    :items="citiesList"
                    hide-details
                    variant="outlined"
                    color="primary"
                    base-color="middleGrey"
                  />
                </div>
              </div>
            </div>

            <div
              class="flex flex-col gap-4"
              :class="isCampaignRecurrent ? 'w-[57%]' : 'w-full'"
            >
              <div
                class="flex items-center gap-2"
                style="min-width: 110px"
              >
                <svn-tooltip>
                  <template #activator-content>
                    <Icon
                      icon="mdi:information-outline"
                      class="text-dark-grey h-4 w-4"
                    />
                  </template>

                  <template #tooltip-content>
                    {{ $t('The end date is purely informative and the campaign will remain available thereafter.') }}
                  </template>
                </svn-tooltip>

                <span class="font-medium text-sm">
                  {{ isCampaignRecurrent ? $t("Campaign recurrences will end on :") : $t("Campaign will end on :") }}
                </span>
              </div>

              <svn-date-picker
                v-if="campaignDraft.isCampaignRecurrent"
                v-model="campaignDraft.recurrent_stop_at"
                color="primary"
                base-color="middleGrey"
                variant="outlined"
              />

              <svn-date-picker
                v-else
                v-model="campaignDraft.deadline"
                color="primary"
                base-color="middleGrey"
                variant="outlined"
              />

              <span
                v-if="isDeadlineBeforeStartline(campaignDraft.startline, campaignDraft.deadline, campaignDraft.is_recurrent)"
              >
                <v-divider
                  :thickness="2"
                  class="opacity-100 border-rose-600"
                />

                <p
                  class="text-xs ml-2"
                  style="color: #B3261E !important;"
                >
                  {{ $t('End date cannot be anterior to start date !') }}
                </p>
              </span>
            </div>

            <div
              v-if="campaignDraft.is_recurrent"
              class="flex flex-col gap-4"
            >
              <div
                class="flex flex-col gap-2"
                style="min-width: 110px"
              >
                <div class="flex items-center gap-2">
                  <svn-tooltip>
                    <template #activator-content>
                      <Icon
                        icon="mdi:information-outline"
                        class="text-dark-grey h-4 w-4"
                      />
                    </template>

                    <template #tooltip-content>
                      {{ $t('The duration of the campaign.') }}
                    </template>
                  </svn-tooltip>

                  <span class="font-medium text-sm"> {{ $t("Campaign will last : ") }} </span>
                </div>

                <div class="flex flex-row w-full mt-2">
                  <v-text-field
                    v-model="campaignDraft.deadline_number"
                    :label="$t('Enter a duration')"
                    class="sm:mr-5 sm:w-1/2"
                    type="number"
                    color="primary"
                    hide-details
                    base-color="middleGrey"
                    variant="outlined"
                    min="1"
                  />

                  <v-select
                    v-model="campaignDraft.deadline_duration_unit"
                    :label="$t('Duration unit')"
                    item-title="text"
                    item-value="value"
                    :items="durationUnits"
                    color="primary"
                    hide-details
                    base-color="middleGrey"
                    variant="outlined"
                    class="sm:w-1/2"
                  />
                </div>
                <span
                  v-if="campaignDraft.deadline_number <= 0"
                >
                  <p
                    class="text-xs -mt-4"
                    style="color: #B3261E !important;"
                  >
                    {{ $t('Duration number should be greater than 0') }}
                  </p>
                </span>
              </div>

              <div
                class="flex flex-col"
                style="min-width: 110px"
              >
                <div class="flex items-center gap-2">
                  <svn-tooltip>
                    <template #activator-content>
                      <Icon
                        icon="mdi:information-outline"
                        class="text-dark-grey h-4 w-4"
                      />
                    </template>

                    <template #tooltip-content>
                      {{ $t('The periodicity of the campaign.') }}
                    </template>
                  </svn-tooltip>

                  <span class="font-medium text-sm"> {{ $t("Repeat :") }} </span>
                </div>

                <div class="flex flex-col mt-2">
                  <v-select
                    v-model="campaignDraft.recreate_repetition"
                    :placeholder="repetition[1].text"
                    variant="outlined"
                    base-color="middleGrey"
                    color="primary"
                    :label="$t('Repeat')"
                    hide-details="auto"
                    item-title="text"
                    item-value="value"
                    :items="repetition"
                  />
                  <div
                    v-if="campaignDraft.recreate_repetition === 'every_month'"
                    class="w-full mt-4"
                    @mouseenter="updateDateTooltip(true)"
                    @mouseleave="updateDateTooltip(false)"
                  >
                    <svn-tooltip>
                      <template #activator-content>
                        <Icon
                          icon="mdi:information-outline"
                          class="text-dark-grey h-4 w-4"
                        />
                      </template>

                      <template #tooltip-content>
                        {{ $t('Select a day between 1st and 28th to be able to select the monthly repetition')  }}
                      </template>
                    </svn-tooltip>
                    <v-select
                      :model-value="campaignDraft.recreate_repetition_option || 'same_day'"
                      :disabled="isCampaignStartlineOnLastDays"
                      :placeholder="$t('Select period')"
                      :label="$t('Select period')"
                      item-title="text"
                      item-value="value"
                      variant="outlined"
                      base-color="middleGrey"
                      color="primary"
                      :items="periodicityMonth"
                      hide-details
                      @update:model-value="campaignDraft.recreate_repetition_option = $event"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360 || campaignDraft?.campaign_type === CampaignTypeEnum.SURVEY"
      >
        <div v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360">
          <v-card-title
            class="p-0 font-normal text-base"
          >
            {{ $t('Choose parameters') }}
          </v-card-title>

          <v-card-item
            class="p-0"
          >
            <div class="my-2 mx-3">
              <v-switch
                v-model="campaignDraft.can_choose_pairs"
                color="primary"
                density="compact"
                hide-details
              >
                <template #label>
                  <span class="font-medium text-black text-sm">
                    {{ $t('Allow the participant to choose his/her reviewer') }}
                  </span>
                </template>
              </v-switch>
              <p class="text-dark-grey text-sm">
                {{ $t('The participant can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any time.') }}
              </p>
            </div>

            <div class="my-2 mx-3">
              <v-switch
                v-model="campaignDraft.can_view_pairs_answers"
                color="primary"
                density="compact"
                hide-details
              >
                <template #label>
                  <span class="font-medium text-black text-sm">
                    {{ $t('Allow the participant to view reviewer answers') }}
                  </span>
                </template>
              </v-switch>
              <p class="text-dark-grey text-sm">
                {{ $t('The participant is able to view answers from his/her reviewers. Otherwise, only the person in charge of the feedback could see answers.') }}
              </p>
            </div>
            <div class="my-2 mx-3">
              <v-switch
                v-model="campaignDraft.can_self_evaluate"
                color="primary"
                density="compact"
                hide-details
              >
                <template #label>
                  <span class="font-medium text-black text-sm">
                    {{ $t('Self evaluation') }}
                  </span>
                </template>
              </v-switch>

              <p class="text-dark-grey text-sm">
                {{ $t('The participant has to answer the feedback questions too.') }}
              </p>
            </div>
          </v-card-item>
        </div>

        <div v-if="campaignDraft?.campaign_type === CampaignTypeEnum.SURVEY">
          <v-card-title
            class="p-0 font-normal text-base"
          >
            {{ $t('Choose parameters') }}
          </v-card-title>

          <v-card-item
            class="p-0"
          >
            <div class="my-2 mx-3">
              <v-switch
                v-model="campaignDraft.anonymize_answers"
                color="primary"
                density="compact"
                hide-details
              >
                <template #label>
                  <span class="font-medium text-black text-sm">
                    {{ $t('Anonymise answers') }}
                  </span>
                </template>
              </v-switch>

              <p class="text-dark-grey text-sm">
                {{ $t('Pictures and names of participants are anonymised.') }}
              </p>
            </div>
          </v-card-item>
        </div>
      </div>

      <div class="flex">
        <v-spacer />

        <v-btn
          :disabled="isDeadlineBeforeStartline(campaignDraft?.startline, campaignDraft?.deadline, campaignDraft.is_recurrent) || (
            isCampaignStartlineOnLastDays && campaignDraft.is_recurrent && campaignDraft.recreate_repetition === 'every_month'
            || campaignDraft.deadline_number <= 0
          )"
          :loading="campaignDraftLoading"
          color="primary"
          variant="elevated"
          @click="next"
        >
          <span
            class="normal-case text-sm flex items-center gap-2"
          >
            {{ $t("Next") }}
            <Icon
              :icon="'material-symbols:arrow-forward-rounded'"
              class="h-4 w-4"
            />
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {Icon} from "@iconify/vue";
import useFormTools from "@/tools/useFormTools.js";
import { useSnackbar } from "@/store/snackbar.js";
import citiesList from '../../../tools/world-cities';
import {useCampaignDraftStore} from "@/store/campaign-draft.js";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum";
import filters from "@/tools/filters.js";
import { debounce } from "lodash";
import { ref, computed, onMounted } from "vue";
import i18n from "@/plugins/i18n";
import moment from "moment";

const emit = defineEmits(["next"]);

const { campaignDraft, loading: campaignDraftLoading } = storeToRefs(useCampaignDraftStore());
const { updateCampaignDraft, fetchCampaignDraft } = useCampaignDraftStore();
const { anyNullInArray, contactNullStr } = useFormTools();
const snackbar = useSnackbar();
const dateTooltip = ref(false)

const isCampaignRecurrent = ref(false);

onMounted(async () => {
  isCampaignRecurrent.value = campaignDraft.value.is_recurrent
});

const durationUnits = ref([
  {
    text: i18n.global.t("Days"),
    value: 'days'
  },
  {
    text: i18n.global.t("Weeks"),
    value: 'weeks'
  },
  {
    text: i18n.global.t("Months"),
    value: 'months'
  },
  {
    text: i18n.global.t("Years"),
    value: 'years'
  },
]);

const repetition = ref([
  {
    text: i18n.global.t("Every week"),
    value: 'every_week'
  },
  {
    text: i18n.global.t("Every month"),
    value: 'every_month'
  },
  {
    text: i18n.global.t("Every 6 months"),
    value: 'every_six_month'
  },
  {
    text: i18n.global.t("Every year"),
    value: 'every_year'
  },
]);

const periodicityMonth = [
  {
    text: i18n.global.t("Same day each month"),
    value: 'same_day'
  },
  {
    text: i18n.global.t("First day of the month"),
    value: 'first_day'
  },
  {
    text: i18n.global.t("Last day of the month"),
    value: 'last_day'
  },
];

const time = ref([
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
])

const updateDateTooltip = (bool) => {
  if (campaignDraft.value.startline?.split('-')[2] === '29' ||
    campaignDraft.value.startline?.split('-')[2] === '30' || campaignDraft.value.startline?.split('-')[2] === '31') {
    dateTooltip.value = bool
  } else {
    dateTooltip.value = false
  }
}

const isDeadlineBeforeStartline = (startline, deadline, isRecurrent) => {
  return !isRecurrent && startline && deadline && filters.compareDates(startline, deadline)
}

const next = debounce(async () => {

  if (campaignDraft.value.is_recurrent) {

    if (anyNullInArray([campaignDraft.value.startline])) {
      let msg = "Hey, please fill";
      msg += contactNullStr([[campaignDraft.value.startline, ", starting date"]]);

      snackbar.setStatus("error", msg).checkStatus();
    }
    else {
      try {
        await updateCampaignDraft({
          startline: moment(campaignDraft.value.startline).format("YYYY-MM-DD"),
          deadline: null,
          is_recurrent: campaignDraft.value.is_recurrent,
          deadline_duration_unit: campaignDraft.value.deadline_duration_unit,
          deadline_number: parseInt(campaignDraft.value.deadline_number),
          recreate_repetition_option: campaignDraft.value.recreate_repetition_option,
          recreate_repetition: campaignDraft.value.recreate_repetition,
          will_launch_at: campaignDraft.value.will_launch_at,
          recurrent_time_zone: campaignDraft.value.recurrent_time_zone,
          recurrent_stop_at: campaignDraft.recurrent_stop_at,
          can_choose_pairs: campaignDraft.value.can_choose_pairs,
          can_self_evaluate: campaignDraft.value.can_self_evaluate,
          can_view_pairs_answers: campaignDraft.value.can_view_pairs_answers,
          anonymize_answers: campaignDraft.value.anonymize_answers,
        });

        fetchCampaignDraft();
        emit("next");
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(', '))
            .checkStatus();
        } else {
          snackbar
            .setStatus("error", "Our server is experiencing some problems")
            .checkStatus();
        }
      }
    }
  }
  else {
    if (anyNullInArray([campaignDraft.value.startline, campaignDraft.value.deadline])) {
      let msg = "Hey, please fill";
      msg += contactNullStr([
        [campaignDraft.value.startline, ", starting date"],
        [campaignDraft.value.deadline, ", ending date"],
      ]);

      snackbar.setStatus("error", msg).checkStatus();
    }
    else {
      try {
        await updateCampaignDraft({
          startline: moment(campaignDraft.value.startline).format("YYYY-MM-DD"),
          deadline: moment(campaignDraft.value.deadline).format("YYYY-MM-DD"),
          is_recurrent: false,
          deadline_duration_unit: campaignDraft.value.deadline_duration_unit,
          deadline_number: parseInt(campaignDraft.value.deadline_number),
          recreate_repetition_option: campaignDraft.value.recreate_repetition_option,
          recreate_repetition: campaignDraft.value.recreate_repetition,
          can_choose_pairs: campaignDraft.value.can_choose_pairs,
          can_self_evaluate: campaignDraft.value.can_self_evaluate,
          can_view_pairs_answers: campaignDraft.value.can_view_pairs_answers,
          anonymize_answers: campaignDraft.value.anonymize_answers,
        });
        fetchCampaignDraft();
        emit("next");
      } catch (error) {
        const errorData = error?.response?.data?.message;

        if (errorData.length > 0) {
          snackbar
            .setStatus("error", errorData.join(', '))
            .checkStatus();
        } else {
          snackbar
            .setStatus("error", "Our server is experiencing some problems")
            .checkStatus();
        }
      }
    }
  }
}, 200);

const updateIsCampaignRecurrent = (e) => {
  isCampaignRecurrent.value = !isCampaignRecurrent.value

  campaignDraft.value.is_recurrent = e
}

const isCampaignStartlineOnLastDays = computed(() => {
  let formattedDate = moment(campaignDraft?.value?.startline).format("YYYY-MM-DD");

  if (formattedDate !== 'Invalid date') {
    return (
      formattedDate?.split('-')?.[2] === '29' ||
      formattedDate?.split('-')?.[2] === '29' ||
      formattedDate?.split('-')?.[2] === '29'
    )
  }
})
</script>
