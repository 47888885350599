<template>
  <div class="flex flex-col p-4 items-end gap-4 border border-darkGrey rounded-[8px]">
    <!-- Editing Mode Tag category -->
    <div
      v-if="isEditing"
      class="w-full flex flex-col gap-2"
    >
      <p class="text-darkGrey text-sm self-start font-medium uppercase">
        {{ $t('Tag category') }} 
      </p>

      <div class="w-full flex flex-col gap-1">
        <v-text-field
          v-model="tagCategory.name"
          :placeholder="$t('Tag category')"
          variant="outlined"
          density="compact"
          :error="!tagCategory.name || checkUniqueCategoryName(tagCategory.name)"
          hide-details
          class="input text-base font-normal"
        />

        <!-- Error messages -->
        <div
          v-if="!tagCategory.name || checkUniqueCategoryName(tagCategory.name)"
          class="w-full flex justify-start items-center gap-2 text-negativeRed"
        >
          <div>
            <Icon
              icon="ic:baseline-info"
              width="16"
              height="16"
              class="rotate-180"
            />
          </div>

          <p class="text-xs font-normal">
            {{ !tagCategory.name ?
              $t("category name can't be empty") : $t('This category name already exists') }}
          </p>
        </div>
      </div>
    </div>

    <!-- View Mode -->
    <div
      v-else
      class="w-full flex flex-col items-end gap-4 self-stretch"
    >
      <div class="flex justify-end items-center self-stretch gap-4 truncate">
        <p class="text-fakeBlack text-sm font-medium flex-1 overflow-ellipsis">
          {{ tagCategory?.name }}
        </p>

        <!-- Buttons -->
        <div class="flex flex-end items-start gap-2">
          <!-- Edit Tag Category -->
          <svn-icon-button
            variant="noOutlined"
            button-size="sm"
            icon="mdi:pencil-outline"
            @click="editLabel(tagCategory?.id)"
          />

          <!-- Delete Tag Category -->
          <dialog-validation
            title="Are you sure you want to delete this label ?"
            description="This is a permanent action."
            icon="mdi:trash-can-outline"
            :display-close="false"
            primary-button-text="Yes, remove"
            button-primary-color="negativeRed"
            secondary-button-text="No, cancel"
            @click-primary-button="deleteTagCategory()"
          >
            <template #button>
              <svn-icon-button
                variant="noOutlined"
                button-size="sm"
                icon="mdi:trash-can-outline"
              />
            </template>
          </dialog-validation>
        </div>
      </div>
    </div>

    <!-- Editing Mode Tags -->
    <div
      v-if="isEditing"
      class="w-full flex flex-col gap-2"
    >
      <p class="text-darkGrey text-sm self-start font-medium uppercase">
        {{ $t('Tags') }}
      </p>

      <!-- Tags list & Buttons -->
      <div class="flex flex-col items-end gap-2 self-stretch">
        <!-- Tags list -->
        <div
          v-for="(tag, index) in tags"
          :key="tag?.id"
          :class="!tag._destroy ? 'flex flex-col self-stretch gap-1' : ''"
        >
          <div v-if="!tag._destroy" class="w-full flex items-center gap-2">
            <v-text-field
              v-model="tag.tagName"
              :placeholder="$t('Tag')"
              variant="outlined"
              density="compact"
              hide-details
              :error="!tag.tagName || tags.filter(el => el.tagName === tag.tagName).length > 1"
              class="input text-base font-normal"
            />

            <div
              class="cursor-pointer"
              @click="removeTagFromCategory(tag?.id, index)"
            >
              <Icon
                icon="mdi-close"
                height="24"
                width="24"
                class="text-darkGrey hover:!text-fake-black"
              />
            </div>
          </div>

          <!-- Error messages -->
          <div
            v-if="!tag._destroy && !tag.tagName || tags.filter(el => el.tagName === tag.tagName).length > 1"
            class="w-full flex justify-start items-center gap-2 text-negativeRed"
          >
            <div>
              <Icon
                icon="ic:baseline-info"
                width="16"
                height="16"
                class="rotate-180"
              />
            </div>

            <p class="text-xs font-normal">
              {{ !tag.tagName ?
                $t("The tag name cannot be empty.") : $t('This tag already exists in this category') }}
            </p>
          </div>
        </div>


        <!-- Add new tag button -->
        <v-btn
          color="primary"
          variant="outlined"
          prepend-icon="mdi-plus"
          :text="$t('New tag')"
          hide-details
          height="40"
          class="normal-case self-start"
          @click="addNewTag"
        />

        <!-- Buttons -->
        <div
          class="flex flex-end items-start gap-2"
        >
          <!-- Cancel button -->
          <v-btn
            color="primary"
            variant="text"
            :text="$t('Cancel')"
            hide-details
            height="40"
            class="normal-case"
            @click="cancelAndReset(index)"
          />

          <!-- Save button -->
          <v-btn
            color="primary"
            variant="outlined"
            :text="$t('Save')"
            hide-details
            height="40"
            :disabled="!tagCategory.name ||
              checkUniqueCategoryName(tagCategory.name) ||
              checkUniqueTagName(tags) ||
              checkEmptyTagName(tags)"
            class="normal-case"
            @click="updateTagAndTagCategory()"
          />
        </div>
      </div>
    </div>

    <!-- View Mode -->
    <div
      v-else
      class="flex flex-col gap self-stretch gap-2 truncate"
    >
      <p
        v-for="tag in tags?.slice(0, tags?.indexOf(tags?.[10]))"
        :key="tag?.id"
        class="text-darkGrey text-sm font-medium overflow-ellipsis"
      >
        {{ tag?.tagName }}
      </p>

      <div v-if="showAll" class="flex flex-col gap self-stretch gap-2 truncate">
        <p
          v-for="tag in tags?.slice(tags?.indexOf(tags?.[10]))"
          :key="tag?.id"
          class="text-darkGrey text-sm font-medium overflow-ellipsis"
        >
          {{ tag?.tagName }}
        </p>
      </div>

      <div v-if="tags?.length > 10" class="flex items-center gap-1" @click="showAll = !showAll">
        <svn-text sm normal>
          {{ showAll ? $t('Show less') : $t('Show all') }}
        </svn-text>

        <div>
          <Icon
            icon="ic:baseline-arrow-drop-down"
            height="24"
            width="24"
            class="text-fake-black"
            :class="showAll ? 'rotate-180' : ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { useCompanyStore } from '@/store/company';
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import DialogValidation from '../BktPopUp/Dialogs/DialogValidation.vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';

const emit = defineEmits(['recall-tagcategories'])
const props = defineProps({
  currentTagCategory: { type: Object, default: null },
  index: { type: Number, default: null },
  tagCategories: { type: Object, default: null }
})

onMounted(() => {
  tagCategoryName.value = props.currentTagCategory.name
  tagCategory.value = props.currentTagCategory
  tags.value = props.currentTagCategory.tags
})

const { isMobile } = storeToRefs(useMobileStore())

const { updateTagCategoryWithTags, deleteTagCatagory } = useCompanyStore()

const snackbar = useSnackbar()
const tagCategory = ref({})
const tagCategoryName = ref(null)
const tags = ref([])
const isEditing = ref(false)
const showAll = ref(false)

const addNewTag = () => {
  tags.value.push({ tagName: '' })
}
const updateTagAndTagCategory = async() => {
  try {
    const res = await updateTagCategoryWithTags(tagCategory.value, tags.value)
    tagCategory.value = res.data
    tagCategoryName.value = tagCategory.value.name
    tags.value = res.data.tags
    snackbarSavedTagCategoryName()
    isEditing.value = false
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating category name.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const editLabel = () => {
  isEditing.value = true
}

const deleteTagCategory = async() => {
  try {
    await deleteTagCatagory(tagCategory.value.id)
    emit('recall-tagcategories')
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Your Tag category has been deleted.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting Tag category.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
}

const removeTagFromCategory = async(tagId, index) => {
  if (tagId) {
    tags.value.forEach((tag) => {
      if (tag.id == tagId) { 
        tag['_destroy'] = '1'
      }
    })
  } else if (!tagId && index >= 0) {
    tags.value = tags.value.filter((tag, tagIndex) => tagIndex != index)
  }
}

const snackbarSavedTagCategoryName = () => {
  snackbar.setBgColor('fakeBlack')
  snackbar.setMsg('Tag Category name or tags has been updated.')
  snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
  snackbar.displaySnackBar()
}

const cancelAndReset = async() => {
  isEditing.value = false
  tagCategory.value.name = tagCategoryName.value
  tags.value.forEach(tag => {
    if (tag['_destroy']) {
      delete tag['_destroy']
    }
  })
}

const checkUniqueCategoryName = (name) => {
  let categoryNames = props.tagCategories.map(category => category.name)
  if (categoryNames.filter(x => x === name).length > 1) {
    return true
  }
  else {
    return false
  }
}

const checkUniqueTagName = (tags) => {
  let tagNames = tags.map(tag => tag.tagName)
  let counter = {}
  tagNames.forEach(x => {
    if (counter[x]) {
      counter[x] += 1
    }
    else {
      counter[x] = 1
    }
  })
  let values = Object.values(counter)
  if (values.find(x => x > 1)) {
    return true
  }
  else {
    return false
  }
}

const checkEmptyTagName = (tags) => {
  let found = tags.find(x => !x.tagName)

  if (found) {
    return true
  }
  else {
    return false
  }
}

</script>

<style scoped>
* :deep(.v-field__outline) {
  border-radius: 8px;
}
</style>