<template>
  <svn-modal-skeleton
    ref="modalFaceToFace"
    fullscreen
    persistent
  >
    <template #activator-div>
      <div class="w-full flex sm:justify-end">
        <svn-button
          v-if="learnModule?.submission?.status === 'in_progress'"
          :text="$t('Continue evaluation')"
          class="min-w-full sm:min-w-[296px]"
          @click="startEvaluation"
        />

        <svn-button
          v-else-if="learnModule?.submission?.status === 'acquired'"
          :text="$t('Restart evaluation')"
          class="min-w-full sm:min-w-[296px]"
          @click="startEvaluation"
        />

        <svn-button
          v-else
          :text="$t('Start evaluation')"
          class="min-w-full sm:min-w-[296px]"
          @click="startEvaluation"
        />
      </div>
    </template>

    <template #header="{ close }">
      <div class="w-full h-[72px] flex justify-end items-center gap-2 self-stretch px-4 sticky top-0 border-b border-b-middle-grey">
        <div class="flex justify-end items-center gap-2 flex-1">
          <svn-icon-button
            icon="material-symbols:download"
            variant="noOutlined"
            button-size="lg"
            @click="initPdfDownload"
          />

          <svn-icon-button
            icon="ic:baseline-close"
            variant="noOutlined"
            button-size="lg"
            @click="close"
          />
        </div>
      </div>
    </template>

    <template #content-body>
      <div class="w-full flex flex-col px-5 py-6 items-center mx-auto self-stretch md:max-w-[758px] md:py-8 mb-[200px]">
        <div class="flex flex-col items-start gap-6 self-stretch">
          <div class="flex flex-col items-start gap-2 self-stretch">
            <svn-title h1 semi-bold class="text-center self-center">
              {{ $t('Face-to-face evaluation') }}
            </svn-title>

            <svn-title h2 regular class="!text-center self-center">
              {{ learnModule?.title }}
            </svn-title>
          </div>

          <div class="flex flex-col items-center gap-8 self-stretch">
            <face-to-face-evaluation-question-card
              v-for="question in inputsFaceToFace"
              :key="question?.id"
              :question="question"
              @update-question-answer="updateQuestionAnswer(question, $event)"
            />

            <!-- Select your expert -->
            <div class="flex flex-col p-6 md:p-10 gap-4 items-start self-stretch border-[0.5px] border-middle-grey rounded-[8px]">
              <div class="w-full flex flex-col items-start gap-[10px] self-stretch">
                <svn-title h3 medium>
                  {{ $t('Select your expert') }}
                </svn-title>

                <svn-text sm regular color="dark-grey">
                  {{ $t('Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.') }}
                </svn-text>
              </div>

              <div>
                <svn-autocomplete
                  v-if="users"
                  v-model="learnModule.submission.expert"
                  avatar
                  :label="$t('My expert')"
                  :error="expertError.error"
                  :error-messages="expertError.errorMessage"
                  :hide-details="expertError.hideDetails"
                  :items="users"
                  @update:model-value="updateSelectedExpert"
                  @intersect="loadMoreUsers"
                  @input="searchExpert"
                  @clear="clearSearch"
                />
              </div>
            </div>

            <!-- Validate answers -->
            <svn-dialog-skeleton
              ref="dialogModuleAcquired"
              :bind="false"
              persistent
              dialog-custom-class="max-w-[400px]"
              icon="noto:star-struck"
              :primary-button-text="nextModuleId ? 'Go to next module': 'Go back to the catalog'"
              :title="$t('One more module acquired 👏')"
              :display-close="false"
            >
              <template #activator-div>
                <svn-button
                  class="min-w-full"
                  :loading="loading"
                  :text="$t('Validate answers')"
                  @click="validateEvaluation"
                />
              </template>

              <template #description>
                <svn-text sm regular color="dark-grey">
                  {{ $t('Congratulations, keep it up! Your answers have been sent to') }}

                  <span v-if="selectedExpert" class="font-medium">
                    {{ selectedExpert?.firstname }} {{ selectedExpert?.lastname }}.
                  </span>
                </svn-text>
              </template>

              <template #primary-button>
                <svn-button
                  :text="nextModuleId ? $t('Go to next module') : $t('Go back to the catalog')"
                  class="min-w-full"
                  append-icon="ic:baseline-arrow-forward"
                  @click="handleSubmit"
                />
              </template>
            </svn-dialog-skeleton>
          </div>
        </div>
      </div>
    </template>
  </svn-modal-skeleton>
</template>

<script setup>
import { watch, ref, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user";
import axiosService from "@/tools/axios-service.js";
import { useLearnModuleStore } from "@/store/learn-module";
import FaceToFaceEvaluationQuestionCard from '../../../learnApp/FaceToFaceEvaluationQuestionCard.vue';
import { useRoute, useRouter } from 'vue-router';
import { useActionCable } from "@/store/cable.js";
import { debounce } from 'lodash';
import { downloadPdf, downloadPdfWithoutToast } from "@/tools/pdf-proxy-service.js";
import { LearnInputType } from '@/constants/types';

const emit = defineEmits(['start-evaluation']);

const props = defineProps({
  nextModuleId: { type: Number, default: null },
})

onMounted(async() => {
  try {
    await fetchInputsFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id);
    const data = await searchEmployess();
    users.value = data.users
    selectedExpertId.value = learnModule?.value?.submission?.expert?.id;

    currentPage.value = data.meta.pagination?.current_page;
    totalPages.value = data.meta.pagination?.total_pages;
  } catch (error) {
    console.log(error)
  }
})

const { cable } = storeToRefs(useActionCable());
const { learnModule, inputsFaceToFace, submission } = storeToRefs(useLearnModuleStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { searchEmployess } = useUserStore();
const { fetchInputsFaceToFace, validateSubmissionFaceToFace } = useLearnModuleStore();

const users = ref([])
const currentPage = ref(1);
const totalPages = ref(null);
const dialogModuleAcquired = ref(null);
const expertError = ref({ error: false, errorMessage: '', hideDetails: true })
const selectedExpertId = ref(null);
const selectedExpert = ref({});
const inputChannel = ref(null);
const responseChannel = ref(null);
const modalFaceToFace = ref(null);
const loading = ref(false);

const startEvaluation = () => {
  emit('start-evaluation');
}

const initPdfDownload = async() => {
  const unscrollElement = document.getElementsByClassName("v-overlay-scroll-blocked")?.[0];

  if (unscrollElement) {
    unscrollElement.style.position = "static";
  }

  try {
    await downloadPdf(`/pdf/face_to_face_answer/${learnModule?.value?.id}/${learnModule?.value?.editor_content_id}`, `face_to_face_evaluation${learnModule?.value?.id}`)
  } catch (error) {
    console.log(error);
  }
}

const validateEvaluation = async() => {
  let firstNotAnsweredQuestion = inputsFaceToFace?.value?.find(el =>
    el.type !== LearnInputType.PARAGRAPH &&
    !learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === el?.id)?.text
  );

  if (!selectedExpertId?.value) {
    expertError.value = { error: true, errorMessage: 'Please select an expert to continue.', hideDetails: false }
  }
  if (firstNotAnsweredQuestion) {
    for (let question of inputsFaceToFace?.value) {
      if (!learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === question?.id)?.text) {
        question.error = true;
      }
    }
    document.getElementById(`question_${firstNotAnsweredQuestion?.id}`).scrollIntoView({ behavior: 'smooth' });

    snackbar.setMsg('Please fill all the required (*) fields.');
    snackbar.setBgColor('negativeRed');
    snackbar.setCustomClass('mb-10');
    snackbar.displaySnackBar();
  }

  if (selectedExpertId?.value && !firstNotAnsweredQuestion) {
    try {
      loading.value = true;

      let res = await downloadPdfWithoutToast(`/pdf/face_to_face_answer/${learnModule?.value?.id}/${learnModule?.value?.editor_content_id}`, `face_to_face_evaluation${learnModule?.value?.id}`);
      let pdfName = null

      if (import.meta.env.VITE_PDF_PROXY_URL) {
        let pdfProxyUrl = import.meta.env.VITE_PDF_PROXY_URL.replace('/pdf', '')  + '/'
        pdfProxyUrl = pdfProxyUrl.replace('http:', "https:")

        let pdfUrl = res?.href.replace('http:', "https:")
        pdfName = pdfUrl.replace(pdfProxyUrl, "")
      }

      let params = {
        learn_module_id: learnModule?.value?.id,
        pdf_name: pdfName,
      }

      await getExpertDetails();
      await validateSubmissionFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, params);

      dialogModuleAcquired.value.dialog = true;
      loading.value = false;
    } catch (error) {
      loading.value = false;
      console.log(error);

      snackbar.setMsg('An error occurred, please try again');
      snackbar.setBgColor('negativeRed');
      snackbar.setCustomClass('mb-10');
      snackbar.displaySnackBar();
    }
  }
}

const updateSelectedExpert = (expertId) => {
  if (!inputChannel.value) {
    websocketMethod()
  }
  inputChannel.value.update(expertId)
}

const updateQuestionAnswer = (data, answer) => {
  let question = inputsFaceToFace?.value?.find(el => el?.id === data?.id);

  if (question?.error && learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === question?.id)?.text) {
    question.error = false;
  }
  if (!responseChannel.value) {
    websocketResponseChannel()
  }
  responseChannel.value.update(answer)
}

const websocketResponseChannel = () => {
  const subscribeOptions = {
    channel: "Learn::ResponseChannel", submission_id: submission?.value?.id
  }
  responseChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },
    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },
    received: function(data) {
      if (data.status === "update") {
        const index = submission?.value?.learn_responses_answers?.findIndex(x => x.id === data.entity.id);
        submission.value.learn_responses_answers[index].text = data.entity.text;
        learnModule.value.submission.learn_responses_answers[index].text = data.entity.text;
      }
    },
    update: async function(data) {
      responseChannel.value.perform('update', data);
      submission.value.status = "in_progress"
    },
  });
}

const loadMoreUsers = async() => {
  if (currentPage?.value < totalPages?.value) {
    currentPage.value++
    try {
      const data = await searchEmployess('', currentPage?.value)
      const loadMoreUsers = data.users
      users.value = [ ...users?.value, ...loadMoreUsers ]
    } catch (error) {
      console.log(error)
    }
  }
}

const handleSubmit = () => {
  if (props?.nextModuleId) {
    dialogModuleAcquired.value.dialog = false;

    let query;

    if (route?.query?.trainingId) {
      query = { trainingId: route?.query?.trainingId }
    } else if (route?.query?.playlistId) {
      query = { playlistId: route?.query?.playlistId }
    }

    router.push({ name: 'module_show', params: { id: props?.nextModuleId }, query }).then(() => {
      router.go(0);
    });
  } else {
    router.push({ name: 'catalog' }).then(() => {
      router.go(0);
    })
  }
}

const getExpertDetails = async() => {
  if (selectedExpert?.value?.id !== selectedExpertId?.value) {
    try {
      const { data } = await axiosService.get(`/api/v1/users/${selectedExpertId?.value}`);
      selectedExpert.value = {};
      selectedExpert.value = data?.user;
    } catch (error) {
      console.log(error)
    }
  }
}

const searchExpert = debounce(async(search) => {
  try {
    const data = await searchEmployess(search)
    users.value = data.users
  } catch (error) {
    console.log(error)
  }
}, 250);

const clearSearch = debounce(async(e) => {
  try {
    const data = await searchEmployess('')
    users.value = data.users
  } catch (error) {
    console.log(error)
  }
}, 200);

const websocketMethod = () => {
  const subscribeOptions = {
    channel: "Learn::SubmissionChannel", submission_id: submission?.value?.id
  }
  inputChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },
    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },
    received: function(data) {
      if (data.status === "update") {
        submission.value = JSON.parse(data.entity);
        learnModule.value.submision = JSON.parse(data.entity);
      }
    },
    update: async function(id) {
      let data = {
        expert_id: id,
      }
      inputChannel.value.perform('update', data);
      selectedExpertId.value = id;
    },
  });
}
onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  responseChannel?.value?.unsubscribe();
});

watch(selectedExpertId, (newValue, oldValue) => {
  if (newValue && expertError?.value?.error) {
    expertError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

defineExpose({
  modalFaceToFace
})
</script>

<style scoped>
* :deep(.v-input__details) {
  padding-left: 0px;
  padding-right: 0px;
}
</style>