<template>
  <div class="flex justify-evenly sm:justify-start sm:mb-0">
    <div
      v-if="props.setInterview && props.setInterview.employee_interview"
      class="flex items-center mr-1"
    >
      <p class="mr-1 text-xs">
        {{ $t("IE") }}
      </p>

      <span
        :class="
          getBgByCampaignStatus(
            props.setInterview.employee_interview.status
          )
        "
        class="w-3 h-3 inline-block"
      />
    </div>

    <div
      v-if="props.setInterview && props.setInterview.manager_interview"
      class="flex items-center mr-1"
    >
      <p class="mr-1 text-xs">
        {{ $t("IR") }}
      </p>

      <span
        :class="
          getBgByCampaignStatus(
            props.setInterview.manager_interview?.status
          )
        "
        class="w-3 h-3 inline-block"
      />
    </div>

    <div
      v-if="props.setInterview && props.setInterview.crossed_interview"
      class="flex items-center mr-1"
    >
      <p
        :class="
          getColorByCampaignStatus(
            props.setInterview.crossed_interview?.status
          )
        "
        class="mr-1 text-xs"
      >
        {{ $t("CR") }}
      </p>

      <span
        :class="
          getBgByCampaignStatus(
            props.setInterview.crossed_interview?.status
          )
        "
        class="w-3 h-3 inline-block"
      />
    </div>
  </div>
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";

const { getBgByCampaignStatus } = useCampaigns();
const { getColorByCampaignStatus } = useCampaigns();

const props = defineProps({
  setInterview: { type: Object, default: null },
});
</script>