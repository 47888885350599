<template>
  <div
    class="flex flex-col rounded-10px justify-start bg-white items-center h-screen sm:!max-h-[650px]"
    style="box-shadow: 1px 2px 25px 1px #787878;"
  >
    <div
      class="self-end mt-6 mr-6"
      @click.stop="$emit('close-drawer')"
    >
      <Icon
        class="cursor-pointer"
        icon="mdi-close"
        height="24"
      />
    </div>

    <div class="w-full flex flex-col justify-center items-center pt-3 pb-10 bg-white z-50 sticky top-0 border-b border-b-lightGrey">
      <p class="text-xl font-semibold text-fakeBlack">
        {{ $t('Updates') }}
      </p>
    </div>

    <div
      v-if="history.length !== 0"
      class="w-full h-full overflow-y-auto"
    >
      <v-infinite-scroll
        :items="history"
        :empty-text="$t('No more history !')"
        class="overflow-hidden"
        @load="loadMoreHistory"
      >
        <template #default>
          <div
            v-for="hist in history"
            :key="hist?.id"
            class="w-full flex flex-row border-b border-b-lightGrey"
          >
            <svn-avatar
              :avatar="hist?.user?.avatar?.['50']"
              :firstname="hist?.user?.fullname"
              :lastname="''"
              size="md"
              class="ml-6 mr-4 mt-2"
            />

            <div class="flex flex-col justify-start items-start mt-2 mr-6 mb-4">
              <p class="text-sm text-fakeBlack font-medium">
                {{ hist.title }}
              </p>

              <div class="flex flex-row justify-center items-center self-start">
                <p class="text-xs text-darkGrey font-normal mb-2 mr-2">
                  {{ getDate(hist?.created_at) }}
                </p>

                <p class="text-xs text-darkGrey font-normal mb-2">
                  {{ getTime(hist?.created_at) }}
                </p>
              </div>

              <div class="flex flex-row self-start items-center">
                <interview-status-chip
                  :status="hist?.old_value"
                  :with-bg-and-border="true"
                />

                <Icon
                  icon="mdi-arrow-right"
                  class="w-5 h-5 mr-2 text-darkGrey"
                />

                <interview-status-chip
                  :status="hist?.new_value"
                  :with-bg-and-border="true"
                />
              </div>
            </div>
          </div>
        </template>

        <template #loading>
          <svn-loader v-if="history.length !== pagination.total_count" loading-size="lg" />
        </template>
      </v-infinite-scroll>
    </div>

    <div
      v-else
      class="mx-auto my-auto flex flex-col justify-center items-center"
    >
      <p class="text-sm text-darkGrey font-medium">
        {{ $t('No history for the moment.') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import moment from "moment";
import InterviewStatusChip from "@/components/interviewApp/InterviewStatusChip.vue";
import { useSnackbar } from "@/store/snackbar";

const props = defineProps({
  interviewId: {type: Number, required: true}
})

const pagination = ref(null);

const snackbar = useSnackbar();

const history = ref([]);

defineEmits(['close-drawer'])

onMounted(async() => {
  try {
    const { data } = await axios.get(`/api/v2/interview_app/interviews/${props.interviewId}/histories`)
    history.value = data?.histories.reverse();
    pagination.value = data?.meta?.pagination;
  } catch (error) {
    snackbar.setBgColor('negativeRed');
    snackbar.setMsg('Error getting the updates history');
    snackbar.displaySnackBar();
  }
})

const getDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss [UTC]").format('DD/MM/YYYY');
}

const getTime = (time) => {
  return moment(time, "YYYY-MM-DD HH:mm:ss [UTC]").format('hh:mm:a');
}

const loadMoreHistory = async() => {
  if (pagination.value.next_page > pagination.value.current_page) {
    try {
      const { data } = await axios.get(`/api/v2/interview_app/interviews/${props.interviewId}/histories?page[number]=${pagination.value.current_page + 1}`)
      history.value = [...history.value, ...data?.histories];
      pagination.value = data?.meta?.pagination;
    } catch (error) {
      snackbar.setBgColor('negativeRed');
      snackbar.setMsg('Error getting the updates history');
      snackbar.displaySnackBar();
    }
  }
}
</script>
