<template>
  <div class="w-full flex flex-col relative bg-white">
    <!-- Header component -->
    <my-roadmaps-header
      :user="user"
      :archived="archived"
      :unarchived="unarchived"
    />

    <!-- Desktop view -->
    <bkt-index-skeleton body-class="bkt-bg-white rounded mb-32 px-0 pt-0 sm:!pt-5 pb-0 hidden sm:block">
      <template #title>
        <p class="mb-5 hidden sm:block font-medium sm:!mb-0">
          {{ $t("Targets") }}
        </p>
      </template>

      <template #create-index>
        <div class="flex-row justify-center hidden sm:!flex sm:!justify-end mt-5 sm:!mt-0 h-fit">
          <bkt-new-target-button
            from="my_roadmaps"
            :employees="[user?.id]"
          />
        </div>
      </template>

      <template #body>
        <div
          v-if="user && user.id"
          class="w-full relative"
        >
          <v-tabs
            v-model="tab"
            class="sticky top-0 text-darkGrey"
            color="fakeBlack"
            align-tabs="center"
          >
            <v-tab value="current">
              <div class="flex flex-row justify-center items-center">
                <p>
                  {{ $t('Current targets') }}
                </p>
                <span
                class="px-2 rounded-[5px] text-sm ml-2 "
                :class="[tab !== 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
                >
                  {{ unarchived?.length || 0 }}
                </span>
              </div>
            </v-tab>

            <v-tab value="archived">
              <div class="flex flex-row justify-center items-center">
                <p>
                  {{ $t('Archived targets') }}
                </p>
                <span
                  class="px-2 rounded-[5px] text-sm ml-2 bg-error"
                  :class="[tab === 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
                >
                  {{ archived?.length || 0 }}
                </span>
              </div>
            </v-tab>
          </v-tabs>

          <v-window
            v-model="tab"
          >
            <v-window-item value="current">
              <my-roadmaps-table
                :targets="unarchived"
                :pagination="unarchivedPagination"
                type="unarchived"
                @reload-page="fetchUnarchived"
                @update="update"
                @delete="deleteTarget($event, 'unarchived')"
              />
            </v-window-item>

            <v-window-item value="archived">
              <my-roadmaps-table
                :targets="archived"
                :pagination="archivedPagination"
                type="archived"
                @reload-page="fetchArchived"
                @update="update"
                @delete="deleteTarget($event, 'archived')"
              />
            </v-window-item>
          </v-window>

          <div class="w-[300px] hidden xl:block absolute -top-[10px] right-0 pr-5">
            <my-roadmaps-search
              @fetch="reloadAfterSearch"
              :trashes-targets="trashes"
              @search-items="toggleSearchDeletedItems"
              @restore="restoreDeletedItems"
              @deletePermanently="deletePermanentlyDeletedItems"
            />
          </div>
        </div>
      </template>
    </bkt-index-skeleton>

    <!-- Mobile view -->
    <div
      v-if="user && user.id"
      class="w-full relative block sm:hidden"
    >
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        class="sticky top-0 border-b border-b-lightGrey text-darkGrey"
        color="fakeBlack"
        align-tabs="center"
      >
        <v-tab value="current">
          <div class="flex flex-row justify-center items-center">
            <p>
              {{ $t('Current targets') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
              :class="[tab !== 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
            >
              {{ unarchived?.length || 0 }}
            </span>
          </div>
        </v-tab>

        <v-tab value="archived">
          <div class="flex flex-row justify-center items-center">
            <p>
              {{ $t('Archived targets') }}
            </p>

            <span
              class="px-2 rounded-[5px] text-sm ml-2 bg-lightGrey"
              :class="[tab === 'current' ? 'text-darkGrey bg-veryLightGrey' : 'text-fakeBlack bg-lightGrey' ]"
            >
              {{ archived?.length || 0 }}
            </span>
          </div>
        </v-tab>
      </v-tabs>

      <!-- Windows -->
      <v-window
        v-model="tab"
      >
        <v-window-item
          value="current"
          class="mb-24"
        >
          <my-roadmaps-table
            :targets="unarchived"
            :pagination="unarchivedPagination"
            type="unarchived"
            @reload-page="fetchUnarchived"
            @update="update"
          />
        </v-window-item>

        <v-window-item
          value="archived"
          class="mb-24"
        >
          <my-roadmaps-table
            :targets="archived"
            :pagination="archivedPagination"
            type="archived"
            @reload-page="fetchArchived"
            @update="update"
          />
        </v-window-item>
      </v-window>

      <!-- Scroll to top button -->
      <svn-floating-action-button
        square
        mini
        to-top
        :double-icon="true"
      />


      <!-- New target button -->
      <svn-floating-action-button
        default
        :text="$t('New target')"
        icon="mdi-plus"
        @click="openPopUpCreateTarget"
      />
    </div>
  </div>
</template>

<script setup>
import axiosService from "@/tools/axios-service.js";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import MyRoadmapsHeader from "@/components/roadmapApp/MyRoadmapsHeader.vue";
import { useUserStore } from "@/store/user.js";
import MyRoadmapsSearch from "@/components/roadmapApp/MyRoadmapsSearch.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import MyRoadmapsTable from "@/components/roadmapApp/myRoadmaps/table.vue";
import { useRoute } from "vue-router";
import { usePopUpStore } from "@/store/pop-up";
import {useSnackbar} from "@/store/snackbar.js";
import i18n from "@/plugins/i18n.js";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import { debounce } from "lodash";
import { useMobileStore } from "@/store/mobile";

const route = useRoute();

const { openPopUp } = usePopUpStore();
const { addNode, reset, update: updateBreadcrumb } = useBreadcrumbsStore()
const { deletePermanentlyRoadmapTarget, restoreRoadmapTarget, deleteRoadmapTarget } = useRoadmapTargetStore();
const currentUser = storeToRefs(useUserStore());
const { isMobile } = storeToRefs(useMobileStore())
const snackbar = useSnackbar();
const user = ref(null);
const tab = ref('current');

const unarchived = ref(null);
const unarchivedPagination = ref(null);
const archived = ref(null);
const trashes = ref([]);
const archivedPagination = ref(null);
const alignTop = ref(false)

onMounted(async () => {
  await update();

  let title = route.fullPath.split('/').length >= 4 ?
    i18n.global.t("user's roadmaps", { user: user.value?.fullname }) :
    i18n.global.t('My Roadmap')

  if (reset(route.fullPath)) {
    addNode(title, route.fullPath)
    updateBreadcrumb()
  }
  await fetchTrashes()
});

const reloadAfterSearch = async({title}) => {
  if (tab.value === 'current') {
    fetchUnarchived(1, title)
  } else {
    fetchArchived(1, title)
  }
}

const update = async () => {
  if (route.params.employee_id) {
    const res = await axiosService.get(
      `/api/v1/users/${route.params.employee_id}`
    );

    user.value = res.data.user;
  }
  else {
    user.value = {
      id: currentUser.id.value,
      firstname: currentUser.firstname.value,
      lastname: currentUser.lastname.value,
      avatar: currentUser.avatar.value,
      job_title: currentUser.job_title.value,
    };
  }

  try {
    fetchUnarchived()
    await fetchArchived()
  } catch (e) {
    useSnackbar().setStatus("error").displaySnackBar(e?.message);
  }
};

const deleteTarget = async(id, type) => {
  await deleteRoadmapTarget(id)
  if (type === 'unarchived') {
    const trash = unarchived.value.find(trash => trash.id === id)
    trash.deleted_at = new Date()
    trashes.value.push(trash)
    unarchived.value = unarchived.value.filter(trash => trash.id !== id)
  } else if (type === 'archived') {
    const trash = archived.value.find(trash => trash.id === id)
    trash.deleted_at = new Date()
    trashes.value.push(trash)
    archived.value = archived.value.filter(trash => trash.id !== id)
  }
}

const openPopUpCreateTarget = () => {
  openPopUp({
    componentName: 'pop-up-create-target',
    icon: '',
    title: '',
    subtitle: '',
    customClass: 'xl:w-[800px] px-0',
    usersFromOverlay: null,
  })
}

const fetchUnarchived = async (page = 1, title = '') => {
  unarchived.value = null
  const unarchivedReq = await axiosService.get(
      `/api/v1/roadmaps/employees/${user.value.id}/unarchived_target`,
      {
        params: {
          'page[number]': page,
          title
        }
      }
  );
  unarchived.value = unarchivedReq?.data?.objective_elements;
  unarchivedPagination.value = unarchivedReq?.data?.meta?.pagination
}

const fetchTrashes = async (title = null) => {
  const trashesReq = await axiosService.get(`/api/v1/roadmaps/employees/${user.value.id}/target_trashes`,
    {
      params: {
        title
      }
    }
  );
  trashes.value = trashesReq?.data?.objective_elements
}

const fetchArchived = async (page = 1, title = '') => {
  archived.value = null
  const archivedReq = await axiosService.get(
      `/api/v1/roadmaps/employees/${user.value.id}/archived_target`,
      {
        params: {
          'page[number]': page,
          title
        }
      }
  );

  archived.value = archivedReq?.data?.objective_elements;
  archivedPagination.value = archivedReq?.data?.meta?.pagination
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashes(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    await restoreRoadmapTarget(id)
    unarchived.value.push(trashes.value.find(trash => trash.id === id))
    trashes.value = trashes.value.filter(trash => trash.id !== id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Target restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePermanentlyRoadmapTarget(id)
    trashes.value = trashes.value.filter(trash => trash.id !== id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Target deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

</script>
