<template>
  <div class="w-full mt-4">
    <div class="flex">
      <span class="flex-1 text-gray-500">
        {{ templateType != 'Survey' ?
          $t("employee name's Interview with interviewer name") :
          $t('Survey - x participants') }}
      </span>

      <span class="flex-1 self-end text-right text-gray-500">
        {{ $t("Deadline : 00/00/0000") }}
      </span>
    </div>

    <div
      class="mt-8 bkt-unwrap bkt-v-html-format"
      v-html="description"
    />

    <div
      v-if="isValidLink"
      class="mt-4"
    >
      <iframe
        id="ytplayer"
        class="mx-auto"
        type="text/html"
        width="640"
        height="360"
        :src="
          'https://www.youtube.com/embed/' +
            video?.match(
              /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
            )[1]
        "
      />
    </div>

    <div>
      <component
        :is="question.componentName"
        :id="idx"
        v-for="(question, idx) in questionsComponent"
        :key="idx"
        :question="question.data"
        :loop-idx="idx"
        class="my-8"
      />
    </div>
  </div>
</template>

<script>
import { useInterviewStore } from "@/store/interview.js";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Chapter from "@/components/interviewApp/interview_answers/ChapterAnswer.vue";
import OpenQuestion from "@/components/interviewApp/template/Show/OpenQuestion.vue";
import Rating from "@/components/interviewApp/template/Show/Rating.vue";
import Mcq from "@/components/interviewApp/template/Show/Mcq.vue";
import ParagraphAnswer from "@/components/interviewApp/interview_answers/ParagraphAnswer.vue";
import RoadmapBlock from "@/components/interviewApp/interview_answers/RoadmapBlock.vue";
import { InterviewQuestionType } from '@/constants/types';

export default {
  components: {
    Chapter,
    OpenQuestion,
    Rating,
    Mcq,
    RoadmapBlock,
    ParagraphAnswer
  },
  props: ["interviewQuestions", "title", "description", "video", "templateType"],
  setup(props) {
    const { interviewForm } = storeToRefs(useInterviewStore());

    const isValidLink = computed(() => {
      return props.video && props.video.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return props.interviewQuestions.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER) componentName = "chapter";
        else if (question.type === InterviewQuestionType.OPEN)
          componentName = "open-question";
        else if (question.type === InterviewQuestionType.RATING) componentName = "rating";
        else if (question.type === InterviewQuestionType.MCQ) componentName = "mcq";
        else if (question.type === InterviewQuestionType.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question.type === InterviewQuestionType.UPDATE_ROADMAP)
          componentName = "roadmap-block";
        else if (question.type === InterviewQuestionType.CREATE_ROADMAP)
          componentName = "roadmap-block";

        return { data: question, componentName };
      });
    });

    return {
      interviewForm,
      questionsComponent,
      isValidLink
    };
  },
};
</script>
