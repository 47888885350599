<template>
  <v-data-table
    :headers="headers"
    :loading="trainingsLoading"
    :items="trainings ? trainings : []"
    :items-per-page="-1"
    class="hidden sm:block mt-4"
  >
    <template #item="{ item }">
      <tr
        class="h-14 bkt-bg-light-grey9-hover cursor-pointer"
        @click="goToTrainingShow(item.raw)"
      >
        <td>
          <div class="w-[50px] sm:w-[200px] md:w-[300px]">
            <p class="text-sm font-normal text-fakeBlack truncate">
              {{ item.raw?.title }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex justify-start max-w-[300px]">
            <p class="text-fakeBlack text-sm font-normal">
              {{ item?.raw?.module_count || 0 }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex justify-start max-w-[300px]">
            <p class="text-fakeBlack text-sm font-normal">
              {{ item?.raw?.participant_count || 0 }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex justify-start max-w-[300px]">
            <p class="text-fakeBlack text-sm font-normal">
              {{ item?.raw?.all_modules_duration || $t("No duration") }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex justify-start max-w-[300px]">
            <my-trainings-completion-indicator
              :acquired="item?.raw?.participants_completion"
              :total="item?.raw?.participant_count || 0"
            />
          </div>
        </td>

        <td>
          <div>
            <p class="text-fakeBlack text-sm font-normal">
              {{ item?.raw?.launched_at ? $filters.formatDate(item?.raw?.launched_at) : $t('Not yet') }}
            </p>
          </div>
        </td>

        <td>
          <div>
            <p class="text-fakeBlack text-sm font-normal">
              {{ $filters.formatDate(item?.raw?.updated_at) }}
            </p>
          </div>
        </td>

        <td
          v-if="isManagerCreatorOrAbove()"
          class="px-0"
        >
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="edit_training"
                @click="goToTrainingEdit(item?.raw?.id)"
              >
                {{ $t('Edit training') }}
              </v-list-item>

              <v-list-item
                value="delete_training"
                class="text-negativeRed"
                @click="openPopUpDelete(item?.raw?.id)"
              >
                {{ $t('Delete training') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && trainings && trainings.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="p-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />

            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>

        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>

            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col justify-center items-center my-16">
        <Icon
          class="mb-5"
          icon="noto-winking-face"
          height="24"
          width="24"
        />

        <p class="font-medium text-sm mb-5">
          {{ type === 'published' ? $t('Hmm, it seems you have no training yet. Create your first training !') :
            $t('Hmm, it seems you have no upcoming training.') }}
        </p>
      </div>
    </template>
  </v-data-table>

  <!-- Mobile training loading -->
  <div
    v-if="!trainings"
    class="flex flex-col justify-center items-center block sm:hidden px-4 py-4"
  >
    <svn-loader loading-size="xl" class="mt-8 mb-8" />
  </div>

  <!-- Mobile no training -->
  <div
    v-if="trainings && trainings.length === 0"
    class="flex flex-col justify-center items-center my-16 block sm:hidden text-center mx-9"
  >
    <Icon
      class="mb-5"
      icon="noto-winking-face"
      height="24"
      width="24"
    />

    <p class="font-medium text-sm mb-5">
      {{ type === 'published' ? $t('Hmm, it seems you have no training yet. Create your first training !') :
        $t('Hmm, it seems you have no upcoming training.') }}
    </p>
  </div>

  <!-- Mobile Cards -->
  <div
    v-for="(training) in trainings"
    :key="training?.id"
    class="block border-b border-b-lightGrey sm:hidden"
  >
    <my-trainings-card
      :training="training"
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import MyTrainingsCompletionIndicator from "../MyTrainingsCompletionIndicator.vue";
import { useMobileStore } from "@/store/mobile";
import MyTrainingsCard from "../MyTrainingsCard.vue";
import { usePopUpStore } from "@/store/pop-up.js";
import {ref} from "vue";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import i18n from "@/plugins/i18n.js";
import { useUserStore } from "@/store/user.js";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import { storeToRefs } from "pinia";
const { isManagerCreatorOrAbove } = useUserStore();

const snackbar = useSnackbar();

const pagination = ref(null);

const headers = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Title'),
    width: 300
  },
  {
    align: "start",
    key: "module_count",
    sortable: false,
    title: i18n.global.t('Modules'),
    width: 120
  },
  {
    align: "start",
    key: "participant_count",
    sortable: false,
    title: i18n.global.t('Participants'),
    width: 120
  },
  {
    align: "start",
    key: "all_modules_duration",
    sortable: false,
    title: i18n.global.t('Estimated duration'),
    width: 200
  },
  {
    align: "start",
    key: "module_completion",
    sortable: false,
    title: i18n.global.t('Completion'),
    width: 150
  },
  {
    align: "start",
    key: "launched_at",
    sortable: false,
    title: i18n.global.t('Launch date'),
    width: 150
  },
  {
    align: "start",
    key: "updated_at",
    sortable: false,
    title: i18n.global.t('Last update'),
    width: 150
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    width: 50
  }
]);

const props = defineProps({
  trainingsLoading: { type: Boolean, default: false},
  trainings: { type: Array, default: () => [] },
  type: { type: String, required: true },
})

const emit = defineEmits(['update', 'reload-page', 'delete-training'])

const { openPopUp, closePopUp } = usePopUpStore();

const { addNode, update } = useBreadcrumbsStore()

const { isMobile } = storeToRefs(useMobileStore())

const router = useRouter()

const goToTrainingShow = (training) => {
  router.push({ name: 'training_show', params: { id: training?.id } })
}

const goToTrainingEdit = (id) => {
  router.push({ name: 'training_edit', params: { id: id } })
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this training ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No, cancel",
    textConfirm: "Yes, delete this training",
    color: 'red',
    customClass: '',
    showClose: false,
    async confirm() {
      closePopUp(
        emit('delete-training', id)
      )
      snackbar.setBgColor('fakeBlack').setCustomClass(isMobile.value ? 'mb-[85px]' : '').displaySnackBar('Your training has been deleted.')
    },
  })
}

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/catalog')
  update()
}
</script>
