<template>
  <apps-header
    :app-icon="peopleIcon"
    app-title="People"
  >
    <router-view />
  </apps-header>
</template>
  
<script setup>
import AppsHeader from '../components/header/AppsHeader.vue'
import { useRoute } from 'vue-router'
import { onUpdated, ref } from "vue";
import { useTheme } from "vuetify";
import peopleIcon from "@/assets/images/icons/people.svg"

const theme = useTheme()
theme.global.name.value = 'company'

  let routeName = ref(useRoute().name)

  onUpdated(() => {
    routeName.value = useRoute().name
  })
</script>