<template>
  <div class="w-full flex flex-col items-end p-4 gap-1 rounded-[8px] border border-darkGrey">
    <!-- Title -->
    <div class="w-full">
      <!-- Block has been saved -->
      <div class="flex self-stretch gap-4">
        <!-- Block title -->
        <div class="flex-1 truncate">
          <p class="text-sm font-medium text-fakeBlack overflow-ellipsis">
            {{ block.title }}
          </p>
        </div>

        <!-- Buttons -->
        <div class="flex flex-end items-start gap-2">
          <!-- Edit information block -->
          <svn-dialog-skeleton
            ref="sharedInformation"
            title="Shared information"
            description="Enter the title and the link of the shared information you want to add."
            primary-button-text="Save"
            button-primary-color="primary"
            dialog-custom-class="w-[400px]"
            :primary-button-close-on-click="false"
            @click-primary-button="updateInformationBlock"
          >
            <template #activator>
              <svn-icon-button
                variant="noOutlined"
                button-size="sm"
                icon="mdi:pencil-outline"
              />
            </template>

            <template #input>
              <div class="w-full flex flex-col gap-4">
                <!-- Title -->
                <v-text-field
                  v-model="newBlock.title"
                  :label="$t('Title')"
                  :error="errorTitle"
                  variant="outlined"
                  density="compact"
                  hide-details
                  class="input text-base font-normal"
                  @update:model-value="updateTitle"
                />

                <!-- Link -->
                <v-text-field
                  v-model="newBlock.link"
                  :label="$t('Link')"
                  :error="errorLink"
                  variant="outlined"
                  density="compact"
                  hide-details
                  class="input text-base font-normal"
                  @update:model-value="updateLink"
                />
              </div>
            </template>
          </svn-dialog-skeleton>

          <!-- Delete information block -->
          <dialog-validation
            title="Are you sure you want to remove this shared information ?"
            description="This information will no longer be accessible for employees."
            icon="mdi:trash-can-outline"
            :display-close="false"
            primary-button-text="Yes, remove"
            button-primary-color="negativeRed"
            secondary-button-text="No, cancel"
            @click-primary-button="deleteInformationBlock"
          >
            <template #button>
              <svn-icon-button
                variant="noOutlined"
                button-size="sm"
                icon="mdi:trash-can-outline"
              />
            </template>
          </dialog-validation>
        </div>
      </div>
    </div>

    <!-- Link -->
    <div class="w-full truncate">
      <!-- Block has been saved -->
      <p class="text-darkGrey text-sm font-normal overflow-ellipsis">
        {{ block.link }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import DialogValidation from '../BktPopUp/Dialogs/DialogValidation.vue';

const props = defineProps({
  block: { type: Object, default: () => {} },
});

const emit = defineEmits(['update-information-block', 'delete-information-block'])

onMounted(() => {
  newBlock.value.title = props.block?.title
  newBlock.value.link = props.block?.link
});

const sharedInformation = ref(null);
const errorTitle = ref(false);
const errorLink = ref(false);
const newBlock = ref({
  title: null,
  link: null,
});

const updateInformationBlock = () => {
  if (!newBlock.value.title) {
    errorTitle.value = true
  }
  if (!newBlock.value.link) {
    errorLink.value = true
  }
  if (!errorTitle.value && !errorLink.value) {
    emit('update-information-block', { title: newBlock?.value?.title, link: newBlock?.value?.link });
    sharedInformation.value.dialog = false;
  }
}

const updateTitle = (value) => {
  if (value && errorTitle.value) {
    errorTitle.value = false
  }
}

const updateLink = (value) => {
  if (value && errorLink.value) {
    errorLink.value = false
  }
}

const deleteInformationBlock = () => {
  emit('delete-information-block');
}
</script>

<style scoped>
* :deep(.v-field){
  border-radius: 8px;
}
</style>