<template>
  <v-data-table
    :loading="targets ? false : true"
    :items="targets ? targets : []"
    :items-per-page="-1"
    class="hidden sm:block mt-4"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in targets && targets.length !== 0 ? headers : []"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey text-sm font-medium"
        >
          <span>{{ header.title }}</span>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr
        class="h-14 bkt-bg-light-grey9-hover cursor-pointer"
        @click="goToTarget(item.raw?.title, item.raw?.id)"
      >
        <td>
          <div class="max-w-[50px] sm:max-w-[200px] md:max-w-[300px] lg:max-w-[600px] lg2:max-w-[1000px]">
            <p class="text-sm font-normal text-fakeBlack truncate">
              {{ item.raw?.title }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex justify-start max-w-[300px]">
            <my-roadmaps-objective-indicator :objective-indicator="item?.raw?.objective_indicator" />
          </div>
        </td>

        <td>
          <div>
            <p>{{ $filters.formatSpecificDate(item?.raw?.due_date, "YYYY-MM-DD", "DD MMM, YYYY") }}</p>
          </div>
        </td>

        <td class="px-0">
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                v-if="type === 'unarchived'"
                value="unarchived"
                @click="openPopUpArchive(item?.raw?.id)"
              >
                {{ $t('Archive target') }}
              </v-list-item>

              <v-list-item
                v-if="type !== 'unarchived'"
                value="different_than_unarchived"
                @click="openPopUpUnarchive(item?.raw?.id)"
              >
                {{ $t('Unarchive target') }}
              </v-list-item>

              <v-list-item
                value="delete"
                class="text-negativeRed"
                @click="openPopUpDelete(item?.raw?.id)"
              >
                {{ $t('Delete') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && targets && targets.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="p-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />

            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>

        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>

            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col justify-center items-center my-16">
        <Icon
          class="mb-5"
          icon="noto-winking-face"
          height="24"
          width="24"
        />

        <p class="font-medium text-sm mb-5">
          {{ type === 'unarchived' ? $t('Hmm, it seems you have no targets yet. Create your first target !') :
            $t('Hmm, it seems you have no archived targets.') }}
        </p>
        <bkt-new-target-button />
      </div>
    </template>
  </v-data-table>

  <div
    v-if="!targets"
    class="flex flex-col justify-center items-center sm:hidden px-4 py-4"
  >
    <svn-loader loading-size="xl" class="mt-8 mb-8" />
  </div>

  <div
    v-if="targets && targets.length === 0"
    class="flex flex-col justify-center items-center my-16 sm:hidden text-center mx-9"
  >
    <Icon
      class="mb-5"
      icon="noto-winking-face"
      height="24"
      width="24"
    />

    <p class="font-medium text-sm mb-5">
      {{ type === 'unarchived' ? $t('Hmm, it seems you have no targets yet. Create your first target !') :
        $t('Hmm, it seems you have no archived targets.') }}
    </p>
  </div>

  <div
    v-for="(target, index) in targets"
    :key="target?.id"
    class="block sm:hidden"
    :class="index !== targets.length - 1 ? 'border-b border-b-lightGrey' : ''"
  >
    <my-roadmaps-card
      :type="type"
      :roadmap="target"
      @update="emit('update')"
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import MyRoadmapsObjectiveIndicator from "../MyRoadmapsObjectiveIndicator.vue";
import BktNewTargetButton from "@/components/button/BktNewTargetButton.vue";
import axiosService from "@/tools/axios-service.js";
import MyRoadmapsCard from "../MyRoadmapsCard.vue";
import { usePopUpStore } from "@/store/pop-up";
import { useRouter } from "vue-router";
import {useSnackbar} from "@/store/snackbar.js";
import i18n from "@/plugins/i18n.js";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";

const snackbar = useSnackbar();
snackbar.checkStatus();

const router = useRouter()

const headers = [
  {
    align: "start",
    key: "targets",
    sortable: false,
    title: i18n.global.t('Targets'),
  },
  {
    align: "start",
    key: "completion",
    sortable: false,
    title: i18n.global.t('Completion'),
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
  },
  {
    align: "start",
    key: " ",
    sortable: false,
    title: "",
    width: 50
  }
];

const props = defineProps({
  targets: { type: Array, required: false },
  type: { type: String, required: true },
  pagination: {type: Object, default: () => {return null} }
})

const emit = defineEmits(['update', 'reload-page', 'delete'])

const { openPopUp, closePopUp } = usePopUpStore();
const { addNode, update } = useBreadcrumbsStore()

const fetchNewPage = (e) => {
  emit('reload-page', e)
  // setRoadmapEmployee(null);
  // updateParams({
  //   "page[number]": e,
  // });
  // fetchRoadmapEmployee();
};

const goToTarget = (title, targetId) => {
  addNode(title, `/roadmaps/targets/${targetId}`)
  update()
  router.push({ name: 'roadmaps_targets_show', params: { id: targetId } })
}

const openPopUpArchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to archive this target ?',
    subtitle: 'You will find this target in the "Archived targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, archive this target",
    color: 'blue',
    customClass: '',
    showClose: false,
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'archived'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been archived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while archiving your target !')
      }
    },
  })
}

const openPopUpUnarchive = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to unarchive this target ?',
    subtitle: 'You will find this target in the "Current targets" tab.',
    icon: 'ic-outline-archive',
    textClose: "No, cancel",
    textConfirm: "Yes, unarchive this target",
    color: 'blue',
    customClass: '',
    showClose: false,
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, {status: 'opened'})
        closePopUp()
        emit('update')
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been unarchived.')
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while unarchiving your target !')
      }
    },
  })
}

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: 'pop-up-validation',
    title: 'Are you sure you want to delete this target ?',
    subtitle: 'This is a permanent action.',
    icon: 'mdi-trash-can-outline',
    textClose: "No, cancel",
    textConfirm: "Yes, delete",
    color: 'red',
    customClass: '',
    showClose: false,
    async confirm() {
      try {
        emit('delete', id)
        closePopUp()
        snackbar.setBgColor('fakeBlack').displaySnackBar('Your target has been deleted.')
        await router.push({name: "my_roadmaps"})
      } catch (error) {
        snackbar.setBgColor('negativeRed').displaySnackBar('Error while deleting your target !')
      }
    },
  })
}
</script>
