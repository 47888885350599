<template>
  <div
    v-if="toasts.length"
    class="fixed z-[9999] w-fit p-2 flex justify-end right-0 flex-col"
    data-testid="container"
  >
    <v-slide-x-reverse-transition
      v-for="(toast, index) in toasts.slice(0, ITEMS_LENGTH)"
      :key="index"
      @after-leave="afterTransitionLeave(index)"
    >
      <v-card
        v-show="show[index]"
        class="flex flex-col rounded w-full"
        :max-width="466"
        :data-testid="`card-${index}`"
      >
        <div
          class="w-full flex flex-row p-4 gap-2"
        >
          <div class="w-full flex gap-2">
            <div
              class="w-6"
            >
              <div
                v-if="toast.notificationType === 'badge'"
                class="w-6 h-6 bg-contain"
                :style="{'background-image': `url('${BADGES[toast.badge]}')`}"
              />
              <Icon
                v-else
                :icon="ICONS[toast.type]"
                :class="`text-${COLORS[toast.type]}`"
                :width="24"
                :data-testid="`icon-${index}`"
              />
            </div>
            <div
              class="flex flex-col items-start justify-start gap-1"
              :data-testid="`subcontainer-${index}`"
            >
              <v-card-title
                v-if="toast.title && toast.title.length > 0"
                class="p-0 text-sm h-5 !leading-5 font-bold"
                :class="`text-${COLORS[toast.type]}`"
                :data-testid="`title-${index}`"
              >
                {{ toast.title }}
              </v-card-title>

              <div
                v-if="toast.body && toast.body.length > 0"
                class="text-darkGrey text-sm w-full"
                :data-testid="`body-${index}`"
                v-html="toast.body"
              />
              <div
                v-if="toast.action"
                class="font-medium normal-case h-6 text-xs sm:p-0 sm:mt-0 flex items-center cursor-pointer"
                variant="flat"
                :class="toast.notificationType === 'badge' ? 'text-primary' : 'text-fakeBlack'"
                :data-testid="`action-${index}`"
                @click="clickAction(index)"
              >
                {{ $t(toast.action.name) }}
              </div>
            </div>
          </div>

          <div
            class="h-fit flex items-center p-1 justify-center rounded sm:items-start cursor-pointer hover:!bg-[#ECECEC]"
            @click="clickClose(index)"
          >
            <Icon
              icon="mdi-close"
              variant="flat"
              :width="isMobile ? 16 : 20"
              :height="isMobile ? 16 : 20"
            />
          </div>
        </div>
        
        <div
          v-if="toast.progress"
          class="self-end w-full"
        >
          <v-progress-linear
            :data-testid="`progress-${index}`"
            class="self-end w-full"
            :color="COLORS[toast.type]"
            indeterminate
          />
        </div>
      </v-card>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script setup>
  import { Icon } from "@iconify/vue";
  import {onBeforeUnmount, ref, watch} from "vue"
  import { useToastStore } from "@/store/toast.js";
  import {storeToRefs} from "pinia";
  import router from "@/router/index.js";
  import {useMobileStore} from "@/store/mobile.js";
  import {useActionCable} from "@/store/cable.js";
  import {useUserStore} from "@/store/user.js";
  import BadgeHeart from '@/assets/images/badges/badge_heart.svg';
  import BadgeThumb from '@/assets/images/badges/badge_thumb.svg';
  import BadgeBook from '@/assets/images/badges/badge_book.svg';
  import BadgePuzzle from '@/assets/images/badges/badge_puzzle.svg';

  const { isMobile } = storeToRefs(useMobileStore())

  // Constants
  const ICONS = {
    'info': 'mdi:information',
    'success': 'material-symbols:check-circle',
    'warning': 'ic:round-warning',
    'error': 'mdi:close-circle',
  }

  const COLORS = {
    'info': 'fakeBlack',
    'success': 'positiveGreen',
    'warning': 'mediumOrange',
    'error': 'negativeRed',
  }

  const BADGES = {
    'badge_heart': BadgeHeart,
    'badge_thumb': BadgeThumb,
    'badge_book': BadgeBook,
    'badge_puzzle': BadgePuzzle,
  }

  const ITEMS_LENGTH = isMobile.value ? 1 : 3

  // Refs
  const { toasts, forceChange } = storeToRefs(useToastStore())
  const show = ref([true, true, true])
  const transition = ref(false)

  // Functions
  const { removeToast } = useToastStore()

  const clickClose = (idx) => {
    show.value[idx] = false
  }

  const clickAction = (idx) => {
    if (!toasts.value[idx].action.link)
      return
    if (typeof toasts.value[idx].action.link === 'object') {
      toasts.value[idx].action.link.click()
      URL.revokeObjectURL(toasts.value[idx].action.link)
    } else {
      router.push(toasts.value[idx].action.link).then(() => {
        router.go(0)
      })
    }

    toasts.value.splice(idx, 1)
  }

  const afterTransitionLeave = (idx) => {
    show.value[idx] = true
    removeToast(idx)
  }

  watch(toasts, (newVal, _) => {
    if (newVal.length === 0)
      return
    for (let i = 0; i < ITEMS_LENGTH ; i++) {
      if (!show.value[i]) {
        show.value[i] = true
      }
    }
  })

  watch(forceChange, (newVal, _) => {
    for (let i = 0; i < ITEMS_LENGTH ; i++) {
      if (newVal[i]) {
        clickClose(i)
        forceChange.value[i] = false
      }
    }
  }, {deep: true})
</script>
