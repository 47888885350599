<template>
  <trash-warnning
    v-if="isTrashed"
    title="This template is in trash."
    @restore="restoreDeletedItem"
    @delete-permanently="deleteTemplate"
  />
  <div class="relative w-full sm:w-4/5 md:max-w-[589px] mx-auto">
    <p
      v-if="route.params.id"
      class="font-semibold text-center text-fakeBlack text-xl mt-10"
    >
      {{ $t("Edit template") }}
    </p>

    <p
      v-else
      class="font-semibold text-center text-fakeBlack text-xl mt-10"
    >
      {{ $t("Create a template") }}
    </p>
    <v-btn
      class="absolute right-0 -bottom-1"
      density="comfortable"
      icon="mdi-close"
      @click="router.back()"
    />
  </div>
  <bkt-form
    :loading="loading"
    :disabled="isTrashed"
    @submit="handleSubmit"
  >
    <div class="w-full">
      <p class="mx-4 mb-4 text-base font-medium text-fakeBlack">
        {{ $t("Target informations") }}
      </p>

      <div class="flex flex-col items-center mx-4">
        <div class="flex flex-row w-full items-center ">
          <v-text-field
            v-bind="isMobile ? { variant: 'outlined' } : ''"
            v-model="title"
            :disabled="isTrashed"
            class="w-full mt-4 mr-4"
            color="primary"
            :label="$t('Title*')"
          />

          <div
            v-if="!isTrashed"
            class="cursor-pointer bkt-bg-light-grey9-hover rounded-5px p-2"
            @click="toggleLockStatus('title')"
          >
            <svn-tooltip
              size="small long"
              content-class="text-xs font-normal text-right"
            >
              <template #activator-content>
                <Icon
                  :icon="titleLocked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                  height="24"
                  width="24"
                  class="outline-none"
                  :class="titleLocked ? 'text-darkGrey' : 'text-middleGrey'"
                />
              </template>

              <template #tooltip-content>
                <span v-if="titleLocked">
                  {{ $t('When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.') }}
                </span>

                <span v-else>
                  {{ $t('When creating or updating a target, this field will be editable.') }}
                </span>
              </template>
            </svn-tooltip>
          </div>
        </div>

        <p
          v-if="titleError"
          class="text-xs self-start text-start text-negativeRed font-normal -mt-4"
        >
          {{ $t("Fill the title please") }}
        </p>

        <div class="flex flex-row w-full items-center">
          <v-textarea
            v-bind="isMobile ? { variant: 'outlined' } : ''"
            v-model="desc"
            :disabled="isTrashed"
            class="w-full mr-4"
            color="primary"
            :label="$t('Description')"
            hide-details
          />

          <div
            v-if="!isTrashed"
            class="cursor-pointer bkt-bg-light-grey9-hover rounded-5px p-2"
            @click="toggleLockStatus('description')"
          >
            <svn-tooltip
              size="big long"
              content-class="text-xs font-normal text-right"
            >
              <template #activator-content>
                <Icon
                  :icon="descriptionLocked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                  height="24"
                  width="24"
                  class="outline-none"
                  :class="descriptionLocked ? 'text-darkGrey' : 'text-middleGrey'"
                />
              </template>

              <template #tooltip-content>
                <span v-if="descriptionLocked">
                  {{ $t('When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.') }}
                </span>

                <span v-else>
                  {{ $t('When creating or updating a target, this field will be editable.') }}
                </span>
              </template>
            </svn-tooltip>
          </div>
        </div>

        <div class="flex flex-row w-full items-center mt-4 mx-4">
          <div
            class="text-fakeBlack rounded-sm w-full mr-4"
          >
            <svn-date-picker
              v-model="due_date"
              :disable="isTrashed"
              :label="$t('Deadline')"
            />
          </div>
          <div
            v-if="!isTrashed"
            class="cursor-pointer bkt-bg-light-grey9-hover rounded-5px p-2"
            @click="toggleLockStatus('deadline')"
          >
            <svn-tooltip
              size="small long"
              content-class="text-xs font-normal text-right"
            >
              <template #activator-content>
                <Icon
                  :icon="dueDateLocked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                  height="24"
                  width="24"
                  class="outline-none"
                  :class="dueDateLocked ? 'text-darkGrey' : 'text-middleGrey'"
                />
              </template>

              <template #tooltip-content>
                <span v-if="dueDateLocked">
                  {{ $t('When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.') }}
                </span>

                <span v-else>
                  {{ $t('When creating or updating a target, this field will be editable.') }}
                </span>
              </template>
            </svn-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-8 mx-4"
    >
      <div class="flex flex-row w-full justify-between items-center">
        <p class="text-base text-fakeBlack font-medium mb-2">
          {{ $t("Indicator") }}
        </p>

        <div
          v-if="!isTrashed"
          class="cursor-pointer bkt-bg-light-grey9-hover rounded-5px p-2 hidden sm:block"
          @click="toggleLockStatus('indicator')"
        >
          <svn-tooltip
            size="small long"
            content-class="text-xs font-normal text-right"
          >
            <template #activator-content>
              <Icon
                :icon="indicatorLocked ? 'mdi-lock' : 'mdi-lock-open-outline'"
                height="24"
                width="24"
                class="outline-none"
                :class="indicatorLocked ? 'text-darkGrey' : 'text-middleGrey'"
              />
            </template>

            <template #tooltip-content>
              <span v-if="indicatorLocked">
                {{ $t('When creating or updating a target, this field will not be editable. Whereas, you can still edit it here.') }}
              </span>

              <span v-else>
                {{ $t('When creating or updating a target, this field will be editable.') }}
              </span>
            </template>
          </svn-tooltip>
        </div>
      </div>

      <target-indicator-values
        v-model="indicator"
        :create="true"
        :with-lock="true"
        :is-trashed="isTrashed"
        :lock-value="indicatorValueLocked"
        @toggle-target-indicator-value-lock-status="toggleLockStatus('indicatorValue', $event)"
      />
    </div>
  </bkt-form>
</template>

<script setup>
import BktForm from "@/components/BktForm.vue";
import { Icon } from "@iconify/vue";
import TargetIndicatorValues from "@/components/roadmapApp/TargetIndicatorValues.vue";
import axiosService from "@/tools/axios-service.js";
import { useRoadmapTemplateStore } from "@/store/roadmap-template.js";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import {storeToRefs} from "pinia";
import {useMobileStore} from "@/store/mobile.js";
import TrashWarnning from "@/components/TrashWarnning.vue"
import { useSnackbar } from '@/store/snackbar';

const snackbar = useSnackbar()
const router = useRouter();
const route = useRoute();

const titleLocked = ref(false);
const descriptionLocked = ref(false);
const dueDateLocked = ref(false);
const indicatorLocked = ref(false);
const indicatorValueLocked = ref(false);
const loading = ref(false);
const id = ref(null);
const title = ref(null);
const isTrashed = ref(false);
const titleError = ref(null);
const desc = ref(null);
const due_date = ref(null);
const indicator = ref({
  indicator_type: "boolean",
  starting_value: null,
  target_value: null,
  multi_choice_list: [{ option: "", checkbox: false }]
});

const {isMobile} = storeToRefs(useMobileStore())
const { restoreRoadmapTemplate, deletePermanentlyRoadmapTemplate } = useRoadmapTemplateStore();

onMounted(async() => {
  const { fetchRoadmapTemplate } = useRoadmapTemplateStore();

  if (route.params.id) {
    const entity = await fetchRoadmapTemplate(route.params.id);

    mergeToCurrentTemplate(entity);
  }
})
const mergeToCurrentTemplate = (template) => {
  id.value = template.id;
  title.value = template.title;
  desc.value = template.description;
  isTrashed.value = template.status === 'trashed'
  due_date.value = template.due_date;
  indicator.value.indicator_type = template.objective_indicator.indicator_type;
  // this.starting_value = template.objective_indicator.indicator_type == 'multi_choice' ? '' : ''
  indicator.value.starting_value = template.objective_indicator.options.starting_value;
  indicator.value.target_value = template.objective_indicator.options.target_value;
  indicator.value.multi_choice_list = [];

  if (indicator.value.indicator_type === "multi_choice") {
    indicator.value.multi_choice_list = template.objective_indicator.options?.multi_choice_list
  }
  titleLocked.value = template?.attribute_disablement?.title
  descriptionLocked.value = template?.attribute_disablement?.description
  dueDateLocked.value = template?.attribute_disablement?.due_date
  indicatorLocked.value = template?.attribute_disablement?.indicator
  indicatorValueLocked.value = template?.attribute_disablement?.indicator_values
}

const handleSubmit = async() => {
  if (!title.value) {
    titleError.value = true
    return
  }

  loading.value = true;
  try {
    const toSend = {
      title: title.value,
      description: desc.value,
      due_date: due_date.value,
      indicator: indicator.value,
      attribute_disablement: {
        title: titleLocked.value,
        description: descriptionLocked.value,
        due_date: dueDateLocked.value,
        indicator: indicatorLocked.value,
        indicator_values: indicatorValueLocked.value
      }
    }

    if (route.params.id) {
      await axiosService.patch(`/api/v1/roadmaps/templates/${route.params.id}`, toSend);
    }
    else {
      await axiosService.post('/api/v1/roadmaps/templates', toSend);
    }
  } catch (e) {
    console.log('error', e.response.data);
  } finally {
    loading.value = false;
    await router.push({ name: 'roadmaps_templates' });
  }
}

const toggleLockStatus = (fieldName, event) => {
  switch (fieldName) {
    case 'title':
      titleLocked.value = !titleLocked.value;
      break;
    case 'description':
      descriptionLocked.value = !descriptionLocked.value;
      break;
    case 'deadline':
      dueDateLocked.value = !dueDateLocked.value;
      break;
    case 'indicator':
      indicatorLocked.value = !indicatorLocked.value;
      break;
    case 'indicatorValue':
      indicatorValueLocked.value = event;
      break;
    default:
      break;
  }
}

const restoreDeletedItem = async () => {
  try {
    await restoreRoadmapTemplate(id.value)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Template restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    isTrashed.value = false
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring this template')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deleteTemplate = async () => {
  try {
    await deletePermanentlyRoadmapTemplate(id.value)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Template deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    await router.push({ name: "roadmaps_templates" });
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a template')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

</script>
