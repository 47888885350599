<template>
  <div class="w-full flex flex-col gap-4 pt-4">
    <!-- Searchbar -->
    <div class="w-full px-2">
      <v-text-field
        v-model="participantSearch"
        variant="outlined"
        density="compact"
        hide-details
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('Search for participants...')"
      />
    </div>

    <!-- Participants table -->
    <div class="flex flex-col items-start gap-4 self-stretch px-2">
      <p class="text-fakeBlack text-xs font-medium">
        {{ $t('Selected participants : ') }} {{ learnTraining?.participant_ids?.length || 0 }}
      </p>
      <!-- Table -->
      <v-data-table
        v-model="learnTraining.participant_ids"
        :headers="headers"
        :items="formattedUsers"
        :loading="usersLoading"
        :no-data-text="$t('Users are loading...')"
        :search="participantSearch"
        :items-per-page="10"
        :items-per-page-text="$t('Items per page')"
        item-value="id"
        class="w-full mb-[26px]"
        color="primary"
        show-select
        @update:model-value="updateSelectedParticipantsList"
      >
        <template #loading>
          <div class="w-full flex items-center h-full mt-32">
            <svn-loader loading-size="lg" class="mx-auto" />
          </div>
        </template>

        <template #no-data>
          <p class="w-full text-center">
            No user found
          </p>
        </template>

        <template #item="{ item }">
          <tr>
            <td class="!px-0">
              <v-checkbox
                v-model="learnTraining.participant_ids"
                class="px-4"
                :value="item.value"
                hide-details
                @update:model-value="updateSelectedParticipantsList"
              />
            </td>

            <td class="!px-0">
              <div class="flex items-center gap-3">
                <svn-avatar
                  :avatar="item?.raw?.avatar"
                  :firstname="item?.raw?.firstname"
                  :lastname="item?.raw?.lastname"
                  size="lg"
                />

                <p class="text-fakeBlack text-sm font-normal line-clamp-1">
                  {{ item.raw?.fullname }}
                </p>
              </div>
            </td>

            <td
              v-for="tagCat in tagCategories"
              :key="tagCat.id"
              class="!px-4"
            >
              <p class="text-fakeBlack text-sm font-medium">
                {{ item.raw[tagCat.name] }}
              </p>
            </td>
          </tr>
        </template>

        <!-- <template #bottom /> -->
      </v-data-table>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { storeToRefs } from "pinia";
import {useCompanyStore } from "@/store/company.js"
import { useLearnTrainingStore } from '@/store/learn-trainings';
import i18n from '@/plugins/i18n';

const props = defineProps({
  participants: { type: Array, default: null },
})

const { users, tagCategories } = storeToRefs(useCompanyStore());

const { learnTraining } = storeToRefs(useLearnTrainingStore())

const { fetchCompany, fetchCompanyUsers } = useCompanyStore();

const emit = defineEmits(['update-participants'])

onMounted(async() => {
  const headersTmp = [
    {
      align: "start",
      key: "fullname",
      sortable: false,
      title: i18n.global.t("Name"),
      width: 200,
    },
  ];

  if (!users.value.length) await fetchCompanyUsers()

  const formattedTagCat = tagCategories.value.map((obj) => {
    return {
      key: obj.name,
      sortable: true,
      title: i18n.global.t(`${obj.name}`),
      width: 200,
    };
  });
  headers.value = headersTmp.concat(formattedTagCat);

  formattedUsers.value = users.value.map((obj) => {
    const toRet = {
      fullname: obj.fullname,
      firstname: obj.firstname,
      lastname: obj.lastname,
      avatar: obj.avatar?.['50'],
      id: obj.id
    };
    tagCategories.value.map((tagCat) => {
      const found = obj.tags.find(
        (tag) => tag.tag_category.id == tagCat.id
      );

      if (found) toRet[tagCat.name] = found.tag_name;
      else toRet[tagCat.name] = "";
    });
    return toRet;
  });
  usersLoading.value = false;

  fetchCompany();

  try {
    // await fetchModule(route)
  } catch (error) {

  }

})

const headers = ref([]);

const usersLoading = ref(true);

const formattedUsers = ref([]);

const participantSearch = ref('')


const updateSelectedParticipantsList = () => {
  emit("update-participants", learnTraining?.value?.participant_ids)
}
</script>
