<template>
  <div
    class="w-full flex justify-center items-center gap-8 h-14 bg-negativeRed"
  >
    <p
      v-if="!isMobile"
      class="text-base font-medium text-white">
      {{ $t(title) }}
    </p>
    <div
      class="flex gap-4"
    >
      <v-btn
        variant="outlined"
        color="white"
        class="flex justify-center items-center py-4 w-fit normal-case text-xs"
        @click="emit('restore')"
      >
        {{ $t('Restore') }}
      </v-btn>
      <v-btn
        variant="outlined"
        color="white"
        class="flex justify-center items-center py-4 w-fit normal-case text-xs"
        @click="emit('delete-permanently')"
      >
        {{ $t('Delete permanently') }}
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile.js";

const { isMobile } = storeToRefs(useMobileStore())

const props = defineProps({
  title: { type: String, default: 'This is in trash.' }

})

const emit = defineEmits(["restore", "delete-permanently"]);
</script>