<template>
  <v-infinite-scroll
    v-if="trainingsByUser && trainingsByUser.length > 0"
    color="primary"
    class="h-full overflow-y-hidden"
    :mode="isMdScreen ? 'manual' : 'intersect'"
    @load="loadMoreData"
  >
    <template #load-more />
    <template #empty />

    <v-data-table
      :items="trainingsByUser"
      :items-per-page="-1"
      class="-mt-[56px] md:!mt-0"
    >
      <template #headers>
        <!-- Version DESKTOP -->
        <tr v-if="isMdScreen">
          <td
            v-for="column in headers"
            :key="column.key"
            class="px-0 !bg-very-light-grey"
          >
            <div class="flex justify-start items-center gap-1 px-6 py-3 ">
              <p class="font-medium text-xs text-dark-grey leading-[18px]">
                {{ $t(column.title) }}
              </p>
            </div>
          </td>
        </tr>

        <tr v-else class="h-16"/>
      </template>

      <template #item="{ item }">
        <tr
          class="cursor-pointer"
          @click="goToTraining(item?.raw?.title, item?.raw?.id)"
          >
          <!-- 1ere colonne si desktop -->
          <td
            v-if="isMdScreen"
            class="px-0"
          >
            <div class="flex justify-start items-center gap-3 px-6 py-4" >
              <p class="text-sm font-normal text-fake-black" >
                {{ item.raw.title }}
              </p>
            </div>
          </td>

          <!-- 1ere colonne si mobile -->
          <td
            v-else
            class="border-t border-solid border-very-light-grey !px-0"
          >
            <div class="flex gap-2 p-4">
              <div class="flex flex-col grow items-start justify-center gap-1">
                <!-- MOn nom de module -->
                <p class="text-normal font-medium text-fake-black" >
                  {{ item.raw.title }}
                </p>
                <!-- Date acquisition -->
                <div class="flex gap-2 justify-start items-center text-sm text-dark-grey" >
                  {{$t('Acquired on')}}: {{  $filters.formatDate(item.raw?.last_module_acquired_on?.updated_at) }}
                </div>
              </div>


              <div class="flex justify-end items-start w-fit">
                <!-- Mon truc vert -->
                <div class="flex justify-start items-center px-6 py-3">
                  <my-trainings-completion-indicator
                    :acquired="item?.raw?.module_completion"
                    :total="item?.raw?.module_count"
                  />
                </div>
              </div>
            </div>
          </td>

          <!-- 2eme colonne, invisible en mobile -->
          <td
            v-if="isMdScreen"
            class="px-0"
          >
            <div class="flex justify-start items-center px-6 py-3">
              <my-trainings-completion-indicator
                :acquired="item?.raw?.module_completion"
                :total="item?.raw?.module_count"
              />
            </div>
          </td>

          <!-- 3eme colonne, invisible en mobile -->
          <td
            v-if="isMdScreen"
            class="px-0"
          >
            <div class="flex justify-start items-center px-6 py-4 ">
              <p
                v-if="item.raw?.module_completion === item.raw?.module_count"
                class="text-sm font-normal text-fake-black"
              >
                {{ $filters.formatDate(item.raw?.last_module_acquired_on?.updated_at) }}
              </p>
              <p
                v-else
                class="text-sm font-normal text-fake-black"
              >
                -
              </p>
            </div>
          </td>
        </tr>
      </template>

      <template #loading>
        <div class="flex flex-col justify-center items-center">
          <svn-loader loading-size="xl" class="mt-8 mb-8" />
        </div>
      </template>

      <template #bottom>
        <v-pagination
          v-if="isMdScreen && pagination && trainingsByUser && trainingsByUser.length > 0"
          :model-value="pagination.current_page"
          :length="pagination.total_pages"
          :total-visible="6"
          theme="primary"
          variant="text"
          class="p-5 !list-none"
          @update:model-value="fetchNewPage"
        >
          <template #prev>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
            >
              <Icon
                icon="mdi-arrow-left"
                width="20"
                height="20"
                class="mr-2"
              />

              <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
            </div>
          </template>

          <template #next>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(pagination?.current_page < pagination?.total_pages ?
                fetchNewPage(pagination?.current_page + 1) : '')"
            >
              <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>

              <Icon
                icon="mdi-arrow-right"
                width="20"
                height="20"
                class="ml-2"
              />
            </div>
          </template>
        </v-pagination>
      </template>
    </v-data-table>
  </v-infinite-scroll>

  <!-- No training found after search -->
  <div v-else-if="trainingsByUser && trainingsByUser.length === 0 && isSearching && !loading">
    <div class="flex flex-col justify-start items-center py-16 px-4 gap-4 md:px-0 md:gap-5">
      <Icon
        icon="noto:face-with-monocle"
        height="24"
        width="24"
      />
      
      <p class="font-medium text-sm text-fake-black text-center w-[298px]">
        {{ $t(`Hmm, it seems we can’t find any results based on your search.`) }}
      </p>
    </div>
  </div>

  <!-- No training in index -->
  <div v-else-if="trainingsByUser && trainingsByUser.length === 0 && !isSearching && !loading">
    <div class="flex flex-col justify-start items-center py-16 px-4 gap-4 md:px-0 md:gap-5">
      <Icon
        icon="noto:face-with-monocle"
        height="24"
        width="24"
      />

      <p class="font-medium text-sm text-fake-black">
        {{ $t('Hmm, it seems you have no training yet') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { Icon } from '@iconify/vue';
import MyTrainingsCompletionIndicator from "@/components/trainingApp/MyTrainingsCompletionIndicator.vue";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import { useLearnTrainingStore } from "@/store/learn-trainings.js";

const { isMdScreen } = storeToRefs(useMobileStore());
const { pagination, trainingsByUser, loading } = storeToRefs(useLearnTrainingStore());

const router = useRouter();
const { getTrainingsByUser, setTrainingsByUser, getAdditionalTrainingsByUser } = useLearnTrainingStore();

const props = defineProps({
  headers: { type: Array, required: true },
  user: { type: Object, required: true },
  searchText: {type: String, default: ""}
})

const isSearching = ref('')
const page = ref(1)
const totalPages = ref(null)

watch(props, (newValue) => {
  isSearching.value = newValue !== ''
  getLearningsTrainings()
})

onMounted(async () => {
  await getTrainingsByUser(props.user.id, props.searchText);
  totalPages.value = pagination.value.total_pages
  pagination.value.current_page = 1
});

const getLearningsTrainings = debounce(async() => {
  try {
    getTrainingsByUser(props.user.id, props.searchText )

  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings')
    snackbar.displaySnackBar()    }
}, 200)

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      await getAdditionalTrainingsByUser(props.user.id, props.searchText, page.value + 1)
      done('ok')
      page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const goToTraining = (title, trainingId) => {
  router.push({ name: 'training_show', params: { id: trainingId } })
}

const fetchNewPage = (e) => {
  setTrainingsByUser([])
  getTrainingsByUser(props.user.id, props.searchText, e)
};


</script>
