<template>
  <pop-up-skeleton :title="$t('Manage reviewers')">
    <template #body>
      <svn-autocomplete
        :label="$t('Search')"
        avatar
        :items="searchUsers"
        :menu-props="{ closeOnContentClick: false }"
        with-add-and-remove-buttons
        :disabling-function="isPairInFeedback"
        clear-on-select
        class="min-w-full"
        @update:model-value="addPair"
        @input="customSearch($event)"
      />

      <div
        v-if="interviews?.length"
        class="overflow-auto max-h-[375px]"
      >
        <v-list-item
          v-for="(feedbackInterview, idx) in interviews.filter(inter => inter.employee !== null)"
          :key="idx"
          class="my-2 hover:bg-neutral-200 cursor-pointer"
        >
          <div class="w-full flex items-center">
            <svn-avatar
              :avatar="feedbackInterview.employee?.avatar?.['30']"
              :firstname="feedbackInterview.employee?.fullname"
              :lastname="''"
              size="md"
              class="rounded-full mr-2"
            />

            <span>
              {{ feedbackInterview.employee?.fullname }}
            </span>

            <span
              :class="getBgByCampaignStatus(feedbackInterview.status)"
              class="w-3 h-3 inline-block ml-4 mr-1"
            />
            {{ $t(feedbackInterview.status) }}

            <v-spacer />

            <svn-button
              v-if="feedbackInterview.status !== 'submitted'"
              variant="none"
              color-class="!rounded !bg-white !text-negative-red hover:!bg-negative-red-opacity active:!bg-transparent-negative-red"
              @click="removePair(feedbackInterview.id)"
            >
              <template #button-text>
                <svn-text sm medium color="error" class="uppercase">
                  {{ $t("Remove") }}
                </svn-text>
              </template>
            </svn-button>


            <v-dialog
              v-else
              v-model="confirmDialog"
              width="auto"
            >
              <template #activator="{ props }">
                <v-btn
                  color="error"
                  variant="text"
                  v-bind="props"
                >
                  {{ $t("Remove") }}
                </v-btn>
              </template>

              <v-card class="p-4 w-2/3 self-center">
                <v-card-title class="text-center">
                  {{ $t("If you continue, answers will be delete.") }}
                </v-card-title>

                <v-card-text class="text-center">
                  {{ $t("This reviewer has already answered. If you remove him/her, answers will be deleted.") }}
                </v-card-text>

                <div class="flex mx-auto">
                  <v-card-actions>
                    <v-btn
                      color="black"
                      variant="outlined"
                      block
                      @click="confirmDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                  </v-card-actions>

                  <v-card-actions>
                    <v-btn
                      color="error"
                      variant="outlined"
                      block
                      @click="removeAnsweredPair(feedbackInterview.id)"
                    >
                      {{ $t("Yes, remove") }}
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </v-list-item>
      </div>

      <div
        v-else
        class="w-2/3 flex flex-col justify-center items-center text-center mt-2 h-96 mx-auto pb-10"
      >
        <Icon
          icon="noto:busts-in-silhouette"
          height="40"
          class="mx-auto mb-3"
        />
        <span class="font-medium text-md">
          {{
            $t(
              "Hmm, It seems there is no reviewers selected, search for reviewers and add them."
            )
          }}
        </span>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import axiosService from "@/tools/axios-service.js";
import { onMounted, ref } from "vue";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import useCampaigns from "@/tools/useCampaigns.js";
import {Icon} from "@iconify/vue";
import {useSnackbar} from "@/store/snackbar.js";

const snackbar = useSnackbar();
const { searchCompanyUsers } = useCompanyStore();
const { getBgByCampaignStatus } = useCampaigns();

const { searchUsers } = storeToRefs(useCompanyStore());

const props = defineProps({
  participantId: { type: Number, default: null },
  feedbackId: { type: Number, default: null },
  feedback: { type: Object, default: null },
  canAutoEvaluation: {type: Boolean, default: false},
  campaignId: { type: Number, default: null },
  feedbackInterviews: { type: Array, default: null },
});
const confirmDialog = ref(false);
const interviews = ref(props.feedbackInterviews);

onMounted(() => {
  searchCompanyUsers('', 5);
});

const isPairInFeedback = (pairId) => {
  // Little trick for will
  if (interviews.value?.some((inter) => inter?.employee?.id === pairId))
    return true;
  return false;
};

const addPair = async (pairId) => {
  const response = await axiosService.post(
    `/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/generate_interview_feedback`,
    {
      feedback_id: props.feedbackId,
      pair_id: pairId,
    }
  );
  if ( response.status === 422 ) {
    snackbar.setDefaultColor('failed')
      .displaySnackBar(response.data.message)
  } else if ( response.status === 200 ) {
    snackbar.setDefaultColor('success')
      .displaySnackBar("Successfully added reviewer")
    interviews.value.push(response.data);
  }
};

const removePair = async (interviewId) => {
  try {
    const res = await axiosService.delete(
      `/api/v2/interview_app/campaigns/${props.campaignId}/interview_sets/remove_interview_feedback`,
      {
        params: {
          interview_id: interviewId,
        },
      }
    );

    let index = interviews.value.findIndex((item) => item.id === interviewId);
    interviews.value.splice(index, 1);
  } catch (e) {
    console.log("error", e);
  }
};

const removeAnsweredPair = (interviewId) => {
  removePair(interviewId);
  confirmDialog.value = false;
};

const customSearch = async (search, limit = 5) => {
  await searchCompanyUsers(search, limit);
};
</script>
