<template>
  <svn-center-content
    background-color-class="bg-deepBlack"
  >
    <template #centered-content>
      <div
        class="flex flex-col items-center justify-center gap-6 2xl:!gap-20 px-8 pt-8 pb-16 2xl:!px-0 2xl:!w-[732px]"
      >
        <bkt-logo-login />

        <svn-login-card>
          <template #box-content>
            <div class="flex flex-col items-start w-full">
              <div class="flex flex-col items-start gap-6 w-full">
                <div class="flex flex-col items-start gap-4 self-stretch">
                  <div class="flex items-start gap-2 self-stretch">
                    <p class="text-xl 2xl:!text-2xl font-semibold text-fakeBlack">
                      {{ $t('Check your mail') }}
                    </p>
                    <img
                      alt=""
                      src="@/assets/mailbox.svg"
                    >
                  </div>

                  <div class="flex flex-col items-start gap-2">
                    <p class="text-sm font-semibold text-darkGrey">
                      {{ $t('This is the first time you log in to Aleph. We just sent an email with your password to the address :') }}
                    </p>

                    <p class="font-semibold text-sm text-fakeBlack">
                      {{ bktPass?.email }}
                    </p>
                  </div>

                  <p class="text-sm font-medium text-darkGrey">
                    {{ $t('Use it to log in for the first time. If you did not receive it, check your spams, or click on the button below to send it again.') }}
                  </p>
                </div>
                <div class="flex flex-col items-start gap-2 self-stretch">
                  <v-btn
                    :disabled="bktPass && bktPass.timer < 60"
                    class="w-full bkt-white normal-case"
                    :heigth="40"
                    color="fakeBlack"
                    theme="company"
                    hide-details="true"
                    @click="resend"
                  >
                    <svn-loader
                      v-if="bktPass && bktPass.timer < 60"
                      :model-value="bktPass.timer*100/60"
                      loading-size="xs"
                      class="normal-case"
                      color="darkGrey"
                      bg-color="transparent"
                    />
                    <span class="ml-2">
                      {{ $t('Send it again') }}
                    </span>
                  </v-btn>
                  <div
                    class="flex items-center gap-1 cursor-pointer"
                    @click="router.back()"
                  >
                    <Icon
                      icon="mdi:arrow-left"
                      heigth="12"
                      width="12"
                    />
                    <p class="underline text-xs font-medium text-deepBlack">
                      {{ 'Back to login' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </svn-login-card>
      </div>
    </template>
  </svn-center-content>
</template>

<script setup>
import {onBeforeUnmount, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axiosService from "@/tools/axios-service.js";
import {useSnackbar} from "@/store/snackbar.js";
import BktLogoLogin from '@/components/BktLogoLogin.vue';
import {Icon} from "@iconify/vue";

const snackbar = useSnackbar();
snackbar.checkStatus();

const router = useRouter()

const bktPass = ref(
    JSON.parse(window.localStorage.getItem("bktPass"))
)

let interval = null

const setUpInterval = () => {
  interval = setInterval(() => {
    if (bktPass.value.timer > 60 && interval) {
      clearInterval(interval)
    } else {
      bktPass.value.timer += 1
      window.localStorage.setItem("bktPass", JSON.stringify(bktPass.value))
    }
  }, 1000)
}

const resend = async () => {
  const mode = bktPass.value.mode

  if (mode === 'create') {
    await axiosService.createPass(bktPass.value.email)
    bktPass.value.timer = 0
    setUpInterval()
  } else {
    snackbar.setDefaultColor('failed')
      .displaySnackBar('No email mode')
  }
}

onBeforeUnmount(() => {
  if (interval) {
    clearInterval(interval)
  }
})


if (bktPass.value && bktPass.value.timer < 60) {
  setUpInterval()
}
</script>
