<template>
  <div
    class="relative flex flex-row justify-start items-center px-4 py-7 min-h-[145px] rounded-[5px]"
    :class="
      options.selected
        ? disabled
          ? 'bkt-box-shadow-light border border-darkGrey'
          : 'bkt-box-shadow-light border-bkt-objective-blue bkt-objective-blue' +
            disabledClasses +
            ' bkt-bg-objective-blue4'
        : disabled
          ? 'border border-darkGrey'
          : 'border-bkt-light-grey6 bkt-light-grey6' + disabledClasses
    "
    @click="$emit('click', options)"
  >
    <Icon
      class="absolute top-2 right-2"
      :icon="options.selected ? 'material-symbols:circle' : 'material-symbols:circle-outline'"
      :class="options.selected ? disabled ? 'text-darkGrey' : 'bkt-objective-blue' : 'text-lightGrey'"
      width="12"
      height="12"
    />

    <div class="mr-4">
      <Icon
        :icon="options.iconify"
        width="32"
        height="32"
        :class="options.selected ? disabled ? 'text-darkGrey' : 'bkt-objective-blue' : disabled ? 'text-lightGrey' : 'text-fakeBlack'"
      />
    </div>

    <div class="flex flex-col">
      <svn-title
        h2
        medium
        class="text-sm"
        :class="disabled ? 'text-darkGrey' : 'text-fakeBlack'"
      >
        {{ $t(options.title) }}
      </svn-title>

      <p
        class="text-xs text-darkGrey front-normal"
      >
        {{ $t(options.shortDesc) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { computed } from 'vue'

const props = defineProps(["options", "disabled"]);

const disabledClasses = computed(() => {
  return !props.disabled ? ' cursor-pointer' : ''
});

</script>
