<template>
  <pop-up-skeleton :show-close="false">
    <template #title>
      <p />
    </template>

    <template #header>
      <div class="w-full relative flex flex-col">
        <div class="self-center">
          <Icon
            v-if="data?.icon"
            class="text-fakeBlack"
            :icon="data?.icon"
            width="32"
            height="32"
          />
        </div>

        <div
          class="cursor-pointer absolute -right-2 top-0 self-end"
          @click="postIndicatorResult = changed"
        >
          <Icon
            class="text-darkGrey"
            icon="mdi-close"
            width="24"
            height="24"
            @click="ClosePopUpIfUnchanged"
          />

          <v-dialog
            v-model="postIndicatorResult"
            width="auto"
          >
            <v-card class="rounded-xl px-6">
              <pop-up-skeleton :show-close="false">
                <template #header>
                  <div class="w-full relative flex flex-col">
                    <p class="text-center font-medium text-xl mt-3">
                      {{ $t('Do you want to save your changes ?') }}
                    </p>
                    <div
                      class="cursor-pointer absolute -right-7 -top-2 self-end"
                      @click="postIndicatorResult = false"
                    >
                      <Icon
                        class="text-darkGrey"
                        icon="mdi-close"
                        width="24"
                        height="24"
                      />
                    </div>
                  </div>
                </template>

                <template #subtitle>
                  <p class="text-center font-normal text-sm text-darkGrey">
                    {{ $t('You forgot to save your changes.') }}
                  </p>
                </template>

                <template #buttons>
                  <div class="w-full flex flex-col justify-center sm:flex-row">
                    <v-btn
                      color="fakeBlack"
                      variant="outlined"
                      class="sm:w-1/2 text-fakeBlack border-darkGrey border-1 normal-case font-normal mb-3 sm:mb-0 sm:mr-4"
                      @click="discardUserDataAndClose"
                    >
                      {{ $t('Discard') }}
                    </v-btn>

                    <v-btn
                      color="primary"
                      variant="outlined"
                      class="sm:w-1/2 text-veryLightGrey border border-veryLightGrey bg-primary normal-case font-normal mb-3 sm:mb-0"
                      @click="saveUserData"
                    >
                      {{ $t('Save') }}
                    </v-btn>
                  </div>
                </template>
              </pop-up-skeleton>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </template>

    <template #body>
      <!-- Modal title -->
      <div class="-mt-8 mb-8">
        <p class="text-fakeBlack text-center text-xl font-medium">
          {{ $t('Update completion') }}
        </p>
      </div>

      <div class="mb-10">
        <p class="text-fakeBlack text-center text-base font-medium">
          {{ data.title }}
        </p>
      </div>

      <!-- Boolean -->
      <div
        v-if="indicatorFormatted.indicatorType === 'boolean'"
        class="flex flex-row mx-auto"
      >
        <div class="max-w-[115px] mr-2 flex flex-row items-center mb-5">
          <p class="text-fakeBlack text-sm font-medium">
            {{ $t('Current value') }} :
          </p>
        </div>

        <v-switch
          v-model="indicatorFormatted.currentValue"
          :false-value="indicatorFormatted.startingValue"
          :true-value="indicatorFormatted.targetValue"
          color="primary"
          @update:model-value="changed = true"
        />

        <div class="max-w-[85px] flex flex-row items-center ml-2 mb-5">
          <p class="text-fakeBlack text-sm font-medium">
            {{ indicatorFormatted.currentValue }}
          </p>
        </div>
      </div>

      <!-- Numeric value -->
      <div
        v-else-if="indicatorFormatted.indicatorType === 'numeric_value'"
        class="flex flex-row mx-auto mt-4 w-[80%] sm:w-[70%]"
      >
        <div class="w-[185px] mr-2 flex flex-row items-center mb-5">
          <p class="text-fakeBlack text-sm font-medium whitespace-nowrap">
            {{ $t('Current value') }} :
          </p>
        </div>

        <v-text-field
          v-model="indicatorFormatted.currentValue"
          variant="outlined"
          class="w-full"
          color="primary"
          :label="$t('Current value')"
          type="number"
          @update:model-value="changed = true"
        />
      </div>

      <!-- Percentage -->
      <div
        v-else-if="indicatorFormatted.indicatorType === 'percentage'"
        class="flex flex-row mx-auto mt-4 w-[80%] sm:w-[70%] max-w-[250px]"
      >
        <div class="w-[185px] mr-2 flex flex-row items-center mb-5">
          <p class="text-fakeBlack text-sm font-medium whitespace-nowrap">
            {{ $t('Current value') }} :
          </p>
        </div>

        <v-text-field
          v-model="indicatorFormatted.currentValue"
          variant="outlined"
          class="w-full"
          color="primary"
          :label="$t('Current value')"
          type="number"
          @update:model-value="changed = true"
        />
      </div>

      <!-- Multi choice -->
      <div
        v-else-if="indicatorFormatted.indicatorType === 'multi_choice'"
        class="mt-4"
      >
        <v-radio-group
          v-model="indicatorFormatted.currentValue"
          class="self-center"
        >
          <div class="flex flex-col items-center">
            <div>
              <v-radio
                v-for="(item, idx) in indicatorFormatted.multiChoiceList"
                :key="idx"
                :value="item"
                color="primary"
              >
                <template #label>
                  <span class="whitespace-normal">
                    {{ item }}
                  </span>
                </template>
              </v-radio>
            </div>
          </div>
        </v-radio-group>
      </div>

      <!-- Comments -->
      <v-textarea
        v-model="toSendDesc"
        variant="outlined"
        class="w-full"
        color="primary"
        label="Comment"
        @update:model-value="changed = true"
      />
    </template>

    <template #buttons>
      <v-btn
        class="self-center normal-case"
        color="primary"
        theme="roadmap"
        @click="postIndicator"
      >
        {{ $t("Update answer") }}
      </v-btn>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import {ref, watch} from 'vue';
import PopUpSkeleton from './PopUpSkeleton.vue';
import { usePopUpStore } from '@/store/pop-up';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useRoadmapTargetStore } from '@/store/roadmap-target';
import { useInterviewStore } from '@/store/interview';
import { useSnackbar } from '@/store/snackbar';
import axiosService from "@/tools/axios-service.js";
import { useRoute } from 'vue-router';

const { closePopUp, update } = usePopUpStore();
const route = useRoute();
const {fetchRoadmapTarget} = useRoadmapTargetStore()
const { fetchInterview } = useInterviewStore();
const { data } = storeToRefs(usePopUpStore());
const snackbar = useSnackbar();

const changed = ref(false)
const postIndicatorResult = ref(false);
const toSendDesc = ref(null);

const indicatorFormatted = ref({
  id: data?.value.indicator.id,
  indicatorType: data?.value?.indicator?.indicator_type,
  startingValue: data?.value?.indicator?.options?.starting_value,
  targetValue: data?.value?.indicator?.options?.target_value,
  currentValue: data?.value?.indicator?.options?.current_value,
  status: data?.value?.indicator?.status,
  multiChoiceList: [],
});

const ClosePopUpIfUnchanged= () => {
  if (!changed.value) closePopUp()
}

if (indicatorFormatted.value.indicatorType === "multi_choice") {
  indicatorFormatted.value.multiChoiceList = data?.value?.indicator.options?.multi_choice_list.map((option) => {
    return option.option
  })
}

watch(changed, (newVal, _) => {
  if (newVal)
    update({
      event: () => {
        postIndicatorResult.value = true
      }
    })
  else {
    update({ event: null })
  }
})

const postIndicator = async () => {
  try {
    await axiosService.patch(
      `/api/v1/roadmaps/indicators/${indicatorFormatted.value.id}`,
      {
        current_value: indicatorFormatted.value.currentValue,
        comment: toSendDesc.value,
      }
    );
		snackbar.setMsg('Your target has been updated!');
		snackbar.setBgColor('fakeBlack');
		snackbar.displaySnackBar();

    if (!history?.state?.current.split('/').includes('roadmaps')) {
      fetchInterview(route.params.id)
    } else {
      fetchRoadmapTarget(route.params.id);
    }

		closePopUp();
  } catch (e) {
		snackbar.setMsg('Error! Your changes have not been saved.');
		snackbar.setBgColor('error');
		snackbar.displaySnackBar();
    snackbar.setAction('Retry', postIndicator)
  }
};

const discardUserDataAndClose = () => {
  postIndicatorResult.value = false;
  closePopUp();
  snackbar.setMsg('Your changes have not been saved!');
	snackbar.setBgColor('fakeBlack');
	snackbar.displaySnackBar();
}

const saveUserData = () => {
  postIndicatorResult.value = false;
  postIndicator();
}
</script>
