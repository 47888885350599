<template>
  <bkt-index-skeleton body-class="bkt-bg-white rounded mt-20 mb-32">
    <template #title>
      <p class="mb-5 font-medium">
        {{ $t("Reports") }}
      </p>
    </template>

    <template #create-index>
      <v-btn
        prepend-icon="mdi-plus"
        color="primary"
        :to="{ name: 'edit_report' }"
      >
        {{ $t("Create report") }}
      </v-btn>

      <!-- Modal Deleted Items to CUSTOMIZE -->
      <modal-view-deleted-items
        ref="deletedItems"
        content-type="report"
        :contents="trashedReports"
        font-weight="font-medium"
        :user-attribute-name="'creator'"
        title="fullname"
        :avatar-size="40"
        :has-user="true"
        :has-user-with-created-at="true"
        :has-title="false"
        @view-item="downloadMessage"
        @delete-permanently="deletePermanentlyDeletedItems"
        @restore="restoreDeletedItems"
        @toggle-search="toggleSearchDeletedItems"
      />
    </template>

    <template #body>
      <div class="w-full flex flex-col gap-4">
        <!-- Search & Select -->
        <div class="w-full flex flex-row gap-4 self-stretch">
          <!-- Search -->
          <v-text-field
            v-model="searchReport"
            variant="outlined"
            density="comfortable"
            class="input"
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            @input="search"
          />
  
          <div class="flex gap-2 xl:!gap-4">
            <!-- Settings on all: icon mdi-dots-vertical -->
            <bkt-learn-list-settings
              :content-values="settingsOnAllItems"
            />
          </div>
        </div>
  
        <interview-report-table
          v-if="reports && reports.length !== 0"
          :search="searchReport"
        />
        
        <bkt-create-entity-from-index
          v-else-if="reports && reports.length === 0"
          to="edit_report"
        >
          {{ $t("report") }}
        </bkt-create-entity-from-index>
  
        <bkt-box-loader v-else-if="!reports" />
      </div>
    </template>
  </bkt-index-skeleton>
</template>
<script setup>
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import { useReportStore } from "@/store/reports.js";
import { storeToRefs } from "pinia";
import InterviewReportTable from "@/components/interviewApp/reports/InterviewReportTable.vue";
import { useActionCable } from "@/store/cable.js";
import { onBeforeUnmount, ref } from "vue";
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile";
import {useRouter} from "vue-router";

const router = useRouter();
const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore())

const { reports, trashedReports } = storeToRefs(useReportStore());
const { fetchReports, fetchTrashedReports, restoreReport, deletePermanentlyReport } = useReportStore();

const { cable } = storeToRefs(useActionCable());

const reportChannel = ref(null);
const searchReport = ref('');
const deletedItems = ref(null)

fetchReports();
fetchTrashedReports()
reportChannel.value = cable.value?.subscriptions.create(
  {
    channel: "ReportChannel"
  },
  {
    connected() {},
    disconnected() {},
    received(reportData) {
      const reportIndex = reports.value.findIndex(r => r?.id === reportData?.id);

      if (reportIndex !== -1) {
        reports.value[reportIndex] = { ...reports.value[reportIndex], ...reportData };
      }
    },
  }
);

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashedReports(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const search = debounce(async() => {
  try {
    fetchReports(searchReport.value);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching reports')
    snackbar.displaySnackBar()
  }
}, 300)

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePermanentlyReport(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Report successfully permanently deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a report')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted reports'),
    onClick: openDeletedItems
  }
])

const restoreDeletedItems = async (id) => {
  try {
    await restoreReport(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Report restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a report')
    snackbar.setCustomClass(isMobile.value ? 'mb-[report]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const downloadMessage = async (id) => {
  snackbar.setBgColor('fakeBlack')
  snackbar.setMsg('You must restore the report to download it')
  snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
  snackbar.displaySnackBar()
}


onBeforeUnmount(() => {
  if(reportChannel.value) {
    reportChannel.value.unsubscribe();
  }
});
</script>

<style scoped>
* :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
