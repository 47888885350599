<template>
  <v-menu
    v-model="menuDisplay"
    :close-on-content-click="false"
    :disabled="disable"
  >
    <template #activator="{ props, isActive }">
      <v-text-field
        :value="modelValue ? filters.formatDate(modelValue, 'DD/MM/YYYY') : label ? label : $t('Select a date')"
        color="primary"
        class="w-full"
        :base-color="baseColor"
        v-bind="props"
        :autofocus="autoFocus"
        :disabled="disable"
        append-inner-icon="mdi-calendar"
        clearable=""
        :model-value="modelValue ? filters.formatDate(modelValue, 'DD/MM/YYYY') : null"
        :density="density"
        :class="inputClass"
        :variant="variant"
        hide-details
        :label="label"
        :placeholder="placeholder"
        @click:clear="clear()"
      />
    </template>

    <v-date-picker
      v-model="date"
      :title="$t('Select a date')"
      :class="['w-full', 'md:w-70']"
      input-mode="calendar"
      :disabled="disable"
      hide-actions
      @update:model-value="change"
    >
      <template #header>
        <div class="px-2">
          <v-btn
            :text="$t('Clear selected date')"
            variant="text"
            color="primary"
            class="normal-case"
            :disabled="!date"
            @click="clear"
          />
        </div>
      </template>
    </v-date-picker>
  </v-menu>
</template>
<script setup>
import filters from "@/tools/filters";
import { ref } from "vue";

const date = ref()
const menuDisplay = ref(false)
const props = defineProps({
  modelValue: { type: String, default: "" },
  disable: { type: Boolean, default: false },
  autoFocus: { type: Boolean, default: false },
  label: { type: String, default: null },
  placeholder: { type: String, default: null },
  density: {type: String, default: 'default'},
  variant: { type: String, default: 'outlined' },
  inputClass: { type: String, default: null },
  baseColor: { type: String, default: 'middleGrey' },
})

const emit = defineEmits(['update:modelValue'])

const change = (e) => {
  emit('update:modelValue', new Date(date.value).toLocaleDateString('fr-CA'))
  menuDisplay.value = false
}

const clear = () => {
  date.value = null
  emit('update:modelValue', null)
  menuDisplay.value = false
}
</script>

<style scoped>
.input :deep(.v-field) {
  border-radius: 8px;
}
</style>
