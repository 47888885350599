<template>  
  <div
    class="rounded-[3px] px-2 py-[5px] max-w-[150px] h-5 flex justify-center items-center"
    :class="getCompletionIndicatorStyles(acquired, total)"
  >
    <p class="text-sm font-medium normal-case truncate overflow-ellipsis">
      {{ acquired }} / {{ total }}
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  acquired: { type: Number, required: true },
  total: { type: Number, required: true },
})

const getCompletionIndicatorStyles = (acquired, total) => {
  if (acquired == 0) {
    return 'bg-transparentNegativeRed border-bkt-red text-negativeRed'
  }
  else if (acquired < total) {
    return 'bg-transparentMediumOrange border-bkt-yellow text-mediumOrange'
  }
  else if (acquired === total) {
    return 'bg-transparentPositiveGreen border-bkt-green text-positiveGreen'
  }
}
</script>