<template>
  <div class="flex flex-col w-full h-full">
    <div
      class="flex justify-center items-center self-stretch h-[24px] px-[24px] pt-[56px] pb-[24px] w-full border-b border-middle-grey"
    >
      <p class="font-medium text-xl text-center text-fakeBlack flex-1 line-clamp-1">
        {{ tagCategory?.name }}
      </p>


      <div @click="closeDrawer">
        <Icon
          :icon="'material-symbols:close'"
          height="24"
          width="24"
          class="text-dark-grey hover:text-fake-black cursor-pointer"
        />
      </div>
    </div>

    <div class="flex flex-col w-full h-full overflow-y-auto">
      <v-list-item
        v-for="(item, index) in tagCategory?.tags"
        :key="index"
        class="h-[64px] py-0 hover:bg-[#F6F6F6]"
        @click.stop="toggleTagSelection(item)"
      >
        <div
          class="flex flex-row justify-start items-center"
        >
          <Icon
            :icon="selectedTagsList?.find(tag => tag.id === item.id) !== undefined ?
              'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
            color="darkGrey"
            width="18"
            height="18" 
          />
    
          <p class="font-normal text-darkGrey text-xl ml-2">
            {{ item?.tagName }}
          </p>
        </div>
      </v-list-item>
    </div>
  </div>
  <!-- <bkt-pop-up-modal-skeleton
    fullscreen
  >
    <template #button>
      <v-list-item
        class="cursor-pointer bkt-bg-light-grey10-hover"
      >
        <v-list-item-title class="text-darkGrey">
          {{ tagCategory?.name }}
        </v-list-item-title>
      </v-list-item>
    </template>

    <template #header="{ close }">
      <div
        class="flex justify-center items-center self-stretch h-[24px] px-[24px] pt-[56px] pb-[24px] w-full border-b border-middle-grey"
      >
        <p class="font-medium text-xl text-center text-fakeBlack flex-1 line-clamp-1">
          {{ tagCategory?.name }}
        </p>


        <div @click="close">
          <Icon
            :icon="'material-symbols:close'"
            height="24"
            width="24"
            class="text-dark-grey hover:text-fake-black cursor-pointer"
          />
        </div>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col w-full h-full overflow-y-auto">
        <v-list-item
          v-for="(item, index) in tagCategory?.tags"
          :key="index"
          class="h-[64px] py-0 hover:bg-[#F6F6F6]"
          @click.stop="toggleTagSelection(item)"
        >
          <div
            class="flex flex-row justify-start items-center"
          >
            <Icon
              :icon="selectedTagsList?.find(tag => tag.id === item.id) !== undefined ?
                'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
              color="darkGrey"
              width="18"
              height="18" 
            />
    
            <p class="font-normal text-darkGrey text-xl ml-2">
              {{ item?.tagName }}
            </p>
          </div>
        </v-list-item>
      </div>
    </template>
  </bkt-pop-up-modal-skeleton> -->
</template>

<script setup>
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import { Icon } from "@iconify/vue";

const props = defineProps({
  tagCategory: { type: Object, required: true },
  selectedTagsList: { type: Array, required: true },
})

const emit = defineEmits(['toggle-tag-selection', 'close-drawer'])

const toggleTagSelection = (tag) => {
  emit('toggle-tag-selection', tag)
}

const closeDrawer = () => {
  emit('close-drawer')
}
</script>