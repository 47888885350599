<template>
  <div
    class="!w-full text-7xl flex justify-content-center items-center p-2rem bkt-bg-white relative"
  >
    <svn-title h1 bold class="fs-2_4rem">
      {{ $t('New report') }}
    </svn-title>

    <v-btn
      class="absolute lg:right-96 right-10"
      icon="mdi-close"
      color="white"
      @click="$router.push({name: 'reports'})"
    />
  </div>

  <div class="bkt-page-container-min-height flex justify-content-center align-items-start">
    <div class="shadow-md lg:w-50vw w-4/5 p-6 border border-neutral-200">
      <div class="border-b-2 pb-0_75rem fs-1_6rem bkt-fakeblack-grey font-medium">
        {{ $t('Choose report type') }}
      </div>
      <div class="mt-1 mb-4">
        <span class="text-neutral-500">{{ $t('Report type affect the way datas are displayed one the file you extract') }}</span>
      </div>
      <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-1rem w-100 pb-2rem mb-1rem normal-case">
        <bkt-radio-text-icon-button
          title="Global datas"
          description="All informations about interviews in a campaign."
          :condition="mode === 'data'"
          :on-click="selectReportType"
          :click-parameters="['data']"
          icon="noto:card-index-dividers"
        />

        <bkt-radio-text-icon-button
          title="Analytics"
          description="Selected datas already analysed with ratios."
          :condition="mode === 'analytics'"
          :on-click="selectReportType"
          :click-parameters="['analytics']"
          icon="noto:chart-increasing"
        />

        <bkt-radio-text-icon-button
          title="Answers"
          description="All answers submitted in campaigns."
          :condition="mode === 'answers'"
          :on-click="selectReportType"
          :click-parameters="['answers']"
          icon="noto:fountain-pen"
        />

        <bkt-radio-text-icon-button
          v-if="isAppAvailable('objectives')"
          title="Roadmap"
          description="Analyse completions of targets in your campaigns."
          :condition="mode === 'roadmap'"
          :on-click="selectReportType"
          :click-parameters="['roadmap']"
          icon="noto:bullseye"
        />
      </div>

      <div v-if="mode === 'analytics'">
        <div class="fs-1_6rem mb-1rem bkt-fakeblack-grey font-medium border-b-2 pb-0_75rem ">
          {{ $t('Choose a category to analyse') }}
        </div>
        <div class="mt-1 mb-4">
          <span class="text-neutral-500">{{ $t('Each tag of the selected category will be displayed with completion pourcentages.') }}</span>
        </div>

        <v-select
          v-model="selectedCategory"
          :items="tagCategories"
          item-title="name"
          item-value="id"
          :label="$t('Select tag category')"
          @update:model-value="selectCategory"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
      </div>

      <div>
        <div class="fs-1_6rem mb-1rem bkt-fakeblack-grey font-medium border-b-2 pb-0_75rem ">
          {{ $t('Select campaign from following period') }}
        </div>

        <v-select
          v-model="selectedPeriod"
          :items="period"
          item-title="display"
          item-value="value"
          :value="period[0]"
          @update:model-value="fetchCampaigns"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
      </div>

      <div>
        <div class="fs-1_6rem mb-1rem bkt-fakeblack-grey font-medium border-b-2 pb-0_75rem ">
          {{ $t('Select campaign(s)') }}
        </div>
        <v-text-field
          id="users_search"
          v-model="searchText"
          append-inner-icon="mdi-magnify"
          :label="$t('Search')"
          :loading="loader"
          single-line
          hide-details
        />
      </div>
      <v-data-table
        id="resultDisplay"
        v-model="selectedCampaigns"
        :headers="headers"
        :items="campaigns"
        item-value="id"
        show-select
        class="shadow-none my-2"
        :search="searchText"
      />
      <div class="flex justify-content-center items-center my-4">
        <bkt-button
          type="interview"
          :disable="(mode === 'analytics' && (selectedCategory === null || selectedCampaigns.length === 0)) || (mode !== 'analytics' && selectedCampaigns.length === 0) "
          @click="createReport"
        >
          <div
            class="flex p-2"
          >
            <Icon
              icon="fluent-mdl2:generate"
              class="mr-2 self-center"
            />
            {{ $t('Generate') }}
          </div>
        </bkt-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useReportStore} from "@/store/reports.js";
import {storeToRefs} from "pinia";
import {Icon} from "@iconify/vue";
import axios from "@/tools/axios-service.js";
import useTools from "../../../tools/useTools.js";
import useDate from "../../../tools/useDate.js";
import BktButton from "@/components/button/BktButton.vue";
import BktBackButton from "@/components/button/BktBackButton.vue";
import BktSearch from "@/components/BktSearch.vue";
import {useRouter} from 'vue-router'
import {useCompanyStore} from "@/store/company.js";
import BktRadioTextIconButton from "@/components/button/BktRadioTextIconButton.vue";
import i18n from '@/plugins/i18n';
import filters from "@/tools/filters.js";

const {subtractMonths, getFirstDayOfYear, getLastDayOfYear, subtractYears} = useDate();
const { isAppAvailable } = useCompanyStore();

const loader = ref(false)

const getPassedYears = () => {
  let d = new Date("01 " + "January 2021");
  let first = d.getFullYear();

  let s = new Date();
  let second = s.getFullYear();
  let arr = Array();

  for (let i = first; i <= second; i++) {
    let val = {display: i, value: `${new Date("01 January " + i)}|${new Date("31 December " + i)}`}
    arr.push(val)
  }

  return arr.reverse()
}

const router = useRouter()
const mode = ref('analytics')
const tagCategories = ref()
const selectedCategory = ref(null)

const selected = ref([])
const headers = [
  { title: i18n.global.t("Campaign title"), key: 'title', sortable: false, align: 'start' },
  { title: i18n.global.t("Period"), key: 'period', sortable: false },
  { title: i18n.global.t("Participants"), key: 'employees_count', sortable: false },
]

const period = [
  {display: i18n.global.t("Ongoing"), value: new Date()},
  {display: i18n.global.t("Last 6 months"), value: `${subtractMonths(6)}|${new Date()}`}
]
  .concat(getPassedYears())
  .concat([{display: i18n.global.t("All time"), value: ""}])

const selectedPeriod = ref('')
const campaigns = ref(new Array())
const pagination = ref(null)
const searchText = ref('')
const selectedCampaigns = ref(new Array())
let processing = false

const getTagCategories = async () => {
  const res = await axios.get('/api/v1/companies/tag_categories', {
    params: {
      job_title: true,
      format: 'json'
    }
  })
  tagCategories.value = res.data.tag_categories
}

const fetchCampaigns = async () => {
  loader.value = true
  const res = await axios.get('/api/v2/interview_app/campaigns', {
    params: {
      pagination: false,
      title: searchText.value,
      date: selectedPeriod.value,
      campaign_type: 'OneToOne'
    }
  })
  campaigns.value = res.data.campaigns.map(({id, title, startline, deadline, employees_count}) => (
      { id, title, period: `${ filters.formatSpecificDate(startline, "YYYY-MM-DD", 'DD/MM/YYYY') } ➜ ${ filters.formatSpecificDate(deadline,"YYYY-MM-DD", 'DD/MM/YYYY')}`, employees_count}
  ))

  loader.value = false
}

const selectReportType = (type) => {
  mode.value = type
}

const selectCategory = (e) => {
  selectedCategory.value = e
}

const createReport = async () => {
  if (processing === true) {
    return;
  }

  try {
    await axios.post('/api/v2/interview_app/reports', {
      interview_report: {
        mode: mode.value,
        tag_category_id: selectedCategory.value || '',
        campaign_ids: selectedCampaigns.value.join(',')
      }
    })

    router.push({name: 'reports'})
  } catch (e) {
    console.log("can't create", e)
  } finally {
    processing = true
  }
}

getTagCategories()
fetchCampaigns()
</script>
