<template>
  <div class="flex flex-col items-center justify-start max-w-2xl mb-4 w-4/5 2xl:w-full">
    <!--  main template card-->
    <v-card
      class="my-10 w-full overflow-visible"
    >
      <v-card-title class="mb-4">
        {{ $t('Template') }}
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="title"
          color="primary"
          :label="$t('Template name')"
          :disabled="isDeleted"
          @update:model-value="updateInterviewForm({title: $event})"
        />

        <div class="h-40">
          <quill-editor
            v-if="description !== null"
            v-model:content="description"
            content-type="html"
            :placeholder="$t('Description')"
            :enable="!isDeleted"
            @update:content="updateInterviewForm({description: $event})"
          />
        </div>

        <v-text-field
          v-model="video"
          class="mt-16"
          :label="$t('Video link (Youtube)')"
          :disabled="isDeleted"
          :hint="isValidLink ? '' : 'Invalid link. Please check and try again.'"
          persistent-hint
          @update:model-value="updateInterviewForm({video: $event})"
        />
      </v-card-text>
    </v-card>

    <!-- Le bouton + pour ajouter un champ -->
    <bkt-interview-add-menu
      :is-deleted="isDeleted"
      @add="createQuestion(1, $event)"
    />

    <template
      v-for="(question) in interviewQuestions"
      :key="question.id"
    >
      <template-edit-chapter
        v-if="question.type === InterviewQuestionType.CHAPTER"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-paragraph
        v-if="question.type === InterviewQuestionType.PARAGRAPH"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @input="questionInputEvent(question.id, $event)"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-open-question
        v-else-if="question.type === InterviewQuestionType.OPEN"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-rating
        v-else-if="question.type === InterviewQuestionType.RATING"
        class="!w-full overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-edit-multi-choice
        v-else-if="question.type === InterviewQuestionType.MCQ"
        class="!w-full !overflow-visible"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <template-roadmap-block
        v-else-if="question.type === InterviewQuestionType.CREATE_ROADMAP || question.type === InterviewQuestionType.UPDATE_ROADMAP"
        class="!w-full !overflow-visible"
        :title="question.type === InterviewQuestionType.CREATE_ROADMAP ? 'Roadmap - Create Target(s)' :
          'Roadmap - Update Target(s)'"
        :interview-question="question"
        :is-deleted="isDeleted"
        @delete="deleteQuestion(question.id)"
        @move-up="increaseQuestionPos(question.id)"
        @move-down="decreaseQuestionPos(question.id)"
        @copy-bkt="duplicateQuestion(question.id)"
      />

      <bkt-interview-add-menu
        class="!w-full"
        :is-deleted="isDeleted"
        @add="createQuestion(question.position + 1, $event)"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import BktInterviewAddMenu from "@/components/interviewApp/template/Edit/BktInterviewAddMenu.vue";
import TemplateEditChapter from "@/components/interviewApp/template/Edit/TemplateEditChapter.vue";
import TemplateEditParagraph from "@/components/interviewApp/template/Edit/TemplateEditParagraph.vue";
import TemplateEditOpenQuestion from "@/components/interviewApp/template/Edit/TemplateEditOpenQuestion.vue";
import TemplateEditRating from "@/components/interviewApp/template/Edit/TemplateEditRating.vue";
import TemplateEditMultiChoice from "@/components/interviewApp/template/Edit/TemplateEditMultiChoice.vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {storeToRefs} from "pinia";
import TemplateRoadmapBlock from "@/components/interviewApp/template/Edit/TemplateRoadmapBlock.vue";
import {QuillEditor} from "@vueup/vue-quill";
import { InterviewQuestionType } from '@/constants/types';

const {updateQuestion } = useInterviewFormStore()

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const {
  updateInterviewForm,
  createQuestion,
  increaseQuestionPos,
  decreaseQuestionPos,
  deleteQuestion,
  duplicateQuestion
} = useInterviewFormStore()
const {
  title,
  description,
  video,
  interviewQuestions,
} = storeToRefs(useInterviewFormStore())

const isValidLink = computed(() => {
  if (!video.value || video.value.length === 0 ) return true
  return video.value && video.value.match(/youtube\.com/)
})

const questionInputEvent = (id, value) => {
  updateQuestion(id, { text: value })
};
</script>
