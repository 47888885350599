<template>
  <v-card class="mt-10 w-full mr-8">
    <v-card-title>
      {{ $t("Template type") }}
      <v-divider />
    </v-card-title>

    <v-card-text>
      <div class="flex justify-between items-center flex-wrap space-y-2">
        <p class="font-semibold w-72">
          {{ $t("Select the template type") }}
        </p>

        <v-select
          v-model="templateType"
          :items="items"
          class="w-40 overflow-ellipsis max-w-1/2"
          hide-details
          :disabled="isDeleted"
          @update:modelValue="updateInterviewForm({ answerable_by: $event })"
        >
          <template
            #selection="{item}"
          >
            <div class="w-full truncate">
              {{ item.title }}
            </div>
          </template>
        </v-select>
      </div>
      <p class="hint text-neutral-400">
        {{
          $t(
            "These are people who have to answer this form during a campaign. Cross review is an additional step"
          )
        }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { interviewFormTypeToStr } from "@/constants/types.js";
import { useInterviewFormStore } from "@/store/interview-form.js";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { updateInterviewForm } = useInterviewFormStore();
const { id, templateType } = storeToRefs(useInterviewFormStore());

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const items = [
  { value: "employee", title: t(interviewFormTypeToStr.employee) },
  { value: "manager", title: t(interviewFormTypeToStr.manager) },
  { value: "both", title: t(interviewFormTypeToStr.both) },
  { value: "cross", title: t(interviewFormTypeToStr.cross) }
];
</script>

<style scoped>
.hint {
  margin-top: 5px;
}
</style>
