<template>
  <div class="elevation-2 rounded-xl p-6 flex flex-col gap-6">
    <div class="flex flex-col gap-3">
      <div class="font-normal text-base">
        {{ $t("Choose participants") }}
      </div>

      <v-tabs
        v-model="campaignDraft.interviewee_selection_method"
        color="primary"
        align-tabs="center"
      >
        <v-tab
          value="manual"
          class="normal-case"
        >
          {{ $t('Manual selection') }}
        </v-tab>

        <v-tab
          value="by_category"
          class="normal-case"
        >
          {{ $t('By category') }}
        </v-tab>
      </v-tabs>

      <v-window v-model="campaignDraft.interviewee_selection_method">
        <v-window-item value="manual">
          <participant-selection-manual />
        </v-window-item>

        <v-window-item value="by_category">
          <participant-selection-by-tag />
        </v-window-item>
      </v-window>
    </div>

    <div>
      <div
        v-if="campaignDraft.campaign_type === CampaignTypeEnum.SURVEY"
        class="flex flex-col gap-3"
      >
        <div class="font-normal text-base">
          {{
            $t("Choose who will be in charge of the Survey")
          }}
        </div>

        <span class="text-dark-grey text-sm">
          {{
            $t("Answers will be visible in the campaign in the section ‘Answers’")
          }}
        </span>

        <svn-autocomplete
          v-model="campaignDraft.interviewer_ids"
          :items="managers"
          multiple
          avatar
          :label="$t('Person in charge')"
          avatar-chip
          closable-chips
          close-icon="mdi-close"
          class="min-w-full"
          :loading="loadingManagerList"
        >
          <template #append-content>
            {{ null }}
          </template>
        </svn-autocomplete>
      </div>

      <div
        v-else
        class="flex flex-col gap-3"
      >
        <div class="flex flex-col gap-3">
          <div class="font-normal text-base">
            {{
              campaignDraft.campaign_type === CampaignTypeEnum.ONE_TO_ONE
                ? $t("Choose how you want to add interviewer")
                : $t("Choose who will be in charge of the Feedback 360")
            }}
          </div>

          <div>
            <div class="grid grid-rows-1 grid-cols-2 gap-x-4">
              <bkt-radio-text-icon-button
                title="Direct Manager"
                description="Managers from database are in charge of the interview."
                :condition="
                  campaignDraft.interviewer_selection_method === 'manager'
                "
                :on-click="
                  () => {
                    campaignDraft.interviewer_selection_method = 'manager';
                  }
                "
                icon="noto:necktie"
              />

              <bkt-radio-text-icon-button
                title="Specific person"
                description="Designate any user that will be in charge of the campaign."
                :condition="
                  campaignDraft.interviewer_selection_method === 'specific_manager'
                "
                :on-click="
                  () => {
                    campaignDraft.interviewer_selection_method = 'specific_manager';
                  }
                "
                icon="noto:index-pointing-at-the-viewer"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-3">
          <div
            class="flex items-center gap-2"
          >
            <svn-tooltip>
              <template #activator-content>
                <Icon
                  icon="mdi:information-outline"
                  class="text-dark-grey h-4 w-4"
                />
              </template>

              <template #tooltip-content>
                {{ $t('In case of participants are not linked to a manager in the database, please choose a default person in charge') }}
              </template>

            </svn-tooltip>
            <span class="text-sm">
              {{ $t("Choose a default manager") }}
            </span>
          </div>

          <!-- Select a Manager -->
          <svn-autocomplete
            v-model="campaignDraft.default_interviewer_id"
            :items="managers"
            :label="$t('Select a manager')"
            :loading="loadingManagerList"
            class="!min-w-full"
            prepend-inner-icon=""
          >
            <template #main-content>
              {{ null }}
            </template>

            <template #prepend-details>
              {{ null }}
            </template>

            <template #append-content>
              {{ null }}
            </template>
          </svn-autocomplete>
        </div>
      </div>
    </div>

    <div class="flex">
      <v-spacer />

      <v-btn
        :loading="campaignDraftLoading"
        class="bkt-bg-blue-important bkt-white"
        color="primary"
        variant="elevated"
        :disabled="isNextDisabled "
        @click="next"
      >
        <span
          class="normal-case text-sm flex items-center gap-2"
        >
          {{ $t("Next") }}
          <Icon
            :icon="'material-symbols:arrow-forward-rounded'"
            class="h-4 w-4"
          />
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import {useCompanyStore } from "@/store/company.js";
import useFormTools from "@/tools/useFormTools.js";
import { useSnackbar } from "@/store/snackbar.js";
import ParticipantSelectionManual from "./ParticipantSelectionManual.vue";
import ParticipantSelectionByTag from "./ParticipantSelectionByTag.vue";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { useI18n } from "vue-i18n";
import CampaignTypeEnum from "@/constants/CampaignTypeEnum";
import { Icon } from "@iconify/vue";
import BktRadioTextIconButton from "@/components/button/BktRadioTextIconButton.vue";
import { debounce } from "lodash";

const emit = defineEmits(["next"]);

const { campaignDraft, loading: campaignDraftLoading } = storeToRefs(useCampaignDraftStore());
const { users, managers, tagCategories } = storeToRefs(useCompanyStore());

const { updateCampaignDraft } = useCampaignDraftStore();
const { fetchCompany, fetchCompanyUsers, fetchCompanyManagers } = useCompanyStore();
const { anyNullInArray, contactNullStr } = useFormTools();
const { t } = useI18n();
const snackbar = useSnackbar();

const loadingManagerList = ref(true);

const isNextDisabled = computed(() => {
  let hasInterviewerIds = false

  if (campaignDraft.value.campaign_type === 'Survey') { // only for survey
    hasInterviewerIds = campaignDraft.value.interviewer_ids && campaignDraft.value.interviewer_ids.length <= 0
  } else { //for one to one and feedback
    hasInterviewerIds = !campaignDraft.value.default_interviewer_id
  }
  return !campaignDraft.value.interviewer_selection_method || hasInterviewerIds
});

onMounted( async () => {
  if (!managers.value.length) {
    fetchCompanyManagers().then(() => {
      loadingManagerList.value = false;
    });
  } else {
    loadingManagerList.value = false;
  }
});

const next = debounce(async () => {
  if (anyNullInArray([
      campaignDraft.value.interviewee_selection_method,
      campaignDraft.value.interviewee_ids,
      campaignDraft.value.interviewer_selection_method,
    ])) {
    let msg = "Hey, please heheh";

    msg += contactNullStr([
      [campaignDraft.value.interviewee_selection_method, ", participant method"],
      [campaignDraft.value.interviewee_ids, ", selected participant"],
      [campaignDraft.value.interviewer_selection_method, ", interviewer method",],
    ]);
    snackbar.setStatus("error", msg).checkStatus();
  }
  else {
    try {
      await updateCampaignDraft({
        interviewee_selection_method: campaignDraft.value.interviewee_selection_method,
        interviewee_ids: campaignDraft.value.interviewee_selection_method === 'manual' ? campaignDraft.value.interviewee_ids : [],
        interviewer_ids: campaignDraft.value.interviewer_ids,
        participant_filter_tag_ids: campaignDraft.value.interviewee_selection_method === 'by_category' ? campaignDraft.value.participant_filter_tag_ids : [],
        interviewer_selection_method: campaignDraft.value.interviewer_selection_method,
        default_interviewer_id: campaignDraft.value.default_interviewer_id,
      });
      emit("next");
    } catch (error) {
      const errorData = error?.response?.data?.message;

      if (errorData.length > 0) {
        snackbar
          .setStatus("error", errorData.join(', '))
          .checkStatus();
      } else {
        snackbar
          .setStatus("error", "Our server is experiencing some problems")
          .checkStatus();
      }
    }
  }
}, 200);
</script>
