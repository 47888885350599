<template>
  <pop-up-skeleton :title="$t('Manage co-pilots')">
    <template #body>
      <div
        v-if="loading"
        class="w-full h-[450px] flex items-center justify-center"
      >
        <svn-loader loading-size="lg" />
      </div>

      <div v-else class="w-full flex flex-col">
        <svn-autocomplete
          :label="$t('Search')"
          avatar
          :items="searchManagersCreators"
          :menu-props="{ closeOnContentClick: false }"
          with-add-and-remove-buttons
          :disabling-function="isCopilotInList"
          clear-on-select
          class="min-w-full"
          @update:model-value="addCopilot"
          @input="customSearch($event)"
        />

        <div
          v-if="copilots?.length"
          class="overflow-auto max-h-[375px]"
        >
          <v-list-item
            v-for="(copilot, idx) in copilots"
            :key="idx"
            :value="copilot?.id"
            class="my-2 cursor-pointer"
          >
            <div class="w-full flex flex-row justify-between items-center">
              <div class="flex flex-row items-center">
                <div class="flex items-center justify-center">
                  <svn-avatar
                    :avatar="copilot?.avatar?.['30']"
                    :firstname="copilot?.firstname"
                    :lastname="copilot?.lastname"
                    size="md"
                    class="mr-2"
                  />
                </div>

                <div class="flex flex-col justify-center items-start h-full">
                  <div>
                    <p>{{ copilot?.fullname }}</p>
                  </div>

                  <div>
                    <p>{{ copilot?.job_title }}</p>
                  </div>
                </div>
              </div>

              <svn-button
                variant="none"
                color-class="!rounded !bg-white !text-negative-red hover:!bg-negative-red-opacity active:!bg-transparent-negative-red"
                @click="removeCopilot(copilot.id)"
              >
                <template #button-text>
                  <svn-text sm medium color="error" class="uppercase">
                    {{ $t("Remove") }}
                  </svn-text>
                </template>
              </svn-button>
            </div>
          </v-list-item>
        </div>

        <div
          v-else
          class="w-2/3 flex flex-col justify-center items-center text-center mt-2 h-96 mx-auto pb-10"
        >
          <Icon
            icon="mdi-account-pilot"
            height="40"
            class="mx-auto mb-3"
          />
          <span class="font-medium text-md">
            {{ $t("Hmm, It seems there is no co-pilots selected, search for co-pilots and add them.") }}
          </span>
        </div>
      </div>
    </template>
  </pop-up-skeleton>
</template>
<script setup>
import { usePopUpStore } from "@/store/pop-up.js";
import axiosService from "@/tools/axios-service.js";
import { onMounted, ref } from "vue";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useSnackbar } from "@/store/snackbar.js";
import { debounce } from "lodash";

const { searchCompanyManagersCreators } = useCompanyStore();
const { data } = storeToRefs(usePopUpStore());
const snackbar = useSnackbar();
const { searchManagersCreators } = storeToRefs(useCompanyStore());
const copilots = ref([]);
const loading = ref(true);

onMounted(async() => {
  try {
    await searchCompanyManagersCreators('', 5);
  } catch (error) {
    snackbar.setBgColor('negativeRed').displaySnackBar("Error getting Manager creators list !")
  }
  try {
    const response = await axiosService.get(`/api/v2/interview_app/campaigns/${data.value?.campaignId}/copilots`)
    copilots.value = response?.data?.users
    loading.value = false;
  } catch (error) {
    snackbar.setBgColor('negativeRed').displaySnackBar("Error getting copilots list !")
  }
});

const isCopilotInList = (copilotId) => {
  if (copilots.value?.some((copilot) => copilot?.id === copilotId))
    return true;
  return false;
};

const addCopilot = debounce(async(copilotId) => {
  try {
    if (copilotId) {
      await axiosService.post(
        `/api/v2/interview_app/campaigns/${data.value?.campaignId}/copilots`,
        {
          user_id: copilotId,
        }
      );
      const copilot = searchManagersCreators.value.find(user => user.id === copilotId)
      copilots.value.push(copilot);
      snackbar.setBgColor('success').displaySnackBar("Copilot added successfully")
    }
  } catch (error) {
    snackbar.setBgColor('negativeRed').displaySnackBar("Error while adding copilot !")
  }
}, 250);

const removeCopilot = debounce(async(copilotId) => {
  try {
    await axiosService.delete(`/api/v2/interview_app/campaigns/${data.value?.campaignId}/copilots/${copilotId}`);
    copilots.value.splice(copilots.value.findIndex((item) => item.id === copilotId), 1);
    snackbar.setBgColor('success').displaySnackBar("Copilot removed successfully")
  } catch (e) {
    snackbar.setBgColor('negativeRed').displaySnackBar("Error while removing copilot !")
  }
}, 250);

const customSearch = async (search, limit = 5) => {
  await searchCompanyManagersCreators(search, limit);
};
</script>
