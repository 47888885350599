<template>
  <div class="w-full flex justify-center items-center">
    <v-divider color="grey" />
    <v-menu location="end">
      <template #activator="{ props }">
        <v-btn
          v-if="!isDeleted"
          color="white"
          icon="mdi-plus"
          size="30"
          v-bind="props"
        />
      </template>

      <v-list
        :items="filteredTypes"
        @update:selected="$emit('add', $event[0])"
      />
    </v-menu>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useInterviewFormStore } from "@/store/interview-form.js";
import { useI18n } from "vue-i18n";
import { useCompanyStore } from "@/store/company.js";
import { InterviewQuestionType } from '@/constants/types';

const { isAppAvailable } = useCompanyStore();
const { t } = useI18n();
const { interviewQuestions } = storeToRefs(useInterviewFormStore());
const {isFeedBack360, isSurvey} = useInterviewFormStore()

const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const types = ref([
  {
    title: t("Chapter"),
    value: InterviewQuestionType.CHAPTER,
    props: {
      prependIcon: "mdi-menu",
    },
    disabled: false,
  },
  {
    title: t("Paragraph"),
    value: InterviewQuestionType.PARAGRAPH,
    props: {
      prependIcon: "mdi-text",
    },
    disabled: false,
  },
  {
    title: t("Open question"),
    value: InterviewQuestionType.OPEN,
    props: {
      prependIcon: "mdi-help-circle-outline",
      disabled: false,
    },
  },
  {
    title: t("Rating"),
    value: InterviewQuestionType.RATING,
    props: {
      prependIcon: "mdi-star-half-full",
      disabled: false,
    },
  },
  {
    title: t("Multi choice"),
    value: InterviewQuestionType.MCQ,
    props: {
      prependIcon: "mdi-checkbox-marked",
      disabled: false,
    },
  },
  {
    title: t("Roadmap - Update targets"),
    value: InterviewQuestionType.UPDATE_ROADMAP,
    props: {
      prependIcon: "mdi-bullseye-arrow",
      disabled: !isAppAvailable("objectives"),
    },
  },
  {
    title: t("Roadmap - Create targets"),
    value: InterviewQuestionType.CREATE_ROADMAP,
    props: {
      prependIcon: "mdi-bullseye-arrow",
      disabled: !isAppAvailable("objectives"),
    },
  },
]);

const filteredTypes = computed(() => {
  const roadmapCreateCount = interviewQuestions.value.filter(
    (item) => item.type === InterviewQuestionType.CREATE_ROADMAP).length;

  const roadmapUpdateCount = interviewQuestions.value.filter(
    (item) => item.type === InterviewQuestionType.UPDATE_ROADMAP).length;

  let array = types.value.map((item) => {
    if (item.value === InterviewQuestionType.UPDATE_ROADMAP) {
      item.props.disabled = (roadmapUpdateCount > 0) ? true : false;
      return item;
    }
    if (item.value === InterviewQuestionType.CREATE_ROADMAP) {
      item.props.disabled = (roadmapCreateCount > 0) ? true : false;
      return item;
    }
    else {
      return item;
    }
  })

  return array.filter((item) => {
    if ( item.value === InterviewQuestionType.CREATE_ROADMAP) {
      const display = isFeedBack360() || isSurvey() ? false : true
      return display
    }
    if (item.value === InterviewQuestionType.UPDATE_ROADMAP) {
      const display = isFeedBack360() || isSurvey() ? false : true
      return display
    }
    else {
      return true;
    }
  });
});
</script>
