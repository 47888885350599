<template>
  <div
    class="w-full flex gap-2"
    :class="side ? '' : ' self-start'"
  >
    <svn-avatar
      v-if="side"
      :avatar="user?.avatar?.['50']"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      size="lg"
    />

    <div class="w-full flex flex-col gap-2">
      <!-- user name -->
      <p
        v-if="!crossed"
        class="text-fakeBlack text-base font-semibold leading-6"
        :class="side ? '' : 'self-end'"
      >
        {{ user?.fullname }}
      </p>

      <div
        class="rounded-lg p-4"
        :class="[questionType === InterviewQuestionType.OPEN ? '' : 'flex flex-col gap-2',
          interviewKind === 'crossed' ? isLastInterviewCompletedAndLocked ? 'bg-[#F4FAFF]' :
            locked ? 'bg-very-light-grey' : 'bg-[#F4FAFF]' : 'bg-[#F4FAFF]']"
      >
        <!-- Question type Rating -->
        <div
          v-if="questionType === InterviewQuestionType.RATING"
          class="flex items-start p-2 rounded-[8px] bg-white border border-middle-grey"
          :class="side ? 'self-start' : 'self-end'"
        >
          <v-rating
            :model-value="answer"
            disabled
            :length="ratingLength"
          >
            <template #item="{ isFilled }">
              <div class="cursor-pointer">
                <Icon
                  icon="ph:star-fill"
                  size="20"
                  :class="isFilled ? 'text-fakeBlack mx-[2px]' : 'text-middleGrey mx-[2px]'"
                />
              </div>
            </template>
          </v-rating>
        </div>

        <!-- Question type Open question -->
        <div
          v-if="questionType === InterviewQuestionType.OPEN"
          class="rounded-lg bg-transparent"
          :class="[side ? 'w-full' : 'self-end']"
        >
          <quill-editor
            v-model:content="answerRef"
            content-type="html"
            :placeholder="' '"
            :read-only="true"
            :class="side ? '' : 'input !self-end'"
            class="text-fake-black text-sm font-normal leading-5 description break-words w-full"
          />
        </div>

        <!-- Any other Question type -->
        <div
          v-if="questionType !== InterviewQuestionType.RATING && questionType !== InterviewQuestionType.OPEN"
          class="rounded-[8px] bg-white p-2 border border-middle-grey"
          :class="[side ? 'self-start' :
            'self-end']"
        >
          <quill-editor
            v-model:content="answerRef"
            content-type="html"
            :placeholder="' '"
            :read-only="true"
            class="text-fake-black text-sm font-normal leading-5 description  break-words w-full"
          />
        </div>

        <!-- Comments input -->
        <quill-editor
          v-if="comments"
          v-model:content="commentsRef"
          content-type="html"
          :placeholder="' '"
          :read-only="true"
          :class="side ? '' : 'input !self-end'"
          class="text-fake-black text-sm font-normal leading-5 description  break-words w-full"
        />
      </div>
    </div>

    <svn-avatar
      v-if="!side"
      :avatar="user?.avatar?.['50']"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      size="lg"
    />
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { ref, watch, onMounted } from "vue";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';

const props = defineProps({
  isLastInterviewCompletedAndLocked: { type: Boolean, default: false },
  crossed: { type: Boolean, default: false },
  user: { type: Object, required: true },
  answer: { type: String, required: true },
  comments: { type: String, required: false },
  questionType: { type: String, required: false },
  ratingLength: { type: String, required: false },
  side: { type: Boolean, default: true },
})

onMounted(() => {
  answerRef.value = props?.answer
  commentsRef.value = props?.comments
})

const { interviewKind, locked } = storeToRefs(useInterviewStore())

const answerRef = ref(null);
const commentsRef = ref(null);

watch(props, (newValue, oldValue) => {
  if (newValue) {
    answerRef.value = newValue?.answer
    commentsRef.value = newValue?.comments
  }
});
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}

:deep(.input .ql-editor p) {
  text-align: end !important;
}
</style>