<template>
  <svn-center-content
    background-color-class="bg-deepBlack"
  >
    <template #centered-content>
      <div class="flex flex-col gap-2 sm:!gap-4 justify-center items-center pb-16">
        <div
          class="flex flex-col items-center justify-center gap-6 2xl:!gap-20 px-8 pt-8 2xl:!px-0 2xl:!w-[732px]"
        >
          <bkt-logo-login />

          <svn-login-card>
            <template #box-content>
              <div class="flex flex-col items-start w-full">
                <div class="flex flex-col items-start gap-6 w-full">
                  <div class="flex flex-col items-center 2xl:!items-start justify-center gap-2 w-full">
                    <p class="text-xl 2xl:!text-2xl font-semibold text-fakeBlack">
                      {{ $t('Welcome on board !') }}
                    </p>

                    <p class="text-sm font-medium text-darkGrey">
                      {{ $t('Please enter your email.') }}
                    </p>
                  </div>

                  <v-text-field
                    v-model="email"
                    :label="$t('Email')"
                    class="w-full"
                    type="text"
                    variant="outlined"
                    hide-details="false"
                    @keyup.enter="checkMail"
                  />
                  <!--          INFO: little trick for will to have native suggestion-->
                  <input
                    class="hidden"
                    type="password"
                  >

                  <v-btn
                    :height="40"
                    :loading="submitLoading"
                    class="w-full normal-case"
                    color="primary"
                    theme="company"
                    @click="checkMail"
                  >
                    {{ $t('Continue') }}
                  </v-btn>
                </div>

                <div class="flex items-center gap-4 w-full my-2">
                  <v-divider
                    :thickness="2"
                    class="border-opacity-100"
                    color="info"
                  />
                  <p class="text-xs font-normal">{{ $t('or') }}</p>
                  <v-divider
                    :thickness="2"
                    class="border-opacity-100"
                    color="info"
                  />
                </div>

                <svn-button
                  class="w-full"
                  variant="outlined"
                  text="Sign in with Google"
                  prepend-icon="flat-color-icons:google"
                  @click="connect"
                />
              </div>
            </template>
          </svn-login-card>
        </div>

        <dialog-request-demo>
          <template #button>
            <svn-text sm regular color="white" class="underline cursor-pointer">
              {{ $t('Request a demo') }}
            </svn-text>
          </template>
        </dialog-request-demo>
      </div>
    </template>
  </svn-center-content>

  <v-overlay
    :model-value="appLoading"
    class="align-center justify-center"
  >
    <svn-loader loading-size="lg" class="mx-auto" />
  </v-overlay>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import axios from "@/tools/axios-service.js";
import BktLogoLogin from '@/components/BktLogoLogin.vue';
import {useRouter, useRoute} from 'vue-router'
import {useUserStore} from "@/store/user.js";
import {storeToRefs} from "pinia";
import {useSnackbar} from "@/store/snackbar.js";
import { googleSdkLoaded } from 'vue3-google-login';
import DialogRequestDemo from '../../components/BktPopUp/Dialogs/DialogRequestDemo.vue';
import { useAppRouter } from '@/store/app-router';

const snackbar = useSnackbar();
snackbar.checkStatus();

const router = useRouter()
const route = useRoute()
const {email} = storeToRefs(useUserStore())

const appLoading = ref(false)
const submitLoading = ref(false)
const {bktRedirectPath} = storeToRefs(useAppRouter())

onMounted(() => {
  if (route?.query?.email_not_found) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Email not found. Please check and try again.')
    snackbar.displaySnackBar()
  } else if (route?.query?.google_payload) {
    snackbar.setBgColor('error')
    snackbar.setMsg("Unable to process Google payload. Please try again.")
    snackbar.displaySnackBar()
  }
})
const checkMail = async () => {
  try {
    submitLoading.value = true
    const res = await axios.get("/api/v1/users/check", {params: {email: email.value}})

    if (res.data.email_exist && res.data.password_exist) {
      router.push("sign_in")
    } else if (res.data.email_exist && !res.data.password_exist) {
      window.localStorage.setItem("bktPass", JSON.stringify({
        email: email.value,
        timer: 0,
        mode: 'create'
      }))
      router.push("email_sent_first")
    } else {
      router.push("email_not_found")
    }
  } catch (e) {
    console.log(e)
    snackbar.setDefaultColor('failed')
      .displaySnackBar(e.message)
  } finally {
    submitLoading.value = false
  }
}

const connect = () => {
  appLoading.value = true
  googleSdkLoaded((google) => {
    google.accounts.oauth2
      .initCodeClient({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        scope: 'email profile openid',
        redirect_uri: `${import.meta.env.VITE_BACKEND_DOMAIN}/google/callback`,
        ux_mode: 'redirect',
        state: bktRedirectPath.value
      })
      .requestCode()
  });
  appLoading.value = false
};


</script>

<style scoped>
:deep(.v-field) {
  border-radius: 8px !important;
}
</style>
