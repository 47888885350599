<template>
  <div class="w-full lg:w-2/3 lg:ml-24 xl:w-2/3 xl:ml-24 pt-5">
    <div class="flex">
      <svn-title h2 semi-bold class="grow text-center mb-10">
        {{ campaignDraft?.title || $t("New campaign") }}
      </svn-title>
      <v-btn
        icon="mdi-close"
        color="white"
        @click="deleteAndRedirect(campaignDraft.id)"
      />
    </div>
    <div class="flex justify-center">
      <v-timeline
        class="h-full xl:mb-32"
        side="end"
      >
        <v-timeline-item
          v-for="(item, idx) in steps"
          :key="idx"
          :dot-color="idx === indexStep && idx === steps.length - 1 || idx <= lastStep && idx !== steps.length - 1 ? '#3177B7' : 'grey lighten-2'"
          small
          fill-dot
          height="110"
          @click="changeTimeline($event, idx)"
        >
          <template #icon>
            <div
              :class="idx !== indexStep ? 'cursor-pointer' : 'bg-white'"
              class="w-8 h-8 rounded-full"
            />
          </template>
          <template #opposite>
            <div class="bg-red-100 w-full relative hidden lg:block xl:block">
              <span
                class="absolute right-0 w-80 text-right -translate-y-1/2"
                :class="idx === indexStep ? 'bkt-blue' : ''"
              >{{ item.title }}</span>
            </div>
          </template>

          <div class="h-40" />
        </v-timeline-item>
      </v-timeline>

      <component
        :is="steps[indexStep].component"
        class="w-full xl:w-2/3"
        @next="next"
      />
    </div>
  </div>
</template>

<script>
import CampaignDraftTitle from "@/components/interviewApp/campaignDraft/Title.vue";
import Settings from "@/components/interviewApp/campaignDraft/Settings.vue";
import Participants from "@/components/interviewApp/campaignDraft/Participants.vue";
import Templates from "@/components/interviewApp/campaignDraft/Templates.vue";
import Launch from "@/components/interviewApp/campaignDraft/Launch.vue";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { useSnackbar } from "@/store/snackbar.js";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    CampaignDraftTitle,
    Settings,
    Participants,
    Templates,
    Launch,
  },
  setup() {
    const { campaignDraft } = storeToRefs(useCampaignDraftStore());
    const { fetchCampaignDraft } = useCampaignDraftStore();
    const { t } = useI18n();
    const indexStep = ref(0);
    const lastStep = ref(0);
    const steps = [
      { title: t("Set your campaign"), component: "CampaignDraftTitle" },
      { title: t("Choose your settings"), component: "settings" },
      { title: t("Select the participants"), component: "participants" },
      { title: t("Set your template(s)"), component: "templates" },
      { title: t("Launch your campaign !"), component: "launch" },
    ];

    fetchCampaignDraft().then(() => {
      const idx = steps.findIndex(
        (item) => item.component === campaignDraft.value?.step
      );

      if (idx > -1) {
        indexStep.value = idx;
        lastStep.value = idx;
      }
    });

    return {
      indexStep,
      steps,
      lastStep,
      campaignDraft
    };
  },
  methods: {
    changeTimeline(e, index) {
      const snackbar = useSnackbar();
      const { campaignDraft } = storeToRefs(useCampaignDraftStore());
      const lastFilledStep = this.steps.findIndex(
        (item) => item.component === campaignDraft.value.step
      );

      if (index <= lastFilledStep) {
        this.indexStep = index;
      } else {
        snackbar
          .setStatus("warning", "Try to finish this step first")
          .checkStatus();
      }
    },
    async next() {
      const { updateCampaignDraft } = useCampaignDraftStore();
      const { campaignDraft } = storeToRefs(useCampaignDraftStore());
      const snackbar = useSnackbar();
      const newIndex = this.indexStep + 1;

      if (this.steps[newIndex]) {
        this.indexStep = newIndex;

        this.lastStep = this.lastStep < newIndex ? newIndex : this.lastStep;
        try {
          await updateCampaignDraft({ campaign_draft: { step: this.steps[newIndex].component } });
          campaignDraft.value.step = this.steps[newIndex].component;
        } catch (error) {
          console.log("error", error.message);
          const errorData = error?.response?.data?.message?.data;

          if (Array.isArray(errorData) && errorData.length > 0) {
            snackbar
              .setStatus("error", errorData.join(' '))
              .checkStatus();
          } else if (typeof errorData === 'string') {
            snackbar
              .setStatus("error", errorData)
              .checkStatus();
          } else {
            snackbar
              .setStatus("error", "Our server is experiencing some problems")
              .checkStatus();
          }
        }
      } else {
        // TODO: launch campaign
      }
    },
    async deleteAndRedirect(id) {
      const { deleteCampaignDraft } = useCampaignDraftStore();

      deleteCampaignDraft(id);
      this.$router.push({ name: "campaigns" });
    },
  },
};
</script>
