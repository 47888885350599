import axios from "./axios-service.js";

let timer = null;
let requestStack = null;

let timerUpdate = null;
let requestStackUpdate = null;

export const genericStates = {
  loading: false,
  error: null,
  pagination: null,
  params: {},
};

export const genericFunctions = {
  updateParams(obj) {
    this.params = { ...this.params, ...obj };
  },
  async fetchEntity(path, keyEntity, keyEntityState = keyEntity) {
    return new Promise(async (resolve, reject) => {
      this.loading = true;
      try {
        this[keyEntityState] = await axios
          .get(path, { params: this.params })
          .then((response) => {
            if (response.data["meta"])
              this.pagination = response.data["meta"]['pagination'];
            return response.data[keyEntity]
          });
        resolve(this[keyEntityState])
      } catch (error) {
        this.error = error;
        if (error?.response?.status === 403) { // Pundit not authorize
          await this.router.push({name: 'not-auth'})
        }
        reject(error)
      } finally {
        this.loading = false;
        resolve(null)
      }
    })
  },
  async deleteEntity(path, keyEntityState = null) {
    this.loading = true;
    try {
      await axios.delete(path);
      if (keyEntityState) this[keyEntityState] = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  async createEntity(path, opts) {
    this.loading = true;
    try {
      await axios.post(path, opts);
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  async createEntityWithoutCatch(path, opts) {
    this.loading = true;
    try {
      await axios.post(path, opts);
    } finally {
      this.loading = false;
    }
  },
  updateEntity(path, data) {
    this.loading = true;
    return axios
      .put(path, data)
      .finally(() => {
        this.loading = false;
      });
  }
};
