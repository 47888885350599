<template>
  <v-dialog
    v-model="dialogDeletePlaylist"
    content-class="!rounded-[12px] bg-white"
    :width="isMobile ? 300 : 400"
  >
    <template #default>
      <div class="w-full flex flex-col p-6 gap-8">
        <!-- Icon and text -->
        <div class="flex flex-col items-center gap-4">
          <!-- icon -->
          <Icon
            icon="mdi:trash-can-outline"
            height="32"
            width="32"
          />

          <!-- Title & description -->
          <div class="flex flex-col items-center self-stretch gap-4">
            <p class="text-fakeBlack text-xl font-medium text-center">
              {{ $t('Are you sure you want to delete this playlist ?') }}
            </p>

            <p class="text-darkGrey text-sm font-normal text-center">
              {{ $t('This is a permanent action.') }}
            </p>
          </div>
        </div>

        <!-- Buttons -->
        <div class="flex flex-col items-start gap-3 self-stretch lg:!flex-row">
          <v-btn
            variant="outlined"
            color="fakeBlack"
            class="normal-case text-xs w-full lg:!flex-1"
            :text="$t('No, cancel')"
            @click="dialogDeletePlaylist = false"
          />

          <v-btn
            variant="flat"
            color="negativeRed"
            class="normal-case text-xs w-full lg:!w-1/2"
            :text="$t('Yes, delete this playlist')"
            @click="emit('delete-playlist')"
          />
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';

const dialogDeletePlaylist = ref(false)

const { isMobile } = storeToRefs(useMobileStore())

const emit = defineEmits(['delete-playlist'])

defineExpose({
  dialogDeletePlaylist
})
</script>