<template>
  <dialog-skeleton
    :title="title"
    :bind="true"
    button-primary-color="primary"
    :display-secondary-button="false"
    :primary-button-text="textConfirm"
    :primary-button-disabled="disabled"
    :width="isMobile ? 300 : 580"
    @click-primary-button="createNewPlaylist"
  >
    <template #button>
      <slot name="button" />
    </template>

    <template #close="{ close }">
      <slot
        name="close"
        :close="close"
      />
    </template>

    <template #body>
      <div class="flex flex-col gap-6">
        <!-- Title input -->
        <v-text-field
          v-model="playlistTitle"
          variant="outlined"
          class="text-sm text-fakeBlack font-normal input"
          persistent-counter
          maxlength="70"
          counter
          density="compact"
        >
          <template #counter="{ counter, max = 70, value }">
            <div class="flex items-start self-stretch px-4 pt-1 self-start">
              <span class="text-fakeBlack text-xs font-normal">
                {{ value }} / {{ max }}
              </span>
            </div>
          </template>

          <template #label>
            <p class="text-sm text-darkGrey font-normal">
              {{ $t('Playlist title') }}
            </p>
          </template>
        </v-text-field>

        <!-- Description input -->
        <v-textarea
          v-model="playlistDescription"
          variant="outlined"
          class="text-sm text-fakeBlack font-normal input"
          persistent-counter
          maxlength="200"
          counter
          density="compact"
        >
          <template #counter="{ counter, max = 200, value }">
            <div class="flex items-start self-stretch px-4 pt-1">
              <span class="text-fakeBlack text-xs font-normal">
                {{ value }} / {{ max }}
              </span>
            </div>
          </template>

          <template #label>
            <p class="text-sm text-darkGrey font-normal">
              {{ $t('Description') }}
            </p>
          </template>
        </v-textarea>
      </div>
    </template>
  </dialog-skeleton>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import DialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue"
import { useSnackbar } from '@/store/snackbar';

const props = defineProps({
  title: { type: String, default: '' },
  textConfirm: { type: String, default: '' },
})

const playlistId = ref(null)

const playlistTitle = ref('')

const playlistDescription = ref('')

const snackbar = useSnackbar()

const { isMobile } = useMobileStore()

const { createPlaylist } = useLearnPlaylistStore()

const disabled = computed(() => {
  if (!(playlistTitle.value)) {
    return true
  }
  else {
    return false
  }
})

const router = useRouter()

const createNewPlaylist = async() => {
  try {
    playlistId.value = await createPlaylist(playlistTitle.value, playlistDescription.value)
    router.push({ name: "playlist_show", params: { id: playlistId?.value }  })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating new playlist')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
