
<template>
  <div
    class="flex flex-row items-center"
    :class="withBgAndBorder ?
      [getBorderAndBgColorByCampaignStatus(status), 'w-[135px] justify-center mr-2 px-2 py-1'] :
      ['justify-start space-x-1']"
  >
    <div
      class="w-[8px] h-[8px] !rounded-full"
      :data-testid="statusTestId"
      :class="withBgAndBorder ?
        [getBgByCampaignStatus(status), 'w-[8px] h-[8px] !rounded-full mr-2'] :
        [getBgByCampaignStatus(status), 'w-[8px] h-[8px] !rounded-full sm:w-[11px] sm:h-[11px]']"
    />

    <p
      :data-testid="textStatustestId"
      class="font-medium text-sm"
      :class="getColorByCampaignStatus(status)"
    >
      {{ $t(campaign_status_str(status)) }}
    </p>
  </div>
</template>

<script setup>
import useCampaigns from "@/tools/useCampaigns.js";

// Props
const props = defineProps({
  status: {type: String, required: true},
  withBgAndBorder: {type: Boolean, default: false},
  statusTestId: {type: String, default: ""},
  textStatustestId: {type: String, default: ""}
})

// Functions
const { getBgByCampaignStatus, campaign_status_str, getColorByCampaignStatus, getBorderAndBgColorByCampaignStatus } = useCampaigns();

</script>