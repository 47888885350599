<template>
  <bkt-index-skeleton
    v-if="isManagerCreatorOrAbove()"
    body-class="bkt-bg-white rounded mt-20 mb-32"
  >
    <template #title>
      <p class="mb-5 font-medium">
        {{ $t("Campaigns") }} ({{ campaignPagination?.total_count || 0 }})
      </p>
    </template>

    <template #create-index>
      <v-btn
        :to="{ name: 'campaign_draft' }"
        color="primary"
        prepend-icon="mdi-plus"
        theme="interview"
      >
        {{ $t("Create campaign") }}
      </v-btn>
    </template>

    <template #body>
      <v-tabs
        v-model="tab"
        color="fakeBlack"
        align-tabs="center"
      >
        <v-tab
          value="current"
          class="flex flex-row"
        >
          {{ $t('Current') }}
          <span
            class="px-2 rounded-[5px] font-medium text-xs ml-2 bg-lightGrey"
          >
            {{ campaignPagination?.total_count || 0 }}
          </span>
        </v-tab>
        <v-tab
          value="upcoming"
          class="flex flex-row"
        >
          {{ $t('Upcoming') }}
          <span
            class="px-2 rounded-[5px] font-medium text-xs ml-2 bg-lightGrey"
          >
            {{ campaignDraftPagination?.total_count || 0 }}
          </span>
        </v-tab>
      </v-tabs>

      <v-window v-model="tab">
        <v-window-item value="current">
          <div>
            <campaign-index-search
              :tab="tab"
              :trashed-campaigns="trashedCampaigns"
              @fetch="reloadAfterSearch"
              @search-items="searchDeletedItems"
              @restore="restoreDeletedItems"
              @delete-permanently="deletePermanently"
            />

            <group-category-filter @fetch="updateCampaignsFromGroupCategoryFilter" />

            <campaign-table
              v-if="campaigns && campaigns?.length !== 0"
              @delete="deleteCampaign($event)"
            />

            <bkt-no-entity-from-index
              v-else-if="campaigns?.length === 0 && params?.title"
              :search="params?.title"
              :status="params?.status"
            />

            <bkt-no-entity-in-table
              v-else-if="(campaigns && campaigns.length === 0)"
              title="campaign"
            />

            <div v-if="!campaigns" class="flex h-[30vh] justify-center items-center h-full w-full">
              <svn-loader loading-size="lg" />
            </div>

            <bkt-create-entity-from-index
              v-else-if="
                campaigns?.length === 0 &&
                  params?.title === undefined &&
                  params?.status === 'All'
              "
              to="campaign_draft"
            >
              {{ $t("campaign") }}
            </bkt-create-entity-from-index>
          </div>
        </v-window-item>

        <v-window-item value="upcoming">
          <div>
            <campaign-index-search
              :tab="tab"
              :trashed-campaigns="trashedCampaigns"
              @fetch="reloadCampaignDraftsAfterSearch"
              @search-items="searchDeletedItems"
              @restore="restoreDeletedItems"
              @delete-permanently="deletePermanently"
            />

            <campaign-draft-table v-if="campaignDrafts && campaignDrafts.length !== 0" />

            <bkt-no-entity-from-index
              v-else-if="campaignDrafts?.length === 0 && campaignDraftParams?.title"
              :search="campaignDraftParams?.title"
              :status="campaignDraftParams?.status"
            />

            <bkt-no-entity-in-table
              v-else-if="(campaignDrafts && campaignDrafts.length === 0)"
              title="campaign"
            />

            <div v-if="!campaignDrafts" class="flex h-[30vh] justify-center items-center h-full w-full">
              <svn-loader loading-size="lg" />
            </div>

            <bkt-create-entity-from-index
              v-else-if="
                campaignDrafts?.length === 0 &&
                  params?.title === undefined &&
                  params?.status === 'All'
              "
              to="campaign_draft"
            >
              {{ $t("campaign") }}
            </bkt-create-entity-from-index>
          </div>
        </v-window-item>
      </v-window>
    </template>
  </bkt-index-skeleton>
</template>

<script setup>
import BktIndexSkeleton from "../../../components/BktIndexSkeleton.vue";
import { storeToRefs } from "pinia";
import CampaignIndexSearch from "./CampaignIndexSearch.vue";
import CampaignTable from "./CampaignTable.vue";
import CampaignDraftTable from "./CampaignDraftTable.vue";
import BktNoEntityInTable from "@/components/BktNoEntityInTable.vue";
import BktCreateEntityFromIndex from "../../../components/BktCreateEntityFromIndex.vue";
import BktNoEntityFromIndex from "../../../components/BktNoEntityFromIndex.vue";
import GroupCategoryFilter from "@/components/GroupCategoryFilter.vue";
import { onMounted, ref } from "vue";
import { useCampaignDraftStore } from "@/store/campaign-draft";
import i18n from "@/plugins/i18n.js";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import { useUserStore } from "@/store/user";
import { debounce } from "lodash";
import {useCampaignStore} from "@/store/campaign.js";
import { useCampaignTrashStore } from "@/store/campaign-trash.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";

const { campaigns, params, pagination: campaignPagination } = storeToRefs(useCampaignStore());
const { trashedCampaigns } = storeToRefs(useCampaignTrashStore());
const { campaignDrafts, params: campaignDraftParams, pagination: campaignDraftPagination } = storeToRefs(useCampaignDraftStore());
const { fetchCampaigns, updateParams, setCampaigns, destroyCampaign } = useCampaignStore()
const { restoreCampaign, destroyPermanentlyCampaign, fetchTrashCampaign } = useCampaignTrashStore()
const { fetchCampaignDrafts, setCampaignDrafts, updateParams: updateParamsCampaignDraft } = useCampaignDraftStore();
const { addNode, reset, update } = useBreadcrumbsStore()
const { isManagerCreatorOrAbove } = useUserStore();

const tab = ref(null);
const snackbar = useSnackbar();
const { isMobile } = storeToRefs(useMobileStore())
const reloadAfterSearch = async(params) => {
  setCampaigns(null);
  updateParams({
    title: params.title,
    status: params.status,
    "page[number]": 1,
  });
  fetchCampaigns();
}
const reloadCampaignDraftsAfterSearch = async(params) => {
  setCampaignDrafts(null);
  updateParamsCampaignDraft({
    title: params.title,
    status: params.status,
    "page[number]": 1,
  });
  fetchCampaignDrafts();
}

const updateCampaignsFromGroupCategoryFilter = (e) => {
  updateParams({ tags: e });
  fetchCampaigns();
};

onMounted(() => {
  if (reset('/interviews/campaigns')) {
    addNode(i18n.global.t('Campaigns'), '/interviews/campaigns')
    update()
  }
    fetchCampaigns().catch(e => console.log(e));
    fetchCampaignDrafts().catch(e => console.log(e));
    fetchTrashCampaign().catch(e => console.log(e));
})

const deleteCampaign = async(id) => {
  await destroyCampaign(id);
  const trash = campaigns?.value?.find(trash => trash?.id === id)
  trash.deleted_at = new Date()
  trashedCampaigns?.value?.push(trash)
  campaigns.value = campaigns?.value?.filter(trash => trash?.id !== id)
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreCampaign(id)
    campaigns?.value?.push(trashedCampaigns?.value?.find(trash => trash?.id === id))
    trashedCampaigns.value = trashedCampaigns?.value?.filter(trash => trash?.id !== id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Campaign restored successfully')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a training')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanently = async (id) => {
  try {
    await destroyPermanentlyCampaign(id)
    trashedCampaigns.value = trashedCampaigns?.value?.filter(trash => trash?.id !== id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Campaign has been permanently deleted')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a training')
    snackbar.setCustomClass(isMobile?.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const searchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashCampaign(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

</script>
