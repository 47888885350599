<template>
  <div class="w-full flex flex-col gap-4 p-4">
    <div class="flex items-center !w-full gap-4 sm:p-0">
      <v-text-field
        v-model="searchText"
        class="grow w-full input"
        color="primary"
        density="compact"
        variant="outlined"
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
        @update:model-value="updateText"
      />
      
      <svn-filter
        v-if="headers"
        :table-name="tableName"
        :filter-columns="filterList"
        :show-results="showResults"
        :selected-filters="getActiveFiltersCount()"
        @add-filter="addFilter"
        @activator-clicked="displayOrHideFilterResulsts"
        @get-filter-columns="getFilterColumns"
      />
    </div>

    <svn-filter-results
      :data="interviewForms"
      :table-name="tableName"
      :headers="headers"
      :show-results="showResults"
      :filter-list="filterList"
      :selected-filters="selectedFilters"
      :show-filter-resulsts="showFilterResulsts"
      @add-filter="addFilter"
      @delete-filter="deleteFilter"
      @clear-selected-filters="clearSelectedFilters"
      @remove-empty-filter="removeEmptyFilter"
      @update-selected-data-filters="updateSelectedDataFilters"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useInterviewFormStore } from "@/store/interview-form";
import { debounce } from "lodash";
import { useFilterStore } from "@/store/filters";
import SvnFilter from "@/components/SvnFilter.vue";
import SvnFilterResults from "@/components/SvnFilterResults.vue";

const emit = defineEmits(["fetch"]);

const { fetchFilterMetadata } = useFilterStore();
const { fetchInterviewForms } = useInterviewFormStore();

const { columns } = storeToRefs(useFilterStore());
const { params } = storeToRefs(useInterviewFormStore());
const { interviewForms } = storeToRefs(useInterviewFormStore());

const tableName = ref("interview_forms")
const showResults = ref(false);
const filterList = ref([]);
const filterData = ref([]);
const selectedFilters = ref([]);
const showFilterResulsts = ref(true);
const headers = ref([
  {
    name: "title",
    key: "title",
    title: "Template title",
    icon: "ic-baseline-title",
  },
  {
    name: "template_type",
    key: "template_type",
    title: "Type",
    icon: "ic-outline-arrow-drop-down-circle",
  },
  {
    name: "questions_count",
    key: "questions_count",
    title: "Questions",
    icon: "ic-person-outline",
  },
  {
    name: "updated_at",
    key: "updated_at",
    title: "Updated at",
    icon: "akar-icons:calendar",
  },
  {
    name: "tags",
    key: "tags",
    title: "Tags",
    icon: "ph-tag-bold",
  },
])
const searchText = ref(params.value.title);

const updateText = debounce((e) => {
  searchText.value = e;
  emit("fetch", {
    title: searchText.value,
  });
}, 300)

const getFilterColumns = async() => {
  if (!columns?.value) {
    try {
      await fetchFilterMetadata(tableName?.value)
      for (let column of columns?.value) {
        let foundCol = headers?.value?.find(el => el?.name === column?.name)
        if (foundCol) {
          let newCol = { ...column, key: foundCol?.key, name: foundCol?.title, icon: foundCol?.icon || "" }
          filterList?.value?.push(newCol)
        }
      }
    } catch (error) {
      console.log('Error')
    }
  }
}

const addFilter = (item) => {
  selectedFilters.value.push({ ...item, active: false })
  showFilterResulsts.value = true
}

const removeEmptyFilter = (item) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === item?.id), 1)
}

const deleteFilter = async(id) => {
  selectedFilters?.value?.splice(selectedFilters.value.findIndex(el => el?.id === id), 1)
  filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === id), 1)
  checkShowResulsts()

  if (filterData?.value?.length) {
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  } else {
    params.value = {}
  }

  await fetchInterviewFormsAfterFilter()
}

const clearSelectedFilters = async() => {
  selectedFilters.value = []
  showFilterResulsts.value = false
  showResults.value = false
  
  params.value = {}
  if (filterData?.value?.length) {
    filterData.value = []
    await fetchInterviewFormsAfterFilter()
  }
}

const updateSelectedDataFilters = async(data) => {
  let found = selectedFilters?.value?.find(el => el?.id === data?.id)
  let filter = filterData?.value?.find(element => element?.id === data?.event?.id)

  if (filter) {
    filter.operator = data?.event?.operator
    filter.value = data?.event?.value
  } else {
    filterData?.value?.push({
      id: data?.event?.id,
      name: data?.event?.name,
      operator: data?.event?.operator?.toLowerCase(),
      value: data?.event?.value,
    })
  }

  if (data?.event?.value?.length) {
    found.active = true
    checkShowResulsts()
    params.value = {
      filter_data: JSON.stringify(filterData?.value),
    }
  }
  else {
    found.active = false
    checkShowResulsts()
    filterData?.value?.splice(filterData?.value?.findIndex(el => el?.id === data?.id), 1)
    if (filterData?.value?.length) {
      params.value = {
        filter_data: JSON.stringify(filterData?.value),
      }
    } else {
      params.value = {}
    }
  }

  await fetchInterviewFormsAfterFilter()
}

const getActiveFiltersCount = () => {
  return selectedFilters?.value?.filter(el => el.active === true).length
}

const displayOrHideFilterResulsts = () => {
  showFilterResulsts.value = !showFilterResulsts.value
}

const checkShowResulsts = () => {
  if (selectedFilters?.value?.filter(el => el.active === true).length) {
    showResults.value = true
  }
  else {
    showResults.value = false
  }
}

const fetchInterviewFormsAfterFilter = async() => {
  try {
    await fetchInterviewForms()
  } catch (error) {
    console.log(error)
  }
}

watch(selectedFilters.value, (newValue, oldValue) => {
  if (newValue.length > 0) {
    showFilterResulsts.value = true
  }
  else {
    showFilterResulsts.value = false
  }
})
</script>

<style scoped>
.input :deep(.v-field) {
  height: 48px;
  border-radius: 8px;
}
</style>
