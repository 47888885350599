<template>
  <v-list-item
    v-if="!accessToken"
    class="cursor-pointer bkt-bg-light-grey10-hover"
    @click="launch"
  >
    <v-list-item-title>
      {{ $t('Connect to Slack') }}
    </v-list-item-title>
  </v-list-item>
  <v-menu
    v-else
    location="start"
  >
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        class="cursor-pointer bkt-bg-light-grey10-hover"
        append-icon="mdi-chevron-right"
      >
        <v-list-item-title>
          {{ $t('Slack notification') }}
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-list max-height="400">
      <v-list-item
        v-for="(item, idx) in channels"
        :key="idx"
        class="cursor-pointer bkt-bg-light-grey10-hover"
        :append-icon="slackChannelId === item.id ? 'mdi-check-bold' : ''"
        @click="slackChannelId === item.id ? $emit('leaveChannel', item.id) : $emit('channelSelected', item.id, item.name)"
      >
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import axiosService from "@/tools/axios-service.js";
import {useSnackbar} from "@/store/snackbar.js";

export default {
  props: {
    accessToken: {
      type: String,
      default() {
        return null
      }
    },
    slackChannelId: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      windowObjectReference: null,
      previousUrl: null,
      channels: []
    }
  },
  async beforeMount() {
    const snackbar = useSnackbar()

    if (this.accessToken) {
      try {
        const res = await axiosService.get('/external_oauth/slacks/channels', {
          params: {
            access_token: this.accessToken
          }
        })

        this.channels = res.data
      } catch (e) {
        snackbar.setStatus("warning").displaySnackBar(e?.message);
      }
    }
  },
  unmounted() {
    window.removeEventListener('message', this.receiveMessage.bind(this));
  },
  methods: {
    launch() {
      const url = new URL("https://slack.com/oauth/v2/authorize");
      url.searchParams.set("client_id", import.meta.env.VITE_SLACK_CLIENT_ID);
      url.searchParams.set("scope", "channels:join,channels:read,chat:write,commands,groups:read,im:read,mpim:read");
      url.searchParams.set("redirect_uri", import.meta.env.VITE_SLACK_REDIRECT_URI);

      this.openSignInWindow(url.href, "Slack integration")
    },
    openSignInWindow(url, name) {
      // remove any existing event listeners
      window.removeEventListener('message', this.receiveMessage.bind(this));

      // window features
      const strWindowFeatures =
        `toolbar=no, menubar=no, width=600, height=700, top=${window.innerHeight / 2 - 700 / 2}, left=${window.innerWidth / 2 - 600 / 2}, popup=yes`;

      if (this.windowObjectReference === null || this.windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
         or if such pointer exists but the window was closed */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
        this.windowObjectReference.focus();
      } else if (this.previousUrl !== url) {
        /* if the resource to load is different,
         then we load it in the already opened secondary window and then
         we bring such window back on top/in front of its parent window. */
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
        this.windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
         is not closed; therefore, we can bring it back on top of any other
         window with the focus() method. There would be no need to re-create
         the window or to reload the referenced resource. */
        this.windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener('message', this.receiveMessage.bind(this), false);
      // assign the previous URL
      this.previousUrl = url;
    },
    receiveMessage(event) {
      // Do we trust the sender of this message? (might be
      // different from what we originally opened, for example).
      const trustedUrl = new URL(import.meta.env.VITE_SLACK_REDIRECT_URI)

      if (event.origin !== trustedUrl.origin) {
        return;
      }

      const accessToken = event.data;
      // this line emits an event like emitEvent in vuejs
      this.$el.dispatchEvent(new CustomEvent("connected", { detail: accessToken }));
    },
  }
}

</script>