<template>
  <v-data-table
    :headers="headers"
    :loading="roadmapTemplates ? false : true"
    :items="roadmapTemplates ? roadmapTemplates : []"
    :items-per-page="-1"
    class="mt-4"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in headers"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey text-sm font-medium"
        >
          <span>{{ header.title }}</span>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey9-hover h-14"
        @click="goToTemplateShow(item.raw.id)"
      >
        <td class="px-0">
          <div
            class="flex flex-row justify-start items-center px-4 max-w-[250px]"
          >
            <p class="font-normal text-fakeBlack text-sm text-truncate">
              {{ item?.raw.title }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <div class="flex flex-row justify-start px-4">
            <p
              class="text-fakeBlack text-sm font-normal"
            >
              {{ item?.raw?.due_date }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <div class="flex flex-row justify-start px-4">
            <p
              class="text-fakeBlack text-sm font-normal"
            >
              {{ getUpdatedAtDate(item?.raw.updated_at) }}
            </p>
          </div>
        </td>

        <td class="px-0">
          <svn-dots-button
            table
            circle
            variant="hoverNoPrimary"
          >
            <template #items>
              <v-list-item
                value="delete"
                class="text-negativeRed"
                @click="openPopUpDelete(item.raw.id)"
              >
                {{ $t('Delete template') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </td>
      </tr>
    </template>

    <template #loading>
      <div class="flex flex-col justify-center items-center">
        <svn-loader loading-size="xl" class="mt-8 mb-8" />
      </div>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && roadmapTemplates && roadmapTemplates.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="p-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>

        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>

    <template #no-data>
      <div class="flex flex-col jsutify-center items-center my-16">
        <bkt-create-entity-from-index
          to="roadmaps_templates_new"
        >
          {{ $t("template") }}
        </bkt-create-entity-from-index>
      </div>
    </template>
  </v-data-table>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import { usePopUpStore } from "@/store/pop-up";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar.js";
import { useRoadmapTemplateStore } from "@/store/roadmap-template.js";
import { useRouter } from "vue-router";
import i18n from '@/plugins/i18n';
import moment from "moment";

const emit = defineEmits(['fetch-new-page'])

const headers = [
  {
    align: "start",
    key: "template_title",
    sortable: false,
    title: i18n.global.t('Template title'),
  },
  {
    align: "start",
    key: "due_date",
    sortable: false,
    title: i18n.global.t('Due date'),
  },
  {
    align: "start",
    key: "last_update",
    sortable: false,
    title: i18n.global.t('Last update'),
  },
  {
    align: "start",
    key: "",
    sortable: false,
    title: " ",
  },
];

const router = useRouter();
const snackbar = useSnackbar();
const { openPopUp, closePopUp } = usePopUpStore();
const { roadmapTemplates, pagination } = storeToRefs(useRoadmapTemplateStore());
const { deleteRoadmapTemplate, setRoadmapTemplates } = useRoadmapTemplateStore();

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: "Are you sure you want to delete this template ?",
    subtitle: "This is a permanent action.",
    textClose: "No, cancel",
    textConfirm: "Yes, delete this template",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: 'red',
    customClass: "w-[400px]",
    showClose: false,
    close() {
    },
    async confirm() {
      try {        
        await deleteRoadmapTemplate(id);
        const tmpTemplates = roadmapTemplates.value.filter(obj => obj.id !== id);
        setRoadmapTemplates(tmpTemplates);
        closePopUp();
        snackbar.setDefaultColor('info').displaySnackBar('Your template has been deleted !');
      } catch (error) {
        snackbar.setDefaultColor('error').displaySnackBar('Error deleting your template !');
      }
    },
  });
}

const goToTemplateShow = (id) => {
  router.push({ name: 'roadmaps_templates_show', params: { id } });
}

const getUpdatedAtDate = (date) => {
  const newDate = moment(date);
  return newDate.format('YYYY-MM-DD');
}

const fetchNewPage = (page) => {
  emit('fetch-new-page', page)
}
</script>
