<template>
  <div class="relative w-full sm:w-4/5 md:max-w-[589px] mx-auto">
    <p
      v-if="targetId"
      class="font-semibold text-center text-fakeBlack text-xl mt-10"
    >
      {{ $t("Edit target") }}
    </p>

    <p
      v-else
      class="font-semibold text-center text-fakeBlack text-xl mt-10"
    >
      {{ $t("Create a target") }}
    </p>
    <v-btn
      v-if="!overlayMode"
      class="absolute right-0 -bottom-1"
      density="comfortable"
      icon="mdi-close"
      @click="close"
    />
  </div>
  <bkt-form
    :loading="loading"
    :pop-up-mode="overlayMode"
    :closable="overlayMode"
    @close="close"
    @submit="handleSubmit"
  >
    <div
      v-if="buildingPage"
      class="flex justify-center items-center h-screen w-full"
    >
      <svn-loader loading-size="lg" class="mx-auto" />
    </div>

    <div
      v-else
      class="w-full p-6"
    >
      <p class="mb-4 text-base font-medium text-fakeBlack">
        {{ $t("Target informations") }}
      </p>

      <div class="flex flex-col items-center">
        <v-text-field
          v-model="title"
          class="w-full mt-4 "
          :disabled="indicator.attribute_disablement?.title"
          color="primary"
          v-bind="isMobile ? { variant: 'outlined' } : ''"
          :label="$t('Title*')"
        />

        <p
          v-if="titleError"
          class="text-xs self-start text-start text-negativeRed font-normal -mt-4"
        >
          {{ $t("Fill the title please") }}
        </p>

        <div class="w-full flex flex-row justify-between items-center">
          <div class="w-[70%] flex-1">
            <svn-autocomplete
              :model-value="selectedUsers"
              :items="users"
              :label="$t('People')"
              hide-details
              :disabled="isUpdatingUsers || overlayMode || route.query.fromRoute === 'my_roadmaps'"
              avatar
              chips
              closable-chips
              color="primary"
              item-color="primary"
              multiple
              @update:model-value="wrapToFixVuetifyError"
              @input="getUsers($event)"
            >
              <template #chip-content="{ props, item }">
                <v-chip
                  v-bind="props"
                  :key="item.value"
                  close-icon="mdi-close-circle"
                  variant="default"
                  color="red"
                  class="!text-fake-black bg-middle-grey mx-[2px] rounded-full"
                  :class="chipClass"
                  @click:close="removeItem(item)"
                />
              </template>

              <template #main-content>
                {{ null }}
              </template>

              <template #append-content>
                {{ null }}
              </template>
            </svn-autocomplete>
          </div>

          <div class="w-[40%] md:w-[30%]">
            <v-checkbox-btn
              v-if="!usersFromOverlay"
              v-model="allUsers"
              :disabled="isUpdatingUsers || overlayMode || route.query.fromRoute === 'my_roadmaps'"
              color="primary"
              @update:model-value="toggleAllUsers"
            >
              <template #label>
                <span class="whitespace-nowrap">{{ $t('Select all') }}</span>
              </template>
            </v-checkbox-btn>
          </div>
        </div>
      </div>

      <template v-if="indicator.attribute_disablement?.description">
        <div
          class="mt-4 mx-4 p-2 border bg-veryLightGrey border-gray-300 rounded bg-white overflow-y-auto max-h-48"
          v-html="desc"
        />
      </template>
      <template v-else>
        <v-textarea
          v-model="desc"
          class="mt-4"
          color="primary"
          :label="$t('Description')"
          v-bind="isMobile ? { variant: 'outlined' } : ''"
          hide-details
        />
      </template>

      <div class="mt-4">
        <svn-date-picker
          v-model="date"
          :disable="indicator.attribute_disablement?.due_date || overlayMode && objectiveTemplateId"
          :label="$t('Deadline')"
        />
      </div>
      <p class="text-[10px] font-normal text-darkGrey mt-2">
        {{ $t('*Mandatory fields') }}
      </p>

      <div
        class="mt-8"
      >
        <p class="text-base text-fakeBlack font-medium mb-2 ">
          {{ $t("Target Indicator") }}
        </p>

        <target-indicator-values
          v-model="indicator"
          :is-trashed="isTrashed"
          :create="true"
        />
      </div>
    </div>
  </bkt-form>
</template>

<script setup>
import BktForm from "@/components/BktForm.vue";
import TargetIndicatorValues from "@/components/roadmapApp/TargetIndicatorValues.vue";
import { onMounted, ref } from "vue";
import axiosService from "@/tools/axios-service.js";
import { useRoute, useRouter } from "vue-router";
import { useRoadmapTemplateStore } from "@/store/roadmap-template.js";
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile.js";
import { storeToRefs } from "pinia";
import { useInterviewStore } from "@/store/interview";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";

const { fetchTargetsSuggestionsCreated } = useTargetSuggestionsStore();
const snackbar = useSnackbar();
snackbar.checkStatus();

const props = defineProps([
  "overlayMode",
  "usersFromOverlay",
  "targetId",
  "objectiveTemplateId",
  "isEditingTarget",
  "createDraft",
  "from"
]);

const emit = defineEmits(["close"]);
const { fetchRoadmapTemplate } = useRoadmapTemplateStore();

const route = useRoute();
const router = useRouter();
const { isMobile } = storeToRefs(useMobileStore())
const { id, campaignId, employee } = storeToRefs(useInterviewStore());

const targetDraft = ref(null);
const buildingPage = ref(true);
const loading = ref(false);
const title = ref(null);
const isTrashed = ref(false);
const titleError = ref(false);
const desc = ref(null);
const date = ref(null);
const indicator = ref({
  indicator_type: "boolean",
  starting_value: null,
  target_value: null,
  multi_choice_list: [{ option: "", checkbox: true }],
});
const users = ref([]);
const allUsers = ref(false);
const isUpdatingUsers = ref(true);
const selectedUsers = ref(
 route?.query?.usersFromOverlay
      ? typeof route?.query?.usersFromOverlay === 'string' ?
        [parseInt(route?.query?.usersFromOverlay)] :
        route?.query?.usersFromOverlay?.map((item) => ({ id: parseInt(item) })) : []
);
const search = ref()

onMounted(async () => {
  const filteredUsers =  null;
  if (props.usersFromOverlay && props.usersFromOverlay.length > 0) {
    users.value = props.usersFromOverlay;
    selectedUsers.value = props.usersFromOverlay.map((user) => ({ id: parseInt(user.id) }));
  } else {
    await getUsers();

    const routeUsersFromOverlay = route.query.usersFromOverlay;
    if (routeUsersFromOverlay && routeUsersFromOverlay.length !== 0) {
      const ids = routeUsersFromOverlay.map(id => Number(id));
      users.value = users.value.filter(user => ids.includes(user.id));
    }
  }


  if (route.query.fromRoute === "my_team_roadmaps") {
    selectedUsers.value = []
  }

  if (route?.query?.objectiveTemplateId || props.objectiveTemplateId) {
    const entity = await fetchRoadmapTemplate(route?.query?.objectiveTemplateId || props.objectiveTemplateId);

    mergeToCurrentTemplate(entity);
  }
  else if (props.targetId && !props.isEditingTarget) {
    const target = await fetchTarget(props.targetId);

    mergeToCurrentTemplate(target);
  }
  else if (props.isEditingTarget) {
    try {
    const res = await axiosService.get(`/api/v1/roadmaps/target_drafts/${props?.targetId}`)

    targetDraft.value = res.data?.objective_draft
    }
    catch (e) {
      snackbar.setBgColor("negativeRed")
      snackbar.setMsg('Error fetching target draft')
      snackbar.displaySnackBar();
    }
    mergeToCurrentTemplate(targetDraft.value);
  }
  buildingPage.value = false;
});

const fetchTarget = async (id) => {
  try {
    const res = await axiosService.get(`/api/v1/roadmaps/targets/${id}`);

    return res.data["objective/element"];
  } catch (e) {
    console.log("couldn't fetch target");
    return null;
  }
};

const mergeToCurrentTemplate = (template) => {
  title.value = template.title;
  desc.value = template.description;
  isTrashed.value = template.status === 'trashed'
  date.value = template.due_date;
  indicator.value.indicator_type = template.objective_indicator.indicator_type;
  indicator.value.attribute_disablement = template?.attribute_disablement;
  indicator.value.starting_value =
    template.objective_indicator.options.starting_value;
  indicator.value.target_value =
    template.objective_indicator.options.target_value;
  indicator.value.multi_choice_list = [];

  if (indicator.value.indicator_type === "multi_choice") {
    indicator.value.multi_choice_list = template.objective_indicator?.options?.multi_choice_list
  }
};

const getUsers = async (text = '') => {
  try {
    const { data } = await axiosService.get(`/api/v1/users/users_search`,
      {
        params: {
          only_manager_employees: true,
          text: text
        }
      }
    );

    users.value = data.users
    if (users.value.length === 1) {
      selectedUsers.value = []
      selectedUsers.value.push({id: users.value[0].id})
    }
  } catch (e) {
    console.log("error", e);
  } finally {
    isUpdatingUsers.value = false;
  }
};

const handleSubmit = async () => {
  if (!title.value) {
    titleError.value = true;
    return;
  }
  if (selectedUsers.value.length === 0) {
    snackbar
      .setDefaultColor("failed")
      .displaySnackBar("You need to set at least one employee");
    return;
  }

  loading.value = true;
  try {
    const toSend = {
      title: title.value,
      description: desc.value,
      attribute_disablement: indicator.value.attribute_disablement,
      indicator: indicator.value,
      user_ids: selectedUsers.value?.map((item) => item.id),
      due_date: date.value,
      create_draft: props.createDraft,
      campaign_id: campaignId?.value,
    };

    if (props.targetId && !props.isEditingTarget) {
      await axiosService.put(`/api/v1/roadmaps/targets/${props.targetId}`, toSend);
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Your target has been updated successfully.')
      snackbar.displaySnackBar()
    }
    else if (props.isEditingTarget) {
      const { data } = await axiosService.put(`/api/v1/roadmaps/target_drafts/${props.targetId}`, toSend);
      let newTargetData = data

      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Your target has been updated successfully.')
      snackbar.displaySnackBar()
      emit("close", newTargetData)
    }
    else if (!props.createDraft) {
      await axiosService.post("/api/v1/roadmaps/targets", toSend);
      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Your target has been created successfully.')
      snackbar.displaySnackBar()
    }
    else {
      await axiosService.post('/api/v1/roadmaps/target_drafts', toSend);
      await fetchTargetsSuggestionsCreated(employee?.value?.id, id?.value)

      snackbar.setBgColor('positiveGreen')
      snackbar.setMsg('Your target has been created successfully.')
      snackbar.displaySnackBar()
      emit("close")
    }
    if (!props.overlayMode) await router.push(history.state.back);
    else emit("close")
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while creating your target !')
    snackbar.displaySnackBar()
  } finally {
    loading.value = false;
  }
};

const close = async () => {
  if (!props.overlayMode) await router.push(history.state.back);
  else emit("close");
};

const toggleAllUsers = () => {
  if (allUsers.value) {
    selectedUsers.value = users.value;
  } else {
    selectedUsers.value = [];
  }
};

const wrapToFixVuetifyError = (arg) => {
  selectedUsers.value = arg.map((item) => ({ id: item }));
  search.value = ''
};
</script>
