import {defineStore} from "pinia";
import {genericFunctions, genericStates} from "@/tools/genericFunctions.js";
import axiosService from "@/tools/axios-service.js";

export const useMyTeamRoadmapUnarchivedStore = defineStore('myteamroadmapunarchived', {
  state: () => ({
    users: null,
    ...genericStates
  }),
  actions: {
    ...genericFunctions,
    async fetchMyTeamRoadmapUnarchived(id, page = 1, title = '') {
      this.params = {
        'page[number]': page,
        title
      }
      await this.fetchEntity(
        `/api/v1/roadmaps/employees/${id}/team_unarchived_targets`,
        'users'
      );
    }
  }
})