<template>
  <div
    v-if="interviewForm"
    class="w-full mt-4 sm:!mt-10 px-4 sm:!px-0"
  >
    <div
      class="flex flex-col"
    >
      <p
        v-if="campaign"
        class="text-center text-fakeBlack font-medium text-xl sm:!hidden mb-4"
      >
        {{ campaign.title }}
      </p>

      <span class="flex-1 sm:!hidden self-center text-right text-gray-500">
        {{ $t("Deadline") }} :
        {{ campaign.deadline ? campaign.deadline : $t("No deadline") }}
      </span>

      <div class="flex flex-col justify-center sm:w-full sm:flex-row sm:justify-between items-center">
        <span
          v-if="campaign.campaign_type === 'OneToOne'"
          class="flex-1 text-gray-500 text-center mb-8 sm:!mb-0 sm:!text-start"
        >
          {{
            $t("'s Interview with", {
              interviewee: employee?.firstname,
              interviewer: interviewer?.firstname,
            })
          }}
        </span>

        <span
          v-else-if="campaign.campaign_type === 'Feedback'"
          class="flex-1 text-gray-500 text-center mb-8 sm:!mb-0 sm:!text-start"
        >
          {{
            $t("Feedback of ", {
              interviewee: participantFullname,
            })
          }}
        </span>

        <span
          v-else-if="campaign.campaign_type === 'Survey'"
          class="flex-1 text-gray-500 text-center mb-8 sm:!mb-0 sm:!text-start"
        >
          {{
            $t("Survey - x participants", participantsCount, {participantsCount})
          }}
        </span>

        <span class="flex-1 hidden sm:!block self-end text-right text-gray-500">
          {{ $t("Deadline") }} :
          {{ campaign.deadline ? campaign.deadline : $t("No deadline") }}
        </span>
      </div>

      <p
        v-if="campaign"
        class="text-center text-fakeBlack hidden sm:!block font-medium text-3xl mt-12"
      >
        {{ campaign.title }}
      </p>
    </div>

    <div
      class="my-8 break-words w-full description"
      v-html="interviewForm.description"
    />

    <div
      v-if="isValidLink"
      class="mt-4 flex flex-col justify-center items-center gap-4 description"
    >
      <iframe
        id="ytplayer"
        class="mx-auto"
        type="text/html"
        width="640"
        height="360"
        :src="
          'https://www.youtube.com/embed/' +
            interviewForm.video?.match(
              /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
            )[1]
        "
      />
    </div>

    <div class="flex flex-col gap-2 sm:!gap-8">
      <component
        :is="question.componentName"
        v-for="(question, idx) in questionsComponent"
        :key="idx"
        :loop-idx="idx"
        :question="question.data"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useInterviewStore } from "@/store/interview.js";
import ChapterAnswer from "@/components/interviewApp/interview_answers/ChapterAnswer.vue";
import ParagraphAnswer from "./ParagraphAnswer.vue";
import OpenQuestionAnswer from "@/components/interviewApp/interview_answers/OpenQuestionAnswer.vue";
import RatingAnswer from "@/components/interviewApp/interview_answers/RatingAnswer.vue";
import McqAnswer from "@/components/interviewApp/interview_answers/McqAnswer.vue";
import RoadmapBlockUpdate from "@/components/interviewApp/interview_answers/RoadmapBlockUpdate.vue";
import RoadmapBlockCreate from "@/components/interviewApp/interview_answers/RoadmapBlockCreate.vue";
import { InterviewQuestionType } from '@/constants/types';

export default {
  components: {
    RoadmapBlockUpdate,
    RoadmapBlockCreate,
    ParagraphAnswer,
    ChapterAnswer,
    OpenQuestionAnswer,
    RatingAnswer,
    McqAnswer,
  },
  setup() {
    const { interviewForm, employee, interviewer, campaign, participantFullname, participantsCount } = storeToRefs(
      useInterviewStore()
    );

    const isValidLink = computed(() => {
      return interviewForm.value.video && interviewForm.value.video.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return interviewForm.value.questions.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER)
          componentName = "chapter-answer";
        else if (question.type === InterviewQuestionType.OPEN)
          componentName = "open-question-answer";
          else if (question.type === InterviewQuestionType.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question.type === InterviewQuestionType.RATING)
          componentName = "rating-answer";
        else if (question.type === InterviewQuestionType.MCQ) componentName = "mcq-answer";
        else if (question.type === InterviewQuestionType.UPDATE_ROADMAP)
          componentName = "roadmap-block-update";
        else if (question.type === InterviewQuestionType.CREATE_ROADMAP)
          componentName = "roadmap-block-create";

        return { data: question, componentName };
      });
    });
    return {
      campaign,
      employee,
      interviewer,
      interviewForm,
      questionsComponent,
      participantFullname,
      participantsCount,
      isValidLink
    };
  },
};
</script>