<template>
  <v-data-table
    v-if="campaigns"
    :headers="headers"
    :items="campaigns"
    :loading="loading"
    :items-per-page="-1"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in headers"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey font-semibold"
        >
          <div class="flex flex-row items-center">
            <Icon
              :icon="header.icon"
              width="16"
              height="16"
              class="mr-1"
            />
            <span>{{ header.title }}</span>
          </div>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey10-hover"
        @click="goToCampaign(item.raw?.title, item.raw?.id)"
      >
        <td>
          <div class="w-[125px] flex flex-row items-center gap-2">
            <Icon
              :icon="types[item.raw?.campaign_type]?.icon"
              width="16"
              height="16"
              class="mr-1"
            />

            <span
              class="max-w-[125px] text-sm font-normal overflow-ellipsis"
            >
              {{ types[item.raw?.campaign_type]?.title }}
            </span>
          </div>
        </td>

        <td>
          <p
            class="max-w-[400px] text-sm font-medium overflow-ellipsis"
            :title="item.raw?.title"
          >
            {{ item.raw?.title }}
          </p>
        </td>

        <td>
          <div class="flex items-center w-[200px]">
            <p class="text-sm font-medium">
              {{ moment(item.raw?.startline, "YYYY-MM-DD").format("D MMM, YYYY") }}
            </p>

            <Icon
              class="iconify mx-1"
              icon="bi:arrow-right"
            />

            <p class="text-sm font-normal">
              {{ moment(item.raw?.deadline, "YYYY-MM-DD").format("D MMM, YYYY") }}
            </p>
          </div>
        </td>

        <td class="w-[350px]">
          <display-tag-in-index
            :tags="item.raw?.categories"
          />
        </td>

        <td>
          <p class="text-sm font-normal">
            {{ item.raw?.employees_count }}
          </p>
        </td>

        <td>
          <div
            :class="getClassByCompletion(item.raw?.completion)"
            class="w-12 p-1 rounded font-bold leading-6 text-white text-center"
          >
            <p class="text-xs">
              {{ item.raw?.completion }}%
            </p>
          </div>
        </td>

        <td class="pa-0">
          <div class="flex justify-end items-end">
            <svn-dots-button
              table
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  value="edit_deadline"
                  @click="openEditDeadline(item.raw?.id, item?.raw?.deadline)"
                >
                  {{ $t('Edit deadline') }}
                </v-list-item>

                <v-list-item
                  value="relaunch_campaign"
                  @click="relaunchCampaign(item.raw?.id)"
                >
                  {{ $t('Relaunch campaign') }}
                </v-list-item>

                <v-list-item
                  v-if="item.raw?.can_set_copilot === true"
                  value="manage_copilots"
                  @click="openManageCopilotsPopUp(item.raw?.id)"
                >
                  {{ $t('Manage Co-pilots') }}
                </v-list-item>

                <dialog-edit-template-tags
                  fetch-tags-from-entity-path="categories_from_campaign"
                  route="campaigns"
                  :entity-id="item.raw?.id"
                  @refresh="fetchCampaigns"
                >
                  <template #activator>
                    <v-list-item
                      value="edit_tags"
                    >
                      {{ $t('Edit campaign tags') }}
                    </v-list-item>
                  </template>
                </dialog-edit-template-tags>

                <v-list-item
                  v-if="item.raw?.completion !== 100"
                  value="send_emails"
                  @click="sendRemainder(item.raw?.id)"
                >
                  {{ $t('Send email to participants') }}
                </v-list-item>

                <v-list-item
                  v-if="item.raw?.completion !== 100"
                  value="delete_campaign"
                  class="text-negativeRed"
                  @click="deleteCampaign(item.raw?.id)"
                >
                  {{ $t('Delete campaign') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </td>
      </tr>

      <v-dialog v-model="sendModal">
        <v-card>
          <v-card-text> send</v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteModal">
        <v-card>
          <v-card-text> delete</v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && campaigns && campaigns.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="mt-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>
        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>
  </v-data-table>
</template>

<script setup>
import { ref } from "vue";
import DisplayTagInIndex from "../../../components/DisplayTagInIndex.vue";
import DialogEditTemplateTags from "@/components/BktPopUp/Dialogs/DialogEditTemplateTags.vue";
import { Icon } from "@iconify/vue";
import { useUserStore } from "@/store/user";
import { useCampaignStore } from "@/store/campaign.js";
import { storeToRefs } from "pinia";
import { usePopUpStore } from "@/store/pop-up.js";
import useTools from "@/tools/useTools.js";
import { useSnackbar } from "@/store/snackbar";
import axios from "@/tools/axios-service.js";
import i18n from "@/plugins/i18n";
import {useBreadcrumbsStore} from "@/store/breadcrumbs.js";
import { useRouter } from "vue-router";
import moment from "moment";
const headers = [
  {
    align: "start",
    key: "campaign_type",
    title: i18n.global.t("Type"),
    icon: "icon-park-outline:down-c",
    sortable: false,
    width: 20
  },
  {
    align: "start",
    key: "campaign_title",
    title: i18n.global.t("Campaign title"),
    icon: "mdi-format-text",
    sortable: false,
  },
  {
    title: i18n.global.t("Period"),
    key: "period",
    icon: "akar-icons:calendar",
    sortable: false,
  },
  {
    align: "start",
    key: "tags",
    title: i18n.global.t("Tags"),
    icon: "fluent:tag-32-regular",
    sortable: false,
  },
  {
    align: "start",
    key: "participants", title: "Participants", icon: "mdi-account-outline", sortable: false,
  },
  {
    align: "start",
    key: "completion", title: "Completion", icon: "ic:baseline-percent", sortable: false,
  },
  {
    align: "start",
    key: "space", title: " ", icon: " ", sortable: false,
  }
];

const types = {
  Survey: {
    key: "survey",
    title: i18n.global.t("Survey"),
    icon: "noto:bar-chart"
  },
  OneToOne: {
    key: "one_to_one",
    title: i18n.global.t("One to One"),
    icon: "twemoji:handshake"
  },
  Feedback: {
    key: "feedback_360",
    title: i18n.global.t("Feedback"),
    icon: "noto:memo"
  }
}

const type = ref(false)
const router = useRouter()
const editModal = ref(false);
const sendModal = ref(false);
const deleteModal = ref(false);
const snackbar = useSnackbar();
const { accessLevel } = storeToRefs(useUserStore());
const { campaigns, pagination, loading } = storeToRefs(useCampaignStore());
const { updateParams, fetchCampaigns, setCampaigns } = useCampaignStore();
const { addNode, update } = useBreadcrumbsStore()
const { fetchPage } = useTools();
const { closePopUp, openPopUp } = usePopUpStore();



const emit = defineEmits(["delete"])
const getClassByCompletion = (completion) => {
  if (completion === 0) {
    return "bkt-bg-light-grey";
  } else if (completion === 100) {
    return "bkt-bg-green";
  }
  return "bkt-bg-yellow";
};

const relaunchCampaign = async(campaignDraftId) => {
  try {
    await axios.post(`/api/v2/interview_app/campaign_drafts/${campaignDraftId}/relaunch_campaign`);
    router.push({ name: 'campaign_draft' });
  } catch (error) {
    snackbar.setMsg(i18n.global.t('Error relaunching campaign !'));
    snackbar.setBgColor('error');
    snackbar.displaySnackBar();
  }
}

const openEditDeadline = (campaignId, deadline) => {
    openPopUp({
      componentName: "pop-up-campaign-edit-deadline",
      title: i18n.global.t('Edit deadline'),
      campaignId: campaignId,
      icon: '',
      deadline: deadline,
      customClass: "",
      showClose: true,
      close() {
        fetchCampaigns();
      },
    });
};

const sendRemainder = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t("Are you sure you want to send emails to all participants ?"),
    subtitle: i18n.global.t("All participants will receive an email about this campaign."),
    textClose: "No",
    textConfirm: "Yes, do it",
    textLoading: "Sending ...",
    icon: "",
    color: "blue",
    customClass: "w-[400px]",
    showClose: false,
    async confirm() {
      try {
        await axios.get(`/api/v2/interview_app/campaigns/${id}/send_notification_email?format=json`);
        closePopUp();
        openPopUp({
          componentName: "pop-up-action-done",
          title: i18n.global.t(`Reminder is on its way !`),
          description: "The participants will receive an email in a few moments.",
          textConfirm: "Great !",
          icon: '',
          color: "blue",
          appName: "Interviews",
          confirm() {
            closePopUp();
          },
        });
      } catch (error) {

      }
    },
  });
};

const deleteCampaign = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t("Are you sure you want to delete this campaign ?"),
    subtitle: i18n.global.t("This is a permanent action."),
    textClose: "No, cancel",
    textConfirm: "Yes, delete this campaign",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: "red",
    customClass: "w-[500px]",
    showClose: false,
    async confirm() {
      try {
        emit('delete', id)
      } catch (e) {
        console.log('error', e)
      } finally {
        closePopUp();
      }
    },
  });
};

const fetchNewPage = (e) => {
  setCampaigns(null);
  updateParams({
    "page[number]": e,
  });
  fetchCampaigns();
};

const goToCampaign = (title, id) => {
  addNode(title, `/interviews/campaigns/${id}/overview`)
  update()
  router.push({
    name: 'campaign_overview',
    params: { id },
    query: { tab: 1 }
  })
}

const openManageCopilotsPopUp = (campaignId) => {
  openPopUp({
    componentName: "pop-up-manage-copilots",
    title: i18n.global.t('Manage co-pilots'),
    subtitle: '',
    icon: '',
    campaignId: campaignId,
    customClass: "h-[573px] lg:w-[800px] !justify-normal",
  });
}
</script>
