<template>
  <v-select
    v-model="lang"
    :items="langs"
    item-title="title"
    item-value="value"
    hide-details
    density="compact"
    variant="outlined"
    base-color="middleGrey"
    class="w-full"
    @update:model-value="changeLang"
  >
    <template #selection="{ item }">
      <svn-text base regular class="w-full truncate">
        {{ item.title }}
      </svn-text>
    </template>

    <template #item="{ props, item }">
      <v-list-item
        :key="item.title"
        :title="item.title"
        v-bind="props"
      >
        <template #prepend>
          <Icon
            class="mr-3"
            :icon="item.raw.flag"
            width="25"
            height="25"
          />
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { Icon } from "@iconify/vue";

const props = defineProps({
  language: { type: String, default: "en" },
})

onMounted(() => {
  lang.value = props.language;
});

const emit = defineEmits(['change-lang']);

const langs = [
  {
    flag: "noto-v1:flag-for-flag-united-kingdom",
    value: "en",
    title: "English",
  },
  {
    flag: "noto-v1:flag-for-flag-france",
    value: "fr",
    title: "Français",
  },
];

const lang = ref(null);

const changeLang = async (lang) => {
  emit('change-lang', lang)
};

watch(props, (newValue, oldValue) => {
  if (newValue.language) {
    lang.value = newValue.language
  }
});
</script>

<style scoped>
* :deep(.v-field){
  border-radius: 8px;
}
</style>
