<template>
  <div
    class="flex flex-col gap-8 border-[0.5px] border-middle-grey bg-white rounded-[12px] p-10"
  >
    <!-- Target question card header -->
    <div class="flex flex-col gap-10 items-center">
      <div class="flex flex-col gap-2 items-center">
        <v-icon
          class="text-[#5C95FF]"
          icon="mdi-bullseye-arrow"
          size="48"
        />

        <p class="text-xl text-center font-medium text-fakeBlack">
          {{ target.title }}
        </p>
      </div>

      <div class="flex flex-row justify-between w-[424px]">
        <div class="flex flex-col gap-2 items-center max-w-[140px]">
          <p class="text-fakeBlack font-medium text-sm">
            {{ $t('Starting value') }}
          </p>

          <p class="text-middleGrey text-center font-medium text-xl">
            {{ target?.objective_indicator?.options?.starting_value || '-' }} {{ target?.objective_indicator?.indicator_type === 'percentage' ? '%' : '' }}
          </p>
        </div>

        <div class="flex flex-col gap-2 items-center max-w-[140px]">
          <p class="text-fakeBlack font-medium text-sm">
            {{ $t('Current value') }}
          </p>

          <p
            v-if="!isDraft"
            class="text-[#5C95FF] text-center font-medium text-xl"
          >
            {{ target?.objective_indicator?.options?.current_value || '-' }} {{ target?.objective_indicator?.indicator_type === 'percentage' ? '%' : '' }}
          </p>

          <p
            v-else
            class="text-[#5C95FF] text-center font-medium text-xl"
          >
            {{ '-' }}
          </p>
        </div>

        <div class="flex flex-col gap-2 items-center max-w-[140px]">
          <p class="text-fakeBlack font-medium text-sm">
            {{ $t('Target value') }}
          </p>

          <p
            v-if="target?.objective_indicator?.indicator_type === 'multi_choice'"
            class="text-middleGrey text-center font-medium text-xl"
          >
            {{ getMCQPossibleAnswers(target?.objective_indicator?.options?.multi_choice_list) }} {{ $t('possible answers') }}
          </p>

          <p
            v-else
            class="text-middleGrey text-center font-medium text-xl"
          >
            {{ target?.objective_indicator?.options?.target_value || '-' }} {{ target?.objective_indicator?.indicator_type === 'percentage' ? '%' : '' }}
          </p>
        </div>
      </div>
    </div>

    <!-- Target infos & Target description -->
    <div class="self-start w-full py-0">
      <!-- Target infos (users, deadline) -->
      <v-list
        v-model:opened="targetInfoOpened"
        class="!py-0"
      >
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="Opened"
        >
          <template #activator="{ props, isOpen }">
            <v-list-item
              v-bind="props"
              class="pl-0 border-b border-b-dark-grey"
            >
              <div class="flex gap-2 items-center justify-center">
                <Icon
                  :icon="isOpen ? 'ic:baseline-arrow-drop-down' : 'ic:baseline-arrow-right'"
                  width="16"
                  height="16"
                  class="text-fakeblack"
                />

                <p class="w-full text-fakeBlack font-medium text-sm">
                  {{ $t('Target info') }}
                </p>
              </div>
            </v-list-item>
          </template>

          <div class="flex flex-col gap-4">
            <v-divider
              thickness="1"
              color="negativeRed"
              class="border-opacity-100"
            />

            <div class="w-full flex flex-col px-4 gap-4 py-3">
              <div class="flex flex-row justify-between">
                <roadmap-block-target-users
                  :objectivable="target?.objectivable"
                  :manager="target?.manager"
                  :creator="target?.creator"
                />

                <div class="flex flex-row items-center space-x-1">
                  <div>
                    <Icon
                      icon="material-symbols:today-rounded"
                      color="darkGrey"
                      size="16"
                    />
                  </div>

                  <p class="text-darkGrey font-normal text-sm">
                    {{ $t('Due date : ', { dueDate: target?.due_date }) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </v-list-group>
      </v-list>

      <!-- Target infos (description) -->
      <v-list
        v-model:opened="targetDescriptionOpened"
        class="!py-0"
      >
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="Opened"
        >
          <template #activator="{ props, isOpen }">
            <v-list-item
              v-bind="props"
              class="pl-0 border-b border-b-dark-grey flex gap-2"
            >
              <div class="flex gap-2 items-center justify-center">
                <Icon
                  :icon="isOpen ? 'ic:baseline-arrow-drop-down' : 'ic:baseline-arrow-right'"
                  width="16"
                  height="16"
                  class="text-fakeblack"
                />

                <p class="w-full text-fakeBlack font-medium text-sm">
                  {{ $t('Target description') }}
                </p>
              </div>
            </v-list-item>
          </template>

          <div class="flex flex-col gap-4">
            <v-divider
              thickness="1"
              color="negativeRed"
              class="border-opacity-100"
            />

            <v-textarea
              v-if="target?.description"
              v-model="target.description"
              class="text-dark-grey text-sm font-normal text-justify px-4"
              hide-details
              variant="plain"
              auto-grow
              readonly
            />

            <p v-else class="text-dark-grey font-medium text-sm px-4">
                {{ $t('No target description.') }}
              </p>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <!-- Show employees suggestions if they are present and the interview is submitted -->
    <v-list
      v-if="(question?.type === InterviewQuestionType.UPDATE_ROADMAP &&
        locked && target?.suggestions && target?.suggestions.length > 0) &&
        interviewKind === 'employee'"
      v-model:opened="suggestedListOpened"
      class="self-start w-full py-0 mt-4"
    >
      <v-list-group
        expand-icon=""
        collapse-icon=""
        :value="suggestedListOpened[0]"
      >
        <template #activator="{ props, isOpen }">
          <v-list-item
            v-bind="props"
            class="pl-0"
          >
            <div class="flex items-center self-start gap-2">
              <Icon
                :icon="isOpen ? 'ic:baseline-arrow-drop-down' : 'ic:baseline-arrow-right'"
                width="16"
                height="16"
                class="text-fakeblack"
              />

              <p class="w-full flex flex-row items-center text-fakeBlack font-medium text-sm">
                {{ $t('Suggested updates') }}

                  <span class="relative">
                    <svn-tooltip
                      location="bottom start"
                      origin="top end"
                    >
                      <template #activator-content>
                        <Icon
                          icon="ic:outline-info"
                          size="16"
                          color="darkGrey"
                        />
                      </template>

                    <template #tooltip-content>
                      {{ $t('These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.') }}
                    </template>
                  </svn-tooltip>
                </span>
              </p>
            </div>
          </v-list-item>
        </template>

        <div class="flex flex-col">
          <v-divider
            thickness="1"
            color="negativeRed"
            class="border-opacity-100 mb-4"
          />

          <!-- Show employee's suggestions if they are present -->
          <div
            class="w-full flex flex-col gap-4"
          >
            <interview-answer
              :user="getSuggessedData('employee')?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :answer="getSuggessedData('employee')?.proposal"
              :comments="getSuggessedData('employee')?.comment"
            />
          </div>
        </div>
      </v-list-group>
    </v-list>

    <!-- Show employee & manager suggestions if they are present and the interview is submitted -->
    <v-list
      v-else-if="(question?.type === InterviewQuestionType.UPDATE_ROADMAP &&
        target?.suggestions && target?.suggestions.length > 0) &&
        (interviewKind === 'manager' || interviewKind === 'crossed')"
      v-model:opened="suggestedListOpened"
      class="self-start w-full py-0"
    >
      <v-list-group
        expand-icon=""
        collapse-icon=""
        :value="suggestedListOpened[0]"
      >
        <template #activator="{ props, isOpen }">
          <v-list-item
            v-bind="props"
            class="pl-0"
          >
            <div class="flex items-center self-start gap-2">
              <Icon
                :icon="isOpen ? 'ic:baseline-arrow-drop-down' : 'ic:baseline-arrow-right'"
                width="16"
                height="16"
                class="text-fakeblack"
              />

              <p class="w-full flex flex-row items-center text-fakeBlack font-medium text-sm">
                {{ $t('Suggested updates') }}

                  <span class="relative">
                    <svn-tooltip
                      location="end top"
                      origin="bottom start"
                    >
                      <template #activator-content>
                        <Icon
                          icon="ic:outline-info"
                          size="16"
                          color="darkGrey"
                        />
                      </template>

                    <template #tooltip-content>
                      {{ $t('These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.') }}
                    </template>
                  </svn-tooltip>
                </span>
              </p>
            </div>
          </v-list-item>
        </template>

        <div class="flex flex-col">
          <v-divider
            thickness="1"
            color="negativeRed"
            class="border-opacity-100 mb-4"
          />

          <div
            class="w-full flex flex-col gap-4"
          >
            <!-- Show employee's suggestions if they are present -->
            <interview-answer
              v-if="getSuggessedData('employee')"
              :user="getSuggessedData('employee')?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :answer="getSuggessedData('employee')?.proposal"
              :comments="getSuggessedData('employee')?.comment"
            />

            <!-- Show manager's suggestions if they are present, manager view -->
            <interview-answer
              v-if="getSuggessedData('manager') && locked && !isLastInterviewCompletedAndLocked"
              :side="false"
              :user="getSuggessedData('manager')?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :answer="getSuggessedData('manager')?.proposal"
              :comments="getSuggessedData('manager')?.comment"
            />

            <!-- Show manager's suggestions if they are present, cross view -->
            <interview-answer
              v-if="getSuggessedData('manager') && interviewKind === 'crossed'"
              :side="false"
              :user="getSuggessedData('manager')?.user"
              :question-type="target?.objective_indicator?.indicator_type"
              :answer="getSuggessedData('manager')?.proposal"
              :comments="getSuggessedData('manager')?.comment"
            />
          </div>
        </div>
      </v-list-group>
    </v-list>

    <!-- Show inputs if the interview is submitted and it is the last of the set -->
    <div
      v-if="!isDraft && isLastInterviewCompletedAndLocked"
      class="w-full flex flex-col self-start gap-4"
    >
      <div class="flex flex-row items-center space-x-2">
        <p class="text-fakeBlack text-sm font-medium">
          {{ $t('Final update') }}
        </p>
      </div>

      <v-divider
        thickness="1"
        color="negativeRed"
        class="border-opacity-100"
      />
      {{ getSuggessedData('cross') }}
      <interview-answer
        v-if="getSuggessedData('manager')?.proposal || getSuggessedData('crossed')?.proposal"
        :user="null"
        :crossed="isLastInterviewCompletedAndLocked"
        :is-last-interview-completed-and-locked="isLastInterviewCompletedAndLocked"
        :question-type="InterviewQuestionType.MCQ"
        :answer="interviewKind === 'manager' ?
          getSuggessedData('manager')?.proposal : interviewKind === 'crossed' ? getSuggessedData('crossed')?.proposal : null"
        :comments="interviewKind === 'manager' ? getSuggessedData('manager')?.comment :
          interviewKind === 'crossed' ?
            getSuggessedData('crossed')?.comment : $t('Comment')"
      />
    </div>

    <!-- Input fields to update a target and add comments -->
    <div
      v-if="!locked && !isDraft"
      class="w-full flex flex-col self-start gap-4"
    >
      <div class="flex flex-row items-center space-x-2">
        <!-- Employee interview -->
        <p
          v-if="interviewKind === 'employee'"
          class="text-fakeBlack text-sm font-medium"
        >
          {{ $t('Your suggested update') }}
        </p>

        <!-- Manager Interview -->
        <p
          v-else-if="interviewKind === 'manager'"
          class="text-fakeBlack text-sm font-medium"
        >
          {{ isFinalUpdate ? $t('Final update') : $t('Suggested updates') }}
        </p>

        <!-- Cross Interview -->
        <p
          v-else
          class="text-fakeBlack text-sm font-medium"
        >
          {{ $t('Final update') }}
        </p>

        <svn-tooltip
          location="end top"
          origin="bottom start"
          activator-class="ml-2"
        >
          <template #activator-content>
            <Icon
              icon="ic:outline-info"
              color="darkGrey"
            />
          </template>

            <template #tooltip-content>
              <!-- Employee Interview -->
              <span
                v-if="interviewKind === 'employee'"
              >
                {{ $t('These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.') }}
              </span>

              <!-- Manager Interview -->
              <span
                v-else-if="interviewKind === 'manager'"
              >
                {{ isFinalUpdate ? $t('This answer will be applied to the target when you submit this interview.') :
                  $t('These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.') }}
              </span>

              <!-- Cross Interview -->
              <span
                v-else
              >
                {{ $t('This answer will be applied to the target when you submit this interview.') }}
              </span>
            </template>
          </svn-tooltip>
        </div>

      <v-divider
        thickness="1"
        color="negativeRed"
        class="border-opacity-100"
      />

      <v-select
        v-if="target?.objective_indicator?.indicator_type === 'multi_choice'"
        v-model="indicatorCurrentValue"
        :readonly="locked"
        variant="outlined"
        :items="getMCQOptions(target.objective_indicator.options.multi_choice_list)"
        hide-details
        density="compact"
        @update:model-value="updateSuggestion"
      >
        <template
          #selection="{item}"
        >
          <div class="w-full truncate">
            {{ item.title }}
          </div>
        </template>

        <template #label>
          <p>
            {{ $t('Choose an option') }}
          </p>
        </template>
      </v-select>

      <v-text-field
        v-if="target?.objective_indicator?.indicator_type === 'percentage' ||
          target?.objective_indicator?.indicator_type === 'numeric_value'"
        v-model="indicatorCurrentValue"
        :readonly="locked"
        :placeholder="isFinalUpdate ? $t('Final value') : $t('Suggested value')"
        hide-details
        variant="outlined"
        density="compact"
        type="number"
        @update:model-value="updateSuggestion"
      />

      <v-switch
        v-if="target?.objective_indicator?.indicator_type === 'boolean'"
        v-model="indicatorCurrentValue"
        :readonly="locked"
        :true-value="target?.objective_indicator?.options?.target_value"
        :false-value="target?.objective_indicator?.options?.starting_value"
        color="#5C95FF"
        :label="isFinalUpdate ? $t(`Final value :`, { value: indicatorCurrentValue }) :
          $t(`Suggested value :`, { value: indicatorCurrentValue })"
        hide-details
        density="comfortable"
        @update:model-value="updateSuggestion"
      />

      <v-textarea
        v-model="targetComments"
        :readonly="locked"
        hide-details
        variant="outlined"
        :label="$t('Comment')"
        @update:model-value="updateSuggestion"
      />
    </div>

    <!-- If someone has created a new target creation suggestion -->
    <div
      v-if="isDraft && !isLastInterviewCompletedAndLocked"
      class="flex flex-col w-full gap-4"
    >
      <div
        v-if="!locked"
        class="flex flex-row items-center"
      >
        <div>
          <Icon
            icon="ic:outline-info"
            size="16"
            color="darkGrey"
          />
        </div>

        <p class="text-darkGrey text-sm font-normal">
          {{ $t('This target is still a draft. Complete all interviews of the set to create it.') }}
        </p>
      </div>

      <div class="w-full flex flex-row space-x-2 ml-0">
        <v-dialog
          v-model="editDialog"
          width="700"
        >
          <template #activator="">
            <v-btn
              variant="outlined"
              :disabled="locked"
              class="w-[95%] normal-case flex flex-row justify-center items-center space-x-2 border !border-[#3177B7] border-opacity-100 px-3 py-2"
              @click="editDialog = true"
            >
              <div class="mr-1">
                <Icon
                  icon="fa6-solid:pencil"
                  size="16"
                  class="text-primary"
                />
              </div>

              <p class="text-primary text-xs font-medium">
                {{ $t('Edit target') }}
              </p>
            </v-btn>
          </template>

          <v-card>
            <target-new
              :target-id="target?.id"
              :overlay-mode="true"
              :users-from-overlay="[employee]"
              :is-editing-target="true"
              :create-draft="isDraft"
              @close="closePopUpAndReloadInterview"
            />
          </v-card>
        </v-dialog>

        <v-btn
          class="w-[5%] border border-negativeRed border-opacity-100"
          variant="outlined"
          :disabled="locked"
          @click="deleteTargetDraft(target?.id)"
        >
          <Icon
            icon="mdi:trash-can-outline"
            size="16"
            class="text-negativeRed"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import {storeToRefs} from "pinia";
import { Icon } from '@iconify/vue';
import InterviewAnswer from '../InterviewAnswer.vue';
import TargetNew from '@/views/roadmaps/Targets/New.vue';
import { useInterviewStore } from "@/store/interview.js";
import { useSnackbar } from '@/store/snackbar';
import RoadmapBlockTargetUsers from './RoadmapBlockTargetUsers.vue';
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';
import { useUserStore } from "@/store/user.js";
import axiosService from "@/tools/axios-service.js";
import { debounce } from 'lodash';
import { InterviewQuestionType } from '@/constants/types';

const props = defineProps({
  target: { type: Object, required: true },
  question: { type: Object, required: false },
  isDraft: { type: Boolean, default: false },
  employee: { type: Object, required: false },
})

const { id: currentUserId } = storeToRefs(useUserStore());
const { interviewKind, locked, id, isFinalUpdate, isLastInterviewCompletedAndLocked } = storeToRefs(useInterviewStore())

const snackbar = useSnackbar();
const { deleteTargetSuggestionCreated } = useTargetSuggestionsStore();

const targetInfoOpened = ref([]);
const targetDescriptionOpened = ref(['Opened']);
const suggestedListOpened = ref(['Suggested']);
const indicatorCurrentValue = ref(null);
const targetComments = ref(null);
const editDialog = ref(false);

const getMCQOptions = (mcq) => {
  if (mcq) {
    return mcq.map((option) => {
      return option.option
    })
  }
}

const emit = defineEmits(['changed'])

onMounted(async () => {

  if (!props?.target?.current_suggestion && interviewKind.value == 'crossed') {
    const previousSuggestion = props.target.suggestions.find((suggestion) =>
      suggestion.user.id === currentUserId.value &&
      suggestion.state === 'manager'
    );

    indicatorCurrentValue.value = previousSuggestion?.proposal;
    targetComments.value = previousSuggestion?.comment;
  } else {
    indicatorCurrentValue.value = props?.target?.current_suggestion?.proposal;
    targetComments.value = props?.target?.current_suggestion?.comment;
  }
});

const getMCQPossibleAnswers = (mcq) => {
  if (mcq) {
    return mcq.filter((option) => option.checkbox === true).length
  }
}

const updateSuggestion = debounce(async() => {
  try {
    await axiosService.patch(
      `/api/v1/roadmaps/indicators/${props?.target?.objective_indicator?.id}`,
      {
        current_value: indicatorCurrentValue.value,
        comment:  targetComments.value,
        interview_id: id.value
      }
    );
  } catch (error) {
    console.log(error)
    snackbar.setBgColor("negativeRed")
    snackbar.displaySnackBar("Could not update suggestion ! Please try again.");
  }
}, 200)

const getSuggessedData = (state) => {
  if (state === 'employee') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'employee')
  }
  else if (state === 'manager') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'manager')
  }
  else if (state === 'crossed') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'crossed')
  }
}

const deleteTargetDraft = async(targetDraftId) => {
  try {
    await deleteTargetSuggestionCreated(targetDraftId);
    emit('changed', true)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Your target has been deleted.')
    snackbar.displaySnackBar()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting your target !')
    snackbar.displaySnackBar()
  }
}

const closePopUpAndReloadInterview = (newTargetData) => {
  editDialog.value = false
  emit('changed', newTargetData)
}
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px !important;
}
</style>
