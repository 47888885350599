<template>
  <nav
    class="!h-[72px] flex justify-between sticky top-0 z-50 v-theme-background border-b border-b-light-grey pl-5 sm:!pl-0 pr-5 sm:pr-6"
  >
    <div class="flex items-center justify-center">
      <router-link
        class="hidden sm:flex items-center h-[72px] w-[72px]"
        to="/"
      >
        <div class="h-[72px] w-[72px] flex justify-center items-center bg-very-light-grey">
          <Icon
            icon="bxs:home"
            height="24"
            width="24"
            :class="route.name === 'home' ? 'text-fake-black' : 'text-middle-grey hover:text-fake-black'"
          />
        </div>
      </router-link>

      <svn-icon-button
        icon="mdi-menu"
        variant="hoverNoPrimary"
        color-class="!bg-transparent !text-fake-black hover:!bg-very-light-grey active:!bg-light-grey"
        class="text-fake-black sm:hidden"
        @click.stop="drawer = !drawer"
      />

      <div
        v-if="appTitle"
        class="hidden xl:flex items-center"
      >
        <div class="flex items-center px-5 min-w-fit h-[30px]" :class="links?.length ? 'border-r fakeBlack-border ' : ''">
          <img
            :src="appIcon"
            alt=""
            class="h-[22px]"
          >

          <p class="text-xl font-weight-500 router-link-active router-link-exact-active ml-1rem">
            {{ $t(appTitle) }}
          </p>
        </div>

        <div class="flex justify-start items-start px-1rem">
          <router-link
            v-for="(link, idx) in links"
            :key="idx"
            :to="{ name: link.linkName }"
            class="links rounded font-medium text-nowrap !text-lightGrey hover:bg-light-grey "
          >
            {{ $t(link.title) }}
          </router-link>
        </div>
      </div>

      <!-- Taille intermédiaire -->
      <div
        v-if="appTitle"
        class="xl:hidden xs:hidden sm:flex items-center"
      >
        <div class="flex items-center px-5 !min-w-fit">
          <img
            :src="appIcon"
            alt=""
            class="h-[22px]"
          >
        </div>

        <div class="text-center">
          <svn-dropdown :offset="22">
            <template #activator>
              <div
                class="flex items-center !min-w-fit cursor-pointer"
              >
                <p class="text-xl font-weight-500 router-link-active router-link-exact-active !mx-2.5">
                  {{ $t(currentPageName()) }}
                </p>

                <icon
                  v-if="links?.length"
                  icon="mdi-menu-down"
                  class="text-3xl font-bold"
                />
              </div>
            </template>

            <template #dropdown>
              <v-list class="!py-0 rounded-[8px] !shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)]">
                <v-list-item
                  v-for="(item, i) in currentAppPages"
                  :key="i"
                  :to="{
                    name: item.linkName,
                    params: item.linkName === 'people_show' ? { id: id } : null,
                    query: item.linkName === 'people_show' ? {page: 'interviews'} : null
                  }"
                  :value="item"
                  @click="mobilMenuDialog = false"
                >
                  <template #prepend>
                    <img
                      :src="item.icon"
                      alt=""
                      class="mr-4 h-4"
                    >
                  </template>

                  <v-list-item-title v-text="$t(item.title)" />
                </v-list-item>
              </v-list>
            </template>
          </svn-dropdown>
        </div>
      </div>
    </div>

    <app-and-user-menus
      :id="id"
      :firstname="firstname"
      :lastname="lastname"
      :email="email"
      :avatar="avatar"
      :access-level="accessLevel"
      :impersonate-display="impersonateDisplay"
      :display-impersonate="displayImpersonate"
      :logo="logo?.['150']"
      :name="name"
      :applications="applications"
      :applications-filtered="applicationsFiltered"
      v-bind="$attrs"
      @display-impersonate="displayImpersonate"
    />
  </nav>

  <div class="flex flex-col items-center">
    <slot />
  </div>

  <!-- Drawer -->
  <v-navigation-drawer
    v-model="drawer"
    temporary
    :width="290"
  >
    <div class="flex flex-col w-full h-full p-4">
      <!-- Logout button -->
      <svn-icon-button
        icon="mdi-close"
        variant="hoverNoPrimary"
        color-class="!bg-transparent !text-fake-black hover:!bg-very-light-grey active:!bg-light-grey"
        class="text-fake-black !self-end sm:hidden"
        @click="drawer = !drawer"
      />

      <div class="!p-0 flex flex-col gap-5">
        <!-- Menu List -->
        <v-list class="flex flex-col gap-4 p-0 pb-6">
          <div
            v-for="(item, i) in mobileMenuListFiltered"
            :key="i"
            :to="{ name: item.linkName, params: item.linkName === 'people_index' ? { id: id } : null }"
            :value="item"
            @click="mobilMenuDialog = false"
          >
            <div
              :class="item?.linkName === 'home' || item?.linkName === 'people_index' ? 'cursor-pointer hover:bg-[#F6F6F6]' : ''"
              @click="item?.linkName === 'home' || item?.linkName === 'people_index' ? router.push({ name: item?.linkName, params: item.linkName === 'people_index' ? { id: id } : null }) : ''"
            >
              <div class="flex p-2 gap-2">
                <Icon
                  :icon="item.icon"
                  width="24"
                  height="24"
                  :color="item?.color"
                />

                <v-list-item-title
                  :class="[item.title === 'Homepage' || item.title === 'People' ? 'text-fakeBlack' : 'text-darkGrey', 'text-base', 'font-normal' ]"
                  v-text="$t(item.title)"
                />
              </div>

              <v-list class="py-0">
                <div
                  v-for="(subitem, i) in item?.subItems"
                  :key="i"
                >
                  <v-list-item
                    v-if="deduceSubLinkDisplay(subitem)"
                    :to="{ name: subitem.linkName }"
                    :value="subitem"
                    class="py-0"
                    :active="false"
                    density="compact"
                    @click="mobilMenuDialog = false"
                  >
                    <v-list-item-title
                      :class="['text-fakeBlack', 'text-sm', 'font-medium', '!px-0']"
                      v-text="$t(subitem.title)"
                    />
                  </v-list-item>
                </div>
              </v-list>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import { Icon } from "@iconify/vue/dist/iconify.js";
import { useCompanyStore } from "@/store/company.js";
import { useUserStore } from "@/store/user.js";
import { storeToRefs } from "pinia";
import axiosService from "@/tools/axios-service.js";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppAndUserMenus from "@/components/header/AppAndUserMenus.vue";
import { useImpersonateStore } from "@/store/impersonate.js";
import interviewIcon from "@/assets/images/icons/interviews.svg"
import roadmapIcon from "@/assets/images/icons/roadmaps.svg"
import trainingIcon from "@/assets/images/icons/trainings.svg"
import homeIcon from "@/assets/images/icons/home.svg"
import companyIcon from "@/assets/images/icons/company.svg"
import peopleIcon from "@/assets/images/icons/people.svg"

const { isAdminOrAbove, isManagerCreatorOrAbove, isManagerOrAbove, isManagerCreatorOrBelow } = useUserStore();
const { logo, applications, name } = storeToRefs(useCompanyStore());

const { id, firstname, lastname, email, avatar, accessLevel } = storeToRefs(
  useUserStore()
);

const { impersonateDisplay } = storeToRefs(useImpersonateStore());
const { displayImpersonate } = useImpersonateStore();

const route = useRoute();
const router = useRouter();

const drawer = ref(false)

const props = defineProps({
  appIcon: { type: String, default: null },
  appTitle: { type: String, default: null },
  links: { type: Array, default: () => [] },
});

const mobilMenuDialog = ref(false);

const mobileMenuList = [
  {
    title: "Homepage",
    linkName: "home",
    icon: "ph:house",
    color: "#333333",
  },
  {
    title: "People",
    linkName: "people_index",
    icon: "mingcute:group-3-line",
    color: "#333333",
  },
  {
    title: "Interviews",
    linkName: "my_interviews",
    icon: "mingcute:chat-3-line",
    color: "#3177B7",
    subItems: [
        {
        title: "My interviews",
        linkName: "my_interviews",
        icon: interviewIcon,
      },
      {
        title: "My Team Interviews",
        linkName: "my_team_interviews",
        icon: interviewIcon,
      },
      {
        title: "Campaigns",
        linkName: "campaigns",
        icon: interviewIcon,
      },
      {
        title: "Templates",
        linkName: "interview_forms",
        icon: interviewIcon,
      },
      {
        title: "Reports",
        linkName: "reports",
        icon: interviewIcon,
      },
    ]
  },
  {
    title: "Learn",
    linkName: "my_learnings",
    icon: "mingcute:mortarboard-line",
    color: "#EF8C64",
    subItems: [
        {
          title: "My Learnings",
          linkName: "my_learnings",
          icon: trainingIcon,
      },
      {
        title: "Catalog",
        linkName: "catalog",
        icon: interviewIcon,
      },
      {
        title: "Trainings",
        linkName: "trainings",
        icon: interviewIcon,
      }
    ]
  },
  {
    title: "Roadmap",
    linkName: "my_roadmaps",
    icon: "mingcute:target-line",
    color: "#5C95FF",
    subItems: [
      {
        title: "My Roadmap",
        linkName: "my_roadmaps",
        icon: roadmapIcon,
      },
      {
        title: "My Team Roadmaps",
        linkName: "my_team_roadmaps",
        icon: roadmapIcon,
      },
      {
        title: "Targets",
        linkName: "roadmaps_targets",
        icon: roadmapIcon,
      },
      {
        title: "All Roadmaps",
        linkName: "roadmaps_employees",
        icon: roadmapIcon,
      },
      {
        title: "Templates",
        linkName: "roadmaps_templates",
        icon: roadmapIcon,
      },
    ]
  }
];

const middleScreenMenuList = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    },
    {
      title: "My Team Interviews",
      linkName: "my_team_interviews",
      icon: interviewIcon,
    },
    {
      title: "Campaigns",
      linkName: "campaigns",
      icon: interviewIcon,
    },
    {
      title: "Templates",
      linkName: "interview_forms",
      icon: interviewIcon,
    },
    {
      title: "Reports",
      linkName: "reports",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My Learnings",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    },
    {
      title: "Trainings",
      linkName: "trainings",
      icon: trainingIcon,
    },
  ],
  Roadmap: [
    {
      title: "My roadmaps",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "My team roadmaps",
      linkName: "my_team_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "Targets",
      linkName: "roadmaps_targets",
      icon: roadmapIcon,
    },
    {
      title: "People targets",
      linkName: "roadmaps_employees",
      icon: roadmapIcon,
    },
    {
      title: "Target templates",
      linkName: "roadmaps_templates",
      icon: roadmapIcon,
    }
  ],
  Company: [
    {
      title: "Company",
      linkName: "company",
      icon: companyIcon,
    }
  ],
  People: [
    {
      title: "People",
      linkName: "people_index",
      icon: peopleIcon,
    },
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

const middleScreenMenuListForManager = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    },
    {
      title: "My Team Interviews",
      linkName: "my_team_interviews",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My learn - coming soon",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    }
  ],
  Roadmap: [
    {
      title: "My roadmaps",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    },
    {
      title: "My team roadmaps",
      linkName: "my_team_roadmaps",
      icon: roadmapIcon,
    },
  ],
  People: [
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

const middleScreenMenuListForEmployee = {
  Home: [
    {
      title: "Home",
      linkName: "home",
      icon: homeIcon,
    },
  ],
  Interview: [
    {
      title: "My interviews",
      linkName: "my_interviews",
      icon: interviewIcon,
    }
  ],
  Learn: [
    {
      title: "My learn - coming soon",
      linkName: "my_learnings",
      icon: trainingIcon,
    },
    {
      title: "Catalog",
      linkName: "catalog",
      icon: trainingIcon,
    }
  ],
  Roadmap: [
    {
      title: "My roadmaps",
      linkName: "my_roadmaps",
      icon: roadmapIcon,
    }
  ],
  People: [
    {
      title: "Current",
      linkName: "people_show",
      icon: peopleIcon,
    }
  ]
}

let appsConfigs = [
  {
    backendNameToMatch: "interviews",
    appName: "Interviews",
    linkName: "my_interviews",
    icon: 'mingcute:chat-3-line',
    iconColor: '#3177B7',
  },
  {
    backendNameToMatch: "trainings",
    appName: "Learn",
    linkName: "my_learnings",
    icon: 'mingcute:mortarboard-line',
    iconColor: '#EF8C64',
    beta: true,
  },
  {
    backendNameToMatch: "objectives",
    appName: "Roadmaps",
    linkName: "my_roadmaps",
    icon: 'mingcute:target-line',
    iconColor: '#5C95FF',
  },
  {
    backendNameToMatch: null,
    appName: "People",
    linkName: "people_index",
    icon: 'mingcute:group-3-line',
    iconColor: '#333333'
  }
];

const applicationsFiltered = computed(() => {
  appsConfigs = appsConfigs.filter(
    (app) =>
      applications.value.includes(app.backendNameToMatch) ||
      (app.appName === "People" && isAdminOrAbove())
  );

  return appsConfigs
});

const mobileMenuListFiltered = computed(() => {
  return mobileMenuList.filter(item => !(item.linkName === 'people_index' && isManagerCreatorOrBelow()))
})

const currentPageName = () => {
  const routeName = currentAppPages?.value?.find((link) => link.linkName === router.currentRoute?._value?.name)
  return typeof routeName == 'undefined' ?  props.appTitle : routeName.title
}

const currentAppPages = computed(() => {
  return filterCurrentAppPages.value[props.appTitle]
});

const filterCurrentAppPages = computed(() => {
  return isManagerCreatorOrAbove() ? middleScreenMenuList : (isManagerOrAbove() ?  middleScreenMenuListForManager : middleScreenMenuListForEmployee)
});

const deduceSubLinkDisplay = (item) => {
  const employeePages = [
    'my_interviews',
    'my_learnings',
    'catalog',
    'my_roadmaps'
  ]
  const managerPages = [
    'my_team_interviews',
    'my_team_roadmaps'
  ]
  const managerCreatorPage = [
    'trainings',
    'campaigns',
    'interview_forms',
    'reports',
    'roadmaps_targets',
    'roadmaps_employees',
    'roadmaps_templates'
  ]

  if (employeePages.includes(item.linkName))
    return true
  else if (managerPages.includes(item.linkName) &&
      isManagerOrAbove())
    return true
  else if (managerCreatorPage.includes(item.linkName) && isManagerCreatorOrAbove())
    return true
  else return false
}
</script>

<style lang="scss" scoped>
.v-list-group__items {
  display: block !important;
}

nav {
  height: 4.8rem;
}

.router-link-active {
  font-weight: 700;
}

.links {
  font-size: 0.9rem;
  padding: 10px;
}

.fakeBlack-border {
  border-color: rgb(var(--v-theme-fakeBlack));
}
.v-list-item.v-theme--interview.v-list-item--density-default.v-list-item--one-line.v-list-item--variant-text {
  display: flex;
  align-items: flex-start;
}
</style>
