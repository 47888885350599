<template>
  <div
    class="w-full flex flex-col-reverse gap-2 pt-6 items-start
    md:flex-row md:justify-end md:items-center md:gap-0 md:!py-0"
  >
    <div class="w-full md:w-fit">
      <v-text-field
        v-model="searchText"
        color="primary"
        class="w-full text-darkGrey md:w-[258px]"
        variant="outlined"
        density="compact"
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
      />
    </div>
  </div>

  <!-- Contenu des onglets -->
  <sub-tab-in-targets
    v-if="pagination"
    :headers="learningsHeadersTrainings"
    :data-sorted="roadmapUserTargets"
    :is-searching="isSearching"
    :user="user"
    :search-text="searchText"
    class="w-full"
  />

  <!-- Floating Button ton top -->
  <svn-floating-action-button
    square
    to-top
  />
</template>

<script setup>
import {onMounted, ref, watch, computed, defineProps} from "vue";
import {storeToRefs} from "pinia";
import SubTabInTargets from "@/components/BktSubTabTargetsInProfile.vue"
import {debounce} from "lodash";

import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
const { roadmapUserTargets, pagination, loading } = storeToRefs(useRoadmapTargetStore());
const { fetchRoadmapTargetsByUserId } = useRoadmapTargetStore();

const props = defineProps({
  user: { type: Object, required: true }
});


const learningsHeadersTrainings = computed(() => {
  return [
    { title: "Targets",
      key: "targets",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "Role",
      key: "role",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
})

const searchText = ref('')
const isSearching = ref(false)



// Remplacer la valeur de isSearching par le true/false resultant de :
// Est-ce que la nouvelle valeur de searchText est différente de vide (= recherche en cours) ?
watch(searchText, (newValue) => {
      isSearching.value = newValue !== ''
      getTargets()
})

onMounted(async () => {
  await getTargets();
});

const getTargets = debounce(
  async() => {
    try {
      await fetchRoadmapTargetsByUserId(props.user.id, searchText.value)

    } catch (error) {
      console.log(error);
    }
  }, 200
)
</script>

<style scoped>

:deep(.v-input__control) {
  color: #333333;
  font-weight: 500;
}

.floating-button {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

</style>
