<template>
  <div
    :id="question.id"
    class="py-6"
  >
    <div class="text-3xl break-words w-full font-medium">
      <span
        class="first-letter:uppercase"
      >
        {{ question.text }}
      </span>
    </div>

    <quill-editor
      v-model:content="question.description"
      content-type="html"
      :placeholder="' '"
      :enable="true"
      class="!cursor-default break-words w-full !text-darkGrey !text-xl leading-8 border-b-0 description"
    />

    <div>
      <div
        v-if="interviewKind === 'crossed'"
        class="mb-6 text-2xl"
      >
        <div
          v-if="findEmployeeQuestion(question.id)"
          class="mb-6"
        >
          <div>
            {{ `${employee.lastname} ${employee.firstname}` }} {{ $t('Interviewee') }} {{ $t("answer") }} :
          </div>
          <v-rating
            :length="objectFlip(question.options)[1]"
            :model-value="Number(findEmployeeQuestion(question.id)?.answer)"
            color="darkGrey"
            readonly
            class="my-2"
          >
            <template #item="iconProps">
              <v-icon
                :color="'darkGrey'"
                class="mr-4"
                @click="iconProps.onClick"
              >
                {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
              </v-icon>
            </template>
          </v-rating>
          <quill-editor
            v-if="findEmployeeQuestion(question.id)?.comments"
            v-model:content="findEmployeeQuestion(question.id).comments"
            content-type="html"
            :placeholder="' '"
            :enable="true"
            class="!cursor-default break-words w-full !text-darkGrey !text-xl leading-8 border-b-0 description"
          />
        </div>
        <div
          v-if="findManagerQuestion(question.id)"
          class="mb-6"
        >
          <div>
            {{ `${interviewer.lastname} ${interviewer.firstname}` }} {{ $t('Interviewer') }} answers :
          </div>
          <div>
            <v-rating
              :length="objectFlip(question.options)[1]"
              :model-value="Number(findManagerQuestion(question.id)?.answer)"
              color="darkGrey"
              readonly
              class="my-2"
            >
              <template #item="iconProps">
                <v-icon
                  :color="'darkGrey'"
                  class="mr-4"
                  @click="iconProps.onClick"
                >
                  {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
              </template>
            </v-rating>
            <quill-editor
              v-if="findManagerQuestion(question.id)?.comments"
              v-model:content="findManagerQuestion(question.id).comments"
              content-type="html"
              :placeholder="' '"
              :enable="true"
              class="!cursor-default break-words w-full !text-darkGrey !text-xl leading-8 border-b-0 description"
            />
          </div>
        </div>
      </div>
      <div
        class="text-2xl mb-6"
      >
        <div
          v-if="interviewKind === 'crossed'"
          class="font-medium"
        >
          {{ $t("Cross review") }} {{ $t("answer") }} :
        </div>
        <v-rating
          :length="objectFlip(question.options)[1]"
          :model-value="
            findInterviewAnswer ? Number(findInterviewAnswer.answer) : null
          "
          color="darkGrey"
          readonly
          class="my-2"
        >
          <template #item="iconProps">
            <v-icon
              :color="'fakeBlack'"
              class="mr-4"
              @click="iconProps.onClick"
            >
              {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
            </v-icon>
          </template>
        </v-rating>

        <quill-editor
          v-if="question.allow_comments"
          v-model:content="findInterviewAnswer.comments"
          content-type="html"
          :placeholder="' '"
          :enable="true"
          class="!cursor-default break-words w-full !text-darkGrey !text-xl leading-8 border-b-0 description"
        />
      </div>
      <div
        v-if="findInterviewAnswer && findInterviewAnswer?.messages?.length && campaign?.campaign_type === 'Survey'"
        class="text-2xl"
      >
        <div
          class="font-medium mb-6"
        >
          {{ $t("Comments") }} :
        </div>
        <div
          v-for="message in findInterviewAnswer.messages"
          :key="message.id"
          class="mb-8"
        >
          <div
            class="font-medium"
          >
            {{ message.user.fullname }} <span class="text-darkGrey font-normal">{{ $filters.formatDateHoursWithoutSeconds(new Date(message.created_at.split(" UTC")[0])) }}</span>
          </div>
          <div
            class="break-words w-full border-l border-opacity-100 border-darkGrey pl-3.5"
          >
            <quill-editor
              v-model:content="message.text"
              content-type="html"
              :placeholder="' '"
              :enable="true"
              class="!cursor-default break-words w-full !text-darkGrey !text-xl leading-8 border-b-0 description"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {useInterviewStore} from "@/store/interview.js";
import useTools from "@/tools/useTools.js";
import {computed} from "vue";
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";
import {useCampaignStore} from "@/store/campaign.js";

const {
  interviewKind,
  campaign,
  interviewAnswers,
  locked,
  employee,
  interviewer,
  employeeAnswers,
  managerAnswers,
} = storeToRefs(useInterviewStore());
const { objectFlip } = useTools();

const props = defineProps(["question", "loopIdx"]);

const findInterviewAnswer = computed(() => {
  return (
    interviewAnswers.value?.find(
      (answer) => answer.question_id === props.question.id
    ) || {}
  );
});

const findEmployeeQuestion = (questionId) => {
  return employeeAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};
const findManagerQuestion = (questionId) => {
  return managerAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const setCrossDefaultAnswer = () => {
  let managerAnswers = findManagerQuestion(props.question.id);
  let employeeAnswers = findEmployeeQuestion(props.question.id);

  if (!findInterviewAnswer.value.answer) {
    findInterviewAnswer.value.answer = managerAnswers
      ? managerAnswers.answer
      : employeeAnswers
        ? employeeAnswers.answer
        : null;
  }
};

if (interviewKind.value === "crossed") {
  setCrossDefaultAnswer();
}
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}
</style>