<template>
  <!-- TemplatesSingle -->
  <svn-autocomplete
    v-model="campaignDraft.default_template_id"
    :label="$t('Select a default template')"
    :items="interviewForms"
    item-title="title"
    :loading="loading"
    :class="[classCustom]"
    color="primary"
    prepend-inner-icon=""
  >
    <template #main-content>
      {{ null }}
    </template>

    <template #append-content>
      {{ null }}
    </template>
  </svn-autocomplete>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";

const { fetchInterviewForms } = useCompanyStore();

const { interviewForms } = storeToRefs(useCompanyStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const props = defineProps({
  classCustom: {type: String, default: '!min-w-full'},
})

const loading = ref(true);

onMounted(async () => {
  if (!interviewForms.value.length) {
    let formType = ''
    if (campaignDraft.value.campaign_type == 'Survey') {
      formType = 'InterviewApp::Forms::Templates::Survey'
    } else if (campaignDraft.value.campaign_type == 'Feedback') {
      formType = 'InterviewApp::Forms::Templates::Feedback'
    } else {
      formType = 'InterviewApp::Forms::Templates::OneToOne'
    }
    await fetchInterviewForms(formType);
    loading.value = false;
  } else {
    loading.value = false;
  }
});
</script>
