<template>
  <v-card-text class="h-[500px] overflow-x-hidden overflow-y-hidden flex flex-col gap-4 p-0">
    <v-text-field
      v-model="searchUserList"
      variant="outlined"
      :label="$t('Search for participants')"
      prepend-inner-icon="mdi-magnify"
      color="primary"
      hide-details
      single-line
      base-color="middleGrey"
    />

    <div class="font-medium">
      {{ $t("Selected participants : ") }}
      {{ campaignDraft.interviewee_ids.length }}
    </div>

    <div class="w-full">
      <v-data-table
        v-model="campaignDraft.interviewee_ids"
        :headers="headers"
        :items="formattedUsers"
        :loading="usersLoading"
        :no-data-text="$t('Users are loading...')"
        :search="searchUserList"
        :items-per-page="-1"
        item-value="id"
        class="my-6"
        color="primary"
        height="400"
        show-select
      >
        <template #loading>
          <div class="w-full flex items-center h-full mt-32">
            <svn-loader loading-size="lg" class="mx-auto" />
          </div>
        </template>

        <template #no-data>
          <p class="w-full text-center">
            No user found
          </p>
        </template>

        <template #item="{ item }">
          <tr>
            <td>
              <v-checkbox
                v-model="campaignDraft.interviewee_ids"
                :value="item.value"
                hide-details
              />
            </td>

            <td>{{ item.raw?.fullname }}</td>

            <td
              v-for="tagCat in tagCategories"
              :key="tagCat.id"
            >
              {{ item.raw[tagCat.name] }}
            </td>
          </tr>
        </template>
        <template #bottom />
      </v-data-table>
    </div>
  </v-card-text>
</template>

<script setup>
import { storeToRefs } from "pinia";
import {useCompanyStore } from "@/store/company.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import { ref, onMounted } from "vue";

const { campaignDraft } = storeToRefs(useCampaignDraftStore());
const { users, managers, tagCategories } = storeToRefs(useCompanyStore());
const { fetchCompany, fetchCompanyUsers, fetchCompanyManagers } = useCompanyStore();

const searchUserList = ref("");
const headers = ref([]);
const formattedUsers = ref([]);
const usersLoading = ref(true);
// const loadingManagerList = ref(true);

onMounted(async() => {
  const headersTmp = [
    {
      align: "start",
      key: "fullname",
      sortable: false,
      title: "Name",
      width: 200,
    },
  ];

  if (!users.value.length) await fetchCompanyUsers()

  const formattedTagCat = tagCategories.value.map((obj) => {
    return {
      key: obj.name,
      sortable: true,
      title: obj.name,
      width: 200,
    };
  });
  headers.value = headersTmp.concat(formattedTagCat);

  formattedUsers.value = users.value.map((obj) => {
    const toRet = {
      fullname: obj.fullname,
      id: obj.id
    };
    tagCategories.value.map((tagCat) => {
      const found = obj.tags.find(
        (tag) => tag.tag_category.id == tagCat.id
      );

      if (found) toRet[tagCat.name] = found.tag_name;
      else toRet[tagCat.name] = "";
    });

    return toRet;
  });
  usersLoading.value = false;

  // if (!managers.value.length) {
  //   fetchCompanyManagers().then(() => {
  //     loadingManagerList.value = false;
  //   });
  // } else {
  //   loadingManagerList.value = false;
  // }

  // fetchCompany();
})

</script>
