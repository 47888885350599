<template>
  <div
    class="flex flex-col items-center justify-center self-stretch h-[324px]
    rounded-[12px] border border-darkGrey overflow-hidden
    hover:shadow-[0_1px_5px_rgba(0,0,0,0.20)]"
  >
    <!-- Module background image -->
    <div
      class="flex flex-col items-end h-1/2 bg-cover w-full relative"
      :style="{'background-image': `url('${backgroundImage}')`} "
    >
      <div
        class="flex items-center self-stretch pt-4 px-4"
        :class="getTotalReactionsCount() > 0 ? 'justify-between' : 'justify-end'"
      >
        <!-- Card left buttons (Reactions) -->
        <div
          v-if="getTotalReactionsCount() > 0"
          class="flex items-center gap-1 py-1 px-[6px] bg-white/[.5] rounded-l-[20px] rounded-r-[20px]"
        >
          <div
            v-for="(reaction, index) in reactions"
            :key="index"
            :class="reaction?.count > 0 ? 'block' : 'hidden'"
          >
            <Icon
              v-if="reaction?.count > 0"
              :icon="getReactionsIcons(reaction)"
              height="16"
              width="16"
              :class="isFavorite ? 'text-negativeRed' : 'text-fakeBlack'"
            />
          </div>

          <p class="text-fakeBlack text-sm font-medium">
            {{ getTotalReactionsCount() }}
          </p>
        </div>

        <!-- Card right buttons -->
        <div
          class="flex items-center gap-x-2 p-1"
        >
          <!-- Favorite button -->
          <svn-icon-button
            :icon="isFavorite ? 'mdi:cards-heart' : 'mdi:cards-heart-outline'"
            variant="noOutlined"
            circle
            button-size="lg"
            color-class="!bg-white"
            :class="isFavorite ? 'text-negativeRed' : 'text-fakeBlack'"
            @click="toggleFavorite"
          />

          <!-- Copy link button -->
          <svn-icon-button
            icon="mdi:link-variant"
            variant="noOutlined"
            circle
            button-size="lg"
            color-class="!bg-white"
            class="text-fakeBlack"
            @click="copyModuleLink"
          />


          <!-- Options button -->
          <div
            v-if="!toDiscover && amICreatorOfEntityOrAdmin(moduleCreatorId)"
            class="flex flex-col justify-center items-center w-10 h-10 p-1
              bg-white hover:bg-[#FFFFFF] rounded-full overflow-hidden"
          >
            <svn-dots-button
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  value="duplicate"
                  @click="duplicateModule"
                >
                  {{ $t('Duplicate module') }}
                </v-list-item>

                <v-list-item
                  value="add_module_to_playlist"
                  @click="addModuleToPlaylist"
                >
                  {{ $t('Add module to playlist') }}
                </v-list-item>

                <v-list-item
                  value="delete_module"
                  class="text-negativeRed"
                  @click="deleteModule"
                >
                  {{ $t('Delete module') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </div>
      </div>

      <div
        class="h-full w-full cursor-pointer"
        @click="goToModuleShow"
      />
    </div>

    <!-- Module Info -->
    <div
      class="w-full flex flex-col items-start justify-between h-1/2 p-4 cursor-pointer"
      @click="goToModuleShow"
    >
      <div class="flex flex-col items-start gap-2">
        <!-- Module title -->
        <p class="text-fakeBlack text-sm font-medium line-clamp-1">
          {{ title }}
        </p>

        <!-- If there is 1 or less themes -->
        <div
          v-if="themeList?.length !== 0 && themeList?.length === 1"
          class="flex items-center gap-x-2"
        >
          <div
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              {{ themeList?.[0] }}
            </p>
          </div>
        </div>

        <!-- If there is more than 1 themee -->
        <div
          v-else-if="themeList?.length !== 0 && themeList?.length > 1"
          class="flex items-center gap-x-2"
        >
          <div
            v-for="(theme) in slicedThemesList"
            :key="theme"
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              {{ theme }}
            </p>
          </div>

          <div
            v-if="(themeList?.length - slicedThemesList?.length) > 0"
            class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
          >
            <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
              +{{ themeList?.length - slicedThemesList?.length }}
            </p>
          </div>
        </div>

        <!-- if there are no themes -->
        <div
          v-else
          class="flex justify-center items-center rounded-[8px] bg-trainingOrangeSoft"
        >
          <p class="flex text-xs font-normal px-2 py-[6px] text-fakeBlack items-center">
            {{ $t('No theme') }}
          </p>
        </div>
      </div>

      <!-- Module updatedAt & duration -->
      <div class="flex items-center w-full justify-between">
        <div class="flex flex-col items-start">
          <div
            class="flex items-start gap-x-2"
            :class="submissionStatus === 'acquired' ? 'h-fit' : 'h-[40px]'"
          >
            <!-- Module duration -->
            <div class="flex items-center gap-x-1">
              <Icon
                icon="mdi:clock-outline"
                height="16"
                width="16"
                class="text-darkGrey"
              />

              <p class="text-darkGrey text-sm font-medium">
                {{ duration || $t('No duration') }}
              </p>
            </div>

            <!-- Module updatedAt -->
            <div class="flex items-center gap-x-1">
              <Icon
                icon="mdi:update"
                height="16"
                width="16"
                class="text-darkGrey"
              />

              <p class="text-darkGrey text-sm font-medium">
                {{ updatedAt }}
              </p>
            </div>
          </div>

          <!-- Module acquired -->
          <div
            v-if="submissionStatus === 'acquired'"
            class="flex items-center gap-1"
          >
            <Icon
              icon="gg:check-o"
              height="16"
              width="16"
              class="text-positiveGreen"
            />

            <p class="text-positiveGreen text-sm font-medium">
              {{ $t('Acquired on') }} {{ filters.formatDate(submissionUpdatedAt, 'DD/MM/YYYY') }}
            </p>
          </div>
        </div>

        <!-- Module button arrow right -->
        <div class="cursor-pointer">
          <Icon
            icon="mdi:arrow-right"
            height="24"
            width="24"
            class="text-trainingOrange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user.js";
import { computed } from "vue";
import { useRouter } from "vue-router";
import filters from "@/tools/filters";

const { isMobile } = storeToRefs(useMobileStore());

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  id: { type: Number, default: null },
  moduleCreatorId: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  toDiscover: { type: Boolean, default: false },
  isDraft: { type: Boolean, default: false },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  reactions: { type: Object, default: null },
  duration: { type: String, default: '' },
  updatedAt: { type: String, default: '' },
  submissionStatus: { type: String, default: '' },
  submissionUpdatedAt: { type: String, default: '' },
})

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props?.themeList?.slice(0, 1)
  }
  else {
    return props?.themeList?.slice(0, 2)
  }
})

const router = useRouter()

const emit = defineEmits(['duplicate-module', 'toggle-favorite', 'add-to-playlist', 'delete-module', 'module-show'])

const snackbar = useSnackbar()

const copyModuleLink = () => {
  const link = router.resolve({  name: 'module_show', params: { id: props?.id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('positiveGreen')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.setMsg('Link copied to clipboard !')
    snackbar.displaySnackBar()
  }).catch(() => {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : '')
    snackbar.displaySnackBar('Error copying link to clipboard')
  })
}

const goToModuleShow = () => {
  emit('module-show')
}

const toggleFavorite = () => {
  emit('toggle-favorite')
}

const duplicateModule = () => {
  emit('duplicate-module')
}

const addModuleToPlaylist = () => {
  emit('add-to-playlist')
}

const getReactionsIcons = (reaction) => {
  if (reaction?.type === 'like') {
    return 'noto:thumbs-up'
  }
  if (reaction?.type === 'recommend') {
    return 'noto:star-struck'
  }
  if (reaction?.type === 'question') {
    return 'noto:face-with-raised-eyebrow'
  }
  if (reaction?.type === 'remark') {
    return 'noto:index-pointing-up'
  }
}

const getTotalReactionsCount = () => {
  let totalReactions = 0;
  props?.reactions?.forEach(reaction => {
    totalReactions += reaction?.count
  })
  return totalReactions
}

const deleteModule = () => {
  emit('delete-module')
}
</script>

<style scoped>
</style>
