<template>
  <div
    class="w-full flex flex-col-reverse gap-2 pt-6 items-start
      md:flex-row md:justify-between md:items-center md:!py-0 md:gap-0"
  >
    <div class="text-fake-black">
      <v-tabs
        v-model="page"
        :grow="isMobile"
        class="text-darkGrey"
        color="fakeBlack"
      >
        <!-- VERSION MOBILE -->
        <div class="flex sm:hidden grow items-center w-full justify-start z-20 bkt-bg-white border-solid border-lightGrey border-b-[1px]">
          <v-select
            v-model="page"
            :items="tabs"
            item-title="text"
            item-value="value"
            color="blue"
            bg-color="transparent"
            class="w-full text-black"
            hide-details
          />
        </div>

        <!-- VERSION DESKTOP -->
        <div class="items-center w-fit justify-start sticky top-[75px] z-20 bkt-bg-white border-solid border-lightGrey border-b-2 hidden sm:flex">
          <v-tab
            v-for="tab in tabs"
            :key="tab"
            :value="tab.value"
          >
            <div class="flex w-fit max-w-[202px] items-center py-4 pb-3">
              <p class="!normal-case truncate">
                {{ tab.text }}
              </p>
            </div>
          </v-tab>
        </div>
      </v-tabs>
    </div>

    <div class="w-full md:w-fit">
      <v-text-field
        v-model="searchText"
        color="primary"
        class="w-full text-darkGrey md:w-[258px]"
        variant="outlined"
        density="compact"
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('Search')"
        @input="search"
      />
    </div>
  </div>

  <!-- Contenu des onglets -->
  <v-window v-model="page" class="!w-full">
    <v-window-item value="as_participant">
      <sub-tabs-in-interviews
        :headers="interviewsHeadersParticipant"
        :interview-data="interviewDataAsparticipant"
        :pagination-data="paginationAsParticipant"
        :page="page"
        :user="user"
        :search-text="searchText"
        @refetch-interviews="refetchInterviews"
        @fetch-additional-interviews="fetchAdditionalInterviews"
      />
    </v-window-item>

    <v-window-item value="as_reviewer">
      <sub-tabs-in-interviews
        :headers="interviewsHeadersReviewer"
        :interview-data="interviewDataAsReviewer"
        :pagination-data="paginationAsReviewer"
        :page="page"
        :user="user"
        :search-text="searchText"
        @refetch-interviews="refetchInterviews"
        @fetch-additional-interviews="fetchAdditionalInterviews"
      />
    </v-window-item>

    <v-window-item value="as_person_in_charge">
      <sub-tabs-in-interviews
        :headers="interviewsHeadersPersonInCharge"
        :interview-data="interviewDataAsPersonInCharge"
        :pagination-data="paginationAsPersonInCharge"
        :page="page"
        :user="user"
        :search-text="searchText"
        @refetch-interviews="refetchInterviews"
        @fetch-additional-interviews="fetchAdditionalInterviews"
      />
    </v-window-item>
  </v-window>

  <!-- Floating Button ton top -->
  <svn-floating-action-button
    square
    to-top
  />
 </template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import i18n from '@/plugins/i18n';
import { useMobileStore } from "@/store/mobile";
import { storeToRefs} from "pinia";
import SubTabsInInterviews from "@/components/BktSubTabsInterviewsInProfile.vue"
import { debounce} from "lodash";
import { useInterviewStore } from "@/store/interview.js";

const { pagination } = storeToRefs(useInterviewStore());
const { getInterviewsByUser, fetchAdditionalInterviewsByUserId } = useInterviewStore();
const { isMobile } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();

const page = ref('as_participant');
const interviewDataAsparticipant = ref([]);
const paginationAsParticipant = ref({});
const interviewDataAsReviewer = ref([]);
const paginationAsReviewer = ref({});
const interviewDataAsPersonInCharge = ref([]);
const paginationAsPersonInCharge = ref({});

onMounted(async () => {
  try {
    if (pagination.value) {
      pagination.value.current_page = 1
    }
    interviewDataAsparticipant.value = await getInterviewsByUser(props.user.id, 'as_participant', searchText?.value, 1);
    paginationAsParticipant.value = pagination.value
    interviewDataAsReviewer.value = await getInterviewsByUser(props.user.id, 'as_reviewer', searchText?.value, 1);
    paginationAsReviewer.value = pagination.value
    interviewDataAsPersonInCharge.value = await getInterviewsByUser(props.user.id, 'as_person_in_charge', searchText?.value, 1);
    paginationAsPersonInCharge.value = pagination.value
  } catch (error) {
    console.log(error)
  }
})

const props = defineProps({
  user: { type: Object, required: true }
});

const tabs = [
  {
    value: "as_participant",
    text: i18n.global.t("As participant")
  },
  {
    value: "as_reviewer",
    text: i18n.global.t("As reviewer")
  },
  {
    value: "as_person_in_charge",
    text: i18n.global.t("As person in charge")
  }
]

const interviewsHeadersParticipant = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "Person in charge",
      key: "person",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
})

const interviewsHeadersReviewer = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "Review of",
      key: "person",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
})

const interviewsHeadersPersonInCharge = computed(() => {
  return [
    { title: "Campaign",
      key: "campaign",
      icon: true,
      align: 'start',
      sortable: true
    },
    { title: "In charge of",
      key: "person",
      icon: false,
      align: 'start',
      sortable: true
    },
    { title: "Completion",
      key: "completion",
      icon: false,
      align: 'start',
      sortable: true
    }
  ]
})

const refetchInterviews = async ({ userId, as, text, page }) => {
  switch (as) {
    case "as_participant":
      interviewDataAsparticipant.value = await getInterviewsByUser(userId, 'as_participant', text, page);
      paginationAsParticipant.value = pagination.value
    case "as_reviewer":
      interviewDataAsReviewer.value = await getInterviewsByUser(userId, 'as_reviewer', text, page);
      paginationAsReviewer.value = pagination.value
    case "as_reviewer":
      interviewDataAsPersonInCharge.value = await getInterviewsByUser(userId, 'as_person_in_charge', text, page);
      paginationAsPersonInCharge.value = pagination.value
  }
}

const fetchAdditionalInterviews = async ({ userId, as, text, page }) => {
  switch (as) {
    case "as_participant":
      interviewDataAsparticipant.value = await fetchAdditionalInterviewsByUserId(userId, 'as_participant', text, page);
      paginationAsParticipant.value = pagination.value
    case "as_reviewer":
      interviewDataAsReviewer.value = await fetchAdditionalInterviewsByUserId(userId, 'as_reviewer', text, page);
      paginationAsReviewer.value = pagination.value
    case "as_reviewer":
      interviewDataAsPersonInCharge.value = await fetchAdditionalInterviewsByUserId(userId, 'as_person_in_charge', text, page);
      paginationAsPersonInCharge.value = pagination.value
  }
}

const searchText = ref('')

const search = debounce(
  async() => {
    try {
      getInterviewsByUser(props.user.id, page.value, searchText.value )

    } catch (error) {
      snackbar.setBgColor('negativeRed')
      snackbar.setMsg('Error while searching')
      snackbar.displaySnackBar()
    }
  }, 200
)
</script>

<style scoped>
:deep(.v-input__control) {
  color: #333333;
  font-weight: 500;
}
</style>
