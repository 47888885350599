<template>
  <bkt-pop-up-modal-skeleton
    ref="addToTrainingModal"
    :fullscreen="isMobile ? true : false"
  >
    <template #button>
      <v-btn
        variant="flat"
        color="trainingOrangeSoft"
        prepend-icon="mdi-plus"
        :text="contentType === 'module' ? $t('Add modules') : $t('Add playlists')"
        class="text-trainingOrange text-xs font-medium normal-case"
      />
    </template>

    <template #header="{ close }">
      <!-- Desktop -->
      <div
        class="hidden lg:flex flex-col w-full relative px-4 py-4 items-center justify-center"
      >
        <div
          class="self-end"
          @click="close"
        >
          <Icon
            :icon="'material-symbols:close'"
            height="24"
            width="24"
            class="text-dark-grey hover:text-fake-black cursor-pointer"
          />
        </div>

        <div class="flex justify-center items-center max-w-[80%]">
          <p
            v-if="contentType === 'module'"
            class="font-medium text-xl text-fakeBlack line-clamp-1"
          >
            {{ (selectedModulesToAdd?.length === 0 || selectedModulesToAdd?.length === 1) ?
              $t('Select module to add ()', { selectedModules: selectedModulesToAdd?.length || 0 }) :
              $t('Select modules to add ()', { selectedModules: selectedModulesToAdd?.length || 0 }) }}
          </p>
          <p
            v-else
            class="font-medium text-xl text-fakeBlack line-clamp-1"
          >
            {{ (selectedPlaylistsToAdd?.length === 0 || selectedPlaylistsToAdd?.length === 1) ?
              $t('Select playlist to add ()', { selectedPlaylists: selectedPlaylistsToAdd?.length || 0 }) :
              $t('Select playlist to add ()', { selectedPlaylists: selectedPlaylistsToAdd?.length || 0 }) }}
          </p>
        </div>
      </div>

      <!-- Mobile -->
      <div
        class="lg:hidden flex flex-col w-full relative items-center justify-center"
      >
        <div class="flex justify-end pt-4 pr-4 w-full">
          <div @click="close">
            <Icon
              :icon="'material-symbols:close'"
              class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
            />
          </div>
        </div>

        <div class="px-4 gap-2 w-full flex justify-center items-center">
          <p
            v-if="contentType === 'module'"
            class="font-medium text-xl text-fakeBlack line-clamp-1 pb-4"
          >
            {{ (selectedModulesToAdd?.length === 0 || selectedModulesToAdd?.length === 1) ?
              $t('Select module to add ()', { selectedModules: selectedModulesToAdd?.length || 0 }) :
              $t('Select modules to add ()', { selectedModules: selectedModulesToAdd?.length || 0 }) }}
          </p>

          <p
            v-else
            class="font-medium text-xl text-fakeBlack line-clamp-1 pb-4"
          >
            {{ (selectedPlaylistsToAdd?.length === 0 || selectedPlaylistsToAdd?.length === 1) ?
              $t('Select playlist to add ()', { selectedPlaylists: selectedPlaylistsToAdd?.length || 0 }) :
              $t('Select playlist to add ()', { selectedPlaylists: selectedPlaylistsToAdd?.length || 0 }) }}
          </p>
        </div>
      </div>
    </template>

    <template #body-content>
      <div class="flex flex-col h-full overflow-y-auto gap-4">
        <!-- Inputs -->
        <div class="flex flex-start px-4 pt-4 gap-2 self-stretch">
          <v-text-field
            v-model="searchText"
            hide-details
            class="text-sm font-normal w-full"
            variant="outlined"
            :label="$t('Search')"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            @input="toggleSearch"
          />

          <!-- Filter -->
          <v-menu
            :close-on-content-click="false"
            location="bottom"
            :content-class="'w-[300px] h-[300px]'"
          >
            <!-- Menu activator -->
            <template #activator="{ props }">
              <div
                v-bind="props"
                class="flex justify-center items-center w-[48px] h-[48px] px-4 border border-darkGrey rounded cursor-pointer"
              >
                <div class="flex justify-center items-center self-stretch">
                  <Icon
                    icon="mdi:filter-variant"
                    height="16"
                    width="16"
                  />
                </div>
              </div>
            </template>

            <!-- List -->
            <v-list class="!py-0 !rounded-[8px] !border !border-fakeBlack">
              <template #default>
                <div
                  class="flex flex-col"
                >
                  <!-- Theme list -->
                  <v-list-item
                    v-for="(item, index) in trainingThemes"
                    :key="index"
                    class="!p-0 cursor-pointer"
                    :class="selectedThemesList.find(theme => theme === item) !== undefined ?
                      'bg-veryLightGrey' : ''"
                    @click.stop="toggleThemeSelection(item)"
                  >
                    <template #default>
                      <div class="flex h-10 px-4 gap-2 items-center">
                        <Icon
                          :icon="selectedThemesList.find(theme => theme === item) !== undefined ?
                            'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                          color="darkGrey"
                          width="18"
                          height="18"
                        />

                        <div class="w-full flex items-center">
                          <p class="text-darkGrey text-base font-normal">
                            {{ item?.name }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-list-item>

                  <!-- No result -->
                  <div
                    v-if="trainingThemes.length === 0"
                    class="w-full h-[120px] flex justify-center items-center self-stretch p-2"
                  >
                    <p class="text-middleGrey text-base font-normal">
                      {{ $t('No theme to display') }}
                    </p>
                  </div>
                </div>
              </template>
            </v-list>
          </v-menu>

          <!-- Create new playlist button -->
          <div v-if="contentType === 'playlist'">
            <dialog-create-new-playlist
              title="Create new playlist"
              text-confirm="Validate"
            >
              <template #button>
                <v-btn
                  class="text-middleGrey border border-darkGrey"
                  size="48"
                  variant="plain"
                >
                  <Icon
                    icon="mdi:add"
                    height="16"
                    width="16"
                  />
                </v-btn>
              </template>
            </dialog-create-new-playlist>
          </div>

          <!-- Create new module button -->
          <v-btn
            v-else
            class="text-middleGrey border border-darkGrey"
            size="48"
            variant="plain"
            @click="goToNewModule"
          >
            <Icon
              icon="mdi:add"
              height="16"
              width="16"
            />
          </v-btn>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesList.length > 0"
          class="flex justify-between items-start self-stretch px-4"
        >
          <div class="flex items-start content-start gap-2 flex-1 flex-wrap">
            <!-- Theme chips -->
            <div
              v-for="(theme, index) in selectedThemesList"
              :key="index"
              class="flex px-2 py-[6px] justify-center items-center rounded-[8px] bg-veryLightGrey !border !border-middleGrey"
            >
              <div class="flex justify-center items-center gap-2">
                <p class="text-darkGrey text-xs font-normal">
                  {{ theme?.name }}
                </p>

                <!-- Close icon -->
                <div
                  class="cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                >
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-darkGrey"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedThemesList.length > 1"
            class="flex justify-center items-center px-4 py-[10px]"
            @click="clearAllSelectedThemes"
          >
            <p class="text-xs text-trainingOrange font-medium cursor-pointer">
              {{ $t('Clear all') }}
            </p>
          </div>
        </div>

        <!-- Content list -->
        <div
          v-if="contents?.length"
          class="w-full h-full flex flex-col lg:!max-h-[300px] xl:!max-h-[600px] lg:!overflow-y-auto"
        >
          <div
            v-if="contentType === 'module'"
            class="flex flex-col gap-2"
          >
            <div
              v-for="(content, index) in contents"
              :key="index"
              class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer h-[82px]"
              :class="selectedModulesToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ? 'bg-trainingOrangeSoft ' : 'bg-white'"
              @click="toggleContent(content)"
            >
              <div>
                <Icon
                  :icon="selectedModulesToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ?
                    'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                  height="18"
                  width="18"
                  :color="selectedModulesToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ?
                    '#EF8C64' : 'black'"
                />
              </div>

              <module-card-list-item
                :id="content?.id"
                :key="index"
                :background-image="content?.cover_url?.['500']"
                :is-favorite="content?.connected_user_favorited"
                :title="content?.title"
                :theme-list="content?.themes"
                :duration="content?.duration"
                :updated-at="moment(content?.updated_at).format('DD/MM/YYYY')"
                :is-acquired="content?.isAcquired"
                :acquisition-date="content?.acquisitionDate"
              />
            </div>
          </div>

          <div
            v-if="contentType === 'playlist'"
            class="flex flex-col gap-2"
          >
            <div
              v-for="(content, index) in contents"
              :key="index"
              class="flex flex-row items-center px-4 py-2 gap-4 cursor-pointer h-[82px]"
              :class="selectedPlaylistsToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ? 'bg-trainingOrangeSoft ' : 'bg-white'"
              @click="toggleContent(content)"
            >
              <div>
                <Icon
                  :icon="selectedPlaylistsToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ?
                    'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                  height="18"
                  width="18"
                  :color="selectedPlaylistsToAdd.some((data) => data.id == content.id && data.content_type == content.content_type) ?
                    '#EF8C64' : 'black'"
                />
              </div>

              <playlist-card-list-item
                :playlist="content"
              />
            </div>
          </div>
        </div>

        <!-- No content in list -->
        <div
          v-else
          class="flex flex-col justify-center items-center gap-4 self-stretch h-[320px]"
        >
          <Icon
            icon="noto:winking-face"
            height="24"
            width="24"
          />

          <p class="text-fakeBlack text-sm font-medium text-center max-w-[277px]">
            {{ contentType === 'module' ?
              $t('Hmm, it seems you have no module yet. Create your first module!') :
              $t('Hmm, it seems you have no playlist yet. Create your first playlist!') }}
          </p>
        </div>

        <!-- Validate button module -->
        <div
          v-if="contentType === 'module'"
          class="w-full px-4 pb-4"
        >
          <v-btn
            variant="flat"
            class="w-full normal-case"
            color="primary"
            @click="addContentsToTraining()"
          >
            <p class="text-white text-xs font-medium">
              {{ $t('Validate') }}
            </p>
          </v-btn>
        </div>

        <!-- Validate button playlist -->
        <div
          v-if="contentType === 'playlist'"
          class="w-full px-4 pb-4"
        >
          <v-btn
            variant="flat"
            class="w-full normal-case"
            color="primary"
            @click="addContentsToTraining()"
          >
            <p class="text-white text-xs font-medium">
              {{ $t('Validate') }}
            </p>
          </v-btn>
        </div>
      </div>
    </template>
  </bkt-pop-up-modal-skeleton>
</template>

<script setup>
import { ref, computed } from "vue";
import DialogCreateNewPlaylist from "../../Dialogs/learn/DialogCreateNewPlaylist.vue";
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import { Icon } from "@iconify/vue";
import { useLearnModuleStore } from "@/store/learn-module";
import { useLearnTrainingStore } from "@/store/learn-trainings";
import ModuleCardListItem from "@/components/trainingApp/ModuleCardListItem.vue";
import PlaylistCardListItem from "@/components/trainingApp/PlaylistCardListItem.vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useRouter } from "vue-router";
import moment from "moment";
import { debounce } from "lodash";
import { useSnackbar } from "@/store/snackbar";
import i18n from '@/plugins/i18n';
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"

const props = defineProps({
  contentType: { type: String, required: true },
  trainingThemes: { type: Array, default: null },
  contents: { type: Array, required: true },
  trainingId: { type: Number, required: true },
  selectedModulesToAdd: { type: Array, default: null },
  selectedPlaylistsToAdd: { type: Array, default: null },
  modulesAndPlaylist: { type: Array, default: null },
})

const searchText = ref('')

const addToTrainingModal = ref(null)

const selectedThemesList = ref([])

const snackbar = useSnackbar()

const router = useRouter()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const { createModule, updateModuleImage } = useLearnModuleStore()

const { isMobile } = storeToRefs(useMobileStore())

const emit = defineEmits(['toggle-search', 'toggle-content-in-training', 'add-contents-to-training'])

const toggleThemeSelection = (item) => {
  const found = selectedThemesList?.value?.find(theme => theme === item);

  if (found) {
    selectedThemesList.value?.splice(selectedThemesList.value?.findIndex((theme) => theme === item), 1);
  }
  else {
    selectedThemesList.value?.push(item);
  }
}

const clearAllSelectedThemes = () => {
  selectedThemesList.value = [];
}

const toggleSearch = debounce(() => {
  if (props?.contentType === 'module') {
    emit('toggle-search', searchText.value, 'module')
  }
  else if (props?.contentType === 'playlist') {
    emit('toggle-search', searchText.value, 'playlist')
  }
}, 300)

const goToNewModule = async() => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    const module = await createModule("Module title", "30 minutes")

    loadXHR(randomImage).then(async(blob) => {
      updateModuleImage(module?.data?.id, blob)
    })

    addToTrainingModal.value.dialog = false

    router.push({ name: "module_edit", params: { id: module?.data?.id }, query: { training_id: props.trainingId} })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const toggleContent = async(content) => {
  emit('toggle-content-in-training', content)
}

const addContentsToTraining = async() => {
  emit('add-contents-to-training')
  addToTrainingModal.value.dialog = false
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
