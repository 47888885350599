<template>
  <div
    v-if="managerInterview || employeeInterview || crossedInterview"
  >
    <pop-up-skeleton>
      <template #header>
        <svn-avatar
          :avatar="employee.avatar"
          :firstname="employee.firstname"
          :lastname="employee.lastname"
          size="xl"
          class="mt-16 mb-2 self-center"
        />

        <p class="text-xl font-medium text-center text-fakeBlack">
          {{ `${employee.firstname}
          ${employee.lastname}` }}
        </p>
      </template>

      <template #body>
        <div class="flex xs:flex-wrap xl:flex-nowrap xs:justify-center lg:justify-content-between !w-full gap-x-6">
          <div
            v-for="(interview, idx) in [employeeInterview, managerInterview, crossedInterview].filter(n => n)"
            :key="idx"
            class="flex flex-col w-1/3"
          >
            <div
              v-if="interview"
              class="flex flex-col w-1/3 border border-middleGrey border-opacity-100
                rounded h-[250px] justify-between items-center w-full p-5"
            >
              <div v-if="interview.label === 'Employee'">
                <svn-avatar
                  :avatar="employee.avatar"
                  :firstname="employee.firstname"
                  :lastname="employee.lastname"
                  size="lg"
                  class="rounded-full"
                />

                <div class="mt-3">
                  <p class="text-sm font-semibold text-center text-fakeBlackGrey mb-3">
                    {{ label[interview.label] }}
                  </p>

                  <p
                    :class="getColorByCampaignStatus(interview.status)"
                    class="text-sm font-medium"
                  >
                    {{ $t($filters.capitalize($filters.cleanUnderscore(interview.status))) }}
                  </p>
                </div>
              </div>

              <div v-else-if="interview.label === 'Manager'">
                <svn-avatar
                  :avatar="interviewer.avatar"
                  :firstname="interviewer.firstname"
                  :lastname="interviewer.lastname"
                  size="lg"
                  class="mx-auto"
                />

                <div class="mt-3">
                  <p class="text-sm font-semibold text-center text-fakeBlackGrey mb-3">
                    {{ label[interview.label] }}
                  </p>

                  <p
                    :class="getColorByCampaignStatus(interview.status)"
                    class="text-sm font-medium"
                  >
                    {{ $t($filters.capitalize($filters.cleanUnderscore(interview.status))) }}
                  </p>
                </div>
              </div>
              <div v-else>
                <div class="flex">
                  <div class="flex m-auto">
                    <svn-avatar
                      :avatar="employee.avatar"
                      :firstname="employee.firstname"
                      :lastname="employee.lastname"
                      size="lg"
                    />

                    <svn-avatar
                      :avatar="interviewer.avatar"
                      :firstname="interviewer.firstname"
                      :lastname="interviewer.lastname"
                      size="lg"
                    />
                  </div>
                </div>

                <div class="mt-3">
                  <p class="text-sm font-semibold text-center text-fakeBlackGrey mb-3">
                    {{ label[interview.label] }}
                  </p>

                  <p
                    :class="getColorByCampaignStatus(interview.status)"
                    class="text-sm font-medium"
                  >
                    {{ $t($filters.capitalize($filters.cleanUnderscore(interview.status))) }}
                  </p>
                </div>
              </div>

              <div class="!h-[50px] !w-full flex flex-column justify-content">
                <v-btn
                  v-if="interview.label == 'Crossed' && interview.date"
                  variant="text"
                  class="!w-full flex flex-column justify-content-center bkt-bg-light-grey8-hover
                               py-0_5rem rounded-3px"
                  :disabled="interview.status == 'submitted'"
                  @click.stop="shiftCrossInterviewDate(interview)"
                >
                  <p class="text-xs font-medium text-primary">
                    {{ new Date(interview.date).toLocaleString(
                      "en-GB", {weekday: "short", year: "numeric", month: "short", day: "numeric"}
                    ) }}
                  </p>
                  <p class="text-xs font-medium mr-1 text-primary">
                    {{ formatTime(interview.starts_at) }} {{ $t('to') }} {{ formatTime(interview.ends_at) }}
                  </p>
                </v-btn>

                <v-btn
                  v-else-if="interview.label == 'Crossed' && interview.status != 'submitted'"
                  class="!w-full flex justify-center place-items-center bkt-bg-light-grey8-hover
                               py-0_5rem rounded-3px"
                  variant="outlined"
                  color="primary"
                  @click.stop="shiftCrossInterviewDate(interview)"
                >
                  <Icon
                    class="text-xs font-medium text-primary mr-1"
                    icon="akar-icons:calendar"
                  />
                  <p class="text-xs font-medium text-primary">
                    {{ $t('Schedule a meeting') }}
                  </p>
                </v-btn>
              </div>
              <div
                v-if="interview.label === 'Employee' ||
                  interview.status === 'submitted' ||
                  (employee.id.toString() !== currentUserId?.toString() &&
                    interviewer.id.toString() !== currentUserId.toString())"
              >
                <v-btn
                  color="primary"
                  :disabled="interview.status !== 'submitted'"
                  :to="{name: 'interview_answer', params: {id: interview.id}}"
                  class="normal-case text-sm disabled:bg-middleGrey text-surface"
                  :class="interview.status !== 'submitted' ? 'bg-middleGrey text-darkGrey' : null"
                  @click="closePopUp()"
                >
                  {{ $t('View answers') }}
                </v-btn>
              </div>

              <div
                v-else-if="interview.label === 'Manager'"
              >
                <v-btn
                  color="neutral"
                  :class="'bg-middleGrey text-darkGrey'"
                  class="normal-case text-sm"
                  disabled
                >
                  {{ interview.status === 'not_started' ? $t('Not Started') : $t('Started') }}
                </v-btn>
              </div>

              <div
                v-else-if="interview.label === 'Crossed'"
              >
                <v-btn
                  color="primary"
                  class="normal-case text-sm"
                  :class="'bg-middleGrey text-darkGrey'"
                  disabled
                >
                  {{ interview.status === 'in_progress' ? $t('In Progress') : $t('Not Started') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </template>
    </pop-up-skeleton>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Icon } from "@iconify/vue";
import PopUpSkeleton from './PopUpSkeleton.vue';
import useCampaigns from "../../tools/useCampaigns.js";
import { usePopUpStore } from "../../store/pop-up.js";
import i18n from '@/plugins/i18n';

const DEFAULT_LABEL = {
  'Employee': 'Interviewee',
  'Manager': 'Interviewer',
  'Crossed': 'Cross review'
}

const emit = defineEmits(['fetch-page'])
defineProps({
  overview: { type: String, default: null},
  managerInterview: { type: Object, default: null},
  crossedInterview: { type: Object, default: null},
  employeeInterview: { type: Object, default: null},
  employee: { type: Object, default: null},
  interviewer: { type: Object, default: null},
  currentUserId: { type: String, default: null},
  open: { type: Boolean, default: true},
  close: { type: Function, default: null},
  loading: { type: Boolean, default: false},
  componentName: { type: String, default: 'PopUpSetInterview'},
  title: { type: String, default: null},
  })
const { getColorByCampaignStatus } = useCampaigns();
const label = ref(Object.assign({}, DEFAULT_LABEL))
const { closePopUp, openPopUp } = usePopUpStore();

  onMounted(() => {
    document.querySelectorAll('button[data-disabled="true"]').forEach((button) => {
      button.disabled = true
      button.classList.remove('bkt-bg-light-grey8-hover')
    })
  })
    const shiftCrossInterviewDate = (cross) => {
      openPopUp({
        componentName: 'pop-up-shift-cross-interview-date',
        title: i18n.global.t('Schedule a meeting'),
        campaignId: cross.campaign_id,
        crossId: cross.id,
        startDate: cross.starts_at,
        endDate: cross.ends_at,
        icon: 'material-symbols-today',
        date: cross.date,
        callback: () => { emit('fetch-page') },
        close() {}
      })
    }
    const formatTime = (datetime) => {
      const time = new Date(datetime)
      const options = { minimumIntegerDigits: 2, useGrouping: false }
      const hour = parseInt(time.getUTCHours(), 10) - (new Date().getTimezoneOffset()) / 60
      var time_string = hour.toLocaleString('en-GB', options) + ':' + time.getUTCMinutes().toLocaleString('en-GB', options)
      return time_string
    }
</script>
