<template>
  <div
    class="w-full flex items-center self-stretch gap-3 cursor-pointer"
    :class="isModule ?
      'p-4 rounded-[12px] border border-darkGrey' : 'px-1 py-4 border-0 border-t border-t-darkGrey'"
  >
    <!-- Card image -->
    <img
      :src="module?.cover_url?.['500']"
      class="rounded-[5px] sm:!rounded-[4px] w-[140px] h-[110px] sm:!w-[112px] sm:!h-[88px]"
      :class="module?.is_acquired ? 'grayscale' : 'grayscale-0'"
      draggable="false"
      style="object-fit: cover;"
    >

    <div class="flex flex-col items-start gap-2 flex-1 sm:!gap-1">
      <!-- Module title and Icon -->
      <div class="flex items-start gap-2 self-stretch">
        <div class="flex py-1">
          <!-- Icon -->
          <Icon
            icon="mdi:puzzle"
            height="16"
            width="16"
            :class="module?.is_acquired ? 'text-middleGrey' : 'text-darkGrey'"
          />
        </div>

        <!-- Title -->
        <p
          class="flex-1 line-clamp-2 text-sm font-medium sm:!line-clamp-1"
          :class="module?.is_acquired ? 'text-darkGrey' : 'text-fakeBlack'"
        >
          {{ module?.title }}
        </p>
      </div>

      <!-- Module duration -->
      <div class="flex items-start self-stretch">
        <div class="flex items-center gap-1">
          <div class="flex items-center">
            <Icon
              icon="mdi:clock-outline"
              height="16"
              width="16"
              class="text-darkGrey"
            />
          </div>

          <p class="text-darkGrey text-sm font-medium">
            {{ module?.duration || $t('No duration') }}
          </p>
        </div>
      </div>

      <div class="flex flex-col gap-2 sm:!flex-row sm:!w-full sm:!gap-0 sm:!justify-between sm:!items-center sm:mt-4">
        <!-- Module Themes -->
        <div class="flex items-center gap-2 self-stretch">
          <div class="flex items-center gap-2 flex-1">
            <!-- There is only 1 themes or less -->
            <div
              v-for="(theme) in slicedThemesList"
              :key="theme"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px]"
              :class="module?.is_acquired ? 'bg-veryLightGrey' : 'bg-trainingOrangeSoft'"
            >
              <p
                class="flex text-xs font-normal items-center"
                :class="module?.is_acquired ? 'text-darkGrey' : 'text-fakeBlack'"
              >
                {{ theme?.name }}
              </p>
            </div>
    
            <!-- If there is more than 1 themes -->
            <div
              v-if="(module?.themes?.length - slicedThemesList?.length) > 0"
              class="flex h-[22px] px-2 py-[6px] justify-center items-center rounded-[8px]"
              :class="module?.is_acquired ? 'bg-veryLightGrey' : 'bg-trainingOrangeSoft'"
            >
              <p
                class="flex text-xs font-normal items-center"
                :class="module?.is_acquired ? 'text-darkGrey' : 'text-fakeBlack'"
              >
                +{{ module?.themes?.length - slicedThemesList?.length }}
              </p>
            </div>
          </div>
        </div>
  
        <!-- Module acquired -->
        <div
          v-if="module?.submission_status === 'acquired'"
          class="flex items-center gap-1"
        >
          <Icon
            icon="gg:check-o"
            height="11"
            width="11"
            class="text-positiveGreen"
          />
    
          <p class="text-positiveGreen text-xs font-medium">
            {{ $t('Acquired on') }} {{ filters.formatDate(module?.submission_updated_at, 'DD/MM/YYYY') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import { storeToRefs } from 'pinia';
import filters from '@/tools/filters';
import { useMobileStore } from '@/store/mobile';

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps({
  module: { type: Object, default: null },
  isModule: { type: Boolean, default: true },
})

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props.module?.themes?.slice(0, 1)
  }
  else {
    return props.module?.themes?.slice(0, 2)
  }
})
</script>