<template>
  <bkt-pop-up-modal-skeleton
    ref="modalSkeleton"
    fullscreen
  >
    <template #button>
      <div @click="updateBreadcrumbs">
        <slot name="button" />
      </div>
    </template>

    <template #header="{ close }">
      <module-show-header-buttons
        modal
        @close-and-go-back="handleClose"
      />
    </template>

    <template #body-content>

      <div class="flex flex-col gap-8 gap-h-full overflow-y-auto pb-[80px] lg:py-8">
        <!-- Breadcrumbs -->
        <div class="hidden lg:flex">
          <!-- <bkt-breadcrumbs>
            {{ title }}
          </bkt-breadcrumbs> -->
        </div>

        <div class="flex flex-col md:w-[758px] mx-auto px-4 lg:!px-[0px] gap-6 lg:gap-8 w-full">
          <!-- Module Cover -->
          <div class="py-4 lg:!py-[0px]">
            <div
              class="rounded-lg bg-middle-grey h-[157px] sm:h-[320px] relative bg-contain bg-center"
              :style="{'background-image': cover ? `url('${cover}')` : '', 'background-size': 'cover'}"
            />
          </div>

          <div class="flex flex-col gap-6">
            <!-- Module infos -->
            <div class="flex flex-col gap-1">
              <!-- Title -->
              <p class="font-semibold text-2xl lg:text-3xl line-clamp-1">
                {{ title ? title : $t('Module title') }}
              </p>

              <!-- Themes list -->
              <div class="w-full flex justify-start items-center gap-2 flex-wrap">
                <div
                  v-for="(theme, index) in themes"
                  :key="index"
                  class="rounded-lg bg-training-orange-soft text-xs px-2 py-[6px]"
                >
                  {{ theme?.name }}
                </div>
              </div>

              <!-- Module duration -->
              <div class="flex gap-2 text-dark-grey items-center">
                <Icon
                  icon="mdi:access-time"
                  class="h-4 w-4"
                />

                <p class="font-medium font-sm">
                  {{ duration }}
                </p>
              </div>
            </div>

            <!-- Rich text editor -->
            <svn-tiptap
              v-if="editorData?.blocks?.length"
              :create-image-url="`/api/v1/editor_contents/fake-id/upload_image`"
              :html-data="editorData?.blocks"
              :extension-selection="AllTipTapPlugins"
              :extension-slash-command="AllTipTapPlugins"
              :is-editable="false"
            />

            <!-- Reactions -->
            <div class="flex flex-col gap-4 p-6 border rounded-lg">
              <!-- Titles -->
              <div class="flex flex-col gap-2.5">
                <p class="font-medium text-xl">
                  {{ $t('What did you think of the module ?') }}
                </p>

                <p class="text-dark-grey text-sm">
                  {{ $t('Your feedback helps us improve this module.') }}
                </p>
              </div>

              <!-- Reactions List -->
              <div class="grid grid-cols-2 lg:grid-cols-4 gap-2.5">
                <reaction-card
                  v-for="(reaction, index) in reactions"
                  :key="index"
                  :icon="reaction?.icon"
                  :name="reaction?.name"
                  :count="reaction?.count"
                  :type="reaction?.type"
                  :selected="reaction?.selected"
                  :bind="false"
                />
              </div>
            </div>

            <!-- No evaluation is present -->
            <div
              v-if="learnModule?.has_learn_pieces_approval"
              class="flex flex-col gap-8 p-6 border rounded-lg"
            >
              <!-- Titles -->
              <div class="flex flex-col gap-2.5">
                <p class="font-medium text-xl">
                  {{ $t("🙌 You've reached the end! Did you learn all the following learning objectives?") }}
                </p>

                <div
                  v-html="learnApprovalInput?.text ||  $t('Not set')"
                  class="font-medium text-base text-darkGrey leading-6 whitespace-pre-wrap"
                />

                <p class="text-dark-grey text-sm">
                  {{ $t('This module has no more things to teach you. We hope you have learnt the best from it !') }}
                </p>
              </div>
            </div>

            <!-- Evaluation Quiz is present -->
            <div
              v-else-if="learnModule.has_learn_pieces_quiz"
              class="flex flex-col gap-8 p-6 border rounded-lg"
            >
              <!-- Titles -->
              <div class="flex flex-col gap-2.5">
                <p class="font-medium text-xl">
                  {{ $t("🙌 You’ve reached the end ! Ready for the quiz ?") }}
                </p>

                <p class="text-dark-grey text-sm">
                  {{ $t('To complete this module, evaluate yourself with a quiz.') }}
                </p>
              </div>

              <!-- Start the Quiz block -->
              <div class="flex justify-center sm:justify-end items-center w-full">
                <v-spacer class="hidden sm:flex" />

                <v-btn
                  class="normal-case text-xs w-full sm:w-[296px]"
                  variant="flat"
                  color="primary"
                >
                  {{ learnModule?.submission?.status === 'not_started' ?  $t('Start the quiz !') : $t('Retake quiz') }}
                </v-btn>
              </div>
            </div>

            <!-- Evaluation Face to Face is present -->
            <div
              v-else-if="learnModule.has_learn_pieces_face_to_face_evaluation"
              class="flex flex-col items-start gap-8 p-6 border border-middle-grey rounded-[8px]"
            >
              <!-- Titles -->
              <div class="flex flex-col items-start gap-[10px] self-stretch">
                <svn-title v-if="learnModule?.submission?.status === 'in_progress'" h2 medium>
                  {{ $t("🙌 You’ve reached the end! Validate your evaluation to complete this module.") }}
                </svn-title>

                <svn-title v-else-if="learnModule?.submission?.status === 'acquired'" h2 medium>
                  {{ $t('✅ Evaluation done, you have acquired this module!') }}
                </svn-title>

                <svn-title v-else h2 medium>
                  {{ $t("🙌 You’ve reached the end! Please notify your expert before starting the evaluation.") }}
                </svn-title>

                <svn-text v-if="learnModule?.submission?.status !== 'acquired'" sm regular color="dark-grey">
                  {{ $t('To successfully complete this module, your answers must be reviewed in real time by your expert.') }}
                </svn-text>

                <svn-text v-else sm regular color="dark-grey">
                  {{ $t('This module has been acquired, but you can still restart the evaluation.') }}
                </svn-text>
              </div>

              <!-- Start/Continue/restart evaluation button -->
              <div class="flex justify-center sm:justify-end items-center w-full">
                <v-spacer class="hidden sm:flex" />

                <v-btn
                  class="normal-case text-xs w-full sm:w-[296px]"
                  variant="flat"
                  color="primary"
                >
                  {{ learnModule?.submission?.status === 'not_started' ?  $t('Start evaluation') : learnModule?.submission?.status === 'acquired' ? $t('Restart evaluation') : $t('Continue evaluation') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </bkt-pop-up-modal-skeleton>
</template>

<script setup>
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import { Icon} from "@iconify/vue";
import { onMounted, onUnmounted, ref } from "vue";
import ReactionCard from "@/components/trainingApp/quiz/ReactionCard.vue";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import ModuleShowHeaderButtons from "@/components/trainingApp/ModuleShowHeaderButtons.vue";
import { storeToRefs } from "pinia";
import { useLearnModuleStore } from "@/store/learn-module";
import { AllTipTapPlugins } from 'svn-ui-library/extensions';

const props = defineProps({
  title: { type: String, required: true, default: '' },
  cover: { type: String, required: true, default: '' },
  themes: { type: Array, required: true, default: () => [] },
  inputs: { type: Array, required: true, default: () => [] },
  duration: { type: String, required: true, default: '' },
  editorData: { type: Object, required: true, default: () => {} },
  reactions: { type: Object, default: () => {} },
})

const breadcrumbsStore = useBreadcrumbsStore();

const { learnModule, learnApprovalInput } = storeToRefs(useLearnModuleStore());

const { reset, addNode, update } = breadcrumbsStore

const alignTop = ref(false)
const modalSkeleton = ref(null)

const updateBreadcrumbs = () => {
  addNode(props.title, 'learns/catalog')
  update()
}

const resetBreadcrumbs = () => {
  reset()
}

const handleClose = (close) => {
  modalSkeleton.value.dialog = false
}

const alignFunc = () => {
  alignTop.value = (document.body.scrollTop > window.innerHeight / 4 ||
    document.documentElement.scrollTop > window.innerHeight / 4)
}

onMounted(async () => {
  window.addEventListener("scroll", alignFunc);
})

onUnmounted(() => {
  window.removeEventListener("scroll", alignFunc)
})

const goToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}
</script>
