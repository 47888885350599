<template>
  <svn-dialog-skeleton
    ref="dialogRef"
    dialogHasTextArea
    :center-description="false"
    :title="$t('Recommend Aleph')"
    :description="$t('Do you want to recommend Aleph to someone? Please fill out this form, our team will contact the interested person very soon!')"
    :width="isMobile ? 300 : 400"
    :primary-button-text="'Send'"
    loader-color="fake-black"
    primary-button-class="!rounded !text-white !bg-fake-black hover:!bg-[#8c8c8c] active:!bg-[#101010]"
    primary-button-loading-class="!bg-fake-black !text-white"
    :primary-button-close-on-click="false"
    :primary-button-loading="primaryButtonLoading"
    @click-primary-button="clickPrimaryButton"
  >
    <template #activator>
      <slot name="button" />
    </template>

    <template #body>
      <div class="w-full flex flex-col gap-4 self-stretch">
        <v-text-field
          v-model="email"
          density="compact"
          :placeholder="('Target persons email*')"
          variant="outlined"
          :error="emailError.error"
          :error-messages="emailError.errorMessage"
          :hide-details="emailError.hideDetails"
        />

        <v-textarea
          v-model="message"
          density="compact"
          :placeholder="$t('Your message*')"
          variant="outlined"
          :error="messageError.error"
          :error-messages="messageError.errorMessage"
          :hide-details="messageError.hideDetails"
        />
      </div>
    </template>
  </svn-dialog-skeleton>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import axiosService from "@/tools/axios-service.js";
import { useUserStore } from "../../../store/user";
import i18n from "@/plugins/i18n.js";

const snackbar = useSnackbar();

const { isMobile } = storeToRefs(useMobileStore());
const { email: userEmail } = storeToRefs(useUserStore());

const email = ref('');
const message = ref('');
const dialogRef = ref(null);
const primaryButtonLoading = ref(false);
const emailError = ref({ error: false, errorMessage: '', hideDetails: true });
const messageError = ref({ error: false, errorMessage: '', hideDetails: true });

const clickPrimaryButton = async () => {
  if (!email?.value) {
    emailError.value = { error: true, errorMessage: "", hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!message?.value) {
    messageError.value = { error: true, errorMessage: "", hideDetails: true };
    displayRequiredFieldsSnackbar();
  }
  if (!emailHasCorrectFormat?.value) {
    emailError.value = { error: true, errorMessage: i18n.global.t("Wrong email format !"), hideDetails: false };
  }
  if (email?.value && message?.value && emailHasCorrectFormat?.value) {
    primaryButtonLoading.value = true;
    try {
      await axiosService.post('api/v1/global_mailer/recommend_aleph', {
        email: email?.value,
        recommendedEmail: userEmail?.value,
        message: message?.value
      });
      dialogRef.value.dialog = false;
      primaryButtonLoading.value = false;

      document.getElementById("profile_dropdown").click();

      snackbar.setMsg('Your form has been sent. Thank you!');
      snackbar.setBgColor('fake-black');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
  
      resetForm();
    } catch (error) {
      primaryButtonLoading.value = false;

      console.log(error);
      snackbar.setMsg('Error sending form, please try again.');
      snackbar.setBgColor('negativeRed');
      snackbar.setCustomClass('mb-12');
      snackbar.displaySnackBar();
    }
  }
}

const resetForm = () => {
  email.value = '';
  message.value = '';
}

const emailHasCorrectFormat = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email?.value)
});

const displayRequiredFieldsSnackbar = () => {
  snackbar.setMsg('Please fill out all the required(*) fields.');
  snackbar.setBgColor('negativeRed');
  snackbar.setCustomClass('mb-12');
  snackbar.displaySnackBar();
}

watch(email, (newValue, oldValue) => {
  if (newValue !== "" && emailError?.value?.error === true) {
    emailError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

watch(message, (newValue, oldValue) => {
  if (newValue !== "" && messageError?.value?.error === true) {
    messageError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

watch(emailHasCorrectFormat, (newValue, oldValue) => {
  if (newValue === true) {
    emailError.value = { error: false, errorMessage: "", hideDetails: true }
  }
});

defineExpose({
  dialogRef
})
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px;
}

* :deep(.v-text-field .v-input__details) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
</style>
