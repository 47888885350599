<template>
  <div :class="containerClass">
    <!-- :lazy-src="imageLazySrc" -->
    <v-img
      :src="imageSrc"
      aspect-ratio="1/1"
      :cover="cover"
      class="w-full h-full bg-middle-grey"
    >
      <template #placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <svn-loader loading-size="lg" />
        </div>
      </template>

      <template #error>
        <div class="w-full h-full flex flex-col justify-center items-center">
          <p class="sm medium text-center text-dark-grey">
            {{ $t('Link expired') }}
          </p>
        </div>
      </template>

      <template #default>
        <div v-if="!imageSrc" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] p-6">
          <Icon
            icon="ic:outline-image"
            height="104"
            width="104"
            class="text-middle-grey"
          />
        </div>

        <div class="w-full flex justify-end items-end bg-transparent relative">
          <svn-icon-button
            id="editButton"
            circle
            variant="outlined"
            button-size="lg"
            icon="mdi:pencil-outline"
            class="border border-fake-black right-6 top-6"
          >
            <Icon
              icon="mdi:pencil-outline"
              height="24"
              width="24"
              class="text-fake-black"
            />
            
            <dialog-image-upload
              v-model="opened"
              @upload-local-image="fileInput.click()"
              @upload-embed-image="uploadImage"
              @upload-unsplash-image="uploadImage"
              @file-dropped="uploadImage"
            />

            <input class="hidden" type="file" ref="fileInput" @change="loadLocalImage" accept="image/*">
          </svn-icon-button>
        </div>
      </template>
    </v-img>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { Icon } from '@iconify/vue';
import { useSnackbar } from '@/store/snackbar';
import DialogImageUpload from "@/components/BktPopUp/Dialogs/DialogImageUpload.vue";

const props = defineProps({
  containerClass: { type: String, default: "" },
  imageLazySrc: { type: String, default: "" },
  imageSrc: { type: String, default: "" },
  cover: {type: Boolean, default: true}
});

const emit = defineEmits(['file-upload']);

const snackbar = useSnackbar()

const fileInput = ref(null);
const opened = ref(false);

const uploadImage = async (imageLink) => {
  try {
    const tmpFile = await loadXHR(imageLink)
    emit('file-upload', tmpFile)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error updating image.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
    snackbar.displaySnackBar()
  }
  opened.value = false
}

const loadXHR = (url) => {
  return new Promise(function (resolve, reject) {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function () {
        reject("Network error.")
      };
      xhr.onload = function () {
        if (xhr.status === 200) {
          const file = new File([xhr.response], `tmp-${new Date()}`);
          resolve(file)
        } else {
          reject("Loading error:" + xhr.statusText)
        }
      };
      xhr.send();
    } catch (err) {
      reject(err.message)
    }
  });
}

const getMimeType = (file, fallback = null) => {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

const loadLocalImage = (event) => {
  const { files } = event.target;

  if (files && files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (getMimeType(e.target.result, files[0].type)) {
        emit('file-upload', files[0]);
      } else {
        snackbar.setBgColor('negativeRed')
        snackbar.setMsg('Error updating image.')
        snackbar.setCustomClass(isMobile.value ? 'mb-[88px]' : 'mb-10')
        snackbar.displaySnackBar()
      }
    };
    reader.readAsArrayBuffer(files[0]);
  }
}
</script>