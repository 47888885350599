<template>
  <v-window-item
    value="playlist"
  >
    <v-fade-transition>
      <!-- Skeleton loaders -->
      <div
        v-if="loading"
        class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-center self-stretch
          sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <skeleton-loader-playlist-card
          v-for="item in 8"
          :key="item"
        />
      </div>

      <!-- Finished loading -->
      <div
        v-else
        class="flex flex-col mx-4 sm:!mx-6 lg:!mx-12 2xl:!mx-0"
      >
        <!-- Search & Select -->
        <div class="w-full flex flex-row gap-4 pb-1 pt-6 self-stretch xl:!px-0">
          <!-- Search -->
          <v-text-field
            v-model="searchTermPlaylists"
            variant="outlined"
            density="comfortable"
            class="input"
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            @input="search"
          />

          <!-- Sort & Filter -->
          <div class="flex gap-2 xl:!gap-4">
            <!-- Filter -->
            <v-menu
              :close-on-content-click="false"
              location="bottom"
              :content-class="themes?.length === 0 ? 'w-[320px] xl:!w-[464px] h-[228px]' :
                themes?.length === themes?.length ?
                  'w-[320px] xl:!w-[464px] h-[416px]' : 'w-[320px] xl:!w-[464px] h-fit'"
            >
              <!-- Menu activator -->
              <template #activator="{ props }">
                <div
                  v-bind="props"
                  class="flex justify-center items-center w-[52px] h-[52px] px-4
                        border border-darkGrey rounded cursor-pointer"
                >
                  <div class="flex justify-center items-center self-stretch">
                    <Icon
                      icon="mdi:filter-variant"
                      height="16"
                      width="16"
                    />
                  </div>
                </div>
              </template>

              <!-- List -->
              <v-list class="!py-0 !rounded-[8px] !border !border-fakeBlack">
                <template #default>
                  <div
                    class="flex flex-col"
                  >
                    <!-- Searchbar -->
                    <div
                      class="flex flex-col gap-2"
                    >
                      <v-text-field
                        v-model="filteredThemeSearchPlaylists"
                        prepend-inner-icon="mdi-magnify"
                        variant="outlined"
                        density="comfortable"
                        class="px-4 pt-4"
                        :placeholder="$t('Search')"
                        hide-details
                      />

                      <p class="text-middleGrey text-base font-normal pl-6">
                        {{ $t('Themes') }}
                      </p>
                    </div>

                    <!-- Theme list -->
                    <v-list-item
                      v-for="(item, index) in themes"
                      :key="index"
                      class="!p-0 cursor-pointer"
                      :class="selectedThemesListPlaylists?.find(theme => theme?.id === item?.id) !== undefined ?
                        'bg-veryLightGrey' : ''"
                      @click.stop="toggleThemeSelection(item)"
                    >
                      <template #default>
                        <div class="flex h-10 pl-10 pr-2 gap-2 items-center">
                          <Icon
                            :icon="selectedThemesListPlaylists?.find(theme =>
                              theme?.id === item?.id) !== undefined ?
                              'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                            color="darkGrey"
                            width="18"
                            height="18"
                          />

                          <div class="w-full flex items-center">
                            <p class="text-darkGrey text-base font-normal">
                              {{ item?.name }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-list-item>

                    <!-- No theme to display -->
                    <div
                      v-if="themes?.length === 0"
                      class="w-full h-[120px] flex justify-center items-center self-stretch p-2"
                    >
                      <p class="text-middleGrey text-base font-normal">
                        {{ $t('No theme to display') }}
                      </p>
                    </div>

                    <!-- Favorite toggle -->
                    <v-list-item
                      v-if="themes?.length !== 0"
                      class="border-t border-t-middleGrey"
                      @click="toggleFavoritesSelection"
                    >
                      <template #default>
                        <div
                          class="flex h-10 pl-6 pr-2 items-center gap-2 self-stretch cursor-pointer"
                        >
                          <Icon
                            :icon="filterByFavorites ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
                            color="darkGrey"
                            width="18"
                            height="18"
                          />

                          <div class="w-full flex items-center">
                            <p class="text-darkGrey text-base font-normal">
                              {{ $t('Favorites') }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </v-list-item>
                  </div>
                </template>
              </v-list>
            </v-menu>

            <!-- Settings on all: icon mdi-dots-vertical -->
            <bkt-learn-list-settings
              v-if="isManagerCreatorOrAbove()"
              :content-values="settingsOnAllItems"
            />
          </div>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesListPlaylists?.length > 0"
          class="flex justify-between items-start self-stretch px-4 pb-6 xl:!px-0 xl:flex-wrap"
        >
          <div class="flex items-start content-start gap-2 flex-1 flex-wrap">
            <div
              v-for="(theme, index) in selectedThemesListPlaylists"
              :key="index"
              class="flex justify-center items-center rounded-[8px]
                    bg-trainingOrangeSoft !border-[0.5px] !border-[#FEF4F0]"
            >
              <div class="flex justify-center items-center px-2 py-[6px] gap-2">
                <p class="text-fakeBlack text-xs font-normal">
                  {{ theme?.name }}
                </p>

                <div
                  class="cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                >
                  <Icon
                    icon="mdi-close"
                    height="16"
                    width="16"
                    class="text-darkGrey"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedThemesListPlaylists?.length > 1"
            class="flex justify-center items-center px-4 py-[10px]"
            @click="clearAllSelectedThemes"
          >
            <p class="text-xs text-trainingOrange font-medium cursor-pointer">
              {{ $t('Clear all') }}
            </p>
          </div>
        </div>

        <!-- Playlists card list -->
        <v-infinite-scroll
          :items="learnPlaylists"
          class="overflow-hidden"
          color="primary"
          @load="loadMoreData"
        >
          <div
            class="w-full grid grid-cols-1 my-4 gap-4 justify-center items-start self-stretch
            sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            <playlist-card
              v-for="(playlist) in learnPlaylists"
              :id="playlist?.id"
              :key="playlist?.id"
              :playlist-creator-id="playlist?.creator_id"
              :playlist-backgrounds="playlist?.cover_urls"
              :is-favorite="playlist?.connected_user_favorited"
              :title="playlist?.title"
              :theme-list="playlist?.themes"
              :module-count="playlist?.learn_modules?.length"
              @toggle-favorite="togglePlaylistFavorite(playlist?.id)"
              @edit-playlist="goToPlaylistShowEdit(playlist?.id)"
              @delete-playlist="openDialogDeletePlaylist(playlist?.id)"
              @playlist-show="goToPlaylistShowEdit(playlist?.id, playlist?.title)"
            />
          </div>

          <template #empty />
        </v-infinite-scroll>

        <!-- No playlist found after search -->
        <div
          v-if="!learnPlaylists?.length && searchTermPlaylists.length > 0"
          class="flex flex-col text-center justify-center items-center gap-4 px-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:face-with-monocle"
            height="32"
            width="32"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{ $t("Hmm, it seems we can’t find any results based on your search.") }}
          </p>
        </div>

        <!-- No playlist in index -->
        <div
          v-if="learnPlaylists?.length === 0 && searchTermPlaylists.length === 0"
          class="flex flex-col text-center justify-center items-center gap-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:eyes"
            height="24"
            width="24"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{
              isManagerCreatorOrAbove() ? $t("There are no playlists. Create your first playlist !") : $t("There are no playlists.")
            }}
          </p>

          <!-- Create new playlist dialog -->
          <dialog-create-new-playlist
            v-if="isManagerCreatorOrAbove()"
            title="Create new playlist"
            text-confirm="Validate"
          >
            <template #button>
              <v-btn
                color="primary"
                variant="flat"
                class="normal-case text-xs font-medium"
              >
                {{ $t("Create playlist") }}
              </v-btn>
            </template>

            <template #close="{ close }">
              <div
                class="self-end"
                @click="close"
              >
                <Icon
                  :icon="'material-symbols:close'"
                  class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
                />
              </div>
            </template>
          </dialog-create-new-playlist>
        </div>
      </div>
    </v-fade-transition>

    <!-- Modal Deleted Items to CUSTOMIZE -->
    <modal-view-deleted-items
      ref="deletedItems"
      content-type="playlist"
      :contents="learnTrashPlaylists"
      @toggle-search="toggleSearchDeletedItems"
      @restore="restoreDeletedItems"
      @delete-permanently="deletePermanentlyDeletedItems"
      @view-item="viewDeletedItem"
    />

    <!-- Dialog delete Playlist -->
    <dialog-delete-playlist
      ref="deletePlaylistDialog"
      @delete-playlist="deleteLearnPlaylist"
    />
  </v-window-item>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from "pinia";
import { ref, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import DialogCreateNewPlaylist from '@/components/BktPopUp/Dialogs/learn/DialogCreateNewPlaylist.vue';
import { useLearnPlaylistStore } from "@/store/learn-playlist.js";
import PlaylistCard from "@/components/trainingApp/PlaylistCard.vue";
import router from '@/router';
import SkeletonLoaderPlaylistCard from '@/components/skeletonLoaders/SkeletonLoaderPlaylistCard.vue';
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import { debounce } from 'lodash';
import DialogDeletePlaylist from '@/components/BktPopUp/Dialogs/learn/DialogDeletePlaylist.vue';
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import i18n from '@/plugins/i18n';
import { useUserStore } from "@/store/user.js";

const { isManagerCreatorOrAbove } = useUserStore();

const props = defineProps({
  themes: { type: Array, default: null },
})

onMounted(async() => {
  try {
    await fetchPlaylists().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnPlaylists.value = res.data.learn_playlists
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching playlists')
    snackbar.displaySnackBar()
  }

  try {
    await fetchTrashPlaylists()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setCustomClass(isMobile.value ? 'mb-[85px]' : 'mb-10')
    snackbar.setMsg('Error fetching trash playlists')
    snackbar.displaySnackBar()
  }
})

const page = ref(1)

const totalPages = ref(null)

const loading = ref(true)

const deletePlaylistDialog = ref(false)

const idPlaylistToDelete = ref(null)

const searchTermPlaylists = ref('')

const filteredThemeSearchPlaylists = ref('')

const selectedThemesListPlaylists = ref([])

const filterByFavorites = ref(false)

const snackbar = useSnackbar()

const { fetchPlaylists, fetchPlaylistsOnlyWithPages, toggleFavorite, deletePlaylist, restorePlaylist, deletePlaylistPermanently, fetchTrashPlaylists } = useLearnPlaylistStore();

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const { isMobile } = storeToRefs(useMobileStore())

const { learnPlaylists, learnTrashPlaylists } = storeToRefs(useLearnPlaylistStore());

const deletedItems = ref(null)

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted playlists'),
    onClick: openDeletedItems
  }
])

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashPlaylist(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const viewDeletedItem = async (id) => {
  try {
    goToPlaylistShowEdit(id, "Playlist show")
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while showing deleted playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const restoreDeletedItems = async (id) => {
  try {
    await restorePlaylist(id)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Playlist restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deletePlaylistPermanently(id)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Playlist deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const toggleThemeSelection = debounce(async(item) => {
  const found = selectedThemesListPlaylists?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesListPlaylists?.value?.splice(selectedThemesListPlaylists?.value?.findIndex((theme) => theme?.id === item?.id), 1);
  }
  else {
    selectedThemesListPlaylists?.value?.push(item);
  }
  search()
}, 200)

const togglePlaylistFavorite = debounce((playlistId) => {
  const found = learnPlaylists?.value?.find(playlist => playlist?.id === playlistId)

  if (found) {
    toggleFavorite(playlistId)
  }
}, 200)

const toggleFavoritesSelection = debounce(() => {
  filterByFavorites.value = !filterByFavorites.value

  search()
}, 200)

const openDialogDeletePlaylist = (id) => {
  deletePlaylistDialog.value.dialogDeletePlaylist = true
  idPlaylistToDelete.value = id
}

const deleteLearnPlaylist = async() => {
  try {
    await deletePlaylist(idPlaylistToDelete?.value)

    deletePlaylistDialog.value.dialogDeletePlaylist = false

    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('This playlist has been deleted')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error deleting playlist')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
  idPlaylistToDelete.value = null
}

const goToPlaylistShowEdit = (id, title) => {
  updateBreadcrumbs(title, id)
  router.push({ name: 'playlist_show', params: { id: id } })
}

const clearAllSelectedThemes = () => {
  selectedThemesListPlaylists.value = [];
}

const search = async() => {
  try {
    const res = await fetchPlaylists(searchTermPlaylists.value,
      selectedThemesListPlaylists?.value?.map(theme => theme?.id), filterByFavorites.value)
    learnPlaylists.value = res.data.learn_playlists
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching playlists')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const updateBreadcrumbs = (title, playlistId) => {
  addNode(title, `/learns/module/${playlistId}/show`)
  update()
}

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      const res = await fetchPlaylistsOnlyWithPages(page.value + 1)

        if (res.data.learn_playlists.length) {
          learnPlaylists.value = [...learnPlaylists.value, ...res.data.learn_playlists]
        }
        done('ok')
        page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>

<style scoped>
.skeleton :deep(.v-skeleton-loader__bone) {
  margin-left: 0;
  margin-right: 0;
}
</style>