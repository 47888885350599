<template>
  <trash-warnning
    v-if="isTrashed"
    title="This target is in trash."
    @restore="restoreDeletedItem"
    @delete-permanently="deleteTarget"
  />

  <div class="w-full sm:w-3/4 flex items-start justify-start">
    <BktBreadcrumbs>
      <div class="flex flex-row items-center justify-center">
        <v-btn
          variant="flat"
          class="mr-2 normal-case bkt-fakeblack-grey pl-3 text-fakeBlack font-medium text-sm"
        >
          {{ roadmapTarget?.title }}
        </v-btn>
      </div>
    </BktBreadcrumbs>
  </div>

  <div class="w-full flex flex-col sm:flex-row justify-center sm:pt-9 pb-5">
    <div class="w-full sm:w-[43%] sm:border-t sm:border-l sm:border-b sm:border-r sm:rounded-l-lg">
      <!-- Header -->
      <div class="flex flex-row justify-around items-center px-6 py-5 h-20 border-b border-b-middleGrey">
        <div
          v-for="(item, idx) in [
            { kind: 'Employee', obj: employee },
            { kind: 'Manager', obj: employee?.manager },
            { kind: 'Creator', obj: creator },
          ]"
          :key="idx"
          class="flex flex-row justify-center items-center mr-2"
        >
          <svn-avatar
            v-if="item.obj != null"
            :avatar="item.obj?.avatar?.['50']"
            :firstname="item.obj.firstname"
            :lastname="item.obj.lastname"
            size="md"
          />

          <svn-avatar
            v-else
            :firstname="'N/A'"
            :lastname="''"
            size="md"
          />

          <div
            class="flex flex-col ml-2 max-w-[50px] sm:max-w-[60px] lg:max-w-[70px] xl:max-w-[90px] 2xl:max-w-[100px]"
          >
            <p class="font-medium text-sm text-start truncate text-fakeBlack">
              {{ item.obj != null ? `${item.obj.firstname} ${item.obj.lastname}` : "N/R" }}
            </p>

            <p class="font-medium text-xs text-start text-darkGrey text-truncate">
              {{ $t(item.kind) }}
            </p>
          </div>
        </div>

        <!-- Three dots button -->
        <div class="hidden sm:block">
          <svn-dots-button
            :show="!isTrashed"
            circle
            variant="hoverNoPrimary"
            class="w-2 px-1 flex items-center justify-center"
          >
            <template #items>
              <v-list-item
                value="archive_target"
                @click="openPopUpArchive(route?.params?.id)"
              >
                {{ $t('Archive target') }}
              </v-list-item>

              <v-list-item
                v-if="isMobile === true"
                :disabled="attributeDisablement?.title"
                value="edit_title"
                @click="openPopUpEditTargetField(route?.params?.id, 'Title')"
              >
                {{ $t('Edit title') }}
              </v-list-item>

              <v-list-item
                v-if="isMobile === true"
                :disabled="attributeDisablement?.due_date"
                value="edit_due_date"
                @click="openPopUpEditTargetField(route?.params?.id, 'Due date')"
              >
                {{ $t('Edit due date') }}
              </v-list-item>

              <v-list-item
                v-if="isMobile === true"
                :disabled="attributeDisablement?.description"
                value="edit_description"
                @click="openPopUpEditTargetField(route?.params?.id, 'Description')"
              >
                {{ $t('Edit description') }}
              </v-list-item>

              <v-list-item
                value="delete"
                class="text-negativeRed"
                @click="openPopUpDelete(route?.params?.id)"
              >
                {{ $t('Delete') }}
              </v-list-item>
            </template>
          </svn-dots-button>
        </div>
      </div>

      <!-- MOBILE design, hidden à partir de sm -->
      <div class="flex flex-row justify-end items-center sm:hidden">
        <v-dialog
          v-model="lastUpdateDialog"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <template #activator="{ props }">
            <div
              v-bind="props"
              class="p-2 bkt-bg-light-grey9-hover cursor-pointer"
            >
              <Icon
                icon="mdi:clock-outline"
                height="24"
                width="24"
                color="darkGrey"
              />
            </div>
          </template>

          <!-- Target History -->
          <v-card>
            <div class="flex flex-col justify-between overflow-hidden">
              <!-- Main scrollable container -->
              <div class="flex flex-col h-full overflow-y-auto">
                <!-- Target list title -->
                <div class="w-full flex flex-col justify-center bg-white items-center pb-[25px] border-b border-b-fakeBlack">
                  <div
                    class="self-end mx-4 mt-4 mb-2 cursor-pointer"
                    @click="lastUpdateDialog = false"
                  >
                    <Icon
                      icon="mdi-close"
                      height="24"
                      width="24"
                      color="fakeBlack"
                    />
                  </div>

                  <div class="w-full">
                    <p class="text-xl text-center text-fakeBlack font-semibold">
                      {{ $t('Last events') }}
                    </p>
                  </div>
                </div>

                <!-- Target logs list -->
                <div class="w-full h-screen">
                  <target-logs
                    v-if="indicator"
                    :indicator="indicator"
                    :logs="logs"
                    :target-title="title"
                  />
                </div>
              </div>

              <!-- Add comment input -->
              <div class="h-[100px]">
                <v-textarea
                  v-model="commentToSend"
                  color="primary"
                  variant="plain"
                  :disabled="isTrashed"
                  :no-resize="true"
                  class="w-full bg-[#FFFFFF] pl-4 pr-6 py-2 border-t border-t-darkGrey"
                  hide-details
                  :label="$t('Add comment')"
                >
                  <template #append-inner>
                    <div
                      class="cursor-pointer"
                      @click.stop="addNewComment"
                    >
                      <v-icon
                        icon="mdi-send"
                        color="primary"
                        height="24"
                        width="24"
                      />
                    </div>
                  </template>
                </v-textarea>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <svn-dots-button
          :show="!isTrashed"
          circle
          variant="hoverNoPrimary"
          class="rotate-90 flex items-center justify-center"
        >
          <template #items>
            <v-list-item
              value="archive_target"
              @click="openPopUpArchive(route?.params?.id)"
            >
              {{ $t('Archive target') }}
            </v-list-item>

            <v-list-item
              v-if="isMobile === true"
              :disabled="attributeDisablement?.title"
              value="edit_title"
              @click="openPopUpEditTargetField(route?.params?.id, 'Title')"
            >
              {{ $t('Edit title') }}
            </v-list-item>

            <v-list-item
              v-if="isMobile === true"
              :disabled="attributeDisablement?.due_date"
              value="edit_due_date"
              @click="openPopUpEditTargetField(route?.params?.id, 'Due date')"
            >
              {{ $t('Edit due date') }}
            </v-list-item>

            <v-list-item
              v-if="isMobile === true"
              :disabled="attributeDisablement?.description"
              value="edit_description"
              @click="openPopUpEditTargetField(route?.params?.id, 'Description')"
            >
              {{ $t('Edit description') }}
            </v-list-item>

            <v-list-item
              value="delete"
              class="text-negativeRed"
              @click="openPopUpDelete(route?.params?.id)"
            >
              {{ $t('Delete') }}
            </v-list-item>
          </template>
        </svn-dots-button>
      </div>

      <!-- Target Infos -->
      <div class="flex flex-col px-6 py-4">
        <!-- Target title -->
        <div
          class="flex justify-start items-center w-full cursor-default min-h-8"
          @mouseover="isTitleHovered = true"
          @mouseleave="isTitleHovered = false"
        >
          <p class="text-base text-center sm:!text-start text-fakeBlack font-medium w-full">
            {{ title }}
          </p>
          <svn-icon-button
            v-if="!isTrashed && isTitleHovered"
            icon="material-symbols:edit-outline-rounded"
            variant="noOutlined"
            class="transition-all duration-300"
            @click="openPopUpEditTargetField(route?.params?.id, 'Title')"
            />
        </div>

        <!-- Target due date -->
        <div
          class="flex justify-center items-center w-full cursor-default mb-6 min-h-8"
          @mouseover="isDateHovered = true"
          @mouseleave="isDateHovered = false"
        >
          <p class="text-sm text-darkGrey font-normal w-full text-center sm:!text-start">
            {{ $t('Due date : ') }}
            {{ date ? $filters.formatSpecificDate(date, "YYYY-MM-DD", "DD MMM, YYYY") : $t('No due date') }}
          </p>

          <svn-icon-button
            v-if="!isTrashed && isDateHovered"
            icon="material-symbols:edit-outline-rounded"
            variant="noOutlined"
            class="transition-all duration-300"
            @click="openPopUpEditTargetField(route?.params?.id, 'Due date')"
          />
        </div>

        <!-- Target description -->
        <div
          class="flex flex-row w-full items-center mb-2 whitespace-pre-wrap"
          @mouseover="isDescHovered = true"
          @mouseleave="isDescHovered = false"
        >
          <div class="w-full p-0 -mr-6">
            <div
              v-if="descriptionWordCount >= 35"
              class="w-full p-0 m-0"
            >
              <p
                class="text-fakeBlack font-normal text-xs relative pr-1 text-center sm:!text-start"
                :class="{ 'whitespace-pre': !descSeeAll, 'line-clamp-6': !descSeeAll }"
              >
                {{ desc }}
                <span
                  class="absolute bg-white pl-3 right-0.5 -bottom-[0.3px] text-sm text-primary font-normal underline decoration-solid cursor-pointer w-18"
                  @click="descSeeAll = !descSeeAll"
                >
                  {{ descSeeAll ? $t('See less') : $t('See all') }}
                </span>
              </p>
            </div>

            <div v-else-if="descriptionWordCount === 0">
              <p class="text-sm text-center sm:!text-start text-fakeBlack font-normal w-full break-words">
                {{ $t('No description') }}
              </p>
            </div>

            <div
              v-else
              class="w-[90%]"
            >
              <p class="text-sm text-center sm:!text-start text-fakeBlack font-normal w-full break-words whitespace-pre-wrap">
                {{ desc }}
              </p>
            </div>
          </div>

          <svn-icon-button
            v-if="!isTrashed && isDescHovered"
            icon="material-symbols:edit-outline-rounded"
            variant="noOutlined"
            class="transition-all duration-300"
            @click="openPopUpEditTargetField(route?.params?.id, 'Description')"
            />
        </div>
      </div>

      <!-- Target Completion -->
      <div class="flex flex-col items-center px-6">
        <div class="mt-8">
          <p class="text-xl text-fakeBlack font-medium mb-[18px]">
            {{ $t("Completion") }}
          </p>
        </div>

        <!-- Indicator Progress Circular & Tooltip -->
        <svn-tooltip
          content-class="text-xs font-normal text-right"
          size="medium"
        >
          <template #activator-content>
            <svn-loader
              :model-value="getTargetModelValue(indicator?.indicator_type)"
              :indeterminate="false"
              :color="
                getTargetProgressColor(
                  indicator?.options?.starting_value,
                  indicator?.options?.current_value,
                  indicator?.options?.target_value,
                  indicator?.indicator_type
                  , indicator?.options)"
              :size="isMobile ? '120' : '200'"
              width="7"
            >
              <template #default >
                <div class="max-w-[79px] h-[76px] sm:max-w-[130px] flex flex-col justify-center">
                  <p
                    class="text-center font-medium leading-[18px]"
                    :class="
                      indicator?.indicator_type === 'number' || indicator?.indicator_type === 'percentage'
                        ? 'text-[24px]'
                        : 'text-base'
                    "
                  >
                    {{
                      (indicator?.indicator_type === 'number' || indicator?.indicator_type === 'percentage') && roadmapTarget?.histories.length === 0 ?
                        "0%" :
                        getTargetIndicatorValue(
                          indicator?.options?.starting_value,
                          indicator?.options?.current_value,
                          indicator?.options?.target_value,
                          indicator?.indicator_type
                        )
                    }}
                  </p>
                </div>
              </template>
            </svn-loader>
          </template>

          <template #tooltip-content>
            {{ getTooltipText(
              indicator?.options?.current_value,
              indicator?.options?.target_value,
              indicator?.indicator_type ) }}
          </template>
        </svn-tooltip>

        <div class="w-full grid grid-cols-3 gap-x-4 items-start mt-[55px] mb-8 py-2">
          <!-- Starting value -->
          <div class="flex flex-col justify-center items-center">
            <p class="text-sm text-fakeBlack text-center font-medium mb-4">
              {{ $t("Starting Value") }}
            </p>

            <p
              v-if="indicator?.indicator_type === 'percentage'"
              class="max-w-[105px] truncate overflow-ellipsis text-2xl text-middleGrey text-center font-medium"
            >
              {{ indicator?.options?.starting_value }}%
            </p>

            <p
              v-else-if="indicator?.indicator_type === 'multi_choice'"
              class="max-w-[105px] truncate overflow-ellipsis text-2xl text-middleGrey text-center font-medium"
            >
              {{ "-" }}
            </p>

            <p
              v-else
              class="max-w-[105px] truncate overflow-ellipsis text-2xl text-middleGrey text-center font-medium"
            >
              {{ indicator?.options?.starting_value }}
            </p>
          </div>

          <!-- Current value -->
          <div class="flex flex-col justify-center items-center">
            <p class="text-sm text-fakeBlack text-center font-medium mb-4">
              {{ $t("Current Value") }}
            </p>

            <p
              v-if="indicator?.indicator_type === 'multi_choice'"
              :class="['max-w-[105px] text-2xl truncate overflow-ellipsis', indicator?.options?.current_value !== undefined ? 'text-primary' : 'text-middleGrey', 'text-center', 'font-medium']"
            >
              {{ indicator?.options?.current_value !== undefined ? indicator?.options?.current_value : '-' }}
            </p>
            <p
              v-else
              :class="['max-w-[105px] text-2xl truncate overflow-ellipsis text-primary']"
            >
              {{ indicator?.options?.current_value || indicator?.options?.starting_value }}
            </p>
          </div>

          <!-- Target value -->
          <div class="flex flex-col justify-center items-center">
            <p class="text-sm text-fakeBlack text-center font-medium mb-4">
              {{ $t("Target Value") }}
            </p>

            <p
              v-if="indicator?.indicator_type === 'percentage'"
              class="max-w-[105px] text-2xl text-middleGrey text-center font-medium truncate overflow-ellipsis"
            >
              {{ indicator?.options?.target_value }}%
            </p>
            <div
              v-else-if="indicator?.indicator_type === 'multi_choice'"
              class="max-w-[105px] text-2xl text-center font-medium transition-all duration-300 truncate overflow-ellipsis"
              :class="targetHover ? 'text-primary' : 'text-middleGrey'"
              @mouseover="targetHover = true"
              @mouseleave="targetHover = false"
            >
              <p v-if="multiChoiceTargetValues && multiChoiceTargetValues.length > 0">
                <svn-tooltip
                  location="top end"
                  origin="bottom start"
                  content-class="text-xs font-normal text-right"
                  size="small long"
                >
                  <template #activator-content>
                    {{
                      multiChoiceTargetValues.length == 1
                        ? multiChoiceTargetValues[0]
                        : multiChoiceTargetValues.length + " " + $t("possible answers")
                    }}
                  </template>

                  <template #tooltip-content>
                    {{ multiChoiceTargetValues.join(', ') }}
                  </template>
                </svn-tooltip>
              </p>

              <p v-else>
                {{ $t("no target value") }}
              </p>
            </div>

            <p
              v-else
              class="max-w-[105px] text-2xl text-middleGrey text-center font-medium truncate overflow-ellipsis"
            >
              {{ indicator?.options?.target_value }}
            </p>
          </div>
        </div>
      </div>

      <!-- Update Answer Button -->
      <div class="flex flex-row justify-center items-center py-2 mb-8">
        <v-btn
          class="normal-case"
          color="primary"
          :text="$t('Update answer')"
          height="36"
          :disabled="isTrashed"
          @click="openEditTargetIndicatorAnswerPopUp"
        />
      </div>
    </div>

    <!-- Target logs -->
    <!-- Design DESKTOP: caché de base, flex à partir de sm -->
    <div
      id="target-history"
      class="w-full flex-col justify-between sm:w-[43%] sm:border-t sm:border-r sm:border-b sm:rounded-r-lg relative hidden sm:flex"
    >
      <!-- Target list title -->
      <div class="w-full flex flex-col justify-center min-h-[80px] items-center border-b border-b-fakeBlack">
        <p class="text-xl text-center text-fakeBlack font-medium">
          {{ $t("Last events") }}
        </p>
      </div>

      <!-- Target logs list -->
      <div class="w-full h-full overflow-y-auto">
        <target-logs
          v-if="indicator"
          :indicator="indicator"
          :logs="logs"
          :target-title="title"
        />
      </div>

      <!-- Add comment input -->
      <div class="w-full h-[100px]">
        <v-textarea
          v-model="commentToSend"
          color="primary"
          no-resize
          hide-details
          :disabled="isTrashed"
          :label="$t('Add comment')"
          variant="plain"
          class="bg-[#FFFFFF] pl-4 pr-6 py-2 text-dark-grey border-t border-t-darkGrey h-[100px] overflow-hidden"
        >
          <template #append-inner>
            <div
              class="cursor-pointer"
              @click.stop="addNewComment"
            >
              <v-icon
                icon="mdi-send"
                color="primary"
                height="24"
                width="24"
              />
            </div>
          </template>
        </v-textarea>
      </div>
    </div>

    <v-dialog
      v-model="isFullScreenTargetValues"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <v-spacer />

          <v-toolbar-title>Target values</v-toolbar-title>
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="isFullScreenTargetValues = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>

        <v-list
          v-for="option in multiChoiceTargetValues"
          :key="option"
        >
          <v-list-item
            class="text-center"
            :title="option"
          />
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import {usePopUpStore} from "@/store/pop-up";
import TargetLogs from "@/components/roadmapApp/target/TargetLogs.vue";
import i18n from "@/plugins/i18n.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import BktBreadcrumbs from "@/components/BktBreadcrumbs.vue";
import useTools from "@/tools/useTools.js";
import TrashWarnning from "@/components/TrashWarnning.vue"

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();

const { openPopUp, closePopUp } = usePopUpStore();
const { mapRange } = useTools()

const id = ref(null);
const title = ref(null);
const targetHover = ref(false)
const desc = ref(null);
const indicator = ref(null);
const date = ref(null);
const logs = ref(null);
const employee = ref(null);
const creator = ref(null);
const isSaveButtonDirty = ref(false);
const lastUpdateDialog = ref(false);
const commentToSend = ref(null);
const descriptionWordCount = ref(0);
const descriptionWordsArray = ref(null);
const cutDescription = ref("");
const attributeDisablement = ref({});
const emit = defineEmits(["update"]);
const isFullScreenTargetValues = ref(null);
const isTitleHovered = ref(false)
const isDateHovered = ref(false)
const isDescHovered = ref(false)
const descSeeAll = ref(false)
const isTrashed = ref(false);

const { fetchRoadmapTarget, restoreRoadmapTarget, deletePermanentlyRoadmapTarget } = useRoadmapTargetStore();
const { roadmapTarget } = storeToRefs(useRoadmapTargetStore());
const { isMobile } = storeToRefs(useMobileStore());

onMounted(() => {
  build();
});

watch(roadmapTarget, (newVal, _) => {
  mergeToCurrentTarget(newVal);
});

const build = async () => {
  const entity = await fetchRoadmapTarget(route.params.id);
  mergeToCurrentTarget(entity);

  descriptionWordCount.value = desc.value?.split(" ").filter((word) => {
    return word !== "";
  }).length;
  descriptionWordsArray.value = desc.value.split(" ");

  for (let i = 0; i < 75; i++) {
    cutDescription.value += descriptionWordsArray.value[i] + " ";
  }
};

const multiChoiceTargetValues = computed(() => {
  if (indicator.value && indicator.value.indicator_type === "multi_choice") {
    var selectedOptions = indicator.value?.options?.multi_choice_list?.filter(function (option) {
      return option.checkbox === true;
    });

    // Extract and return the option values
    var selectedValues = selectedOptions.map(function (option) {
      return option.option;
    });

    return selectedValues;
  } else {
    return [];
  }
});

const mergeToCurrentTarget = (target) => {
  id.value = target.id;
  title.value = target.title;
  isTrashed.value = target.status === 'trashed'
  desc.value = target.description;
  date.value = target.due_date;
  employee.value = target.objectivable;
  attributeDisablement.value = target.attribute_disablement;
  creator.value = target.creator;
  logs.value = target.histories.map((log) => ({...log, displayed: false}));
  indicator.value = target.objective_indicator;

  watch(title, activeSaveButton);
  watch(desc, activeSaveButton);
  watch(date, activeSaveButton);
};

const activeSaveButton = () => {
  isSaveButtonDirty.value = true;
};

const openPopUpArchive = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: "Are you sure you want to archive this target ?",
    subtitle: 'You will find this target in the "Archived targets" tab.',
    icon: "ic-outline-archive",
    textClose: "No, cancel",
    textConfirm: "Yes, archive this target",
    color: "blue",
    customClass: "",
    async confirm() {
      try {
        await axiosService.put(`/api/v1/roadmaps/targets/${id}`, { status: "archived" });
        closePopUp();
        emit("update");
        await router.push({ name: "my_roadmaps" });
        snackbar.setBgColor("fakeBlack").displaySnackBar("Your target has been archived.");
      } catch (error) {
        snackbar.setBgColor("negativeRed").displaySnackBar("Error while archiving your target !");
      }
    },
  });
};

const openPopUpDelete = async (id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: "Are you sure you want to delete this target ?",
    icon: "mdi-trash-can-outline",
    textClose: "No",
    textConfirm: "Yes, delete",
    color: "red",
    customClass: "",
    async confirm() {
      try {
        await axiosService.delete(`/api/v1/roadmaps/targets/${id}`);
        closePopUp();
        emit("update");
        snackbar.setBgColor("fakeBlack");
        snackbar.setMsg("Your target has been deleted.");
        snackbar.displaySnackBar();
        await router.push({ name: "my_roadmaps" });
      } catch (error) {
        snackbar.setBgColor("negativeRed");
        snackbar.setMsg("Error while deleting your target !");
        snackbar.displaySnackBar();
      }
    },
  });
};

const getTargetProgressColor = (startingValue, currentValue, targetValue, indicatorType) => {
  if (indicatorType === "numeric_value" || indicatorType === "percentage") {
    if (parseInt(startingValue) === parseInt(currentValue)) {
      return "negativeRed";
    } else if (mapRange(currentValue, startingValue, targetValue, 0, 100) >= 100) {
      return "positiveGreen";
    } else
      return "mediumOrange";
  } else if (indicatorType === "boolean") {
    if (currentValue === targetValue) {
      return "positiveGreen";
    } else {
      return "mediumOrange";
    }
  }
  else if (indicatorType === 'multi_choice') {
    if (!currentValue || currentValue === '') {
      return 'darkGrey';
    }
    else if (checkIfOptionSelected()) {
      return 'positiveGreen';
    }
    else if (!checkIfOptionSelected()) {
      return 'mediumOrange';
    }
  } else {
    if (currentValue === "") {
      return "darkGrey";
    } else if (currentValue !== "" && !multiChoiceTargetValues.value.includes(currentValue)) {
      return "mediumOrange";
    } else {
      return "positiveGreen";
    }
  }
};

const getTargetModelValue = (indicatorType) => {
  if (indicatorType === "numeric_value" || indicatorType === "percentage") {
    if (indicator?.value?.options?.current_value === undefined || !indicator?.value?.options?.current_value) {
      return 0;
    }
    return mapRange(
      indicator?.value?.options?.current_value,
      indicator?.value?.options.starting_value,
      indicator?.value?.options.target_value,
      0,
      100)
  }
  else {
    return 100;
  }
};

const checkIfOptionSelected = () => {
  const multiChoiceList = indicator?.value?.options?.multi_choice_list;

  // Iterate over the multi_choice_list
  for (var i = 0; i < multiChoiceList.length; i++) {
    var option = multiChoiceList[i];
    if (option.checkbox && option.option === indicator?.value?.options?.current_value) {
      return true; // Option matches and checkbox is true
    }
  }

  return false; // Option not found or checkbox is false
};

const getTargetIndicatorValue = (startingValue, currentValue, targetValue, indicatorType) => {
 if (indicatorType === 'numeric_value' || indicatorType === 'percentage') {
    if (currentValue === undefined || !currentValue) {
      return '0%';
    } else {
      return `${Math.round(mapRange(currentValue, startingValue, targetValue, 0, 100))}%`
    }
  }
  else if (indicatorType === 'boolean') {
    if (currentValue === targetValue) {
      return i18n.global.t("Target completed!");
    } else {
      return i18n.global.t("Target not achieved yet");
    }
  } else if (indicatorType === "multi_choice") {
    if (currentValue === undefined || !currentValue) {
      return i18n.global.t("Not set yet");
    }
    if (!checkIfOptionSelected()) {
      return i18n.global.t("Target not achieved yet");
    } else if (checkIfOptionSelected()) {
      return i18n.global.t("Target completed!");
    } else {
      return currentValue;
    }
  }
};

const getTooltipText = (currentValue, targetValue, indicatorType) => {
  if (indicatorType === "numeric_value") {
    if (parseInt(targetValue) !== parseInt(currentValue)) {
      return i18n.global.t("left to complete your target!", { value: parseInt(targetValue) - parseInt(currentValue) });
    } else {
      return i18n.global.t("Target completed!");
    }
  } else if (indicatorType === "percentage") {
    if (parseInt(targetValue) !== parseInt(currentValue)) {
      return i18n.global.t("percent left to complete your target!", {
        value: parseInt(targetValue) - parseInt(currentValue),
      });
    } else {
      return i18n.global.t("Target completed!");
    }
  } else if (indicatorType === "boolean") {
    if (targetValue !== currentValue) {
      return i18n.global.t("The current answer does not correspond to the target value.");
    } else if (currentValue === targetValue) {
      return i18n.global.t("Target completed!");
    }
  } else if (indicatorType === "multi_choice") {
    if (!currentValue || currentValue === "") {
      return i18n.global.t('You have not replied yet. Start answering by clicking on "Update answer".');
    } else if (!multiChoiceTargetValues.value.includes(currentValue)) {
      return i18n.global.t("The current answer does not correspond to the target value.");
    } else {
      return i18n.global.t("Target completed!");
    }
  }
};

const openEditTargetIndicatorAnswerPopUp = () => {
  openPopUp({
    componentName: "pop-up-edit-target-indicator-answer",
    title: title.value,
    indicator: indicator.value,
    targetId: route.params?.id,
    icon: "",
    logs: logs.value,
    employee: employee.value,
    targetName: title.value,
    customClass: "lg:w-[800px]",
  });
};

const addNewComment = async () => {
  try {
    await axiosService.patch(`/api/v1/roadmaps/indicators/${indicator.value?.id}`, {
      current_value: indicator?.value?.options?.current_value,
      comment: commentToSend.value,
    });
    commentToSend.value = null;
    snackbar.setMsg("Your comment has been added!");
    snackbar.setBgColor("success");
    snackbar.displaySnackBar();
    build();
  } catch (e) {
    snackbar.setMsg("Error! Your comment could not be saved.");
    snackbar.setBgColor("error");
    snackbar.displaySnackBar();
  }
};

const openPopUpEditTargetField = (targetId, field) => {
  openPopUp({
    componentName: "pop-up-edit-target-field",
    title: "",
    subtitle: "",
    icon: "mdi-pencil-outline",
    targetId: targetId,
    targetField: field,
    customClass: "",
    callback: async () => {
      await build();
    },
  });
};

const openPopUpTargetFullDescription = (description) => {
  openPopUp({
    componentName: "pop-up-target-full-description",
    title: "",
    subtitle: "",
    icon: "",
    desc: description,
    customClass: "",
  });
};

const restoreDeletedItem = async () => {
  try {
    await restoreRoadmapTarget(id.value)
    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Target restored successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    isTrashed.value = false
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while restoring this target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deleteTarget = async () => {
  try {
    await deletePermanentlyRoadmapTarget(id.value)
    snackbar.setBgColor('fakeBlack')
    snackbar.setMsg('Target deleted successfully')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
    if (route?.query?.from) {
      await router.push({ name: route?.query?.from });
    } else {
      await router.push({ name: 'my_roadmaps' });
    }
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error while deleting permanently a target')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>

:deep(.v-field__append-inner) {
  display: flex;
  align-items: center!important;
  padding: 0px!important;
}

:deep(.v-field__input) {
  max-height: 90px;
}
</style>
