<template>
  <pop-up-skeleton :show-close="false">
    <template #title>
      <p class="text-xl font-medium w-3/4 text-center">
        {{ $t('If you confirm, you will not be able to participate anymore.') }}
      </p>
    </template>
    <template #subtitle>
      <p
        class="mr-1 text-sm font-medium"
        v-html="$t('This survey will no longer appear in your My interviews section.')"
      />
    </template>

    <template #buttons>
      <div class="w-full flex flex-col justify-center sm:flex-row">
        <v-btn
          variant="outlined"
          color="black"
          class="sm:w-1/2 text-fakeBlack border-darkGrey border-1 normal-case font-normal mb-3 sm:mb-0 sm:mr-4"
          @click="closePopUp"
        >
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          variant="outlined"
          class="sm:w-1/2 text-white bg-negativeRed normal-case font-normal mb-3 sm:mb-0"
          @click="doNotParticipate"
        >
          {{ $t("Yes, confirm") }}
        </v-btn>
      </div>
    </template>
  </pop-up-skeleton>
</template>

<script setup>
import {usePopUpStore} from "@/store/pop-up.js";
import PopUpSkeleton from "./PopUpSkeleton.vue";
import {useMyInterviewsStore} from "@/store/my-interviews.js";
import {useSnackbar} from "@/store/snackbar.js";
import axios from "axios";
import {storeToRefs} from 'pinia'

const props = defineProps({
  id: { type: Number, required: true, default: null },
});

const snackbar = useSnackbar();

const { closePopUp } = usePopUpStore();

const { fetchMyInterviews } = useMyInterviewsStore();

const {interviews, interviewsToDo, interviewsDone, interviewsMeta, interviewsToDoMeta, interviewsDoneMeta}  = storeToRefs(useMyInterviewsStore())

async function doNotParticipate() {
  try {
    await axios.delete(`/api/v2/interview_app/interviews/${props.id}.json`);

    const idx = interviews.value.findIndex((inter) => (inter.interview_id == props.id))
    const idxToDo = interviewsToDo.value.findIndex((inter) => (inter.interview_id == props.id))
    const idxDone = interviewsDone.value.findIndex((inter) => (inter.interview_id == props.id))

    if (idx !== -1) {
      interviews.value.splice(idx, 1)
      if (interviewsMeta?.value?.total_count) {
        interviewsMeta.value.total_count -= 1
      }
    }
    if (idxToDo !== -1) {
      interviewsToDo.value.splice(idxToDo, 1)
      if (interviewsToDoMeta?.value?.total_count) {
        interviewsToDoMeta.value.total_count -= 1
      }
    }
    if (idxDone !== -1) {
      interviewsDone.value.splice(idxDone, 1)
      if (interviewsDoneMeta?.value?.total_count) {
        interviewsDoneMeta.value.total_count -= 1
      }
    }
    // fetchMyInterviews();
  } catch (e) {
    snackbar.setMsg("Error deleting interview !");
    snackbar.setDefaultColor("failed");
    snackbar.setTimer(1500);
    snackbar.displaySnackBar(snackbar.msg, snackbar.timeout);
  } finally {
    closePopUp();
  }
}
</script>
