<template>
  <v-data-table
    v-if="campaignDrafts"
    class="mt-12"
    :headers="headers"
    :items="campaignDrafts"
    :loading="loading"
    :items-per-page="-1"
  >
    <template #headers>
      <tr>
        <td
          v-for="header in headers"
          :key="header.key"
          class="bg-veryLightGrey text-darkGrey font-semibold"
        >
          <div class="flex flex-row items-center">
            <Icon
              :icon="header.icon"
              width="16"
              height="16"
              class="mr-1"
            />
            <span>{{ header.title }}</span>
          </div>
        </td>
      </tr>
    </template>

    <template #item="{ item }">
      <tr
        class="cursor-pointer bkt-bg-light-grey10-hover"
      >
        <td>
          <p
            class="w-full flex flex-row justify-start items-center text-sm font-normal mr-2 overflow-ellipsis"
            :title="item.raw?.title"
          >
            {{ item.raw?.title }}
            <Icon
              v-if="item?.raw?.is_recurrent"
              class="iconify mx-1"
              icon="ic:baseline-event-repeat"
              color="darkGrey"
              width="16"
              height="16"
            />
          </p>
        </td>

        <td>
          <div class="flex items-center w-[200px]">
            <p class="text-sm font-medium">
              {{ $filters.formatSpecificDate(item.raw?.startline, "YYYY-MM-DD", "DD MMM, YYYY") }}
            </p>
            <Icon
              class="iconify mx-1"
              icon="bi:arrow-right"
            />
            <p class="text-sm font-normal">
              {{ $filters.formatSpecificDate(item.raw?.deadline, "YYYY-MM-DD", "DD MMM, YYYY") }}
            </p>
          </div>
        </td>

        <td>
          <p class="text-sm font-normal">
            {{ item.raw?.employees_count }}
          </p>
        </td>

        <td>
          <div
            class="w-[165px] px-2 !rounded bg-veryLightGrey border border-middleGrey text-center"
          >
            <p class="px-2 py-1 text-sm font-medium text-middleGrey">
              {{ $t('Not launched yet') }}
            </p>
          </div>
        </td>

        <td>
          <div class="flex items-center">
            <p class="text-sm font-normal">
              {{ getCampaignStartTimeline(item?.raw?.startline_datetime) }}
            </p>
          </div>
        </td>

        <td class="pa-0">
          <div class="flex justify-end items-end">
            <svn-dots-button
              table
              circle
              variant="hoverNoPrimary"
            >
              <template #items>
                <v-list-item
                  v-if="!item?.raw?.is_recurrent"
                  value="edit_starting_line"
                  @click="EditCampaignStartline(item.raw?.id, item?.raw?.startline)"
                >
                  {{ $t('Edit starting date') }}
                </v-list-item>

                <v-list-item
                  v-if="item?.raw?.is_recurrent"
                  value="manage_recurrence"
                  @click="manageRecurrence(item.raw?.id)"
                >
                  {{ $t('Manage recurrence') }}
                </v-list-item>

                <v-list-item
                  value="delete_and_stop_recurrence"
                  class="text-negativeRed"
                  @click="deleteCampaignAndStopRecurrence(item.raw?.id)"
                >
                  {{ item?.raw?.is_recurrent ? $t('Delete campaign and stop recurrence') : $t('Delete') }}
                </v-list-item>
              </template>
            </svn-dots-button>
          </div>
        </td>
      </tr>
    </template>

    <template #bottom>
      <v-pagination
        v-if="pagination && campaignDrafts && campaignDrafts.length > 0"
        :model-value="pagination.current_page"
        :length="pagination.total_pages"
        :total-visible="6"
        theme="primary"
        variant="text"
        class="mt-5 !list-none"
        @update:model-value="fetchNewPage"
      >
        <template #prev>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page > 1 ? fetchNewPage(pagination?.current_page - 1) : '')"
          >
            <Icon
              icon="mdi-arrow-left"
              width="20"
              height="20"
              class="mr-2"
            />
            <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
          </div>
        </template>
        <template #next>
          <div
            class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
            @click="(pagination?.current_page < pagination?.total_pages ?
              fetchNewPage(pagination?.current_page + 1) : '')"
          >
            <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>
            <Icon
              icon="mdi-arrow-right"
              width="20"
              height="20"
              class="ml-2"
            />
          </div>
        </template>
      </v-pagination>
    </template>
  </v-data-table>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { useCampaignDraftStore } from "@/store/campaign-draft";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { usePopUpStore } from "@/store/pop-up.js";
import useTools from "@/tools/useTools.js";
import i18n from "@/plugins/i18n";
import moment from "moment";
import axiosService from "@/tools/axios-service.js";

const headers = [
  {
    align: "start",
    key: "campaign_title",
    title: i18n.global.t("Campaign title"),
    icon: "mdi-format-text",
    sortable: false,
    width: 300,
  },
  {
    align: "start",
    key: "period",
    title: i18n.global.t("Period"),
    icon: "akar-icons:calendar",
    sortable: false,
    width: 50,
  },
  {
    align: "start",
    key: "participants",
    title: "Participants",
    icon: "mdi-account-outline",
    sortable: false,
    width: 50,
  },
  {
    align: "start",
    key: "completion",
    title: "Completion",
    icon: "ic:baseline-percent",
    sortable: false,
    width: 100,
  },
  {
    align: "start",
    key: "will_start_on",
    title: i18n.global.t("Will start"),
    icon: "mdi:calendar-start",
    sortable: false,
    width: 150,
  },
  {
    align: "start",
    key: "",
    title: " ",
    icon: " ",
    sortable: false,
    width: 50,
  }
];

const { campaignDrafts, pagination, loading } = storeToRefs(useCampaignDraftStore());
const { fetchCampaignDrafts, setCampaignDrafts, updateParams } = useCampaignDraftStore();
const { fetchPage } = useTools();
const snackbar = useSnackbar();
const { closePopUp, openPopUp } = usePopUpStore();

const deleteCampaignAndStopRecurrence = async(id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t("Stop the repetition of the recurring campaign ?"),
    subtitle: i18n.global.t("This is a permanent action. All future campaigns will be deleted."),
    textClose: "No, cancel",
    textConfirm: "Yes, stop",
    textLoading: "Deleting ...",
    icon: "mdi-alert-circle",
    color: "red",
    customClass: "w-[400px]",
    showClose: false,
    async confirm() {
      try {
        await axiosService.delete(`/api/v2/interview_app/campaign_drafts/${id}`);
      } catch (e) {
        snackbar.setMsg(i18n.global.t('Error deleting campaign !'));
        snackbar.setBgColor('error');
        snackbar.displaySnackBar();
      } finally {
        fetchPage(updateParams, fetchCampaignDrafts, pagination.value.current_page);
        closePopUp();
      }
    },
  });
}

const deleteCampaign = async(id) => {
  openPopUp({
    componentName: "pop-up-validation",
    title: i18n.global.t("Are you sure you want to delete this campaign ?"),
    subtitle: i18n.global.t("This is a permanent action."),
    textClose: "No, cancel",
    textConfirm: "Yes, delete this campaign",
    textLoading: "Deleting ...",
    icon: "mdi-trash-can-outline",
    color: "red",
    customClass: "w-[400px]",
    showClose: false,
    async confirm() {
      try {
        await axiosService.delete(`/api/v2/interview_app/campaign_drafts/${id}`);
      } catch (e) {
        snackbar.setMsg(i18n.global.t('Error deleting campaign !'));
        snackbar.setBgColor('error');
        snackbar.displaySnackBar();
      } finally {
        fetchPage(updateParams, fetchCampaignDrafts, pagination.value.current_page);
        closePopUp();
      }
    },
  });
};

const getCampaignStartTimeline = (date) => {
  const today = moment(new Date());
  const startline = moment(date);
  const dayDifference = startline.diff(today, 'days') + 1;
  // true if it is today or false if it's not
  const isToday = moment(date).isSame(moment(), 'day')

  if (isToday) {
    return (i18n.global.t('Today'));
  }
  else if (dayDifference <= 1) {
    return (i18n.global.t('Tomorrow'));
  }
  else if (dayDifference > 1 && dayDifference < 7) {
    return (i18n.global.t('In day(s)', { n: dayDifference }));
  }
  else if (dayDifference === 7) {
    return (i18n.global.t('In week(s)', { n: 1 }));
  }
  else if (dayDifference > 7 && dayDifference < 28) {
    return (i18n.global.t('In week(s)', { n: startline.diff(today, 'weeks') }));
  }
  else if (dayDifference >= 28 && dayDifference <= 32) {
    return (i18n.global.t('In month(s)', { n: 1 }));
  }
  else if (dayDifference >= 28 && dayDifference < 365) {
    return (i18n.global.t('In month(s)', { n: startline.diff(today, 'months') }));
  }
  else if (dayDifference >= 366) {
    return (i18n.global.t('In over a year'));
  }
}

const EditCampaignStartline = (campaignId, currentStartline) => {
  openPopUp({
    componentName: 'pop-up-campaign-edit-startline',
    title: i18n.global.t('Edit starting date'),
    campaignId: campaignId,
    startline: currentStartline,
  })
}

const manageRecurrence = async(campaignId) => {
  openPopUp({
    componentName: 'pop-up-manage-recurrence',
    title: i18n.global.t('Manage recurrence'),
    campaignDraftId: campaignId,
  })
}

const fetchNewPage = (e) => {
  setCampaignDrafts(null);
  updateParams({
    "page[number]": e,
  });
  fetchCampaignDrafts();
};
</script>
