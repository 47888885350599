<template>
  <bkt-pop-up-dialog-skeleton
    ref="dialogModuleCheckUnderstanding"
    icon="noto:bell"
    :bind="bind"
    :title="learnModule?.has_learn_pieces_quiz ? `Don't forget to take the quiz!` :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        'Don\'t forget the face-to-face evaluation!' :
          'Did you learn all the following learning objectives?'"
    :primary-button-text="learnModule?.has_learn_pieces_quiz ? 'Start the quiz now' : learnModule?.has_learn_pieces_face_to_face_evaluation ?
      'Start evaluation now' : 'Yes, it\'s all clear to me'"
    :secondary-button-text="(learnModule?.has_learn_pieces_quiz || learnModule?.has_learn_pieces_face_to_face_evaluation) ? 'I will do it later' : 'No, I will come back later'"
    :vertical="isMobile"
    :width="isMobile ? 300 : 400"
    @click-secondary-button="goToNextModule"
  >
    <template #button>
      <svn-button
        append-icon="mdi:arrow-forward"
        variant="plain"
      >
        <template #button-text>
          {{ $t('Go to next module') }}
        </template>
      </svn-button>
    </template>

    <template #description>
      <div v-if="learnModule?.has_learn_pieces_approval">
        <div
          v-html="learnApprovalInput?.text ||  $t('Not set')"
          class="font-medium text-base text-darkGrey leading-6 whitespace-pre-wrap"
        />
      </div>

      <div v-else-if="learnModule?.has_learn_pieces_quiz">
        {{ $t('To mark this module as “acquired”, you must pass the assessment quiz.') }}
      </div>

      <div v-else>
        {{ $t("To mark this module as “acquired”, you must pass the face-to-face evaluation.") }}
      </div>
    </template>

    <template #primary-button>
      <v-btn
        v-if="learnModule?.has_learn_pieces_quiz"
        class="normal-case text-xs w-full sm:w-fit"
        color="primary"
        variant="flat"
        @click="openQuiz"
      >
        {{ $t('Start the quiz now') }}
      </v-btn>

      <v-btn
        v-else-if="learnModule?.has_learn_pieces_face_to_face_evaluation"
        class="normal-case text-xs w-full sm:w-fit"
        color="primary"
        variant="flat"
        @click="startEvaluationFaceToFace"
      >
        {{ $t('Start evaluation now') }}
      </v-btn>

      <v-btn
        v-else-if="learnModule?.has_learn_pieces_approval"
        class="normal-case text-xs w-full sm:w-fit"
        color="primary"
        variant="flat"
        @click="clearToMe"
      >
        {{ $t("Yes, it's all clear to me!") }}
      </v-btn>
    </template>
  </bkt-pop-up-dialog-skeleton>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import BktPopUpDialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue";
import { useLearnModuleStore } from "@/store/learn-module";

const props = defineProps({
  bind: { type: Boolean, default: true },
  nextModuleId: { type: Number, default: null },
})

const { isMobile } = storeToRefs(useMobileStore())
const { learnModule, learnApprovalInput } = storeToRefs(useLearnModuleStore())

const emit = defineEmits(['open-quiz','recall-mounted-method', 'start-evaluation-face-to-face', 'clear-to-me'])

const router = useRouter()
const route = useRoute()

const dialogModuleCheckUnderstanding = ref(null)

const goToNextModule = () => {
  closeDialog()

  if (props.nextModuleId) {
    let query

    if (route?.query.trainingId) {
      query = { trainingId: route?.query?.trainingId }

    } else if (route?.query.playlistId) {
      query = { playlistId: route?.query?.playlistId }
    }

    router.push({ name: 'module_show', params: { id: props.nextModuleId }, query }).then(() => {
      emit('recall-mounted-method')
    })
  }

  else {
    router.push({ name: 'my_learnings' }).then(() => {})
  }
}

const closeDialog = () => {
  dialogModuleCheckUnderstanding.value.dialog = false
}

const startEvaluationFaceToFace = () => {
  emit('start-evaluation-face-to-face');
  closeDialog()
}

const clearToMe = () => {
  emit('clear-to-me');
  goToNextModule()
}

const openQuiz = async () => {
  emit('open-quiz')
  closeDialog()
}
</script>
