<template>
  <div>
    <v-text-field
      clearable
      :label="$t('Search')"
      class="!w-full"
      variant="outlined"
      hide-details
      density="compact"
      prepend-inner-icon="mdi-magnify"
      @click:clear="clear"
      @input="update"
    />
  </div>
</template>

<script setup>
const emit = defineEmits(['input', 'clear'])
defineProps({
  value: {type: String, default: null}
})

let timer = null

const update = (e) => {
  if (timer) clearTimeout(timer)

  timer = setTimeout(() => {
    emit('input', e.target.value)
  }, 300)
}

const clear = () => {
  emit('clear')
}
</script>