<template>
  <div
    v-if="user.id"
    class="w-full h-full flex flex-col justify-center items-center p-5 sm:gap-10"
  >
    <div />

    <div class="w-full h-full overflow-y-auto max-w-[1136px]">
      <div class="flex flex-col gap-6 md:gap-4 items-center">
        <div class="w-full flex flex-col md:flex-row gap-6 md:gap-4 items-center md:items-start">
          <div class="w-full flex flex-col gap-4">
            <svn-profile
              :firstname="user?.firstname"
              :lastname="user?.lastname"
              :email="user?.email"
              :avatar="user.avatar"
              :avatar-size="isMobile ? 'xl' : '2xl'"
              :tags="userTags"
              :edit="true"
              @upload-file="uploadAvatar"
              @delete-picture="deletePicture"
            />

            <!-- Badges -->
            <div v-if="applications?.includes('trainings')" class="w-full flex gap-2">
              <svn-tooltip
                v-for="(item, index) in badges"
                :key="index"
                :tooltip-text="getTooltipText(item?.category)"
              >
                <template #activator-content>
                  <div class="relative cursor-pointer" @click="openModalUserBadges">
                    <v-img
                      class="w-10 h-10"
                      cover
                      :src="getBadgeBase(item?.category)"
                    />

                    <v-img
                      cover
                      :src="getBadgeIcon(item?.category)"
                      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[15px] h-[15px]"
                      data-testid="icon"
                    />
                  </div>
                </template>
              </svn-tooltip>
            </div>
          </div>
          
          <svn-button
            variant="outlined"
            :text="$t('Settings')"
            prepend-icon="material-symbols:settings-outline"
            class="w-full md:w-fit"
            @click="openModalSettings"
          />

          <modal-user-settings
            ref="modalSettings"
            :user="user"
            :update-user-profile="updateUserProfile"
          />
        </div>

        <v-tabs v-model="page" grow class="border-b-fake-black border-b">
          <v-tab
            v-for="tab in allTabs"
            :key="tab"
            :value="tab.value"
            class="pt-2 md:w-[140px]"
          >
            <div
              class="flex items-center gap-2 pb-3"
              :class="tab.value === page ? 'text-fake-black' : 'text-dark-grey'"
            >
              <Icon
                :icon="tab.icon"
                width="24"
                height="24"
              />
  
              <p class="normal-case">
                {{ $t(tab.text) }}
              </p>
            </div>
          </v-tab>
        </v-tabs>
      </div>

      <v-window v-model="page" class="w-full">
        <!-- Interviews -->
        <v-window-item
          value="interviews"
          class="flex flex-col justify-center items-center md:pt-8 md:pb-6 md:gap-4"
        >
          <bkt-interviews-in-profile :user="user"/>
        </v-window-item>

        <!-- Learnings -->
        <v-window-item
          value="learnings"
          class="flex flex-col justify-center items-center md:pt-8 md:pb-6 md:gap-4"
        >
          <bkt-learnings-in-profile :user="user"/>
        </v-window-item>

        <!-- Targets -->
        <v-window-item
          value="targets"
          class="flex flex-col justify-center items-center md:pt-8 md:pb-6 md:gap-4"
        >
          <bkt-targets-in-profile :user="user"/>
        </v-window-item>
      </v-window>
    </div>

    <modal-user-badges ref="modalUserBadges" :user-badges="user.badges"/>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import axios from "../../tools/axios-service.js";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import BktInterviewsInProfile from "@/components/BktInterviewsInProfile.vue";
import BktLearningsInProfile from "@/components/BktLearningsInProfile.vue";
import BktTargetsInProfile from "@/components/BktTargetsInProfile.vue";
import caseStyles from "@/tools/caseStyles.js";
import { useCompanyStore } from "@/store/company.js";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n";
import { useRoute, useRouter } from "vue-router";
import ModalUserSettings from "@/components/BktPopUp/Modals/ModalUserSettings.vue";
import ModalUserBadges from "@/components/BktPopUp/Modals/ModalUserBadges.vue";
import BaseCompleted from '@/assets/images/badges/base_completed.svg';
import BaseOngoing from '@/assets/images/badges/base_ongoing.svg';
import OpenBookCompleted from '@/assets/images/badges/open_book_completed.svg';
import OpenBookOngoing from '@/assets/images/badges/open_book_ongoing.svg';
import PuzzleCompleted from '@/assets/images/badges/puzzle_completed.svg';
import PuzzleOngoing from '@/assets/images/badges/puzzle_ongoing.svg';
import ThumbCompleted from '@/assets/images/badges/thumb_completed.svg';
import ThumbOngoing from '@/assets/images/badges/thumb_ongoing.svg';
import HeartCompleted from '@/assets/images/badges/heart_completed.svg';
import HeartOngoing from '@/assets/images/badges/heart_ongoing.svg';

onMounted(async () => {
  page.value = route?.query?.page
  await updateUserProfile()
  userTags.value = user?.value?.tags?.map(tag => tag.tagName)
});

const { applications } = storeToRefs(useCompanyStore());
const { user: storeUser } = storeToRefs(useUserStore());
const { isMobile } = storeToRefs(useMobileStore());

const snackbar = useSnackbar();
const route = useRoute();
const router = useRouter();
const { getUserById } = useUserStore();

const page = ref("interviews")
const modalUserBadges = ref(null);
const modalSettings = ref(null);
const userTags = ref([])
const badges = ref([
  {
    category: "acquire_a_module",
  },
  {
    category: "react_to_a_module",
  },
  {
    category: "complete_a_training",
  },
  {
    category: "favorite_a_module",
  },
]);

watch(page, (newVal, _) => {
  const id = user.value?.id

  router.push({
    name: 'people_show',
    params: { id },
    query: { page: newVal }
  })
})

watch(route, () => {
  if (route.name == 'people_show') {
    page.value = route?.query?.page
  }
})

watch(storeUser, (newVal, _) => {
  if (newVal) {
    userTags.value = newVal?.tags?.map(tag => tag.tagName)
  }
})

const getBadgeBase = (category) => {
  switch (category) {
    case 'acquire_a_module':
      if (storeUser?.value?.completed_learn_modules_count) {
        return BaseCompleted
      } else {
        return BaseOngoing
      }
    case 'react_to_a_module':
      if (storeUser?.value?.reaction_learn_module_count) {
        return BaseCompleted
      } else {
        return BaseOngoing
      }
    case 'complete_a_training':
      if (storeUser?.value?.completed_learn_training_count) {
        return BaseCompleted
      } else {
        return BaseOngoing
      }
    case 'favorite_a_module':
      if (storeUser?.value?.favorite_learn_module_count) {
        return BaseCompleted
      } else {
        return BaseOngoing
      }
  }
}

const getBadgeIcon = (category) => {
  switch (category) {
    case 'acquire_a_module':
      if (storeUser?.value?.completed_learn_modules_count) {
        return PuzzleCompleted
      } else {
        return PuzzleOngoing
      }
    case 'react_to_a_module':
      if (storeUser?.value?.reaction_learn_module_count) {
        return ThumbCompleted
      } else {
        return ThumbOngoing
      }
    case 'complete_a_training':
      if (storeUser?.value?.completed_learn_training_count) {
        return OpenBookCompleted
      } else {
        return OpenBookOngoing
      }
    case 'favorite_a_module':
      if (storeUser?.value?.favorite_learn_module_count) {
        return HeartCompleted
      } else {
        return HeartOngoing
      }
  }
}

const getTooltipText = (category) => {
  switch (category) {
    case 'acquire_a_module':
      if (storeUser?.value?.completed_learn_modules_count < 5) {
        return "Enlightened Novice"
      } else if (storeUser?.value?.completed_learn_modules_count >= 5 && storeUser?.value?.completed_learn_modules_count < 10) {
        return "Knowledge Explorer"
      } else if (storeUser?.value?.completed_learn_modules_count >= 10 && storeUser?.value?.completed_learn_modules_count < 25) {
        return "Thirsty for Knowledge"
      } else if (storeUser?.value?.completed_learn_modules_count >= 25) {
        return "Curriculum Master"
      }
    case 'react_to_a_module':
      if (storeUser?.value?.reaction_learn_module_count < 5) {
        return "The Approver"
      } else if (storeUser?.value?.reaction_learn_module_count >= 5 && storeUser?.value?.reaction_learn_module_count < 10) {
        return "Positive Supporter"
      } else if (storeUser?.value?.reaction_learn_module_count >= 10 && storeUser?.value?.reaction_learn_module_count < 25) {
        return "Enlightened Evaluator"
      } else if (storeUser?.value?.reaction_learn_module_count >= 25) {
        return "Master of Emotions"
      }
    case 'complete_a_training':
      if (storeUser?.value?.completed_learn_training_count < 5) {
        return "Accomplished Apprentice"
      } else if (storeUser?.value?.completed_learn_training_count >= 5 && storeUser?.value?.completed_learn_training_count < 10) {
        return "Trained and Competent"
      } else if (storeUser?.value?.completed_learn_training_count >= 10 && storeUser?.value?.completed_learn_training_count < 25) {
        return "Certified Expert"
      } else if (storeUser?.value?.completed_learn_training_count >= 25) {
        return "Training Champion"
      }
    case 'favorite_a_module':
      if (storeUser?.value?.favorite_learn_module_count < 5) {
        return "The Selector"
      } else if (storeUser?.value?.favorite_learn_module_count >= 5 && storeUser?.value?.favorite_learn_module_count < 10) {
        return "Content Lover"
      } else if (storeUser?.value?.favorite_learn_module_count >= 10 && storeUser?.value?.favorite_learn_module_count < 25) {
        return "Informed Collector"
      } else if (storeUser?.value?.favorite_learn_module_count >= 25) {
        return "Resource Guardian"
      }
  }
}

const user = ref({
  id: null,
  accessLevel: null,
  accessLevelPreset: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birthdate: null,
  hiredate: null,
  gender: null,
  avatar: null,
  avatars: null,
  lang: null,
  tags: [],
  jobTitle: null,
  managerId: null,
  timezone: null,
  badges: {} // On n'a pas mis null sinon ça plante
});

const allTabs = ref([
  {
    value: "interviews",
    text: "Interviews",
    icon: "mingcute:chat-3-line"
  },
  {
    value: "learnings",
    text: "Learnings",
    icon: "mingcute:mortarboard-line"
  },
  {
    value: "targets",
    text: "Targets",
    icon: "mingcute:target-line"
  },
]);

const updateUserProfile = async () => {
  await getUserById(route.params.id).then((res) => {
    user.value = caseStyles.convertKeysToCamel(res);

    user.value.badges = {
      acquireModuleCount: storeUser?.value?.completed_learn_modules_count,
      reactModuleCount: storeUser?.value?.reaction_learn_module_count,
      completeTrainingCount: storeUser?.value?.completed_learn_training_count,
      favoriteModuleCount: storeUser?.value?.favorite_learn_module_count,
    }
    i18n.global.locale.value = user.value.lang;
  });
};

const uploadAvatar = async (data) => {
  try {
    user.value.avatar = data;
    user.value.uploadedAvatar = URL.createObjectURL(user.value.avatar);

    const formData = new FormData();
    if (user.value.avatar) formData.append("user[avatar]", user.value.avatar);

    const headers = { "Content-Type": "multipart/form-data" };
    await axios.patch(`/api/v1/users/${user.value.id}`, formData, { headers });

    snackbar.setStatus("updated").displaySnackBar("Your profile photo has been updated", 3000);
  } catch (e) {
    console.log(e);
  }
}

const deletePicture = async () => {
  await axios.patch(`/api/v1/users/${user.value.id}`, {
    user: {
      remove_avatar: true
    }
  });
  await updateUserProfile()

  snackbar.setStatus("updated").displaySnackBar("Your profile photo has been removed", 3000);
  snackbar.setBgColor('fakeBlack')
}

const openModalSettings = () => {
  modalSettings.value.modalSettings.dialog = true;
}

const openModalUserBadges = () => {
  modalUserBadges.value.modalUserBadges.dialog = true;
}
</script>

<style scoped>
.v-slide-group, .v-slide-group__container {
  width: 100%;
}

:deep(.v-slide-group__content) {
  justify-content: space-between;
}

:deep(.v-field--variant-filled .v-field__overlay) {
  background-color: transparent;
}

:deep(.v-slide-group__prev),
:deep(.v-slide-group__next) {
  display: none;
}

</style>
