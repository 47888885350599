// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify'
import * as components from 'vuetify/components'
import * as labs from "vuetify/labs/components";

const interview = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#3177B7",
    primaryHover: "#4D93D4",
    primaryActive: "#1D63A3",
    primaryLighten3: "#E2F5FE",
    tooltipBackground: "#DAE6F2",
    tooltipText: "#3177B7",
    accent: "#1260ab",
    secondary: "#71b1f3",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    interviewBlueSoft: "#EBF2F8",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
  },
};
const learn = {
  dark: false,
  colors: {
    black: "#000000",
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#EF8C64",
    primaryHover: "#FEA37E",
    primaryActive: "#DF774C",
    primaryLighten3: "#FEF4F0",
    tooltipBackground: "#FEF4F0",
    tooltipText: "#EF8C64",
    accent: "#ff7034",
    secondary: "#ffbea1",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    trainingOrange: "#EF8C64",
    trainingOrangeSoft: "#FEF4F0",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}
const roadmap = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#5C95FF",
    primaryHover: "#78A7FF",
    primaryActive: "#347BFF",
    primaryLighten3: "#EFF5FF",
    tooltipBackground: "#EFF5FF",
    tooltipText: "#5C95FF",
    accent: "#1a69ff",
    secondary: "#8fa7d5",
    success: "#86af3f",
    info: "#eed055",
    grey: '#CDCDCD',
    warning: "#FB8C00",
    error: "#FF5252",
    neutral: "#333333",
    objectiveBlueSoft: "#EFF5FF",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}
const company = {
  dark: false,
  colors: {
    black: '#000000',
    deepBlack: '#111111',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: "#333333",
    primaryHover: "#8c8c8c",
    primaryActive: "#101010",
    primaryLighten3: "#ECECEC",
    tooltipBackground: "#787878",
    tooltipText: "#FFFFFF",
    accent: "#000000",
    secondary: "#656565",
    success: "#86af3f",
    info: "#eed055",
    warning: "#FB8C00",
    grey: '#CDCDCD',
    error: "#FF5252",
    neutral: "#333333",
    fakeBlack: "#333333",
    darkGrey: "#787878",
    middleGrey: "#CDCDCD",
    lightGrey: "#ECECEC",
    veryLightGrey: "#F6F6F6",
    positiveGreen: "#7BC079",
    mediumOrange: "#FFC85C",
    negativeRed: "#FF5656",
    mediumOrangeOpacity: "#FFFAEF",
    negativeRedOpacity: "#FFEFEF",
    positiveGreenOpacity: "#F2F9F2",
    transparentPositiveGreen: "#D8EDD7",
    transparentMediumOrange: "#fff3db",
    transparentNegativeRed: "#FAD4D4"
  }
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    date: {
      adapter: VuetifyDateAdapter,
    },
    theme: {
      defaultTheme: 'company',
      themes: {
        interview,
        learn,
        roadmap,
        company
      },
      variations: {
        colors: [
          'primary',
          'secondary',
          'fakeBlack',
          'grey',
          'darkGrey',
          'middleGrey',
          'lightGrey',
          'veryLightGrey',
          'positiveGreen',
          'mediumOrange',
          'negativeRed'
        ],
        lighten: 5,
        darken: 5,
      },
    },
    components: {
      ...components,
      ...labs,
    }
  }
)
