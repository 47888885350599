<template>
  <div v-if="loading">
    <div class="flex flex-col justify-center items-center w-full mx-auto">
      <svn-loader loading-size="xl" class="mt-8 mb-8" />
    </div>
  </div>

  <v-infinite-scroll
    v-if="interviewData && interviewData.length > 0"
    class="h-full overflow-y-hidden"
    :items="interviewData"
    color="primary"
    :mode="isMdScreen ? 'manual' : 'intersect'"
    @load="loadMoreData"
  >
    <template #load-more />
    <template #empty />

    <v-data-table
      v-if="!loading"
      :items="interviewData"
      :items-per-page="-1"
      class="-mt-[56px] md:!mt-0 "
    >
      <template #headers>
        <!-- Version DESKTOP -->
        <tr v-if="isMdScreen">
          <td
            v-for="column in headers"
            :key="column.key"
            class="!px-0 !bg-very-light-grey"
          >
            <div class="flex justify-start items-center gap-1 px-6 py-3 ">
              <p class="font-medium text-xs text-dark-grey leading-[18px]">
                {{ $t(column.title) }}
              </p>
            </div>
          </td>
        </tr>

        <tr v-else class="h-16" />
      </template>

      <template #item="{ item }">
        <tr
          class="cursor-pointer"
          @click="goToSpecificInterview(item?.raw?.title, item?.raw?.id)"
        >
          <!-- 1ere colonne si desktop -->
          <td v-if="isMdScreen"class="!px-0">

            <div class="flex justify-start items-center gap-3 px-6 py-4 max-w-[400px]">
              <Icon
                class="text-fake-black cursor-pointer"
                :icon="campaign_icon(item.raw.campaign.campaign_type)"
                width="16"
                height="16"
              />
              <p class="text-sm font-normal text-fake-black">
                {{ item.raw.campaign.title }}
              </p>
            </div>
          </td>

          <!-- 1ere colonne si mobile -->
          <td v-else class="border-t border-solid border-very-light-grey !px-0">
            <div class="flex flex-col gap-2 p-4">
              <div class="flex flex-col gap-1 items-start">
                <div class="flex justify-start items-center gap-2">
                  <Icon
                    class="text-fake-black cursor-pointer"
                    :icon="campaign_icon(item.raw.campaign.campaign_type)"
                    width="16"
                    height="16"
                  />

                  <p class="text-normal font-medium text-fake-black">
                    {{ item.raw.campaign.title }}
                  </p>
                </div>

                <div class="flex gap-2 justify-start items-center">
                  <p class="text-sm text-dark-grey min-w-[89.75px]">
                    {{ $t(headers[1].title) }} :
                  </p>

                  <div class="flex items-center px-2 gap-1 border-solid border-middle-grey border-[0.5px] rounded-lg">
                    <div
                      id="avatar"
                      class="flex justify-center items-center relative cursor-pointer w-4 h-4 content-center"
                    >
                      <svn-avatar
                        :avatar="getAvatar(item)"
                        :firstname="getSplitName(item).firstname"
                        :lastname="getSplitName(item).lastname"
                        size="xs"
                        class="'!text-[9px]'"
                      />
                    </div>

                    <p class="text-sm font-normal text-dark-grey">
                      {{ getNameFromType(item) }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="flex justify-start">
                <div
                  class="flex justify-center items-center rounded-[3px] px-2 py-[5px]"
                  :class="getBgWithBorderByCampaignStatus(item.raw.campaign.status)"
                >
                  <p class="text-sm font-medium">
                    {{ $t(campaign_status_str(item.raw.campaign.status)) }}
                  </p>
                </div>
              </div>
            </div>
          </td>

          <!-- 2eme colonne, invisible en mobile -->
          <td v-if="isMdScreen" class="px-0">
            <div class="flex justify-start items-center px-6 py-4">
              <div class="flex items-center px-2 gap-1 border-solid border-middle-grey border-[0.5px] rounded-lg h-[22px]">
                <div
                  id="avatar"
                  class="flex justify-center items-center relative cursor-pointer w-4 h-4 content-center"
                >
                  <svn-avatar
                    :avatar="getAvatar(item)"
                    :firstname="getSplitName(item)?.firstname"
                    :lastname="getSplitName(item)?.lastname"
                    size="xs"
                  />
                </div>
                <p class="text-xs font-normal text-dark-grey leading-5 truncate">
                  {{ getNameFromType(item) }}
                </p>
              </div>
            </div>
          </td>

          <!-- 3eme colonne, invisible en mobile -->
          <td v-if="isMdScreen"class="px-0">
            <div class="flex justify-start items-center px-6 py-4">
              <div
                class="rounded-[3px] px-2 py-[5px] flex justify-center items-center"
                :class="[getBgWithBorderByCampaignStatus(item.raw.campaign.status)]"
              >
                <p class="text-sm font-medium">
                  {{ $t(campaign_status_str(item.raw.campaign.status)) }}
                </p>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #bottom>
        <v-pagination
          v-if="isMdScreen && paginationData && interviewData &&interviewData.length > 0"
          :model-value="paginationData.current_page"
          :length="paginationData.total_pages"
          :total-visible="6"
          theme="primary"
          variant="text"
          class="p-5 !list-none"
          @update:model-value="fetchNewPage"
        >
          <template #prev>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(paginationData?.current_page > 1 ? fetchNewPage(paginationData?.current_page - 1) : '')"
            >
              <Icon
                icon="mdi-arrow-left"
                width="20"
                height="20"
                class="mr-2"
              />

              <span class="text-sm normal-case not-italic">{{ $t("Previous") }}</span>
            </div>
          </template>

          <template #next>
            <div
              class="flex flex-row justify-center items-center cursor-pointer text-darkGrey"
              @click="(paginationData?.current_page < paginationData?.total_pages ?
                fetchNewPage(paginationData?.current_page + 1) : '')"
            >
              <span class="text-sm normal-case not-italic">{{ $t("Next") }}</span>

              <Icon
                icon="mdi-arrow-right"
                width="20"
                height="20"
                class="ml-2"
              />
            </div>
          </template>
        </v-pagination>
      </template>
    </v-data-table>
  </v-infinite-scroll>

  <!-- No campaign found after search -->
  <div v-else-if="interviewData && interviewData.length === 0 && isSearching && !loading">
    <div class="flex flex-col justify-start items-center py-16 px-4 gap-4 md:px-0 md:gap-5">
      <Icon
        icon="noto:face-with-monocle"
        height="24"
        width="24"
      />
      <p class="font-medium text-sm text-fake-black text-center w-[358px]">
        {{ $t(`Hmm, it seems we can’t find any results based on your search.`) }}
      </p>
    </div>
  </div>

  <!-- No campaign in index -->
  <div v-else-if="interviewData && interviewData.length === 0 && !isSearching && !loading">
    <div class="flex flex-col justify-start items-center py-16 px-4 gap-4 md:px-0 md:gap-5">
      <Icon
        icon="noto:face-with-monocle"
        height="24"
        width="24"
      />
      <p class="font-medium text-sm text-fake-black text-center w-[358px]">
        {{ $t(`Hmm, it seems you have no campaigns ${getMention(props.headers[1].title)}`) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs } from "vue";
import i18n from '@/plugins/i18n';
import { useMobileStore } from "@/store/mobile";
import { storeToRefs } from "pinia";
import { Icon } from '@iconify/vue';
import useCampaigns from '@/tools/useCampaigns';
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import { useInterviewStore } from "@/store/interview.js";

const props = defineProps({
  headers: { type: Array, required: true },
  page: { type: String, default: "as_participant" },
  interviewData: { type: Array, default: () => [] },
  paginationData: { type: Object, default: () => {} },
  user: { type: Object, required: true },
  searchText: { type: String, default: "" }
})

const emit = defineEmits(['refetch-interviews', 'fetch-additional-interviews'])

const router = useRouter();
const { campaign_status_str, getBgWithBorderByCampaignStatus, campaign_icon } = useCampaigns();
const { setInterviewsByUser } = useInterviewStore();

const { isMdScreen } = storeToRefs(useMobileStore());
const { loading } = storeToRefs(useInterviewStore());

const currentPage = ref(1)
const isSearching = ref('')
const totalPages = ref(null)
const { searchText, page } = toRefs(props)

onMounted(async () => {
  if (props?.paginationData) {
    totalPages.value = props?.paginationData.total_pages
  }
});

const fetchNewPage = (e) => {
  setInterviewsByUser([])
  emit('refetch-interviews', { userId: props.user.id, as: page.value, text: searchText.value, page: e })
};

const loadMoreData = debounce(async({ done }) => {
  if (currentPage.value < totalPages.value) {
    try {
      emit('fetch-additional-interviews', { userId: props.user.id, as: page?.value, text: searchText?.value, page: currentPage.value + 1 })

      done('ok')
      currentPage.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}, 200);

 const getMention = (person) => {
  if ( person === "Person in charge") {
    return 'as participant'
  }
  else if (person === "Review of") {
    return 'as reviewer'
  }
  else if (person === "In charge of") {
    return 'as person in charge'
  }
}

const getNameFromType = (item) => {
  if (props.page === "as_participant") {
    return item.raw.interviewer?.fullname ? item.raw.interviewer?.fullname : i18n.global.t('No name available')
  } else if (props.page === "as_reviewer") {
    return item.raw.participant?.fullname ? item.raw.participant?.fullname : i18n.global.t('No name available')
  } else if (props.page === "as_person_in_charge") {
    return item.raw.employee?.fullname ? item.raw.employee?.fullname : i18n.global.t('No name available')
  } else {
    return i18n.global.t('No name available')
  }
}

const goToSpecificInterview = (title, InterviewId) => {
  if (props.page === "as_participant" || props.page === "as_person_in_charge") {
    return goToInterview(title, InterviewId);
  } else if (props.page === "as_reviewer") {
    return goToInterviewIfFeedback(title, InterviewId);
  } else {
  return ""
  }
}

const goToInterview = (title, InterviewId) => {
  router.push({ name: 'interview_answer', params: { id: InterviewId } })
}

const goToInterviewIfFeedback = (title, InterviewId) => {
  router.push({ name: 'feedback_answer', params: { id: InterviewId } })
}

const getAvatar = (item) => {
  if (props.page === "as_participant" && item.raw?.interviewer?.avatar) {
    return item.raw?.interviewer?.avatar["30"]
  } else if (props.page === "as_reviewer" && item.raw?.participant?.avatar) {
    return item.raw?.participant?.avatar["30"]
  } else if (props.page === "as_person_in_charge" && item.raw?.employee?.avatar) {
    return item.raw?.employee?.avatar["30"]
  } else {
    return null
  }
}

const getSplitName = (item) => {
  if (props.page === "as_participant" && item?.raw?.interviewer && item.raw?.interviewer?.fullname) {
    return {
      firstname: item.raw?.interviewer?.fullname.split(' ')[0],
      lastname: item.raw?.interviewer?.fullname.split(' ')[1]
    }
  } else if (props.page === "as_reviewer" && item?.raw?.participant && item.raw?.participant?.fullname) {
    return {
      firstname: item.raw?.participant?.fullname.split(' ')[0],
      lastname: item.raw?.participant?.fullname.split(' ')[1]
    }
  } else if (props.page === "as_person_in_charge" && item?.raw?.employee && item.raw?.employee?.fullname) {
    return {
      firstname: item.raw?.employee?.fullname.split(' ')[0],
      lastname: item.raw?.employee?.fullname.split(' ')[1]
    }
  } else {
    return {
      firstname: 'N',
      lastname: 'A'
    }
  }
}
</script>
