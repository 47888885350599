<template>
  <div class="w-full flex flex-col justify-center items-start gap-2 self-stretch rounded-[8px] border-[0.5px] border-lightGrey shadow">
    <div class="w-full flex flex-col gap-2 lg:!flex-row lg:!justify-between lg:border-b-2 lg:border-b-lightGrey">
      <!-- Training completion - Category -->
      <div class="flex flex-col justify-center items-start self-stretch p-4 gap-1 lg:!p-8">
        <p class="text-fakeBlack text-xl font-medium">
          {{ $t('Training completion - Category') }}
        </p>
  
        <p class="text-darkGrey text-sm font-normal">
          {{ $t('Compare completion by category') }}
        </p>
      </div>
  
      <!-- Select Input -->
      <div class="w-full px-4 lg:!w-[250px] lg:!h-[48px] lg:!self-center">
        <v-select
          v-model="chartCompletionCategorySelected"
          item-title="name"
          item-value="id"
          density="compact"
          :items="tagCategories"
          variant="outlined"
          :placeholder="$t('Category')"
          hide-details
          @update:model-value="changeCompletionCategory"
        />
      </div>
    </div>

    <!-- Completion Chart and legend -->
    <div class="flex items-center self-stretch px-2 py-4 lg:!px-8">
      <!-- Chart -->
      <bkt-bar
        :data="completionCategoryData"
        :options="completionCategoryOptions"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import BktBar from "@/components/chart/BktBar.vue";
import i18n from "@/plugins/i18n.js";
import { storeToRefs } from "pinia";
import { useAnalyticTrainingsStore } from "@/store/analytic-trainings.js";
import { useCompanyStore } from "@/store/company.js";

const { getTrainingCompletionAllByCategory } = useAnalyticTrainingsStore()
const { trainingCompletionAllByCategory } = storeToRefs(useAnalyticTrainingsStore())

const compagnyStore = useCompanyStore()
const { tagCategories } = storeToRefs(compagnyStore)

const props = defineProps({
  trainingId: { type: String, required: true },
  trainingParticipantsList: { type: Array, required: true },
})

const chartCompletionCategorySelected = ref(null);

const completionCategoryData = ref({
  labels: trainingCompletionAllByCategory.value ? trainingCompletionAllByCategory.value.map(tag => tag.tag_title) : [],
  datasets: [
    {
      label: i18n.global.t('Acquired'),
      borderRadius: 4,
      backgroundColor: '#7BC079',
      data: trainingCompletionAllByCategory.value.map(tag => tag.acquired)
    },
    {
      label: i18n.global.t('In progress'),
      borderRadius: 4,
      backgroundColor: '#FFC85C',
      data: trainingCompletionAllByCategory.value.map(tag => tag.in_progress)
    },
    {
      label: i18n.global.t('Not started'),
      borderRadius: 4,
      backgroundColor: '#FF5656',
      data: trainingCompletionAllByCategory.value.map(tag => tag.not_started)
    }
  ]
})

const completionCategoryOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true,
      suggestedMax: props?.trainingParticipantsList?.length + 1,
    },
    x: {
      stacked: true
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 21,
        boxHeight: 8,
        color: '#4F4F4F',
        font: {
          weight: 400,
        }
      },
    }
  },
}

const changeCompletionCategory = (value) => {
  chartCompletionCategorySelected.value = value

  getTrainingCompletionAllByCategory(props.trainingId, value).then(() => {
    completionCategoryData.value = {
      labels: trainingCompletionAllByCategory.value ? trainingCompletionAllByCategory.value.map(tag => tag.tag_title) : [],
      datasets: [
        {
          label: i18n.global.t('Acquired'),
          borderRadius: 4,
          backgroundColor: '#7BC079',
          data: trainingCompletionAllByCategory.value.map(tag => tag.acquired)
        },
        {
          label: i18n.global.t('In progress'),
          borderRadius: 4,
          backgroundColor: '#FFC85C',
          data: trainingCompletionAllByCategory.value.map(tag => tag.in_progress)
        },
        {
          label: i18n.global.t('Not started'),
          borderRadius: 4,
          backgroundColor: '#FF5656',
          data: trainingCompletionAllByCategory.value.map(tag => tag.not_started)
        }
      ]
    }
  })
}

onMounted(() => {
  chartCompletionCategorySelected.value = tagCategories.value?.[0].id

  getTrainingCompletionAllByCategory(props.trainingId, chartCompletionCategorySelected.value).then(() => {
    completionCategoryData.value = {
      labels: trainingCompletionAllByCategory.value ? trainingCompletionAllByCategory.value.map(tag => tag.tag_title) : [],
      datasets: [
        {
          label: i18n.global.t('Acquired'),
          borderRadius: 4,
          backgroundColor: '#7BC079',
          data: trainingCompletionAllByCategory.value.map(tag => tag.acquired)
        },
        {
          label: i18n.global.t('In progress'),
          borderRadius: 4,
          backgroundColor: '#FFC85C',
          data: trainingCompletionAllByCategory.value.map(tag => tag.in_progress)
        },
        {
          label: i18n.global.t('Not started'),
          borderRadius: 4,
          backgroundColor: '#FF5656',
          data: trainingCompletionAllByCategory.value.map(tag => tag.not_started)
        }
      ]
    }
  })
})

</script>

<style scoped>
.shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
}
</style>