<template>
  <div class="mb-16">
    <p v-if="!campaignDraft">
      {{ $t("Loading ...") }}
    </p>
    <div
      v-else
    >
      <div
        class="flex flex-col elevation-2 rounded-xl p-6 gap-8"
      >
        <div class="flex flex-col gap-3">
          <div
            class="p-0 font-normal text-base"
          >
            {{ $t("Choose a campaign type") }}
          </div>
          <div>
            <div
              class="grid xl:grid-rows-1 xl:grid-cols-2 lg:grid-cols-1 gap-4"
            >
              <bkt-radio-text-icon-button
                title="One to One"
                description="Interview between in interviewer and interviewee"
                :condition="campaignDraft.campaign_type === CampaignTypeEnum.ONE_TO_ONE"
                :on-click="updateCampaignDraftKind"
                :click-parameters="[CampaignTypeEnum.ONE_TO_ONE]"
                icon="twemoji:handshake"
              />
              <bkt-radio-text-icon-button
                title="Feedback 360"
                description="Participants receive feedbacks from their reviewers"
                :condition="campaignDraft.campaign_type === CampaignTypeEnum.FEEDBACK_360"
                :on-click="updateCampaignDraftKind"
                :click-parameters="[CampaignTypeEnum.FEEDBACK_360]"
                icon="noto:megaphone"
                :is-beta="true"
              />
              <bkt-radio-text-icon-button
                title="Survey"
                description="Collect data to take the temperature of employees."
                :condition="campaignDraft.campaign_type === CampaignTypeEnum.SURVEY"
                :on-click="updateCampaignDraftKind"
                :click-parameters="[CampaignTypeEnum.SURVEY]"
                icon="noto:bar-chart"
                :is-beta="true"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div
            class="font-normal text-base"
          >
            {{ $t("Choose a campaign name") }}
          </div>
          <div>
            <v-text-field
              v-model="campaignDraft.title"
              color="primary"
              base-color="middleGrey"
              :label="$t('Title')"
              variant="outlined"
              hide-details
            />
          </div>
        </div>

        <div class="flex">
          <v-spacer />
          <v-btn
            :disabled="isDeadlineBeforeStartline(campaignDraft.startline, campaignDraft.deadline)"
            :loading="loading"
            color="primary"
            variant="elevated"
            @click="next"
          >
            <span class="flex gap-2 normal-case">
              {{ $t("Next") }}
              <Icon
                :icon="'material-symbols:arrow-forward-rounded'"
                class="h-4 w-4"
              />
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useFormTools from "@/tools/useFormTools.js";
import { useSnackbar } from "@/store/snackbar.js";
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import {Icon} from "@iconify/vue";
import CampaignTypeEnum, {CampaignTypeToKey} from "@/constants/CampaignTypeEnum.js";
import {useCompanyStore} from "@/store/company.js";
import BktRadioTextIconButton from "@/components/button/BktRadioTextIconButton.vue";
import filters from "@/tools/filters.js";
import {debounce} from "lodash";

const emit = defineEmits(["next"]);

const { campaignDraft, loading } = storeToRefs(useCampaignDraftStore());

const { updateCampaignDraft } = useCampaignDraftStore();
const snackbar = useSnackbar();
const { fetchInterviewForms } = useCompanyStore();
const { anyNullInArray, contactNullStr } = useFormTools();

const next = debounce(async () => {
  if (
    anyNullInArray([
      campaignDraft.value.title,
    ])
  ) {
    let msg = "Hey, please fill";

    msg += contactNullStr([
      [campaignDraft.value.title, ", title"],
    ]);
    snackbar.setStatus("error", msg).checkStatus();
  } else {
    try {
      await updateCampaignDraft({
        campaign_type: campaignDraft.value.campaign_type,
        title: campaignDraft.value.title,
      });
      emit("next");
    } catch (error) {
      const errorData = error?.response?.data?.message;

      if (errorData.length > 0) {
        snackbar
          .setStatus("error", errorData.join(', '))
          .checkStatus();
      } else {
        snackbar
          .setStatus("error", "Our server is experiencing some problems")
          .checkStatus();
      }
    }
  }
}, 200);

const updateCampaignDraftKind = async (type) => {
  campaignDraft.value.campaign_type = type
  const formType = 'InterviewApp::Forms::Templates::' + type

  await fetchInterviewForms(formType, {disableCache: true});
}

const isDeadlineBeforeStartline = (startline, deadline) => {
  return startline && deadline && filters.compareDates(startline, deadline)
}

</script>

<style>
.custom-switch .v-switch__track {
  height: 18px !important;
  width: 38px !important;
}

.custom-switch .v-switch__thumb {
  height: 14px !important;
  width: 14px !important;
  left: 6px !important;
}

</style>