<template>
  <bkt-pop-up-dialog-skeleton
    ref="dialogTrainingCompleted"
    icon="noto:party-popper"
    :bind="true"
    :icon-size="56"
    :display-close="false"
    :display-secondary-button="false"
    primary-button-text="There's a problem with the content"
    vertical
    :width="isMobile ? 300 : 400"
    @click:outside="closeDialog"
  >
    <template #button>
      <div class="!hidden" />
    </template>

    <template #body>
      <div class="flex flex-col items-center gap-4">
        <p class="text-fakeBlack text-base font-medium text-center">
          {{ $t('Congratulations, William. You have completed your training! 👏') }}
        </p>

        <p class="text-darkGrey text-sm font-semibold text-center">
          {{ $t('Training title is completed !') }}
        </p>
      </div>

      <div
        class="w-full h-full absolute top-0 left-0 !z-[9999]"
      >
        <iframe
          src="https://lottie.host/?file=943422dd-97a0-41c9-8d79-e3b9b124194f/NBtuldEzhF.json"
          class="w-full h-full"
        />
      </div>
    </template>

    <template #actionButtons>
      <div class="flex justify-center items-center">
        <v-btn
          variant="flat"
          color="primary"
          :text="$t('Great')"
          @click="closeDialog"
        />
      </div>
    </template>
  </bkt-pop-up-dialog-skeleton>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import BktPopUpDialogSkeleton from "@/components/BktPopUp/skeleton/BktPopUpDialogSkeleton.vue";

const props = defineProps({
  opened: { type: Boolean, default: false },
})

const { isMobile } = storeToRefs(useMobileStore())

const dialogTrainingCompleted = ref(null)

const closeDialog = () => {
  dialogTrainingCompleted.value.dialog = false
}

const isDialogOpened = computed(() => {
  return props?.opened
})

watch(isDialogOpened, (newValue, oldValue) => {
  if (newValue === true) {
    dialogTrainingCompleted.value.dialog = true
  }
})
</script>