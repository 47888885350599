<template>
  <apps-header
    :links="getLinks"
    :app-icon="imageIcon"
    app-title="Roadmap"
  >
    <router-view />
  </apps-header>
</template>

<script setup>
import AppsHeader from "../../components/header/AppsHeader.vue";
import { useRoute } from "vue-router";
import { computed, onUpdated, ref } from "vue";
import { useTheme } from "vuetify";
import { useUserStore } from "@/store/user.js";
import imageIcon from "@/assets/images/icons/roadmaps.svg"

const { isManagerOrAbove, isManagerCreatorOrAbove, isAdminOrAbove } = useUserStore();
const theme = useTheme();
theme.global.name.value = "roadmap";

const links = [
  { title: "My Roadmap", linkName: "my_roadmaps" },
  { title: "My Team Roadmaps", linkName: "my_team_roadmaps" },
  { title: "Targets", linkName: "roadmaps_targets" },
  { title: "All Roadmaps", linkName: "roadmaps_employees" },
  { title: "Templates", linkName: "roadmaps_templates" },
];

let routeName = ref(useRoute().name);

const getLinks = computed(() => {
  return links.filter((link) => {
    if (link.linkName === "roadmaps_employees") {
      if (isAdminOrAbove()) {
        return true;
      }
      return false;
    } else if (
      link.linkName === "roadmaps_templates" ||
      link.linkName === "roadmaps_targets"
    ) {
      if (isManagerCreatorOrAbove()) {
        return true;
      }
      return false;
    } else if (link.linkName === "my_team_roadmaps") {
      if (isManagerOrAbove()) {
        return true;
      }
      return false;
    }
    return true;
  });
});

onUpdated(() => {
  routeName.value = useRoute().name;
});
</script>
